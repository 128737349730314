import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbFSService } from 'src/app/services/db-fs.service';
import { User } from 'src/app/shared/user';
import { environment } from 'src/environments/environment';

class Invitacion {
  _key?: string;
  email: string;
  keyLocal: string;
  nombreLocal: string;
  photoUrlEnviador: string;
  nameEnviador: string; 
  loading?: boolean;
}

@Component({
  selector: 'app-invitaciones',
  templateUrl: './invitaciones.component.html',
})
export class InvitacionesComponent implements OnInit {

  didSearch: boolean = false;
  email: string = 'germanmarin888@gmail.com';
  loading: boolean;
  invitaciones: ({ _key: string; } & Invitacion)[];
  user: { _key: string; } & User;

  constructor(
    private dbfs: DbFSService,
    private http: Http,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  async buscarInvitaciones(email: string) {
    if (!email) {
      return;
    }
    email = email.trim();
    this.loading = true;
    this.didSearch = true;
    const users = await this.dbfs.collectionOnce<User>(
      'users',
      ref => ref.where('email', '==', email)
    ).toPromise();
    for (const user of users) {
      if (user._key && user.uid) {
        this.user = user;
        await this.fetchNotifications(email);
        this.loading = false;
      }
    }

    this.loading = false;
    return;
  }

  async fetchNotifications(email: string) {
    this.invitaciones = await this.dbfs
      .collectionOnce<Invitacion>("invitaciones", (ref) =>
        ref.where("email", "==", email)
      ).toPromise();
  }

  aceptar(inv: Invitacion) {
    const body = {
      invitacion: inv,
      auth: { uid: this.user.uid }
    }

    inv.loading = true;

    this.http.post(`${environment.urls.functions}/invitaciones/aceptarInvitacion`, body).toPromise()
      .then(res => {
        console.log(res.json());
        this._snackBar.open('✅ Invitación aceptada', '')._dismissAfter(2000);

      })
      .catch(error => {
        console.log(error);
        this._snackBar.open('❗Error al aceptar', 'Revisar consola (F12)')._dismissAfter(2000);
      }).finally(() => {
        inv.loading = false;
      })
  }

}
