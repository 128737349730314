import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { Concepto } from "../modal-pricing/modal-pricing.component";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-modal-concepto',
  templateUrl: './modal-concepto.component.html',
  styleUrls: ['./modal-concepto.component.scss']
})
export class ModalConceptoComponent implements OnInit {

  passReseteado: boolean = false;

  s: ServidorAdmin;
  concepto: Concepto;
  creandoConcepto: boolean;
  formConcepto: FormGroup;
  submitted = false;
  error: string;

  constructor(
    public dialogRef: MatDialogRef<ModalConceptoComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin, concepto?: Concepto },
    private db: AngularFireDatabase,
    private dbFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    private cuentaService: CuentaService) {

  }

  ngOnInit() {
    this.s = this.data.s;
    this.concepto = this.data.concepto;
    if (!this.concepto) {
      this.creandoConcepto = true;
    } else {
      this.creandoConcepto = false;
    }

    this.formConcepto = this.formBuilder.group({
      concepto: ["", [Validators.required, Validators.minLength(2)]],
      total: ["", [Validators.required]],
      cuotas: ["", []],
    });
    if (this.concepto) {
      if (this.concepto.concepto)
        this.formConcepto.controls.concepto.setValue(this.concepto.concepto);
      if (this.concepto.total)
        this.formConcepto.controls.total.setValue(this.concepto.total);
      if (this.concepto.cuotasPendientes)
        this.formConcepto.controls.cuotas.setValue(this.concepto.cuotasPendientes);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  async guardarDatos() {
    this.submitted = true;
    this.error = null;

    if (this.formConcepto.invalid) {
      this.error = "Datos inválidos";
      return;
    }
    let urlConceptos = "cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/conceptos";
    // subimos el formulario 
    let mapUpdates = {};
    let id;
    if (this.creandoConcepto) {
      let conceptosRef = this.db.object("/cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/conceptos");
      let reference = await conceptosRef.query.ref.push();
      id = reference.key;
    }
    else
      id = this.concepto.id;
    mapUpdates[urlConceptos + "/" + id + "/id"] = id;
    const concepto = this.formConcepto.controls.concepto.value.trim();
    const total = this.formConcepto.controls.total.value;
    const cuotas = this.formConcepto.controls.cuotas.value ? this.formConcepto.controls.cuotas.value : null;

    mapUpdates[urlConceptos + "/" + id + "/concepto"] = concepto;
    mapUpdates[urlConceptos + "/" + id + "/total"] = total;
    mapUpdates[urlConceptos + "/" + id + "/cuotasPendientes"] = cuotas;
    let importe;
    if (this.s.pagaIva) {
      importe = total / (this.s.pagaIva / 100 + 1);
      mapUpdates[urlConceptos + "/" + id + "/importe"] = importe;
      mapUpdates[urlConceptos + "/" + id + "/iva"] = total - importe;
    } else {
      mapUpdates[urlConceptos + "/" + id + "/importe"] = total;
      mapUpdates[urlConceptos + "/" + id + "/iva"] = 0;
    }

    this.db.object("/").update(mapUpdates).then(ok => {
      this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
      this.dialogRef.close(true);
    }).catch(e => {
      console.error("No se pudo grabar el concepto", e);
      this.snackbar.open("Error, no se pudo grabar")._dismissAfter(4000);
    });
  }

}
