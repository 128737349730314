import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RutaService } from '../../services/ruta.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-headerlanding',
  templateUrl: './headerlanding.component.html',
  styleUrls: ['./headerlanding.component.scss', '../../../mypopapp/assets/_css/style2.css', '../../../mypopapp/assets/_css/style2.2.css', '../../../mypopapp/assets/_css/media-queries2.css']

})
export class HeaderlandingComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(public rutaService: RutaService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ruta: string;
  navVisible: boolean = false;

  ngOnInit() {
    this.rutaService.ruta$.subscribe(rutax => {
      this.ruta = rutax;
    })
  }

  toggleNav() {
    this.navVisible = !this.navVisible
  }
  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
