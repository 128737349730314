import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Locacion from '../../models/locacion';
import Servidor from '../../models/servidor';
import { CommonService } from '../../services/common.service';
import { LocacionesService } from '../../services/locaciones.service';
import { ServidoresService } from '../../services/servidores.service';
import { ToastsService } from '../../services/toasts.service';
import { ModalEditServidorComponent } from './modal-edit-local/modal-edit-servidor.component';

type ColumnasMostradasServidores = (keyof Servidor | 'actions');

@Component({
  selector: 'app-gestion-servidores',
  templateUrl: './gestion-servidores.component.html',
  styleUrls: ['./gestion-servidores.component.scss']
})
export class GestionServidoresComponent implements OnInit {

  @Input() locacion: Locacion;
  @Input() locaciones: Locacion[];

  servidores: Servidor[];
  dataServidoresTabla: MatTableDataSource<Servidor>;
  columnasMostradas: ColumnasMostradasServidores[] = ['pkLocal', 'nombreLocal', 'keyLocal', 'mac', 'activo', 'actions'];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  soloActivos = true;
  loadingData;

  constructor(
    private dialog: MatDialog,
    private toastSrv: ToastsService,
    private locacionesSrv: LocacionesService,
    private servidoresSrv: ServidoresService,
  ) { }

  ngOnInit(): void {
    this.loadingData = true;
    this.obtenerServidores();
    this.obtenerLocaciones();
  }

  private obtenerServidores() {
    if (this.locacion) {
      this.locacionesSrv.obtenerServidoresDeLocacion(this.locacion.id).subscribe(
        res => this.exitoObtenerServidores(res.data)
      ),
      err => console.error('Error obteniendo servidores de cuenta');
    } else {
      this.servidoresSrv.getServidores().subscribe(
        res => this.exitoObtenerServidores(res.data),
        err => console.error('Error al obtener las servidores', err)
      );
    }
  }

  private exitoObtenerServidores(servidores: Servidor[]) {
    console.log('servidores obtenidos', servidores);
    this.servidores = servidores;
    this.cargarTablaFiltrada();
    this.loadingData = false;
  }

  private obtenerLocaciones() {
    if (!this.locaciones) this.locacionesSrv.getLocaciones().subscribe(
      res => {
        this.locaciones = res.data;
      },
      err => {
        console.error('Error al obtener locaciones', err);
        this.toastSrv.show('Error obteniendo las locaciones ' + err.message)
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataServidoresTabla.filter = filterValue.trim().toLowerCase();
  }

  onClickCrearServidor() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60%';
    dialogConfig.height = '90%';
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      titulo: 'Crear servidor',
      createMode: true,
      locacionDeServidor: this.locacion,
      locaciones: this.locaciones,
    };

    this.dialog.open(ModalEditServidorComponent, dialogConfig).afterClosed().subscribe(result => {
      console.log('result servidorCreado', result);
      if (result?.servidorCreado) {
        this.agregarServidorArreglo(result.servidorCreado);
        this.mostrarModalEditarServidor(result.servidorCreado);
        this.toastSrv.show('Servidor creado con éxito');
      }
    });
  }

  mostrarModalEditarServidor(servidor: Servidor) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60%';
    dialogConfig.height = '90%';
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      titulo: 'Editar servidor',
      servidor: servidor,
      locacionDeServidor: this.locacion || this.locaciones.find(c => c.id == servidor.idLocacion),
    };

    this.dialog.open(ModalEditServidorComponent, dialogConfig).afterClosed().subscribe(result => {
      console.log('result',result);
      if(result?.servidorModificado){
        this.modificarServidorArreglo(result.servidorModificado);
        this.toastSrv.show('Servidor actualizado con éxito');
      }
    });
  }

  modificarServidorArreglo(servidor: Servidor) {
    const indice = this.servidores.findIndex(c => c.pkLocal === servidor.pkLocal && c.pkManager === servidor.pkManager);
    this.servidores[indice] = servidor;
    this.dataServidoresTabla.data[indice] = servidor;
    this.dataServidoresTabla.paginator = this.paginator;
  }

  agregarServidorArreglo(servidor: Servidor) {
    this.dataServidoresTabla.data.push(servidor);
    this.dataServidoresTabla.paginator = this.paginator;
  }

  onChangeActivos(event){
    this.soloActivos = !this.soloActivos;
    this.cargarTablaFiltrada();
  }

  cargarTablaFiltrada(){
    if(this.soloActivos){
      this.dataServidoresTabla = new MatTableDataSource(this.servidores.filter(l => l.activo == this.soloActivos));
    }else{
      this.dataServidoresTabla = new MatTableDataSource(this.servidores);
    }
    this.dataServidoresTabla.paginator = this.paginator;
    this.dataServidoresTabla.sort = this.sort;
  }

}
