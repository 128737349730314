import { Injectable } from '@angular/core';
import { BASE_URL_OB } from '../core/const';
import { HttpClient } from '@angular/common/http';
import Cuenta from '../models/cuenta';
import { Local } from 'protractor/built/driverProviders';
import Locacion from '../models/locacion';

@Injectable({
  providedIn: 'root'
})
export class CuentasService {

  constructor(
    private http: HttpClient
  ) { }

  obtenerCuentas() {
    return this.http.get<Cuenta[]>(`${BASE_URL_OB}/v1/cuentas`);
  }

  putCuentas(cuenta:Cuenta){
    return this.http.put(`${BASE_URL_OB}/v1/cuentas`, cuenta);
  }

  postCuentas(cuenta:Cuenta){
    return this.http.post(`${BASE_URL_OB}/v1/cuentas`, cuenta);
  }

  obtenerLocacionesDeCuenta(pkCuenta: number) {
    return this.http.get<{msg: string, data:Locacion[]}>(`${BASE_URL_OB}/v1/cuentas/${pkCuenta}/locaciones`);
  }
}
