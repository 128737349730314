import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Cuenta from 'src/app/admin2/models/cuenta';
import Locacion from 'src/app/admin2/models/locacion';
import { SelectData } from 'src/app/admin2/models/types';
import { CuentasService } from 'src/app/admin2/services/cuentas.service';
import { LocacionesService } from 'src/app/admin2/services/locaciones.service';
import { ToastsService } from 'src/app/admin2/services/toasts.service';
import { ModalEditServidorComponent } from '../../gestion-servidores/modal-edit-local/modal-edit-servidor.component';

@Component({
  selector: 'app-modal-edit-locacion',
  templateUrl: './modal-edit-locacion.component.html',
  styleUrls: ['./modal-edit-locacion.component.scss']
})
export class ModalEditLocacionComponent implements OnInit {

  titulo: string;
  locacion: Locacion | null; //en caso de editar local
  formulario: FormGroup;
  createMode: boolean; //Indica si el componente se instancia para crear un nuev local
  cuentaDeLocacion: Cuenta | null;
  paisesForm: SelectData[];
  guardandoDatos: boolean;

  cuentasOptions: { value: number, label: string }[];
  mostrarCambiarCuenta: boolean = false;

  monedas = [
    { value: 'USD', label: 'Dólar ' },
    { value: 'UF', label: 'UF' },
    { value: 'ARS', label: 'Peso Argentino' }
  ];

  tipoComercios = [
    { value: 'Tradicional', label: 'Tradicional' },
    { value: 'Dark Kitchen', label: 'Dark Kitchen' },
    { value: 'Dark Store', label: 'Dark Store' },
    { value: 'Otro', label: 'Otro' },
  ];

  constructor(
    private locacionesSrv: LocacionesService,
    private dialogRef: MatDialogRef<ModalEditServidorComponent>,
    private toastSrv: ToastsService,
    private datePipe: DatePipe,
    private cuentasSrv: CuentasService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    console.log('data edit modal', data);
    this.titulo = data.titulo;
    this.locacion = data.local;
    this.createMode = data.createMode;
    this.cuentaDeLocacion = data.cuentaDeLocal;
    this.paisesForm = data.paisesForm;
  }

  ngOnInit(): void {
    this.formulario = new FormGroup({
      // no modificables
      'id': new FormControl(this.locacion?.id),
      'idLocal': new FormControl(this.locacion?.idLocal),
      'fechaCreacion': new FormControl((this.createMode ? new Date() : this.locacion.fechaCreacion)),
      'fechaBaja': new FormControl(this.locacion?.fechaBaja),
      'idCuenta': new FormControl(this.cuentaDeLocacion?.id, [Validators.required]),
      // modificables
      'nombre': new FormControl(this.locacion?.nombre, [Validators.required, Validators.minLength(5)]),
      'activo': new FormControl(this.locacion?.activo, [Validators.required]),
      'pais': new FormControl(this.locacion?.ubicacion.pais, [Validators.required]),
      'direccion': new FormControl(this.locacion?.ubicacion.direccion),
      'divisionPolitica3': new FormControl(this.locacion?.ubicacion.divisionPolitica3),
    });
    this.formulario.patchValue({ fechaCreacion: this.datePipe.transform((this.createMode ? new Date() : this.locacion.fechaCreacion), 'dd/MM/yyyy') });

  }

  guardarLocal() {
    this.guardandoDatos = true;
    if (this.createMode) this.nuevaLocacion()
    else this.actualizarLocal();
  }

  nuevaLocacion() {
    const locacionAGuardar = this.locacionGuardar();
    this.locacionesSrv.postLocaciones(locacionAGuardar).subscribe({
      next: data => {
        this.dialogRef.close({ locacionCreada: data.data });
      },
      error: (err) => {
        this.toastSrv.show('Error al crear local: ' + err.error.msg);
        console.error('error al crear local', err);
      },
    })
      .add(() => this.guardandoDatos = false);
    ;
  }

  actualizarLocal() {
    const locacionGuardar = this.locacionGuardar();
    this.locacionesSrv.putLocaciones(locacionGuardar).subscribe({
      next: (data) => {
        this.dialogRef.close({ locacionModificada: data.data });
      },
      error: (err) => {
        this.toastSrv.show('Error al modificar local: ' + err.error.msg);
        console.error('error al modificar local', err);
      },
    })
      .add(() => this.guardandoDatos = false);
  }

  locacionGuardar(): Locacion {
    const formControls = this.formulario.controls;
    const localGenerado = {
      id: this.locacion?.id,
      nombre: formControls.nombre.value,
      fechaCreacion: this.locacion?.fechaCreacion || new Date(),
      ubicacion: {
        pais: formControls.pais.value,
        divisionPolitica1: this.locacion?.ubicacion.divisionPolitica1,
        divisionPolitica2: this.locacion?.ubicacion.divisionPolitica2,
        divisionPolitica3: formControls.divisionPolitica3.value,
        direccion: formControls.direccion.value,
      },
      idCuenta: formControls.idCuenta.value?.value /* Por el select */ || formControls.idCuenta.value,
      idLocal: this.locacion?.idLocal,
      createdBy: 'Admin Cuentas 2',
      activo: Boolean(+formControls.activo.value),
      fechaBaja: this.locacion?.activo == true && formControls.activo.value == false ? new Date() : null,
    }
    console.log('locacionGenerado', localGenerado);
    return localGenerado;
  }

  cerrarModal() {
    this.dialogRef.close();
  }

  obtenerCuentas() {
    this.cuentasSrv.obtenerCuentas().subscribe(
      data => {
        this.cuentasOptions = data.map(c => ({ value: c.id, label: c.nombre || `${c.id}` }))
        this.formulario.patchValue({ idCuenta: this.cuentasOptions.find(c => c.value === this.locacion.idCuenta)})

      },
      err => this.toastSrv.show('Error al obtener cuentas'),
    )
  }

  onClickEditarCuenta() {
    // this.formulario.controls.idCuenta.updateValueAndValidity(null);
    this.mostrarCambiarCuenta = true;
    this.obtenerCuentas()
  }

}
