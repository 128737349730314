import { Component, OnInit, OnDestroy } from '@angular/core';
import { RutaService } from '../../services/ruta.service';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-comenzar',
  templateUrl: './comenzar.component.html',
  styleUrls: ['./comenzar.component.scss', '../../../mypopapp/assets/_css/style.css', '../../../mypopapp/assets/_css/style1.2.css', '../../../mypopapp/assets/_css/media-queries.css']

})
export class ComenzarComponent implements OnInit, OnDestroy {

  error: string;
  registrando: boolean = false;
  formComenzar: FormGroup;
  submitted = false;
  subscriptionComenzar: Subscription;
  constructor(public rutaService: RutaService, private formBuilder: FormBuilder, public authService: AuthService, public router: Router) { }

  //TODO:revisar utm al enviar contacto nuevo a hubspot
  ngOnInit() {
    this.rutaService.changeRuta("comenzar")
    this.subscriptionComenzar = this.authService.user.subscribe(user => {
      if (user && user.email && user.email.length > 3) {
        this.routeToMyPopApp()
      }
    });
    this.formComenzar = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      email2: ["", [Validators.required, Validators.email]],
      pass: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnDestroy() {
    if (this.subscriptionComenzar) {
      try { this.subscriptionComenzar.unsubscribe() } catch (e) { }
    }
  }
  mostrar(e) {
    console.log(e);
  }

  registrarGoogle() {
    this.error = null



    this.authService.googleLogin(true)
      .then(r => {

        //this.routeToMyPopApp()
      })
      .catch(error => {
        console.log("Error al registrarse con Google", error)
        //this.authService.mandarEmailError("Error al registrarse con Google", JSON.stringify(error))
        this.error = error.message

      })
  }

  cambiaEsUsuario(es: boolean) {
    console.log("esUsuario ", es)
  }
  registrar(logGoogle: boolean) {
    this.submitted = true;
    this.error = null
    if (logGoogle) {
      this.authService.googleLogin(true)
        .then(r => {
          //this.routeToMyPopApp()
        })
        .catch(error => {
          console.log("Error al registrarse con Google", error)
          //this.authService.mandarEmailError("Error al registrarse con Google", JSON.stringify(error))
          this.error = error.message

        })
    } else {
      if (this.formComenzar.invalid) {
        this.error = "Datos inválidos";
        return;
      }
      if (this.f.email.value !== this.f.email2.value) {
        this.error = "Emails incorrectos";
        return;
      }
      //primero revisar si ya esta logueado con ese mail
      this.registrando = true
      this.authService.emailLogin(this.f.email.value, this.f.pass.value)
        .then(_ => {
          this.registrando = false
        })
        .catch(_ => {
          //sino intentar registrar
          this.authService.emailSignUp(this.f.email.value, this.f.pass.value)
            .then(_ => {
              //this.routeToMyPopApp()
              this.registrando = false
            })
            .catch(error => {
              this.registrando = false
              console.log("No se pudo crear el usuario con pass", error)
              this.error = error.message
              //this.authService.mandarEmailError("No se pudo crear el usuario con pass", JSON.stringify(error))

            })
        })
    }

  }

  routeToMyPopApp() {
    this.router.navigateByUrl('/mypopapp');

  }

  get f() { return this.formComenzar.controls; }


}
