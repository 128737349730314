import { Component, OnInit, OnDestroy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, InfoCuenta } from '../core/auth.service';
import { Subscription } from '../../../node_modules/rxjs';
import { LocalAdmin } from '../shared/admin/local-admin';
import { ServidorAdmin } from '../shared/admin/servidor-admin';
import { Router, ActivatedRoute } from '@angular/router';

import { Http, RequestOptionsArgs, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { CuentaService } from '../services/cuenta.service';

import * as consts from "../shared/constantes";
import { first } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { CuentaAdmin } from '../shared/admin/cuenta-admin';
import { SuscripcionMercadoPago } from '../shared/admin/suscripcion_mercado_pago';
import { environment } from 'src/environments/environment';

// const urlBilling = 'https://serverless.popapp.io/billing/new_payment/';
const urlBilling = 'http://localhost:5000/popappowner/us-central1/billing/new_payment/';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.scss']
})
export class MiCuentaComponent implements OnInit, OnDestroy {

  subscriptionCuenta: Subscription;
  subscriptionQueryParams: Subscription;
  idCuentaParam: string;
  loading: boolean = true;

  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;

  cuentaSubs: Subscription;

  errorMoneda: boolean;
  fechaUltimoPago: Date;
  idCuenta: string;
  ultimoPagoCuenta: string;
  localesADM: LocalAdmin[] = [];
  moneda: string;
  nombreCuenta: string;
  totalImportes: number = 0;
  totalImportes9x12: number = 0;
  vence: Date;
  cantMesesAPagar: number = 1;
  cantMesesString: string = ' 1 MES';
  cantMesesAPagar9x12: number = 10;
  pagaIva: number = 0;
  pagaIva9x12: number = 0;
  fechaProxVenc: Date;
  fechaProxAnioVenc: Date;
  pais: string;

  enviando: boolean = false;

  fechaActualServidor: string;
  fechaDateServ: Date;
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  totalFinal: number = 0;
  totalFinal9x12: number = 0;
  descuentoImportes: number = 0; // descuento de importes por cuotas pendientes
  descuentoImportes9x12: number = 0; // descuento de importes por cuotas pendientes
  descuentoIva: number = 0; // descuento de iva por cuotas pendientes
  descuentoIva9x12: number = 0; // descuento de iva por cuotas pendientes
  cotizacionUF: number;// = 39999;
  totalCotizacionUSDCLP: number;// = 39999;
  cuitORut: string;
  emailpago: string;
  totalCotizacionUSDARS: number;
  public debitoAutomatico: string = "Suscribirse a \n\n débito automático";
  linkSuscription: string | undefined;
  idSuscription: string | undefined;
  yaEstasSuscripto: boolean;
  dlocal: boolean;
  newBilling: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private db: AngularFireDatabase,
    private dbfs: AngularFirestore,
    public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public afAuth: AngularFireAuth,
    public snackBar: MatSnackBar, public authService: AuthService,
    public router: Router, private http: Http, public cuentaService: CuentaService,
    private fns: AngularFireFunctions,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  marca() {
    return consts.marca(this.infoCuenta);
  }

  ngOnInit() {

    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });
    this.loading = true;
    this.subscriptionQueryParams = this.activatedRoute.queryParams.subscribe(params => {
      this.idCuentaParam = params['i'];
      if (this.idCuentaParam) {
        this.leerFBCuenta(this.idCuentaParam);
      } else {
        this.subscriptionCuenta = this.authService.cuenta$
          .subscribe(cuenta => {
            if (cuenta) {
              let refMacP = this.authService.leerFB('macsPasadas/' + cuenta.id);
              refMacP.snapshotChanges().subscribe(action => {

                //consts.logD(action.type);
                //consts.logD(action.key);
                //consts.logD(action.payload.val());
                let macPasad = action.payload.val();
                let idCuenta = macPasad['idCuenta'];

                this.leerFBCuenta(idCuenta);
              });
            }
          });
      }
    });
  }

  calculaTotal() {
    this.montoCuotasNoPendientes();
    this.totalFinal = (this.totalImportes + this.pagaIva);
    this.totalFinal9x12 = (this.totalImportes9x12 + this.pagaIva9x12);
  }

  fechaActual(fecha: string) {
    const fdate = this.convierteFechaConBarrasAIso(fecha);
    if (fdate && fdate.getTime() < Date.now()) {
      return false;
    } else {
      return true;
    }
  }

  convierteFechaConBarrasAIso(fechaEn_dd_mm_YYYY: string): Date | undefined {
    if (fechaEn_dd_mm_YYYY) {
      const parts = fechaEn_dd_mm_YYYY.split('/');
      // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
      // January - 0, February - 1, etc.
      const mydate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
      return mydate;
    } else {
      return undefined;
    }
  }

  montoCuotasNoPendientes() {
    this.descuentoImportes = 0;
    this.descuentoImportes9x12 = 0;
    this.descuentoIva = 0;
    this.descuentoIva9x12 = 0;
    let servidor: any;
    this.localesADM.forEach(local => {
      for (let localKey in local.servidores) {
        servidor = local.servidores[localKey];

        if (servidor.cuotasPendientes && servidor.cuotasPendientes < this.cantMesesAPagar && servidor.cuotasPendientes > 0) {
          //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
          this.descuentoImportes = this.descuentoImportes + (this.cantMesesAPagar - servidor.cuotasPendientes) * servidor.importe;
          if (servidor.pagaIva) {
            this.descuentoIva = this.descuentoIva + (this.cantMesesAPagar - servidor.cuotasPendientes) * servidor.importe * servidor.pagaIva / 100;
          }
        } else {
          //cuando las cuotas pendientes son mas que los meses a pagar el calculo se hizo correctamente
          //por lo tanto solo se deben actualizar las cuotas pendientes al realizar el pago
        }

        if (servidor.cuotasPendientes && servidor.cuotasPendientes < this.cantMesesAPagar9x12 && servidor.cuotasPendientes > 0) {
          //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
          this.descuentoImportes9x12 = this.descuentoImportes9x12 + (this.cantMesesAPagar9x12 - servidor.cuotasPendientes) * servidor.importe;
          if (servidor.pagaIva) {
            this.descuentoIva9x12 = this.descuentoIva9x12 + (this.cantMesesAPagar9x12 - servidor.cuotasPendientes) * servidor.importe * servidor.pagaIva / 100;
          }
        } else {
          //cuando las cuotas pendientes son mas que los meses a pagar el calculo se hizo correctamente
          //por lo tanto solo se deben actualizar las cuotas pendientes al realizar el pago
        }

        /* CUANDO SE EFECTUE EL PAGO EJECUTAR ESTE CODIGO EN SERVIDOR MP
        
        mapUpdates = {};
          urlBase = "cuentas/" + servidor.idCuenta + "/locales/" + servidor.idLocal + "/servidores/";
          mapUpdates[urlBase + servidor.idMac + "/cuotasPendientes/"] = servidor.cuotasPendientes-this.cantMesesAPagar; 
          db.object("/").update(mapUpdates);*/
        let concepto: any;
        for (let c in servidor.conceptos) {
          concepto = servidor.conceptos[c];

          if (concepto.cuotasPendientes && concepto.cuotasPendientes < this.cantMesesAPagar && concepto.cuotasPendientes > 0) {
            //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
            this.descuentoImportes = this.descuentoImportes + (this.cantMesesAPagar - concepto.cuotasPendientes) * concepto.importe;
            if (concepto.iva) {
              this.descuentoIva = this.descuentoIva + (this.cantMesesAPagar - concepto.cuotasPendientes) * concepto.iva;
            }
          }

          if (concepto.cuotasPendientes && concepto.cuotasPendientes < this.cantMesesAPagar9x12 && concepto.cuotasPendientes > 0) {
            //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
            this.descuentoImportes9x12 = this.descuentoImportes9x12 + (this.cantMesesAPagar9x12 - concepto.cuotasPendientes) * concepto.importe;
            if (concepto.iva) {
              this.descuentoIva9x12 = this.descuentoIva9x12 + (this.cantMesesAPagar9x12 - concepto.cuotasPendientes) * concepto.iva;
            }
          }
        }
      }

    });
  }

  ngOnDestroy(): void {
    //this.ref.snapshotChanges().unsubscribe();
    // prevent memory leak when component is destroyed
    try { this.subscriptionCuenta.unsubscribe(); } catch (e) { }
    try { this.subscriptionQueryParams.unsubscribe(); } catch (e) { }
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }
    if (this.cuentaSubs) {
      try { this.cuentaSubs.unsubscribe() } catch (e) { }
    }
  }

  convierteStringADate(fechaEn_dd_mm_YYYY: string, separador: string): Date {
    if (fechaEn_dd_mm_YYYY && separador) {
      var parts = fechaEn_dd_mm_YYYY.split(separador);
      // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
      // January - 0, February - 1, etc.
      var mydate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
      return mydate;
    } else {
      return null;
    }
  }

  restaOSuma(resta: boolean) {
    if (resta && this.cantMesesAPagar > 1) {
      this.cantMesesAPagar--;

    } else if (this.cantMesesAPagar < 10) {
      this.cantMesesAPagar++;
    }

    this.cantMesesString = this.cantMesesAPagar === 1 ? (' 1 MES') : (this.cantMesesAPagar + ' MESES');

    this.fechaProxVenc = new Date(this.vence.getFullYear(), this.vence.getMonth() + this.cantMesesAPagar, this.vence.getDate());

  }

  getMyStyles(vence: Date) {
    let today = new Date();
    let anios: number = vence.getFullYear() - today.getFullYear();
    let meses: number = vence.getMonth() - today.getMonth();
    let diasResta: number = vence.getDate() - today.getDate();

    let dias: number = anios * 365 + meses * 30 + diasResta * 1;
    let color = 'green';
    if (dias < 10 && dias > 0) {
      color = 'orange';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': color,
      // 'background': color,
      // 'padding': '10px'
    };
    return myStyles;
  }

  idMPDeliGuru = "251579662";
  marcaMPDeliGuru = "Deli Gurú";

  async clickPagar9x12() {
    let email;
    let cuitORut = this.cuitDniORut();
    if (cuitORut === "CUIT") {
      if (!this.emailpago || this.emailpago.length <= 5 ||
        !this.cuitORut || this.cuitORut.length <= 5) {
        let si = confirm("Los datos de facturación no están completos, la factura no se emitirá a tu nombre, continuar?");
        if (si) {
          email = this.emailpago;
        } else {
          return;
        }
      }
    } else if (cuitORut === "RUT") {
      if (!this.cuitORut || this.cuitORut.length <= 5) {
        let si = confirm("Los datos de facturación no están completos, la factura no se emitirá a tu nombre, continuar?");
        if (!si) {
          return;
        }
      }
    }

    if (!email) {
      if (this.afAuth && this.afAuth.user) {
        let u = await this.afAuth.user.pipe(first()).toPromise();
        // console.log(u);
        if (u && u.email) {
          email = u.email;
        }
      }
      if (!email) {
        email = "p@p.c";
      }
    }


    if (!email) {
      this.snackBar.open("Ingresa un email válido", "", {
        duration: 3000,
      });
      return;

    } else if (!email.includes("@") || !email.includes(".")) {
      this.snackBar.open("Ingresa un email válido", "", {
        duration: 3000,
      });
      return;
    }


    let idMP: string;
    if (!this.pais) {
      this.pais = "ARG";
    }
    let mostrarSoloPayPal = false;
    if (this.pais === "ARG" || this.pais === "AR") {
      if (this.marca() === this.marcaMPDeliGuru) {
        idMP = this.idMPDeliGuru;
      } else {
        idMP = "1392845136";
      }
    } else if (this.pais == "CHI" || this.pais == "CL") {
      idMP = "349794797";
    } else if (this.pais == "PER" || this.pais == "PE") {
      mostrarSoloPayPal = true;
    } else if (this.pais == "ARGPRUEBA") {
      idMP = "350425595";
    } else if (this.pais == "CHIPRUEBA") {
      idMP = "350419662";
    } else {
      mostrarSoloPayPal = true;
    }

    if (mostrarSoloPayPal) {
      let valor = prompt("Te enviaremos una solicitud de pago de PayPal a tu email\nTu email:", email);
      if (valor != null && valor != "") {
        //this.enviarEmailPayPal(); con cant de meses prox fecha, importe mensual y total y moneda
        //let urlServidorMail = "environment.urls.escuchador/payments/mails/paypal";

        let cuerpoDeMail = "email: " + valor + "\n" + this.cantMesesAPagar9x12 + " meses\n" +
          this.moneda + " " + (this.totalImportes9x12 * this.cantMesesAPagar9x12 - this.descuentoImportes9x12);
        if (this.pagaIva9x12 && this.pagaIva9x12 > 0) {
          cuerpoDeMail += " +" + this.pagaIva9x12 + "% IVA " + (this.cantMesesAPagar9x12 * this.pagaIva9x12 - this.descuentoIva9x12) +
            " = " + this.moneda + " " + (this.totalFinal9x12 * this.cantMesesAPagar9x12 - this.descuentoImportes9x12 - this.descuentoIva9x12);
        }

        if (this.cantMesesAPagar9x12 == 10) {
          cuerpoDeMail += "\nProx. Vencimiento: " + this.fechaProxAnioVenc.getDate() + "/" + this.fechaProxAnioVenc.getMonth() + 1 + "/" + this.fechaProxAnioVenc.getFullYear();
        } else {
          cuerpoDeMail += "\nProx. Vencimiento: " + this.fechaProxVenc.getDate() + "/" + this.fechaProxVenc.getMonth() + 1 + "/" + this.fechaProxVenc.getFullYear();
        }

        cuerpoDeMail += "\n" + this.nombreCuenta + " (" + this.idCuenta + ")";

        let body = {
          notification: {
            title: "popapp",
            to: "facundo@popapp.io, pmarin@popapp.io",
            //copia: "gmarin@popapp.io",
            asunto: "Solicitud Botón de Pago PayPal " + this.nombreCuenta,
            text: cuerpoDeMail
          }
        }
        this.cuentaService.enviarEmailAFSProm(body, "paypal");

        //this.enviaMail(body, urlServidorMail);
      }
    } else {
      const base = environment.urls.escuchador + "/";
      // const base = "http://localhost:3000/";
      let urlAPagar = base + "mporden/" + idMP + "/pos/" + this.idCuenta;
      //let urlAPagar = "http://localhost:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
      //let urlAPagar = "http://192.168.10.113:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
      let body: any = { items: [], email_payer: '' };

      let totalMensual: number = this.totalFinal9x12 - this.descuentoImportes9x12 / this.cantMesesAPagar9x12 - this.descuentoIva9x12 / this.cantMesesAPagar9x12;

      if (this.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
        let up = totalMensual * this.cotizacionUF;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": up,
          "quantity": this.cantMesesAPagar9x12
        };
      } else if (this.moneda === "USD" && this.pais === "ARG" && this.totalCotizacionUSDARS) {
        if (this.dlocal) {
          urlAPagar = urlBilling + this.idCuenta;
          let up = totalMensual * this.totalCotizacionUSDARS;
          up = +(up.toFixed());
          body.country = "AR";
          body.accountId = this.idCuenta;
          // body.currency_original = "USD";
          // body.currency_amount = "ARS";
          body.tax = 0;
          body.amount = (up * this.cantMesesAPagar9x12);
          body.months = this.cantMesesAPagar9x12;
          body.date = this.fechaProxAnioVenc.toISOString();
          body.platform = 'DLOCAL'
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          body.payer = {
            email: email,
            //TODO: si esta el cuit ponerlo
          }
          body.currencyPrice = this.totalCotizacionUSDARS;
          body.description = 'Pago licencia Popapp ' + this.cantMesesAPagar9x12 + ' ' + (this.cantMesesAPagar9x12 === 1 ? 'mes.' : 'meses.');
          this.authService.setDataAPagar(urlAPagar, body, this.idCuenta, this.ultimoPagoCuenta, this.moneda, this.pais);
          if (this.idCuentaParam) {
            this.router.navigateByUrl('/pagodlocal');
          } else {
            this.router.navigateByUrl('/mypopapp/micuenta/pagardlocal');
          }
          return;
        }
        let up = totalMensual * this.totalCotizacionUSDARS;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": up,
          "quantity": this.cantMesesAPagar9x12
        };
      } else if (this.moneda === "USD" && this.pais === "CHI" && this.totalCotizacionUSDCLP) {
        let up = totalMensual * this.totalCotizacionUSDCLP;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": up,
          "quantity": this.cantMesesAPagar9x12
        };
        body.items[0].cotiz = this.totalCotizacionUSDCLP;

      } else {
        let up = totalMensual;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": totalMensual,
          "quantity": this.cantMesesAPagar9x12
        };
      }

      if (this.moneda && this.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
        body.items[0].cotiz = this.cotizacionUF;
      } else if (this.moneda && this.moneda === "USD" && this.pais && this.pais === "ARG" && this.totalCotizacionUSDARS) {
        body.items[0].cotiz = this.totalCotizacionUSDARS;
      }
      body.email_payer = email;

      this.authService.setDataAPagar(urlAPagar, body, this.idCuenta, this.ultimoPagoCuenta, this.moneda, this.pais);
      if (this.idCuentaParam) {
        this.router.navigateByUrl('/pago');

      } else {
        this.router.navigateByUrl('/mypopapp/micuenta/pagar');
      }
    }
  }
  async clickPagar() {
    let email;
    let cuitORut = this.cuitDniORut();
    if (cuitORut === "CUIT") {
      if (!this.emailpago || this.emailpago.length <= 5 ||
        !this.cuitORut || this.cuitORut.length <= 5) {
        let si = confirm("Los datos de facturación no están completos, la factura no se emitirá a tu nombre, continuar?");
        if (si) {
          email = this.emailpago;
        } else {
          return;
        }
      }
    } else if (cuitORut === "RUT") {
      if (!this.cuitORut || this.cuitORut.length <= 5) {
        let si = confirm("Los datos de facturación no están completos, la factura no se emitirá a tu nombre, continuar?");
        if (!si) {
          return;
        }
      }
    }

    if (!email) {
      if (this.afAuth && this.afAuth.user) {
        let u = await this.afAuth.user.pipe(first()).toPromise();
        // console.log(u);
        if (u && u.email) {
          email = u.email;
        }
      }
      if (!email) {
        email = "p@p.c";
      }
    }


    if (!email) {
      this.snackBar.open("Ingresa un email válido", "", {
        duration: 3000,
      });
      return;

    } else if (!email.includes("@") || !email.includes(".")) {
      this.snackBar.open("Ingresa un email válido", "", {
        duration: 3000,
      });
      return;
    }


    let idMP: string;
    if (!this.pais) {
      this.pais = "ARG";
    }
    let mostrarSoloPayPal = false;
    if (this.pais === "ARG" || this.pais === "AR") {
      if (this.marca() === this.marcaMPDeliGuru) {
        idMP = this.idMPDeliGuru;
      } else {
        idMP = "1392845136";
      }
    } else if (this.pais == "CHI" || this.pais == "CL") {
      idMP = "349794797";
    } else if (this.pais == "PER" || this.pais == "PE") {
      mostrarSoloPayPal = true;
    } else if (this.pais == "ARGPRUEBA") {
      idMP = "350425595";
    } else if (this.pais == "CHIPRUEBA") {
      idMP = "350419662";
    } else {
      mostrarSoloPayPal = true;
    }

    if (mostrarSoloPayPal) {
      let valor = prompt("Te enviaremos una solicitud de pago de PayPal a tu email\nTu email:", email);
      if (valor != null && valor != "") {
        //this.enviarEmailPayPal(); con cant de meses prox fecha, importe mensual y total y moneda
        //let urlServidorMail = "environment.urls.escuchador/payments/mails/paypal";

        let cuerpoDeMail = "email: " + valor + "\n" + this.cantMesesAPagar + " meses\n" +
          this.moneda + " " + (this.totalImportes * this.cantMesesAPagar - this.descuentoImportes);
        if (this.pagaIva && this.pagaIva > 0) {
          cuerpoDeMail += " +" + this.pagaIva + "% IVA " + (this.cantMesesAPagar * this.pagaIva - this.descuentoIva) +
            " = " + this.moneda + " " + (this.totalFinal * this.cantMesesAPagar - this.descuentoImportes - this.descuentoIva);
        }

        if (this.cantMesesAPagar == 10) {
          cuerpoDeMail += "\nProx. Vencimiento: " + this.fechaProxAnioVenc.getDate() + "/" + this.fechaProxAnioVenc.getMonth() + 1 + "/" + this.fechaProxAnioVenc.getFullYear();
        } else {
          cuerpoDeMail += "\nProx. Vencimiento: " + this.fechaProxVenc.getDate() + "/" + this.fechaProxVenc.getMonth() + 1 + "/" + this.fechaProxVenc.getFullYear();
        }

        cuerpoDeMail += "\n" + this.nombreCuenta + " (" + this.idCuenta + ")";

        let body = {
          notification: {
            title: "popapp",
            to: "facundo@popapp.io, pmarin@popapp.io",
            //copia: "gmarin@popapp.io",
            asunto: "Solicitud Botón de Pago PayPal " + this.nombreCuenta,
            text: cuerpoDeMail
          }
        }
        this.cuentaService.enviarEmailAFSProm(body, "paypal");

        //this.enviaMail(body, urlServidorMail);
      }
    } else {
      const base = environment.urls.escuchador + "/";
      // const base = "http://localhost:3000/";
      let urlAPagar = base + "mporden/" + idMP + "/pos/" + this.idCuenta;
      //let urlAPagar = "http://localhost:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
      //let urlAPagar = "http://192.168.10.113:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
      let body: any = { items: [], email_payer: '' };

      let totalMensual: number = this.totalFinal - this.descuentoImportes / this.cantMesesAPagar - this.descuentoIva / this.cantMesesAPagar;


      if (this.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
        let up = totalMensual * this.cotizacionUF;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": up,
          "quantity": this.cantMesesAPagar
        };
      } else if (this.moneda === "USD" && this.pais === "ARG" && this.totalCotizacionUSDARS) {
        if (this.dlocal) {
          urlAPagar = urlBilling + this.idCuenta;
          let up = totalMensual * this.totalCotizacionUSDARS;
          up = +(up.toFixed());
          body.country = "AR";
          body.accountId = this.idCuenta;
          // body.currency_original = "USD";
          // body.currency_amount = "ARS";

          body.tax = 0;
          body.amount = (up * this.cantMesesAPagar);
          body.months = this.cantMesesAPagar;
          body.date = this.fechaProxVenc.toISOString();
          body.platform = 'DLOCAL'
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          // TODO: testear y ajustar esto
          body.payer = {
            email: email,
            // TODO: si esta el cuit ponerlo
          }
          body.currencyPrice = this.totalCotizacionUSDARS;
          // body.total_amount = (up * this.cantMesesAPagar);
          body.description = 'Pago licencia Popapp ' + this.cantMesesAPagar + ' ' + (this.cantMesesAPagar === 1 ? 'mes.' : 'meses.');
          this.authService.setDataAPagar(urlAPagar, body, this.idCuenta, this.ultimoPagoCuenta, this.moneda, this.pais);
          if (this.idCuentaParam) {
            this.router.navigateByUrl('/pagodlocal');
          } else {
            this.router.navigateByUrl('/mypopapp/micuenta/pagardlocal');
          }
          return;
        }
        let up = totalMensual * this.totalCotizacionUSDARS;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": up,
          "quantity": this.cantMesesAPagar
        };
      } else if (this.moneda === "USD" && this.pais === "CHI" && this.totalCotizacionUSDCLP) {
        let up = totalMensual * this.totalCotizacionUSDCLP;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": up,
          "quantity": this.cantMesesAPagar
        };
        body.items[0].cotiz = this.totalCotizacionUSDCLP;

      } else {
        let up = totalMensual;
        up = +(up.toFixed());
        body.items[0] = {
          "currency_id": this.moneda,
          "unit_price": totalMensual,
          "quantity": this.cantMesesAPagar
        };
      }

      if (this.moneda && this.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
        body.items[0].cotiz = this.cotizacionUF;
      } else if (this.moneda && this.moneda === "USD" && this.pais && this.pais === "ARG" && this.totalCotizacionUSDARS) {
        body.items[0].cotiz = this.totalCotizacionUSDARS;
      }
      body.email_payer = email;

      this.authService.setDataAPagar(urlAPagar, body, this.idCuenta, this.ultimoPagoCuenta, this.moneda, this.pais);
      if (this.idCuentaParam) {
        this.router.navigateByUrl('/pago');

      } else {
        this.router.navigateByUrl('/mypopapp/micuenta/pagar');
      }
    }
  }

  async abm(valor) {
    let msg;
    let valorAnterior;

    if (valor === "emailpago") {
      msg = "Email donde recibirás la factura";
      valorAnterior = this.emailpago ? this.emailpago : '';
    } else if (valor === "cuitORut") {
      valorAnterior = this.cuitORut ? this.cuitORut : '';

      let cuitORut = this.cuitDniORut();
      if (cuitORut === "CUIT") {
        msg = "CUIT receptor de la factura";
      } else if (cuitORut === "RUT") {
        msg = "RUT receptor de la factura";
      } else {
        return;
      }
    } else {
      return;
    }

    let nuevoValor = "" + prompt(msg, valorAnterior);
    nuevoValor = nuevoValor.trim();
    if (valor === "cuitORut" && nuevoValor && this.cuitDniORut() === "CUIT" && (nuevoValor.length < 10 || nuevoValor.length > 11)) {
      if (nuevoValor.length < 10) {
        this.snackBar.open("CUIT muy corto", "Ingrese dígitos sin guiones", { duration: 2000 });
        return;
      } else if (nuevoValor.length > 11) {
        this.snackBar.open("CUIT muy largo", "Ingrese dígitos sin guiones", { duration: 2000 });
        return;
      }
    }
    if (nuevoValor && nuevoValor.length > 5) {
      // console.log(nuevoValor);
      await this.db.object("cuentas/" + this.idCuenta + "/" + valor).set(nuevoValor);
      const mapFS = {};
      if (valor === 'cuitORut' && valorAnterior !== '' && this.cuitDniORut() === "CUIT") {
        mapFS['idClienteContabilium'] = null;
        mapFS['responsabilidad'] = null;
      }
      mapFS[valor] = nuevoValor;
      console.log(mapFS);
      await this.dbfs.doc("cuentas/" + this.idCuenta).set(mapFS, { merge: true });
      this.snackBar.open(msg + " Actualizado", "", { duration: 2000 });
    } else {
      this.snackBar.open(msg + " muy corto", "", { duration: 2000 });
    }
  }

  leerFBCuenta(idCuenta: string) {
    if (idCuenta && idCuenta.length > 3) {
      let refCuenta = this.authService.leerFB('cuentas/' + idCuenta);
      this.cuentaSubs = refCuenta.snapshotChanges().subscribe(async action => {

        //consts.logD(action.type);
        //consts.logD(action.key);
        //consts.logD(action.payload.val());

        let cuentaAdmin: CuentaAdmin = <CuentaAdmin>action.payload.val();

        if (cuentaAdmin.newBilling) {
          this.newBilling = true;
          window.location.href = `${environment.urls.popappWeb}/billing/${idCuenta}`;
        }

        this.dlocal = cuentaAdmin.dlocal;
        let locales = cuentaAdmin['locales'];
        this.ultimoPagoCuenta = cuentaAdmin['ultimoPago'];
        this.cuitORut = cuentaAdmin['cuitORut'];
        this.emailpago = cuentaAdmin['emailpago'];
        this.localesADM = [];
        this.moneda = "";
        this.errorMoneda = false;
        this.totalImportes = 0;
        this.totalImportes9x12 = 0;
        this.pagaIva = 0;
        this.pagaIva9x12 = 0;

        if (cuentaAdmin.linkSuscription && cuentaAdmin.idSuscription) {
          this.linkSuscription = cuentaAdmin.linkSuscription;
          this.idSuscription = cuentaAdmin.idSuscription;

          try {
            const suscripcion = await this.leerSuscripcion(idCuenta, cuentaAdmin.idSuscription);
            if (suscripcion && suscripcion.status === "authorized") {
              this.yaEstasSuscripto = true;
            } else {
              const fdate = this.getPayDay(cuentaAdmin);
              if (fdate && fdate.getTime() < Date.now()) {
                this.linkSuscription = undefined;
              }

            }
          } catch (e) {
            console.log("Error", e);
            const fdate = this.getPayDay(cuentaAdmin);
            if (fdate && fdate.getTime() < Date.now()) {
              this.linkSuscription = undefined;
            }

          }


        } else {
          this.linkSuscription = undefined;
          this.idSuscription = undefined;
        }
        this.loading = false;


        this.fechaUltimoPago = new Date(1900, 1, 13);
        this.vence = new Date(2200, 1, 13);;

        for (let keyLocal in locales) {
          let local: LocalAdmin = locales[keyLocal];
          let servidoresADM: ServidorAdmin[] = [];
          local.totalImpServidores = 0;
          local.iva = 0;
          for (let keyServ in local.servidores) {
            let servidor: ServidorAdmin = local.servidores[keyServ];
            servidor.idLocal = keyLocal;
            servidor.idCuenta = idCuenta;
            if (servidor.showPagar && servidor.numeroLocal && +servidor.numeroLocal > 0 && servidor.importe && servidor.moneda && servidor.pagoHasta && (servidor.cuotasPendientes == null || servidor.cuotasPendientes > 0)) {
              if (servidor.importeQDeberia && servidor.importeQDeberia < servidor.importe) {
                servidor.importeQDeberia = servidor.importe;
              }
              servidoresADM.push(servidor);
              if (servidor.tag && servidor.tag.includes("planal1D")) {
                this.cantMesesAPagar = 2;
                this.cantMesesAPagar9x12 = 10;
              }

              this.pais = servidor.pais;
              local.totalImpServidores += servidor.importe;
              if (servidor.pagaIva) {
                let iva = servidor.importe * servidor.pagaIva / 100;
                this.pagaIva += iva;
                this.pagaIva9x12 += iva;
                local.iva += iva;
              }
              if (this.moneda == "") {
                this.moneda = servidor.moneda;
              } else if ((this.moneda !== servidor.moneda) && !this.errorMoneda) {
                this.errorMoneda = true;
                this.moneda = "Monedas Distintas (" + this.moneda + " " + servidor.moneda + " )";
              }

              this.cuentaService.actualizaPagoHastaSiEsLeadOClienteViejo(servidor.cuotasPendientes, servidor.primerPago, servidor.pagoHasta, idCuenta, keyLocal, keyServ);

              let venceDate = this.convierteStringADate(servidor.pagoHasta, "/");
              if (venceDate.getTime() < this.vence.getTime()) {
                this.vence = new Date(venceDate);
                venceDate.setMonth(venceDate.getMonth() + this.cantMesesAPagar);
                this.fechaProxVenc = new Date(venceDate);
                venceDate = new Date(this.vence);
                venceDate.setFullYear(venceDate.getFullYear() + 1);
                this.fechaProxAnioVenc = new Date(venceDate);
              }
              if (servidor.fechaUltimoPago) {

                let dateUltimoPago = this.convierteStringADate(servidor.fechaUltimoPago, "/");
                if (dateUltimoPago.getTime() > this.fechaUltimoPago.getTime()) {
                  this.fechaUltimoPago = dateUltimoPago;
                }
              }

            }
          }
          if (servidoresADM.length > 0) {
            local.servidores = servidoresADM;
            this.localesADM.push(local);
            this.totalImportes += local.totalImpServidores;
            this.totalImportes9x12 += local.totalImpServidores;
          }
        }
        if (this.moneda && this.moneda === 'UF') {
          this.leerCotizacionUF();
        } else if (this.moneda && this.moneda === "USD" && this.pais && this.pais === "ARG") {
          const callable = this.fns.httpsCallable("cotizacionUSDARS");
          callable({}).pipe(first()).subscribe(v => {
            this.totalCotizacionUSDARS = v;
            this.calculaTotal();
          });
        } else if (this.moneda && this.moneda === "USD" && this.pais && this.pais === "CHI") {
          await this.leerCotizacionUSDCLP()
        }
        this.nombreCuenta = cuentaAdmin['nombreCuenta'];
        this.idCuenta = idCuenta;



      });
    } else {
      this.loading = false;
      this.snackBar.open("No se pudo leer la cuenta, reintenta más tarde", "", { duration: 4000 });
    }
  }


  cuitDniORut() {
    if (this.moneda) {
      if (this.moneda === "ARS" || this.moneda === "ARS $" || (this.moneda === "USD" && this.pais === "ARG")) {
        return "CUIT";
      } else if (this.moneda === "CLP" || this.moneda === "CLP $" || this.moneda === 'UF' || (this.moneda === "USD" && this.pais === "CHI")) {
        return "";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  leerSuscripcion(idCuenta: string, idSuscription: string): Promise<SuscripcionMercadoPago> {
    let url = environment.urls.functions + "/mercadopago/suscripcion/";
    // let url = "http://localhost:5000/popappowner/us-central1/mercadopago/suscripcion/";
    url += idCuenta + "/" + idSuscription;
    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        return body;
      })
  }

  leerCotizacionUF() {
    // const url = "https://mindicador.cl/api/uf/";
    const url = environment.urls.functions + "/cotizacion/cotizacion/UF/CLP";
    // const url = "http://localhost:5000/popappowner/us-central1/cotizacion/cotizacion/UF/CLP";

    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.serie && body.serie[0] && body.serie[0].valor) {
          this.cotizacionUF = Math.floor(body.serie[0].valor);
        } else if (body && body.data && body.data.cotizacion) {
          this.cotizacionUF = body.data.cotizacion;
        }
      })
      .catch(this.handleErrorObservable);

  }

  leerCotizacionUSDCLP() {
    const url = environment.urls.functions + "/cotizacion/cotizacion/USD/CLP";
    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.data && body.data.cotizacion) {
          this.totalCotizacionUSDCLP = body.data.cotizacion;
        }
      })
      .catch(this.handleErrorObservable);

  }

  enviaMail(body: any, url: string): void {
    this.enviando = true;

    this.httpPostEnviarMailPaypal(body, url)
      .then(respuesta => {
        //console.log("Respuestaaa", respuesta);
        //exito;
        this.enviando = false;
        this.snackBar.open("En breve llegará el email con el botón de pago", "", { duration: 4000 });
      },
        error => {
          this.enviando = false;
          this.snackBar.open("No se pudo enviar el email", "", { duration: 4000 });
        }
      );
  }

  httpPostEnviarMailPaypal(body: any, url: string): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    return this.http.post(url, body, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorObservable);
  }
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }


  getPayDay(cuenta: CuentaAdmin): Date | undefined {
    let fechaAux: Date | undefined = undefined;
    for (let idLocal in cuenta.locales) {
      let local: LocalAdmin = cuenta.locales[idLocal];
      if (this.esUnLocalActivo(local)) {
        for (let idServidor in local.servidores) {
          let servidor: ServidorAdmin = local.servidores[idServidor];
          if (this.esUnManagerActivo(servidor)) {
            if (servidor.pagoHasta) {
              if (!fechaAux) {
                fechaAux = this.convierteFechaConBarrasAIso(servidor.pagoHasta)
              } else {
                let payDay: Date | undefined = this.convierteFechaConBarrasAIso(servidor.pagoHasta)
                if (payDay && fechaAux.getTime() < payDay.getTime()) {
                  fechaAux = payDay;
                }
              }
            }
          }
        }
      }
    }
    return fechaAux;
  }

  esUnManagerActivo(s: ServidorAdmin): boolean {

    if (s.nombreServidor &&
      s.numeroLocal && 0 < +s.numeroLocal &&
      +s.numeroLocal < 9000 &&
      +s.numeroLocal !== 900
      && s.nombreServidor !== 'Costo alta de licencia') {
      return true;
    } else {
      return false;
    }
  }

  esUnLocalActivo(local): boolean {

    for (let idMac in local.servidores) {
      const servidorLocal = local.servidores[idMac];

      if (idMac && this.esUnManagerActivo(servidorLocal)) {
        return true;
      }
    }
    return false;
  }


}
