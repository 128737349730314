import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'select-form',
  templateUrl: './select-form.component.html',
  styleUrls: ['./select-form.component.scss']
})
export class SelectFormComponent implements OnInit {
  
  @Input() form;
  @Input() id: string;
  @Input() msgErrorValidator: string;
  @Input() label: string;
  @Input() options: {value: unknown, label: string}[];
  @Input() default;
  @Input() ngSelect;
  @Input() valueField;
  @Input() labelField;

  constructor() { }
  
  ngOnInit(): void {
  }

}
