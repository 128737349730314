import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../core/auth.service';
import { Cuenta } from '../shared/cuenta';
import { Resto } from '../shared/resto';
import { LineChartConfig } from '../charts/linechartconfig';
import { Subscription } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.scss']
})
export class VentasComponent implements OnInit, OnDestroy {
  cuentas: Cuenta[] = [];
  restos: Map<string, Resto>;
  data3: any[];
  config3: LineChartConfig;
  elementId3: string;

  data4: any[];
  config4: LineChartConfig;
  elementId4: string;

  subscriptionCuentas: Subscription;
  mobileQuery: MediaQueryList;

  suscripition;
  ref;
  private _mobileQueryListener: () => void;

  constructor(public authService: AuthService, public afAuth: AngularFireAuth, public snackBar: MatSnackBar, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    //this.ref.snapshotChanges().unsubscribe();
    // prevent memory leak when component is destroyed
    this.subscriptionCuentas.unsubscribe();
  }

  ngOnInit() {
    this.subscriptionCuentas = this.authService.cuentas$.subscribe(arreglo => {
      this.cuentas = arreglo;
      if (this.cuentas && this.cuentas.length > 0) {
        this.restos = new Map<string, Resto>();
        this.leerDatosResto(this.cuentas, 0);
      }
    });

  }

  leerDatosResto(cuentas: Cuenta[], i: number) {
    if (cuentas && cuentas.length > 0) {
      this.ref = this.authService.leerFB("restos/" + cuentas[i].id);
      this.suscripition = this.ref.snapshotChanges().subscribe(action => {
        //consts.logD(action.type);
        //consts.logD(action.payload.val());
        let resto = <Resto>action.payload.val();
        if (resto) {
          this.restos.set(cuentas[i].id, resto);
        } else {
          console.log("resto null", cuentas[i].name + " " + cuentas[i].id)
          //esto deberia generar un email de reporte de error
        }
        this.suscripition.unsubscribe();
        this.suscripition = null;
        this.ref = null;
        if (i < (cuentas.length - 1)) {
          i++;
          this.leerDatosResto(cuentas, i);
        } else {
          this.dibujaCharts();
        }
      });
    }
  }

  dibujaCharts() {
    if (this.cuentas && this.restos) {//&& this.cuentas.length == this.restos.size) {
      //solo aca dibujamos pues ya leimos todos los restos

      //Line Chart Ventas
      this.data3 = [];
      this.data3.push(['Días', 'Ventas - Descuentos']);


      let arreDeDias: string[][] = [,];
      let ind = 0;
      let maxInd = 0;
      this.restos.forEach((re: Resto, key: string) => {
        for (let i = 0; i < re.ventasDescr.length; i++) {
          if (!arreDeDias[ind]) {
            arreDeDias[ind] = [];
          }
          arreDeDias[ind].push(re.ventasDescr[i]);
        }
        maxInd = arreDeDias[ind].length;
        ind++;
      });
      let mapaDias: Map<string, boolean> = new Map<string, boolean>();
      //ahora armamos los dias en orden
      if (arreDeDias.length > 0) {
        for (let i = 0; i < maxInd; i++) {
          let j = 0;
          while (arreDeDias.length > j) {
            if (arreDeDias[j].length > i) {
              mapaDias.set(arreDeDias[j][i], true);
            }
            j++;
          }
        }
      }

      //arma acumulador
      let mapAcumulador: Map<string, number> = new Map<string, number>();

      this.restos.forEach((re: Resto, key: string) => {
        for (let i = 0; i < re.ventasDescr.length; i++) {
          let valor = re.ventasDia[i];
          if (mapAcumulador.get(re.ventasDescr[i])) {
            valor += mapAcumulador.get(re.ventasDescr[i]);
          }
          mapAcumulador.set(re.ventasDescr[i], valor);
        }
      });

      mapaDias.forEach((nada: boolean, dia: string) => {
        let valor: number = 0;
        if (mapAcumulador.get(dia)) {
          valor = mapAcumulador.get(dia);
        }
        this.data3.push([dia, valor]);
      });

      this.config3 = new LineChartConfig('Ventas Acumuladas', '');
      this.elementId3 = 'myPieChart3';


      this.dibujaChartComparativo(mapaDias);
    }
  }

  buscaNombre(key): string {
    for (let i = 0; i < this.cuentas.length; i++) {
      if (key === this.cuentas[i].id) {
        return this.cuentas[i].name;
      }
    }
    return null;
  }


  dibujaChartComparativo(mapaDias: Map<string, boolean>) {
    this.data4 = [];

    //arma comparador
    let lineasDeResto: string[] = ['Días'];

    this.restos.forEach((re: Resto, key: string) => {
      lineasDeResto.push(this.buscaNombre(key));

    });
    this.data4.push(lineasDeResto);

    mapaDias.forEach((nada: boolean, dia: string) => {
      let valores: any[] = [dia];
      this.restos.forEach((re: Resto, key: string) => {
        let index = re.ventasDescr.indexOf(dia);
        let valor = 0;
        while (index != -1) {
          valor += re.ventasDia[index];
          re.ventasDescr[index] = "asd";
          index = re.ventasDescr.indexOf(dia);
        }
        valores.push(valor);
      });
      this.data4.push(valores);
    });


    this.config4 = new LineChartConfig('Ventas comparativas', '');
    this.elementId4 = 'myPieChart4';
  }

}
