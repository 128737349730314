export class PieChartConfig {
    title: string;
    pieHole: number;
    colors: string[];

    constructor(title: string, pieHole: number, colors?: string[]) {
        this.title = title;
        this.pieHole = pieHole;
        if (colors) {
            this.colors = colors;
        }
    }
}