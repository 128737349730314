import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';



const PARmp = 'PARmp/';
const mpPAR = 'mpPAR/';
const integracionmp = 'integracionmp/';


export interface MercadoPago {
  token: string;
  idMP: string;
  servidor: string;
  email: string;
  posId: string;
}


@Component({
  selector: 'app-modal-integrar-mp',
  templateUrl: './modal-integrar-mp.component.html',
  styleUrls: ['./modal-integrar-mp.component.scss']
})
export class ModalIntegrarMpComponent implements OnInit {


  confMercadoPago: MercadoPago;
  s: ServidorAdmin;
  form: FormGroup;
  submitted: boolean = false;
  error: string;
  loading: boolean;
  PARmp: { idmp: number, posid: string };
  mpPAR: { mail: string, mails: string[], nombre: string, tok: string };

  buscoMP: boolean = false;
  posIdProhibidos: string[] = [];
  errorPosId: string;
  macsData: any;

  constructor(
    public dialogRef: MatDialogRef<ModalIntegrarMpComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin },
    private db: AngularFireDatabase,
    private dbFs: AngularFirestore,
    public _snackBar: MatSnackBar,
    private formBuilder: FormBuilder) {

    this.s = this.data.s;

  }


  async ngOnInit() {
    this.loading = true;

    this.initForm();

    //await this.db.list('PARmpprueba/' + this.s.idMac);
    await this.db.object(PARmp + this.s.idMac).query.once('value').then(v => {
      if (v && v.val()) {
        this.PARmp = v.val();
      }
    })

    if (this.s.mercadopago) {
      await this.buscarIdMP(this.s.mercadopago);

    }

    let v = await this.leerConfMp(this.s);

    this.confMercadoPago = v;
    this.initForm();


    this.loading = false;
  }
  cambiaIdMp(valor: string) {
    this.buscoMP = false;
  }

  async buscarIdMP(idMp: string) {
    const v = await this.db.object(mpPAR + idMp).query.once('value');
    if (v && v.val()) {
      this.mpPAR = v.val();
    }
    let consulta = await this.dbFs.doc(integracionmp + idMp).get().pipe(first()).toPromise();
    if (consulta && consulta.data()) {
      console.log("consulta", consulta.data());

      this.macsData = consulta.data();
      if (this.macsData.macs) {
        for (const mac in this.macsData.macs) {
          this.posIdProhibidos.push(this.macsData.macs[mac].posid);
        }
      }
    }

    this.buscoMP = true;
  }

  async leerConfMp(s: ServidorAdmin) {

    let confFact: MercadoPago = {
      token: '',
      idMP: '',
      servidor: s.nombreServidor,
      email: '',
      posId: '',
    };

    if (this.s.mercadopago) {
      confFact.token = this.mpPAR.tok
      confFact.idMP = this.s.mercadopago
      //confFact.servidor = this.mpPAR.nombre
      confFact.email = this.mpPAR.mail
      confFact.posId = this.PARmp.posid
    }

    return confFact;
  }

  initForm() {
    if (this.confMercadoPago) {

      this.form = this.formBuilder.group({
        idMP: [this.confMercadoPago.idMP ? this.confMercadoPago.idMP : '', [Validators.required]],
        token: [this.confMercadoPago.token ? this.confMercadoPago.token : '', [Validators.required]],
        servidor: [this.confMercadoPago.servidor ? this.confMercadoPago.servidor : '', [Validators.required]],
        email: [this.confMercadoPago.email ? this.confMercadoPago.email : '', [Validators.required]],
        posId: [this.confMercadoPago.posId ? this.confMercadoPago.posId : 'popapp', [Validators.required]],
      });
    } else {
      this.form = this.formBuilder.group({
        idMP: ['', [Validators.required]],
        token: ['', [Validators.required]],
        servidor: ['', [Validators.required]],
        email: ['', [Validators.required]],
        posId: ['popapp', [Validators.required]],
      });

    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  async guardarDatos() {
    console.log(this.s)
    if (!this.form.valid) {
      return;
    }

    const posId = this.form.value.posId.trim();
    if (posId && this.posIdProhibidos && this.posIdProhibidos.includes(posId)) {
      this.errorPosId = 'Este posid ya existe, debes elegir uno distinto';
      return;
    }


    try {

      const url = 'https://api.mercadopago.com/mpmobile/instore/merchant/qr/' +
        this.form.value.idMP.trim() +
        '/' +
        this.form.value.posId.trim() +
        '?access_token=' +
        this.form.value.token.trim();


      let mapUpdates: any = {};

      mapUpdates[PARmp + this.s.idMac + '/idmp'] = +(this.form.value.idMP.trim());
      mapUpdates[PARmp + this.s.idMac + '/posid'] = this.form.value.posId.trim();


      mapUpdates[mpPAR + this.form.value.idMP + '/mail'] = this.form.value.email.trim();
      mapUpdates[mpPAR + this.form.value.idMP + '/mails'] = [this.form.value.email.trim()];
      mapUpdates[mpPAR + this.form.value.idMP + '/nombre'] = this.form.value.servidor.trim();
      mapUpdates[mpPAR + this.form.value.idMP + '/tok'] = this.form.value.token.trim();

      mapUpdates['cuentas/' + this.s.idCuenta + '/locales/' + this.s.idLocal + '/servidores/' + this.s.idMac + '/mercadopago'] = this.form.value.idMP.trim();
      mapUpdates['cuentas/' + this.s.idCuenta + '/locales/' + this.s.idLocal + '/servidores/' + this.s.idMac + '/linkqrmp'] = url;


      await this.db.object('/').update(mapUpdates);

      if (!this.macsData) {
        this.macsData = { macs: {} };
      }
      this.macsData.macs[this.s.idMac] = { posid: this.form.value.posId.trim() };

      await this.dbFs.doc(integracionmp + this.form.value.idMP.trim()).ref.set(this.macsData, { merge: true });

      this.copiarAlPortapapeles(url);
      this.openSnackBar('Exito', '');
    } catch (e) {
      console.error('Error al integrar', e);
      this.openSnackBar('No se pudo grabar', 'Revisar consola');
    }
  }

  copiarAlPortapapeles(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this._snackBar.open("Link copiado al portapapeles", "")._dismissAfter(2000);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
