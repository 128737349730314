import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { RutaService } from '../services/ruta.service';

@Component({
  selector: 'app-mypopapp',
  templateUrl: './mypopapp.component.html',
  styleUrls: ['./mypopapp.component.scss']
})
export class MypopappComponent implements OnInit {

  ruta: string;

  loading = false;

  constructor(public auth: AuthService, private rutaService: RutaService) { }

  ngOnInit() {
    this.rutaService.ruta$.subscribe(r => {
      this.ruta = <string>r;
    })
  }

}
