import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { ModalTiendaComponent } from '../modal-tienda/modal-tienda.component';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { DbFSService } from 'src/app/services/db-fs.service';
import { Subscription } from 'rxjs';
import { ModalConfPaisOffsetComponent } from 'src/app/modal-conf-pais-offset/modal-conf-pais-offset.component';
import { AdminCuentaService } from 'src/app/services/admin-cuenta.service';


export class LocalOnline {
  nombre: string;
  _key: string;
  idCuenta: string;
  idLocal: string;
  idMac: string;
  ciudad?: string;
  direccion?: string;
  uids: string[];
  tiendasIds: string[];
  test?: boolean;
  desactivado?: boolean;
  globalConfigs: any;
  esHub?: boolean;
  keyHub?: string;
  moneda?: string;
  admin?: boolean;
  last?: string;
  offset?: number;
  estadoActualizacion?: string;
  cambioEstado?: boolean;
  fpayActivo?: boolean;
  imprimirAlConfirmar?: boolean;
  imprimirAutoAccept?: boolean;
  pais?: string;
  emitirBoletaAutoAccept?: boolean;
  imprimirBoletaAutomaticAccept?: boolean;
  sound?: boolean;
  soundLink?: string;
  soundName?: string;
  pedidosPorColumnaRider?: number;
  printingActiveSession?: string;
  tiempoProgramados?: number;
  tiempoSliderRider?: number;
  factElect?: {
    activado: boolean,
    proveedor: string,
    factCostoEnvio?: string[],
    factSinCostoEnvio?: string[],
    notaCredito: boolean,
    factura: boolean
  }
  urlFacturacion?: string;
  impreso?: number;
  syncManager?: boolean;

  constructor(local?: LocalOnline) {
    if (local) {
      for (let prop in local) {
        this[prop] = local[prop];
      }
    }
  }


  public facturarCostoEnvio(origenPedidoAFacturar: string): boolean {
    let facturarCostoEnvio = true;
    if (this.factElect && this.factElect.factSinCostoEnvio && this.factElect.factSinCostoEnvio.includes(origenPedidoAFacturar)) {
      facturarCostoEnvio = false;
    } else if (this.factElect && this.factElect.factCostoEnvio && this.factElect.factCostoEnvio.includes(origenPedidoAFacturar)) {
      facturarCostoEnvio = true;
    }

    return facturarCostoEnvio;
  }
}


export interface LocalOnlineInterno {
  keyFBAdmin: string,
  nombre: string,
  activado: boolean,
  productosVinculados: boolean,
  sinCostoEnvio: boolean,
  autoaccept: boolean,
  rappiv2: boolean,
  deliveryTimeAA: number,
  concurrenciaEstado: boolean,
  token: string,
  notificacionesUrl?: string,
  origen: string,
  plataforma: string,
  idStore: string,
  marca: string,
  keyLocalOnline: string,
  keyHub: string,
  offset: number,
  tag: string,
  descrappi: string,
  descuentopya: string,
  descuentoUber: boolean,
  readyToDeliver: boolean,
  readyToDeliverNoLogistics: boolean,
  fechaAlta: string,
  seleccionado: boolean,
  menuSync: boolean
}

@Component({
  selector: 'app-modal-conf-ponline',
  templateUrl: './modal-conf-ponline.component.html',
  styleUrls: ['./modal-conf-ponline.component.scss']
})
export class ModalConfPonlineComponent implements OnInit, AfterViewInit, OnDestroy {
  correo: string;
  passReseteado: boolean = false;
  locales: LocalOnlineInterno[];
  usuarios: { email: string, uid: string }[] = [];

  keyLocalNueva: string = "";
  mapKeys;

  vistaVieja = false;
  filtroActual2: string = "";
  filtroActual: string = "a";
  primeraVez: boolean;


  MSJ_KEY_MUY_CORTA: string = "❌ Key muy corta";
  MSJ_KEY_VALIDA: string = "✅ Key válida";
  MSJ_KEY_NOVALIDA: string = '❌ Key con caracteres especiales (sólo permite minúsculas, números y "_")'
  resultadoBuscaKey: string;

  dataSource: MatTableDataSource<LocalOnlineInterno>;
  @ViewChild(MatSort) sort: MatSort;



  columnsToDisplay: string[] = [
    "nombre",
    "activado",
    "autoaccept",
    "plataforma",
    "marca",
    "idStore",
    "keyHub",
    "deliveryTimeAA",
    "concurrenciaEstado",
    "origen",


    "fechaAlta",
    "offset",
    "tag",
    "productosVinculados",
    "descrappi",
    "descuentopya",
    "token",
  ]

  subs: Subscription[] = [];

  buscandoKey: number;
  pais: string;
  offset: number;
  loadingPais: boolean;
  migrando: boolean;
  tiendasSeleccionadas: LocalOnlineInterno[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalConfPonlineComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public s: ServidorAdmin,
    private db: AngularFireDatabase,
    private dbFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    public adminCuentaService: AdminCuentaService,
    private dbFS: DbFSService) { }


  ngOnDestroy(): void {
    for (let s of this.subs) {
      try { s.unsubscribe() } catch (e) { }
    }
  }




  ngOnInit() {
    this.primeraVez = true;

    this.abrirModalPais(false);
    this.locales = this.localesOnline(this.s);
    this.leerKeys();
    this.updateTabla(false);

  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  async buscaKey(key: string) {
    this.keyLocalNueva = key;
    // if (!key || key.length < 3) {
    //   this.resultadoBuscaKey = this.MSJ_KEY_MUY_CORTA;
    // } else {
    let validar = new RegExp("[^a-z0-9\_]")
    //buscar
    const copia = new Date().getTime();
    this.buscandoKey = new Date().getTime();
    this.resultadoBuscaKey = "🔍 Buscando...";

    let local = await this.dbFS.docOnce("confLocalPO/" + key);
    if (local.exists) {
      if (copia <= this.buscandoKey) {
        // ok
        this.resultadoBuscaKey = "❌ CUIDADO Esa key ya existe";
      } else {
        // se esta buscando otra key
      }
    } else {
      await this.dbFS.collectionOnce("confLocalPO/" + key + "/pedidos", ref => ref.limit(1)).toPromise().then(pedidos => {
        if (pedidos.length > 0) {
          if (copia <= this.buscandoKey) {
            // ok
            this.resultadoBuscaKey = "❌ CUIDADO Esa key ya tiene pedidos";
          } else {
            // se esta buscando otra key
          }

        } else {
          if (!validar.test(key) && key && key.length >= 3) {
            if (copia <= this.buscandoKey) {
              // ok
              this.resultadoBuscaKey = this.MSJ_KEY_VALIDA
            } else {
              // se esta buscando otra key
            }

          } else {
            if (!key || key.length < 3) {
              if (copia <= this.buscandoKey) {
                // ok
                this.resultadoBuscaKey = this.MSJ_KEY_MUY_CORTA;
              } else {
                // se esta buscando otra key
              }
            } else {
              console.log('Encontro algo que no esta en el patron permitido', (key.match(validar))[0]);
              if (copia <= this.buscandoKey) {
                // ok
                this.resultadoBuscaKey = this.MSJ_KEY_NOVALIDA + '. No permitido: "' + (key.match(validar))[0] + '"';
              } else {
                // se esta buscando otra key
              }
            }
          }
        }
      })
      // }
    }
  }


  applyFilter(filterValue: string) {
    if (this.primeraVez) {
      this.updateTabla(true);
    } else {
      this.primeraVez = false;
    }
    this.dataSource.filter = "";

    let filtro = filterValue.toLowerCase();
    if (!filtro || filtro.length == 0) {
      filtro = "a";
      this.filtroActual2 = "";
      //this.buscandoActual = "";
    } else {
      if (filtro === "a") {
        this.filtroActual2 = "";
        //this.buscandoActual = "";
      } else {
        this.filtroActual2 = filtro;
        //this.buscandoActual = filtro;
      }
    }

    this.filtroActual = filtro;
    this.dataSource.filter = filtro;
    this.dataSource.sort = this.sort;


  }

  irAEditarPaisUOffset() {
    this.abrirModalPais(true);
  }

  updateTabla(yaFiltro: boolean) {
    // this.locales.sort(function (l1: LocalOnlineInterno, l2: LocalOnlineInterno) {
    // //  return l2.dias - l1.dias;
    // });

    this.dataSource = new MatTableDataSource(this.locales);
    this.dataSource.filterPredicate =
      (l: LocalOnlineInterno, filter: string) => {
        return JSON.stringify(l).trim().toLowerCase().indexOf(filter) != -1;
      }

    if (!yaFiltro) {

      this.applyFilter(this.filtroActual)
    } else {
      this.dataSource.sort = this.sort;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  abrirModalPais(force: boolean) {
    this.loadingPais = true;
    console.log(this.s);
    if (this.s.keyPedidosOnline && this.s.keyPedidosOnline.key) {
      const s = this.s;
      const dialogRef = this.dialog.open(ModalConfPaisOffsetComponent, {
        width: '85%',
        data: { s, forced: force }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadingPais = false;
        // console.log('The dialog was closed', result);
        if (result && result.pais && result.offset) {
          this.pais = result.pais;
          this.offset = result.offset;
          // this.moneda = result.moneda;
        } else {
          console.error('No hay pais y offset')
        }
      });
    }
  }

  async guardarKey(seLoQHago: boolean) {
    if (!seLoQHago) {
      if (this.s.keyPedidosOnline && this.s.keyPedidosOnline.key) {
        this.guardarKey(true);
        return;
      }

      let encontrado = false;
      if (this.resultadoBuscaKey !== this.MSJ_KEY_VALIDA) {
        encontrado = true;
      }
      for (let pya123 in this.mapKeys) {
        if (this.keyLocalNueva === this.mapKeys[pya123]) {
          encontrado = true;
          break;
        }
      }
      if (encontrado) {
        let snack = this.snackbar.open("WARNING Esta key ya existe", "CONTINUAR (sé lo que hago)");

        snack._dismissAfter(2000);
        snack.onAction().subscribe(v => {
          this.guardarKey(true);
        })
      } else {
        this.guardarKey(true);
      }
    } else {

      if (this.s.keyPedidosOnline && this.s.keyPedidosOnline.key) {
        // guardar pais y offset
        let mapFs = {};
        if (!this.pais) {
          // validamos el pais
          if (!this.pais || this.pais === '-1') {
            this.snackbar.open("Debes elegir un país ❌", "")._dismissAfter(2000);
            return;
          } else {
            mapFs['pais'] = this.pais;
          }
        } else {
          mapFs['pais'] = this.pais;

        }

        if (!this.offset) {
          this.snackbar.open("Debes elegir un offset ❌", "")._dismissAfter(2000);
          return;
        } else {
          mapFs['offset'] = (+this.offset);
          // console.log(this.offset);

        }
        await this.dbFS.updateAt("confLocalPO/" + this.s.keyPedidosOnline.key, mapFs);
        this.snackbar.open("Datos guardados ✔️", "")._dismissAfter(2000);

      } else {
        // guardar key
        await this.adminCuentaService.hitearSQL(this.s, this.keyLocalNueva);

        console.log('hiteos sql done');
        this.db.object("cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/keyPedidosOnline/key").set(this.keyLocalNueva)
          .then(async _ => {

            await this.dbFS.updateAt("confLocalPO/" + this.keyLocalNueva + "/", {
              _key: this.keyLocalNueva,
              idCuenta: this.s.idCuenta,
              idLocal: this.s.idLocal,
              idMac: this.s.idMac,
              // moneda: this.s.moneda
            });

            if (!this.s.keyPedidosOnline) {
              this.s.keyPedidosOnline = { key: this.keyLocalNueva, locales: new Map(), users: new Map() };
            } else {
              this.s.keyPedidosOnline.key = this.keyLocalNueva;
            }



            this.snackbar.open("Datos guardados ✔️", "")._dismissAfter(2000);
            this.abrirModalPais(false);
          })
          .catch(err => {
            this.snackbar.open("No se pudo guardar ❌", "")._dismissAfter(2000);

            console.log("No se pudo completar la accion", err);
          })
      }


    }
  }
  async leerKeys() {

    let v = await this.db.object("keysLocalesPedidosOnline").query.once("value");
    this.mapKeys = v.val();

  }

  migrar() {
    if (this.migrando && this.tiendasSeleccionadas.length > 0) {
      this.dialogRef.close(this.tiendasSeleccionadas);
    }
  }
  updateTienda(s: ServidorAdmin, local?: LocalOnlineInterno) {
    if (this.migrando) {
      local.seleccionado = !local.seleccionado;
      if (local.seleccionado) {
        this.tiendasSeleccionadas.push(local);
      } else {
        const index = this.locales.findIndex(x => x.origen === local.origen && x.idStore === local.idStore);
        if (index > -1) {
          this.tiendasSeleccionadas.splice(index, 1);
        }
      }
      console.log(this.tiendasSeleccionadas);
    } else {
      const dialogRef = this.dialog.open(ModalTiendaComponent, {
        width: '85%',
        data: { s, local }
      });

      dialogRef.afterClosed().subscribe(result => {
        result => {
          console.log('The dialog was closed', result);
          if (result && result.msj) {
            return;
          }
        }
      });
    }
  }

  async editarKey(s: ServidorAdmin) {
    let defaultKeys: string = "";
    let keyAnterior = "";
    if (s.keyPedidosOnline && s.keyPedidosOnline.key) {
      keyAnterior = s.keyPedidosOnline.key;
    }



    let keyLocalNueva = prompt("Key para vincular los servicios de " + s.nombreCuenta + "\n" + defaultKeys, keyAnterior);

    if (keyLocalNueva && keyLocalNueva.length > 3 && keyLocalNueva !== keyAnterior) {

      this.snackbar.open("Cambio exitoso", "")._dismissAfter(2000);

    } else {
      this.snackbar.open("No se realizó el cambio", "")._dismissAfter(2000);
    }

  }

  localesOnline(s: ServidorAdmin): LocalOnlineInterno[] {

    let locales: LocalOnlineInterno[] = [];

    let usuarios: { email: string, uid: string }[] = [];
    if (s.keyPedidosOnline) {
      let cantTotalTiendas = 0;
      for (let k in s.keyPedidosOnline.locales) {
        locales.push({
          keyFBAdmin: k,
          nombre: s.keyPedidosOnline.locales[k].nombre,
          activado: s.keyPedidosOnline.locales[k].activado,
          autoaccept: s.keyPedidosOnline.locales[k].autoaccept,
          rappiv2: s.keyPedidosOnline.locales[k].rappiv2,
          deliveryTimeAA: s.keyPedidosOnline.locales[k].deliveryTimeAA,
          concurrenciaEstado: s.keyPedidosOnline.locales[k].concurrenciaEstado,
          token: s.keyPedidosOnline.locales[k].token,
          origen: s.keyPedidosOnline.locales[k].origen,
          plataforma: s.keyPedidosOnline.locales[k].plataforma,
          idStore: s.keyPedidosOnline.locales[k].idStore,
          marca: s.keyPedidosOnline.locales[k].marca,
          tag: s.keyPedidosOnline.locales[k].tag,
          fechaAlta: s.keyPedidosOnline.locales[k].fechaAlta,
          descuentopya: s.keyPedidosOnline.locales[k].descuentopya,
          descuentoUber: s.keyPedidosOnline.locales[k].descuentoUber,
          readyToDeliver: s.keyPedidosOnline.locales[k].readyToDeliver,
          readyToDeliverNoLogistics: s.keyPedidosOnline.locales[k].readyToDeliverNoLogistics,
          descrappi: s.keyPedidosOnline.locales[k].descrappi,
          keyLocalOnline: s.keyPedidosOnline.key,
          productosVinculados: s.keyPedidosOnline.locales[k].productosVinculados,
          sinCostoEnvio: s.keyPedidosOnline.locales[k].sinCostoEnvio,
          keyHub: s.keyPedidosOnline.locales[k].keyHub,
          offset: s.keyPedidosOnline.locales[k].offset,
          seleccionado: false,
          menuSync: s.keyPedidosOnline.locales[k].menuSync,
        });

        cantTotalTiendas++;
      }



      locales.sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1;
        } else if (a.nombre === b.nombre) {
          return 0;
        } else {
          return 1;
        }
      })

      if (s.keyPedidosOnline.users) {
        for (let u in s.keyPedidosOnline.users) {
          usuarios.push({ email: s.keyPedidosOnline.users[u], uid: u });
        }
      }
      this.usuarios = usuarios;
    }

    return locales;
  }


  async borrarUsuario(s: ServidorAdmin, user: { email: string, uid: string }) {
    try {
      const users: any[] = await this.dbFS.collectionOnce('confLocalPO/' + s.keyPedidosOnline.key + '/usuarios').toPromise();
      if (users) {
        const usersConEmail = users.filter(x => x.email === user.email);
        if (usersConEmail && usersConEmail.length > 0) {
          for (const u of usersConEmail) {
            await this.dbFS.delete('confLocalPO/' + s.keyPedidosOnline.key + '/usuarios/' + u._key);
            if (u.uid) {
              this.dbFirestore.doc("confLocalPO/" + s.keyPedidosOnline.key).
                update({
                  uids: firebase.firestore.FieldValue.arrayRemove(u.uid)
                });
            }
          }
        }
      }

      const invitaciones: any[] = await this.dbFS.collectionOnce('invitaciones', ref => ref.where('email', '==', user.email).where('keyLocal', '==', s.keyPedidosOnline.key)).toPromise();
      if (invitaciones && invitaciones.length > 0) {
        for (const i of invitaciones) {
          await this.dbFS.delete('invitaciones/' + i._key);
        }
      }

      await this.db.object("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/users/" + user.uid).set(null).catch(e => { });
      this.snackbar.open('Exito')._dismissAfter(2000);
    } catch (e) {
      console.log('Error al borrar usuario', e);
      this.snackbar.open('Error al borrar usuario')._dismissAfter(4000);
    }
    return;
  }

  async updateInfoFS(s: ServidorAdmin) {
    let mapUpdatesFS = {};
    mapUpdatesFS["idCuenta"] = s.idCuenta;
    mapUpdatesFS["idLocal"] = s.idLocal;
    mapUpdatesFS["idMac"] = s.idMac;
    mapUpdatesFS["_key"] = s.keyPedidosOnline.key;
    mapUpdatesFS["nombre"] = s.nombreLocal;

    let urlConfLocal = "confLocalPO/" + this.s.keyPedidosOnline.key + "/";
    this.dbFS.updateAt(urlConfLocal, mapUpdatesFS).then(v => {
      this.snackbar.open("Local actualizado")._dismissAfter(2000);
    }).catch(e => {
      this.snackbar.open("Problema al grabar los datos, revisar la consola (F12)")._dismissAfter(2000);
      console.error("Problema al grabar los datos FS", e);
    });
  }

  async nuevoUsuario(s: ServidorAdmin) {
    // sad
    let email = prompt("Email del nuevo usuario para acceder a POnline");
    let emailConComa = email.replace(".", ",");
    while (emailConComa.includes(".")) {
      emailConComa = emailConComa.replace(".", ",");
    }
    if (email && email.length > 3) {
      //let usuarioSnap = await this.db.object("users/" + emailConComa).query.once("value");
      //let usuario = usuarioSnap.val();
      //if (usuario && usuario.uid) {

      this.db.object("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/users/" + emailConComa).set(email).catch(e => {
        alert("Problema al grabar los datos, revisar la consola (F12)");
        console.error("Problema al grabar los datos CUENTAS", e);
      });



      const PEDIDOSR = "PEDIDOSR";
      const PEDIDOSW = "PEDIDOSW";
      const CONFIGURACIONESR = "CONFIGURACIONESR";
      const CONFIGURACIONESW = "CONFIGURACIONESW";
      const ESTADISTICASR = "ESTADISTICASR";
      const ESTADISTICASW = "ESTADISTICASW";
      const USUARIOSR = "USUARIOSR";
      const USUARIOSW = "USUARIOSW";

      let invitacion = {
        email: email,
        keyLocal: s.keyPedidosOnline.key,
        nombreLocal: s.nombreLocal,
        photoUrlEnviador: "https://popapp.io/assets/images/users/default-user.png",
        nameEnviador: "Popapp",
      };

      let permisosRoWSelec = [PEDIDOSW, PEDIDOSR, CONFIGURACIONESW, CONFIGURACIONESR, ESTADISTICASW, ESTADISTICASR, USUARIOSW, USUARIOSR]
      this.dbFS.writeBatch([{
        path: "confLocalPO/" + s.keyPedidosOnline.key + "/usuarios",
        data: {
          email: email,
          funcion: "Propietario",
          permisos: permisosRoWSelec,
          ultConexion: "Pendiente"
        },
        removeOperation: false,
      },
      {
        path: "invitaciones",
        data: invitacion,
        removeOperation: false,
      }]).then(v => {
        this.snackbar.open("Usuario agregado")._dismissAfter(2000);

      }).catch(e => {
        alert("Problema al grabar los datos, revisar la consola (F12)");
        console.error("Problema al grabar los datos FS", e);
      });

    }
  }
}
