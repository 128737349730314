import { Component, OnInit, OnDestroy } from '@angular/core';
import { POnline } from '../test-pedidos-online/ponliine';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

function formatoFecha(date: Date): string {
  const mes = date.getMonth() + 1;
  const mesString = mes < 10 ? '0' + mes : mes;
  const diaString = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return `${date.getFullYear()}-${mesString}-${diaString} 10:00:00`;
};

@Component({
  selector: 'app-admin-update-pedidos',
  templateUrl: './admin-update-pedidos.component.html',
  styleUrls: ['./admin-update-pedidos.component.scss']
})
export class AdminUpdatePedidosComponent implements OnInit, OnDestroy {

  keyLocal: string;
  buscando: boolean;
  pedidos: POnline[];
  environment = environment;
  fechaDesde: string;
  fechaHasta: string;
  error: string;
  keyLocalActual: string;

  constructor(private dbFs: AngularFirestore, private snackbar: MatSnackBar, private cuentaService: CuentaService) { }

  ngOnInit() {
    const hoy = new Date();
    this.fechaHasta = formatoFecha(hoy);
    hoy.setMonth(hoy.getMonth() - 1);
    this.fechaDesde = formatoFecha(hoy);
  }

  ngOnDestroy() {
  }

  buscar() {
    this.error = '';
    this.fechaDesde = this.fechaDesde?.trim();
    this.fechaHasta = this.fechaHasta?.trim();
    this.keyLocalActual = this.keyLocal?.trim();
    if (this.fechaHasta < this.fechaDesde) {
      this.error = 'Fecha Hasta mayor a Desde';
      return;
    }
    if (!this.fechaDesde) {
      this.error = 'Fecha Desde vacía';
      return;
    }
    if (!this.fechaHasta) {
      this.error = 'Fecha Hasta vacía';
      return;
    }
    if (!this.keyLocalActual) {
      this.error = 'keyLocal vacía';
      return;
    }
    this.buscando = true;

    this.dbFs.collection<POnline>(`confLocalPO/${this.keyLocalActual}/pedidos`,
      ref => ref
        .where("fechaCreacion", ">=", this.fechaDesde)
        .where("fechaCreacion", "<=", this.fechaHasta))
      .snapshotChanges().subscribe(v => {
        this.pedidos = [];
        for (const d of v) {
          this.pedidos.push(d.payload.doc.data());
        }
        this.buscando = false;
      });
  }

  log(p: POnline) {
    console.log("pedido", p);
  }

  fecha(f: string): string {
    if (!f) {
      return "Sin fecha";
    }
    let d = new Date(f);
    d.setHours(d.getHours());
    return (d.getDate() < 10 ? "0" : "") + d.getDate() + "/" +
      (d.getMonth() < 9 ? "0" : "") + (d.getMonth() + 1) + "/" +
      (d.getDate() < 10 ? "0" : "") + d.getFullYear() + " " +
      (d.getHours() < 10 ? "0" : "") + d.getHours() + ":" +
      (d.getMinutes() < 10 ? "0" : "") + d.getMinutes()// + ":" +
    //(d.getSeconds() < 10 ? "0" : "") + d.getSeconds()
  }

  //baseUrl = "http://localhost:3000/pedidosonline/";
  baseUrl = environment.urls.escuchador + "/pedidosonline/";

  async actualizarUpdatedAt(pedidos: POnline[]) {
    const body = pedidos.map(p => {
      return { origen: p.origen, idStore: p.idStore, idPedido: p.id };
    });
    this.buscando = true;
    try {
      await this.cuentaService.postUrlProm(`${environment.urls.functions}/orders/${this.keyLocalActual}/update/updated_at`, body);
      this.snackbar.open("Éxito", "✅")._dismissAfter(2000);
    } catch (err) {
      console.log(err);
      this.snackbar.open("Error, por favor reintenta", "❗")._dismissAfter(2000);
    } finally {
      this.buscando = false;
    }
  }

  migrarAPOnline(p: POnline) {
    this.cuentaService.getUrlProm(this.baseUrl + "migrar/" + p.keyLocal + "/" + p.origen + "," + p.idStore + "," + p.id).then(v => {

      let snack = this.snackbar.open("Migrado", "VER");
      snack._dismissAfter(4000);
      snack.onAction().subscribe(v => {
        let url = "https://app.popapp.io/ponline/pedidos/keyERROR/porpreparar";
        window.open(url, "_blank");
      });
    }).catch(e => {
      console.error("error al migrar", e);
      this.snackbar.open("No se pudo completar la acción", "ERROR")._dismissAfter(2000);
    })
  }
}
