import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'firebase/firestore';

export interface EmailFire {
  delivery: {
    state: string
  };
  message: {
    subject: string
  };
}

export interface EmailFireId extends EmailFire {
  id: string;
}

@Component({
  selector: 'app-admin-emails-firestore',
  templateUrl: './admin-emails-firestore.component.html',
  styleUrls: ['./admin-emails-firestore.component.scss']
})
export class AdminEmailsFirestoreComponent implements OnInit {

  private itemsCollection: AngularFirestoreCollection<EmailFire>;
  items: Observable<EmailFireId[]>;
  arre: EmailFireId[];
  constructor(private afs: AngularFirestore) {
  }
  retry(item: EmailFireId) {
    this.afs.doc("mail2/" + item.id).set(
      {
        delivery: {
          state: "RETRY"
        }
      },
      { merge: true });
  }

  retryAll() {
    for (let i of this.arre) {
      this.retry(i);
    }
  }
  deleteItem(item: EmailFireId) {
    this.afs.doc("mail2/" + item.id).delete();
  }
  deleteAllItems() {
    for (let i of this.arre) {
      this.deleteItem(i);
    }
  }

  dateD(item) {
    return item?.delivery?.startTime ? item.delivery.startTime.toDate().toISOString() : ''
  }

  ngOnInit() {
    const SUCCESS = "SUCCESS";
    const QUERY = SUCCESS;
    //let QUERY = "ERROR";
    this.itemsCollection = this.afs.collection<EmailFire>('mail2',
      ref => ref.limit(15)//where('delivery.state', '==', QUERY).limit(95)
      // .where("message.subject", "==", "Err: FoodFast Spa - Errores buscando pedidos Pedidos Online")
    );
    // .snapshotChanges() returns a DocumentChangeAction[], which contains
    // a lot of information about "what happened" with each change. If you want to
    // get the data and the id use the map operator.
    this.items = this.itemsCollection.snapshotChanges().pipe(
      map(actions => {
        let arreglo =
          actions.map(a => {
            const data = a.payload.doc.data() as EmailFire;
            const id = a.payload.doc.id;
            if (data.delivery && data.delivery.state && data.delivery.state === SUCCESS) {
              this.afs.doc("mail2/" + id).delete();
            }
            return { id, ...data };
          });
        this.arre = arreglo;
        /*if (SUCCESS === QUERY) {
          this.borrar(0);
        }*/
        return this.arre;
      })

    );
  }

  borrar(cantIntentos) {
    console.count("borrar");
    setTimeout(_ => {
      let i = 0;
      for (let e of this.arre) {
        if (i < 26) {
          this.afs.doc("mail2/" + e.id).delete();
        }
        i++;
      }
      if (cantIntentos < 5) {
        cantIntentos = cantIntentos + 1
        this.borrar(cantIntentos)
      }
    }, 1000);
  }
}
