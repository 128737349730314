export class PuntuacionResumen {
    cantPunt: number;
    comentarios: number;
    fechaUltimaPunt: string;
    idCuenta: string;
    mac: string;
    nombre: string;
    promedio: number;
    total: number;
    ultimaPunt: number;

    qxPuntbaja: number;
    qxEstadosAbiertos: number;
    qxEstadosCerrados: number;
    qxEstadosResueltos: number;
}
