import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_OB } from '../core/const';
import Servidor from '../models/servidor';

const BASE_URL = `${BASE_URL_OB}/v1/managers/?server=true`;

@Injectable({
  providedIn: 'root'
})
export class ServidoresService {

  constructor(
    private http: HttpClient
  ) { }

  getServidores() {
    return this.http.get<{data: Servidor[]}>(BASE_URL);
  }

  postServidores(servidor: Servidor){
    return this.http.post<{data: Servidor}>(BASE_URL, servidor);
  }

  putServidores(servidor: Servidor){
    return this.http.put<{data: Servidor}>(BASE_URL, servidor);
  }
}
