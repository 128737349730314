import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService, InfoCuenta } from "../core/auth.service";
import { Cuenta } from "../shared/cuenta";
import { Resto } from "../shared/resto";
import { AngularFireAuth } from '@angular/fire/auth';
import * as consts from "../shared/constantes";
import { PieChartConfig } from '../charts/pieconfig';
import { LineChartConfig } from '../charts/linechartconfig';
import { MediaMatcher } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router, ActivatedRoute } from '@angular/router';
import { RutaService } from '../services/ruta.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { EmailCuenta } from '../shared/admin/emailCuenta';
import { MinutosPipe } from '../pipes/minutos';
import { CuentaService } from '../services/cuenta.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss', '../../mypopapp/assets/_css/mypopapp.css', '../../mypopapp/assets/_css/mypopapp-mq.css']
})
export class MainComponent implements OnInit, OnDestroy {


  cuenta: Cuenta;

  textoComensales: string = "No hay datos";

  idCuentaSelected: string = "";


  resto: Resto;

  ref;

  subscriptionCuenta: Subscription;

  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;

  emailRef;//: Observable<any>;


  mobileQuery: MediaQueryList;


  private _mobileQueryListener: () => void;
  tieneDatos: boolean = true;



  constructor(private minutosPipe: MinutosPipe, private db: AngularFireDatabase, public rutaService: RutaService, public activatedRoute: ActivatedRoute, public authService: AuthService, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public afAuth: AngularFireAuth, public snackBar: MatSnackBar, public router: Router, private cuentaService: CuentaService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  snackInfo() {

    this.snackBar.open("Se actualiza cada " + this.minutosPipe.transform(this.txMypopapp / 60), "", {
      duration: 3000,
    });

  }

  aborrar() {
    let email = "gmarin@popapp.io"
    let nombre = "German"
    let nombreLocal = "Tally"
    let pais = "ARG"
    let wapp = "+549264"
    let cuerpoDeMail = "Te damos la bienvenida a Popapp " + email + ",\nEn el siguiente link puedes descargar Popapp Manager para Windows\nhttps://popapp.io/descargas/setupPopApp.exe\n\n";
    //this.db.object("emailsCuentas/" + this.authService.decodeEmail(email) + "/").update(map)
    cuerpoDeMail += "Tus datos son:";
    cuerpoDeMail += "\nNombre: " + nombre;
    cuerpoDeMail += "\nNegocio: " + nombreLocal;
    cuerpoDeMail += "\nPais: " + pais;
    cuerpoDeMail += "\nTelefono: " + wapp;
    cuerpoDeMail += "\n\n";


    let urlServidorMail = "localhost:3000/payments/mails/leads";

    cuerpoDeMail += "Cualquier duda puedes contactarte con nosotros a través de nuestra web https://popapp.io\nGracias,\nEl equipo de popapp."

    let body = {
      notification: {
        title: "popapp",
        to: "gmarin@popapp.io",
        copia: "jcorona@popapp.io, cmarin@popapp.io",
        asunto: "Bienvenido a popapp " + nombre,
        text: cuerpoDeMail
      }
    }

    let body2 = {
      notification: {
        title: "popapp",
        to: "gmarin@popapp.io, jcorona@popapp.io",
        copia: "",
        asunto: "HolaAAASDASDASD 👋 ¡Bienvenid@ a Popapp!",
        text: cuerpoDeMail,
        html: this.leerHtml(nombre)
      }
    }
    //mandar email para descargar con copia al facu jorge y a mi
    this.cuentaService.enviaMail(body, urlServidorMail);
    this.cuentaService.enviaMail(body2, urlServidorMail);
  }
  leerHtml(nombre: string): string {
    return "<!DOCTYPE HTML PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n" +
      "<html>\n" +
      "\n" +
      "<head>\n" +
      "   <meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\n" +
      "   <title>Bienvenida</title>\n" +
      "   <link href=\"https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700\" rel=\"stylesheet\">\n" +
      "   <style type=\"text/css\">\n" +
      "      html {\n" +
      "         -webkit-text-size-adjust: none;\n" +
      "         -ms-text-size-adjust: none;\n" +
      "      }\n" +
      "\n" +
      "      @media only screen and (min-device-width: 750px) {\n" +
      "         .table750 {\n" +
      "            width: 750px !important;\n" +
      "         }\n" +
      "      }\n" +
      "\n" +
      "      @media only screen and (max-device-width: 750px),\n" +
      "      only screen and (max-width: 750px) {\n" +
      "         table[class=\"table750\"] {\n" +
      "            width: 100% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_b {\n" +
      "            width: 93% !important;\n" +
      "            max-width: 93% !important;\n" +
      "            min-width: 93% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_b1 {\n" +
      "            width: 100% !important;\n" +
      "            max-width: 100% !important;\n" +
      "            min-width: 100% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_left {\n" +
      "            text-align: left !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_soc {\n" +
      "            width: 50% !important;\n" +
      "            max-width: 50% !important;\n" +
      "            min-width: 50% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_menu {\n" +
      "            width: 50% !important;\n" +
      "            max-width: 50% !important;\n" +
      "            min-width: 50% !important;\n" +
      "            box-shadow: inset -1px -1px 0 0 rgba(255, 255, 255, 0.2);\n" +
      "         }\n" +
      "\n" +
      "         .mob_btn {\n" +
      "            width: 100% !important;\n" +
      "            max-width: 100% !important;\n" +
      "            min-width: 100% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_card {\n" +
      "            width: 88% !important;\n" +
      "            max-width: 88% !important;\n" +
      "            min-width: 88% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_title1 {\n" +
      "            font-size: 36px !important;\n" +
      "            line-height: 40px !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_title2 {\n" +
      "            font-size: 26px !important;\n" +
      "            line-height: 33px !important;\n" +
      "         }\n" +
      "\n" +
      "         .top_pad {\n" +
      "            height: 15px !important;\n" +
      "            max-height: 15px !important;\n" +
      "            min-height: 15px !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_pad {\n" +
      "            width: 15px !important;\n" +
      "            max-width: 15px !important;\n" +
      "            min-width: 15px !important;\n" +
      "         }\n" +
      "\n" +
      "         .top_pad2 {\n" +
      "            height: 40px !important;\n" +
      "            max-height: 40px !important;\n" +
      "            min-height: 40px !important;\n" +
      "         }\n" +
      "      }\n" +
      "\n" +
      "      @media only screen and (max-device-width: 550px),\n" +
      "      only screen and (max-width: 550px) {\n" +
      "         .mod_div {\n" +
      "            display: block !important;\n" +
      "         }\n" +
      "      }\n" +
      "\n" +
      "      .table750 {\n" +
      "         width: 750px;\n" +
      "      }\n" +
      "   </style>\n" +
      "</head>\n" +
      "\n" +
      "<body style=\"margin: 0; padding: 0;\">\n" +
      "\n" +
      "   <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "      style=\"background: #f5f8fa; min-width: 340px; font-size: 1px; line-height: normal;\">\n" +
      "      <tr>\n" +
      "         <td align=\"center\" valign=\"top\">\n" +
      "            <!--[if (gte mso 9)|(IE)]>\n" +
      "         <table border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n" +
      "         <tr><td align=\"center\" valign=\"top\" width=\"750\"><![endif]-->\n" +
      "            <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"750\" class=\"table750\"\n" +
      "               style=\"width: 100%; max-width: 750px; min-width: 340px; background: #f5f8fa;\">\n" +
      "               <tr>\n" +
      "                  <td class=\"mob_pad\" width=\"25\" style=\"width: 25px; max-width: 25px; min-width: 25px;\">&nbsp;</td>\n" +
      "                  <td align=\"center\" valign=\"top\" style=\"background: #ffffff;\">\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                        style=\"width: 100% !important; min-width: 100%; max-width: 100%; background: #f5f8fa;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"right\" valign=\"top\">\n" +
      "                              <div class=\"top_pad\" style=\"height: 25px; line-height: 25px; font-size: 23px;\">&nbsp;\n" +
      "                              </div>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                        style=\"width: 88% !important; min-width: 88%; max-width: 88%;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"left\" valign=\"top\">\n" +
      "                              <div style=\"height: 30px; line-height: 30px; font-size: 28px;\">&nbsp;</div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           <table border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n" +
      "                           <tr><td align=\"center\" valign=\"top\" width=\"460\"><![endif]-->\n" +
      "                              <div style=\"display: inline-block; vertical-align: top; width: 74%; min-width: 270px;\">\n" +
      "                                 <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                                    style=\"width: 100% !important; min-width: 100%; max-width: 100%;\">\n" +
      "                                    <tr>\n" +
      "                                       <td align=\"left\" valign=\"top\">\n" +
      "                                          <div style=\"height: 10px; line-height: 10px; font-size: 8px;\">&nbsp;</div>\n" +
      "                                          <a href=\"https://popapp.io\" target=\"_blank\" style=\"display: block; max-width: 180px;\">\n" +
      "                                             <img src=\"https://popapp.io/bienvenida/logo-popapp.png\" alt=\"img\"\n" +
      "                                                width=\"180\" border=\"0\" style=\"display: block; width: 180px;\" />\n" +
      "                                          </a>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]></td><td align=\"center\" valign=\"top\" width=\"150\"><![endif]-->\n" +
      "                              <div style=\"display: inline-block; vertical-align: top; width: 150px;\">\n" +
      "                                 <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                                    style=\"width: 100% !important; min-width: 100%; max-width: 100%;\">\n" +
      "                                    <tr>\n" +
      "                                       <td class=\"mob_left\" align=\"right\" valign=\"top\">\n" +
      "                                          <div style=\"height: 15px; line-height: 15px; font-size: 13px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#949494\"\n" +
      "                                             style=\"font-size: 14px; line-height: 18px; font-weight: 700;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #949494; font-size: 14px; line-height: 18px; font-weight: 700;\">BIENVENID@</span>\n" +
      "                                          </font>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           </td></tr>\n" +
      "                           </table><![endif]-->\n" +
      "                              <div class=\"top_pad2\" style=\"height: 70px; line-height: 70px; font-size: 68px;\">&nbsp;\n" +
      "                              </div>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                        style=\"width: 88% !important; min-width: 88%; max-width: 88%;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"left\" valign=\"top\">\n" +
      "                              <font class=\"mob_title1\" face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                 style=\"font-size: 42px; line-height: 45px; font-weight: 300; letter-spacing: -1.5px;\">\n" +
      "                                 <span class=\"mob_title1\"\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 36px; line-height: 36px; font-weight: 300; letter-spacing: -1.5px;\">Hola\n " + nombre + ",</span>\n" +
      "                              </font>\n" +
      "                              <div style=\"height: 22px; line-height: 22px; font-size: 20px;\">&nbsp;</div>\n" +
      "                              <font class=\"mob_title2\" face=\"'Source Sans Pro', sans-serif\" color=\"#5e5e5e\"\n" +
      "                                 style=\"font-size: 36px; line-height: 36px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                 <span class=\"mob_title2\"\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #5e5e5e; font-size: 30px; line-height: 30px; font-weight: 300; letter-spacing: -1px;\">¡El\n" +
      "                                    equipo de Popapp te da la bienvenida!</span>\n" +
      "                              </font>\n" +
      "                              <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                              <a href=\"https://popapp.io/mypopapp/micuenta\" target=\"_blank\">\n" +
      "                              <img src=\"https://popapp.io/bienvenida/equipo.gif\" alt=\"img\" width=\"100%\" border=\"0\"\n" +
      "                                 style=\"display: block; width: 100%;\" />\n" +
      "                               </a>" +
      "                              <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                              <font face=\"'Source Sans Pro', sans-serif\" color=\"#3f51b5\"\n" +
      "                                 style=\"font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">\n" +
      "                                 <span\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #eb135a; font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">Popapp</span>\n" +
      "                              </font>\n" +
      "                              <div style=\"height: 12px; line-height: 12px; font-size: 10px;\">&nbsp;</div>\n" +
      "                              <font face=\"'Source Sans Pro', sans-serif\" color=\"#101010\"\n" +
      "                                 style=\"font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                 <span\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #101010; font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">Popapp\n" +
      "                                    es un software de gestión para locales gastronómicos enfocado en aumentar tus\n" +
      "                                    ventas.</span>\n" +
      "                              </font>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                        style=\"width: 100% !important; min-width: 100%; max-width: 100%;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"center\" valign=\"top\">\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           <table border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n" +
      "                           <tr><td align=\"center\" valign=\"top\" width=\"325\"><![endif]-->\n" +
      "                              <div class=\"mob_btn\" style=\"display: inline-block; vertical-align: top; width: 325px;\">\n" +
      "                                 <table class=\"mob_card\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"295\"\n" +
      "                                    style=\"width: 295px !important; min-width: 295px; max-width: 295px;\">\n" +
      "                                    <tr>\n" +
      "                                       <td align=\"left\" valign=\"top\">\n" +
      "                                          <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                          <a href=\"https://www.youtube.com/watch?v=ZNO0ha_Wx4Y\" target=\"_blank\" style=\"display: block; max-width: 100%;\">\n" +
      "                                             <img src=\"https://popapp.io/bienvenida/pic_1.jpg\" alt=\"img\" width=\"100%\"\n" +
      "                                                border=\"0\" style=\"display: block; width: 100%;\" />\n" +
      "</a>" +
      "                                          <div style=\"height: 22px; line-height: 22px; font-size: 20px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#3f51b5\"\n" +
      "                                             style=\"font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #3f51b5; font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">Instalación</span>\n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 12px; line-height: 12px; font-size: 10px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#101010\"\n" +
      "                                             style=\"font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #101010; font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">Instala\n" +
      "                                                Popapp! Mira este video para hacerlo, es muy sencillo.</span>\n" +
      "                                                <a href=\"https://popapp.io/descargas/setupPopApp.exe\" target=\"_blank\">Descarga</a> \n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 8px; line-height: 8px; font-size: 6px;\">&nbsp;</div>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]></td><td align=\"center\" valign=\"top\" width=\"325\"><![endif]-->\n" +
      "                              <div class=\"mob_btn\" style=\"display: inline-block; vertical-align: top; width: 325px;\">\n" +
      "                                 <table class=\"mob_card\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"295\"\n" +
      "                                    style=\"width: 295px !important; min-width: 295px; max-width: 295px;\">\n" +
      "                                    <tr>\n" +
      "                                       <td align=\"left\" valign=\"top\">\n" +
      "                                          <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                          <a target=\"_blank\" style=\"display: block; max-width: 100%;\">\n" +
      "                                             <img src=\"https://popapp.io/bienvenida/pic_2.jpg\" alt=\"img\" width=\"100%\"\n" +
      "                                                border=\"0\" style=\"display: block; width: 100%;\" />\n" +
      "                                          </a>\n" +
      "                                          <div style=\"height: 22px; line-height: 22px; font-size: 20px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#3f51b5\"\n" +
      "                                             style=\"font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #3f51b5; font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">Contacto</span>\n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 12px; line-height: 12px; font-size: 10px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#101010\"\n" +
      "                                             style=\"font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #101010; font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">Espera\n" +
      "                                                mi llamado! Dentro de las próximas 24 horas estaré hablando contigo. No\n" +
      "                                                puedo esperar!</span>\n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 8px; line-height: 8px; font-size: 6px;\">&nbsp;</div>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           </td></tr>\n" +
      "                           </table><![endif]-->\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                        style=\"width: 88% !important; min-width: 88%; max-width: 88%; border-width: 1px; border-style: solid; border-color: #e8e8e8; border-top: none; border-left: none; border-right: none;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"center\" valign=\"top\">\n" +
      "                              <div style=\"height: 40px; line-height: 40px; font-size: 38px;\">&nbsp;</div>\n" +
      "                              <table class=\"mob_btn\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"\n" +
      "                                 style=\"background: #eb135a; border-radius: 4px;\">\n" +
      "                                 <tr>\n" +
      "                                    <td align=\"center\" valign=\"top\">\n" +
      "                                       <a href=\"https://popapp.io/foodtech/\" target=\"_blank\"\n" +
      "                                          style=\"display: block; border: none; border-radius: 4px; padding: 19px 26px; font-family: 'Source Sans Pro', Arial, Verdana, Tahoma, Geneva, sans-serif; color: #ffffff; font-size: 26px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;\">\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#ffffff\"\n" +
      "                                             style=\"font-size: 26px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Verdana, Tahoma, Geneva, sans-serif; color: #ffffff; font-size: 26px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;\">Descubre\n" +
      "                                                cómo potenciar tu negocio con Popapp!</span>\n" +
      "                                          </font>\n" +
      "                                       </a>\n" +
      "                                    </td>\n" +
      "                                 </tr>\n" +
      "                              </table>\n" +
      "                              <div style=\"height: 50px; line-height: 50px; font-size: 48px;\">&nbsp;</div>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                        style=\"width: 100% !important; min-width: 100%; max-width: 100%; background: #f5f8fa;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"center\" valign=\"top\">\n" +
      "                              <div style=\"height: 34px; line-height: 34px; font-size: 32px;\">&nbsp;</div>\n" +
      "                              <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                                 style=\"width: 88% !important; min-width: 88%; max-width: 88%;\">\n" +
      "                                 <tr>\n" +
      "                                    <td align=\"center\" valign=\"top\">\n" +
      "                                       <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"78%\"\n" +
      "                                          style=\"min-width: 300px;\">\n" +
      "                                          <tr>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"23%\">\n" +
      "                                                <a href=\"https://tallypar.freshdesk.com/support/home\" target=\"_blank\"\n" +
      "                                                   style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                   <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                      style=\"font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                      <span\n" +
      "                                                         style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">CENTRO&nbsp;DE&nbsp;AYUDA</span>\n" +
      "                                                   </font>\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"10%\">\n" +
      "                                                <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                   style=\"font-size: 17px; line-height: 17px; font-weight: bold;\">\n" +
      "                                                   <span\n" +
      "                                                      style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 17px; font-weight: bold;\">&bull;</span>\n" +
      "                                                </font>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"23%\">\n" +
      "                                                <a href=\"https://wa.me/542645122091\" target=\"_blank\"\n" +
      "                                                   style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                   <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                      style=\"font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                      <span\n" +
      "                                                         style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">SOPORTE&nbsp;24/7</span>\n" +
      "                                                   </font>\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"10%\">\n" +
      "                                                <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                   style=\"font-size: 17px; line-height: 17px; font-weight: bold;\">\n" +
      "                                                   <span\n" +
      "                                                      style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 17px; font-weight: bold;\">&bull;</span>\n" +
      "                                                </font>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"23%\">\n" +
      "                                                <a href=\"https://popapp.io/mypopapp/micuenta\" target=\"_blank\"\n" +
      "                                                   style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                   <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                      style=\"font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                      <span\n" +
      "                                                         style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">CUENTA</span>\n" +
      "                                                   </font>\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                          </tr>\n" +
      "                                       </table>\n" +
      "                                       <div style=\"height: 34px; line-height: 34px; font-size: 32px;\">&nbsp;</div>\n" +
      "                                       <font face=\"'Source Sans Pro', sans-serif\" color=\"#868686\"\n" +
      "                                          style=\"font-size: 17px; line-height: 20px;\">\n" +
      "                                          <span\n" +
      "                                             style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #868686; font-size: 17px; line-height: 20px;\">Copyright\n" +
      "                                             &copy; 2019 Popapp. Todos los derechos reservados.</span>\n" +
      "                                       </font>\n" +
      "                                       <div style=\"height: 3px; line-height: 3px; font-size: 1px;\">&nbsp;</div>\n" +
      "                                       <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                          style=\"font-size: 17px; line-height: 20px;\">\n" +
      "                                          <span\n" +
      "                                             style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 20px;\"><a\n" +
      "                                                href=\"mailto:hola@popapp.io?Subject=Consulta%20desde%20email%20de%20bienvenida\"\n" +
      "                                                target=\"_blank\"\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 20px; text-decoration: none;\">hola@popapp.io</a>\n" +
      "                                             &nbsp;&nbsp;|&nbsp;&nbsp; <a href=\"https://wa.me/542645487131\"\n" +
      "                                                target=\"_blank\"\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 20px; text-decoration: none;\">+54\n" +
      "                                                264 548 7131</a> </span>\n" +
      "                                       </font>\n" +
      "                                       <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                       <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\">\n" +
      "                                          <tr>\n" +
      "                                             <td align=\"center\" valign=\"top\">\n" +
      "                                                <a href=\"https://www.instagram.com/popapp.io/\" target=\"_blank\"\n" +
      "                                                   style=\"display: block; max-width: 19px;\">\n" +
      "                                                   <img src=\"https://popapp.io/bienvenida/soc_1.png\" alt=\"img\"\n" +
      "                                                      width=\"19\" border=\"0\" style=\"display: block; width: 19px;\" />\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td width=\"45\" style=\"width: 45px; max-width: 45px; min-width: 45px;\">\n" +
      "                                                &nbsp;</td>\n" +
      "                                             <td align=\"center\" valign=\"top\">\n" +
      "                                                <a href=\"https://www.facebook.com/popapp.io/\" target=\"_blank\"\n" +
      "                                                   style=\"display: block; max-width: 18px;\">\n" +
      "                                                   <img src=\"https://popapp.io/bienvenida/soc_2.png\" alt=\"img\"\n" +
      "                                                      width=\"18\" border=\"0\" style=\"display: block; width: 18px;\" />\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td width=\"45\" style=\"width: 45px; max-width: 45px; min-width: 45px;\">\n" +
      "                                                &nbsp;</td>\n" +
      "                                             <td align=\"center\" valign=\"top\">\n" +
      "                                                <a href=\"https://www.youtube.com/channel/UCpTpqBQKt9kptPoswA1sSCw\"\n" +
      "                                                   target=\"_blank\" style=\"display: block; max-width: 21px;\">\n" +
      "                                                   <img src=\"https://popapp.io/bienvenida/yt.png\" alt=\"img\" width=\"21\"\n" +
      "                                                      border=\"0\" style=\"display: block; width: 21px;\" />\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td width=\"45\" style=\"width: 45px; max-width: 45px; min-width: 45px;\">\n" +
      "                                                &nbsp;</td>\n" +
      "\n" +
      "                                          </tr>\n" +
      "                                       </table>\n" +
      "                                       <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                    </td>\n" +
      "                                 </tr>\n" +
      "                              </table>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                  </td>\n" +
      "                  <td class=\"mob_pad\" width=\"25\" style=\"width: 25px; max-width: 25px; min-width: 25px;\">&nbsp;</td>\n" +
      "               </tr>\n" +
      "            </table>\n" +
      "            <!--[if (gte mso 9)|(IE)]>\n" +
      "         </td></tr>\n" +
      "         </table><![endif]-->\n" +
      "         </td>\n" +
      "      </tr>\n" +
      "   </table>\n" +
      "</body>\n" +
      "\n" +
      "</html>"
  }
  data1: any[];
  config1: PieChartConfig;
  elementId1: string;
  pieTrueBarFalse1: boolean;
  cantMesas: number = 0;

  data2: any[];
  config2: PieChartConfig;
  elementId2: string;
  pieTrueBarFalse2: boolean;
  cantMasVendidos: number = 0;

  data3: any[];
  config3: LineChartConfig;
  elementId3: string;
  ventas: number = 0;

  data4: any[];
  config4: LineChartConfig;
  elementId4: string;
  ventasGraf: number = 0;


  gSaldoCaja = true;
  gVtasDesc = true;
  gDescuentos = true;
  gVtasBrutas = false;
  gCobEfectiv = false;
  gEgresCaja = true;
  gEntradasCaja = false;
  gOtrosTiposCobro = false;
  gPropinas = false;
  gIngresosExtra = true;
  gItemsEliminados = true;

  arreBool: boolean[] = [true, true, true, true, false, false, true, false, false, false, true, true];
  offset: number;

  promedios: number[];

  txMypopapp: number = 450;

  suscripcionUser: Subscription;
  suscripcionEmails: Subscription;
  suscripcionEmailsCuentas: Subscription;
  checkClick(i: number) {
    this.arreBool[i] = !this.arreBool[i];
    this.dibujaChartDetalle(this.resto);
  }

  /*actualizarHora(){
    this.db.object("restos/"+this.cuenta.id+"/t").set({ ".sv": "timestamp" });
  }*/

  leerDatosResto(cuenta: Cuenta) {
    //if (this.ref) {
    //  this.ref.snapshotChanges().unsubscribe();
    //}
    this.ref = this.authService.leerFB("restos/" + cuenta.id);
    this.ref.snapshotChanges().subscribe(action => {
      //consts.logD(action.type);
      //consts.logD(action.payload.val());
      this.resto = <Resto>action.payload.val();
      if (this.resto && this.resto.t) {
        let dateHoy = new Date();
        //dateHoy.setMinutes(dateHoy.getMinutes() + this.offset);
        let dateSubido = new Date(this.resto.t);
        this.resto.t = (dateHoy.getTime() - dateSubido.getTime()) / 1000 / 60;
        let contexto = this;
        this.db.object("macsPasadas/" + cuenta.id).query.once("value", function (snap) {
          let v = snap.val();
          if (v && v.idCuenta && v.idLocal) {
            contexto.db.object("cuentas/" + v.idCuenta + "/locales/" + v.idLocal + "/servidores/" + cuenta.id + "/txMypopapp").query.once("value", function (snap2) {
              let tx: number = snap2.val();
              if (tx) {
                contexto.txMypopapp = tx;
              }
            })
          }
        })
      }

      consts.logD(this.resto, "ref.snapshotChanges().subscribe");
      if (this.resto) {
        if (this.resto.venceEnDias < 10) {
          if (this.resto.venceEnDias <= 0) {
            // this.snackBar.open("Licencia Vencida", "IR a Mi Cuenta", { duration: 20000 }).onAction().subscribe(_ => {

            //this.router.navigateByUrl('/mypopapp/micuenta');

            //});
          } else {
            // this.snackBar.open("Licencia por vencer", "IR a Mi Cuenta", { duration: 10000 }).onAction().subscribe(_ => {
            //      this.router.navigateByUrl('/mypopapp/micuenta');
            //});
          }
        }

      }

      this.dibujaCharts(this.resto);

    });
  }

  dibujaCharts(resto: Resto) {
    this.cantMesas = 0;
    this.cantMasVendidos = 0;
    this.ventas = 0;
    if (resto) {
      //Line Chart Ventas
      this.data3 = [];
      this.data3.push(['Días', 'Ventas']);

      if (resto.ventasDescr && resto.ventasDia && resto.ventasDia.length == resto.ventasDescr.length) {
        for (let i = 0; i < resto.ventasDescr.length; i++) {
          this.data3.push([resto.ventasDescr[i], resto.ventasDia[i]]);
          this.ventas += resto.ventasDia[i];
        }
      }
      // consts.logD(this.data2);

      this.config3 = new LineChartConfig('Ventas por jornada', '');
      this.elementId3 = 'myPieChart3';



      //Piechart1 Data & Config
      //Mesas
      this.data1 = [];
      this.data1.push(['Mesas', 'Cantidad']);

      if (resto.cantMesas && resto.descrMesas && resto.cantMesas.length == resto.descrMesas.length && resto.cantMesas.length == 5) {
        for (let i = 0; i < resto.cantMesas.length; i++) {
          //colores en orden del graf de google esL: azul, rojo, amarillo, verde, violeta
          if (i == 0) {
            this.data1.push([resto.descrMesas[3], resto.cantMesas[3]]);
          } else if (i == 4) {
            this.data1.push([resto.descrMesas[i], resto.cantMesas[i]]);
          } else {
            this.data1.push([resto.descrMesas[i - 1], resto.cantMesas[i - 1]]);
          }
          this.cantMesas += resto.cantMesas[i];
        }
      }
      //consts.logD(this.data1);


      this.config1 = new PieChartConfig('Mesas', 0.4);
      this.elementId1 = 'myPieChart1';
      this.pieTrueBarFalse1 = true;



      //Piechart2 Data & Config
      this.data2 = [];
      this.data2.push(['Productos', 'Cantidad']);

      if (resto.cantMasVend && resto.nombrMasVend && resto.cantMasVend.length == resto.nombrMasVend.length) {
        let huboComensales: boolean = false;
        for (let i = resto.cantMasVend.length - 1; i >= 0; i--) {
          if (resto.nombrMasVend[i] === "Comensales") {
            this.textoComensales = "" + resto.cantMasVend[i];
            huboComensales = true;
          } else {
            this.data2.push([resto.nombrMasVend[i], resto.cantMasVend[i]]);
            this.cantMasVendidos += resto.cantMasVend[i];
          }
        }
        if (!huboComensales) {
          this.textoComensales = "No hay datos";
        }
      }
      // consts.logD(this.data2);

      this.config2 = new PieChartConfig('Productos más vendidos', 0.4);
      this.elementId2 = 'myPieChart2';
      this.pieTrueBarFalse2 = false;



      this.dibujaChartDetalle(resto);


    }
  }

  dibujaChartDetalle(resto: Resto) {
    this.ventasGraf = 0;

    //Ventas Graf
    //Line Chart Ventas
    this.data4 = [];


    if (resto.ventasDescr && resto.ventasDia && resto.grafDto && resto.grafEfectivo && resto.grafEgresos &&
      resto.grafEliminados && resto.grafEntradas && resto.grafIngresos && resto.grafOtrosTC && resto.grafPropina &&

      resto.ventasDia.length == resto.ventasDescr.length && resto.ventasDia.length == resto.grafDto.length &&
      resto.ventasDia.length == resto.grafEfectivo.length && resto.ventasDia.length == resto.grafEgresos.length &&
      resto.ventasDia.length == resto.grafEliminados.length && resto.ventasDia.length == resto.grafEntradas.length &&
      resto.ventasDia.length == resto.grafIngresos.length && resto.ventasDia.length == resto.grafOtrosTC.length &&
      resto.ventasDia.length == resto.grafPropina.length) {

      let constEncabezados: any[] = ['Días',
        'Saldo de caja (Entradas - Egresos)',
        'Ventas - Descuentos',
        'Descuentos',
        'Ventas Brutas',
        'Cobros en Efectivo',
        'Egresos de caja',
        'Entradas de Caja (Efectivo + Ingresos Extra + Cobros que SI afectan a la caja)',
        'Tipos de cobro que NO afectan a la caja',
        'Propinas',
        'Ingresos Extra de Caja',
        'Items eliminados en dinero'
      ];
      let encabezado: any[] = [];

      for (let i = 0; i < this.arreBool.length; i++) {
        if (this.arreBool[i]) {
          encabezado.push(constEncabezados[i]);
        }
      }

      this.data4.push(encabezado);

      this.promedios = [];
      for (let i = 0; i < this.arreBool.length - 1; i++) {
        this.promedios.push(0);
      }

      for (let i = 0; i < resto.ventasDescr.length; i++) {
        constEncabezados = [resto.ventasDescr[i], //'Días'
        resto.grafEntradas[i] - resto.grafEgresos[i],//'Saldo de caja (Entradas - Egresos)'
        resto.ventasDia[i],//'Ventas - Descuentos'
        resto.grafDto[i], //'Descuentos'
        resto.ventasDia[i] + resto.grafDto[i],//'Ventas Brutas'
        resto.grafEfectivo[i], //'Cobros en Efectivo'
        resto.grafEgresos[i], //'Egresos de caja'
        resto.grafEntradas[i], //'Entradas de Caja (Efectivo + Ingresos Extra + Cobros que SI afectan a la caja)'
        resto.grafOtrosTC[i], //'Tipos de cobro que NO afectan a la caja'
        resto.grafPropina[i], //'Propinas'
        resto.grafIngresos[i], //'Ingresos Extra de Caja'
        resto.grafEliminados[i]//'Items eliminados en dinero'
        ];

        encabezado = [];

        let j: number = 0;
        for (let i = 0; i < this.arreBool.length; i++) {
          if (this.arreBool[i]) {
            encabezado.push(constEncabezados[i]);

          }
          if (i > 0) {
            this.promedios[i - 1] += constEncabezados[i];
            this.ventasGraf += constEncabezados[i];
          }
        }


        this.data4.push(encabezado);
      }
      for (let i = 0; i < this.promedios.length; i++) {
        this.promedios[i] = this.promedios[i] / resto.ventasDescr.length;
      }
    }
    // consts.logD(this.data2);

    this.config4 = new LineChartConfig('Detalle de Ventas por jornada', '');
    this.elementId4 = 'myPieChart4'; //+ cantVecesDibujado;
  }

  marca() {
    return consts.marca(this.infoCuenta);
  }

  ngOnInit() {
    if (this.suscripcionUser) {
      this.suscripcionUser.unsubscribe();
    }
    if (this.suscripcionEmails) {
      this.suscripcionEmails.unsubscribe();
    }
    if (this.suscripcionEmailsCuentas) {
      this.suscripcionEmailsCuentas.unsubscribe();
    }
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }
    this.offset = (new Date()).getTimezoneOffset();

    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });
    this.suscripcionUser = this.authService.user.subscribe(user => {
      if (user && user.email && user.email.length > 3) {
        //this.loading = true
        //this.rutaService.changeRuta("bienvenido")
        this.rutaService.changeRuta("main")

        if (this.authService.rutaAlAQueIba && this.authService.rutaAlAQueIba.length > 0) {
          //ya teniamos una ruta
          //this.loading = false
          this.router.navigateByUrl(this.authService.rutaAlAQueIba)
          this.authService.setRutaAlaQueIba(null)
        } else {
          //leemos emails si tiene algo entonces va a main
          this.suscripcionEmails = this.db.object("emails/" + this.authService.decodeEmail(user.email)).snapshotChanges().subscribe(action => {
            //consts.logD(action.type);
            //consts.logD(action.key);
            //consts.logD(action.payload.val());
            let myMap: any = action.payload.val();

            if (myMap) {
              let cuentasX = []
              for (let key in myMap) {
                let c: Cuenta = new Cuenta()
                c.id = key
                c.name = myMap[key]
                cuentasX.push(c)
                if (!this.cuenta) {
                  this.cuenta = c
                  this.authService.changeCuenta(c)
                }
              }
              this.rutaService.changeRuta("main")

              this.authService.changeCuentas(cuentasX)
              //              this.router.navigateByUrl("mypopapp/0")
              //this.loading = false

              //this.ruta = "main"

            } else {


              this.suscripcionEmailsCuentas = this.db.object("emailsCuentas/" + this.authService.decodeEmail(user.email)).snapshotChanges().subscribe(actionLead => {
                //consts.logD(action.type);
                //consts.logD(action.key);
                //consts.logD(action.payload.val());
                if (actionLead && actionLead.payload && actionLead.payload.val()) {

                  let emailCuenta: EmailCuenta = <EmailCuenta>actionLead.payload.val()
                  this.authService.emailCuenta = emailCuenta
                  if (emailCuenta.nombre && emailCuenta.pais && emailCuenta.wapp && emailCuenta.nombreLocal && !emailCuenta.servidores) { //si no tiene servidores quiere decir q no instalo todavia
                    //es alguien q ya lleno el formulario de bievenido, por lo tanto vamos a descargas
                    //this.loading = false
                    //this.ruta = "descargas"
                    this.router.navigateByUrl('/mypopapp/descargar');
                    this.rutaService.changeRuta("descargas")

                  } else {
                    // entonces nunca lleno el formulario
                    //this.loading = false
                    //this.ruta = "bienvenido"

                    this.tieneDatos = false;
                    // if (emailCuenta.nombre && emailCuenta.pais && emailCuenta.wapp && emailCuenta.nombreLocal) {
                    //   this.router.navigateByUrl('/mypopapp/descargar');
                    //   this.rutaService.changeRuta("descargas")
                    // } else {
                    //   this.router.navigateByUrl('/mypopapp/bienvenido');
                    //   this.rutaService.changeRuta("bienvenido")
                    // }
                  }

                  /*if (!emailCuenta.verificadoEmail && !emailCuenta.mandoMailVerificacion) {
                    this.authService.sendEmailVerif(user.email)
                  }*/
                } else {
                  //no tiene ningun dato de lead ni de restaurant
                  let emailCuenta: EmailCuenta = new EmailCuenta()
                  emailCuenta.email = user.email
                  emailCuenta.nombre = user.displayName
                  this.authService.emailCuenta = emailCuenta

                  //this.loading = false
                  //this.ruta = "bienvenido"

                  // this.router.navigateByUrl('/mypopapp/bienvenido');
                  // this.rutaService.changeRuta("bienvenido")
                  this.tieneDatos = false;

                  //this.authService.sendEmailVerif(user.email)
                }


              });
            }
          });
        }


      } else {
        console.log("Usuario sin email")
        //esto no deberia pasar nunca
      }

    })








    this.subscriptionCuenta = this.authService.cuenta$
      .subscribe(cuenta => {
        consts.logD("cambios", "cuentaSelected().subscribe");
        consts.logD(cuenta, "cuentaSelected().subscribe");
        if (cuenta) {
          this.cuenta = cuenta;
          if (this.idCuentaSelected != this.cuenta.id) {
            this.idCuentaSelected = this.cuenta.id;
            this.leerDatosResto(this.cuenta);
          }
        } else {
          //this.authService.changeCuenta(null)

          // this.router.navigateByUrl('/mypopapp/bienvenido');

        }
      });
    this.rutaService.changeRuta("main")
    this.activatedRoute.queryParams.subscribe(params => {
      let subUrl = params['sub'];
      /*if (subUrl == "micuenta" || subUrl == "miplan" ||
        subUrl == "stock" || subUrl == "ventas" ||
        subUrl == "adminCuentas" || subUrl == "adminEmails") {*/
      if (subUrl)
        this.router.navigateByUrl('/mypopapp/' + subUrl);
      //}
    });

  }

  ngOnDestroy(): void {
    if (this.suscripcionUser) {
      this.suscripcionUser.unsubscribe();
    }
    if (this.suscripcionEmails) {
      this.suscripcionEmails.unsubscribe();
    }
    if (this.suscripcionEmailsCuentas) {
      this.suscripcionEmailsCuentas.unsubscribe();
    }
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }
    //this.ref.snapshotChanges().unsubscribe();
    // prevent memory leak when component is destroyed
    //this.subscriptionCuenta.unsubscribe();
  }

}
