
import { Component, Input, OnInit } from '@angular/core';
import { BarChartConfig } from '../barchartconfig';
import { GooglebarchartService } from '../googlebarchart.service';

declare var google: any;

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent implements OnInit {

  @Input() _data: any[];
  @Input() config: BarChartConfig;
  @Input() elementId: string;
  @Input() _mobileQueryMatches: boolean;


  @Input() set data(value: any[]) {
    if (value) {
      this._data = value;
      this._barChartService.BuildBarChart(this.elementId, this.data, this.config);
    }
  }

  get data(): any[] {
    return this._data;
  }

  @Input() set mobileQueryMatches(value: boolean) {
    this._mobileQueryMatches = value;
    this._barChartService.BuildBarChart(this.elementId, this.data, this.config);
  }

  get mobileQueryMatches(): boolean {
    return this._mobileQueryMatches;
  }


  constructor(private _barChartService: GooglebarchartService) { }

  ngOnInit() {
  }

}

