import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Locacion from 'src/app/admin2/models/locacion';
import Servidor from 'src/app/admin2/models/servidor';
import { SelectData } from 'src/app/admin2/models/types';
import { CommonService } from 'src/app/admin2/services/common.service';
import { ServidoresService } from 'src/app/admin2/services/servidores.service';
import { ToastsService } from 'src/app/admin2/services/toasts.service';


@Component({
  selector: 'app-modal-edit-servidor',
  templateUrl: './modal-edit-servidor.component.html',
  styleUrls: ['./modal-edit-servidor.component.scss']
})
export class ModalEditServidorComponent implements OnInit {
  titulo: string;
  servidor: Servidor | null; //en caso de editar servidor
  formularioServidor: FormGroup;
  createMode: boolean; //Indica si el componente se instancia para crear un nuev servidor
  locacionDeServidor: Locacion | null;
  locacions: Locacion[] | null;
  paisesForm: SelectData[];
  guardandoDatos: boolean;

  monedas = [
    { value: 'USD', label: 'Dólar ' },
    { value: 'UF', label: 'UF' },
    { value: 'ARS', label: 'Peso Argentino' }
  ];

  tipoComercios = [
    { value: 'Tradicional', label: 'Tradicional' },
    { value: 'Dark Kitchen', label: 'Dark Kitchen' },
    { value: 'Dark Store', label: 'Dark Store' },
    { value: 'Otro', label: 'Otro' },
  ];

  constructor(
    private servidoresSrv: ServidoresService,
    private dialogRef: MatDialogRef<ModalEditServidorComponent>,
    private toastSrv: ToastsService,
    private datePipe: DatePipe,
    private commonSrv: CommonService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    console.log('data edit modal', data);
    this.titulo = data.titulo;
    this.servidor = data.servidor;
    this.createMode = data.createMode;
    this.locacionDeServidor = data.locacionDeServidor;
    this.paisesForm = data.paisesForm;
  }

  ngOnInit(): void {
    this.formularioServidor = new FormGroup({
      // no modificables
      'id': new FormControl(this.servidor ? `L${this.servidor.pkLocal}M${this.servidor?.pkManager}` : null),
      'keyLocal': new FormControl(this.servidor?.keyLocal),
      'mac': new FormControl(this.servidor?.mac),
      'idLocacion': new FormControl(this.locacionDeServidor?.id || this.servidor?.idLocacion),
      'fechaInstalacion': new FormControl((this.createMode ? new Date() : this.servidor?.fechaInstalacion)),
      'ultimaApertura': new FormControl(this.servidor?.ultimaApertura),
      // 'fechaBaja': new FormControl(this.servidor?.fechaBaja),
      // modificables obligatorios
      'nombre': new FormControl(this.createMode ? this.locacionDeServidor.nombre : this.servidor?.nombreLocal, [Validators.required, Validators.minLength(5)]),
      'numeroLocal': new FormControl(this.createMode ? '900' : this.servidor?.numeroLocal, [Validators.required]),
      'offset': new FormControl(this.servidor?.offset, [Validators.min(-12), Validators.max(12)]),
      'activo': new FormControl(this.createMode ? 0 : this.servidor?.activo, [Validators.required]),
      'monedaLocal': new FormControl(this.servidor?.monedaLocal, [Validators.required]),
      //no obligatorios 
      //Configuración manager
      'versionDisponible': new FormControl(this.servidor?.versionDisponible),
      'fechaFinPruebaFull': new FormControl(this.createMode ? new Date() : this.servidor?.fechaFinPruebaFull),
      //Pagos 
      'fechaVencimiento': new FormControl(this.createMode ? new Date() : this.servidor?.fechaVencimiento),
      'pagoHasta': new FormControl(this.createMode ? new Date() : this.servidor?.pagoHasta),
      'planN': new FormControl(this.servidor?.planN, [Validators.required, Validators.min(0), Validators.max(3)]),
      'importe': new FormControl(this.servidor?.importe),
      'pagaIva': new FormControl(this.servidor?.pagaIva),
    });
    console.log(`createMode ${this.createMode}, form: ${this.formularioServidor}`)
  }

  guardarServidor() {
    this.guardandoDatos = true;
    if (this.createMode) this.nuevoServidor()
    else this.actualizarServidor();
  }

  nuevoServidor() {
    const servidorAGuardar = this.generarServidorAGuardar();
    this.servidoresSrv.postServidores(servidorAGuardar).subscribe({
      next: (data: {data: Servidor}) => {
        this.dialogRef.close({ servidorCreado: data.data });
      },
      error: (err) => {
        this.toastSrv.show('Error al crear servidor: ' + err.error.msg);
        console.error('error al crear servidor', err);
      },
    })
      .add(() => this.guardandoDatos = false);
    ;
  }

  actualizarServidor() {
    const servidorAGuardar = this.generarServidorAGuardar();
    this.servidoresSrv.putServidores(servidorAGuardar).subscribe({
      next: (data) => {
        this.dialogRef.close({ servidorModificado: servidorAGuardar });
      },
      error: (err) => {
        this.toastSrv.show('Error al modificar servidor: ' + err.error.msg);
        console.error('error al modificar servidor', err);
      },
    })
    .add(() => this.guardandoDatos = false);
  }

  generarServidorAGuardar(): Servidor {
    const formControls = this.formularioServidor.controls;
    const servidorGenerado = {
      pkLocal: this.servidor?.pkLocal,
      keyLocal: this.servidor?.keyLocal,
      nombreLocal: formControls.nombre?.value,
      esHub: this.servidor?.esHub,
      offset: formControls.offset?.value,
      monedaLocal: formControls.monedaLocal?.value,
      activo: formControls.activo?.value,
      idHub: this.servidor?.idHub,
      idLocacion: this.locacionDeServidor.id || this.servidor?.idLocacion,
      pkManager: this.servidor?.pkManager,
      mac: this.servidor?.mac,
      nombreManager: formControls.nombre?.value,
      ultimaApertura: formControls.ultimaApertura?.value,
      fechaInstalacion: formControls.fechaInstalacion?.value,
      versionActual: formControls.versionActual?.value,
      planN: formControls.planN?.value,
      fechaFinPruebaFull: formControls.fechaFinPruebaFull?.value,
      pagoHasta: formControls.pagoHasta?.value,
      fechaVencimiento: formControls.fechaVencimiento?.value,
      monedaManager: formControls.monedaLocal?.value,
      enUso: this.servidor?.enUso,
      showPagar: true,
      numeroLocal: formControls.numeroLocal?.value,
      estado: formControls.estado?.value,
      fiscal: false,
      importe: formControls.importe?.value,
      tester: this.servidor?.tester,
      pagaIva: formControls.pagaIva?.value,
      ultimaActualizacion: this.servidor?.ultimaActualizacion,
      versionDisponible: formControls.versionDisponible?.value,
    }
    console.log('servidorGenerado', servidorGenerado);
    return servidorGenerado;
  }

  cerrarModal() {
    this.dialogRef.close();
  }

  logForm(){
    console.log('form',this.formularioServidor);
  }

}


