import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { CuentaAdmin } from 'src/app/shared/admin/cuenta-admin';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';

import { first } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, Validators } from '@angular/forms';
import { LocalAdmin } from 'src/app/shared/admin/local-admin';
import { environment } from 'src/environments/environment';

interface CuerpoCrearSuscripcion {
  email: string;
  currency: string;
  amount: number;
  startDate: string;
  reason: string;
}

@Component({
  selector: 'app-modal-mp',
  templateUrl: './modal-mp.component.html',
  styleUrls: ['./modal-mp.component.scss']
})
export class ModalMpComponent implements OnInit {
  s: ServidorAdmin;
  cuenta: CuentaAdmin;
  submitted = false;
  error: string;
  loading: boolean;
  escribirLastId: boolean = true;
  quotationUF: number;
  quotationUSD: number;
  quotationUSDCLP: number;
  email: any;


  total: number;
  cotizacionString: string;
  totalString: string;

  base = environment.urls.functions + "/";
  // base = "http://localhost:5000/popappowner/us-central1/";


  private isValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  form1 = this.fb.group({
    email: ['',
      [Validators.pattern(this.isValidEmail)]],
  })
  totalCotizado: number;


  constructor(
    public dialogRef: MatDialogRef<ModalMpComponent>,
    public auth: AuthService,
    private http: Http,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin, cuenta: CuentaAdmin },
    private snackbar: MatSnackBar,
    private fns: AngularFireFunctions,
    private fb: FormBuilder) {

    this.s = this.data.s;
    this.cuenta = this.data.cuenta;

  }


  async ngOnInit() {
    this.loading = true;
    this.s.idSuscription;
    this.getPayDay(this.cuenta);
    this.totalString = await this.getAmount(this.s, this.cuenta);
    this.loading = false;


  }



  copiarAlPortapapeles(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.snackbar.open("Copiado al portapapeles", "")._dismissAfter(2000);
  }

  async crearSuscripcion(s: ServidorAdmin) {
    try {
      const url = this.base + "mercadopago/crearSuscripcion/" + this.s.idCuenta;
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });

      const date = this.convierteFechaConBarrasAIso(s.pagoHasta);
      date.setHours(9);

      const fecha = date.toISOString().replace("Z", "-03:00")

      const email = this.form1.get("email").value;
      let amount = this.total;
      if (this.totalCotizado) {
        amount = this.totalCotizado;
      }
      const body: CuerpoCrearSuscripcion = {
        amount: amount,
        currency: this.getCurrencyId(s),
        email: email,
        reason: "Licencia Popapp " + this.s.nombreCuenta,
        startDate: fecha,
      }
      let res = await this.http.post(url, body, options).toPromise();
      let jsonRespuesta = await res.json();
      console.log("Exito", jsonRespuesta);
      this.snackbar.open("Exito", "")._dismissAfter(2000);
    } catch (e) {
      console.log("ERROR AL CREAR SUSCRIPCION", e);
      this.snackbar.open("Error", "")._dismissAfter(2000);
    }
  }


  convierteFechaConBarrasAIso(fechaEn_dd_mm_YYYY: string): Date | undefined {
    if (fechaEn_dd_mm_YYYY) {
      const parts = fechaEn_dd_mm_YYYY.split('/');
      // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
      // January - 0, February - 1, etc.
      const mydate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
      return mydate;
    } else {
      return undefined;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getCountry(s: ServidorAdmin) {
    if (s.pais) {
      return s.pais
    } else {
      return undefined;
    }
  }

  getCurrencyId(s: ServidorAdmin) {

    let country = this.getCountry(s);
    let currencyId: string;

    if (country) {
      if (country === "CHI") {
        currencyId = "CLP";
        return currencyId;
      } else if (country === "ARG" && s.moneda === 'USD') {
        currencyId = "ARS";
        return currencyId;
      } else if (country === "ARG" && s.moneda === 'ARS $') {
        currencyId = "ARS";
        return currencyId;
      } else return s.moneda;
    }
  }

  async getQuotationUF() {
    // const url = "https://mindicador.cl/api/uf/";
    const url = environment.urls.functions + "/cotizacion/cotizacion/UF/CLP";

    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.serie && body.serie[0] && body.serie[0].valor) {
          this.quotationUF = Math.floor(body.serie[0].valor);
        } else if (body && body.data && body.data.cotizacion) {
          this.quotationUF = body.data.cotizacion;
        }
      })
    //.catch(this.handleErrorObservable); (Preguntar por esto)
  }

  async getQuotationUSDCLP() {
    const url = environment.urls.functions + "/cotizacion/cotizacion/USD/CLP";

    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.data && body.data.cotizacion) {
          this.quotationUSDCLP = body.data.cotizacion;
        }
      })
  }

  async getQuotationUSD() {
    const callable = this.fns.httpsCallable("cotizacionUSDARS");
    this.quotationUSD = await callable({}).pipe(first()).toPromise();

    // .subscribe(v => {
    //   this.QuotationUSD = v;
    //   console.log("soy el ", v)
    // });
  }

  async getAmount(s: ServidorAdmin, c: CuentaAdmin): Promise<string> {
    let currencyId = this.getCurrencyId(s);
    let totalAux = 0;
    let auxTotal = 0;

    for (let idLocal in c.locales) {

      let local = c.locales[idLocal];
      if (this.esUnLocalActivo(local)) {
        for (let idServidor in local.servidores) {
          let servidor: ServidorAdmin = local.servidores[idServidor];
          if (this.esUnManagerActivo(servidor)) {
            if (servidor.conceptos) {
              for (let idConcepto in servidor.conceptos) {
                let concepto = servidor.conceptos[idConcepto];
                auxTotal += concepto.total;
                if (concepto.cuotasPendientes && concepto.cuotasPendientes <= 0) {
                  auxTotal -= concepto.total;
                }
              }
            }
          }
        }
      }
    }
    this.total = auxTotal;
    if (currencyId === "CLP") {
      await this.getQuotationUF();
      this.cotizacionString = "1 UF = " + this.quotationUF + " CLP"
      totalAux = +(auxTotal * this.quotationUF).toFixed();
      this.totalCotizado = totalAux;
      return totalAux + " CLP";
    } else if (currencyId === "ARS" && s.moneda === "USD") {
      await this.getQuotationUSD();
      this.cotizacionString = "1 USD = " + this.quotationUSD + " ARS"
      totalAux = +(auxTotal * this.quotationUSD).toFixed(2);
      this.totalCotizado = totalAux;

      return totalAux + " ARS";
    } else if (currencyId === "CLP" && s.moneda === "USD") {
      await this.getQuotationUSDCLP();
      this.cotizacionString = "1 USD = " + this.quotationUSDCLP + " CLP"
      totalAux = +(auxTotal * this.quotationUSDCLP).toFixed(2);
      this.totalCotizado = totalAux;

      return totalAux + " CLP";
    } else {
      this.totalCotizado = 0;
      this.total = +this.total.toFixed(2);
      return this.total + " " + currencyId;
    }
  }

  esUnManagerActivo(s: ServidorAdmin): boolean {

    if (s.nombreServidor &&
      s.numeroLocal && 0 < +s.numeroLocal &&
      +s.numeroLocal < 9000 &&
      +s.numeroLocal !== 900
      && s.nombreServidor !== 'Costo alta de licencia') {
      return true;
    } else {
      return false;
    }
  }

  esUnLocalActivo(local): boolean {

    for (let idMac in local.servidores) {
      const servidorLocal = local.servidores[idMac];

      if (idMac && this.esUnManagerActivo(servidorLocal)) {
        return true;
      }
    }
    return false;
  }

  fechaActual(fecha: string) {
    const fdate = this.convierteFechaConBarrasAIso(fecha);
    if (fdate && fdate.getTime() < Date.now()) {
      return false;
    } else {
      return true;
    }
  }

  async cancelarSuscripcion() {
    try {
      const url = this.base + "mercadopago/cancelarSuscripcion/" + this.s.idSuscription + "/" + this.s.idCuenta + '/' + this.getCurrencyId(this.s);
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });

      let res = await this.http.get(url, options).toPromise();
      let jsonRespuesta = await res.json();
      console.log("Exito", jsonRespuesta);
      this.snackbar.open("Exito", "")._dismissAfter(2000);
    } catch (e) {
      console.log("ERROR AL CANCELAR", e);
      this.snackbar.open("Error", "")._dismissAfter(2000);
    }
  }
  async modificarPrecioSuscripcion() {
    try {
      const url = this.base + "mercadopago/modificarSuscripcion/precio/" + this.s.idCuenta;
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });

      let res = await this.http.get(url, options).toPromise();
      let jsonRespuesta = await res.json();
      console.log("Exito", jsonRespuesta);
      this.snackbar.open("Exito", "")._dismissAfter(2000);
    } catch (e) {
      console.log("ERROR AL MODIFICAR PRECIO SUSCRIPCION", e);
      this.snackbar.open("Error", "")._dismissAfter(2000);
    }
  }

  alerta(s: ServidorAdmin) {
    const c = (confirm("Esta seguro que desea cancelar la suscripcion ?"));
    if (c) {
      this.cancelarSuscripcion();
      //  this.dbFs.doc('/cuentas'+ s.idCuenta+ '/suscripciones').delete;
    }
  }

  cargaremail(email, s: ServidorAdmin) {
    this.email = email;
    const c = (confirm("Esta seguro que desea cargar este email para notificar la suscripcion ?"));
    if (c) {

    }
  }

  getError(field: string) {
    let m;
    if (this.form1.get(field).errors.required) {
      m = "El campo " + field + " es requerido"
    } else if (this.form1.get(field).errors.pattern) {
      m = "Mail no valido. Producto te esta mirando"
    }
    return m;
  }

  isValidField(field: string): boolean {
    return this.form1 && this.form1.get(field) && this.form1.get(field).valid;
  }

  validaMoneda(s: ServidorAdmin) {

    switch (s.moneda) {
      case "USD":
        return true;
      case "ARS $":
        return true;
      case 'UF':
        return true;
      default: return false;
    }

  }


  async getPayDay(cuenta: CuentaAdmin): Promise<Date | undefined> {
    let fechaAux: Date | undefined = undefined;
    for (let idLocal in cuenta.locales) {
      let local: LocalAdmin = cuenta.locales[idLocal];
      if (this.esUnLocalActivo(local)) {
        for (let idServidor in local.servidores) {
          let servidor: ServidorAdmin = local.servidores[idServidor];
          if (this.esUnManagerActivo(servidor)) {
            if (servidor.pagoHasta) {
              if (!fechaAux) {
                fechaAux = this.convierteFechaConBarrasAIso(servidor.pagoHasta)
                this.s.pagoHasta = servidor.pagoHasta;
              } else {
                let payDay: Date | undefined = this.convierteFechaConBarrasAIso(servidor.pagoHasta)
                if (payDay && fechaAux.getTime() < payDay.getTime()) {
                  fechaAux = payDay;
                  this.s.pagoHasta = servidor.pagoHasta;
                }
              }
            }
          }
        }
      }
    }
    return fechaAux;
  }


}