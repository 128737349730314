import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConceptModalComponent } from './concept-modal/concept-modal.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Account from './models/account';
import { ERRORS, PricingService } from './pricing.service';
import Premise from './models/premise';
import Pricing from './models/pricing';
import Billing from './models/billing';
import { ToastsService } from 'src/app/admin2/services/toasts.service';
import { environment } from 'src/environments/environment';
import { Country } from './models/misc';
import Discount from './models/discount';

@Component({
  selector: 'app-modal-pricing-billing',
  templateUrl: './modal-pricing-billing.component.html',
  styleUrls: ['./modal-pricing-billing.component.scss']
})
export class ModalPricingBillingComponent implements OnInit {

  @Input() popappAccountId: string;

  @Input() popappPremiseId: string;

  loadingData = true;

  migratingAccount = false;

  account: Account;

  premise: Premise;

  pricings: Pricing[] = [];

  fixedBillings: Billing[] = [];

  variableBillings: Billing[] = [];

  premiseBillings: Billing[] = [];

  indexShown = 1;

  editMode = false;

  loadingItem = false;

  pricingId: number;

  fixedPricings: Pricing[] = [];

  countries: Country[] = [];

  countryIdSelected: number;

  countryChanged = false;

  newDiscountsId: number[] = [];

  constructor(
    private pricingSrv: PricingService,
    private toastSrv: ToastsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (environment.production === false && !this.popappAccountId && !this.popappPremiseId) {
      this.popappAccountId = '-N14714zw4vQrxAtgijT';
      this.popappPremiseId = '98EECB6433A8';
    }
    if (!this.popappAccountId || !this.popappPremiseId) {
      throw new Error('missing accountId, premiseId');
    }
    this.initAccount();
  }

  initAccount() {
    this.pricingSrv.getAccount(this.popappAccountId).subscribe(
      res => {
        this.account = res.data;
        this.premise = this.account.premises.find(p => p.popapp_id === this.popappPremiseId);
        console.log('account', this.account);
        console.log('premise', this.premise);
        this.initPremiseBillings();
        this.initCountries();
        if(this.account.country_id){
          this.initPricings();
        }
      },
      err => {
        console.error('error getting account', err);
      }
    ).add(() => {
      this.loadingData = false;
    });
  }

  initPricings() {
    this.pricingSrv.getPricings(this.account.country_id).subscribe(
      res => {
        this.pricings = res.data;
        this.fixedPricings = res.data.filter(p => p.fixed)
        console.log('pricings', this.pricings);
      },
      err => {
        console.error('error getting pricings', err);
      }
    );
  }

  initCountries() {
    this.pricingSrv.getCountries().subscribe(
      res => {
        this.countries = res.data;
        if (!this.countryIdSelected) {
          this.countryIdSelected = this.account.country.id || this.countries[0].id;
        }
      },
      err => {
        console.error('error getting countries', err);
      }
    )
  }

  initPremiseBillings() {
    this.pricingSrv.getBillingsFromAccount(this.account.id).subscribe(
      res => {
        this.premiseBillings = this.getPremiseBillings(res.data);
        this.updateBillings();
        console.log('accountBillings', this.premiseBillings);
      },
      err => {
        console.error('error getting billings', err);
      },
    );
  }

  getPremiseBillings(accountBillings: Billing[]) {
    return accountBillings.filter(b => b.premises_id === this.premise?.id) || [];
  }

  updateBillings() {
    //error es pq el formato que deuvleve billing es diferente al crear descuento
    this.fixedBillings = this.premiseBillings.filter(b => b.pricing.fixed);
    this.variableBillings = this.premiseBillings.filter(b => !b.pricing.fixed);
  }

  onClickEdit() {
    this.editMode = !this.editMode;
  }

  onBillingDeleted(billing: Billing) {
    this.pricingSrv.deleteBilling(billing.id).subscribe(
      _ => {
        this.premiseBillings = this.premiseBillings.filter(b => b.id !== billing.id);
        this.updateBillings();
        this.toastSrv.show('Concepto eliminado con éxito');
      },
      err => {
        console.error('error deleting billing', err);
        this.toastSrv.show('Error al eliminar concepto');
      }
    );
  }

  onClickAddConcept() {
    const dialogRef = this.dialog.open(ConceptModalComponent, {
      width: '85%',
      data: {
        pricings: this.pricings,
        accountBillings: this.premiseBillings,
        accountId: this.account.id,
        premiseId: this.premise.id
      }
    });

    dialogRef.afterClosed().subscribe((result: Partial<{ accountBillings: Billing[], billingUpdated: Billing, discountAdded: Discount }>) => {
      if (result.accountBillings) {
        this.premiseBillings = this.getPremiseBillings(result.accountBillings);
        this.updateBillings();
      }
      if (result.billingUpdated) {
        const i = this.premiseBillings.findIndex(b => b.id === result.billingUpdated.id);
        this.premiseBillings[i] = result.billingUpdated;
        this.updateBillings();
      }
      if(result.discountAdded){
          this.newDiscountsId.push(result.discountAdded.id);
      }
    });
  }

  onClickMigrateAccount() {
    this.migratingAccount = true;
    this.pricingSrv.migrateAccount(this.popappAccountId).subscribe(
      res => {
        this.initAccount();
      },
      err => {
        if(err.error.error === ERRORS.PREMISE_MAIL_EMPTY){
          this.toastSrv.show('La cuenta no tiene un correo asignado');
          return;
        }
        this.toastSrv.show('Error al migrar la cuenta');
        console.error('error migrating account', err);
      }
    )
      .add(() => this.migratingAccount = false);
  }

  formatCurrency(value: number) {
    return `${this.premiseBillings?.[0]?.pricing.base_currency?.code} ${value}`;
  }

  getFixedTotal() {
    return this.fixedBillings.reduce((acum, c) => c.total + acum, 0);
  }

  getVariableTotal() {
    return this.variableBillings.reduce((acum, c) => c.total + acum, 0);
  }

  onClickSetPricing() {
    this.loadingItem = true;
    this.pricingSrv.createBilling(this.account.id, this.premise.id, this.pricingId).subscribe(
      res => {
        this.initPremiseBillings();
      },
      err => {
        console.error('error creating plan', err);
        this.toastSrv.show('Error al crear plan');
      }
    )
      .add(() => this.loadingItem = false);
  }

  getFixedPricings() {
    return this.pricings.filter(p => p.fixed);
  }

  onBillingModified(data: { old: Billing, new: Billing }) {
    const i = this.premiseBillings.findIndex(b => b.id === data.old.id);
    this.premiseBillings[i] = data.new;
    this.updateBillings();
  }

  onCountryChange(id: number) {
    this.countryChanged = true;
    console.log('selected', this.countryIdSelected, 'all', this.countries);
  }

  onCountrySave() {
    this.loadingItem = true;
    this.pricingSrv.updateAccount(this.countryIdSelected, this.account.id).subscribe(
      res => {
        this.account = res.data;
        this.toastSrv.show('País actualizado con éxito');
        this.initPricings();
        this.countryChanged = false;
        this.pricingId = null;
      },
      err => {
        console.error('error updating country', err);
        if(err.error.error === ERRORS.ACCOUNT_HAS_BILLING){
          this.toastSrv.show('No se puede modificar el país de una cuenta con planes configurados');
          return
        }
        this.toastSrv.show('Error al actualizar país');
      }
    ).add(() => this.loadingItem = false)
  }
}
