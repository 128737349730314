import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DbFSService } from 'src/app/services/db-fs.service';
import Swal from 'sweetalert2';

export interface Reporte {
  _key: string;
  asunto: string;
  cliente: string;
  fecha: string;
  keyLocal: string;
  mac: string;
  mensaje: string;
  sistema: string;
  version: string;
  estado: string;
}

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})

export class ReportesComponent implements OnInit, OnDestroy {
  selectValue: string[];
  //plataformaValues: string[];
  estadoValues: string[];
  reportes: Reporte[] = [];
  subs: Subscription;

  ABIERTO = "Abierto";
  CERRADO = "Cerrado";
  ELIMINADO = "Eliminado";

  resultados: string;

  pageSize = 10;

  constructor(private dbFs: DbFSService, private snackbar: MatSnackBar) { }

  ngOnDestroy() {
    if (this.subs) { try { this.subs.unsubscribe() } catch (error) { console.log(error) } }
  }

  ngOnInit() {
    //TODO: Cargar locales disponibles
    this.selectValue = ['Sacro', 'Food Fast', 'California', 'Nevada', 'Oregon', 'Washington', 'Arizona', 'Colorado', 'Idaho', 'Montana', 'Nebraska', 'New Mexico', 'North Dakota', 'Utah', 'Wyoming', 'Alabama', 'Arkansas', 'Illinois', 'Iowa'];
    //this.plataformaValues = ['pya', 'rapar', 'rapcl', 'rappe', 'rapmx', 'glo', 'ube', 'mas', 'jus'];
    this.estadoValues = [this.ABIERTO, this.CERRADO, this.ELIMINADO];

    this.onFiltrarBusqueda(null);

  }

  onDeleteReport(idReporte: string) {
    Swal.fire({
      title: 'Estas seguro que quieres borrar el reporte?',
      text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrarlo!',
      cancelButtonText: 'No, me confundí...'
    }).then((result) => {
      if (result.value) {
        this.dbFs.updateAt("reportes2/" + idReporte, {
          estado: this.ELIMINADO
        });
        Swal.fire('Borrado!', 'El reporte ha sido borrado.', 'success');
      }
    });
  }

  onCloseReport(idReporte: string) {
    Swal.fire({
      title: 'Estas seguro que quieres cerrar el reporte?',
      // text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cerrarlo!',
      cancelButtonText: 'No, me confundí...'
    }).then((result) => {
      if (result.value) {
        this.dbFs.updateAt("reportes2/" + idReporte, {
          estado: this.CERRADO
        });
        Swal.fire('Listo!', 'El reporte ha sido cerrado.', 'success');
      }
    });
  }

  onReopenReport(idReporte: string) {
    Swal.fire({
      title: 'Estas seguro que quieres marcar el reporte como abierto?',
      // text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, porfavor',
      cancelButtonText: 'No, me confundí...'
    }).then((result) => {
      if (result.value) {
        this.dbFs.updateAt("reportes2/" + idReporte, {
          estado: this.ABIERTO
        });
        Swal.fire('Listo!', 'El reporte ha sido reabierto.', 'success');
      }
    });
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    this.snackbar.open("Copiado al portapapeles")._dismissAfter(2000);
  }

  onFiltrarBusqueda(form: NgForm, prevONext?: string) {

    this.resultados = undefined;

    this.dbFs.collection$<Reporte>('reportes2', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      //Filtro estado
      // if (form && form.controls.estado.value && form.controls.estado.value != "") { query = query.where('estado', '==', form.controls.estado.value) };
      //Filtro plataforma
      //if (form.controls.plataforma.value && form.controls.plataforma.value.length > 0) { query = query.where('plataforma', 'in', form.controls.plataforma.value) };
      //Filtro fechaDesde
      // if (form && form.controls.fechaDesde.value != "") {
      //   let dateStr = form.controls.fechaDesde.value.slice(0, 4) + '-' + form.controls.fechaDesde.value.slice(5, -3) + '-' + form.controls.fechaDesde.value.slice(-2);
      //   query = query.where('fecha', '>=', dateStr);
      // }

      //Filtro fechaHasta
      // if (form && form.controls.fechaHasta.value != "") {
      //   let dateStr = form.controls.fechaHasta.value.slice(0, 4) + '-' + form.controls.fechaHasta.value.slice(5, -3) + '-' + form.controls.fechaHasta.value.slice(-2);
      //   //se añade el tiempo para que ese día sea incluído
      //   dateStr += 'T24:00:00';
      //   query = query.where('fecha', '<=', dateStr);
      // }


      if (form && form.controls.asunto.value && form.controls.asunto.value.trim() !== "") {
        query = query.where('asunto', '==', form.controls.asunto.value.trim())
      };


      if (form && form.controls.mac.value && form.controls.mac.value.trim() !== "") {
        query = query.where('mac', '==', form.controls.mac.value.trim())
      };

      // if (form && form.controls.pageSize.value) {
      //   query = query.limit(+form.controls.pageSize.value);
      // } else {

      query = query.limit(this.pageSize);
      //};

      if (form) {
        console.log(form.controls);
      }


      query = query.orderBy('fecha', 'desc');

      if (prevONext) {
        if (prevONext === "prev") {
          query = query.endBefore(this.reportes[0].fecha)
            .limitToLast(this.pageSize);
        } else if (prevONext === "next") {
          query = query.startAfter(this.reportes[this.reportes.length - 1].fecha)
            .limit(this.pageSize);
        }
      }

      return query;
    }).pipe(first()).subscribe(value => {
      this.reportes = [];

      value.map((item: Reporte) => {
        this.reportes.push(item);
      })

      this.resultados = "Mostrando " + this.reportes.length + " resultados ";
      
    });


  }

  // nextPage(last) {

  //   return ref.orderBy(field)
  //     .startAfter(last[field])
  //     .limit(pageSize);
  // }

  // prevPage(first) {

  //   return ref.orderBy(field)
  //     .endBefore(first[field])
  //     .limitToLast(pageSize);
  // }

  copiarTabla() {
    let selector;
    selector = '#tabladatos';
    let table = document.querySelector(selector);
    let range = document.createRange();
    range.selectNodeContents(table)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)

    document.execCommand('copy')

    this.snackbar.open("Se copió la tabla al portapapeles, pega los datos en un archivo de Excel o similar", "", {
      duration: 8000,
    });
  }



}
