import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';

//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/toPromise';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


import { AuthService } from "../../core/auth.service";
import { Router } from '@angular/router';
import { ErrorAdmin } from '../../shared/admin/errorAdmin';
import * as consts from "../../shared/constantes";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-admin-errores',
  templateUrl: './admin-errores.component.html',
  styleUrls: ['./admin-errores.component.scss']
})

export class AdminErroresComponent implements OnInit {

  erroresRef;

  cantLeidos: number = 0;

  environment = environment;

  erroresMap: Map<String, ErrorAdmin>;
  errores: ErrorAdmin[] = [];

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;

  msjSnack: string;

  fecha: any;
  //selectedOptions: ServidorAdmin[];
  @ViewChild(MatSort) sort: MatSort;

  fechaInicioQuery: string;
  fechaFinQuery: string;
  //datos tabla


  displayedColumns: string[] = ['select', 'date', 'sistema', 'sisClase', 'sisMetodo',
    'error', 'nombre', 'idLocal', 'descripcion', 'version', 'firebase'
  ];

  columnsToDisplay: string[] = this.displayedColumns;//.slice(0, 9);

  dataSource: MatTableDataSource<ErrorAdmin> = new MatTableDataSource(this.errores);


  filtroTodasColumnas: boolean = false;

  filtMp: boolean = false;
  filtPYA: boolean = false;


  filtroActual: string = "a";
  filtroActual2: string = "";
  buscandoActual: string;
  filtroClientesActivado: boolean = true;

  actualizaFiltroClientesActivos() {
    this.filtroClientesActivado = !this.filtroClientesActivado;
    this.applyFilter(this.filtroActual);
    this.filtroClientesActivado = !this.filtroClientesActivado;

  }

  actualizaColumnas(value: number, filtro: boolean) {
    if (value == 999) {
      if (filtro) {
        //ocultar columnas
        /*this.columnsToDisplay = this.displayedColumns.slice(0, 5);*/

        this.filtMp = false;
        this.filtPYA = false;


      } else {
        /*this.columnsToDisplay = this.displayedColumns.slice(0, 5);
        for (let i = 5; i < 48; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }*/
        this.filtMp = true;
        this.filtPYA = true;

      }
      this.applyFilter(this.filtroActual);
    } else if (filtro) {

      if (value == 0) {
        this.filtMp = false;
        this.applyFilter(this.filtroActual);
        this.filtMp = true;
      } else if (value == 1) {
        this.filtPYA = false;
        this.applyFilter(this.filtroActual);
        this.filtPYA = true;
      }
    } else {
      //mostrar columnas correspondientes
      this.applyFilter(this.filtroActual);

      if (value == 0) {
        this.filtMp = true;
        this.applyFilter(this.filtroActual);
        this.filtMp = false;

      } else if (value == 1) {
        this.filtPYA = true;
        this.applyFilter(this.filtroActual);
        this.filtPYA = false;
      }
    }

  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = "";

    let filtro = filterValue.trim().toLowerCase();
    if (!filtro || filtro.length == 0) {
      filtro = "a";
      this.filtroActual2 = "";
      this.buscandoActual = "";
    } else {
      if (filtro === "a") {
        this.filtroActual2 = "";
        this.buscandoActual = "";
      } else {
        this.filtroActual2 = filtro;
        this.buscandoActual = filtro;
      }
    }

    this.filtroActual = filtro;
    this.dataSource.filter = filtro;

    this.dataSource.sort = this.sort;


  }


  selection = new SelectionModel<ErrorAdmin>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  driftVar: any;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  esAdmin(user) {
    return consts.esAdmin(user);
  }
  ngOnInit() {
    let date = new Date();
    this.fechaFinQuery = new Date(date.getTime() + 5 * 60 * 60 * 1000).toISOString(); //+5 horas
    this.fechaInicioQuery = new Date(date.getTime() - 30 * 60 * 60 * 1000).toISOString(); //-30 horas
    this.dataSource.sort = this.sort;
    this.buscar();
    this.hideDrift(1);
  }

  constructor(public router: Router, public afAuth: AngularFireAuth, public db: AngularFireDatabase, public authService: AuthService) {
    //console.log("ENTRO");
  }

  buscar() {
    this.afAuth.user.subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {
        // console.log("SUSCRIPTO");

        //leemos las cuentas
        this.itemsRef = this.db.list('errores', ref => {// Use snapshotChanges().map() to store the key
          return ref.orderByChild('fecha').startAt(new Date(this.fechaInicioQuery).getTime()).endAt(new Date(this.fechaFinQuery).getTime())
        });
        this.items = this.itemsRef.snapshotChanges().pipe(
          map(changes => {
            let mapeado = changes.map(errAux => ({ key: errAux.payload.key, ...errAux.payload.val() }));
            //console.log("LLEGO ALGO");
            this.procesaErrs(mapeado);
            this.dataSource.sort = this.sort;
            return mapeado;
          })
        );
      }
    });
  }


  sortHeader(index: number) {
    //console.log("click");
    this.dataSource.sort = this.sort;
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }


  onRightClick(input: string, element: ErrorAdmin) {
    if (input == "cobrar") {
      //leer mails de ese usuario

      return false;
    }
  }


  inputABM(input: string, element: ErrorAdmin) {
    let errsAModificar: ErrorAdmin[] = [];
    if (this.selection.selected && this.selection.selected.length > 0) {
      for (let i = 0; i < this.selection.selected.length; i++) {
        errsAModificar.push(this.selection.selected[i]);
      }
      if (!this.selection.selected.includes(element)) {
        errsAModificar.push(element);
      } //else ya se proceso en el for
    } else {
      //proceso individual
      errsAModificar.push(element);
    }
  }


  procesaErrs(mapErrores: any[]) {

    this.errores = [];
    this.erroresMap = new Map<string, ErrorAdmin>();
    this.errores = [];

    for (let i in mapErrores) {
      let e: ErrorAdmin = mapErrores[i];
      e.idFb = mapErrores[i].key;
      this.erroresMap.set(mapErrores[i].key, e);
      e.error = JSON.stringify(e.error);
      //aca iria poner tipos de MP y esas cosas
      this.errores.push(e);

      // no habria q sumar nada pues no sabemos q moneda iria
    }

    this.dataSource = new MatTableDataSource(this.errores);
    this.dataSource.filterPredicate = (e: ErrorAdmin, filter: string) => {
      if (!this.filtMp && e.sistema && e.sistema.includes("Node MP")) {
        return false;
      }

      if (!this.filtPYA && e.sistema && (e.sistema.includes("glcNodePYa") ||
        (e.sistema.includes("mpAzure") && e.sisClase.includes("pya.js")))) {
        return false;
      }

      return JSON.stringify(e).trim().toLowerCase().indexOf(filter) != -1;
    }
    this.applyFilter(this.filtroActual);
    this.deseleccionarTodes();
  }
  deseleccionarTodes() {
    this.selection.clear();
  }
  getMyStyles(dias: number) {
    let color = 'green';
    if (dias < 0 && dias > -15) {
      color = 'violet';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': 'white',
      'background': color,
      'padding': '10px'
    };
    return myStyles;
  }

  openSnackBar(message: string, action: string) {
    this.msjSnack = "|  " + message + "      " + action + "  |"
    setTimeout(_ => {
      this.msjSnack = null
    }, 3000)
    //this.snackBar.open(message, action, {
    // duration: 2000,
    //});
  }

}
