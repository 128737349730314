import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Subscription } from 'rxjs';
import { Cuenta } from '../shared/cuenta';
import { ServidorAdmin } from '../shared/admin/servidor-admin';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuentaService } from '../services/cuenta.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-mi-plan',
  templateUrl: './mi-plan.component.html',
  styleUrls: ['./mi-plan.component.scss', '../../mypopapp/assets/_css/mypopapp.css', '../../mypopapp/assets/_css/mypopapp-mq.css']
})
export class MiPlanComponent implements OnInit {
  subscriptionCuenta: Subscription;
  macElegida: string = "";
  nombreCuenta: string;
  servidor: ServidorAdmin;
  idLocal: string = "";
  plan: number;
  importes: number[] = [];
  moneda: string;
  planDowngrade: string;
  subscriptionCuentas: Subscription;
  cuentas: Cuenta[] = [];
  planNro: number;
  codigoActivacion: string;
  nombreLocal: string;
  constructor(public authService: AuthService, public snackBar: MatSnackBar, public cuentaService: CuentaService, public router: Router, afAuth: AngularFireAuth) {
  }

  ngOnInit() {
    this.subscriptionCuentas = this.authService.cuentas$
      .subscribe(cuentas => {
        if (cuentas) {
          this.cuentas = cuentas;
        }
      });
    this.subscriptionCuenta = this.authService.cuenta$
      .subscribe(cuenta => {
        if (cuenta) {
          let refMacP = this.authService.leerFB('macsPasadas/' + cuenta.id);
          refMacP.snapshotChanges().subscribe(action => {

            let macPasad = action.payload.val();
            let idCuenta = macPasad['idCuenta'];
            this.idLocal = macPasad['idLocal'];
            this.macElegida = cuenta.id;
            this.leerFBCuenta(idCuenta);

          });
        }
      });
  }
  leerFBCuenta(idCuenta: string): void {
    if (idCuenta && idCuenta.length > 3) {
      let refCuenta = this.authService.leerFB('cuentas/' + idCuenta);
      refCuenta.snapshotChanges().subscribe(action => {
        let cuentaAdmin = action.payload.val();
        let locales = cuentaAdmin['locales'];
        this.nombreCuenta = cuentaAdmin['nombreCuenta'];
        let local = locales[this.idLocal];
        let servidores = local['servidores'];
        this.servidor = servidores[this.macElegida];
        this.servidor.nombreLocal = local['nombreLocal'];
        this.servidor.idCuenta = idCuenta;
        this.servidor.idLocal = this.idLocal;
        this.servidor.idMac = this.macElegida;
        if (this.servidor.moneda)
          this.moneda = this.servidor.moneda;
        else
          this.moneda = "USD $";
        this.leeImportes();
        let downgrade = this.cuentaService.buscaDowngrade(this.servidor);
        if (downgrade) {
          this.planDowngrade = this.cuentaService.planNumberToString(this.servidor['planN']);
          this.plan = this.servidor.downGradeTo;
        }
        else {
          this.plan = this.servidor['planN'];
          this.planDowngrade = null;
        }
        if (this.servidor.pagoHasta) {
          this.codigoActivacion = this.cuentaService.generaCódigoConNuevaFechayPlan(this.servidor);
        }

        if (!this.plan && this.authService.plan) {
          this.upgradePlan(this.authService.plan)
        }
      });
    } else {
      this.snackBar.open("No se pudo leer la cuenta, reintenta más tarde", "", { duration: 4000 });
    }
  }
  selectCuenta(cuenta: Cuenta) {
    this.authService.changeCuenta(cuenta);
  }
  leeImportes() {
    this.importes = [];
    for (let i = 1; i <= 3; i++) {
      this.importes.push(this.cuentaService.importePorPlan(i, this.moneda));
    }
  }
  upgradePlan(plan: number) {
    let estabaEn0 = false;
    if (!this.servidor.planN && !this.servidor.primerPago) {
      estabaEn0 = true;
    }
    if (plan < this.servidor.planN) { //con esto se controla que haya hecho upgrade downgrade upgrade
      this.downgradePlan(plan);
    } else {
      this.cuentaService.upgrade(this.servidor, plan)
        .then(_ => {
          if (estabaEn0) {
            this.router.navigateByUrl("/mypopapp/micuenta")
          } else {
            this.router.navigateByUrl('/mypopapp/miplan/felicitaciones');
          }
        })
        .catch(reason => {
          console.log(reason);
          this.snackBar.open("No se pudo realizar la operación", "Reintentar", { duration: 4000 }).onAction().subscribe(_ => {
            this.upgradePlan(plan);
          });
        });
    }
  }
  downgradePlan(plan: number) {
    this.cuentaService.downgrade(this.servidor, plan)
      .then(_ => {
        this.snackBar.open("La operación se ha realizado con éxito", "", { duration: 4000 });
      })
      .catch(reason => {
        console.log(reason);
        this.snackBar.open("No se pudo realizar la operación", "Reintentar", { duration: 4000 }).onAction().subscribe(_ => {
          this.downgradePlan(plan);
        });
      });
  }

}
