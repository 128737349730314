import { Component, OnInit, Input } from '@angular/core';
import { POnline } from 'src/app/admin/test-pedidos-online/ponliine';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.scss']
})
export class PedidoComponent implements OnInit {
  @Input() pedido: POnline
  constructor() { }

  ngOnInit() {
  }

  fechaHistorica(p: POnline): string[] {
    let date1 = new Date(p.fechaCreacion);
    date1.setHours(date1.getHours() + 3)

    let date2 = new Date(p.procesada);

    let date3 = new Date(p.porconfirmar);

    let date4 = new Date(p.confirmada);

    let res = []
    res.push("Fecha creacion: " + this.fechatDiaMesYYYY(date1));
    res.push("Fecha procesada: " + this.dif(date2, date1));
    res.push("Fecha porconfirmar: " + this.dif(date3, date2));
    res.push("Fecha confirmada: " + this.dif(date4, date3));
    return res;
  }

  fechatDiaMesYYYY(date: Date) {
    return this.num0(date.getDate()) + "/" +
      this.num0(date.getMonth() + 1) + "/" +
      date.getFullYear() + " " +
      this.fechaHoraMinSegs(date)
  }
  fechaHoraMinSegs(date: Date) {
    return this.num0(date.getHours()) + ":" +
      this.num0(date.getMinutes()) + ":" +
      this.num0(date.getSeconds());
  }
  dif(date2: Date, date1: Date) {
    if (date2.getFullYear() === date1.getFullYear() && date2.getMonth() === date1.getMonth() && date2.getDate() === date1.getDate()) {
      return this.fechaHoraMinSegs(date2);
    } else {
      return this.fechatDiaMesYYYY(date2);
    }
  }

  num0(dOm: number): string {
    return (dOm < 10 ? "0" : "") + dOm;
  }


}
