import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manejo-cuentas-nuevo',
  templateUrl: './manejo-cuentas-nuevo.component.html',
  styleUrls: ['./manejo-cuentas-nuevo.component.scss']
})
export class ManejoCuentasNuevoComponent implements OnInit {
  cuentas: any[];
  cuentasBusqueda: any[];
  searchValue: string;
  obteniendo: boolean = false;
  buscandoLocales: boolean = false;
  constructor(private http: Http) { }

  ngOnInit(): void {
    this.obtenerCuentas().then(res => {
      this.cuentas = res.json();
      this.cuentasBusqueda = this.cuentas;
    }).catch(e => {
      console.error("error al obtener cuentas", e)
    })

  }
  obtenerCuentas() {
    return this.http.get(`${environment.urls.onboarding}/v1/cuentas`).toPromise().then(res => {
      this.obteniendo = true;
      return res;
    });
  }
  obtenerLocalesDeCuenta(cuenta: any, $event: MouseEvent) {
    cuenta.botonActivar = true;
    cuenta.buscandoLocales = true;
    console.log("Boton apretado, buscar locales de cuenta:", cuenta);
    let resultadoGet = null;
    // this.http.get(`http://localhost:3000/v1/cuentas/${cuenta.id}/locales`).toPromise().then(res => {
    //   resultadoGet = res.json().locales;
    //   cuenta.locales = resultadoGet;
    //   cuenta.buscandoLocales = false;
    // }).catch(e => {
    //   console.error("Error al obtener locales", e)
    //   cuenta.locales = null;
    //   cuenta.buscandoLocales = false;
    // });
    return;
  }
  buscarEnCuentas(nombre: string) {
    this.cuentasBusqueda = this.cuentas.filter((c: any) => c.nombre ? c.nombre.toLocaleLowerCase().includes(nombre.toLocaleLowerCase()) > 0 : false)
    console.log("cuentas", this.cuentasBusqueda)
  }

}
