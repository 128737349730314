import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


/**
 * ejemplo
 * {
 *  asunto: "popapp ARG Pago Aprobado $3068 08/07/2021 06:42",
 *  cantMeses: 1,
 *  fecha: "2021-07-22T15:05:29.275Z",
 *  importe: 3068,
 *  iva: 21,
 *  nuevoVencimiento: "01/08/2021",
 *  pais: "ARG",
 *  text: "popapp ARG Pago Aprobado $3068 08/07/2021 06:42Neto Recibido: $2938.53Pago Lic. Popapp Clapton | Total: 3068.0000000000005 ARS | Datos de facturación: 33707514839  -  ksualsrl@gmail.com | 1 mes | Próximo vencimiento licencia: 01 Ago. 2021null nullksualsrl@gmail.comNueva Fecha: 01/08/2021",
 *  tipo: "Mercado Pago",
 *  to: "facundo@popapp.io, cmarin@popapp.io, pmarin@popapp.io, jcorona@popapp.io, luciana@popapp.io, paula@popapp.io"
 * }
 */
export interface Pago {
  asunto: string;
  cantMeses: number;
  controlado?: boolean;
  deleted?: boolean;
  /** isoString del servidor */
  fecha: string;
  idCuenta: string;
  idPago: string;
  idFactura?: string;
  importe: number;
  iva: number;
  nuevoVencimiento: string;
  /**ARG, CHI, ... */
  pais: string;
  path: string;
  text: string;
  tipo: string;
  to: string;

  cuenta?: CuentaPopapp;
  factura?: FacturaPopapp;
}

export interface CuentaPopapp {
  cuitORut?: string;
  emailpago?: string;
  idClienteContabilium?: number;
  idCuenta: string;
  nombre: string;
  responsabilidad?: string;
  datosFacturacion?: {
    comuna: string;
    cuitORut: string;
    direccion: string;
    giro: string;
    mail: string;
    noFacturarAutomaticamente: boolean;
    razonSocial: string;
  }
}

export interface FacturaPopapp {
  body: any,
  fecha: string,
  fechaMes: string,
  idCuenta: string,
  idFactura: string,
  idPago: string,
  resultado: any,
  total: number,
}

@Component({
  selector: 'app-admin-facturacion',
  templateUrl: './admin-facturacion.component.html',
  styleUrls: ['./admin-facturacion.component.scss']
})
export class AdminFacturacionComponent implements OnInit, OnDestroy {
  buscandoPagosSinFactura: boolean = true;
  pagosSinFactura: Pago[];

  pagos: Pago[];
  buscandoPagos: boolean = true;

  cuentas: CuentaPopapp[];
  facturas: FacturaPopapp[];

  pagosNoFacturadosLimit = 20;
  pagosLimit = 20;

  idCuenta?: string;
  subscripciones: Subscription[] = [];
  cuenta: CuentaPopapp;
  paisElegido: string;

  querySinFacturar: BehaviorSubject<(ref: any) => any> = new BehaviorSubject(undefined);
  queryUltimos: BehaviorSubject<(ref: any) => any> = new BehaviorSubject(undefined);

  constructor(
    private fs: AngularFirestore,
    private afAuth: AuthService,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnDestroy(): void {
    if (this.subscripciones) {
      for (let s of this.subscripciones) {
        try { s.unsubscribe() } catch (e) { }
      }
    }
  }

  async ngOnInit(): Promise<void> {

    this.subscripciones.push(this.afAuth.user.subscribe(user => {
      if (user && user.email) {
      }
    }));

    const queryParams = this.activatedRoute.snapshot.queryParams
    if (queryParams && queryParams["idCuenta"]) {
      this.idCuenta = queryParams["idCuenta"];
    }

    if (this.idCuenta) {
      const cuentaSnap = await this.fs.doc('cuentas/' + this.idCuenta).get().toPromise();
      if (cuentaSnap && cuentaSnap.data()) {
        this.cuenta = <CuentaPopapp>cuentaSnap.data();
        if (queryParams['pais']) {
          this.paisElegido = queryParams['pais'];
        }
      }
    }
    if (!this.paisElegido) {
      const doc = await this.fs.doc<any>('globales/facturacion').get().toPromise();

      if (doc && doc.data() && doc.data().paisElegido) {
        this.paisElegido = doc.data().paisElegido;
      } else {
        this.paisElegido = 'ARG';
      }

    }

    this.cuentas = [];
    this.facturas = [];


    this.querySinFacturar.next(this.busquedaPagos(true));
    this.queryUltimos.next(this.busquedaPagos(false));
  }

  cambioLimite(limite: number, sinFacturar: boolean) {
    console.log(limite, sinFacturar);
    if (sinFacturar) {
      this.pagosNoFacturadosLimit = limite;
      this.querySinFacturar.next(this.busquedaPagos(sinFacturar));
    } else {
      this.pagosLimit = limite;
      this.queryUltimos.next(this.busquedaPagos(sinFacturar));
    }
  }

  cambiarPais() {
    if (this.cuenta) {
      this._snackBar.open('No se puede cambiar el país de la cuenta', '')._dismissAfter(2000);
    } else {
      if (this.paisElegido === 'ARG') {
        this.paisElegido = 'CHI';
      } else {
        this.paisElegido = 'ARG';
      }
      this.fs.doc('globales/facturacion').set({ paisElegido: this.paisElegido }, { merge: true });
      this.querySinFacturar.next(this.busquedaPagos(true));
      this.queryUltimos.next(this.busquedaPagos(false));
    }
  }
  busquedaPagos(sinFacturar: boolean) {
    if (sinFacturar) {
      this.pagosSinFactura = [];
      this.buscandoPagosSinFactura = true;
    } else {
      this.pagos = [];
      this.buscandoPagos = true;
    }
    const paisElegido = this.paisElegido;
    const pagosNoFacturadosLimit = +this.pagosNoFacturadosLimit;
    const pagosLimit = +this.pagosLimit;
    const query = sinFacturar ?
      (ref => ref.orderBy('fecha', 'desc').where("idFactura", "==", null).where("pais", "==", paisElegido).limit(pagosNoFacturadosLimit)) :
      (ref => ref.orderBy('fecha', 'desc').where("pais", "==", paisElegido).limit(pagosLimit));

    return query;

  }


}
