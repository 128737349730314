import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';



export interface ConfFactContabilium {
  activado: boolean;
  actualizado: boolean;
  proveedor: string;
  nroLocalFea: number;
  token: string;
  Emisor: {
    cuit: string;
    email: string;
    password: string;
    razonSocial: string;
    domicilio?: string;
    responsabilidad: string;
    ptoVta: number;
    idPtoVta?: number;
    idProvincia: number;
    ingresosBrutos?: string;
    fechaInicioActividades?: string;
    idPais: number;
  }
}
@Component({
  selector: 'app-modal-conf-contabilium',
  templateUrl: './modal-conf-contabilium.component.html',
  styleUrls: ['./modal-conf-contabilium.component.scss']
})
export class ModalConfContabiliumComponent implements OnInit {

  confFactContabilium: ConfFactContabilium;
  form: FormGroup;
  submitted = false;
  error: string;
  loading: boolean;
  ptosVenta: [];
  escribirLastId: boolean = true;
  urlAdmin: string;

  constructor(
    public dialogRef: MatDialogRef<ModalConfContabiliumComponent>,
    public auth: AuthService,
    private cuentaService: CuentaService,
    @Inject(MAT_DIALOG_DATA) public data: { element: ServidorAdmin },
    private db: AngularFireDatabase,
    private dbFs: AngularFirestore,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder) {

  }

  async ngOnInit() {

    this.loading = true;
    this.urlAdmin = "cuentas/" + this.data.element.idCuenta + "/locales/" + this.data.element.idLocal + "/servidores/" + this.data.element.idMac + "/fearg/";

    let v = await this.leerConfFact();
    this.confFactContabilium = v;
    this.initForm(v);
    this.loading = false;
  }

  async leerConfFact() {

    let confFact: ConfFactContabilium = {
      activado: false,
      proveedor: "contabilium",
      nroLocalFea: 1,
      actualizado: true,
      token: "",
      Emisor: {
        cuit: "",
        email: "",
        password: "",
        razonSocial: "",
        domicilio: "",
        responsabilidad: "",
        ptoVta: 0,
        idPtoVta: 1,
        idProvincia: 1,
        ingresosBrutos: "",
        fechaInicioActividades: "",
        idPais: 10,
      }
    };
    if (this.data && this.data.element && this.data.element.keyPedidosOnline && this.data.element.keyPedidosOnline.key) {

      let doc = await this.dbFs.doc("confLocalPO/" + this.data.element.keyPedidosOnline.key).get().toPromise();
      let factElect = <ConfFactContabilium>(doc.data() ? doc.data()["factElect"] : null);
      if (factElect) {
        confFact = factElect;
      } else {
        //intento leer de adminCuentas

        let confAdmin = (await this.db.object(this.urlAdmin).query.once("value")).val();
        if (confAdmin) {
          confFact.activado = confAdmin.activado;
          confFact.nroLocalFea = confAdmin.nroLocalFea;
          confFact.Emisor.cuit = confAdmin.cuit;
          confFact.Emisor.domicilio = confAdmin.domicilio;
          confFact.Emisor.email = confAdmin.email;
          confFact.Emisor.fechaInicioActividades = confAdmin.fechaInicioActividades;
          confFact.Emisor.idPais = confAdmin.idPais;
          confFact.Emisor.idProvincia = confAdmin.idProvincia;
          confFact.Emisor.idPtoVta = confAdmin.idPtoVta;
          confFact.Emisor.password = confAdmin.password;
          confFact.Emisor.ptoVta = confAdmin.ptoVta;
          confFact.Emisor.razonSocial = confAdmin.razonSocial;
          confFact.Emisor.responsabilidad = confAdmin.responsabilidad;

        }
      }
    } else {
      this.snackbar.open("No hay keyLocalPOnline, configurar una key en POnline", "")._dismissAfter(3000);
    }
    return confFact;
  }

  initForm(v: ConfFactContabilium) {
    this.form = this.formBuilder.group({
      activado: [this.confFactContabilium.activado, []],
      // No lo pide pero me jode en el formulario
      // token: [this.confFactContabilium.token, [Validators.required, Validators.minLength(10)]],
      nroLocalFea: [this.confFactContabilium.nroLocalFea, [Validators.required]],
      cuit: [this.confFactContabilium.Emisor.cuit, [Validators.required, Validators.minLength(4)]],
      razonSocial: [this.confFactContabilium.Emisor.razonSocial, [Validators.required]],
      domicilio: [this.confFactContabilium.Emisor.domicilio, []],
      email: [this.confFactContabilium.Emisor.email, [Validators.required]],
      password: [this.confFactContabilium.Emisor.password, [Validators.required]],
      ptoVta: [this.confFactContabilium.Emisor.ptoVta, [Validators.required]],
      idPtoVta: [this.confFactContabilium.Emisor.idPtoVta, []],
      provincia: [this.confFactContabilium.Emisor.idProvincia, [Validators.required]],
      ingresosBrutos: [this.confFactContabilium.Emisor.ingresosBrutos, []],
      fechaInicioActividades: [this.confFactContabilium.Emisor.fechaInicioActividades, []],
      responsabilidad: [this.confFactContabilium.Emisor.responsabilidad, []]
    });
  }

  async guardarDatos() {
    this.submitted = true;
    this.error = null;
    // console.log("Estamos aca?",this.form);
    // console.log( "IDCUENTA:", this.data.element.idCuenta);

    if (this.form.invalid) {
      this.error = "Datos inválidos";
      return;
    }


    let urlConfLocalTienda = "confLocalPO/" + this.data.element.keyPedidosOnline.key;
    let mapUpdates = {};
    let factElectFS = {};


    const activado = this.form.controls.activado.value ? true : false;
    const proveedor = 'contabilium';
    // const numLocal = +this.form.controls.numLocal.value;
    const email = this.form.controls.email.value.trim();
    const cuit = this.form.controls.cuit.value.trim();
    const razonSocial = this.form.controls.razonSocial.value.trim();
    const responsabilidad = this.form.controls.responsabilidad.value;
    const ptoVta = +this.form.controls.ptoVta.value;
    const provincia = +this.form.controls.provincia.value;
    const fechaInicioActividades = this.form.controls.fechaInicioActividades.value ? this.form.controls.fechaInicioActividades.value : "";
    const password = this.form.controls.password.value.trim();
    

    const domicilio = this.form.controls.domicilio.value ? this.form.controls.domicilio.value.trim() : "";
    const idPtoVta = this.form.controls.idPtoVta.value ? +this.form.controls.idPtoVta.value : 111;
    const nroLocalFea = +this.form.controls.nroLocalFea.value;



    let Emisor: ConfFactContabilium["Emisor"] = {
      cuit: cuit,
      email: email,
      password: password,
      razonSocial: razonSocial,
      responsabilidad: responsabilidad,
      ptoVta: ptoVta,
      idProvincia: provincia,
      domicilio: domicilio,
      idPtoVta: idPtoVta,
      fechaInicioActividades: fechaInicioActividades,
      idPais: 10
    }


    mapUpdates[this.urlAdmin + "cuit"] = cuit;
    mapUpdates[this.urlAdmin + "domicilio"] = domicilio;
    mapUpdates[this.urlAdmin + "email"] = email;
    mapUpdates[this.urlAdmin + "fechaInicioActividades"] = fechaInicioActividades;
    mapUpdates[this.urlAdmin + "idPais"] = 10;
    mapUpdates[this.urlAdmin + "idProvincia"] = provincia;
    mapUpdates[this.urlAdmin + "idPtoVta"] = idPtoVta;
    mapUpdates[this.urlAdmin + "nroLocalFea"] = nroLocalFea;
    mapUpdates[this.urlAdmin + "password"] = password;
    mapUpdates[this.urlAdmin + "ptoVta"] = ptoVta;
    mapUpdates[this.urlAdmin + "razonSocial"] = razonSocial;
    mapUpdates[this.urlAdmin + "responsabilidad"] = responsabilidad;



    mapUpdates[this.urlAdmin + "activado"] = activado;
    mapUpdates[this.urlAdmin + "actualizado"] = true;
    mapUpdates[this.urlAdmin + "proveedor"] = proveedor;
    // mapUpdates[urlAdmin + "token"] = "";

    factElectFS["Emisor"] = Emisor;
    factElectFS["activado"] = activado;
    factElectFS["nroLocalFea"] = nroLocalFea;
    factElectFS["actualizado"] = true;
    factElectFS["proveedor"] = proveedor;
    // factElectFS["token"] = "";

    let objFS = { factElect: factElectFS };

    // subimos el formulario 
    try {
      let refTienda = this.dbFs.doc(urlConfLocalTienda).ref;
      await refTienda.set(objFS, { merge: true });

      this.db.object("/").update(mapUpdates).then(ok => {
        this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
        this.dialogRef.close();
      }).catch(e => {
        console.error("No se pudo grabar la tienda en admin", e);
        this.snackbar.open("Error, no se pudo grabar en admin")._dismissAfter(2000);
      });
    } catch (e) {
      console.error("Error al grabar en FS", e);
      this.snackbar.open("Error, no se pudo guardar")._dismissAfter(2000);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async buscarPuntosDeVenta() {
    const url = environment.urls.functions + "/api/efactura/ptosVta/" + this.data.element.idCuenta + "/" + this.data.element.idLocal + "/" + this.data.element.idMac;
    // const url = "http://localhost:5000/popappowner/us-central1/contabilium/efactura/ptosVta/" + this.data.element.idCuenta + "/" + this.data.element.idLocal + "/" + this.data.element.idMac;
    let datos = await this.cuentaService.getUrlProm(url);
    this.ptosVenta = datos.json();
  }

}
