import { Component, OnInit, Inject, OnDestroy, ViewChild } from "@angular/core";
import { AuthService } from "../../core/auth.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ServidorAdmin } from "src/app/shared/admin/servidor-admin";
import { AngularFireDatabase } from "@angular/fire/database";
import { LocalOnlineInterno } from "../modal-conf-ponline/modal-conf-ponline.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CuentaService } from "src/app/services/cuenta.service";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";

import { Http, Response } from "@angular/http";
import { Headers, RequestOptions } from "@angular/http";
import { Origen } from "src/app/admin/admin-origenes/admin-origenes.component";
import { Subscription } from "rxjs";
import { ModalOrigenesComponent } from "../modal-origenes/modal-origenes.component";
import { ModalMarcasComponent } from "../modal-marcas/modal-marcas.component";
import { AdminCuentaService } from "src/app/services/admin-cuenta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-modal-tienda",
  templateUrl: "./modal-tienda.component.html",
  styleUrls: ["./modal-tienda.component.scss"],
})
export class ModalTiendaComponent implements OnInit, OnDestroy {
  correo: string;
  passReseteado: boolean = false;
  locales: LocalOnlineInterno[];

  marcas: string[] = [];
  modalMacras: boolean = false;
  mostrarSeleccionarMarcas: boolean = false;

  deliveryTimesPya: { id: number, name: string }[] = [
    { id: 1, name: "Entre 15' y 30'" },
    { id: 2, name: "Entre 30' y 45'" },
    { id: 3, name: "Entre 45' y 60'" },
    { id: 4, name: "Entre 60' y 90'" },
    { id: 5, name: "Entre 90' y 120'" },
    { id: 6, name: '24 horas' },
    { id: 7, name: '48 horas' },
    { id: 8, name: '72 horas' },
    { id: 9, name: "Entre 120' y 150'" },
    { id: 10, name: '12 horas' },
    { id: 11, name: "Entre 150' y 180'" }
  ];


  deliveryTimesRappi: { id: number, name: string }[] = [
    { id: 5, name: '5 minutos' },
    { id: 10, name: '10 minutos' },
    { id: 15, name: '15 minutos' },
    { id: 20, name: '20 minutos' },
    { id: 25, name: '25 minutos' },
    { id: 30, name: '30 minutos' },
    { id: 35, name: '35 minutos' },
    { id: 40, name: '40 minutos' },
    { id: 45, name: '45 minutos' },
    { id: 50, name: '50 minutos' },
    { id: 55, name: '55 minutos' },
    { id: 60, name: '60 minutos' }
  ];

  keyLocalNueva: string = "";
  mapKeys;
  s: ServidorAdmin;
  local: LocalOnlineInterno;
  creandoTienda: boolean;
  formTienda: FormGroup;
  submitted: boolean = false;
  enviando: boolean = false;
  cargando: boolean = false;
  error: string;

  checkeandoEstado: boolean;
  resultadoCheck: string;

  keyHubVieja: string;

  subs: Subscription[] = [];
  origenes: Origen[];
  origenSeleccionado: Origen;
  disableButonLink: boolean;
  linkDidi: string;

  linkIfood: string;
  disableButtonCodigoIfood: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalTiendaComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public data: { s: ServidorAdmin; local?: LocalOnlineInterno },
    private db: AngularFireDatabase,
    private dbFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private http: Http,
    private adminCuentasService: AdminCuentaService,
    private cuentaService: CuentaService
  ) { }

  ngOnDestroy(): void {
    for (const s of this.subs) {
      try {
        s.unsubscribe();
      } catch (e) { }
    }
  }

  displayOrigenes(origenes: Origen[]) {
    const dialogRef = this.dialog.open(ModalOrigenesComponent, {
      width: "85%",
      data: { origenes: origenes, origenSeleccionado: this.origenSeleccionado },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("Origen elegido:", result);
        this.origenSeleccionado = result;
        if (this.origenSeleccionado && this.origenSeleccionado.token) {
          this.formTienda.controls.token.setValue(
            this.origenSeleccionado.token
          );
        } else if (this.local && this.local.token) {
          this.formTienda.controls.token.setValue(this.local.token);
        } else {
          this.formTienda.controls.token.setValue("");
        }
        if (this.origenSeleccionado.notificacionesUrl) {
          this.formTienda.controls.notificacionesUrl.setValue(
            this.origenSeleccionado.notificacionesUrl
          );
        }
      }
    });
  }

  async ngOnInit() {
    this.checkeandoEstado = false;
    this.resultadoCheck = undefined;
    this.s = this.data.s;
    this.local = this.data.local;
    if (!this.local) {
      this.creandoTienda = true;
    } else {
      this.creandoTienda = false;
    }

    this.inicializoForm();
    this.leerOrigenes();
    await this.getMarcas();
    //console.log(this.marcas, "asdasdas");
  }

  async generarLinkDidi() {
    this.snackbar.open("Generando link...")._dismissAfter(1000);

    const idLocal = this.formTienda.controls.idLocal.value.trim();
    const origen = this.origenSeleccionado.abreviacion;


    const baseUrl = environment.urls.functions + "/";
    // const baseUrl = "http://localhost:5000/popappowner/us-central1/";
    const url = baseUrl + 'didiv2/integracion_tienda/' + origen;

    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    this.disableButonLink = true;

    try {
      let resActivar = await this.http.get(url, options).toPromise();
      let jsonRespuesta = await resActivar.json();
      console.log("Respuesta didi", jsonRespuesta);

      if (jsonRespuesta && jsonRespuesta.idStore && jsonRespuesta.url) {
        this.linkDidi = jsonRespuesta.url;
        this.formTienda.controls.idLocal.setValue(jsonRespuesta.idStore);
        this.adminCuentasService.copyToClipboard(jsonRespuesta.url);
        let snack = this.snackbar.open("Link copiado al portapapeles", 'ABRIR');
        snack._dismissAfter(4000);
        snack.onAction().subscribe(v => {
          let url = jsonRespuesta.url;
          window.open(url, "_blank");
        });
      } else {
        this.snackbar.open("No se pudo obtener URL", "Consultar con Joaquin")._dismissAfter(4000);
      }
    } catch (e) {
      console.error(e);
      this.snackbar.open("No se pudo obtener URL", "Consultar con Joaquin")._dismissAfter(4000);
    } finally {
      // this.disableButonLink = false;
    }
    return "OK";
  }

  async generarLinkIfood() {
    this.snackbar.open("Generando link...")._dismissAfter(1000);

    const url = 'https://merchant-api.ifood.com.br/authentication/v1.0/oauth/userCode';

    let headers = new Headers({ "Content-Type": "application/x-www-form-urlencoded" });
    let options = new RequestOptions({ headers: headers });
    const body = new FormData();
    body.append('clientId', '431d6b02-f0b4-4581-9639-a07717f1a082');

    this.disableButtonCodigoIfood = true;

    try {
      let linkIfood = await this.http.post(url, body, options).toPromise();
      let jsonRespuesta = await linkIfood.json();
      console.log("Respuesta código ifood", jsonRespuesta);

      if (jsonRespuesta && jsonRespuesta.verificationUrlComplete) {
        this.linkIfood = jsonRespuesta.verificationUrlComplete;
        this.adminCuentasService.copyToClipboard(jsonRespuesta.verificationUrlComplete);
        let snack = this.snackbar.open("Link copiado al portapapeles", 'ABRIR');
        snack._dismissAfter(4000);
        snack.onAction().subscribe(v => {
          let url = jsonRespuesta.verificationUrlComplete;
          window.open(url, "_blank");
        });
      } else {
        this.snackbar.open("No se pudo obtener URL", "Respuesta no tiene en código verificationUrlComplete")._dismissAfter(4000);
      }
    } catch (e) {
      console.error(e);
      this.snackbar.open("No se pudo obtener URL", e.message)._dismissAfter(4000);
    }

    return "OK";
  }

  leerOrigenes() {
    this.subs.push(
      this.db
        .object("origenesPedidosOnline")
        .valueChanges()
        .subscribe((snap) => {
          this.origenes = <Origen[]>snap;
          for (let o of this.origenes) {
            if (o.logo16) {
              o.logo16 = "data:image/png;base64," + o.logo16;
            }
          }
          this.origenes.sort((a, b) => {
            if (a.deprecated === b.deprecated) {
              if (a.nombre === b.nombre) {
                return a.id - b.id;
              } else if (a.nombre < b.nombre) {
                return -1;
              } else {
                return 1;
              }
            } else if (a.deprecated && !b.deprecated) {
              return 1;
            } else {
              return -1;
            }
          });

          this.inicializoForm();
        })
    );
  }

  inicializoForm() {
    this.formTienda = this.formBuilder.group({
      nombre: ["", [Validators.required, Validators.minLength(2)]],
      idLocal: ["", [Validators.required, Validators.minLength(1)]],
      activado: ["", []],
      productosVinculados: ["", []],
      sinCostoEnvio: ["", []],
      fechaAlta: ["", [Validators.required]],
      marca: ["", [Validators.required]],
      tag: ["", []],
      descuentopya: ["", []],
      descuentoUber: ["", []],
      descrappi: ["", []],
      autoaccept: ["", []],
      readyToDeliver: ["", []],
      readyToDeliverNoLogistics: ["", []],
      deliveryTimeAA: ["", []],
      concurrenciaEstado: ["", []],
      keyHub: ["", []],
      token: ["", []],
      notificacionesUrl: ["", []],
      offset: ["", []]
    });
    if (this.local) {
      if (this.local.nombre)
        this.formTienda.controls.nombre.setValue(this.local.nombre);

      if (this.origenes && this.origenes.length > 0 && this.local.origen) {
        const or = this.origenes.find(
          (x) => x.abreviacion === this.local.origen
        );
        if (or) {
          this.origenSeleccionado = or;
        }
      }

      if (this.local.idStore) {
        this.formTienda.controls.idLocal.setValue(this.local.idStore);
      } else {
        let idLocal = this.local.keyFBAdmin.substr(3);
        console.log(this.local.keyFBAdmin);
        this.formTienda.controls.idLocal.setValue(idLocal);
      }
      this.formTienda.controls['idLocal'].disable();
      if (this.local.activado)
        this.formTienda.controls.activado.setValue(this.local.activado);
      // if (this.local.rappiv2)
      //   this.formTienda.controls.rappiv2.setValue(this.local.rappiv2);

      if (this.local.productosVinculados)
        this.formTienda.controls.productosVinculados.setValue(
          this.local.productosVinculados
        );

      if (this.local.sinCostoEnvio)
        this.formTienda.controls.sinCostoEnvio.setValue(
          this.local.sinCostoEnvio
        );

      if (this.local.marca)
        this.formTienda.controls.marca.setValue(this.local.marca);
      else {
        if (this.local.nombre) {
          this.formTienda.controls.marca.setValue(this.local.nombre);
        }
      }
      if (this.local.fechaAlta && this.local.fechaAlta !== "") {
        this.formTienda.controls.fechaAlta.setValue(this.local.fechaAlta);
      } else {
        let date = new Date();
        let mes = date.getMonth() + 1;
        let dia = date.getDate();
        this.formTienda.controls.fechaAlta.setValue(
          date.getFullYear() +
          "-" +
          (mes > 9 ? mes : "0" + mes) +
          "-" +
          (dia > 9 ? dia : "0" + dia)
        );
      }

      if (this.local.tag) this.formTienda.controls.tag.setValue(this.local.tag);
      if (this.local.descuentopya)
        this.formTienda.controls.descuentopya.setValue(this.local.descuentopya);
      if (this.local.descuentoUber)
        this.formTienda.controls.descuentoUber.setValue(
          this.local.descuentoUber
        );
      if (this.local.descrappi)
        this.formTienda.controls.descrappi.setValue(this.local.descrappi);

      if (this.local.autoaccept)
        this.formTienda.controls.autoaccept.setValue(this.local.autoaccept);
      if (this.local.deliveryTimeAA !== null)
        this.formTienda.controls.deliveryTimeAA.setValue(
          this.local.deliveryTimeAA
        );
      if (this.local.readyToDeliver)
        this.formTienda.controls.readyToDeliver.setValue(
          this.local.readyToDeliver
        );
      if (this.local.readyToDeliverNoLogistics)
        this.formTienda.controls.readyToDeliverNoLogistics.setValue(
          this.local.readyToDeliverNoLogistics
        );
      if (this.local.concurrenciaEstado)
        this.formTienda.controls.concurrenciaEstado.setValue(
          this.local.concurrenciaEstado
        );
      if (this.local.keyHub) {
        this.formTienda.controls.keyHub.setValue(this.local.keyHub);
        this.keyHubVieja = this.local.keyHub;
      }
      if (this.local.offset)
        this.formTienda.controls.offset.setValue(this.local.offset);
      if (this.local.token) {
        this.formTienda.controls.token.setValue(this.local.token);
      } else if (this.origenSeleccionado && this.origenSeleccionado.token) {
        this.formTienda.controls.token.setValue(this.origenSeleccionado.token);
      }

      if (this.local.notificacionesUrl) {
        this.formTienda.controls.notificacionesUrl.setValue(
          this.local.notificacionesUrl
        );
      } else if (
        this.origenSeleccionado &&
        this.origenSeleccionado.notificacionesUrl
      ) {
        this.formTienda.controls.notificacionesUrl.setValue(
          this.origenSeleccionado.notificacionesUrl
        );
      }
    } else {
      let date = new Date();
      let mes = date.getMonth() + 1;
      let dia = date.getDate();
      this.formTienda.controls.fechaAlta.setValue(
        date.getFullYear() +
        "-" +
        (mes > 9 ? mes : "0" + mes) +
        "-" +
        (dia > 9 ? dia : "0" + dia)
      );

      this.formTienda.controls['idLocal'].enable();
    }
  }


  estadoUber(operacion: string, idStore: string) {
    this.checkeandoEstado = true;

    const urlBaseFunctions = environment.urls.functions + '/uber';
    // Check:  UrlBaseFunctions/get_store/:idStore
    // Activar: UrlBaseFunctions /patch_store/:idStore   body: { activar: true }
    // Desactivar: UrlBaseFunctions /patch_store/:idStore   body: { activar: false }

    if (operacion === 'activar' || operacion === 'desactivar') {
      const body = operacion === 'activar' ? { activar: true } : { activar: false };
      this.cuentaService.patchUrlProm(urlBaseFunctions + '/patch_store/' + idStore, body).then(v => {
        this.cuentaService.getUrlProm(urlBaseFunctions + '/get_store/' + idStore).then(v => {
          this.muestraMsg(v);
        }).catch(e => {
          this.muestraMsg(undefined, e);
        })
      }).catch(e => {
        this.muestraMsg(undefined, e);
      })
    } else { //check

      this.cuentaService
        .getUrlProm(urlBaseFunctions + '/get_store/' + idStore)
        .then((v) => {
          this.muestraMsg(v);
        })
        .catch((e) => {
          this.muestraMsg(undefined, e);
        });
    }
  }

  muestraMsg(v, error?) {
    if (error) {
      this.checkeandoEstado = false;
      console.log("Error", error);
      this.resultadoCheck = "Hubo un problema";
    } else {
      this.checkeandoEstado = false;
      let body = v.json();
      console.log("Exito", body);
      this.resultadoCheck = (body && body.msg) ? body.msg : 'Éxito';
      if (body.msg && body.msg.pos_integration_enabled) {
        this.resultadoCheck = "Integración activada";
      } else if (body.msg) {
        this.resultadoCheck = "Integración DESACTIVADA";
      }

      if (body.msg.auto_accept_enabled) {
        this.resultadoCheck += " Auto-Accept";
      } else if (body.msg) {
        this.resultadoCheck += " SIN Auto-Accept";
      }



    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  async cambiarAV2() {
    this.inicializoForm();

    const origenViejo = this.origenSeleccionado;

    const dialogRef = this.dialog.open(ModalOrigenesComponent, {
      width: "85%",
      data: {
        origenes: this.origenes.filter((x) => x.abreviacion.startsWith("ra2")),
        origenSeleccionado: this.origenSeleccionado,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        console.log("Origen elegido:", result);
        this.origenSeleccionado = result;
        if (
          this.local.idStore &&
          this.s.keyPedidosOnline.key &&
          origenViejo &&
          this.origenSeleccionado &&
          origenViejo.abreviacion !== this.origenSeleccionado.abreviacion
        ) {
          try {
            console.log("Migrando estadisticas...");
            await this.migrarEstadisticas(
              this.s.keyPedidosOnline.key,
              origenViejo.abreviacion,
              this.origenSeleccionado.abreviacion,
              this.local.idStore
            );
            console.log("Borrando tienda...");
            await this.borrar(true);
            console.log("Creando tienda con los nuevos datos");
            const resul = await this.guardarDatos(
              this.origenSeleccionado.abreviacion,
              this.origenSeleccionado.plataforma
            );
            if (resul === "ERROR") {
              console.log("Error");
            } else {
              console.log("Resultado", resul);
            }
            console.log("Cambio realizado con exito");
            this.snackbar
              .open("Cambio realizado con éxito", "")
              ._dismissAfter(4000);
          } catch (e) {
            console.log("Error en el cambio", e);
            this.snackbar
              .open("Error en el cambio", "Revisa la consola (F12)")
              ._dismissAfter(4000);
          }
        } else {
          this.error =
            "No hay cambio de version de " +
            origenViejo.plataforma +
            " (" +
            origenViejo.abreviacion +
            ") a " +
            this.origenSeleccionado.plataforma +
            " (" +
            this.origenSeleccionado.abreviacion +
            ")";
          return;
        }
      }
    });
  }

  async activarMenuSync() {

    this.cargando = true;
    try {

      const confirmado = confirm("¿Migrar credenciales de tienda a menú automático?");
      if (confirmado) {

        console.log("Activando menú sync...");

        let mapUpdates = {};
        let urlkeysLocales2 = "keysLocalesPedidosOnline2/" + this.local.origen + "," + this.local.idStore + "/";
        mapUpdates[urlkeysLocales2 + "menuSync"] = true;
        await this.db.object("/").update(mapUpdates);

        const result = await this.postMenuSync(this.local.origen, this.local.idStore);

        if (result === "OK") {
          let mapUpdates = {};

          let urlAdmin =
            "cuentas/" +
            this.s.idCuenta +
            "/locales/" +
            this.s.idLocal +
            "/servidores/" +
            this.s.idMac +
            "/keyPedidosOnline/locales/" + this.local.origen.slice(0, 3) + this.local.idStore + "/";

          mapUpdates[urlAdmin + "menuSync"] = true;

          await this.db.object("/").update(mapUpdates);

          this.local.menuSync = true;

          console.log("Menú activado con éxito");
          this.snackbar
            .open("Menú activado con éxito", "")
            ._dismissAfter(4000);
        } else {
          console.log("Error al activar menú: " + result);

          let mapUpdates = {};
          let urlkeysLocales2 = "keysLocalesPedidosOnline2/" + this.local.origen + "," + this.local.idStore + "/";
          mapUpdates[urlkeysLocales2 + "menuSync"] = false;

          await this.db.object("/").update(mapUpdates);
          this.snackbar
            .open("Error al activar menú", "Revisa la consola (F12)")
            ._dismissAfter(4000);
        }
      }

      this.cargando = false;

    } catch (e) {
      console.log("Error al activar menú", e);
      this.cargando = false;
      this.snackbar
        .open("Error al activar menú", "Revisa la consola (F12)")
        ._dismissAfter(4000);
    }
  }

  async migrarEstadisticas(
    keyLocal: string,
    origenViejo,
    origenNuevo,
    idStore: string
  ) {
    const url = environment.urls.escuchador + "/migrarEstadisticasTiendas";
    // const url = "http://localhost:3000/migrarEstadisticasTiendas";
    const body = {
      keyLocal: keyLocal,
      tiendaIdVieja: origenViejo + "," + idStore,
      tiendaIdNueva: origenNuevo + "," + idStore,
    };

    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });

    let res = await this.http.post(url, body, options).toPromise();
    let jsonRespuesta = await res.json();
    return "OK";
  }

  async postMenuSync(origen: string, idStore: string) {
    const pais = origen.replace("ra2", "");
    const url = environment.urls.functions + "/rappi/activarMenuSync/" + pais + "/" + idStore;
    // const url = "http://localhost:5000/popappowner/us-central1/rappi/activarMenuSync/" + pais + "/" + idStore;
    const body = {};

    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    let res: any;
    try {
      res = await this.http.post(url, body, options).toPromise();
      return "OK";
    } catch (e) {
      if (e._body) {
        return e._body;
      } else {
        return e.statusText;
      }
    }
  }

  async guardarDatos(
    origenNuevo?: string,
    plataformaNueva?: string
  ): Promise<any> {
    console.log(origenNuevo, plataformaNueva);
    this.submitted = true;
    this.enviando = true;
    this.error = null;

    if (this.formTienda.invalid) {
      this.error = "Datos inválidos";
      this.enviando = false;
      return "ERROR";
    }
    if (!this.origenSeleccionado) {
      this.error = "Debes seleccionar una plataforma";
      this.enviando = false;
      return "ERROR";
    }

    let urlAdmin =
      "cuentas/" +
      this.s.idCuenta +
      "/locales/" +
      this.s.idLocal +
      "/servidores/" +
      this.s.idMac +
      "/keyPedidosOnline/locales/";
    let urlConfLocal = "confLocalPO/" + this.s.keyPedidosOnline.key + "/";

    // subimos el formulario
    let mapUpdates = {};
    let mapUpdatesFS = {};
    mapUpdatesFS["_key"] = this.s.keyPedidosOnline.key;
    mapUpdatesFS["key"] = this.s.keyPedidosOnline.key;
    mapUpdatesFS["idCuenta"] = this.s.idCuenta;
    mapUpdatesFS["idLocal"] = this.s.idLocal;
    mapUpdatesFS["idMac"] = this.s.idMac;
    let mapUpdatesTiendaFS = { keyLocal: this.s.keyPedidosOnline.key };
    const plataforma = this.origenSeleccionado.plataforma;
    const rappiv2 = this.origenSeleccionado.abreviacion.startsWith("ra2");

    let origen = this.origenSeleccionado.abreviacion;
    let origen3 = this.origenSeleccionado.abreviacion.substr(0, 3);
    console.log("origen 3: " + origen3);

    const nombre = this.formTienda.controls.nombre.value.trim();
    const idLocal = this.formTienda.controls.idLocal.value.trim();
    const activado = this.formTienda.controls.activado.value;
    const productosVinculados =
      this.formTienda.controls.productosVinculados.value;
    const sinCostoEnvio = this.formTienda.controls.sinCostoEnvio.value;
    const autoaccept = this.formTienda.controls.autoaccept.value;
    const readyToDeliver = this.formTienda.controls.readyToDeliver.value;
    const readyToDeliverNoLogistics =
      this.formTienda.controls.readyToDeliverNoLogistics.value;
    const marca = this.formTienda.controls.marca.value.trim();
    const descuentopya = this.formTienda.controls.descuentopya.value.trim();
    const descuentoUber = this.formTienda.controls.descuentoUber.value;
    const descrappi = this.formTienda.controls.descrappi.value.trim();
    const tag = this.formTienda.controls.tag.value.trim();
    const fechaAlta = this.formTienda.controls.fechaAlta.value.trim();
    let keyHub: string = this.formTienda.controls.keyHub.value.trim();
    const deliveryTimeAA = origenNuevo
      ? this.formTienda.controls.deliveryTimeAA.value
        ? this.formTienda.controls.deliveryTimeAA.value
        : 20
      : this.formTienda.controls.deliveryTimeAA.value;

    //TODO: si es pya  58031999 && idresto !== 900111 987654 esos 3 se usan para test
    /*if (plataforma === "PYA") {
      if (idLocal === "58031999" ||
        idLocal === "900111" ||
        idLocal === "987654") {
        this.error = "Ids de Test de Pya";
        return;
      }
    }*/

    urlAdmin += origen3 + idLocal + "/";
    let urlConfLocalTienda =
      urlConfLocal + "tiendas/" + origen + "," + idLocal + "/";

    let urlkeysLocales2 =
      "keysLocalesPedidosOnline2/" + origen + "," + idLocal + "/";
    let urlkeysLocales = "keysLocalesPedidosOnline/" + origen3 + idLocal + "/";
    let urlkeysAutoaccept = "keysAutoAceptar/" + origen3 + idLocal + "/";

    mapUpdates[urlAdmin + "nombre"] = nombre;
    mapUpdates[urlkeysLocales2 + "nombre"] = nombre;
    mapUpdatesTiendaFS["nombre"] = nombre;

    mapUpdates[urlAdmin + "activado"] = activado ? true : false;
    mapUpdates[urlkeysLocales2 + "activado"] = activado ? true : false;
    mapUpdatesTiendaFS["activado"] = activado ? true : false;
    if (activado) {
      mapUpdates[urlkeysLocales] = this.s.keyPedidosOnline.key;
    } else {
      mapUpdates[urlkeysLocales] = null;
      // if (this.local.keyHub) {

      // }
    }

    mapUpdates[urlAdmin + "productosVinculados"] = productosVinculados
      ? true
      : false;
    mapUpdates[urlkeysLocales2 + "productosVinculados"] = productosVinculados
      ? true
      : false;
    mapUpdatesTiendaFS["productosVinculados"] = productosVinculados
      ? true
      : false;

    mapUpdates[urlAdmin + "sinCostoEnvio"] = sinCostoEnvio ? true : false;
    mapUpdates[urlkeysLocales2 + "sinCostoEnvio"] = sinCostoEnvio
      ? true
      : false;
    mapUpdatesTiendaFS["sinCostoEnvio"] = sinCostoEnvio ? true : false;

    mapUpdates[urlAdmin + "autoaccept"] = autoaccept ? true : false;
    mapUpdates[urlkeysLocales2 + "autoaccept"] = autoaccept ? true : false;
    mapUpdatesTiendaFS["autoaccept"] = autoaccept ? true : false;
    if (autoaccept) {
      mapUpdates[urlkeysAutoaccept] = this.s.keyPedidosOnline.key;
    } else {
      mapUpdates[urlkeysAutoaccept] = null;
    }

    mapUpdates[urlAdmin + "rappiv2"] = rappiv2 ? true : false;
    mapUpdates[urlkeysLocales2 + "rappiv2"] = rappiv2 ? true : false;
    mapUpdatesTiendaFS["rappiv2"] = rappiv2 ? true : false;

    mapUpdates[urlAdmin + "readyToDeliver"] = readyToDeliver ? true : false;
    mapUpdates[urlkeysLocales2 + "readyToDeliver"] = readyToDeliver
      ? true
      : false;
    mapUpdatesTiendaFS["readyToDeliver"] = readyToDeliver ? true : false;

    mapUpdates[urlAdmin + "readyToDeliverNoLogistics"] =
      readyToDeliverNoLogistics ? true : false;
    mapUpdates[urlkeysLocales2 + "readyToDeliverNoLogistics"] =
      readyToDeliverNoLogistics ? true : false;
    mapUpdatesTiendaFS["readyToDeliverNoLogistics"] = readyToDeliverNoLogistics
      ? true
      : false;

    if (marca) {
      mapUpdates[urlAdmin + "marca"] = marca;
      mapUpdates[urlkeysLocales2 + "marca"] = marca;
      mapUpdatesTiendaFS["marca"] = marca;
    } else {
      mapUpdates[urlAdmin + "marca"] = null;
      mapUpdates[urlkeysLocales2 + "marca"] = null;
      mapUpdatesTiendaFS["marca"] = null;
    }

    if (fechaAlta) {
      mapUpdates[urlAdmin + "fechaAlta"] = fechaAlta;
      mapUpdates[urlkeysLocales2 + "fechaAlta"] = fechaAlta;
      mapUpdatesTiendaFS["fechaAlta"] = fechaAlta;
    } else {
      mapUpdates[urlAdmin + "fechaAlta"] = null;
      mapUpdates[urlkeysLocales2 + "fechaAlta"] = null;
      mapUpdatesTiendaFS["fechaAlta"] = null;
    }

    if (tag) {
      mapUpdates[urlAdmin + "tag"] = tag;
      mapUpdates[urlkeysLocales2 + "tag"] = tag;
      mapUpdatesTiendaFS["tag"] = tag;
    } else {
      mapUpdates[urlAdmin + "tag"] = null;
      mapUpdates[urlkeysLocales2 + "tag"] = null;
      mapUpdatesTiendaFS["tag"] = null;
    }

    if (descuentopya) {
      mapUpdates[urlAdmin + "descuentopya"] = descuentopya;
      mapUpdates[urlkeysLocales2 + "descuentopya"] = descuentopya;
      mapUpdatesTiendaFS["descuentopya"] = descuentopya;
    } else {
      mapUpdates[urlAdmin + "descuentopya"] = null;
      mapUpdates[urlkeysLocales2 + "descuentopya"] = null;
      mapUpdatesTiendaFS["descuentopya"] = null;
    }

    if (descuentoUber) {
      mapUpdates[urlAdmin + "descuentoUber"] = descuentoUber;
      mapUpdates[urlkeysLocales2 + "descuentoUber"] = descuentoUber;
      mapUpdatesTiendaFS["descuentoUber"] = descuentoUber;
    } else {
      mapUpdates[urlAdmin + "descuentoUber"] = null;
      mapUpdates[urlkeysLocales2 + "descuentoUber"] = null;
      mapUpdatesTiendaFS["descuentoUber"] = null;
    }

    if (descrappi) {
      mapUpdates[urlAdmin + "descrappi"] = descrappi;
      mapUpdates[urlkeysLocales2 + "descrappi"] = descrappi;
      mapUpdatesTiendaFS["descrappi"] = descrappi;
    } else {
      mapUpdates[urlAdmin + "descrappi"] = null;
      mapUpdates[urlkeysLocales2 + "descrappi"] = null;
      mapUpdatesTiendaFS["descrappi"] = null;
    }

    const concurrenciaEstado =
      this.formTienda.controls.concurrenciaEstado.value;
    mapUpdates[urlAdmin + "concurrenciaEstado"] = concurrenciaEstado
      ? true
      : false;
    mapUpdates[urlkeysLocales2 + "concurrenciaEstado"] = concurrenciaEstado
      ? true
      : false;
    mapUpdatesTiendaFS["concurrenciaEstado"] = concurrenciaEstado
      ? true
      : false;

    mapUpdates[urlAdmin + "idStore"] = idLocal;
    mapUpdates[urlkeysLocales2 + "idStore"] = idLocal;
    mapUpdatesTiendaFS["idStore"] = idLocal;

    mapUpdates[urlAdmin + "origen"] = origen;
    mapUpdates[urlkeysLocales2 + "origen"] = origen;
    mapUpdatesTiendaFS["origen"] = origen;

    //mapUpdatesFS["uids"] = firebase.firestore.FieldValue.arrayUnion(origen + "," + idLocal);
    //agregar cada uid si es q ya hay

    mapUpdates[urlAdmin + "plataforma"] = plataforma;
    mapUpdates[urlkeysLocales2 + "plataforma"] = plataforma;
    mapUpdatesTiendaFS["plataforma"] = plataforma;

    if (keyHub && keyHub.trim().length > 0) {
      mapUpdates[urlAdmin + "keyHub"] = keyHub.trim();
      mapUpdates[urlkeysLocales2 + "keyHub"] = keyHub.trim();
      mapUpdatesTiendaFS["keyHub"] = keyHub.trim();
      mapUpdatesFS["keyHub"] = keyHub.trim();

      //TODO: vincular a un hub
    } else if (this.keyHubVieja) {
      mapUpdates[urlAdmin + "keyHub"] = null;
      mapUpdates[urlkeysLocales2 + "keyHub"] = null;
      mapUpdatesTiendaFS["keyHub"] = null;
      mapUpdatesFS["keyHub"] = null;

      //TODO: desvincular de un hub
    }

    mapUpdatesTiendaFS["heart"] = -1;
    mapUpdates[urlkeysLocales2 + "heart"] = -1;

    mapUpdatesTiendaFS["pedidos"] = 0;
    mapUpdates[urlkeysLocales2 + "pedidos"] = 0;

    const token = this.formTienda.controls.token.value.trim();
    const notificacionesUrl =
      this.formTienda.controls.notificacionesUrl.value.trim();
    const offset = this.formTienda.controls.offset.value;

    if (plataforma === "JUSTO" || this.origenSeleccionado.api2) {
      if (!token || token.length < 4) {
        this.error = "Token requerido";
        this.enviando = false;
        return;
      } else {
        mapUpdates[urlAdmin + "token"] = token;
        mapUpdates[urlkeysLocales2 + "token"] = token;
        mapUpdates["justo/tokens/" + idLocal] = token;
      }
    }

    if (this.origenSeleccionado.notificacionesUrl) {
      if (!notificacionesUrl || notificacionesUrl.length === 0) {
        this.error = "Url notificacion requerida";
        this.enviando = false;
        return;
      } else {
        mapUpdates[urlAdmin + "notificacionesUrl"] = notificacionesUrl;
        mapUpdates[urlkeysLocales2 + "notificacionesUrl"] = notificacionesUrl;
        mapUpdatesTiendaFS["notificacionesUrl"] = notificacionesUrl;
      }
    }

    let mapUpdatesAPIFS = {};
    if (plataforma === "BICCI") {
      if (activado) {
        mapUpdatesAPIFS["enabled"] = true;
      } else {
        mapUpdatesAPIFS["enabled"] = false;
      }
      mapUpdatesAPIFS["origenes"] =
        firebase.firestore.FieldValue.arrayUnion("bic");
      mapUpdatesAPIFS["token"] =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmlnZW4iOiJiaWMiLCJwbGF0YWZvcm1hIjoiQklDQ0kifQ.hZlx2drZPsuDqVVBhQGzIV3m4r7dkfzTiQHVK36SqrY";
    }
    if (plataforma === "SCON") {
      if (activado) {
        mapUpdatesAPIFS["enabled"] = true;
      } else {
        mapUpdatesAPIFS["enabled"] = false;
      }
      mapUpdatesAPIFS["origenes"] =
        firebase.firestore.FieldValue.arrayUnion("sco");
      mapUpdatesAPIFS["token"] =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmlnZW4iOiJzY28iLCJwbGF0YWZvcm1hIjoiU0NPTiJ9.Ru3Ln3O3s8UR_kzKEbBlctMpfvZqiM4E6Bfzjzzxjlg";
    }

    if (
      plataforma === "JUSTO" ||
      plataforma === "UBER" ||
      plataforma === "DiDi" ||
      plataforma === "IFOOD" ||
      this.origenSeleccionado.abreviacion === "pya2" ||
      this.origenSeleccionado && this.origenSeleccionado.abreviacion.startsWith('did')
    ) {
      if (!offset || offset.length < 1) {
        this.error = "OFFSET requerido";
        this.enviando = false;
        return;
      } else {
        mapUpdates[urlAdmin + "offset"] = offset;
        mapUpdates[urlkeysLocales2 + "offset"] = offset;
        mapUpdatesTiendaFS["offset"] = offset;
        mapUpdatesFS["offset"] = offset;
      }
    }

    if (this.keyHubVieja && !keyHub) {
      mapUpdates[urlkeysLocales2 + "keyHub"] = null;
    }

    if (deliveryTimeAA) {
      mapUpdates[urlAdmin + "deliveryTimeAA"] = deliveryTimeAA;
      mapUpdates[urlkeysLocales2 + "deliveryTimeAA"] = deliveryTimeAA;
      mapUpdatesTiendaFS["deliveryTimeAA"] = deliveryTimeAA;
      let valor = +deliveryTimeAA;
      if (rappiv2) {
        const valoresPermitidos = [
          1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120,
        ];
        if (!valoresPermitidos.includes(valor)) {
          this.error =
            "La API v2 tiene que tener un deliveryTimeAA 1 (Por defecto) o entre 5 y 60 multiplo de 5 o valer 90 o 120";
          this.enviando = false;
          return "ERROR";
        }
        // if (valor % 5 !== 0 || valor < 5 || (valor > 60 && valor !== 90 && valor !== 120)) {
        // }
      }
    } else {
      if (rappiv2) {
        this.error =
          "La API v2 tiene que tener un deliveryTimeAA 5, 10, 15, 20, 25, 30...";
        this.enviando = false;
        return "ERROR";
      }

      if (plataforma === "PYA" && autoaccept) {
        this.error = "Elige algun deliveryTimeId, 1 por ejemplo";
        this.enviando = false;
        return;
      }
      mapUpdates[urlAdmin + "deliveryTimeAA"] = null;
      mapUpdates[urlkeysLocales2 + "deliveryTimeAA"] = null;
      mapUpdatesTiendaFS["deliveryTimeAA"] = null;
    }

    mapUpdates[urlkeysLocales2 + "keyLocal"] = this.s.keyPedidosOnline.key;
    //mapUpdates[urlkeysLocales2 + "versionManager"] = this.s.versionActual;
    //mapUpdates[urlkeysLocales2 + "versionManager"] = this.s.versionActual;

    console.log('creando tienda en sql', mapUpdatesTiendaFS);
    try {
      await this.desOActivarEnSQL(this.creandoTienda, mapUpdatesTiendaFS);
    } catch (e) {
      //this.snackbar.open(e)._dismissAfter(4000);
      //alert(e)
      console.log('Error al crear en la SQL', e._body);
      this.snackbar
        .open("No se pudo guardar la tienda:" + JSON.parse(e._body).msg)
        ._dismissAfter(4000);
      return;
    }

    return this.db
      .object("/")
      .update(mapUpdates)
      .then((ok) => {
        console.log("ok db");
        let batch = this.dbFirestore.firestore.batch();
        let refLocal = this.dbFirestore.doc(urlConfLocal).ref;
        batch.set(refLocal, mapUpdatesFS, { merge: true });

        let refTienda = this.dbFirestore.doc(urlConfLocalTienda).ref;
        batch.set(refTienda, mapUpdatesTiendaFS, { merge: true });

        if (keyHub.trim()) {
          let refTienda2 = this.dbFirestore.doc(
            "confLocalPO/" +
            keyHub.trim() +
            "/tiendasId/" +
            origen +
            "," +
            idLocal +
            "/"
          ).ref;
          const datosKeyHub = {
            keyLocal: this.s.keyPedidosOnline.key,
            origen: mapUpdatesTiendaFS["origen"],
            idStore: mapUpdatesTiendaFS["idStore"],
            activado: activado ? true : false,
          };
          batch.set(refTienda2, datosKeyHub, { merge: true });

          let refHub = this.dbFirestore.doc("confLocalPO/" + keyHub.trim()).ref;
          const datosHub = {
            esHub: true
          }
          batch.set(refHub,
            datosHub
            , { merge: true });

          this.postLocalSQL(this.s.keyPedidosOnline.key, keyHub.trim());
        } else if (this.keyHubVieja) {
          let refTienda2 = this.dbFirestore.doc(
            "confLocalPO/" +
            this.keyHubVieja.trim() +
            "/tiendasId/" +
            origen +
            "," +
            idLocal +
            "/"
          ).ref;
          batch.delete(refTienda2);
          this.postLocalSQL(this.s.keyPedidosOnline.key, '');
        }

        if (
          mapUpdatesAPIFS &&
          mapUpdatesAPIFS["token"]
        ) {
          const refAPI = this.dbFirestore.doc(
            "tokensapi/" + this.s.keyPedidosOnline.key
          ).ref;
          batch.set(refAPI, mapUpdatesAPIFS, { merge: true });
        }

        if (this.origenSeleccionado.api2) {
          const mapa: any = {
            keyLocal: this.s.keyPedidosOnline.key,
            newOrderToken: token,
            nombreStore: nombre,
            plataforma: this.origenSeleccionado.plataforma,
            origen: this.origenSeleccionado.abreviacion,
          };
          if (keyHub) {
            mapa.keyHub = keyHub;
          }
          if (notificacionesUrl) {
            mapa.notificacionesUrl = notificacionesUrl;
          }

          const refAPI = this.dbFirestore.doc(
            "tiendasDeAPI/" +
            this.origenSeleccionado.abreviacion +
            "," +
            idLocal
          ).ref;
          batch.set(refAPI, mapa, { merge: true });
        }

        return batch
          .commit()
          .then(async (v) => {
            if (rappiv2) {
              await this.activarWebhookCambiosDeEstadoRappi(idLocal, origen);
            }

            try {
              if (this.origenSeleccionado && this.origenSeleccionado.abreviacion && this.origenSeleccionado.abreviacion.startsWith('did') && this.origenSeleccionado.abreviacion.length > 3) {
                await this.activarEnDidi(this.origenSeleccionado.abreviacion, idLocal);
              }
            } catch (e) {
              console.error(e);
              this.snackbar.open("Error al grabar datos en SQL, comunicar a ASUNTOS INTERNOS")._dismissAfter(5000);
              // await this.guardarEnLogDeFS();
            }

            this.snackbar
              .open("Datos grabados exitosamente")
              ._dismissAfter(2000);
            this.dialogRef.close();
          })
          .catch((e) => {
            console.error("No se pudo grabar en FS", e);
            this.snackbar.open("Error, no se pudo grabar")._dismissAfter(4000);
            return "ERROR";
          });
      })
      .catch((e) => {
        console.error("No se pudo grabar la tienda", e);
        this.snackbar.open("Error, no se pudo grabar")._dismissAfter(4000);
        return "ERROR";
      }).finally(() => {
        this.enviando = false;
      });
  }

  postLocalSQL(keyLocal: string, keyHub: string) {
    let body: any = {
      _key: keyLocal,
      keyHub: keyHub, //string vacio para deletear
    }
    let url = environment.urls.functions + "/basesql/local"
    console.log(body);
    return this.http.post(url, body).toPromise().then((res) => {
      console.log(res)
      return true;
    }).catch(error => {
      console.error(error);
      this.dbFirestore.collection('mail').add({
        to: 'ldrazic@popapp.io, nquinteros@popapp.io, jcortez@popapp.io',
        message: {
          text: 'Error al actualizar keyHub en: "' + keyLocal + '", keyHub: "' + keyHub + '"',
          subject: 'Error al actualizar keyHub en: "' + keyLocal + '", keyHub: "' + keyHub + '"'
        }
      })
      return false;
    });


  }
  async activarEnDidi(origen: string, idStore: string) {
    try {

      // const baseUrl = "https://us-central1-popapptest-520dc.cloudfunctions.net";
      const baseUrl = "https://us-central1-popappowner.cloudfunctions.net";
      // const baseUrl = "http://localhost:5000/popappowner/us-central1";
      // const baseUrl = "http://localhost:5000/popapptest-520dc/us-central1";

      let headers = new Headers({ "Content-Type": "application/json" });
      let options = new RequestOptions({ headers: headers });

      const body = {
        origen: origen,
        idStore: idStore,
        metodo: "OpenAPI"
      };

      const urlActivar = baseUrl + "/didiv2/cambiar_autoaceptacion/";
      let resActivar = await this.http.post(urlActivar, body, options).toPromise();
      let jsonRespuestaActivar = await resActivar.json();
      console.log("Respuesta activar didi", jsonRespuestaActivar);
    } catch (e) {
      console.error(e);
      this.snackbar.open("No se pudo activar en Didi OpenAPI", "Comunicar a Integraciones")._dismissAfter(4000);
      let msg = '' + e;

      try {
        const error = e.json();
        if (error && error.msg) {
          msg = error.msg;
          console.error(msg);
        }

      } catch (e) {
        console.error(e);
      }
      this.dbFirestore.collection('mail').add({
        to: 'gmarin@popapp.io, jcortez@popapp.io, nquinteros@popapp.io, ldrazic@popapp.io',
        message: {
          text: '' + e,
          subject: 'Error al cambiar autoaceptacion ' + idStore
        }
      })
    }


  }
  async activarWebhookCambiosDeEstadoRappi(idLocal: string, origen: string) {
    //En PAIS debe ir cl para chile, mx para mexico y ar para argentina
    let pais: string;
    if (this.origenSeleccionado && this.origenSeleccionado.pais) {
      pais = this.origenSeleccionado.pais;
    } else {
      const msj =
        "No funciono el llamado al webhook de rappi, por favor reintentar";
      console.log(msj, idLocal, origen);
      this.snackbar.open(msj)._dismissAfter(4000);
      return;
    }
    const baseUrl = environment.urls.functions + "/";
    // const baseUrl = "http://localhost:5000/popappowner/us-central1/";
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });


    // activarPedido
    let exito = true;

    try {

      const urlActivar = baseUrl + "rappi/" + pais + "/activarWebhooks/" + idLocal;
      let resActivar = await this.http.post(urlActivar, {}, options).toPromise();
      let jsonRespuestaActivar = await resActivar.json();
      console.log("Respuesta webhook rappi", jsonRespuestaActivar);
      if (jsonRespuestaActivar && jsonRespuestaActivar.code && +jsonRespuestaActivar.code && +jsonRespuestaActivar.code >= 300) {
        this.snackbar.open("Comunicar LEAN, Webhook pedidoNuevo ERROR: " + jsonRespuestaActivar.code)._dismissAfter(6000);
        exito = false;
      }
    } catch (e) {
      console.error(e);
      this.snackbar.open("Comunicar LEAN, Webhook pedidoNuevo ERROR con captura de consola F12")._dismissAfter(6000);
      exito = false;
    }

    if (exito) {
      this.snackbar.open("Webhook pedidoNuevo y cambiosEstado ACTIVADO")._dismissAfter(2000);
    }

    return "OK";
  }

  async obtenerTiendas() {
    const baseUrl = environment.urls.functions + "/basesql/tiendas_locales";
    // const baseUrl = "http://localhost:5000/popappowner/us-central1/basesql/tiendas_locales";

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    const body = { arregloKeyLocales: [this.s.keyPedidosOnline.key] };
    try {
      let resActivar = await this.http.post(baseUrl, body, options).toPromise();
      let jsonRespuestaActivar = await resActivar.json();
      console.log("Respuesta tiendas sql", jsonRespuestaActivar);
    } catch (e) {
      console.error(e);
      this.snackbar.open("Error leer tiendas sql", "")._dismissAfter(2000);
    }
  }

  /**
   * 
   */
  async desOActivarEnSQL(creandoTienda: boolean, mapUpdatesTiendaFS: any) {
    const baseUrl = environment.urls.functions + "/basesql/tiendas";
    // const baseUrl = "http://localhost:5000/popappowner/us-central1/basesql/tiendas";
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    const body = mapUpdatesTiendaFS;
    let resActivar
    if (creandoTienda) {
      resActivar = await this.http.post(baseUrl, body, options).toPromise();
    } else {
      resActivar = await this.http.patch(baseUrl, body, options).toPromise();
    }
    let jsonRespuestaActivar = await resActivar.json();
    console.log("Respuesta tienda sql", jsonRespuestaActivar);
  }

  async borrar(segurisimx?: boolean) {
    this.error = "";

    if (
      this.s.idCuenta &&
      this.s.idLocal &&
      this.s.idMac &&
      this.s.keyPedidosOnline.key &&
      this.local &&
      this.local.origen &&
      this.local.idStore &&
      this.origenSeleccionado
    ) {
      // nada, está bien
    } else {
      console.error(
        "-   s.idCuenta: ",
        this.s.idCuenta,
        "-   s.idLocal: ",
        this.s.idLocal,
        "-   s.idMac: ",
        this.s.idMac,
        "-   local: ",
        this.local
      );
      this.error = "NO SE PUEDE BORRAR LA TIENDA, consultar a GAMR";
      return;
    }
    const confirmado = segurisimx
      ? true
      : confirm("CUIDADO!!! RECUERDA REVISAR EL PROCEDIMIENTO! Estás segurx?");
    if (confirmado) {
      const muySegrx = segurisimx ? true : confirm("MUY SEGURX?");
      if (muySegrx) {
        this.submitted = true;
        this.error = null;

        let url = environment.urls.functions + "/basesql/tiendas/" + this.local.origen + '/' + this.local.idStore;

        const didDelete = await this.http.delete(url).toPromise().then((res) => {
          console.log(res)
          return true;
        }).catch(error => {
          console.error(error);
          return false;
        });

        if (!didDelete) {
          this.snackbar.open("No se puede borrar en la SQL por lo tanto no se puede borrar")._dismissAfter(2000);
          return;
        }

        let urlAdmin =
          "cuentas/" +
          this.s.idCuenta +
          "/locales/" +
          this.s.idLocal +
          "/servidores/" +
          this.s.idMac +
          "/keyPedidosOnline/locales/";
        let urlConfLocal = "confLocalPO/" + this.s.keyPedidosOnline.key + "/";

        let mapUpdates = {};
        //let mapUpdatesFS = {};

        let keyHub: string = this.local.keyHub;

        const origen3 = this.origenSeleccionado.abreviacion.substr(0, 3);

        urlAdmin += origen3 + this.local.idStore + "/";
        let urlConfLocalTienda =
          urlConfLocal +
          "tiendas/" +
          this.origenSeleccionado.abreviacion +
          "," +
          this.local.idStore +
          "/";

        let urlkeysLocales =
          "keysLocalesPedidosOnline/" + origen3 + this.local.idStore + "/";

        mapUpdates[urlAdmin] = null;
        mapUpdates[urlkeysLocales] = null;

        return this.db
          .object("/")
          .update(mapUpdates)
          .then((ok) => {
            //TODO: if(activo){
            //TODO:   hitear a un endpoint para activar webhook de ra2
            //TODO: }
            let batch = this.dbFirestore.firestore.batch();
            let refTienda = this.dbFirestore.doc(urlConfLocalTienda).ref;
            batch.delete(refTienda);

            if (keyHub && keyHub.trim()) {
              let refTienda2 = this.dbFirestore.doc(
                "confLocalPO/" +
                keyHub.trim() +
                "/tiendasId/" +
                this.origenSeleccionado.abreviacion +
                "," +
                this.local.idStore +
                "/"
              ).ref;
              batch.delete(refTienda2);
            }

            return batch
              .commit()
              .then((v) => {
                this.snackbar.open("Tienda borrada")._dismissAfter(2000);
                this.dialogRef.close();
              })
              .catch((e) => {
                console.error("No se pudo borrar la tienda en FS", e);
                this.snackbar
                  .open("Error, no se pudo borrar")
                  ._dismissAfter(4000);
              });
          })
          .catch((e) => {
            console.error("No se pudo borrar la tienda", e);
            this.snackbar.open("Error, no se pudo borrar")._dismissAfter(4000);
          }).finally(() => {
            this.submitted = false;
          });
      }
    }
  }

  /** url prod  "environment.urls.functionsbasesql/marcas"*/
  /**Url test = https://us-central1-popapptest-520dc.cloudfunctions.net/basesql/marcas */


  async getMarcas() {
    const url =
      environment.urls.functions + "/basesql/marcas";
    const res = await this.http
      .get(url)
      .toPromise();
    let body = res.json();
    body.marcas.forEach((element: string) => this.marcas.push(element));
    this.mostrarSeleccionarMarcas = true;
  }

  showModalMarcas() {
    const dialogRef = this.dialog.open(ModalMarcasComponent, {
      width: "85%",
      data: { marcas: this.marcas },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getMarcas()
    });

  }


}
