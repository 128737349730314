
import { Component, Input, OnInit } from '@angular/core';
import { GooglepiechartService } from '../googlepiechart.service';
import { LineChartConfig } from '../linechartconfig';
import { GooglelinechartService } from '../googlelinechart.service';

declare var google: any;

@Component({
  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.scss']
})



export class LinechartComponent implements OnInit {

  @Input() _data: any[];
  @Input() config: LineChartConfig;
  @Input() elementId: string;
  @Input() _mobileQueryMatches: boolean;


  @Input() set data(value: any[]) {
    if (value) {
      this._data = value;
      this._lineChartService.BuildLineChart(this.elementId, this.data, this.config);
    }
  }

  get data(): any[] {
    return this._data;
  }

  @Input() set mobileQueryMatches(value: boolean) {
    this._mobileQueryMatches = value;
    this._lineChartService.BuildLineChart(this.elementId, this.data, this.config);
  }

  get mobileQueryMatches(): boolean {
    return this._mobileQueryMatches;
  }


  constructor(private _lineChartService: GooglelinechartService) { }

  ngOnInit() {
  }

}

