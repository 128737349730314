import { Subscription } from 'rxjs';


import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { Cuenta } from '../shared/cuenta';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { MatSort, } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Producto } from '../shared/producto';


@Component({
  selector: 'app-productos-abm',
  templateUrl: './productos-abm.component.html',
  styleUrls: ['./productos-abm.component.scss']
})

export class ProductosABMComponent implements OnInit, OnDestroy {
  checked = false;
  link = "popapp.io/productos?n=";

  cuenta: Cuenta;
  copiarLink() {
    this.copyToClipboard(this.link + this.cuenta.id)

    this._snackbar.open("Copiado al portapapeles", "", {
      duration: 3000,
    });
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }




  //#region [ rgba (100, 200, 10, 50) ] Variables
  buscando: boolean = true;


  email: string;
  encabezado: string;
  noHayPermiso = false;

  productos: Producto[];
  productosObs: Observable<any>;
  offset: number;
  subscriptionCuenta: Subscription;

  iSelec: Producto;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  //#region Variables Tabla
  dataSource: MatTableDataSource<Producto>;
  displayedColumns: string[] =
    [
      "categoria", "nombre", "precio", "delete",
    ];
  @ViewChild(MatSort) sort: MatSort;

  //#endregion

  constructor(private activatedRoute: ActivatedRoute, protected router: Router, public authService: AuthService, public db: AngularFireDatabase, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public _snackbar: MatSnackBar) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }



  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "," + mes : ",0" + mes;

    const diaString = fecha.getDate() > 9 ? "," + fecha.getDate() : ",0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  sortHeader() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {

    this.offset = (new Date()).getTimezoneOffset();
    this.dataSource = new MatTableDataSource(this.productos);
    this.buscando = true;
    this.authService.user.subscribe(user => {
      this.noHayPermiso = false;

      if (user && user.email) {
        // console.log("SUSCRIPTO");
        this.email = user.email;
        this.subscriptionCuenta = this.authService.cuenta$
          .subscribe(cuenta => {
            if (cuenta) {
              this.buscando = true;
              this.cuenta = cuenta;
              this.buscar(cuenta)

            } else {
              //No hay permiso
              this.noHayPermiso = true;
              this.buscando = false;
            }
          });
      } else {
        this.buscando = false;
      }
    });
  }

  ngOnDestroy(): void {
    try { this.subscriptionCuenta.unsubscribe() } catch (e) { }
  }

  delete(p: Producto) {
    this.db.object("productos/" + this.cuenta.id + "/prods/" + p.key).set(null);
  }

  buscar(cuenta: Cuenta) {
    this.buscando = true;

    let prods = this.db.object("productos/" + cuenta.id);

    this.productosObs = prods.snapshotChanges().pipe(

      map(changes => {
        let mapeado = changes.payload.val();
        this.buscando = false;
        return this.sacaItems(mapeado);
      })
    );

  }




  sacaItems(mapeado): Producto[] {
    let items: Producto[] = []
    this.productos = []

    for (let i in mapeado.prods) {
      let p: Producto = mapeado.prods[i];
      p.key = i;
      items.push(p);
    }

    this.productos = items
    this.dataSource = new MatTableDataSource(this.productos);
    //this.applyFilter(this.filtroActual);
    return items;
  }

  getCantItems() {
    return this.productos.length
  }


  fechaLocal(fecha: string) {
    let date = new Date(fecha);
    date.setMinutes(date.getMinutes() + this.offset);
    return date.toISOString();
  }

}


