import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { Cuenta } from '../../shared/cuenta';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { PuntuacionResumen } from '../../shared/admin/puntuacionResumen';
import { PuntuacionJornada } from 'src/app/shared/admin/puntuacionJornada';

import * as consts from "../../shared/constantes";
import { ResellerGuard } from 'src/app/guards/reseller.guard';


@Component({
  selector: 'app-admin-puntuaciones',
  templateUrl: './admin-puntuaciones.component.html',
  styleUrls: ['./admin-puntuaciones.component.scss']
})
export class AdminPuntuacionesComponent implements OnInit, OnDestroy {

  //#region [ rgba (100, 200, 10, 50) ] Variables
  buscando: boolean = true;
  cuentas: Cuenta[];

  email: string;
  encabezado: string;
  noHayPermiso = false;

  puntuacionesResumen: PuntuacionResumen[];
  puntuacionesObs: Observable<PuntuacionResumen[]>;
  offset: number;

  reseller: boolean;
  ultimos: string = "15";

  pSelec: PuntuacionResumen;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  //#region Variables Tabla
  dataSource: MatTableDataSource<PuntuacionResumen>;
  displayedColumns: string[] =
    [
      "nombre",
      "promedio",
      "total",
      "cantPunt",
      "comentarios",

      "qxPuntbaja",
      "qxEstadosAbiertos",
      "qxEstadosCerrados",
      "qxEstadosResueltos",

      "fechaUltimaPunt",
      "ultimaPunt",
      "mac",
      "idCuenta",
    ];
  @ViewChild(MatSort) sort: MatSort;

  //#endregion


  constructor(private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, protected router: Router, public authService: AuthService, public db: AngularFireDatabase, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private resellerGuard: ResellerGuard
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  rowSeleccionada(row: PuntuacionResumen, seleccionada: boolean) {
    if (seleccionada) {
      this.pSelec = row;
    } else {
      this.pSelec = undefined;
    }
  }

  esSeleccionada(row: PuntuacionResumen) {
    if (row && row.mac && this.pSelec && this.pSelec.mac && row.mac === this.pSelec.mac) {
      return {
        'background': 'lightgrey',
        'cursor': 'pointer'
      }
    }
    return {}
  }

  verPuntuaciones(p: PuntuacionResumen) {
    this.router.navigateByUrl("/mypopapp/adminPuntuaciones/" + p.idCuenta);
  }

  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "," + mes : ",0" + mes;

    const diaString = fecha.getDate() > 9 ? "," + fecha.getDate() : ",0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  sortHeader() {
    this.dataSource.sort = this.sort;
  }
  driftVar: any;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  esAdmin(user) {
    return consts.esAdmin(user);
  }

  ngOnInit() {


    this.offset = (new Date()).getTimezoneOffset();
    this.dataSource = new MatTableDataSource(this.puntuacionesResumen);
    this.buscando = true;
    this.hideDrift(1)
    this.authService.user.subscribe(user => {
      this.noHayPermiso = false;
      if (user && user.email) {
        // console.log("SUSCRIPTO");
        this.email = user.email;

        if (this.esAdmin(user)) {
          this.buscar()

        } else {
          this.resellerGuard.canActivate(null, null).then(v => {

            this.reseller = v;
            if (this.reseller) {
              this.authService.cuentas$.subscribe(cuentas => {
                if (cuentas) {
                  console.log("cuentas", cuentas)
                  this.cuentas = cuentas;
                  this.buscar()
                }
              });
            } else {
              //No hay permiso
              this.noHayPermiso = true;
              this.buscando = false;
            }
          });
        }

      } else {
        this.buscando = false;
      }
    });


  }

  ngOnDestroy(): void {
  }

  buscar() {
    this.buscando = true;

    let resumenFireList = this.db.list("puntuaciones/resumenLocales");

    this.puntuacionesObs = resumenFireList.snapshotChanges().pipe(

      map(changes => {
        let mapeado = changes.map(o => ({ mac: o.payload.key, ...<PuntuacionResumen>o.payload.val() }));
        this.buscando = false;
        return this.sacaResumenes(mapeado);
      })
    );

  }
  actualizarQx() {
    let v = confirm("Esta operacion puede demorar, continuar?")
    if (v) {
      this.buscando = true;
      let contexto = this;
      this.db.object("puntuaciones/jornadas").query.once("value", function (snap) {
        if (snap) {
          let lista = snap.val();
          if (lista) {
            let mapJornadasResxMac: Map<string, PuntuacionResumen> = new Map<string, PuntuacionResumen>();
            for (let idJ in lista) {
              let pj: PuntuacionJornada = lista[idJ];
              if (mapJornadasResxMac.get(pj.mac)) {
                let pr: PuntuacionResumen = mapJornadasResxMac.get(pj.mac);

                if (pj.comentario) {
                  if (!pr.comentarios) {
                    pr.comentarios = 1;
                  } else {
                    pr.comentarios = pr.comentarios + 1;
                  }
                }
                pr.fechaUltimaPunt = pj.fecha;
                pr.nombre = pj.local;
                const sumaPuntuacionesNueva = pr.total + pj.puntuacion;
                const cantPuntuacionesNueva = pr.cantPunt + 1;

                pr.cantPunt = cantPuntuacionesNueva;
                pr.promedio = sumaPuntuacionesNueva / cantPuntuacionesNueva;
                pr.total = sumaPuntuacionesNueva;
                pr.ultimaPunt = pj.puntuacion;
                if (pj.puntuacion < 5) {
                  pr.qxPuntbaja = pr.qxPuntbaja + 1;
                }

                if (pj.estado) {
                  if (pj.estado === "RESUELTO") {
                    pr.qxEstadosResueltos = pr.qxEstadosResueltos + 1;
                  } else if (pj.estado === "CERRADO") {
                    pr.qxEstadosCerrados = pr.qxEstadosCerrados + 1;
                  } else {
                    pr.qxEstadosAbiertos = pr.qxEstadosAbiertos + 1;
                  }
                } else {
                  if (pj.comentario || pj.puntuacion < 5) {
                    pr.qxEstadosAbiertos = pr.qxEstadosAbiertos + 1;
                  }
                }
              } else {
                let pr: PuntuacionResumen = new PuntuacionResumen();
                pr.qxEstadosAbiertos = 0;
                pr.qxEstadosCerrados = 0;
                pr.qxEstadosResueltos = 0;
                pr.qxPuntbaja = 0;
                if (pj.comentario) {
                  pr.comentarios = 1;
                } else {
                  pr.comentarios = 0;
                }
                pr.fechaUltimaPunt = pj.fecha;
                pr.idCuenta = idJ.split(",")[0];
                pr.cantPunt = 1;
                pr.nombre = pj.local;
                pr.promedio = pj.puntuacion;
                pr.total = pj.puntuacion;
                pr.ultimaPunt = pj.puntuacion;
                if (pj.puntuacion < 5) {
                  //pr.qxEstadosAbiertos = 1;
                  pr.qxPuntbaja = 1;
                }
                if (pj.estado) {
                  if (pj.estado === "RESUELTO") {
                    pr.qxEstadosResueltos = 1;
                  } else if (pj.estado === "CERRADO") {
                    pr.qxEstadosCerrados = 1;
                  } else {
                    pr.qxEstadosAbiertos = 1;
                  }
                } else {
                  if (pj.puntuacion < 5) {
                    pr.qxEstadosAbiertos = 1;
                  } else if (pj.comentario) {
                    pr.qxEstadosAbiertos = 1;
                  }
                }

                mapJornadasResxMac.set(pj.mac, pr);
              }
            }
            contexto.comparaCambiosYgrabaEnfb(mapJornadasResxMac);
          }
        }
      });
    }
  }

  comparaCambiosYgrabaEnfb(map: Map<string, PuntuacionResumen>) {
    let mapUpdates = {}
    for (let prActual of this.puntuacionesResumen) {
      let prGenerada = map.get(prActual.mac);
      if (prGenerada.cantPunt !== prActual.cantPunt) {
        mapUpdates[prActual.mac + "/cantPunt"] = prGenerada.cantPunt;
        console.log(prActual.mac + "/cantPunt antes:" + prActual.cantPunt + " ahora:", prGenerada.cantPunt);
      }
      if (prGenerada.comentarios !== prActual.comentarios) {
        mapUpdates[prActual.mac + "/comentarios"] = prGenerada.comentarios;
        console.log(prActual.mac + "/comentarios antes:" + prActual.comentarios + " ahora:", prGenerada.comentarios);
      }

      if (prGenerada.fechaUltimaPunt !== prActual.fechaUltimaPunt) {
        mapUpdates[prActual.mac + "/fechaUltimaPunt"] = prGenerada.fechaUltimaPunt;
        console.log(prActual.mac + "/fechaUltimaPunt antes:" + prActual.fechaUltimaPunt + " ahora:", prGenerada.fechaUltimaPunt);
      }

      if (prGenerada.idCuenta !== prActual.idCuenta) {
        mapUpdates[prActual.mac + "/idCuenta"] = prGenerada.idCuenta;
        console.log(prActual.mac + "/idCuenta antes:" + prActual.idCuenta + " ahora:", prGenerada.idCuenta);
      }

      if (prGenerada.nombre !== prActual.nombre) {
        mapUpdates[prActual.mac + "/nombre"] = prGenerada.nombre;
        console.log(prActual.mac + "/nombre antes:" + prActual.nombre + " ahora:", prGenerada.nombre);
      }

      if (prGenerada.promedio !== prActual.promedio) {
        mapUpdates[prActual.mac + "/promedio"] = prGenerada.promedio;
        console.log(prActual.mac + "/promedio antes:" + prActual.promedio + " ahora:", prGenerada.promedio);
      }

      if (prGenerada.qxPuntbaja !== prActual.qxPuntbaja && prGenerada.qxPuntbaja) {
        mapUpdates[prActual.mac + "/qxPuntbaja"] = prGenerada.qxPuntbaja;
        console.log(prActual.mac + "/qxPuntbaja antes:" + prActual.qxPuntbaja + " ahora:", prGenerada.qxPuntbaja);
      }

      if (prGenerada.total !== prActual.total) {
        mapUpdates[prActual.mac + "/total"] = prGenerada.total;
        console.log(prActual.mac + "/total antes:" + prActual.total + " ahora:", prGenerada.total);
      }

      if (prGenerada.ultimaPunt !== prActual.ultimaPunt) {
        mapUpdates[prActual.mac + "/ultimaPunt"] = prGenerada.ultimaPunt;
        console.log(prActual.mac + "/ultimaPunt antes:" + prActual.ultimaPunt + " ahora:", prGenerada.ultimaPunt);
      }

      if (prGenerada.qxEstadosAbiertos || prActual.qxEstadosAbiertos) {
        if (prGenerada.qxEstadosAbiertos && prActual.qxEstadosAbiertos && prGenerada.qxEstadosAbiertos !== prActual.qxEstadosAbiertos) {
          mapUpdates[prActual.mac + "/qxEstadosAbiertos"] = prGenerada.qxEstadosAbiertos;

        } else {
          if (prGenerada.qxEstadosAbiertos) {
            mapUpdates[prActual.mac + "/qxEstadosAbiertos"] = prGenerada.qxEstadosAbiertos;
          } else {
            mapUpdates[prActual.mac + "/qxEstadosAbiertos"] = 0;
          }
        }
        console.log(prActual.mac + "/qxEstadosAbiertos antes:" + prActual.qxEstadosAbiertos + " ahora:", prGenerada.qxEstadosAbiertos);
      }

      if (prGenerada.qxEstadosCerrados !== prActual.qxEstadosCerrados) {
        mapUpdates[prActual.mac + "/qxEstadosCerrados"] = prGenerada.qxEstadosCerrados;
        console.log(prActual.mac + "/qxEstadosCerrados antes:" + prActual.qxEstadosCerrados + " ahora:", prGenerada.qxEstadosCerrados);
      }
      if (prGenerada.qxEstadosResueltos !== prActual.qxEstadosResueltos) {
        mapUpdates[prActual.mac + "/qxEstadosResueltos"] = prGenerada.qxEstadosResueltos;
        console.log(prActual.mac + "/qxEstadosResueltos antes:" + prActual.qxEstadosResueltos + " ahora:", prGenerada.qxEstadosResueltos);
      }

    }

    if (mapUpdates !== {}) {
      this.db.object("puntuaciones/resumenLocales").update(mapUpdates);
      this._snackBar.open("Cambios guardados", "", {
        duration: 3000,
      });
    } else {
      this._snackBar.open("Sin cambios", "", {
        duration: 3000,
      });
    }
    this.buscando = false;
  }

  sacaResumenes(mapeado): PuntuacionResumen[] {
    let puntuacionesResumen: PuntuacionResumen[] = []
    this.puntuacionesResumen = []
    console.log("por buscar")
    for (let o of mapeado) {
      if (this.reseller) {
        console.log(o.mac)
        let as = this.cuentas.find(x => x.id === o.mac)
        if (as) {
          console.log(as);

          puntuacionesResumen.push(o)
        }
      } else {
        puntuacionesResumen.push(o)
      }
    }

    this.puntuacionesResumen = puntuacionesResumen
    this.dataSource = new MatTableDataSource(this.puntuacionesResumen);
    //this.applyFilter(this.filtroActual);
    return puntuacionesResumen;
  }

  getCantPunt() {
    return this.puntuacionesResumen.map(o => o.cantPunt).reduce((acc, value) => acc + value, 0);
  }
  getComentarios() {
    return this.puntuacionesResumen.map(o => o.comentarios).reduce((acc, value) => acc + value, 0);
  }

  getPromedio() {
    let acc = this.puntuacionesResumen.map(o => o.promedio).reduce(((acc, value) => { return value ? acc + value : acc }), 0);
    return acc / this.puntuacionesResumen.length
  }

  getTotal() {
    return this.puntuacionesResumen.map(o => o.total).reduce((acc, value) => acc + value, 0);
  }

  getQxEstadosAbiertos() {
    return this.puntuacionesResumen.map(o => o.qxEstadosAbiertos).reduce((acc, value) => value ? acc + value : acc, 0);
  }
  getQxEstadosCerrados() {
    return this.puntuacionesResumen.map(o => o.qxEstadosCerrados).reduce((acc, value) => value ? acc + value : acc, 0);
  }
  getQxEstadosResueltos() {
    return this.puntuacionesResumen.map(o => o.qxEstadosResueltos).reduce((acc, value) => value ? acc + value : acc, 0);
  }
  getQxPuntbaja() {
    return this.puntuacionesResumen.map(o => o.qxPuntbaja).reduce((acc, value) => value ? acc + value : acc, 0);
  }
  color(stars: number) {
    if (stars < 1.5) {
      return { color: "#D50000", "font-weight": "bold" }
    } else if (stars < 2.5) {
      return { color: "#FF6D00", "font-weight": "bold" }
    } else if (stars < 3.5) {
      return { color: "#FBC02D", "font-weight": "bold" }
    } else if (stars < 4.5) {
      return { color: "#558B2F", "font-weight": "bold" }
    } else {
      return { color: "#2E7D32", "font-weight": "bold" }
    }
  }



  fechaLocal(fecha: string) {
    let date = new Date(fecha);
    date.setMinutes(date.getMinutes() + this.offset);
    return date.toISOString();
  }

}
