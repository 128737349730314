import { Injectable } from '@angular/core';
import { GooglebasechartService } from './googlebasechart.service';
import { BarChartConfig } from './barchartconfig';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglebarchartService extends GooglebasechartService {

  constructor() { super(); }
  public BuildBarChart(elementId: string, data: any[], config: BarChartConfig): void {
    var chartFunc = () => { return new google.visualization.ColumnChart(document.getElementById(elementId)); };
    let options
    if (config.colors) {
      options = {
        title: config.title,
        //curveType: config.curveType,
        //legend: { position: 'bottom' },
        colors: config.colors,

      };
    } else {
      options = {
        title: config.title,
        //curveType: config.curveType,
        //legend: { position: 'bottom' }
      };
    }


    this.buildChart(data, chartFunc, options);
  }
}
