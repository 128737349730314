import { Component, OnInit } from '@angular/core';
import { RutaService } from '../../services/ruta.service';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { CuentaService } from '../../services/cuenta.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
@Component({
  selector: 'app-bienvenido',
  templateUrl: './bienvenido.component.html',
  styleUrls: ['./bienvenido.component.scss', '../../../mypopapp/assets/_css/style.css', '../../../mypopapp/assets/_css/style1.2.css', '../../../mypopapp/assets/_css/media-queries.css']

})
export class BienvenidoComponent implements OnInit {



  error: string;
  descargando: boolean = false;
  formComenzar: FormGroup;
  submitted = false;
  emailInvalido = false;

  constructor(public rutaService: RutaService, private formBuilder: FormBuilder, public router: Router, public cuentaService: CuentaService, public db: AngularFireDatabase, public authService: AuthService, private http: Http) { }

  ngOnInit() {
    this.rutaService.changeRuta("bienvenido")
    this.formComenzar = this.formBuilder.group({
      nombre: ["", [Validators.required, Validators.minLength(2)]],
      codPais: ["", [Validators.required]],
      telefono: ["", [Validators.required, Validators.minLength(5)]],
      nombreLocal: ["", [Validators.required]],
      pais: ["", [Validators.required]],
    });
    if (this.authService.emailCuenta) {
      if (this.authService.emailCuenta.nombre)
        this.f.nombre.setValue(this.authService.emailCuenta.nombre);
      if (this.authService.emailCuenta.nombreLocal)
        this.f.nombreLocal.setValue(this.authService.emailCuenta.nombreLocal);
      if (this.authService.emailCuenta.telPais)
        this.f.codPais.setValue(this.authService.emailCuenta.telPais);
      if (this.authService.emailCuenta.telArea || this.authService.emailCuenta.telNum)
        this.f.telefono.setValue(this.authService.emailCuenta.telArea ? this.authService.emailCuenta.telArea : "" + this.authService.emailCuenta.telNum ? this.authService.emailCuenta.telNum : "");
      else if (this.authService.emailCuenta.wapp)
        this.f.telefono.setValue(this.authService.emailCuenta.wapp);
      if (this.authService.emailCuenta.pais)
        this.f.pais.setValue(this.authService.emailCuenta.pais);
    }

  }

  anterior() {
    this.authService.signOut(true);

  }
  get f() { return this.formComenzar.controls; }

  async descargar(omitir: boolean) {
    this.submitted = true;
    this.error = null;


    if (this.formComenzar.invalid) {
      this.error = "Datos inválidos";
      return;
    }

    //primero revisar si ya esta logueado con ese mail
    this.descargando = true

    // subimos el formulario 
    let map = {};
    const nombre = this.f.nombre.value;
    const nombreLocal = this.f.nombreLocal.value;
    const pais = this.f.pais.value;
    const wapp = this.f.codPais.value + "" + this.f.telefono.value;
    const telPais = this.f.codPais.value;
    const telNum = this.f.telefono.value;
    const email = this.authService.emailCuenta.email;

    map["nombre"] = nombre;
    map["nombreLocal"] = nombreLocal;
    map["pais"] = pais;
    map["wapp"] = wapp;
    map["telPais"] = telPais;
    map["telNum"] = telNum;

    // let seCreo = await this.creaContactoHubspot(email, nombre, wapp, pais, nombreLocal);
    // if (!seCreo) {
    //   /**
    //    * Mandar email de error pq no se pudo crear el contacto en hubspot
    //    */
    //   let cuerpoDeMailError = "Los datos son:"
    //     + "\nNombre: " + nombre
    //     + "\nNegocio: " + nombreLocal
    //     + "\nPais: " + pais
    //     + "\nTelefono: " + wapp
    //     + "\n\n";

    //   if (this.emailInvalido) {
    //     cuerpoDeMailError = "***Error email invalido*** \n\n" + cuerpoDeMailError;
    //   }
    //   //const urlServidorMail = "environment.urls.escuchador/payments/mails/errorHubspot";

    //   let body = {
    //     notification: {
    //       title: "popapp",
    //       to: "gmarin@popapp.io",
    //       copia: "gmarin@popapp.io",
    //       asunto: "No se pudo crear el contaco en Hubspot " + nombre,
    //       text: cuerpoDeMailError
    //     }
    //   }
    //   this.cuentaService.enviarEmailAFSProm(body, "errorHubspot");
    //   //this.cuentaService.enviaMail(body, urlServidorMail)
    // }

    if (this.emailInvalido) {
      //no dejamos que avance
      return;
    }
    let cuerpoDeMail = "Te damos la bienvenida a Popapp " + email + ",\nEn el siguiente link puedes descargar Popapp Manager para Windows\nhttps://popapp.io/descargas/setupPopApp.exe\n\n";
    this.db.object("emailsCuentas/" + this.authService.decodeEmail(email) + "/").update(map)
    cuerpoDeMail += "Tus datos son:";
    cuerpoDeMail += "\nNombre: " + nombre;
    cuerpoDeMail += "\nNegocio: " + nombreLocal;
    cuerpoDeMail += "\nPais: " + pais;
    cuerpoDeMail += "\nTelefono: " + wapp;
    cuerpoDeMail += "\n\n";


    //let urlServidorMail = "environment.urls.escuchador/payments/mails/leads";

    cuerpoDeMail += "Cualquier duda puedes contactarte con nosotros a través de nuestra web https://popapp.io\nGracias,\nEl equipo de popapp."

    let body = {
      notification: {
        title: "popapp",
        to: "pgarcia@popapp.io",
        copia: "jcorona@popapp.io, victoria@popapp.io, agustina@popapp.io, fzarate@popapp.io, plopez@popapp.io",
        asunto: "Bienvenido a popapp " + nombre,
        text: cuerpoDeMail
      }
    }

    let body2 = {
      notification: {
        title: "popapp",
        to: email,
        copia: "jcorona@popapp.io",
        asunto: "Hola 👋 ¡Bienvenid@ a Popapp!",
        text: cuerpoDeMail,
        html: this.leerHtml(nombre)
      }
    }
    //mandar email para descargar con copia al facu jorge y a mi
    this.cuentaService.enviarEmailAFSProm(body, "leads");
    this.cuentaService.enviarEmailAFSProm(body2, "leads");

    //this.cuentaService.enviaMail(body, "leads");
    //this.cuentaService.enviaMail(body2, "leads");
    this.router.navigateByUrl("mypopapp/descargar?de=bienvenido");

  }

  // async creaContactoHubspot(email?, name?, phone?, pais?, nombreLocal?) {
  //   this.emailInvalido = false;
  //   try {
  //     let _hsq = window["_hsq"] = window["_hsq"] || [];
  //     _hsq.push(["identify", {
  //       email: email,
  //       firstname: name,
  //       phone: phone,
  //       country: pais,
  //       company: nombreLocal
  //     }]);
  //     _hsq.push(['trackPageView']);
  //     return true;
  //   } catch (error) {
  //     console.log("error hs", error);
  //     let headers = new Headers({ 'Content-Type': 'application/json' });
  //     let options = new RequestOptions({ headers: headers });
  //     let jsonContacto = {};
  //     jsonContacto["properties"] = [];
  //     jsonContacto["properties"].push({
  //       "property": "firstname",
  //       "value": name
  //     })
  //     jsonContacto["properties"].push({
  //       "property": "phone",
  //       "value": phone
  //     })
  //     jsonContacto["properties"].push({
  //       "property": "country",
  //       "value": pais
  //     })
  //     jsonContacto["properties"].push({
  //       "property": "company",
  //       "value": nombreLocal
  //     })
  //     this.http.post("environment.urls.functionsapi/createOrUpdateContactHubspot/" + email, jsonContacto, options).toPromise()
  //       .then(v => {
  //         console.log("Exito", v);
  //         return false;
  //       })
  //       .catch(e => {
  //         if (e && e._body && e._body === "{'errorEmail':'INVALID_EMAIL'}") {
  //           this.emailInvalido = true;
  //           return false;
  //         }
  //         return false;
  //       });
  //   }
  // }


  leerHtml(nombre: string): string {
    return "<!DOCTYPE HTML PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\" \"http://www.w3.org/TR/html4/loose.dtd\">\n" +
      "<html>\n" +
      "\n" +
      "<head>\n" +
      "   <meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\n" +
      "   <title>Bienvenida</title>\n" +
      "   <link href=\"https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700\" rel=\"stylesheet\">\n" +
      "   <style type=\"text/css\">\n" +
      "      html {\n" +
      "         -webkit-text-size-adjust: none;\n" +
      "         -ms-text-size-adjust: none;\n" +
      "      }\n" +
      "\n" +
      "      @media only screen and (min-device-width: 750px) {\n" +
      "         .table750 {\n" +
      "            width: 750px !important;\n" +
      "         }\n" +
      "      }\n" +
      "\n" +
      "      @media only screen and (max-device-width: 750px),\n" +
      "      only screen and (max-width: 750px) {\n" +
      "         table[class=\"table750\"] {\n" +
      "            width: 100% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_b {\n" +
      "            width: 93% !important;\n" +
      "            max-width: 93% !important;\n" +
      "            min-width: 93% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_b1 {\n" +
      "            width: 100% !important;\n" +
      "            max-width: 100% !important;\n" +
      "            min-width: 100% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_left {\n" +
      "            text-align: left !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_soc {\n" +
      "            width: 50% !important;\n" +
      "            max-width: 50% !important;\n" +
      "            min-width: 50% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_menu {\n" +
      "            width: 50% !important;\n" +
      "            max-width: 50% !important;\n" +
      "            min-width: 50% !important;\n" +
      "            box-shadow: inset -1px -1px 0 0 rgba(255, 255, 255, 0.2);\n" +
      "         }\n" +
      "\n" +
      "         .mob_btn {\n" +
      "            width: 100% !important;\n" +
      "            max-width: 100% !important;\n" +
      "            min-width: 100% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_card {\n" +
      "            width: 88% !important;\n" +
      "            max-width: 88% !important;\n" +
      "            min-width: 88% !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_title1 {\n" +
      "            font-size: 36px !important;\n" +
      "            line-height: 40px !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_title2 {\n" +
      "            font-size: 26px !important;\n" +
      "            line-height: 33px !important;\n" +
      "         }\n" +
      "\n" +
      "         .top_pad {\n" +
      "            height: 15px !important;\n" +
      "            max-height: 15px !important;\n" +
      "            min-height: 15px !important;\n" +
      "         }\n" +
      "\n" +
      "         .mob_pad {\n" +
      "            width: 15px !important;\n" +
      "            max-width: 15px !important;\n" +
      "            min-width: 15px !important;\n" +
      "         }\n" +
      "\n" +
      "         .top_pad2 {\n" +
      "            height: 40px !important;\n" +
      "            max-height: 40px !important;\n" +
      "            min-height: 40px !important;\n" +
      "         }\n" +
      "      }\n" +
      "\n" +
      "      @media only screen and (max-device-width: 550px),\n" +
      "      only screen and (max-width: 550px) {\n" +
      "         .mod_div {\n" +
      "            display: block !important;\n" +
      "         }\n" +
      "      }\n" +
      "\n" +
      "      .table750 {\n" +
      "         width: 750px;\n" +
      "      }\n" +
      "   </style>\n" +
      "</head>\n" +
      "\n" +
      "<body style=\"margin: 0; padding: 0;\">\n" +
      "\n" +
      "   <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "      style=\"background: #f5f8fa; min-width: 340px; font-size: 1px; line-height: normal;\">\n" +
      "      <tr>\n" +
      "         <td align=\"center\" valign=\"top\">\n" +
      "            <!--[if (gte mso 9)|(IE)]>\n" +
      "         <table border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n" +
      "         <tr><td align=\"center\" valign=\"top\" width=\"750\"><![endif]-->\n" +
      "            <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"750\" class=\"table750\"\n" +
      "               style=\"width: 100%; max-width: 750px; min-width: 340px; background: #f5f8fa;\">\n" +
      "               <tr>\n" +
      "                  <td class=\"mob_pad\" width=\"25\" style=\"width: 25px; max-width: 25px; min-width: 25px;\">&nbsp;</td>\n" +
      "                  <td align=\"center\" valign=\"top\" style=\"background: #ffffff;\">\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                        style=\"width: 100% !important; min-width: 100%; max-width: 100%; background: #f5f8fa;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"right\" valign=\"top\">\n" +
      "                              <div class=\"top_pad\" style=\"height: 25px; line-height: 25px; font-size: 23px;\">&nbsp;\n" +
      "                              </div>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                        style=\"width: 88% !important; min-width: 88%; max-width: 88%;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"left\" valign=\"top\">\n" +
      "                              <div style=\"height: 30px; line-height: 30px; font-size: 28px;\">&nbsp;</div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           <table border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n" +
      "                           <tr><td align=\"center\" valign=\"top\" width=\"460\"><![endif]-->\n" +
      "                              <div style=\"display: inline-block; vertical-align: top; width: 74%; min-width: 270px;\">\n" +
      "                                 <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                                    style=\"width: 100% !important; min-width: 100%; max-width: 100%;\">\n" +
      "                                    <tr>\n" +
      "                                       <td align=\"left\" valign=\"top\">\n" +
      "                                          <div style=\"height: 10px; line-height: 10px; font-size: 8px;\">&nbsp;</div>\n" +
      "                                          <a href=\"https://popapp.io\" target=\"_blank\" style=\"display: block; max-width: 180px;\">\n" +
      "                                             <img src=\"https://popapp.io/bienvenida/logo-popapp.png\" alt=\"img\"\n" +
      "                                                width=\"180\" border=\"0\" style=\"display: block; width: 180px;\" />\n" +
      "                                          </a>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]></td><td align=\"center\" valign=\"top\" width=\"150\"><![endif]-->\n" +
      "                              <div style=\"display: inline-block; vertical-align: top; width: 150px;\">\n" +
      "                                 <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                                    style=\"width: 100% !important; min-width: 100%; max-width: 100%;\">\n" +
      "                                    <tr>\n" +
      "                                       <td class=\"mob_left\" align=\"right\" valign=\"top\">\n" +
      "                                          <div style=\"height: 15px; line-height: 15px; font-size: 13px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#949494\"\n" +
      "                                             style=\"font-size: 14px; line-height: 18px; font-weight: 700;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #949494; font-size: 14px; line-height: 18px; font-weight: 700;\">BIENVENID@</span>\n" +
      "                                          </font>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           </td></tr>\n" +
      "                           </table><![endif]-->\n" +
      "                              <div class=\"top_pad2\" style=\"height: 70px; line-height: 70px; font-size: 68px;\">&nbsp;\n" +
      "                              </div>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                        style=\"width: 88% !important; min-width: 88%; max-width: 88%;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"left\" valign=\"top\">\n" +
      "                              <font class=\"mob_title1\" face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                 style=\"font-size: 42px; line-height: 45px; font-weight: 300; letter-spacing: -1.5px;\">\n" +
      "                                 <span class=\"mob_title1\"\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 36px; line-height: 36px; font-weight: 300; letter-spacing: -1.5px;\">Hola\n " + nombre + ",</span>\n" +
      "                              </font>\n" +
      "                              <div style=\"height: 22px; line-height: 22px; font-size: 20px;\">&nbsp;</div>\n" +
      "                              <font class=\"mob_title2\" face=\"'Source Sans Pro', sans-serif\" color=\"#5e5e5e\"\n" +
      "                                 style=\"font-size: 36px; line-height: 36px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                 <span class=\"mob_title2\"\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #5e5e5e; font-size: 30px; line-height: 30px; font-weight: 300; letter-spacing: -1px;\">¡El\n" +
      "                                    equipo de Popapp te da la bienvenida!</span>\n" +
      "                              </font>\n" +
      "                              <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                              <a href=\"https://popapp.io/mypopapp/micuenta\" target=\"_blank\">\n" +
      "                              <img src=\"https://popapp.io/bienvenida/equipo.gif\" alt=\"img\" width=\"100%\" border=\"0\"\n" +
      "                                 style=\"display: block; width: 100%;\" />\n" +
      "                               </a>" +
      "                              <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                              <font face=\"'Source Sans Pro', sans-serif\" color=\"#3f51b5\"\n" +
      "                                 style=\"font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">\n" +
      "                                 <span\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #eb135a; font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">Popapp</span>\n" +
      "                              </font>\n" +
      "                              <div style=\"height: 12px; line-height: 12px; font-size: 10px;\">&nbsp;</div>\n" +
      "                              <font face=\"'Source Sans Pro', sans-serif\" color=\"#101010\"\n" +
      "                                 style=\"font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                 <span\n" +
      "                                    style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #101010; font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">Popapp\n" +
      "                                    es un software de gestión para locales gastronómicos enfocado en aumentar tus\n" +
      "                                    ventas.</span>\n" +
      "                              </font>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                        style=\"width: 100% !important; min-width: 100%; max-width: 100%;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"center\" valign=\"top\">\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           <table border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n" +
      "                           <tr><td align=\"center\" valign=\"top\" width=\"325\"><![endif]-->\n" +
      "                              <div class=\"mob_btn\" style=\"display: inline-block; vertical-align: top; width: 325px;\">\n" +
      "                                 <table class=\"mob_card\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"295\"\n" +
      "                                    style=\"width: 295px !important; min-width: 295px; max-width: 295px;\">\n" +
      "                                    <tr>\n" +
      "                                       <td align=\"left\" valign=\"top\">\n" +
      "                                          <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                          <a href=\"https://www.youtube.com/watch?v=ZNO0ha_Wx4Y\" target=\"_blank\" style=\"display: block; max-width: 100%;\">\n" +
      "                                             <img src=\"https://popapp.io/bienvenida/pic_1.jpg\" alt=\"img\" width=\"100%\"\n" +
      "                                                border=\"0\" style=\"display: block; width: 100%;\" />\n" +
      "</a>" +
      "                                          <div style=\"height: 22px; line-height: 22px; font-size: 20px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#3f51b5\"\n" +
      "                                             style=\"font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #3f51b5; font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">Instalación</span>\n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 12px; line-height: 12px; font-size: 10px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#101010\"\n" +
      "                                             style=\"font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #101010; font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">Instala\n" +
      "                                                Popapp! Mira este video para hacerlo, es muy sencillo.</span>\n" +
      "                                                <a href=\"https://popapp.io/descargas/setupPopApp.exe\" target=\"_blank\">Descarga</a> \n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 8px; line-height: 8px; font-size: 6px;\">&nbsp;</div>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]></td><td align=\"center\" valign=\"top\" width=\"325\"><![endif]-->\n" +
      "                              <div class=\"mob_btn\" style=\"display: inline-block; vertical-align: top; width: 325px;\">\n" +
      "                                 <table class=\"mob_card\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"295\"\n" +
      "                                    style=\"width: 295px !important; min-width: 295px; max-width: 295px;\">\n" +
      "                                    <tr>\n" +
      "                                       <td align=\"left\" valign=\"top\">\n" +
      "                                          <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                          <a target=\"_blank\" style=\"display: block; max-width: 100%;\">\n" +
      "                                             <img src=\"https://popapp.io/bienvenida/pic_2.jpg\" alt=\"img\" width=\"100%\"\n" +
      "                                                border=\"0\" style=\"display: block; width: 100%;\" />\n" +
      "                                          </a>\n" +
      "                                          <div style=\"height: 22px; line-height: 22px; font-size: 20px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#3f51b5\"\n" +
      "                                             style=\"font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #3f51b5; font-size: 16px; line-height: 22px; font-weight: 700; text-transform: uppercase;\">Contacto</span>\n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 12px; line-height: 12px; font-size: 10px;\">&nbsp;</div>\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#101010\"\n" +
      "                                             style=\"font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #101010; font-size: 26px; line-height: 33px; font-weight: 300; letter-spacing: -1px;\">Espera\n" +
      "                                                mi llamado! Dentro de las próximas 24 horas estaré hablando contigo. No\n" +
      "                                                puedo esperar!</span>\n" +
      "                                          </font>\n" +
      "                                          <div style=\"height: 8px; line-height: 8px; font-size: 6px;\">&nbsp;</div>\n" +
      "                                       </td>\n" +
      "                                    </tr>\n" +
      "                                 </table>\n" +
      "                              </div>\n" +
      "                              <!--[if (gte mso 9)|(IE)]>\n" +
      "                           </td></tr>\n" +
      "                           </table><![endif]-->\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                        style=\"width: 88% !important; min-width: 88%; max-width: 88%; border-width: 1px; border-style: solid; border-color: #e8e8e8; border-top: none; border-left: none; border-right: none;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"center\" valign=\"top\">\n" +
      "                              <div style=\"height: 40px; line-height: 40px; font-size: 38px;\">&nbsp;</div>\n" +
      "                              <table class=\"mob_btn\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"\n" +
      "                                 style=\"background: #eb135a; border-radius: 4px;\">\n" +
      "                                 <tr>\n" +
      "                                    <td align=\"center\" valign=\"top\">\n" +
      "                                       <a href=\"https://popapp.io/foodtech/\" target=\"_blank\"\n" +
      "                                          style=\"display: block; border: none; border-radius: 4px; padding: 19px 26px; font-family: 'Source Sans Pro', Arial, Verdana, Tahoma, Geneva, sans-serif; color: #ffffff; font-size: 26px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;\">\n" +
      "                                          <font face=\"'Source Sans Pro', sans-serif\" color=\"#ffffff\"\n" +
      "                                             style=\"font-size: 26px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;\">\n" +
      "                                             <span\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Verdana, Tahoma, Geneva, sans-serif; color: #ffffff; font-size: 26px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;\">Descubre\n" +
      "                                                cómo potenciar tu negocio con Popapp!</span>\n" +
      "                                          </font>\n" +
      "                                       </a>\n" +
      "                                    </td>\n" +
      "                                 </tr>\n" +
      "                              </table>\n" +
      "                              <div style=\"height: 50px; line-height: 50px; font-size: 48px;\">&nbsp;</div>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "\n" +
      "\n" +
      "                     <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"100%\"\n" +
      "                        style=\"width: 100% !important; min-width: 100%; max-width: 100%; background: #f5f8fa;\">\n" +
      "                        <tr>\n" +
      "                           <td align=\"center\" valign=\"top\">\n" +
      "                              <div style=\"height: 34px; line-height: 34px; font-size: 32px;\">&nbsp;</div>\n" +
      "                              <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"88%\"\n" +
      "                                 style=\"width: 88% !important; min-width: 88%; max-width: 88%;\">\n" +
      "                                 <tr>\n" +
      "                                    <td align=\"center\" valign=\"top\">\n" +
      "                                       <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" width=\"78%\"\n" +
      "                                          style=\"min-width: 300px;\">\n" +
      "                                          <tr>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"23%\">\n" +
      "                                                <a href=\"https://tallypar.freshdesk.com/support/home\" target=\"_blank\"\n" +
      "                                                   style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                   <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                      style=\"font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                      <span\n" +
      "                                                         style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">CENTRO&nbsp;DE&nbsp;AYUDA</span>\n" +
      "                                                   </font>\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"10%\">\n" +
      "                                                <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                   style=\"font-size: 17px; line-height: 17px; font-weight: bold;\">\n" +
      "                                                   <span\n" +
      "                                                      style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 17px; font-weight: bold;\">&bull;</span>\n" +
      "                                                </font>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"23%\">\n" +
      "                                                <a href=\"https://wa.me/542645122091\" target=\"_blank\"\n" +
      "                                                   style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                   <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                      style=\"font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                      <span\n" +
      "                                                         style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">SOPORTE&nbsp;24/7</span>\n" +
      "                                                   </font>\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"10%\">\n" +
      "                                                <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                   style=\"font-size: 17px; line-height: 17px; font-weight: bold;\">\n" +
      "                                                   <span\n" +
      "                                                      style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 17px; font-weight: bold;\">&bull;</span>\n" +
      "                                                </font>\n" +
      "                                             </td>\n" +
      "                                             <td align=\"center\" valign=\"top\" width=\"23%\">\n" +
      "                                                <a href=\"https://popapp.io/mypopapp/micuenta\" target=\"_blank\"\n" +
      "                                                   style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                   <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                                      style=\"font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">\n" +
      "                                                      <span\n" +
      "                                                         style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 14px; line-height: 20px; text-decoration: none; white-space: nowrap; font-weight: bold;\">CUENTA</span>\n" +
      "                                                   </font>\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                          </tr>\n" +
      "                                       </table>\n" +
      "                                       <div style=\"height: 34px; line-height: 34px; font-size: 32px;\">&nbsp;</div>\n" +
      "                                       <font face=\"'Source Sans Pro', sans-serif\" color=\"#868686\"\n" +
      "                                          style=\"font-size: 17px; line-height: 20px;\">\n" +
      "                                          <span\n" +
      "                                             style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #868686; font-size: 17px; line-height: 20px;\">Copyright\n" +
      "                                             &copy; 2019 Popapp. Todos los derechos reservados.</span>\n" +
      "                                       </font>\n" +
      "                                       <div style=\"height: 3px; line-height: 3px; font-size: 1px;\">&nbsp;</div>\n" +
      "                                       <font face=\"'Source Sans Pro', sans-serif\" color=\"#1a1a1a\"\n" +
      "                                          style=\"font-size: 17px; line-height: 20px;\">\n" +
      "                                          <span\n" +
      "                                             style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 20px;\"><a\n" +
      "                                                href=\"mailto:hola@popapp.io?Subject=Consulta%20desde%20email%20de%20bienvenida\"\n" +
      "                                                target=\"_blank\"\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 20px; text-decoration: none;\">hola@popapp.io</a>\n" +
      "                                             &nbsp;&nbsp;|&nbsp;&nbsp; <a href=\"https://wa.me/542645487131\"\n" +
      "                                                target=\"_blank\"\n" +
      "                                                style=\"font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #1a1a1a; font-size: 17px; line-height: 20px; text-decoration: none;\">+54\n" +
      "                                                264 548 7131</a> </span>\n" +
      "                                       </font>\n" +
      "                                       <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                       <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\">\n" +
      "                                          <tr>\n" +
      "                                             <td align=\"center\" valign=\"top\">\n" +
      "                                                <a href=\"https://www.instagram.com/popapp.io/\" target=\"_blank\"\n" +
      "                                                   style=\"display: block; max-width: 19px;\">\n" +
      "                                                   <img src=\"https://popapp.io/bienvenida/soc_1.png\" alt=\"img\"\n" +
      "                                                      width=\"19\" border=\"0\" style=\"display: block; width: 19px;\" />\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td width=\"45\" style=\"width: 45px; max-width: 45px; min-width: 45px;\">\n" +
      "                                                &nbsp;</td>\n" +
      "                                             <td align=\"center\" valign=\"top\">\n" +
      "                                                <a href=\"https://www.facebook.com/popapp.io/\" target=\"_blank\"\n" +
      "                                                   style=\"display: block; max-width: 18px;\">\n" +
      "                                                   <img src=\"https://popapp.io/bienvenida/soc_2.png\" alt=\"img\"\n" +
      "                                                      width=\"18\" border=\"0\" style=\"display: block; width: 18px;\" />\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td width=\"45\" style=\"width: 45px; max-width: 45px; min-width: 45px;\">\n" +
      "                                                &nbsp;</td>\n" +
      "                                             <td align=\"center\" valign=\"top\">\n" +
      "                                                <a href=\"https://www.youtube.com/channel/UCpTpqBQKt9kptPoswA1sSCw\"\n" +
      "                                                   target=\"_blank\" style=\"display: block; max-width: 21px;\">\n" +
      "                                                   <img src=\"https://popapp.io/bienvenida/yt.png\" alt=\"img\" width=\"21\"\n" +
      "                                                      border=\"0\" style=\"display: block; width: 21px;\" />\n" +
      "                                                </a>\n" +
      "                                             </td>\n" +
      "                                             <td width=\"45\" style=\"width: 45px; max-width: 45px; min-width: 45px;\">\n" +
      "                                                &nbsp;</td>\n" +
      "\n" +
      "                                          </tr>\n" +
      "                                       </table>\n" +
      "                                       <div style=\"height: 35px; line-height: 35px; font-size: 33px;\">&nbsp;</div>\n" +
      "                                    </td>\n" +
      "                                 </tr>\n" +
      "                              </table>\n" +
      "                           </td>\n" +
      "                        </tr>\n" +
      "                     </table>\n" +
      "\n" +
      "                  </td>\n" +
      "                  <td class=\"mob_pad\" width=\"25\" style=\"width: 25px; max-width: 25px; min-width: 25px;\">&nbsp;</td>\n" +
      "               </tr>\n" +
      "            </table>\n" +
      "            <!--[if (gte mso 9)|(IE)]>\n" +
      "         </td></tr>\n" +
      "         </table><![endif]-->\n" +
      "         </td>\n" +
      "      </tr>\n" +
      "   </table>\n" +
      "</body>\n" +
      "\n" +
      "</html>"
  }


}
