export enum NetworkMode {
    NoData = 'no data',
    Firebase = 'firebase',
    Firestore = 'firestore',
    V2 = '2.0',
}


/**
 * Una cuenta tiene 1 o mas locales, y a la vez 1 local tiene 1 o más servidores o servicios
 */
export class ServidorAdmin {
    /**
     * la ciudad a la que pertenece el servidor
     */
    ciudad: string;

    /**
     * no es una variable en firebase,
     *  se usa para mostrar en la tabla de servidores que contacto tiene esa cuenta
     */
    contacto: string;

    cuitORut: string;

    /**
     * -1 nada
     * 0 fue alguna vez un pago pero ya se canceló, o sea que no hay que mostrarlo en los importes
     * si es > 0, entonces se muestra el importe en el prox pago, de lo contrario el importe no se muestra. por ej 1 se puede utilizar para cobrar instalación.
     * Cada vez que se realice un pago entonces se debe restar un 1 en este numero, a menos que ya este en 0.
     * 
     * si tiene esto, no es un servidor, sino un servicio
     */
    cuotasPendientes: number;

    /**
     * para saber cuantas cuotas historicas se pagaron por ej 1/2 o 1/3
     */
    cuotasTotales: number;

    /**
     * esta es una observación por si se quiere agregar algo aparte de descripción u observación, actualmente no se usa y no se muestra
     */
    detalleImporte: string;

    /**
     * no es una variable en firebase,
     *  se usa para mostrar en la tabla de servidores, los dias restantes para el vencimiento de la máquina
     */
    dias: number;

    /**
     * no es una variable en firebase,
     *  se usa para mostrar en la tabla de servidores, los dias restantes para el vencimiento real del pagoHasta 
     */
    diasReal: number;

    /**
     * la direccion y ciudad del local en la SQL
     */
    direccionyciudad?: { direccion: string, ciudad: string };

    /**
     * -1 o null nada,
     * 0 si baja al plan gratis en el proximo mes
     * 1 si baja al plan básico en el próximo mes
     * 2 si baja al plan en crecimiento en el próximo mes
     */
    downGradeTo: number;

    emailpago: string;

    /* Indica si la mac está en uso */
    enUso?: boolean;

    /**
     * Estados de funnel de ventas, actualmente no se usa.
     */
    estado: string;

    factElect: {
        activado: boolean;
        /** ingefactura */
        proveedor: string;

        Emisor?: any;
        facturaLastId?: number;
        token?: string;
        url?: string;

    };
    /**
     * sirve para activar el modulo Factura Electronica Argentina, donde todos los datos son los que lee Manager para generar las FE
     */
    fearg: { activado: boolean, cuit: string, email: string, password: string, razonSocial: string, domicilio: string, responsabilidad: string, idPais: number, nroLocalFea: number, idProvincia: number, idPtoVta: number, ptoVta: number, ingresosBrutos: string, fechaInicioActividades: string };

    /**
     * fecha ISO de cuando el manager paso a numero de local valido o lo asignaron manualmente
     */
    fechaAlta: string;

    /**
     * Fecha actualizado
     */
    fechaActualizado;

    fechaBaja: string;

    fechaUltimaFacturacion;

    /**
     * 
     */
    fechaUltimoCheck;

    /**
     * la fecha en la que cambió de plan es la key en milisegundos de la compu local
     * el nombre del plan es el valor
     * fbTime es el timestamp en Firebase cuando se produce el cambio
     * from es el plan que estaba antes
     * se actualiza cada vez que se cambia de plan
     */
    fechaCambioPlanMap: Map<number, { fbTime: string, plan: string, from: string }>;

    /**
     * la fecha con la que empiezan las pruebas los servidores instalados, si queremos extender la prueba de un servidor podemos aumentar esta fecha
     */
    fechaFinPruebaFull: string;

    /**
     * la fecha en la que se instaló el servidor, se debería actualizar cuando se instala una nueva mac, sino no
     */
    fechaInstalacion: string;

    fechaInstalacionyyyyMMddd: string;

    fechaConversion: string;

    fechaEstadisticasWeb: string;

    /**
     * la ultima fecha de pago, se actualiza sola cuando se paga desde mercado pago
     */
    fechaUltimoPago: string;

    /**
     * si tiene el mod fiscal activado, desde aca se puede activar o desactivar para los clientes
     */
    fiscal: boolean;

    /**
     * se usa para hacer arreglos en la Base de datos. Generalmente se ponen palabras claves o sentencias SQL para arreglar algo.
     */
    fix: string;

    idClienteContabilium?: string;

    /**
     * no es una variable en firebase,
     *  la key de firebase de la cuenta a la cual pertenece el servidor
     */
    idCuenta: string;

    /**
     * no es una variable en firebase,
     *  la key de firebase del local al cual pertenece el servidor
     */
    idLocal: string;

    idLocalPya: number;
    /**
     * no es una variable en firebase,
     *  es la mac de firebase del cual cuelga el servidor "cuentas/:idCuenta/locales/:idLocal/servidores/:idMac"
     * 
     * este valor se usa para agregar importes a un local, ejemplos de idMac a usar para no solapar son:
     * instalacion
     * upgrade0a1 prorrateo de cobrar por los dias que faltan hasta el pagoHasta 0 es gratis, 1 basico, 2 encrecimiento, 3 empresa
     * upgrade0a2...
     * upgrade0a3...
     * upgrade1a2...
     * upgrade1a3...
     * upgrade2a3...
     * capacitacion
     * router
     * instadelivery
     * 
     * en estos casos es necesario usar cuotasPendientes para que aparezca el importe correspondiente
     */
    idMac: string;

    /**
     * id de suscripcion de mercado pago
     */
    idSuscription?: string;

    /**
     * importe que paga por el servidor sin incluir Iva, se corresponde con la moneda. por ej 40 pueden ser USD o ARS o CLP dependiendo de la moneda.
     * Se debería actualizar cada vez que se elija un nuevo plan
     */
    importe: number;

    /**
     * no es una variable en firebase,
     *  se usa para saber en la tabla de PA Admin si es 1/2 o 2/2 etc.. de los locales 
     */
    indLocal: number;

    /**
     * no es una variable en firebase,
     *  se usa para saber en la tabla de PA Admin si es 1/2 o 2/2 etc.. de los servidores 
     */
    indServidor: number;

    /**
     * es el importe que deberia pagar por ese servidor, en el caso de que sea mayor que importe, entonces se deberia mostrar este importe tachado como un descuento.
     * Deberia ir el importe correspondiente a al valor del plan, y se debería actualizar cada vez que se elija un nuevo plan
     */
    importeQDeberia: number;

    keyPedidosOnline: { key: string, locales?: Map<string, { activado: boolean, nombre: string, autoacept: boolean, fechaAlta: string, marca: string, tag: string, descuentopya: string, descrappi: string }>, users?: Map<string, { email: string, uid: string }> }

    lat: number;

    /**
     * link donde queda el qr de la integracion
     */
    linkqrmp?: string;

    /**
     * link de la suscripcion de mercado pago
     */
    linkSuscription?: string;

    lng: number;

    /**
     * de acuerdo a esta marca podemos configurar la marca blanca en caso de revender popapp
     */
    marca: string;

    marcaFotoURL: string;

    marcaFotoChicaURL: string;

    mercadopago: string;

    /**
     * la moneda a pagar puede ser ARS, USD, CLP, todos los valores que esten en los servidores deberian tener la misma moneda.
     */
    moneda: string;

    network_mode: NetworkMode;

    /**
     * no es una variable en firebase,
     *  se usa para saber en la tabla de PA Admin el nombre de la cuenta a la que pertenece este servidor
     */
    nombreCuenta: string;

    /**
     * no es una variable en firebase,
     *  se usa para saber en la tabla de PA Admin el nombre del local a la que pertenece este servidor
     */
    nombreLocal: string;

    /**
     * el nombre del servidor que ve el cliente a la hora de pagar y que identifica a ese servidor en PA Admin
     */
    nombreServidor: string;

    /**
     *  -1 si fue cliente,
     *  0 si no es ni fué,
     *  >0 si es cliente actual,
     *  -2 reseller,
     * -3 developers,
     *  -4 cambio de PC y desinstalo ese servidor,
     *  1500 si es un local que todavía no es asignado por atencion al cliente.
     * 
     *  Una vez convertido a cliente, este numero lo asigna atencion al cliente en una especie de id autoincremental
     */
    numeroLocal: string;

    /**
     * porcentaje de iva que paga gralmente 21 o 19 si es en chile o 0 si no paga iva. 
     * A la hora de pagar se busca el Maximo pagaIva entre los servidores y se multimplica por el importe total de la cuenta para pagarlo
     */
    pagaIva: number;

    /**
     * indica si pago el costo de alta, si es false para todos los servidores de la cuenta deberia generarse un costo de alta a esa cuenta
     */
    pagoAlta: boolean;

    /**
     * es la fecha en dd//MM/yyyy hasta la que realmente tiene pagado el servidor. 
     * En el caso ideal esta fecha debería ser igual a vencimiento, pero puede que en algunos casos se den más días actualizando solo vencimiento. 
     * A la hora de mostrar el prox. vencimiento hay que tener en cuenta esta fecha y no vencimiento
     */
    pagoHasta: string;

    /**
     * ARG Argentina, CHI chile...
     */
    pais: string;

    /**
     * el nombre del plan al que esta suscripto:
     *  0 Gratis,
     *  0 Básico,
     *  0 En crecimiento,
     *  0 Empresa
     */
    //TODO: 1 para el plan tradicional lite, 3 para el full
    //TODO: 1 para Popapp PO y 3 para PopappPO con dispositivos extra activado
    planN: number;

    /**
     * TODO: [PROD-287] Nuevo pricing
     * Formulario en admin para configurar todas las variables (Poder agregar monto final) en el caso de USD no va IVA 
     *      El acceso a mypopapp debe estar cripleado para configurar los mails
     * Poder agregar conceptos
     * Pantalla en mypopapp con 2 o 3 planes? Pregunar en la sync
     * Pantalla del usuario de la cuenta que se vean los conceptos aparte de los conceptos de los servidores
     * Mandar email de cambio de plan
     */

    conceptos: Map<string, {
        concepto: string;
        importe: number;
        iva: number;
        total: number;
        id: string;
        cuotasPendientes?: number;
    }>;

    emails: Map<string, {
        email: string;
        activado: boolean;
    }>;
    //TODO: LECTURA
    //1
    //usuarios 2
    //emails 3 si estan habilitados o no

    //TODO: para escribir 
    //escribir en 1 y en 3
    //si se activa escribirlo en 1 y en 3
    //si se desactiva borrarlo de 3 y escribirlo en 1 como desactivado

    /**
     * PLAN TRADICIONAL LITE aca planN no puede estar en 3 
     * PLAN TRADICIONAL FULL planMypopapp true
     * PLAN PEDIDOS ONLINE 
     */
    /*planNombre: string;*/

    planCobro: any;
    /*plan["dispositivos"] = {
        cantidad: 1,
        concepto: "5 Marcas",
        monto: 1000,
        activo: false
    };
    plan["mypopapp"] = {
        cantidad: 1,
        concepto: "5 Marcas",
        monto: 1000;
        activo: false
    };
    plan["stock"] = true*/



    /**
     * si ya realizo el primer pago esta en true, si esta en false se trata de un lead o de un cliente viejo q estuvo dado de baja
     */
    primerPago: boolean;

    pyaInicio: any;

    responsabilidad?: string;

    /**
     * Indica si no es null o no es "", entonces tiene habilitada la integracion con vessi, y es el rut q se debe usar para leer la info del emisor de los DTE
     */
    rut: string;

    /**
     * timestamp en milisegundos que indica la ultima modificacion de un atributo de configuracion, tales como:
     * fix
     * network_mode
     * pagoHasta
     * 
     * ... los demás atributos se pueden encontrar en
     * atributosModificablesServidorOnboarding en adminCUentasService
     */
    settings_last_updated: number;

    /**
     * true, si está en condiciones para que se le muestre la pantalla de pagar en mi cuenta, false en caso contrario, por ej, en santa calma esta en false porque nos paga el pablo y no los dueños
     */
    showPagar: boolean;

    /**tiene activada la sincronizacion con manager */
    syncManager?: boolean;

    /**
     * Campo para filtrar en el admin de manera facil por varios servidores
     */
    tag;

    /**
     * 0 es la rama principal, de lo contrario es una de las ramas que se usan en C# 
     */
    tester: number;

    /**
     * En el caso de "Comercial": tiene acceso a testing de Pedidos Online simulando los pedidos
     */
    tipoUsuario: string;

    /**
     * no es una variable en firebase,
     * se usa para saber el total de locales a los que pertenece este servidor para mostrar 1/1 , 1/2 , etc... en el local
     */
    totLocales: number;

    /**
     * no es una variable en firebase,
     * se usa para saber el total de servidores a los que pertenece este servidor para mostrar 1/1 , 1/2 , etc... en el servidor
     */
    totServidoresOSvs: number;

    txMypopapp: number;
    /**
     * @deprecated
     * se usaba para ver cuanto tenia que pagar en total en pesos args
     */
    valorArg: number;

    /**
     * @deprecated
     * se usaba para ver cuanto tenia que pagar en total en dolares
     */
    valorDolar: number;

    /**
     * es la fecha en dd/MM/yyyy o d/M/yyyy en la cual se indica cuando el servidor esta vencido realmente, que puede ser distinto a lo ideal como se menciono en pagoHasta.
     * en general en el servidor hay 14 dias de gracia a partir de esta fecha en la cual el servidor deja de funcionar
     */
    vencimiento: string;

    /**
     * La version que reporto el servidor al actualizarse, este campo junto con fechaUltimoCheck sirven para saber la ultima fecha conocida en la que tenemos ese servidor en esa version
     */
    versionActual: string;

    /**
     * Esta es la version que se asigna desde admin para que el servidor al iniciar busque y actualice, tanto para rollback como para actualizar
     */
    versionDisponible: string;

    /**
     * no es una variable en firebase,
     * se usa para mostrar en PA Admin el whatsapp de la cuenta
     */
    whatsapp: string;

    uCuenta: string;
    lastCuenta: string;

    hub?: string;
    fullweb?: string;
    fechaingreso?: string;
    fechaclientelisto?: string;
    duenix?: string;
    tag2?: string;

    constructor(s: ServidorAdmin) {
        for (let key in s) {
            this[key] = s[key];
        }
    }

    tieneIvaConfiguradoChile() {
        let iva = this.pagaIva;
        if (this.conceptos) {
            for (let kc in this.conceptos) {
                const concepto = this.conceptos[kc];
                if (concepto.importe && concepto.importe > 0.02 && (!concepto.cuotasPendientes || concepto.cuotasPendientes > 0)) {
                    // es un concepto activo
                    let ivaPorcentaje = 100 * concepto.iva / concepto.importe;
                    ivaPorcentaje = +ivaPorcentaje.toFixed(0);
                    if (iva === ivaPorcentaje) {
                    } else {
                        console.log('iva no es el mismo entre todos los conceptos', this);
                        return false;
                    }
                }
            }
        }
        if (iva === 19) {
            return true;
        } else {
            return false;
        }
    }
}