import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastsService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  show(texto: string, accion?: string, duracion?: number) {
    this._snackBar.open(texto,accion, {duration: duracion || 2000});
  }
}
