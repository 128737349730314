import { Component, Input, OnInit } from '@angular/core';
import { PieChartConfig } from '../pieconfig';
import { GooglepiechartService } from '../googlepiechart.service';

declare var google: any;

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PiechartComponent implements OnInit {

  @Input() _data: any[];
  @Input() config: PieChartConfig;
  @Input() elementId: string;
  @Input() pieTrueBarFalse: boolean;
  @Input() _mobileQueryMatches: boolean;


  @Input() set data(value: any[]) {
    if (value) {
      this._data = value;
      this._pieChartService.BuildPieChart(this.elementId, this.data, this.config, this.pieTrueBarFalse);
    }
  }

  get data(): any[] {
    return this._data;
  }

  @Input() set mobileQueryMatches(value: boolean) {
    this._mobileQueryMatches = value;
    this._pieChartService.BuildPieChart(this.elementId, this.data, this.config, this.pieTrueBarFalse);
  }

  get mobileQueryMatches(): boolean {
    return this._mobileQueryMatches;
  }


  constructor(private _pieChartService: GooglepiechartService) { }

  ngOnInit() {
    //this._pieChartService.BuildPieChart(this.elementId, this.data, this.config, this.pieTrueBarFalse);
  }

}

