import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

export interface ConfFacturapi {
  activado: boolean;
  /** facturapi */
  proveedor: string;
  idRestaurant: string;
  /**
   * random
   */
  Emisor: string;
  token: string;
  url: string;
}


@Component({
  selector: 'app-modal-conf-facturapi',
  templateUrl: './modal-conf-facturapi.component.html',
  styleUrls: ['./modal-conf-facturapi.component.scss']
})

export class ModalConfFacturapiComponent implements OnInit {

  conf: ConfFacturapi;
  s: ServidorAdmin;
  loading: boolean;

  nombre: string;

  constructor(
    public dialogRef: MatDialogRef<ModalConfFacturapiComponent>,
    public auth: AuthService,
    private cuentaService: CuentaService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin },
    private db: AngularFireDatabase,
    private dbFs: AngularFirestore,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder) {
    this.s = this.data.s;
  }


  async ngOnInit() {
    this.loading = true;
    let v = await this.leerConfFact(this.s);

    this.conf = v;
    this.initForm(v);

    console.log(this.conf);
    this.loading = false;
  }

  async leerConfFact(s: ServidorAdmin) {

    let confFact: ConfFacturapi = {
      activado: false,
      proveedor: "facturapi",
      idRestaurant: "",
      Emisor: "random",
      token: "",
      url: ""
    };

    if (s.keyPedidosOnline && s.keyPedidosOnline.key) {
      let doc = await this.dbFs.doc("confLocalPO/" + s.keyPedidosOnline.key).get().toPromise();
      let factElect = <ConfFacturapi>(doc.data() ? doc.data()["factElect"] : null);
      if (factElect) {
        confFact = factElect;
      }
    } else {
      this.snackbar.open("No hay keyLocalPOnline, configurar una key en POnline", "")._dismissAfter(3000);
    }

    return confFact;
  }

  initForm(v: ConfFacturapi) {
    // this.form = this.formBuilder.group({
    //   activado: [this.conf.activado, []],
    //   proveedor: [this.conf.proveedor, [Validators.required]],
    //   token: [this.conf.token, [Validators.required, Validators.minLength(10)]],
    //   url: [this.conf.url, [Validators.required, Validators.minLength(10)]],
    //   RUTEmisor: [this.conf.Emisor.RUTEmisor, [Validators.required, Validators.minLength(4)]],
    //   RznSoc: [this.conf.Emisor.RznSoc, [Validators.required]],
    //   Acteco: [this.conf.Emisor.Acteco, [Validators.required]],
    //   CiudadOrigen: [this.conf.Emisor.CiudadOrigen, [Validators.required]],
    //   CmnaOrigen: [this.conf.Emisor.CmnaOrigen, [Validators.required]],
    //   DirOrigen: [this.conf.Emisor.DirOrigen, [Validators.required]],
    //   GiroEmis: [this.conf.Emisor.GiroEmis, [Validators.required]],
    //   numLocal: [this.conf.numLocal ? this.conf.numLocal : 1, [Validators.required]],
    //   printPLATAFORMAeID: [this.conf.printPLATAFORMAeID ? this.conf.printPLATAFORMAeID : true, []],
    //   factCostoEnvio: [this.conf.factCostoEnvio ? this.conf.factCostoEnvio : false, []],
    //   TipoImpresion: [this.conf.TipoImpresion ? this.conf.TipoImpresion : 2, [Validators.required]],
    //   CdgSIISucur: [this.conf.Emisor.CdgSIISucur ? this.conf.Emisor.CdgSIISucur : "", []],
    // });
  }

  desactivar() {
    this.dbFs.doc("confLocalPO/" + this.s.keyPedidosOnline.key).set({ factElect: { activado: false } }, { merge: true }).then(v => {
      this.snackbar.open("Desactivado")._dismissAfter(2000);
    }).catch(e => {
      console.error("No se pudo desactivar", e);
      this.snackbar.open("No se pudo desactivar")._dismissAfter(2000);
    });
  }

  async activar() {

    let urlAdmin = "cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/factElect/";
    let urlConfLocalTienda = "confLocalPO/" + this.s.keyPedidosOnline.key;
    let mapUpdates = {};
    let factElectFS = {};

    let n = this.nombre ? this.nombre.trim() : undefined;
    if (n && n.length <= 3) {
      this.snackbar.open("Nombre muy corto")._dismissAfter(2000);
      return;
    }

    const activado = true;
    const proveedor = "facturapi";


    mapUpdates[urlAdmin + "activado"] = activado;
    mapUpdates[urlAdmin + "proveedor"] = proveedor;

    // let urlRut = "cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/rut/";
    // if (activado) {
    //   mapUpdates[urlRut] = RUTEmisor;
    // } //else {
    // mapUpdates[urlRut] = "0";
    //}

    // subimos el formulario 
    // try {
    //   let refTienda = this.dbFs.doc(urlConfLocalTienda).ref;
    //   await refTienda.set(objFS, { merge: true });

    //   this.db.object("/").update(mapUpdates).then(ok => {
    //     this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
    //     this.dialogRef.close();
    //   }).catch(e => {
    //     console.error("No se pudo grabar la tienda en admin", e);
    //     this.snackbar.open("Error, no se pudo grabar en admin")._dismissAfter(2000);
    //   });
    // } catch (e) {
    //   console.error("Error al grabar en FS", e);
    // }


    let urlProd = environment.urls.escuchador + "/";
    //let urlProd = "http://localhost:3000/";


    const body = JSON.stringify({
      razonSocial: this.nombre,
    });
    let datos = await this.cuentaService.postUrlProm(urlProd + "factElect/crearOrganizacion/prod/" + this.s.keyPedidosOnline.key, body);
    console.log("Exito: ", datos.json());

    //TODO: guardar en q parte de adminCuentas?

  }

  onNoClick(): void {
    this.dialogRef.close();
  }




}