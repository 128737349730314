import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pedidos-reportados',
  templateUrl: './pedidos-reportados.component.html',
  styleUrls: ['./pedidos-reportados.component.scss']
})
export class PedidosReportadosComponent implements OnInit, OnDestroy {
  selectValue: string[];
  plataformaValues: string[];
  estadoValues: string[];
  reportes: { idReporte: string, descripcion: string, estado: string, fecha: Date, idOrden: string, idStore: string, plataforma: string }[] = [];
  subs: Subscription;
  reportesRef;

  constructor(private dbFs: AngularFirestore) { }

  ngOnDestroy() {
    if (this.subs) { try { this.subs.unsubscribe() } catch (error) { console.log(error) } }
  }

  ngOnInit() {
    //TODO: Cargar locales disponibles
    this.selectValue = ['Sacro', 'Food Fast', 'California', 'Nevada', 'Oregon', 'Washington', 'Arizona', 'Colorado', 'Idaho', 'Montana', 'Nebraska', 'New Mexico', 'North Dakota', 'Utah', 'Wyoming', 'Alabama', 'Arkansas', 'Illinois', 'Iowa'];
    this.plataformaValues = ['pya', 'rapar', 'rapcl', 'rappe', 'rapmx', 'glo', 'ube', 'mas', 'jus'];
    this.estadoValues = ["Abierto", "Cerrado"];

    this.reportesRef = this.dbFs.collection('reportes', ref => ref.orderBy('fecha', 'desc'));

    this.subs = this.reportesRef.valueChanges({ idField: 'idReporte' }).subscribe(value => {
      this.reportes = [];

      value.map((item: { idReporte: string, descripcion: string, estado: string, fecha: Date, idOrden: string, idStore: string, plataforma: string }) => {
        if (item.estado != "Eliminado") {
          this.reportes.push(item);
        }
      })
    });

  }

  onDeleteReport(idReporte: string) {
    Swal.fire({
      title: 'Estas seguro que quieres borrar el reporte?',
      text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrarlo!',
      cancelButtonText: 'No, me confundí...'
    }).then((result) => {
      if (result.value) {
        this.reportesRef.doc(idReporte).update({
          estado: "Eliminado"
        });
        Swal.fire('Borrado!', 'El reporte ha sido borrado.', 'success');
      }
    });
  }

  onCloseReport(idReporte: string) {
    Swal.fire({
      title: 'Estas seguro que quieres cerrar el reporte?',
      // text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cerrarlo!',
      cancelButtonText: 'No, me confundí...'
    }).then((result) => {
      if (result.value) {
        this.reportesRef.doc(idReporte).update({
          estado: "Cerrado"
        });
        Swal.fire('Listo!', 'El reporte ha sido cerrado.', 'success');
      }
    });
  }

  onReopenReport(idReporte: string) {
    Swal.fire({
      title: 'Estas seguro que quieres marcar el reporte como abierto?',
      // text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, porfavor',
      cancelButtonText: 'No, me confundí...'
    }).then((result) => {
      if (result.value) {
        this.reportesRef.doc(idReporte).update({
          estado: "Abierto"
        });
        Swal.fire('Listo!', 'El reporte ha sido reabierto.', 'success');
      }
    });
  }

  onFiltrarBusqueda(form: NgForm) {
    this.dbFs.collection('reportes', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      //Filtro estado
      if (form.controls.estado.value && form.controls.estado.value != "") { query = query.where('estado', '==', form.controls.estado.value) };
      //Filtro plataforma
      if (form.controls.plataforma.value && form.controls.plataforma.value.length > 0) { query = query.where('plataforma', 'in', form.controls.plataforma.value) };
      //Filtro fechaDesde
      if (form.controls.fechaDesde.value != "") {
        let dateStr = form.controls.fechaDesde.value.slice(0, 4) + '-' + form.controls.fechaDesde.value.slice(5, -3) + '-' + form.controls.fechaDesde.value.slice(-2);
        query = query.where('fecha', '>=', dateStr);
      }
      //Filtro fechaHasta
      if (form.controls.fechaHasta.value != "") {
        let dateStr = form.controls.fechaHasta.value.slice(0, 4) + '-' + form.controls.fechaHasta.value.slice(5, -3) + '-' + form.controls.fechaHasta.value.slice(-2);
        //se añade el tiempo para que ese día sea incluído
        dateStr += 'T23:59:59';
        query = query.where('fecha', '<=', dateStr);
      }
      query = query.orderBy('fecha', 'desc');
      
      return query;
    }).valueChanges({ idField: 'idReporte' }).subscribe(value => {
      this.reportes = [];

      value.map((item: { idReporte: string, descripcion: string, estado: string, fecha: Date, idOrden: string, idStore: string, plataforma: string }) => {
        if (item.estado != "Eliminado") {
          this.reportes.push(item);
        }
      })
    });



    // this.dbFs.collection('reportes', ref => ref.where('estado', '==', 'Abierto')).valueChanges({ idField: 'idReporte' }).subscribe(value => {
    //   this.reportes = [];

    //   value.map((item: { idReporte: string, descripcion: string, estado: string, fecha: Date, idOrden: string, idStore: string, plataforma: string }) => {
    //     if (item.estado != "Eliminado") {
    //       this.reportes.push(item);
    //     }
    //   })
    // });

    // this.dbFs.collection('reportes', ref => ref.where("capital", "==", true))


    //   .get()
    //   .then(function (querySnapshot) {
    //     querySnapshot.forEach(function (doc) {
    //       // doc.data() is never undefined for query doc snapshots
    //       console.log(doc.id, " => ", doc.data());
    //     });
    //   })
    //   .catch(function (error) {
    //     console.log("Error getting documents: ", error);
    //   });


  }

}
