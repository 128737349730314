import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-modal-vincular-centralizado',
  templateUrl: './modal-vincular-centralizado.component.html',
  styleUrls: ['./modal-vincular-centralizado.component.scss']
})
export class ModalVincularCentralizadoComponent implements OnInit {
  BARRA_ULTIMOS_MODIFICADOS_BARRA = "/ultimosModificados/";
  CARTAS_BARRA = "cartas/";

  s: ServidorAdmin;
  usuario: any;
  formCentralizado: FormGroup;
  submitted = false;
  error: string;

  constructor(public dialogRef: MatDialogRef<ModalVincularCentralizadoComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin, usuario?: any },
    private db: AngularFireDatabase,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.s = this.data.s;
    this.usuario = this.data.usuario;
    this.formCentralizado = this.formBuilder.group({
      keyCarta: ["", [Validators.required]],
      sucursal: ["", [Validators.required]],
      nombre: ["", [Validators.required]],
      emails: ["", [Validators.required]],
      activado: ["", []]
    });
    if (this.usuario) {
      if (this.usuario.nombre)
        this.formCentralizado.controls.nombre.setValue(this.usuario.nombre);
      if (this.usuario.carta)
        this.formCentralizado.controls.keyCarta.setValue(this.usuario.carta);
      if (this.usuario.numSucursal)
        this.formCentralizado.controls.sucursal.setValue(this.usuario.numSucursal);
      if (this.usuario.mails) {
        let stringMails: string = "";
        this.usuario.mails.forEach(mail => {
          stringMails += mail + ",";
        });
        stringMails = stringMails.slice(0, -1);
        this.formCentralizado.controls.emails.setValue(stringMails);
      }
      if (this.usuario.stockCentralizado)
        this.formCentralizado.controls.activado.setValue(this.usuario.stockCentralizado);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  actualizaUltimosModificados(mapUpdates, keyCarta: string, timestamp) {
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/categorias"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/comidas"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/fracciones"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/ingredientes"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/stocks"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/activos"] = timestamp;
  }
  async guardarDatos() {
    this.submitted = true;
    this.error = null;

    if (!confirm("Estás seguro que deseas vincular?")) {
      this.snackbar.open("No se confirmó, no se hizo nada")._dismissAfter(3000);
      return;
    } else {
      const keyCarta = this.formCentralizado.controls.keyCarta.value.trim();
      const nombreLocal = this.formCentralizado.controls.nombre.value.trim();
      const activado = this.formCentralizado.controls.activado.value;
      const emailsString: string = this.formCentralizado.controls.emails.value.trim();
      let emails: string[];
      emails = emailsString.split(",");
      const numSucursal = this.formCentralizado.controls.sucursal.value;
      const mac = this.s.idMac;
      let mapUpdates = {};
      //forzar la actualizacion de la carta
      this.actualizaUltimosModificados(mapUpdates, keyCarta, { ".sv": "timestamp" });

      mapUpdates["usuarios/" + mac + "/carta"] = keyCarta;
      mapUpdates["usuarios/" + mac + "/nombre"] = nombreLocal;
      mapUpdates["usuarios/" + mac + "/numSucursal"] = numSucursal;
      if (activado)
        mapUpdates["usuarios/" + mac + "/stockCentralizado"] = activado;
      else
        mapUpdates["usuarios/" + mac + "/stockCentralizado"] = false;
      mapUpdates["usuarios/" + mac + "/mails"] = emails;


      let macsAnteriores: string[] = []
      let flagIntentoCargarMacs = false

      let fbactivos = await this.db.object("cartas/" + keyCarta + "/activos").query.once("value").then(v => {
        return v.val();
      })
      if (!fbactivos) {
        this.snackbar.open("No hay activos")._dismissAfter(3000);
        return;
      }
      for (let idActivo in fbactivos) {
        if (!flagIntentoCargarMacs) {
          if (fbactivos[idActivo] && fbactivos[idActivo].activo) {
            for (let m in fbactivos[idActivo].activo) {
              macsAnteriores.push(m)
            }
          }
          flagIntentoCargarMacs = true
        }
        mapUpdates["cartas/" + keyCarta + "/activos/" + idActivo + "/activo/" + mac] = true;
      }


      let fbstocks = await this.db.object("cartas/" + keyCarta + "/stocks").query.once("value").then(v => {
        return v.val();
      })
      if (!fbstocks) this.snackbar.open("No hay fbstocks")._dismissAfter(3000);
      for (let idStock in fbstocks) {
        mapUpdates["cartas/" + keyCarta + "/stocks/" + idStock + "/stock/" + mac] = 0;
      }

      console.log(macsAnteriores)
      const USUARIOS_BARRA = "usuarios/";

      for (let locMac of macsAnteriores) {
        if (locMac !== mac) {
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/aceptado"] = true;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/mac"] = mac;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/nombre"] = nombreLocal;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/numSucursal"] = numSucursal;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/mails"] = emails;

          let uAux = await this.db.object("usuarios/" + locMac).query.once("value").then(v => {
            return v.val();
          })
          if (uAux) {
            console.log(uAux)
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/aceptado"] = true;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/mac"] = locMac;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/nombre"] = uAux.nombre;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/numSucursal"] = uAux.numSucursal;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/mails"] = emails;
          }
        }

      }

      this.db.list('/').update("/", mapUpdates).then(v => {
        this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
        this.dialogRef.close(true);
      });
    }
  }
  async desvincularStockCentralizado() {
    this.quitarVinculadoStock()
      .then(v => {
        this.snackbar.open(v)._dismissAfter(2000);
        this.dialogRef.close(true);
      }).catch(e => {
        this.snackbar.open("Hubo un problema", e)._dismissAfter(2000);
        this.dialogRef.close(false);
      });
  }
  async quitarVinculadoStock(): Promise<string> {
    if (!this.s.idMac) {
      throw "No tiene idMac"
    } else {
      let usuario = await this.db.object("usuarios/" + this.s.idMac).query.once("value").then(v => {
        return v.val();
      })

      if (!this.usuario) {
        throw "No existe usuario"
      }
      if (!this.usuario.stockCentralizado) {
        throw "No hay stockCentralizado"
      }
      if (!this.usuario.carta) {
        throw "No hay carta (keycarta)"
      }
      if (!+this.usuario.numSucursal) {
        throw "No hay numSucursal"
      }
      if (!this.usuario.nombre) {
        throw "No hay nombre"
      }
      if (!this.usuario.vinculadoCon) {
        throw "No hay vinculadoCon"
      }
      if (!confirm("Estás seguro que deseas desvincular?")) {
        return of("No se confirmó, no se hizo nada").toPromise();
      } else {
        const USUARIOS_BARRA = "usuarios/"
        const mac: string = this.s.idMac;
        const keyCarta: string = usuario.carta;
        const numSucursal: number = +usuario.numSucursal;
        const nombreLocal: string = usuario.nombre;
        let mapUpdates = {};
        //forzar la actualizacion de la carta
        this.actualizaUltimosModificados(mapUpdates, keyCarta, { ".sv": "timestamp" });

        mapUpdates[USUARIOS_BARRA + this.s.idMac + "/stockCentralizado"] = false;
        mapUpdates[USUARIOS_BARRA + this.s.idMac + "/carta"] = null;
        mapUpdates[USUARIOS_BARRA + this.s.idMac + "/numSucursal"] = null;
        mapUpdates[USUARIOS_BARRA + this.s.idMac + "/vinculadoCon"] = null;

        for (let locMac in usuario.vinculadoCon) {
          if (locMac !== mac) {
            mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac] = null;
          }
        }

        let fbactivos = await this.db.list("cartas/" + keyCarta + "/activos").query.once("value").then(v => {
          return v.val();
        })
        if (!fbactivos) throw "No hay activos"
        for (let idActivo in fbactivos) {
          mapUpdates["cartas/" + keyCarta + "/activos/" + idActivo + "/activo/" + mac] = null;
        }

        let fbstocks = await this.db.list("cartas/" + keyCarta + "/stocks").query.once("value").then(v => {
          return v.val();
        })
        if (!fbstocks) throw "No hay stocks"
        for (let idStock in fbstocks) {
          mapUpdates["cartas/" + keyCarta + "/stocks/" + idStock + "/stock/" + mac] = null;
        }

        return this.db.list('/').update("/", mapUpdates).then(v => {
          return "Exito"
        });
      }
    }
  }

}
