import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';

export interface DeliveryTime {
  descripcion: string;
  id: number;
  maxMinutes: number;
  minMinutes: number;
  name: string;
  order: number;
}

export interface RejectMessage {
  descriptionES: string;
  descriptionPT: string;
  forLogistics: boolean;
  forPickup: boolean;
  forRestaurant: boolean;
  id: number;
  name: string;
}

export interface Origen {
  abreviacion: string;
  ack: boolean;
  /**
   * integraciones como scon o bicci que todavia usan la v1 de la api por lo tanto necesitan escribir en tokensapi/:keyLocal
   */
  api1?: boolean;
  /**
   * integraciones que usan la v2 de la api por lo tanto necesitan escribir en tiendasDeAPI/:origen,:idStore
   */
  api2?: boolean;
  autoAccept: boolean;
  confirm: boolean;
  deliveryTimes: boolean;
  deliveryTimes2: DeliveryTime[];
  deny: boolean;
  /**
   * indica que no se deberian integrar nuevas tiendas con este origen o plataforma
   */
  deprecated: boolean;
  dispatch: boolean;
  id: number;
  logo16: string;
  nombre: string;
  /**
   * este campo deberia tener la url por defecto de la plataforma a la que hay que notificar cambios de estado,
   * o deberia tener algun texto si queremos que pida este campo a la hora de configurar una tienda
   */
  notificacionesUrl?: string;
  observacion?: string;
  /**
   * el valor que necesitan las llamadas del back en rappi por ejemplo ar, mx, etc
   */
  pais?: string;
  plataforma: string;
  reception: boolean;
  reject: boolean;
  rejectMessages: boolean;
  rejectMessages2: RejectMessage[];
  tipoCobro: string;
  /**
   * si el token esta se pone por defecto para las plataformas seleccionadas
   */
  token?: string;
  tipoCobroCupon: string;
  tipoCobroDescuento: string;
}

@Component({
  selector: 'app-admin-origenes',
  templateUrl: './admin-origenes.component.html',
  styleUrls: ['./admin-origenes.component.scss']
})
export class AdminOrigenesComponent implements OnInit, OnDestroy {

  constructor(
    private db: AngularFireDatabase,
  ) { }


  origenes: Origen[];
  max: number = 0;
  subs: Subscription[] = [];

  ngOnDestroy(): void {
    for (const s of this.subs) {
      try { s.unsubscribe(); } catch (e) { }
    }
  }

  ngOnInit(): void {
    this.subs.push(this.db.object('origenesPedidosOnline').valueChanges().subscribe(snap => {
      this.origenes = <Origen[]>snap;
      for (let o of this.origenes) {
        if (o.id > this.max) {
          this.max = o.id
        }
        if (o.logo16) {
          o.logo16 = 'data:image/png;base64,' + o.logo16;
        }
      }
      this.origenes.sort((a, b) => {
        if (a.deprecated === b.deprecated) {
          if (a.nombre === b.nombre) {
            return a.id - b.id;
          } else if (a.nombre < b.nombre) {
            return -1;
          } else {
            return 1;
          }
        } else if (a.deprecated && !b.deprecated) {
          return 1;
        } else {
          return -1;
        }
      })
    }));
  }




}
