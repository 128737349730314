import { Component, OnInit, ViewChild, } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { first, map, } from 'rxjs/operators';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { LocalAdmin } from '../../shared/admin/local-admin';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';

import { AuthService } from "../../core/auth.service";
import { CuentaService } from '../../services/cuenta.service';
import { Router } from '@angular/router';
import * as consts from "../../shared/constantes";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

interface DataParaTabla {
    pais: string,
    moneda: string,
    ingneto: number,
    iva: number,
    descuentos: number,
    cotizacion: number,
    locales: number,
    cuentas: number,
    servidores: number,

    //calculados
    ingbruto: number,
    pxServidores: number,
    pxLocales: number,
    pxCuentas: number,

    paisesDistintos: ServidorAdmin[],
    monedasDistintas: ServidorAdmin[],
}

@Component({
    selector: 'app-admin-metricas2',
    templateUrl: './admin-metricas2.component.html',
    styleUrls: ['./admin-metricas2.component.scss']
})
export class AdminMetricas2Component implements OnInit {


    cantLeidas: number = 0;

    itemsRef: AngularFireList<any>;
    items: Observable<any[]>;

    msjSnack: string;

    fecha: any;


    email: string;
    //datos tabla

    driftVar: any;

    paisMonedasImportes: Map<string, Map<string, {
        importe: number,
        iva: number,
        descuentos: number,
        cotizacion: number,
        locales: Map<string, boolean>,
        cuentas: Map<string, boolean>,
        servidores: number,
        paisesDistintos: ServidorAdmin[],
        monedasDistintas: ServidorAdmin[],
    }>> = new Map();

    paisesMonedas: DataParaTabla[];

    total: {
        importe: number,
        iva: number,
        descuentos: number,
        locales: number,
        cuentas: number,
        servidores: number,
    };

    columnsToDisplay = [
        'pais',
        'moneda',
        'cotizacion',
        'ingbruto',
        'ingneto',
        'cuentas',
        'locales',
        'servidores',
        'pxCuentas',
        'pxLocales',
        'pxServidores',
    ];
    dataSource: MatTableDataSource<DataParaTabla>;
    @ViewChild(MatSort) sort: MatSort;


    hideDrift(cant) {
        if (cant < 15) {
            if (this.driftVar) {
                this.driftVar.widget ? this.driftVar.widget.remove() : "";
            } else {
                this.driftVar = window["HubSpotConversations"];
            }
            cant++;
            setTimeout(() => { this.hideDrift(cant) }, 600)
        }
    }
    ngOnInit() {
        this.hideDrift(1)
        this.leerCuentas();

    }
    esAdmin(user) {
        return consts.esAdmin(user);
    }
    constructor(
        public router: Router,
        public afAuth: AngularFireAuth,
        public cuentaService: CuentaService,
        public db: AngularFireDatabase,
        public authService: AuthService,
    ) {
        afAuth.user.subscribe(user => {
            if (user && user.email && this.esAdmin(user)) {
                this.email = user.email;
            }
        });
    }

    leerCuentas() {
        this.itemsRef = this.db.list('cuentas');
        this.items = this.itemsRef.snapshotChanges().pipe(
            first(),
            map(changes => {
                let mapeado = changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
                this.sacaLocalesYServidores(mapeado);
                return mapeado;
            })
        );
    }

    actualizaCotizacion(data: {
        pais: string,
        moneda: string,
        importe: number,
        iva: number,
        descuentos: number,
        cotizacion: number,
        locales: number,
        cuentas: number,
        servidores: number,
    }) {
        const nuevaCotiz = prompt("Cotización 1 USD = X " + data.moneda, '' + data.cotizacion);
        if (!isNaN(+nuevaCotiz)) {
            data.cotizacion = +nuevaCotiz;

            const objetoMapa = this.paisMonedasImportes.get(data.pais).get(data.moneda);
            objetoMapa.cotizacion = data.cotizacion;
            console.log(nuevaCotiz)
            localStorage.setItem(`cotizacion.${data.pais}.${data.moneda}`, nuevaCotiz);
            this.actualizaTotal();
        }
    }

    async sacaLocalesYServidores(mapCuentas: any[]) {

        for (let i in mapCuentas) {
            const monedasDistintas: ServidorAdmin[] = [];
            const paisesDistintos: ServidorAdmin[] = [];
            let paisCuenta: string;
            let monedaCuenta: string;
            let c: CuentaAdmin = mapCuentas[i];
            c.id = mapCuentas[i].key;
            let mapLocales = mapCuentas[i].locales;
            //for de locales
            for (let j in mapLocales) {
                let l: LocalAdmin = mapLocales[j];
                l.id = j;
                l.idCuenta = c.id;


                //for de servidores
                let mapServidores = mapLocales[j].servidores;
                for (let y in mapServidores) {
                    let s: ServidorAdmin = mapServidores[y];
                    s.idMac = y;
                    s.idLocal = l.id;
                    s.idCuenta = c.id;
                    s.uCuenta = c.u;
                    s.lastCuenta = c.last;
                    if (c.whatsapp) {
                        s.whatsapp = c.whatsapp;
                    }
                    if (c.contacto) {
                        s.contacto = c.contacto;
                    }
                    s.nombreCuenta = c.nombreCuenta;
                    s.nombreLocal = l.nombreLocal;


                    if (this.metodoExporte(s)) {
                        let importesADescontar = 0;
                        // TODO: importes a descontar de cuotas que no son fijas
                        if (!paisCuenta) {
                            paisCuenta = s.pais;
                        }
                        if (!monedaCuenta) {
                            monedaCuenta = s.moneda;
                        }

                        if (paisCuenta !== s.pais) {
                            console.log('***paises distintos: ' + paisCuenta, s);
                            paisesDistintos.push(s);
                        }
                        if (monedaCuenta !== s.moneda) {
                            console.log('***monedas distintas: ' + monedaCuenta, s);
                            monedasDistintas.push(s);
                        }

                        if (!this.paisMonedasImportes.get(s.pais)) {
                            this.paisMonedasImportes.set(s.pais, new Map());
                        }
                        const paisMonedasMap = this.paisMonedasImportes.get(s.pais);
                        if (!paisMonedasMap.get(s.moneda)) {
                            paisMonedasMap.set(s.moneda, {
                                cotizacion: 0,
                                descuentos: 0,
                                importe: 0,
                                iva: 0,
                                locales: new Map(),
                                cuentas: new Map(),
                                servidores: 0,
                                paisesDistintos: [],
                                monedasDistintas: [],
                            });
                        }
                        if (isNaN(s.importe)) {
                            console.log("*********************NaN************************")
                            console.log(s)
                        }


                        const datos = paisMonedasMap.get(s.moneda);
                        datos.importe = datos.importe + s.importe - importesADescontar;
                        if (s.pagaIva && +s.pagaIva > 0 && !isNaN(s.pagaIva)) {
                            datos.iva = s.pagaIva * (s.importe - importesADescontar) / 100;
                        }
                        datos.locales.set(`${s.idCuenta}/locales/${s.idLocal}`, true);
                        datos.cuentas.set(`${s.idCuenta}`, true);
                        datos.servidores = datos.servidores + 1;


                        if (monedaCuenta !== s.moneda) {
                            datos.monedasDistintas.push(s);
                        }
                        if (paisCuenta !== s.pais) {
                            datos.paisesDistintos.push(s);
                        }
                    }
                }
            }
        }

        this.actualizaTotal();
    }

    actualizaTotal() {
        this.total = {
            cuentas: 0,
            descuentos: 0,
            importe: 0,
            iva: 0,
            locales: 0,
            servidores: 0,
        };
        this.paisesMonedas = [];
        for (let pais of this.paisMonedasImportes.keys()) {
            for (let moneda of this.paisMonedasImportes.get(pais).keys()) {
                const objetoMapa = this.paisMonedasImportes.get(pais).get(moneda);
                const cotizacion = this.getCotizacion(pais, moneda);
                this.paisesMonedas.push({
                    cotizacion: cotizacion,
                    cuentas: objetoMapa.cuentas.size,
                    descuentos: cotizacion * objetoMapa.descuentos,
                    ingneto: cotizacion * objetoMapa.importe,
                    iva: cotizacion * objetoMapa.iva,
                    locales: objetoMapa.locales.size,
                    moneda: moneda,
                    pais: pais,
                    servidores: objetoMapa.servidores,
                    ingbruto: cotizacion * (objetoMapa.importe + objetoMapa.iva),
                    pxCuentas: cotizacion * (objetoMapa.importe + objetoMapa.iva) / objetoMapa.cuentas.size,
                    pxLocales: cotizacion * (objetoMapa.importe + objetoMapa.iva) / objetoMapa.locales.size,
                    pxServidores: cotizacion * (objetoMapa.importe + objetoMapa.iva) / objetoMapa.servidores,
                    paisesDistintos: objetoMapa.paisesDistintos,
                    monedasDistintas: objetoMapa.monedasDistintas,
                });

                this.total.cuentas = this.total.cuentas + objetoMapa.cuentas.size;
                this.total.descuentos = this.total.descuentos + (cotizacion * objetoMapa.descuentos);
                this.total.importe = this.total.importe + (cotizacion * objetoMapa.importe);
                this.total.iva = this.total.iva + (cotizacion * objetoMapa.iva);
                this.total.locales = this.total.locales + objetoMapa.locales.size;
                this.total.servidores = this.total.servidores + objetoMapa.servidores;
            }
        }

        this.paisesMonedas.push({
            cotizacion: 1,
            cuentas: this.total.cuentas,
            descuentos: this.total.descuentos,
            ingneto: this.total.importe,
            iva: this.total.iva,
            locales: this.total.locales,
            moneda: 'USD',
            pais: 'TOTAL',
            servidores: this.total.servidores,
            ingbruto: (this.total.importe + this.total.iva),
            pxCuentas: (this.total.importe + this.total.iva) / this.total.cuentas,
            pxLocales: (this.total.importe + this.total.iva) / this.total.locales,
            pxServidores: (this.total.importe + this.total.iva) / this.total.servidores,
            monedasDistintas: [],
            paisesDistintos: [],
        })
        this.dataSource = new MatTableDataSource(this.paisesMonedas);
        this.dataSource.sort = this.sort;

    }

    clickPaisesDistintos(data: DataParaTabla) {
        console.log(data.paisesDistintos);
        alert('Revisa la consola (F12) para ver los paises distintos, configurados en la misma cuenta');
    }

    clickMonedasDistintas(data: DataParaTabla) {
        console.log(data.monedasDistintas);
        alert('Revisa la consola (F12) para ver las monedas distintas, configuradas en la misma cuenta');
    }

    sortHeader() {
        this.dataSource.sort = this.sort;
    }

    getCotizacion(pais: string, moneda: string): number {
        //TODO: traer de endpoint?
        const cotizacionGuardada = localStorage.getItem(`cotizacion.${pais}.${moneda}`);
        if (!cotizacionGuardada) {
            return 1;
        } else {
            return +cotizacionGuardada;
        }
    }

    metodoExporte(servidor: ServidorAdmin) {
        return servidor.idMac
            && +servidor.numeroLocal
            && servidor.nombreServidor
            && servidor.numeroLocal
            && +servidor.numeroLocal > 0
            && +servidor.numeroLocal < 9000
            && +servidor.numeroLocal !== 900
            && servidor.nombreServidor !== 'Costo alta de licencia';
    }

    openSnackBar(message: string, action: string) {
        this.msjSnack = "|  " + message + "      " + action + "  |"
        setTimeout(_ => {
            this.msjSnack = null
        }, 3000)
    }
}
