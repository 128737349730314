import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServidorAdmin } from '../shared/admin/servidor-admin';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { POnline } from '../admin/test-pedidos-online/ponliine';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-mispedidosonline',
  templateUrl: './mispedidosonline.component.html',
  styleUrls: ['./mispedidosonline.component.scss']
})
export class MispedidosonlineComponent implements OnInit, OnDestroy {

  buscando: boolean = true;
  keyPedidosOnline: { key: string, locales?: Map<string, { activado: boolean, nombre: string, autoacept: boolean }> };
  msjOops: string;
  pedidos: Map<string, POnline>;
  servidor: ServidorAdmin;
  tiendaOnline: { key: string, activado: boolean, nombre: string, autoaccept: boolean }[] = []

  /**
   * resultados de busqueda rango de fechas
   */
  resultados: string;
  //subscriptions
  subscriptionPedidosProcesados: Subscription;
  subsCuenta: Subscription;
  subsInfo: Subscription;
  subsServidor: Subscription;

  constructor(private db: AngularFireDatabase, private authService: AuthService) { }

  ngOnInit() {
    this.buscando = true;
    //leer servidor elegido en adminCuentas
    this.subsInfo = this.authService.cuenta$.subscribe(async cuenta => {
      if (cuenta && cuenta.id) {
        let macPasadaSnap = await this.db.object("macsPasadas/" + cuenta.id).query.once("value");
        if (macPasadaSnap && macPasadaSnap.val()) {
          let macPasada: { idCuenta: string, idLocal: string } = macPasadaSnap.val();
          if (macPasada && macPasada.idCuenta && macPasada.idLocal) {
            this.subsServidor = this.db.object("cuentas/" + macPasada.idCuenta + "/locales/" + macPasada.idLocal + "/servidores/" + cuenta.id).snapshotChanges().subscribe(value => {
              this.servidor = <ServidorAdmin>value.payload.val();
              console.log(this.servidor)
              if (this.servidor) {
                this.keyPedidosOnline = this.servidor.keyPedidosOnline;

                if (this.keyPedidosOnline) {
                  this.tiendaOnline = []

                  for (let k in this.keyPedidosOnline.locales) {
                    let l = this.keyPedidosOnline.locales[k];
                    this.tiendaOnline.push({ key: k, activado: l.activado, nombre: l.nombre, autoaccept: l.autoacept })
                  }

                  //TODO: filtro por local
                } else {
                  //TODO: mostrar teaser de 
                  this.buscando = false;
                  this.msjOops = "Parece que no tienes la integración de Pedidos Online activa en tu Manager"
                }
              } else {
                //mostrar que parece no tener Popapp Manager instalado
                this.msjOops = "Parece que no tienes un Manager instalado Windows"
                this.buscando = false;
              }
            });
          } else {
            this.buscando = false;
            this.msjOops = "No hay info para esa cuenta"
          }
        } else {
          this.buscando = false;
          this.msjOops = "No hay info para esa cuenta"
        }
      } else {
        this.buscando = false;
        this.msjOops = "No hay cuenta elegida"
      }




    });

  }

  ngOnDestroy() {
    //desuscribirse a los pedidos
    if (this.subsInfo) {
      try { this.subsInfo.unsubscribe() } catch (e) { }
    }
    if (this.subsServidor) {
      try { this.subsServidor.unsubscribe() } catch (e) { }
    }

    if (this.subscriptionPedidosProcesados) {
      try { this.subscriptionPedidosProcesados.unsubscribe() } catch (e) { }
    }


  }

  consultar(datos: { fechaDesde: string, fechaHasta: string, buscando: boolean }) {
    this.pedidos = new Map<string, POnline>();
    if (this.subscriptionPedidosProcesados) {
      try { this.subscriptionPedidosProcesados.unsubscribe() } catch (e) { }
    }
    this.buscando = true;
    this.resultados = null
    let pList = this.db.list("pedidosProcesados2/" + this.keyPedidosOnline.key, ref => ref.orderByChild("fechaCreacion")
      .startAt(datos.fechaDesde)
      .endAt(datos.fechaHasta + "~"))


    this.subscriptionPedidosProcesados = pList.snapshotChanges().subscribe(changes => {

      this.buscando = false;
      //if (datos.fechaDesde === datos.fechaHasta) {
      this.resultados = "Resultados desde " + datos.fechaDesde + " a " + datos.fechaHasta;

      //}
      // this.resultados = "Resultados desde " + datos.fechaDesde + " a "
      changes.map(p => {
        let pp: POnline = <POnline>p.payload.val();
        //  this.buscando = false;

        return this.procesa(pp);
      });
      // this.dibujaCharts()
    })

  }


  procesa(p: POnline): Map<string, POnline> {
    if (!p.nombreStore || p.nombreStore.length === 0) {
      p.nombreStore = this.keyPedidosOnline.locales[p.keyStore].nombre
    }
    this.pedidos.set(p.id, p);
    return this.pedidos;
    /*this.tareas.forEach((v) => {
      if (v.Estado === "DONE") {
        let size = v.Estimacion;
        if (v.Asignada && v.Asignada.length > 1) {
          size = v.Estimacion * v.Asignada.length;
        }
        if (tipos.get(v.Tipo)) {
          let nuevoTotal = tipos.get(v.Tipo).total + size;
          tipos.set(v.Tipo, { nombre: v.Tipo, total: nuevoTotal })
        } else {
          tipos.set(v.Tipo, { nombre: v.Tipo, total: size })
        }

      }
    })*/
    //return tipos;
  }
}