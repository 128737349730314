import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from '../../../node_modules/rxjs';
import { AuthService } from '../core/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pagar',
  templateUrl: './pagar.component.html',
  styleUrls: ['./pagar.component.scss']
})
export class PagarComponent implements OnInit, OnDestroy {

  subscriptionBody: Subscription;

  importeTotal: number;
  imageSource: string;
  mpInitPoint: string;
  moneda: string;
  pais: string;
  idCuentaAPagar: string;
  ultimoPagoAnterior: string;
  ultimoPagoNuevo: any;
  timerX: string;

  //showOnepay: boolean = false;
  // monedaCL: string;
  // importeTotalCL: number;
  // mesesCL: number;
  //onepayVar: any;

  searchingForQr: boolean = false;

  constructor(private snackBar: MatSnackBar, private authService: AuthService, private http: Http) {
    this.searchingForQr = false;
    //this.ngOnInit();
    //this.showOnepay = false;
  }

  ngOnInit() {
    //this.onepayVar = window["Onepay"];

    this.searchingForQr = false;
    //this.showOnepay = false;

    this.subscriptionBody = this.authService.bodyAPagar$
      .subscribe(body => {
        if (body && this.authService.urlAPagar) {
          //console.log("body", body);
          //console.log("url", this.authService.urlAPagar);
          //hacer post a MP para obtener el QR
          //"currency_id": this.moneda,
          //"unit_price": totalMensual,
          //"quantity": this.cantMesesAPagar
          this.searchingForQr = true;
          this.ultimoPagoAnterior = this.authService.ultimoPago;
          this.idCuentaAPagar = this.authService.idCuentaAPagar;
          this.moneda = this.authService.moneda;
          this.pais = this.authService.pais;

          //this.importeTotalCL = body.items[0].quantity * body.items[0].unit_price;
          //this.monedaCL = body.items[0].currency_id;
          //this.mesesCL = body.items[0].quantity;
          //if (this.monedaCL && (this.monedaCL == "CLP $" || this.monedaCL == "CLP")) {
          //  this.showOnepay = true;
          //}
          this.escribeYObtieneQRCargado(body, this.authService.urlAPagar);
          this.startTimer();

        }
      });
  }



  ngOnDestroy(): void {
    //this.ref.snapshotChanges().unsubscribe();
    // prevent memory leak when component is destroyed
    this.subscriptionBody.unsubscribe();
  }
  startTimer() {

    // Set the date we're counting down to
    let auxDate = new Date();
    auxDate.setMinutes(auxDate.getMinutes() + 10);
    auxDate.setSeconds(auxDate.getSeconds() - 10);
    let countDownDate = auxDate.getTime();
    this.timerX = "10:00"

    // Update the count down every 1 second
    let x = setInterval(() => {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      this.timerX = (minutes < 10 ? "0" + minutes : minutes)
        + ":" + (seconds < 10 ? "0" + seconds : seconds);


      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        location.reload();
      }
    }, 1000);
  }

  //onepay() {
  //TODO: let ruta = 'https://popappservice.net/test/popapp/' + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
  //let ruta = 'https://popappservice.net/app/popapp/' + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
  ////let ruta = 'http://localhost:8080/popapp/' + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
  ////let rutaCallback = 'https://popappservice.net/app/mypopapp/payments';/// + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
  ////TODO:let rutaCallback = 'https://popappservice.net/test/mypopapp/payments';/// + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
  ////let rutaCallback = 'http://localhost:8080/mypopapp/payments';/// + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
  //console.log("ruta", ruta)

  //let description = 'Licencia popapp ' + this.mesesCL;
  //if (this.mesesCL == 1) {
  //  description += " mes"
  //} else {
  //  description += " meses"
  //}
  //this.onepayVar.checkout({
  //  endpoint: ruta,
  //  commerceLogo: 'https://popapp.io/mypopapp/assets/images/logonegro.png',
  //  callbackUrl: rutaCallback,
  //  transactionDescription: description,
  //  onclose: function (status) {
  //    //console.log('el estado recibido es: ', status);
  //  }
  //});
  //}
  //
  escribeYObtieneQRCargado(body: { items: any[] }, url: string): void {
    this.httpPostPagoQR(body, url)
      .then(respuesta => {
        //console.log("Respuestaaa", respuesta);
        if (respuesta && respuesta.bodyQR && respuesta.bodyQR.qr_template && respuesta.bodyQR.qr_template.image) { //image en vez de dpcument es para el png
          this.imageSource = respuesta.bodyQR.qr_template.image;
          this.searchingForQr = false;
          this.importeTotal = body.items[0].quantity * body.items[0].unit_price;
          this.moneda = body.items[0].currency_id;

        }
        if (respuesta && respuesta.init_point) {
          this.searchingForQr = false;

          this.mpInitPoint = respuesta.init_point;
        }

        //empezar a escuchar en cuenta.ultimoPago para mostrar exito
        let refMacP = this.authService.leerFB('cuentas/' + this.idCuentaAPagar + '/ultimoPago/');
        refMacP.snapshotChanges().subscribe(action => {

          //consts.logD(action.type);
          //consts.logD(action.key);
          //consts.logD(action.payload.val());
          if (action && action.payload) {
            this.ultimoPagoNuevo = action.payload.val();
          }

        });
      },
        error => {
          this.snackBar.open("No se pudo cargar el QR", "", { duration: 4000 });
          this.searchingForQr = false;
        }
      );
  }

  httpPostPagoQR(body: { items: any[] }, url: string): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    return this.http.post(url, body, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorObservable);
  }
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

}
