import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../core/auth.service';
import { Http } from '@angular/http';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { CuentaService } from '../../services/cuenta.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { LocalAdmin } from '../../shared/admin/local-admin';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';
import * as consts from "../../shared/constantes";

@Component({
  selector: 'app-map-cuentas',
  templateUrl: './map-cuentas.component.html',
  styleUrls: ['./map-cuentas.component.scss']
})
export class MapCuentasComponent implements OnInit {

  ref: AngularFireList<any>;
  markers: Observable<any[]>;

  title = 'Mapa Popapp';
  lat = -26.678418;
  lng = -50.809007;
  zoom = 3;

  servidores: ServidorAdmin[];
  locales: Map<string, LocalAdmin>;
  cuentasMap: Map<string, CuentaAdmin>;
  cuentas: CuentaAdmin[];

  fechaFiltro: string;
  filtrarPorFecha: boolean;

  constructor(public afAuth: AngularFireAuth, public cuentaService: CuentaService, public db: AngularFireDatabase, private http: Http, public authService: AuthService) {
    afAuth.user.subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {
        //leemos las cuentas
        this.ref = db.list('cuentas');
        // Use snapshotChanges().map() to store the key
        this.markers = this.ref.snapshotChanges().pipe(
          map(changes => {
            let mapeado = changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
            //console.log("LLEGO ALGO");

            this.sacaLocalesYServidores(mapeado);
            return mapeado;
          }
          )
        );
      }
    });
  }
  driftVar: any;

  esAdmin(user) {
    return consts.esAdmin(user);
  }
  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  ngOnInit() {
    this.hideDrift(1)
  }


  diasss(fecha: string, hoyAnio, hoyMes, hoyDia): number {
    let fv: string = fecha;
    if (!fv) {
      fv = "1/1/1999";
    }
    let indexBarra: number = fv.indexOf("/");
    let iFor = 0;
    let stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let dia: number = Number(stringAuxFecha);

    fv = fv.replace(stringAuxFecha + "/", "");
    indexBarra = fv.indexOf("/");
    iFor = 0;
    stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let mes: number = Number(stringAuxFecha);
    fv = fv.replace(stringAuxFecha + "/", "");
    let anio: number = Number(fv);

    //let mes: number = Number(fv.charAt(3) + fv.charAt(4));
    //let anio: number = Number(fv.charAt(6) + fv.charAt(7) + fv.charAt(8) + fv.charAt(9));
    let dias = (anio - hoyAnio) * 365;
    dias += (mes - 1 - hoyMes) * 30;
    dias += (dia - hoyDia);
    return dias;
  }

  clickMap(event) {
    console.log(event)
    event.coords.lat;
    event.coords.lng;
  }
  sacaLocalesYServidores(mapCuentas: any[]) {
    let hoyDia: number = new Date().getDate();
    let hoyMes: number = new Date().getMonth();
    let hoyAnio: number = new Date().getFullYear();
    //console.log("empieza a sacar");
    //for (let i = 0; i < this.servidores.length;) {
    //this.servidores. pop();
    //}
    this.servidores = [];
    this.locales = new Map<string, LocalAdmin>();
    this.cuentasMap = new Map<string, CuentaAdmin>();
    this.cuentas = [];
    //for de cuentas
    let importes: number = 0;
    let importesDolar: number = 0;
    let importesClp: number = 0;

    let servidoresSinVencer: number = 0;
    let incluidos14: number = 0;
    for (let i in mapCuentas) {
      let c: CuentaAdmin = mapCuentas[i];
      c.id = mapCuentas[i].key;
      this.cuentasMap.set(mapCuentas[i].key, c);
      let mapLocales = mapCuentas[i].locales;
      //for de locales
      let indexLocal = 0;
      let totalLocales = 0;
      for (let j in mapLocales) {
        totalLocales++;
      }
      for (let j in mapLocales) {
        indexLocal++;
        let l: LocalAdmin = mapLocales[j];
        l.id = j;
        l.idCuenta = c.id;
        l.totLocales = totalLocales;


        //for de servidores
        let mapServidores = mapLocales[j].servidores;
        let indexServidor = 0;
        let totalServidores = 0;
        for (let y in mapServidores) {
          totalServidores++;
        }
        for (let y in mapServidores) {
          indexServidor++;
          let s: ServidorAdmin = mapServidores[y];
          s.idMac = y;
          s.idLocal = l.id;
          s.idCuenta = c.id;
          if (c.whatsapp) {
            s.whatsapp = c.whatsapp;
          }
          if (c.contacto) {
            s.contacto = c.contacto;
          }
          s.nombreCuenta = c.nombreCuenta;
          s.nombreLocal = l.nombreLocal;
          s.indServidor = indexServidor;
          s.indLocal = indexLocal;
          s.totLocales = totalLocales;
          s.totServidoresOSvs = totalServidores;



          let dias = this.diasss(s.vencimiento, hoyAnio, hoyMes, hoyDia)
          s.dias = dias;

          let diasReal = this.diasss(s.pagoHasta, hoyAnio, hoyMes, hoyDia)
          s.diasReal = diasReal
          if (dias >= 0) {
            servidoresSinVencer++;
            incluidos14++;
          } else if (dias >= -14) {
            incluidos14++;
          }

          this.servidores.push(s);

          if (s.numeroLocal && s.moneda && +s.numeroLocal > 0 && +s.numeroLocal < 9000 && +s.numeroLocal !== 900 && (s.cuotasPendientes == null)) { //1500 para arriba seria para testear pero no para sumar
            if (s.moneda == "ARS" || s.moneda == "ARS $") {
              importes += s.importe;
              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;


            } else if (s.moneda == "CLP" || s.moneda == "CLP $") {
              importesClp += s.importe;
              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;


            } else if (s.moneda == "USD" || s.moneda == "U$D") {
              importesDolar += s.importe;
              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;

            } else if (s.valorDolar && s.valorDolar > 0) {

              let valorAPagar = s.valorDolar;
              if (s.pagaIva && s.pagaIva > 0) {
                valorAPagar = valorAPagar + valorAPagar * s.pagaIva / 100;
              }

              importesDolar += valorAPagar;
              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;


            } else {
              // no habria q sumar nada pues no sabemos q moneda iria
            }
          }

        }

        //  l.servidoresIds = asdasdasd;
        this.locales.set(l.id, l);
      }

      // c.localesIds = asdasd;
      this.cuentas.push(c);
    }
  }
}
