import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

interface Resultado {
  pais: string;
  idMac: string;
  nombre: string;
  barrio: string;
  ciudad: string;
}

@Component({
  selector: 'app-admin-automatizaciones',
  templateUrl: './admin-automatizaciones.component.html',
  styleUrls: ['./admin-automatizaciones.component.scss']
})
export class AdminAutomatizacionesComponent implements OnInit {

  constructor(
    private fs: AngularFirestore,
  ) { }

  ngOnInit(): void {
  }

  async buscarLocalesConPais() {
    // const resultados: Resultado[] = [];
    const resultados: string[] = [];
    const fallos: Map<string, boolean> = new Map();
    const intermedios: Resultado[] = [];
    const localesSnaps = await this.fs.collection('confLocalPO').get().toPromise();
    for (const s of localesSnaps.docs) {
      const local = <any>s.data();
      if (local.barrio || local.ciudad) {
        if (this.buscar(local.barrio) || this.buscar(local.ciudad)) {
          if (local.pais && local.pais === 'Chile') {
            resultados.push(local.idMac);
          } else {
            intermedios.push({ pais: local.pais, barrio: local.barrio, ciudad: local.ciudad, idMac: local.idMac, nombre: local.nombre });
          }
        } else {
          if (local.pais && local.pais !== 'Chile') {

          } else {
            if (local.barrio && local.ciudad && local.barrio === 'Santiago' && local.ciudad === 'Santiago') {
              resultados.push(local.idMac);
            } else {
              fallos.set('b: ' + local.barrio + ', c: ' + local.ciudad, true);
            }
          }
        }
      }
    }

    console.log('*****************');
    console.log('FALLOS');
    console.log('*****************');
    console.log(fallos.keys());
    console.log('               ');
    console.log('               ');
    console.log('*****************');
    console.log('INTERMEDIOS');
    console.log('*****************');
    console.log(intermedios);

    console.log('               ');
    console.log('               ');
    console.log('*****************');
    console.log('RESULTADOS');
    console.log('*****************');
    console.log(resultados);
  }

  buscar(lugar: string): boolean {
    if (!lugar) {
      return false;
    }
    lugar = lugar.toLocaleLowerCase();
    lugar = lugar.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    const matches = [
      'vitacura',
      'providencia',
      'condes',
    ];

    for (const m of matches) {
      if (lugar.indexOf(m) > -1) {
        return true;
      }
    }

    return false;
  }

}


