import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import * as consts from "../shared/constantes";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(public auth: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    return this.auth.user.pipe(take(1),
      map(user => {
        //console.log("user auth", user)
        return user && user.email && user.email.length > 3 && this.esAdmin(user)

      }),
      tap(loggedIn => {
        if (!loggedIn) {
          //console.log('access denied')
          //this.router.navigate(['/mypopapp?sub=' + next.url.join(",")]);
          if (state && state.url && state.url !== "null") {
            this.auth.setRutaAlaQueIba(state.url)
          } else {
            this.auth.setRutaAlaQueIba(null)
          }
          this.router.navigate(['/mypopapp']);
        } else {
          this.auth.setRutaAlaQueIba(null)
        }
      })
    )
  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }
}
