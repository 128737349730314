import { Component, OnInit } from '@angular/core';
import { RutaService } from '../services/ruta.service';
import { MatDialog } from '@angular/material/dialog';
import { TeaserleadspedidosonlineComponent } from '../modales/teaserleadspedidosonline/teaserleadspedidosonline.component';

@Component({
  selector: 'app-pedidosonline',
  templateUrl: './pedidosonline.component.html',
  styleUrls: ['./pedidosonline.component.scss']
})
export class PedidosonlineComponent implements OnInit {


  constructor(public rutaService: RutaService,
    public dialog: MatDialog) { }


  ngOnInit() {
    this.rutaService.changeRuta("comenzar")
  }

  quieroSaberMas() {
    const dialogRef = this.dialog.open(TeaserleadspedidosonlineComponent, {
      width: '95%',
      height: "75%",
      data: undefined
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }




}
