export class Cambio {
    key: string;
    name: string;
    tipo: string;
    padreKey: string;
    shortPadreKey: string;
    constructor(
        padreKey: string,
        key: string,
        name: string,
        tipo: string,
        shortPadreKey: string

    ) {
        this.shortPadreKey = shortPadreKey;
        this.padreKey = padreKey;
        this.key = key;
        this.name = name;
        this.tipo = tipo;

    }
}