import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalConfPaisOffsetComponent } from 'src/app/modal-conf-pais-offset/modal-conf-pais-offset.component';
import { environment } from 'src/environments/environment';

interface DatosFacturacion {
  cuitORut: string;
  razonSocial: string;
  direccion: string;
  giro: string;
  comuna: string;
  mail: string;
  noFacturarAutomaticamente?: boolean;
}



@Component({
  selector: 'app-modal-config-fact-popapp-chi',
  templateUrl: './modal-config-fact-popapp-chi.component.html',
  styleUrls: ['./modal-config-fact-popapp-chi.component.scss']
})
export class ModalConfigFactPopappChiComponent implements OnInit {

  s: ServidorAdmin;
  form: FormGroup;
  submitted = false;
  error: string;
  loading: boolean;
  loadingPais: boolean;
  msjAMostrar: string;

  facturarAutomaticamente?: boolean;
  datosFacturacion: DatosFacturacion;
  pais: string;

  RUTEmisor: string;
  RznSoc: string;
  CiudadOrigen: string;
  CmnaOrigen: string;
  DirOrigen: string;
  GiroEmis: string;
  warningDatosIncompletos: boolean;

  errorSii: string;
  buscandoDatos: boolean;




  constructor(
    public dialogRef: MatDialogRef<ModalConfigFactPopappChiComponent>,
    public auth: AuthService,
    private cuentaService: CuentaService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin },
    private db: AngularFireDatabase,
    private dbFs: AngularFirestore,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) {
    this.s = this.data.s;
  }

  abrirModalPais() {
    this.loadingPais = true;
    if (this.s.keyPedidosOnline && this.s.keyPedidosOnline.key) {
      const s = this.s;
      const dialogRef = this.dialog.open(ModalConfPaisOffsetComponent, {
        width: '85%',
        data: { s }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadingPais = false;

        console.log('The dialog was closed', result);
        if (result && result.pais && result.offset) {

          this.pais = result.pais;
        } else {
          console.error('No hay pais y offset')
        }
      });
    }
  }



  async ngOnInit() {
    this.loading = true;

    if (!(this.s && this.s.pais && this.s.pais === 'CHI')) {
      this.msjAMostrar = 'Sólo se puede configurar para cuentas con pais "CHI"';
      this.loading = false;
      return;

    }

    this.abrirModalPais();

    let cuenta: any = await this.dbFs.doc('cuentas/' + this.s.idCuenta).get().toPromise().then((snap) => { return snap.data() });
    if (cuenta && cuenta.datosFacturacion) {
      this.datosFacturacion = cuenta.datosFacturacion;
      console.log(this.datosFacturacion);
      if (this.datosFacturacion.comuna &&
        this.datosFacturacion.cuitORut &&
        this.datosFacturacion.direccion &&
        this.datosFacturacion.giro &&
        this.datosFacturacion.mail &&
        this.datosFacturacion.razonSocial
      ) {
        this.warningDatosIncompletos = false;
      } else {
        this.warningDatosIncompletos = true;
      }

    } else if (this.s.factElect && this.s.factElect.Emisor) {
      this.warningDatosIncompletos = true;

      this.datosFacturacion = {
        cuitORut: this.s.factElect.Emisor.RUTEmisor,
        comuna: this.s.factElect.Emisor.CmnaOrigen,
        noFacturarAutomaticamente: false,
        direccion: this.s.factElect.Emisor.DirOrigen,
        giro: this.s.factElect.Emisor.GiroEmis,
        razonSocial: this.s.factElect.Emisor.RznSoc,
        mail: '',
      }
    }

    this.initForm();

    this.loading = false;
  }

  initForm() {

    this.form = this.formBuilder.group({
      facturarAutomaticamente: [this.datosFacturacion ? (this.datosFacturacion.noFacturarAutomaticamente ? false : true) : true],
      RUTEmisor: [this.datosFacturacion?.cuitORut, [Validators.required, Validators.minLength(4), Validators.pattern(/^\d{7}(\d{1})?[-][0-9kK]{1}$/)]],
      RznSoc: [this.datosFacturacion?.razonSocial, [Validators.required]],
      CmnaOrigen: [this.datosFacturacion?.comuna, [Validators.required]],
      DirOrigen: [this.datosFacturacion?.direccion, [Validators.required]],
      GiroEmis: [this.datosFacturacion?.giro, [Validators.required]],
      mail: [this.datosFacturacion?.mail, [Validators.required, Validators.email]],

    });
  }


  async guardarDatos() {
    if (this.buscandoDatos) {
      return;
    }
    this.submitted = true;
    this.error = null;

    if (this.form.invalid) {
      this.error = "Datos inválidos";
      return;
    }


    const facturarAutomaticamente = this.form.controls.facturarAutomaticamente.value ? true : false;
    const RUTEmisor = this.form.controls.RUTEmisor.value.trim();
    const RznSoc = this.form.controls.RznSoc.value.trim();
    const CmnaOrigen = this.form.controls.CmnaOrigen.value.trim();
    const DirOrigen = this.form.controls.DirOrigen.value.trim();
    const GiroEmis = this.form.controls.GiroEmis.value.trim();
    const mail = this.form.controls.mail.value.trim();

    const datosFacturacion: DatosFacturacion = {
      cuitORut: RUTEmisor,
      comuna: CmnaOrigen,
      direccion: DirOrigen,
      giro: GiroEmis,
      mail: mail,
      razonSocial: RznSoc,
      noFacturarAutomaticamente: !facturarAutomaticamente
    }

    this.dbFs.doc('cuentas/' + this.s.idCuenta).set({
      datosFacturacion: datosFacturacion
    }, { merge: true }).then(async ok => {
      const mapU = {};
      mapU['cuentas/' + this.s.idCuenta + '/cuitORut'] = RUTEmisor;
      await this.db.object('/').update(mapU);
      this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
      this.dialogRef.close(datosFacturacion);
    }).catch(e => {
      console.error("No se pudo grabar", e);
      this.snackbar.open("Error, no se pudo grabar")._dismissAfter(2000);
    })
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  async datosSii() {
    this.buscandoDatos = true;
    this.errorSii = "Buscando...";

    try {
      const RUTEmisor = this.form.controls.RUTEmisor.value.trim();
      if (RUTEmisor.length < 8) {
        this.errorSii = 'RUT muy corto';
        return;
      }
      this.datosFacturacion = undefined;
      this.initForm();

      let urlProd = environment.urls.functions + "/cuentas/infoRut/";
      const datos = (await this.cuentaService.getUrlProm(urlProd + RUTEmisor)).json();
      if (datos.datosCliente) {
        this.errorSii = undefined;
        this.datosFacturacion = datos.datosCliente;
        this.initForm();

      } else {
        this.errorSii = "Sin datos";
      }
    } catch (e) {
      console.error(e);
      this.errorSii = e;
    } finally {
      this.buscandoDatos = false;
    }



  }


}