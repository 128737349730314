import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(public auth: AuthService, private router: Router, private db: AngularFireDatabase) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.auth.user.pipe(take(1),
      map(user => {
        //.log("user auth", user)
        if (user && user.email && user.email.length > 3) {
          let fecha = new Date().toISOString();
          let emailDecodificado = this.auth.decodeEmail(user.email);
          let mapUpdate = {};
          mapUpdate["users/" + emailDecodificado + "/lastActive"] = fecha;
          try {
            this.db.object("/").update(mapUpdate);
          } catch (e) {
            console.error("qwe", e);
          }
          return true;
        } else {
          return false;
        }

      }),
      tap(loggedIn => {
        if (!loggedIn) {

          // console.log('access denied')
          if (state && state.url && state.url !== "null") {
            this.auth.setRutaAlaQueIba(state.url);
          } else {
            this.auth.setRutaAlaQueIba(null);
          }
          this.router.navigate(['/mypopapp']);
        } else {
          this.auth.setRutaAlaQueIba(null);
        }
      })
    )
  }
}
