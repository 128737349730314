import { Injectable } from '@angular/core';
import { map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class DbFSService {
  constructor(private afs: AngularFirestore) { }

  collectionOnce<T>(path, query?) {
    return this.afs
      .collection<T>(path, query)
      .snapshotChanges()
      .pipe(
        first(),
        map(actions => {
          return actions.map(a => {
            const data: T = a.payload.doc.data();
            const _key = a.payload.doc.id;
            return { _key, ...data };
          });
        })
      );
  }

  collection$<T>(path, query?) {
    return this.afs
      .collection<T>(path, query)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: T = a.payload.doc.data();
            const _key = a.payload.doc.id;
            return { _key, ...data };
          });
        })
      );
  }

  collectionChanges$<T>(path, query?) {
    return this.afs
      .collection<T>(path, query)
      .stateChanges(['added', "modified", "removed"])
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: T = a.payload.doc.data();
            const _key = a.payload.doc.id;
            const _type = a.type;
            return { _type, _key, ...data };
          });
        })
      );
  }

  docOnce(path) {
    return this.afs.doc(path).get().toPromise();
  }


  doc$<T>(path): Observable<T> {
    return this.afs
      .doc<T>(path)
      .snapshotChanges()
      .pipe(
        map(doc => {
          return { id: doc.payload.id, ...doc.payload.data() as T };
        })
      );
  }


  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   **/
  updateAt(path: string, data: Object): Promise<any> {
    const segments = path.split('/').filter(v => v);

    if (segments.length % 2) {
      // Odd is always a collection

      return this.afs.collection(path).add(data);
    } else {
      // Even is always document
      return this.afs.doc(path).set(data, { merge: true });
    }
  }


  /**
   * @param  {string} path path to document
   *
   * Deletes document from Firestore
   **/
  delete(path) {
    return this.afs.doc(path).delete();
  }

  writeBatch(docs: { path: string, data: Object, removeOperation: boolean }[]): Promise<any> {
    let batch = this.afs.firestore.batch();
    let newId: string;
    for (let d of docs) {
      if (d.removeOperation) {
        let ref = this.afs.doc(d.path).ref;
        batch.delete(ref);
      } else {
        const segments = d.path.split('/').filter(v => v);
        if (segments.length % 2) {
          // Odd is always a collection
          let ref: DocumentReference;
          if (!newId) {
            ref = this.afs.collection(d.path).ref.doc();
            newId = ref.id;
          } else {
            let path = d.path.endsWith("/") ? d.path + newId : d.path + "/" + newId;
            ref = this.afs.doc(path).ref;
          }
          batch.set(ref, d.data);
        } else {
          // Even is always document
          let ref = this.afs.doc(d.path).ref;
          batch.set(ref, d.data, { merge: true });
        }
      }
    }
    return batch.commit();
  }
}