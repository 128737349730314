import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Http, Response } from "@angular/http";
import { Headers, RequestOptions } from "@angular/http";
import { DbFSService } from 'src/app/services/db-fs.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-modal-automatizar-sync-manager',
  templateUrl: './modal-automatizar-sync-manager.component.html',
  styleUrls: ['./modal-automatizar-sync-manager.component.scss']
})
export class ModalAutomatizarSyncManagerComponent implements OnInit {
  servidores: { s: ServidorAdmin, error: string, keyACrear: string, resultadoBuscaKey: string, buscandoKey: number }[] = [];
  error: string;
  loading: boolean;
  servidoresSinErrores: number;
  activarSync: boolean;
  // versionAAplicar: string = "4.6.9.3";

  MSJ_KEY_MUY_CORTA: string = "❌ Key muy corta";
  MSJ_KEY_VALIDA: string = "✅ Key válida";
  MSJ_KEY_NOVALIDA: string = '❌ Key con caracteres especiales (sólo permite minúsculas, números y "_")'
  // resultadoBuscaKey: string;

  constructor(
    public dialogRef: MatDialogRef<ModalAutomatizarSyncManagerComponent>,
    public auth: AuthService,
    private cuentaService: CuentaService,
    @Inject(MAT_DIALOG_DATA) public data: { servidores: ServidorAdmin[], activar: boolean },
    private db: AngularFireDatabase,
    private dbFirestore: AngularFirestore,
    private dbFS: DbFSService,
    private snackbar: MatSnackBar,
    private http: Http,
  ) {
    console.log(this.data);
    this.activarSync = this.data.activar;
    for (let s of this.data.servidores) {
      this.servidores.push({ s: s, error: "", keyACrear: "", resultadoBuscaKey: "", buscandoKey: 0 });
    }
  }


  async ngOnInit() {
    this.loading = true;
    this.servidoresSinErrores = this.servidores.length;
    this.checkVersion();
    this.checkQNoexistaLatienda();
    this.loading = false;
  }


  async buscaKey(key: string, se: { s: ServidorAdmin, error: string, keyACrear: string, resultadoBuscaKey: string, buscandoKey: number }) {
    se.keyACrear = key;
    // if (!key || key.length < 3) {
    //   this.resultadoBuscaKey = this.MSJ_KEY_MUY_CORTA;
    // } else {
    let validar = new RegExp("[^a-z0-9\_]")
    //buscar
    const copia = new Date().getTime();
    se.buscandoKey = new Date().getTime();
    se.resultadoBuscaKey = "🔍 Buscando...";

    let local = await this.dbFS.docOnce("confLocalPO/" + key);
    if (local.exists) {
      if (copia <= se.buscandoKey) {
        // ok
        se.resultadoBuscaKey = "❌ CUIDADO Esa key ya existe";
      } else {
        // se esta buscando otra key
      }
    } else {
      await this.dbFS.collectionOnce("confLocalPO/" + key + "/pedidos", ref => ref.limit(1)).toPromise().then(pedidos => {
        if (pedidos.length > 0) {
          if (copia <= se.buscandoKey) {
            // ok
            se.resultadoBuscaKey = "❌ CUIDADO Esa key ya tiene pedidos";
          } else {
            // se esta buscando otra key
          }

        } else {
          if (!validar.test(key) && key && key.length >= 3) {
            if (copia <= se.buscandoKey) {
              // ok
              se.resultadoBuscaKey = this.MSJ_KEY_VALIDA
            } else {
              // se esta buscando otra key
            }

          } else {
            if (!key || key.length < 3) {
              if (copia <= se.buscandoKey) {
                // ok
                se.resultadoBuscaKey = this.MSJ_KEY_MUY_CORTA;
              } else {
                // se esta buscando otra key
              }
            } else {
              console.log('Encontro algo que no esta en el patron permitido', (key.match(validar))[0]);
              if (copia <= se.buscandoKey) {
                // ok
                se.resultadoBuscaKey = this.MSJ_KEY_NOVALIDA + '. No permitido: "' + (key.match(validar))[0] + '"';
              } else {
                // se esta buscando otra key
              }
            }
          }
        }
      })
      // }
    }
  }



  checkQNoexistaLatienda() {
    for (let se of this.servidores) {
      let s = se.s;

      if (!s.keyPedidosOnline || !s.keyPedidosOnline.key) {
        se.keyACrear = s.nombreServidor;
        this.buscaKey(se.keyACrear, se);
      }

      if (s.keyPedidosOnline && s.keyPedidosOnline.locales) {
        for (let pya123 in s.keyPedidosOnline.locales) {
          const tienda = s.keyPedidosOnline.locales[pya123];
          /**
           *activado: true
            autoaccept: true
            concurrenciaEstado: false
            deliveryTimeAA: ""
            fechaAlta: "2020-11-16"
            idStore: "2"
            marca: "Popapp store"
            nombre: "Take away"
            origen: "tak"
            plataforma: "OTRA"
            tag: "test"
           */
          if (this.activarSync && tienda.idStore === s.idMac) {
            this.servidoresSinErrores--;
            se.error += " Ya tiene una tienda con MAC activada ";
            break;
          }
        }
      }
    }
  }

  checkVersion() {
    if (this.activarSync) {
      for (let se of this.servidores) {
        let s = se.s;
        // if (!s.versionActual || !(s.versionActual >= this.versionAAplicar)) {
        //   this.servidoresSinErrores--;
        //   se.error += " No tiene version mayor a 4.6.9.3";
        // }
      }
    }
  }

  /**
   * 
   */
  async desOActivarEnSQL(creandoTienda: boolean, mapUpdatesTiendaFS: any) {
    const baseUrl = environment.urls.functions +"/basesql/tiendas";
    // const baseUrl = "http://localhost:5000/popappowner/us-central1/basesql/tiendas";
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    const body = mapUpdatesTiendaFS;
    let resActivar
    if (creandoTienda) {
      resActivar = await this.http.post(baseUrl, body, options).toPromise();
    } else {
      resActivar = await this.http.patch(baseUrl, body, options).toPromise();
    }
    let jsonRespuestaActivar = await resActivar.json();
    console.log("Respuesta tienda sql", jsonRespuestaActivar);
  }


  async guardarTienda(s: ServidorAdmin) {

    let urlAdmin = "cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/locales/";
    let urlConfLocal = "confLocalPO/" + s.keyPedidosOnline.key + "/";

    // subimos el formulario 
    let mapUpdates = {};
    let mapUpdatesFS = {};
    mapUpdatesFS["_key"] = s.keyPedidosOnline.key;
    mapUpdatesFS["key"] = s.keyPedidosOnline.key;
    mapUpdatesFS["idCuenta"] = s.idCuenta;
    mapUpdatesFS["idLocal"] = s.idLocal;
    mapUpdatesFS["idMac"] = s.idMac;
    mapUpdatesFS["syncManager"] = true;

    let mapUpdatesTiendaFS = { keyLocal: s.keyPedidosOnline.key };
    const plataforma = "POPAPP";

    let origen = "pop";
    let origen3 = "pop";

    const nombre = "POPAPP";
    const idLocal = s.idMac;
    const activado = true;
    const autoaccept = true;
    const marca = "POPAPP";
    let date = new Date();
    let mes = date.getMonth() + 1;
    let dia = date.getDate();
    const fechaAlta = date.getFullYear() + "-" +
      (mes > 9 ? mes : "0" + mes) + "-" +
      (dia > 9 ? dia : "0" + dia);

    urlAdmin += origen3 + idLocal + "/";
    let urlConfLocalTienda = urlConfLocal + "tiendas/" + origen + "," + idLocal + "/";

    let urlkeysLocales2 = "keysLocalesPedidosOnline2/" + origen + "," + idLocal + "/";
    let urlkeysLocales = "keysLocalesPedidosOnline/" + origen3 + idLocal + "/";
    let urlkeysAutoaccept = "keysAutoAceptar/" + origen3 + idLocal + "/";

    mapUpdates[urlAdmin + "nombre"] = nombre;
    mapUpdates[urlkeysLocales2 + "nombre"] = nombre;
    mapUpdatesTiendaFS["nombre"] = nombre;

    mapUpdates[urlAdmin + "activado"] = activado ? true : false;
    mapUpdates[urlkeysLocales2 + "activado"] = activado ? true : false;
    mapUpdatesTiendaFS["activado"] = activado ? true : false;
    if (activado) {
      mapUpdates[urlkeysLocales] = s.keyPedidosOnline.key;
    } else {
      mapUpdates[urlkeysLocales] = null;
    }


    mapUpdates[urlAdmin + "autoaccept"] = autoaccept ? true : false;
    mapUpdates[urlkeysLocales2 + "autoaccept"] = autoaccept ? true : false;
    mapUpdatesTiendaFS["autoaccept"] = autoaccept ? true : false;
    if (autoaccept) {
      mapUpdates[urlkeysAutoaccept] = s.keyPedidosOnline.key;
    } else {
      mapUpdates[urlkeysAutoaccept] = null;
    }

    if (marca) {
      mapUpdates[urlAdmin + "marca"] = marca;
      mapUpdates[urlkeysLocales2 + "marca"] = marca;
      mapUpdatesTiendaFS["marca"] = marca;
    } else {
      mapUpdates[urlAdmin + "marca"] = null;
      mapUpdates[urlkeysLocales2 + "marca"] = null;
      mapUpdatesTiendaFS["marca"] = null;
    }

    if (fechaAlta) {
      mapUpdates[urlAdmin + "fechaAlta"] = fechaAlta;
      mapUpdates[urlkeysLocales2 + "fechaAlta"] = fechaAlta;
      mapUpdatesTiendaFS["fechaAlta"] = fechaAlta;
    } else {
      mapUpdates[urlAdmin + "fechaAlta"] = null;
      mapUpdates[urlkeysLocales2 + "fechaAlta"] = null;
      mapUpdatesTiendaFS["fechaAlta"] = null;
    }

    // if (tag) {
    //   mapUpdates[urlAdmin + "tag"] = tag;
    //   mapUpdates[urlkeysLocales2 + "tag"] = tag;
    //   mapUpdatesTiendaFS["tag"] = tag;
    // } else {
    //   mapUpdates[urlAdmin + "tag"] = null;
    //   mapUpdates[urlkeysLocales2 + "tag"] = null;
    //   mapUpdatesTiendaFS["tag"] = null;
    // }

    const concurrenciaEstado = true;
    mapUpdates[urlAdmin + "concurrenciaEstado"] = concurrenciaEstado;
    mapUpdates[urlkeysLocales2 + "concurrenciaEstado"] = concurrenciaEstado;
    mapUpdatesTiendaFS["concurrenciaEstado"] = concurrenciaEstado;


    mapUpdates[urlAdmin + "idStore"] = idLocal;
    mapUpdates[urlkeysLocales2 + "idStore"] = idLocal;
    mapUpdatesTiendaFS["idStore"] = idLocal;

    mapUpdates[urlAdmin + "origen"] = origen;
    mapUpdates[urlkeysLocales2 + "origen"] = origen;
    mapUpdatesTiendaFS["origen"] = origen;


    mapUpdates[urlAdmin + "plataforma"] = plataforma;
    mapUpdates[urlkeysLocales2 + "plataforma"] = plataforma;
    mapUpdatesTiendaFS["plataforma"] = plataforma;

    // if (keyHub && keyHub.trim().length > 0) {

    //   mapUpdates[urlAdmin + "keyHub"] = keyHub.trim();
    //   mapUpdates[urlkeysLocales2 + "keyHub"] = keyHub.trim();
    //   mapUpdatesTiendaFS["keyHub"] = keyHub.trim();
    //   mapUpdatesFS["keyHub"] = keyHub.trim();
    // }



    mapUpdates[urlkeysLocales2 + "keyLocal"] = s.keyPedidosOnline.key;
    //mapUpdates[urlkeysLocales2 + "versionManager"] = s.versionActual;
    //mapUpdates[urlkeysLocales2 + "versionManager"] = s.versionActual;

    try {
      await this.desOActivarEnSQL(true, mapUpdatesTiendaFS);

      await this.db.object("/").update(mapUpdates);
      let batch = this.dbFirestore.firestore.batch();
      let refLocal = this.dbFirestore.doc(urlConfLocal).ref;
      batch.set(refLocal, mapUpdatesFS, { merge: true });

      let refTienda = this.dbFirestore.doc(urlConfLocalTienda).ref;
      batch.set(refTienda, mapUpdatesTiendaFS, { merge: true });

      // if (keyHub.trim()) {
      //   let refTienda2 = this.dbFirestore.doc("confLocalPO/" + keyHub.trim() + "/tiendasId/" + origen + "," + idLocal + "/").ref;
      //   batch.set(refTienda2,
      //     {
      //       keyLocal: s.keyPedidosOnline.key,
      //       origen: mapUpdatesTiendaFS["origen"],
      //       idStore: mapUpdatesTiendaFS["idStore"],
      //       activado: activado ? true : false
      //     }
      //     , { merge: true });
      // }


      await batch.commit()
    } catch (e) {
      console.error("No se grabar la tienda", e, s);
      this.snackbar.open("Error, no se pudo grabar")._dismissAfter(4000);
    }
  }

  async configurar() {
    this.error = null;

    // if (this.activarSync && this.versionAAplicar) {
    //   this.error = "Version a aplicar incorrecta";
    //   return;
    // }

    for (const se of this.servidores) {
      if (se.keyACrear && se.resultadoBuscaKey !== this.MSJ_KEY_VALIDA) {
        this.error = "No todas las keys son válidas, revisar";
        return;
      } else {
        if ((!se.s.keyPedidosOnline && se.keyACrear)) {
          se.s.keyPedidosOnline = {
            key: se.keyACrear,
            // locales: null,
            // users: null,
          }
        } else {
          if (se.keyACrear) {
            se.s.keyPedidosOnline.key = se.keyACrear;
          }
        }
      }
    }

    this.loading = true;

    for (const se of this.servidores) {

      if (!se.error) {
        const s = se.s;
        const urlAdmin = "cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/";
        const map = {};
        if (this.activarSync) {
          await this.guardarTienda(s);
          map[urlAdmin + "fix"] = "UPDATE CONFIGURACIONPEDIDOSYA SET cantintentoscambioestado = '2';UPDATE TOGGLE SET activo=1 WHERE id=32;UPDATE TOGGLE SET activo=1 WHERE id=74;";
          // map[urlAdmin + "versionDisponible"] = this.versionAAplicar;
          map[urlAdmin + "syncManager"] = true;
        } else {
          let mapUpdatesFS = {};
          mapUpdatesFS["syncManager"] = false;
          await this.dbFirestore.doc("confLocalPO/" + s.keyPedidosOnline.key).set(mapUpdatesFS, { merge: true });

          map[urlAdmin + "fix"] = "UPDATE TOGGLE SET activo=0 WHERE id=32;UPDATE TOGGLE SET activo=0 WHERE id=74;";
          map[urlAdmin + "syncManager"] = false;

        }
        try {
          await this.db.object("/").update(map);
        } catch (e) {
          console.log("Error al configurar fix", s);
          this.snackbar.open("Error al configurar fix, revisa la consola(F12)")._dismissAfter(4000);
        }
      }
    }
    this.snackbar.open("Proceso terminado")._dismissAfter(4000);
    this.loading = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}