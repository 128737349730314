import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface ConfFactIngeFactura {
  activado: boolean;
  /** ingefactura */
  proveedor: string;

  numLocal: number;

  TipoImpresion: number;
  printPLATAFORMAeID: boolean;
  factCostoEnvio: boolean;
  Emisor: {
    RUTEmisor: string;
    CdgSIISucur?: number;
    RznSoc: string;
    Acteco: string;
    CiudadOrigen: string;
    CmnaOrigen: string;
    DirOrigen: string;
    GiroEmis: string;
  }
  facturaLastId?: number,
  token?: string;
  url?: string;
  //**Variable validar si el cliente tiene nota de credito o factura*/
  notaCredito: boolean;
  factura: boolean;
  //**Variable validar si el cliente tiene nota de credito o factura*/
}


@Component({
  selector: 'app-modal-conf-ingefactura',
  templateUrl: './modal-conf-ingefactura.component.html',
  styleUrls: ['./modal-conf-ingefactura.component.scss']
})
export class ModalConfIngefacturaComponent implements OnInit {

  confFactIngeFactura: ConfFactIngeFactura;
  s: ServidorAdmin;
  form: FormGroup;
  submitted = false;
  error: string;
  loading: boolean;
  escribirLastId: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ModalConfIngefacturaComponent>,
    public auth: AuthService,
    private cuentaService: CuentaService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin },
    private db: AngularFireDatabase,
    private dbFs: AngularFirestore,
    private snackbar: MatSnackBar,
    private fns: AngularFireFunctions,
    private formBuilder: FormBuilder,
  ) {
    this.s = this.data.s;
  }


  async ngOnInit() {
    this.loading = true;
    let v = await this.leerConfFact(this.s);

    this.confFactIngeFactura = v;
    this.initForm(v);

    console.log(this.confFactIngeFactura);
    this.loading = false;
  }

  async leerConfFact(s: ServidorAdmin) {

    let confFact: ConfFactIngeFactura = {
      activado: false,
      proveedor: "ingefactura",
      TipoImpresion: 2,
      printPLATAFORMAeID: true,
      factCostoEnvio: false,
      numLocal: 0,
      Emisor: {

        RUTEmisor: "",
        RznSoc: "",
        Acteco: "",
        CiudadOrigen: "",
        CmnaOrigen: "",
        DirOrigen: "",
        GiroEmis: ""
      },
      facturaLastId: 0,
      token: "",
      url: "",
      notaCredito: null,
      factura: null
    };
    if (s.rut) {
      confFact.Emisor.RUTEmisor = s.rut;
    }
    if (s.keyPedidosOnline && s.keyPedidosOnline.key) {
      let doc = await this.dbFs.doc("confLocalPO/" + s.keyPedidosOnline.key).get().toPromise();
      let factElect = <ConfFactIngeFactura>(doc.data() ? doc.data()["factElect"] : null);
      if (factElect) {
        confFact = factElect;

        if (factElect.facturaLastId || factElect.numLocal) {
          //delete confFact.facturaLastId;
          this.escribirLastId = false;
        } //else {
        //this.escribirLastId = true;

        //}
      }
    } else {
      this.snackbar.open("No hay keyLocalPOnline, configurar una key en POnline", "")._dismissAfter(3000);
    }

    return confFact;
  }

  initForm(v: ConfFactIngeFactura) {
    this.form = this.formBuilder.group({
      activado: [this.confFactIngeFactura.activado, []],
      proveedor: [this.confFactIngeFactura.proveedor, [Validators.required]],
      token: [this.confFactIngeFactura.token, [Validators.required, Validators.minLength(10)]],
      url: [this.confFactIngeFactura.url, [Validators.required, Validators.minLength(10)]],
      //**constantes para validar si el cliente tiene nota de credito o factura*/
      notaCredito: [this.confFactIngeFactura.notaCredito, [Validators.required]],
      factura: [this.confFactIngeFactura.factura, [Validators.required]],
      //**constantes para validar si el cliente tiene nota de credito o factura*/
      RUTEmisor: [this.confFactIngeFactura.Emisor.RUTEmisor, [Validators.required, Validators.minLength(4)]],
      RznSoc: [this.confFactIngeFactura.Emisor.RznSoc, [Validators.required]],
      Acteco: [this.confFactIngeFactura.Emisor.Acteco, [Validators.required]],
      CiudadOrigen: [this.confFactIngeFactura.Emisor.CiudadOrigen, [Validators.required]],
      CmnaOrigen: [this.confFactIngeFactura.Emisor.CmnaOrigen, [Validators.required]],
      DirOrigen: [this.confFactIngeFactura.Emisor.DirOrigen, [Validators.required]],
      GiroEmis: [this.confFactIngeFactura.Emisor.GiroEmis, [Validators.required]],
      // numLocal: [this.confFactIngeFactura.numLocal ? this.confFactIngeFactura.numLocal : 1, [Validators.required]],
      printPLATAFORMAeID: [this.confFactIngeFactura.printPLATAFORMAeID ? this.confFactIngeFactura.printPLATAFORMAeID : true, []],
      factCostoEnvio: [this.confFactIngeFactura.factCostoEnvio ? this.confFactIngeFactura.factCostoEnvio : false, []],
      TipoImpresion: [this.confFactIngeFactura.TipoImpresion ? this.confFactIngeFactura.TipoImpresion : 2, [Validators.required]],
      CdgSIISucur: [this.confFactIngeFactura.Emisor.CdgSIISucur ? this.confFactIngeFactura.Emisor.CdgSIISucur : "", []],
    });
  }


  async guardarDatos() {
    this.submitted = true;
    this.error = null;

    if (this.form.invalid) {
      this.error = "Datos inválidos";
      return;
    }


    let urlAdmin = "cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/factElect/";
    let urlConfLocalTienda = "confLocalPO/" + this.s.keyPedidosOnline.key;
    let mapUpdates = {};
    let factElectFS = {};


    const activado = this.form.controls.activado.value ? true : false;
    const proveedor = this.form.controls.proveedor.value.trim();
    // const numLocal = +this.form.controls.numLocal.value;
    const token = this.form.controls.token.value.trim();
    const url = this.form.controls.url.value.trim();
    const RUTEmisor = this.form.controls.RUTEmisor.value.trim();
    //**constantes para validar si el cliente tiene nota de credito o factura*/
    const notaCredito: boolean = this.form.controls.notaCredito.value === "true" ? true : false;
    const factura: boolean = this.form.controls.factura.value === "true" ? true : false;
    //**constantes para validar si el cliente tiene nota de credito o factura*/

    const RznSoc = this.form.controls.RznSoc.value.trim();
    const Acteco = this.form.controls.Acteco.value.trim();
    const CiudadOrigen = this.form.controls.CiudadOrigen.value.trim();
    const CmnaOrigen = this.form.controls.CmnaOrigen.value.trim();
    const DirOrigen = this.form.controls.DirOrigen.value.trim();
    const GiroEmis = this.form.controls.GiroEmis.value.trim();

    const printPLATAFORMAeID = this.form.controls.printPLATAFORMAeID.value ? true : false;
    const factCostoEnvio = this.form.controls.factCostoEnvio.value ? true : false;
    const TipoImpresion = +this.form.controls.TipoImpresion.value;
    const CdgSIISucur = (this.form.controls.CdgSIISucur.value && +this.form.controls.CdgSIISucur.value.length > 0) ? undefined : +this.form.controls.CdgSIISucur.value;



    let Emisor: ConfFactIngeFactura["Emisor"] = {
      RUTEmisor: RUTEmisor,
      RznSoc: RznSoc,
      Acteco: Acteco,
      CiudadOrigen: CiudadOrigen,
      CmnaOrigen: CmnaOrigen,
      DirOrigen: DirOrigen,
      GiroEmis: GiroEmis
    }

    if (CdgSIISucur) {
      Emisor.CdgSIISucur = CdgSIISucur;
    }


    mapUpdates[urlAdmin + "Emisor"] = Emisor;
    mapUpdates[urlAdmin + "activado"] = activado;
    mapUpdates[urlAdmin + "printPLATAFORMAeID"] = printPLATAFORMAeID;
    mapUpdates[urlAdmin + "factCostoEnvio"] = factCostoEnvio;
    mapUpdates[urlAdmin + "TipoImpresion"] = TipoImpresion;
    mapUpdates[urlAdmin + "proveedor"] = proveedor;
    mapUpdates[urlAdmin + "token"] = token;
    mapUpdates[urlAdmin + "url"] = url;
    mapUpdates[urlAdmin + "factura"] = factura;
    mapUpdates[urlAdmin + "notaCredito"] = notaCredito;

    let urlRut = "cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/rut/";
    if (activado) {
      mapUpdates[urlRut] = RUTEmisor;
    } //else {
    // mapUpdates[urlRut] = "0";
    //}

    factElectFS["Emisor"] = Emisor;
    factElectFS["activado"] = activado;
    factElectFS["printPLATAFORMAeID"] = printPLATAFORMAeID;
    if (factCostoEnvio) {
      factElectFS["factCostoEnvio"] = ["jus"];
    } else {
      factElectFS["factCostoEnvio"] = null;
    }
    factElectFS["TipoImpresion"] = TipoImpresion;
    factElectFS["numLocal"] = this.confFactIngeFactura.numLocal;
    factElectFS["proveedor"] = proveedor;
    factElectFS["token"] = token;
    factElectFS["url"] = url;
    factElectFS["factura"] = factura;
    factElectFS["notaCredito"] = notaCredito;


    if (this.escribirLastId) {
      if (this.confFactIngeFactura.numLocal) {
        this.error = "Ya tiene numLocal";
        return;
      } else {
        const callable = this.fns.httpsCallable("numeroLocalIngefactura");
        callable({ rut: RUTEmisor }).pipe(first()).subscribe(numLocal => {
          if (numLocal > 0) {
            let mult = (numLocal - 1) * 10000000;
            factElectFS["facturaLastId"] = mult;
            factElectFS["numLocal"] = numLocal;
            let objFS = { factElect: factElectFS };
            this.grabarEnFirebase(objFS, mapUpdates, urlConfLocalTienda);

          } else {
            this.error = "ERROR numLocal debe ser > 0";
            return;
          }
        });
      }
    } else if (!this.confFactIngeFactura.numLocal) {
      if (this.confFactIngeFactura.facturaLastId && this.confFactIngeFactura.facturaLastId > 0) {
        const cantidad = +(this.confFactIngeFactura.facturaLastId / 10000000).toFixed() + 1;
        factElectFS["numLocal"] = cantidad;
        let objFS = { factElect: factElectFS };
        this.grabarEnFirebase(objFS, mapUpdates, urlConfLocalTienda);
      } else {
        this.error = "ERROR no tiene numLocal";
        return;
      }
    } else {
      let objFS = { factElect: factElectFS };
      this.grabarEnFirebase(objFS, mapUpdates, urlConfLocalTienda);
    }

  }

  async grabarEnFirebase(objFS: { factElect: {}; }, mapUpdates: {}, urlConfLocalTienda: string) {
    // subimos el formulario 
    try {
      let refTienda = this.dbFs.doc(urlConfLocalTienda).ref;
      await refTienda.set(objFS, { merge: true });

      this.db.object("/").update(mapUpdates).then(ok => {
        this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
        this.dialogRef.close();
      }).catch(e => {
        console.error("No se pudo grabar la tienda en admin", e);
        this.snackbar.open("Error, no se pudo grabar en admin")._dismissAfter(2000);
      });
    } catch (e) {
      console.error("Error al grabar en FS", e);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async datosSii() {
    const RUTEmisor = this.form.controls.RUTEmisor.value;
    let urlProd = environment.urls.escuchador + "/";
    //let urlProd = "http://localhost:3000/";
    let datos = await this.cuentaService.getUrlProm(urlProd + "factElect/contribuyentes/info/" + RUTEmisor);
    console.log("Datos para RUT: " + RUTEmisor, datos.json()["name"]);

  }


}