import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionCuentasComponent } from './pages/gestion-cuentas/gestion-cuentas.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalEditCuentaComponent } from './pages/gestion-cuentas/modal-edit-cuenta/modal-edit-cuenta.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GestionServidoresComponent } from './pages/gestion-servidores/gestion-servidores.component';
import { ModalEditServidorComponent } from './pages/gestion-servidores/modal-edit-local/modal-edit-servidor.component';
import { InputFormComponent } from './common/input-form/input-form.component';
import { SelectFormComponent } from './common/select-form/select-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GestionLocacionesComponent } from './pages/gestion-locaciones/gestion-locaciones.component';
import { ModalEditLocacionComponent } from './pages/gestion-locaciones/modal-edit-locacion/modal-edit-locacion.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SelectorFechaComponent } from './common/selector-fecha/selector-fecha.component';
import { MatSortModule } from '@angular/material/sort';




@NgModule({
  declarations: [
    GestionCuentasComponent,
    ModalEditCuentaComponent,
    GestionServidoresComponent,
    ModalEditServidorComponent,
    InputFormComponent,
    SelectFormComponent,
    GestionLocacionesComponent,
    ModalEditLocacionComponent,
    SelectorFechaComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgbModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    NgSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatSortModule
  ]
})
export class Admin2Module { }
