
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DatosTexto {
  titulo: string;
  textos: string[];
}
@Component({
  selector: 'app-modal-texto',
  templateUrl: './modal-texto.component.html',
  styleUrls: ['./modal-texto.component.scss']
})
export class ModalTextoComponent implements OnInit {
  errorReset: string;
  correo: string;
  passReseteado: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalTextoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DatosTexto
  ) { }

  ngOnInit() {
  }

}



