import { Component, OnInit, OnDestroy } from '@angular/core';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { PieChartConfig } from 'src/app/charts/pieconfig';
import { Subscription } from 'rxjs';
import { LineChartConfig } from 'src/app/charts/linechartconfig';
import { environment } from 'src/environments/environment';

export interface Persona {
  id: string;
  name: string;

  totalPuntosWIP: number;
  totalPuntosDONE: number;

  estados: Map<string, {
    nombre: string,
    total: number,
  }>;
  proyectos: Map<string, {
    nombre: string,
    total: number,
  }>;

  data1: any[];
  config1: PieChartConfig;
  elementId1: string;
  pieTrueBarFalse1: boolean;

  data2: any[];
  config2: PieChartConfig;
  elementId2: string;
  pieTrueBarFalse2: boolean;
}
export interface Historia {
  key: string;

  Asignada: { email: string, id: string, name: string }[];
  Estado: string;
  Size: number;
  "Criterio de aceptacion": string;
  "Fecha creacion": string;
  "Fecha DONE": string;
  "Fecha WIP": string;
  Prioridad: number;
  Proyecto: string;
  Titulo: string;
}

@Component({
  selector: 'app-admin-historias',
  templateUrl: './admin-historias.component.html',
  styleUrls: ['./admin-historias.component.scss']
})
export class AdminHistoriasComponent implements OnInit, OnDestroy {
  driftVar: any;
  historias: Map<string, Historia>;
  personas: Map<string, Persona>;
  _MS_PER_DAY = 1000 * 60 * 60 * 24;
  dias: number;
  subs: Subscription;
  totalTdx: number;
  totalDone: number;
  fechaDesde: Date;
  fechaHasta: Date;
  fechaHastaComparar: string;

  histograma: boolean = false;
  data4: any[];
  config4: LineChartConfig;
  elementId4: string;


  constructor(private cuentaService: CuentaService, private db: AngularFireDatabase) { }

  ngOnInit() {
    this.hideDrift(1);
  }

  ngOnDestroy() {
    if (this.subs) {
      try { this.subs.unsubscribe() } catch (e) { }
    }
  }

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  prom(total) {
    if (this.dias) {
      let promed = Math.floor(total / this.dias);
      return "" + promed + " " + Math.floor((100 * promed / (this.totalTdx / this.dias))) + "%";
    } else {
      return 0;
    }
  }
  importarHistorias() {
    let si = confirm("Revisaste el estado de La vista NO TOCAR de Historias en Airtable?");
    if (si) {
      this.cuentaService.getUrlProm(environment.urls.functions + "/api/agilidad/updateHistorias").then(v => {
        console.log("exito", v)
      }).catch(e => {
        console.error("error al leerr ", e)
      })
    }
  }
  addDays(date: Date, days): Date {
    let dateAux = new Date(date);
    dateAux.setDate(dateAux.getDate() + days);
    return dateAux;
  }

  getDates(startDate: Date, stopDate: Date): Date[] {
    let dateArray = [];
    startDate.setHours(startDate.getHours() + 3);
    stopDate.setHours(stopDate.getHours() + 3);
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = this.addDays(currentDate, 1);
    }
    return dateArray;
  }

  fechaString(d: Date): string {
    let m = (d.getMonth() + 1);
    let mes = m > 9 ? "-" + m : "-0" + m;
    let dia = d.getDate() > 9 ? "-" + d.getDate() : "-0" + d.getDate();
    return d.getFullYear() + mes + dia;
  }
  dibujaChartComparativo(p: Persona) {

    this.data4 = [];

    let diasss = this.getDates(this.fechaDesde, this.fechaHasta);

    let mapDias = new Map<string, string>();
    console.log(diasss)
    for (let d of diasss) {
      let daa = this.fechaString(d);
      mapDias.set(daa, daa);
    }


    let lineas: string[] = ['Tipo', "TODO", "DONE"] //, "SOPORTE", "BUG", "AGILIDAD", "MEJORA"] //, "Resolved", "Closed", "Open", "Pending", "Total"];
    let colores: string[] = ["#dc3912", "#109618"];

    this.data4.push(lineas);
    let totalFechaWIP = 0;
    let totalFechaDONE = 0;
    for (let date of mapDias.values()) {
      let valores: any[] = [date];

      this.historias.forEach((v) => {
        let cantWIP = 0
        if (v["Fecha WIP"] === date) {
          cantWIP = v.Size
          totalFechaWIP += cantWIP
        }

        let cantDONE = 0
        if (v["Fecha DONE"] && v["Fecha DONE"] === date) {
          cantDONE = v.Size
        }
        totalFechaDONE += cantDONE
        totalFechaWIP -= cantDONE
      })
      valores.push(totalFechaWIP);
      valores.push(totalFechaDONE);
      this.data4.push(valores);
    }


    this.config4 = new LineChartConfig('Historico', '', colores);
    this.elementId4 = 'myPieChart4';
  }

  async consultar(datos: { fechaDesde: string, fechaHasta: string, buscando: boolean }) {
    this.fechaDesde = new Date(datos.fechaDesde);
    this.fechaHasta = new Date(datos.fechaHasta);
    this.historias = new Map<string, Historia>();
    this.fechaHastaComparar = datos.fechaHasta;
    this.dias = 1 + this.dateDiffInDays(new Date(datos.fechaDesde), new Date(datos.fechaHasta));
    let a = this.db.list("agilidad/historias", ref => ref.orderByChild("Fecha WIP")
      .startAt(datos.fechaDesde)
      .endAt(datos.fechaHasta + "~"));

    let his = await a.query.once("value").then(v => {
      return v.val();
    });


    let historias2 = new Map<string, Historia>();
    for (let hKey in his) {

      let h = his[hKey];
      historias2.set(hKey, <Historia>h);
    }

    this.procesaHistorias(historias2);

    this.historias = historias2;
    this.dibujaChartComparativo(this.personas.get("TODXS"));
    this.histograma = true;
  }

  getTotal(p: Persona): number {
    let total = 0;
    if (p.estados) {
      for (let t of p.estados.values()) {
        total += t.total;
      }
    }
    return total;
  }

  getTotal2(p: Persona): number {
    let total = 0;
    if (p.proyectos) {
      for (let t of p.proyectos.values()) {
        total += t.total;
      }
    }
    return total;
  }

  procesaHistorias(historias: Map<string, Historia>) {
    let personas = new Map<string, Persona>();
    let tiposa = new Map<string, { nombre: string, total: number }>();
    let tagsx = new Map<string, { nombre: string, total: number }>();
    let pc = new PieChartConfig('Estado', 0.4);
    let pc2 = new PieChartConfig('Proyecto', 0, []);
    let p = {
      id: "TODXS",
      name: "TODXS",
      totalPuntosWIP: 0,
      totalPuntosDONE: 0,
      estados: tiposa,
      proyectos: tagsx,
      data1: [],
      config1: pc,
      elementId1: 'myPieChart1todxs',
      pieTrueBarFalse1: true,


      data2: [],
      config2: pc2,
      elementId2: 'myPieChart2todxs',
      pieTrueBarFalse2: true,
    }
    personas.set("TODXS", p);

    let tiposdone = new Map<string, { nombre: string, total: number }>();
    let tagsdone = new Map<string, { nombre: string, total: number }>();
    let pcdone = new PieChartConfig('Estado', 0.4);
    let pc2done = new PieChartConfig('Proyecto', 0, []);
    let pdone = {
      id: "DONE",
      name: "DONE",
      totalPuntosWIP: 0,
      totalPuntosDONE: 0,
      estados: tiposdone,
      proyectos: tagsdone,
      data1: [],
      config1: pcdone,
      elementId1: 'myPieChart1done',
      pieTrueBarFalse1: true,


      data2: [],
      config2: pc2done,
      elementId2: 'myPieChart2done',
      pieTrueBarFalse2: true,
    }
    personas.set("DONE", pdone);

    historias.forEach((v) => {
      for (let a in v.Asignada) {
        let tiposx = new Map<string, { nombre: string, total: number }>();
        let tagsx = new Map<string, { nombre: string, total: number }>();
        let pc = new PieChartConfig('Estado', 0.4);
        let pc2 = new PieChartConfig('Proyecto', 0, []);
        let p = {
          id: v.Asignada[a].id,
          name: v.Asignada[a].name,
          totalPuntosWIP: 0,
          totalPuntosDONE: 0,
          estados: tiposx,
          proyectos: tagsx,
          data1: [],
          config1: pc,
          elementId1: 'myPieChart1' + v.Asignada[a].id,
          pieTrueBarFalse1: true,

          data2: [],
          config2: pc2,
          elementId2: 'myPieChart2' + v.Asignada[a].id,
          pieTrueBarFalse2: true,
        };
        personas.set(v.Asignada[a].id, p);
      }
    });


    for (let v of historias.values()) {
      if (v["Fecha WIP"]) {

        let divisor = v.Asignada.length ? 1 : v.Asignada.length;
        let size = v.Size / divisor;
        let sizeConjunto = v.Size;
        /* let arrep: Persona[] = []; para sacar estadisticas por persona
 
         for (let a in v.Asignada) {
           arrep.push(personas.get(v.Asignada[a].id));
         }
 
         for (let p of arrep) {
           this.actualizaValores(p.estados, v, size, "Estado");
           if (v.Proyecto) {
             this.actualizaValores(p.proyectos, v, size, "Proyecto");
           }
         }*/
        let ptdxs = personas.get("TODXS");
        this.actualizaValores(ptdxs.estados, v, sizeConjunto, "Estado");
        if (v.Proyecto) {
          this.actualizaValores(p.proyectos, v, sizeConjunto, "Proyecto");
        }

        if (v["Fecha DONE"] && v["Fecha DONE"] <= this.fechaHastaComparar) {
          let pdone = personas.get("DONE");
          this.actualizaValores(pdone.estados, v, sizeConjunto, "Estado");
          if (v.Proyecto) {
            this.actualizaValores(p.proyectos, v, sizeConjunto, "Proyecto");
          }
        }

      }
    }

    for (let p of personas.values()) {
      this.dibujaCharts(p);
    }
    this.totalTdx = this.getTotal(personas.get("TODXS"));
    this.totalDone = this.getTotal(personas.get("DONE"));
    this.personas = personas
    console.log(personas);
  }

  actualizaValores(
    coleccion: Map<string, {
      nombre: string;
      total: number;
    }>,
    v: Historia,
    size: number,
    atributo: string) {

    let arre = v[atributo];
    if (!Array.isArray(arre)) {
      arre = [arre];
    }

    for (let tagOTipo of arre) {
      if (coleccion.get(tagOTipo)) {
        let nuevoTotal = coleccion.get(tagOTipo).total + size;
        coleccion.set(tagOTipo, { nombre: tagOTipo, total: nuevoTotal })
      } else {
        coleccion.set(tagOTipo, { nombre: tagOTipo, total: size })
      }
    }
  }

  dibujaCharts(p: Persona) {
    if (p.estados && p.estados.size > 0) {
      //Piechart1 Data & Config
      //Mesas
      p.data1 = [];
      p.data1.push(['Estado', 'Puntos']);


      p.estados.forEach((v) => {
        p.data1.push([v.nombre, v.total])
      });

      // p.config1 = new PieChartConfig('Tipo', 0.4);
      //  p.elementId1 = 'myPieChart1';
      // p.pieTrueBarFalse1 = true;

    }

    if (p.proyectos && p.proyectos.size > 0) {
      p.data2 = [];
      p.data2.push(['Proyecto', 'Puntos']);

      let colores = [];
      let tags = [];
      p.proyectos.forEach((v) => {
        if (!tags.includes(v.nombre)) {
          p.data2.push([v.nombre, v.total])
          colores.push(this.colorPorTag(v.nombre));
        }
      });

      p.config2 = new PieChartConfig('Proyecto', 0);
      //TODO:p.config2 = new PieChartConfig('Proyecto', 0, colores);
      // p.elementId1 = 'myPieChart1';
      // p.pieTrueBarFalse1 = true;

    }
  }

  colorPorTag(tag: string): string {
    const verde = "#109618";
    const violeta = "#990099";
    const rojo = "#dc3912";
    const amarillo = "#ff9900";
    const gris = "#888777";
    const celeste = "#990099";
    const azul = "#3366cc";

    switch (tag) {
      case "MEJORA":
      case "PLANING":
      case "PRE REFINEMENT":
      case "TUTORIAL":
        return verde;

      case "AGILIDAD":
      case "METRICAS":
      case "PRE REUNION":
      case "REUNION":
        return amarillo;

      case "BUG":
        return rojo;

      case "SOPORTE":
        return azul;
      case "OTRO":
      case "DEPLOY":
        return gris;

      case "Total":
        return celeste;

      default:
        return violeta;
    }
  }

  // a and b are javascript Date objects
  dateDiffInDays(a: Date, b: Date) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
  }
}
