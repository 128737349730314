import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RutaService {
  // Observable navItem source
  private _ruta = new BehaviorSubject<string>(null);
  // Observable navItem stream
  ruta$ = this._ruta.asObservable();
  // service command
  
  changeRuta(ruta: string) {
    this._ruta.next(ruta);
  }
  
  constructor() { }
}
