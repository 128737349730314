/**
 * cada valor de Firebase esta compuesto por una url + una key y un valor al cual con un PUT apuntan
 */
export class ValorFirebase {
    
    key: string;
    
    /**
     * la direccion de firebase en la que se aplica la rama, termina en / y este campo más la key es a lo que se aplica el valor con el metodo PUT
     */
    url: string;
    
     
    valor: any;
}