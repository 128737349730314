
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from "../core/auth.service";
import { Cuenta } from '../shared/cuenta';
import { StockPOJO, Stock } from '../shared/stock';
import { User } from '../shared/user';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComidaCentral, CartaCentalPOJO as CartaCentalPOJO, ComidaPOJO } from '../shared/stockcentral';
import { IngredientePOJO, IngredCentral } from '../shared/stockCentral/ingredientecentral';
import { FraccionCentral } from '../shared/stockCentral/fraccioncentral';
import { MatDialog } from '@angular/material/dialog';
import { ModalAlertaStockComponent } from '../modales/modal-alerta-stock/modal-alerta-stock.component';
import { AngularFireDatabase } from '@angular/fire/database';
import { MediaMatcher } from '@angular/cdk/layout';
import * as consts from "../shared/constantes";

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})

export class ProductosComponent implements OnInit, OnDestroy {

  subscriptionCuenta: Subscription;
  cuenta: Cuenta;
  idCuentaSelected: string = "";
  productos: Stock[];
  prodsCentralizados: ComidaCentral[];
  colores: string[];
  colorCategoria: { idCat: string, color: string }[];
  fecha: string;
  dataSource: MatTableDataSource<Stock>;
  dataSourceCentral: MatTableDataSource<ComidaCentral>;
  displayedColumns: string[] = ['color', 'categoria', 'nombre', 'precio'];
  displayedColumns2: string[] = [];
  errorAlLeerDatos: boolean = false

  subscriptionUsuario: Subscription;
  subscriptionStockNormal: Subscription;
  subscriptionCentralizado: Subscription;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) sortCentral: MatSort;

  macsConEmailDeUsuario: Map<string, string>;
  medidas: any
  filtroActual: string = "gamrgamr";
  filtroActual2: string = "";
  ultimoSubidoStock
  ingredienteFiltrado: ComidaCentral;
  filtroAlerta: boolean
  filtroNegativo: boolean

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  user: User;

  constructor(public authService: AuthService, public dialog: MatDialog, public db: AngularFireDatabase, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private _snackbar: MatSnackBar) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }



  obtieneColumnasAMostrar() {
    this.displayedColumns2 = this.displayedColumns.slice(0, 4);
  }

  agregaProducto(producto: ComidaCentral, agregar: boolean) {
    if (agregar) {
      let updates = {};
      this.macsConEmailDeUsuario.forEach((v, mac) => {
        for (let f of producto.fracciones) {
          updates[mac + "/prods/" + f.keyfb + "/categoria"] = producto.categoria;
          updates[mac + "/prods/" + f.keyfb + "/esCentral"] = true;
          updates[mac + "/prods/" + f.keyfb + "/idProductoStock"] = producto.idkey;
          updates[mac + "/prods/" + f.keyfb + "/nombre"] = producto.nombre + (f.nombre.length > 0 ? f.nombre : "");
          updates[mac + "/prods/" + f.keyfb + "/precio"] = f.precio;
        }
      })

      this.db.object("productos").update(updates);
    } else {
      let updates = {};
      this.macsConEmailDeUsuario.forEach((v, mac) => {
        for (let f of producto.fracciones) {
          updates[mac + "/prods/" + f.keyfb + "/categoria"] = producto.categoria;
          updates[mac + "/prods/" + f.keyfb + "/esCentral"] = true;
          updates[mac + "/prods/" + f.keyfb + "/idProductoStock"] = producto.idkey;
          updates[mac + "/prods/" + f.keyfb + "/nombre"] = producto.nombre + (f.nombre.length > 0 ? f.nombre : "");
          updates[mac + "/prods/" + f.keyfb + "/precio"] = f.precio;
        }
      })

      this.db.object("productos").update(updates);

    }
  }
  email: string;
  ngOnInit() {
    this.authService.user.subscribe(user => {
      if (user && user.email) {
        this.user = user;
        // console.log("SUSCRIPTO");
        this.email = user.email;
        this.subscriptionCuenta = this.authService.cuenta$
          .subscribe(cuenta => {
            if (cuenta) {
              this.cuenta = cuenta;
              if (this.idCuentaSelected != this.cuenta.id) {
                this.idCuentaSelected = this.cuenta.id;

                this.siTieneCentralizadoLeerCentralizadoSinoNormal(this.cuenta)
              }
            }
          });
      }
    });

    this.obtieneColumnasAMostrar();
  }

  fechaDesdeAhora(millis: number) {
    let date = new Date();
    let now = date.getTime();

    let resta = now - millis;
    let segs = resta / 1000;
    let min = segs / 60;

    if (min < 0) {
      //no deberia pasar
    } else if (min >= 60) {
      //horas, dias, meses
      let horas = min / 60
      if (horas < 12) {
        if (horas.toFixed(0) == "1")
          return "Hace " + horas.toFixed(0) + " hora"
        else
          return "Hace " + horas.toFixed(0) + " horas"
      }
    } else {
      if (min < 2) {
        return "Hace instantes"
      } else
        return "Hace " + min.toFixed(0) + " minutos"
    }

    date.setTime(millis);
    return date.toLocaleDateString();

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);

    try { this.subscriptionUsuario.unsubscribe() } catch (e) { }
    try { this.subscriptionStockNormal.unsubscribe() } catch (e) { }
    try { this.subscriptionCentralizado.unsubscribe() } catch (e) { }
    try { this.subscriptionCuenta.unsubscribe() } catch (e) { }
  }

  siTieneCentralizadoLeerCentralizadoSinoNormal(cuenta: Cuenta) {

    //leerUsuario para ver si tiene stockCentralizado y keyCarta o carta y mails
    let refUsuario = this.authService.leerFB("usuarios/" + cuenta.id);

    this.subscriptionUsuario = refUsuario.snapshotChanges().subscribe(action => {
      let usuario = action.payload.val()
      if (usuario && usuario["stockCentralizado"]) {
        try { this.subscriptionStockNormal.unsubscribe() } catch (e) { }
        this.productos = null;
        this.dataSource = new MatTableDataSource(this.productos);
        this.dataSource.sort = this.sort;
        this.leerDatosStockCentral(cuenta, usuario);
      } else {
        try { this.subscriptionCentralizado.unsubscribe() } catch (e) { }
        this.prodsCentralizados = null;
        this.dataSourceCentral = new MatTableDataSource(this.prodsCentralizados);
        this.dataSourceCentral.sort = this.sortCentral;
        this.leerDatosStockNormal(cuenta);
      }
    });
  }

  reportarError(msj: string) {
    //TODO:
    //FIXME:
    throw "Sin implementar reportarError"
    /*if (this.cuenta) {
 
    } else {
 
    }*/
  }

  fechaMasViejaDeSubida(cartaCentral: CartaCentalPOJO) {
    //TODO:
    //FIXME:
    if (cartaCentral) {
      return "HOY"
    }
  }
  esAdmin(user: User) {
    return consts.esAdmin(user);
  }
  emailAdmin(): boolean {
    return this.esAdmin(this.user)
  }

  leerDatosStockCentral(cuenta: Cuenta, usuario: any) {
    if (usuario && usuario.carta && usuario.stockCentralizado && cuenta && cuenta.id) {
      this.errorAlLeerDatos = false;
    } else {
      this.errorAlLeerDatos = true;
      return;
    }

    this.macsConEmailDeUsuario = new Map<string, string>();
    this.macsConEmailDeUsuario.set(cuenta.id, cuenta.name);

    try {
      if (usuario.vinculadoCon) {
        for (let mac in usuario.vinculadoCon) {
          let userAux = usuario.vinculadoCon[mac];
          if (userAux && userAux.mails) {
            let mails: string[] = userAux.mails
            if (this.emailAdmin() || mails.find(x => x === this.email)) {
              this.macsConEmailDeUsuario.set(mac, userAux.nombre);
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
    let refStockCentral = this.authService.leerFB("cartas/" + usuario.carta);
    this.subscriptionCentralizado = refStockCentral.snapshotChanges().subscribe(action => {
      //consts.logD(action.type);
      //consts.logD(action.payload.val());
      let cartaCentral: CartaCentalPOJO = <CartaCentalPOJO>action.payload.val();
      if (cartaCentral) {
        this.ultimoSubidoStock = cartaCentral.ultimoSubidoStock;
        this.prodsCentralizados = [];
        this.colorCategoria = [];
        this.fecha = this.fechaMasViejaDeSubida(cartaCentral);
        this.medidas = cartaCentral.medidas;
        if (!this.medidas) {
          this.medidas = {
            key1: {
              idPAR: 1,
              keyfb: "key1",
              nombre: "Unidades"
            },
            key2: {
              idPAR: 2,
              keyfb: "key2",
              nombre: "Kilogramos"
            },
            key3: {
              idPAR: 3,
              keyfb: "key3",
              nombre: "Gramos"
            },
            key4: {
              idPAR: 4,
              keyfb: "key4",
              nombre: "Litros"
            },
            key5: {
              idPAR: 5,
              keyfb: "key5",
              nombre: "Mililitros"
            },
          }
        }

        //#region [rgba (202,20,10,0.1)] comidas 

        for (let idComida in cartaCentral.comidas) {
          let comida = <ComidaPOJO>cartaCentral.comidas[idComida]
          if (comida.eliminado) {
            continue;
          }
          let comidaCentral = new ComidaCentral();
          comidaCentral.idkey = idComida;
          comidaCentral.categoria = cartaCentral.categorias[comida.keyCategoria].nombre
          comidaCentral.nombre = comida.nombreComida;
          comidaCentral.keyCategoria = comida.keyCategoria;

          if (comida.alertaStock) {
            comidaCentral.alerta = true;
            comidaCentral.alertaCant = comida.stockAlerta;
          }

          if (comida.tieneIngredientes) {
            comidaCentral.tieneIngredientes = true;
          } else if (comida.esCombo) {
            comidaCentral.esCombo = true;
          }


          if (cartaCentral.activos && cartaCentral.activos[idComida]) {
            let activo = cartaCentral.activos[idComida];
            if (activo.activo) {
              for (let mac in activo.activo) {
                if (!activo.activo[mac]) {
                  if (!comidaCentral.desactivoEnMacs) {
                    comidaCentral.desactivoEnMacs = []
                  }
                  if (this.macsConEmailDeUsuario.get(mac))
                    comidaCentral.desactivoEnMacs.push(this.macsConEmailDeUsuario.get(mac));
                }
              }
            }
          }
          comidaCentral.ingredientes = [];
          if (comida.ingredientes) {
            for (let keyIngr in comida.ingredientes) {
              if (cartaCentral && cartaCentral.ingredientes && cartaCentral.ingredientes[keyIngr]) {
                let ingr: IngredientePOJO = cartaCentral.ingredientes[keyIngr];
                let ingredientecentral = <IngredCentral>ingr;
                if (cartaCentral.stocks && cartaCentral.stocks[ingr.keyfb]) {
                  ingredientecentral.stock = cartaCentral.stocks[ingr.keyfb];
                }
                comidaCentral.ingredientes.push(ingredientecentral);
              }
            }
          }

          comidaCentral.fracciones = [];
          if (comida.keysFracciones) {
            for (let keyFrac in comida.keysFracciones) {
              if (cartaCentral && cartaCentral.fracciones && cartaCentral.fracciones[keyFrac]) {
                let frCentral: FraccionCentral = cartaCentral.fracciones[keyFrac];
                if (comidaCentral.fracciones.length > 0) {
                  if (comidaCentral.fracciones.length === 1) {
                    comidaCentral.nombre += " " + comidaCentral.fracciones[0].nombre;
                  }
                  let newComidaCentralPorFraccion: ComidaCentral = new ComidaCentral();

                  newComidaCentralPorFraccion.idkey = idComida;
                  newComidaCentralPorFraccion.categoria = comidaCentral.categoria
                  newComidaCentralPorFraccion.nombre = comida.nombreComida + " " + frCentral.nombre;
                  newComidaCentralPorFraccion.keyCategoria = comida.keyCategoria;

                  if (comida.alertaStock) {
                    newComidaCentralPorFraccion.alerta = true;
                    newComidaCentralPorFraccion.alertaCant = comida.stockAlerta;
                  }

                  if (comida.tieneIngredientes) {
                    newComidaCentralPorFraccion.tieneIngredientes = true;
                  } else if (comida.esCombo) {
                    newComidaCentralPorFraccion.esCombo = true;
                  }

                  newComidaCentralPorFraccion.desactivoEnMacs = comidaCentral.desactivoEnMacs
                  newComidaCentralPorFraccion.ingredientes = comidaCentral.ingredientes
                  newComidaCentralPorFraccion.fracciones = [frCentral]
                  newComidaCentralPorFraccion.precio = frCentral.precio
                  this.prodsCentralizados.push(newComidaCentralPorFraccion)
                } else {
                  comidaCentral.precio = frCentral.precio;
                  comidaCentral.fracciones.push(frCentral);
                }
              }
            }
          }
          this.prodsCentralizados.push(comidaCentral)
        }
        //#endregion 

        this.colorCategoria = [];
        for (let idCat in cartaCentral.categorias) {
          let hue: string = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
          this.colorCategoria.push({ idCat: idCat, color: hue });
        }

        this.dataSourceCentral = new MatTableDataSource(this.prodsCentralizados);
        this.dataSourceCentral.filterPredicate = this.filtroDeStock();
        this.applyFilter(this.filtroActual2);
        this.dataSourceCentral.sort = this.sortCentral;
      }
    });
  }
  clickFiltroAlerta() {
    //this.filtroAlerta = true;
    //this.filtroNegativo = true;
    //this.applyFilter(this.filtroActual2)
    const dialogRef = this.dialog.open(ModalAlertaStockComponent, {
      width: '70%',
      data: {
        isAlertaActivada: this.filtroAlerta,
        isStockNegativo: this.filtroNegativo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.filtroNegativo = result.isStockNegativo;
      this.filtroAlerta = result.isAlertaActivada;
      this.applyFilter(this.filtroActual2);
    });
  }

  filtroDeStockNoCentral() {
    return (c: Stock, filter: string) => {
      let stringABuscar: string = "";
      if (filter === "gamrgamr") {
        stringABuscar += "gamrgamr";
      }
      stringABuscar += c.categoria + c.nombre + c.medida;
      return this.filtroDePalabras(stringABuscar, filter);
    }
  }
  filtroDeStock() {
    return (c: ComidaCentral, filter: string) => {
      let stringABuscar: string = "";
      if (filter === "gamrgamr") {
        stringABuscar += "gamrgamr";
      }
      stringABuscar += c.categoria + c.nombre + c.medida;
      if (this.ingredienteFiltrado) {
        if (c.esIngrediente) {
          return c.idkey === this.ingredienteFiltrado.idkey;
        } else {
          if (!!c.ingredientes.find(x => x.keyfb === this.ingredienteFiltrado.idkey)) {
            if (this.filtroAlertas(c)) {
              return this.filtroDePalabras(stringABuscar, filter)
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      } else {
        if (this.filtroAlertas(c)) {
          return this.filtroDePalabras(stringABuscar, filter);
        } else {
          return false;
        }
      }
    }
  }
  filtroAlertas(c: ComidaCentral) {
    if (this.filtroAlerta) {
      return c.alerta; //si es para ver si tiene la alerta activada
    } else {
      if (this.filtroNegativo) {
        let minimo = 999999999;

        this.macsConEmailDeUsuario.forEach((v, mac) => {
          let cantAProdAux = 999999999;
          if (c.fracciones) {
            cantAProdAux = 100 * c.fracciones[0].mapMacStock.get(mac).stock / c.fracciones[0].porcentaje;

          } else if (c.esIngrediente) {
            cantAProdAux = c.stock[mac];
          }
          if (minimo > cantAProdAux) {
            minimo = cantAProdAux;
          }
        });
        if (minimo < 0) {
          return true;
        } else if (c.alerta && minimo < c.alertaCant) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }


  filtroDePalabras(stringABuscar: string, filter: string) {
    if (filter && filter.length > 0) {
      return stringABuscar.trim().toLowerCase().indexOf(filter) != -1;
    } else {
      return true
    }
  }

  medida(ingr: IngredCentral) {
    let medida = "un.";
    if (this.medidas && ingr.idMedida && this.medidas["key" + ingr.idMedida] && this.medidas["key" + ingr.idMedida].nombre) {
      let medidaCompleta: string = this.medidas["key" + ingr.idMedida].nombre;
      medidaCompleta = medidaCompleta.toLowerCase()
      return medidaCompleta.charAt(0) + medidaCompleta.charAt(1) + "."
    }
  }

  irAlIngrediente(producto: ComidaCentral, ingr: any) {
    this.ingredienteFiltrado = this.prodsCentralizados.find(x => (x.esIngrediente && x.idkey === ingr.keyfb));
    this.applyFilter(this.filtroActual2);
  }

  filtroIngrediente(ingrediente: ComidaCentral) {
    this.ingredienteFiltrado = ingrediente;
    this.applyFilter(this.filtroActual2);
  }


  sacarFiltroIngr() {
    this.ingredienteFiltrado = undefined;

    this.applyFilter(this.filtroActual2)
  }

  leerDatosStockNormal(cuenta: Cuenta) {
    let refStockNormal = this.authService.leerFB("stock/" + cuenta.id);
    this.subscriptionStockNormal = refStockNormal.snapshotChanges().subscribe(action => {
      //consts.logD(action.type);
      //consts.logD(action.payload.val());
      let stock: StockPOJO = <StockPOJO>action.payload.val();
      if (stock) {
        this.productos = [];
        this.colores = [];
        this.fecha = stock.fecha;
        let numProductosConCat = stock.categorias.length;
        let numeroCategorias: number = 0;
        let categoriaActual: String = stock.categorias[0];
        for (let i = 0; i < stock.prodNombre.length; i++) {
          if (i < numProductosConCat) {
            if (stock.categorias[i] != categoriaActual) {
              categoriaActual = stock.categorias[i];
              numeroCategorias++;
            }
            this.productos[i] = new Stock(numeroCategorias, stock.prodNombre[i], stock.categorias[i], "Unidades", stock.prodStock[i], stock.alertas[i], stock.precios[i], false);
          } else {
            //this.productos[i] = new Stock(numeroCategorias + 1, stock.prodNombre[i], "Ingredientes", stock.medidas[i - numProductosConCat], stock.prodStock[i], stock.alertas[i], stock.precios[i], false);
          }
        }
        numeroCategorias++;
        for (let j = 0; j < numeroCategorias; j++) {
          let hue: string = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
          this.colores[j] = hue;
        }
        this.dataSource = new MatTableDataSource(this.productos);
        this.dataSource.filterPredicate = this.filtroDeStockNoCentral();
        this.applyFilter(this.filtroActual2);
        this.dataSource.sort = this.sort;
      }
    });
  }
  sortHeader() {
    if (this.productos)
      this.dataSource.sort = this.sort;
    else if (this.prodsCentralizados)
      this.dataSourceCentral.sort = this.sortCentral;

  }

  copiarTabla() {
    let selector;
    if (this.prodsCentralizados) {
      selector = '#tablaProdsCentralizados';
    } else if (this.productos) {
      selector = '#tablaProdsNormales';
    }
    let table = document.querySelector(selector);
    let range = document.createRange();
    range.selectNodeContents(table)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)

    document.execCommand('copy')

    this._snackbar.open("Se copió la tabla al portapapeles, pega los datos en un archivo de Excel o similar", "", {
      duration: 8000,
    });

  }

  applyFilter(filterValue: string) {
    this.filtroActual = filterValue;
    let filtro = filterValue.trim().toLowerCase();
    if (!filtro || filtro.length == 0) {
      filtro = "gamrgamr";
      this.filtroActual2 = "";
    } else {
      if (filtro === "gamrgamr") {
        this.filtroActual2 = "";
      } else {
        this.filtroActual2 = filtro;
      }
    }
    if (this.productos) {
      this.dataSource.filter = "gamrgamr";
      this.dataSource.filter = filtro;
    } else if (this.prodsCentralizados) {
      this.dataSourceCentral.filter = "gamrgamr";

      this.dataSourceCentral.filter = filtro;
      this.dataSourceCentral.sort = this.sort;
    }
  }

  obtieneColorCategoriaCentral(sc: ComidaCentral) {
    let myStyles = {
      'width': '4 px',
      'height': '4 px',
      'background': this.colorCategoria.find(x => x.idCat === sc.keyCategoria).color,
      'color': this.colorCategoria.find(x => x.idCat === sc.keyCategoria).color
    };
    return myStyles;
  }

  obtieneColorCategoria(indiceCategoria: number) {
    let myStyles = {
      'width': '4 px',
      'height': '4 px',
      'background': this.colores[indiceCategoria],
      'color': this.colores[indiceCategoria]
    };
    return myStyles;
  }

  colorRojo(producto: ComidaCentral, mac: string) {
    if (producto.esIngrediente) {
      if (producto.stock[mac] < 0) {
        return { 'color': "red" }
      }
      if (producto.alerta && producto.alertaCant >= producto.stock[mac]) {
        return { 'color': "red" }
      }
    } else if (producto.fracciones && producto.fracciones[0] && producto.fracciones[0].mapMacStock && producto.fracciones[0].mapMacStock.get(mac)) {
      let cant = producto.fracciones[0].porcentaje * producto.fracciones[0].mapMacStock.get(mac).stock / 100
      if (cant < 0) {
        return { 'color': "red" }
      }
      if (producto.alerta && producto.alertaCant >= cant) {
        return { 'color': "red" }
      }
    }

    return {}

  }



  obtieneColorCantidad(cantidad: number, alerta: number) {
    if (cantidad <= alerta) {
      return { 'color': "red" }
    }
    return {};
  }
}
