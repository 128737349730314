import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_OB } from '../core/const';
import Locacion from '../models/locacion';
import Servidor from '../models/servidor';

const BASE_URL = `${BASE_URL_OB}/v1/locaciones`;

@Injectable({
  providedIn: 'root'
})
export class LocacionesService {

  
  constructor(
    private http: HttpClient
  ) { }

  getLocaciones() {
    return this.http.get<{data: Locacion[]}>(BASE_URL);
  }

  postLocaciones(locacion: Locacion){
    return this.http.post<{data: Locacion[]}>(BASE_URL, locacion);
  }

  putLocaciones(locacion: Locacion){
    return this.http.put<{data: Locacion[]}>(BASE_URL, locacion);
  }

  obtenerServidoresDeLocacion(idLocacion: number) {
    return this.http.get<{msg: string, data:Servidor[]}>(`${BASE_URL_OB}/v1/locaciones/${idLocacion}/servidores`);
  }
}
