import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroComponent implements OnInit {
  @Input() titulo: string
  @Input() listaFiltros: Map<string, { name: string, activo: boolean }>;
  todosActivos: boolean
  cantidadFiltrados: number

  production = environment.production;

  @Output() filtroEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.actualizaTodosActivos();
  }



  activaDesactivaTodos() {
    for (let f of this.listaFiltros.values()) {
      if (this.todosActivos) {
        f.activo = true;
      } else {
        f.activo = false;
      }
    }
    this.actualizaTodosActivos();
  }

  actualizaTodosActivos() {
    let cantFiltros = 0;

    for (let f of this.listaFiltros.values()) {
      if (!f.activo) {
        cantFiltros++;
      }
    }
    if (cantFiltros > 0) {
      this.todosActivos = false;
    } else {
      this.todosActivos = true;
    }
    this.cantidadFiltrados = cantFiltros
    this.filtroEvent.emit("");

  }

  clickEnFiltro() {
    this.actualizaTodosActivos();
    this.filtroEvent.emit("");
  }



}
