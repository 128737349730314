
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { Http } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbFSService } from 'src/app/services/db-fs.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfigFactPopappChiComponent } from 'src/app/modales/modal-config-fact-popapp-chi/modal-config-fact-popapp-chi.component';
import { environment } from 'src/environments/environment';


/**
 * ejemplo
 * {
 *  asunto: "popapp ARG Pago Aprobado $3068 08/07/2021 06:42",
 *  cantMeses: 1,
 *  fecha: "2021-07-22T15:05:29.275Z",
 *  importe: 3068,
 *  iva: 21,
 *  nuevoVencimiento: "01/08/2021",
 *  pais: "ARG",
 *  text: "popapp ARG Pago Aprobado $3068 08/07/2021 06:42Neto Recibido: $2938.53Pago Lic. Popapp Clapton | Total: 3068.0000000000005 ARS | Datos de facturación: 33707514839  -  ksualsrl@gmail.com | 1 mes | Próximo vencimiento licencia: 01 Ago. 2021null nullksualsrl@gmail.comNueva Fecha: 01/08/2021",
 *  tipo: "Mercado Pago",
 *  to: "facundo@popapp.io, cmarin@popapp.io, pmarin@popapp.io, jcorona@popapp.io, gmarin@popapp.io, luciana@popapp.io, paula@popapp.io"
 * }
 */
export interface Pago {
  asunto: string;
  cantMeses: number;
  controlado?: boolean;
  deleted?: boolean;
  /** isoString del servidor */
  fecha: string;
  idCuenta: string;
  idPago: string;
  idFactura?: string;
  importe: number;
  iva: number;
  nuevoVencimiento: string;
  /**ARG, CHI, ... */
  pais: string;
  path: string;
  text: string;
  tipo: string;
  to: string;

  cuenta?: CuentaPopapp;
  factura?: FacturaPopapp;
}

export interface CuentaPopapp {
  cuitORut?: string;
  emailpago?: string;
  idClienteContabilium?: number;
  idCuenta: string;
  nombre: string;
  responsabilidad?: string;
  datosFacturacion?: {
    comuna: string;
    cuitORut: string;
    direccion: string;
    giro: string;
    mail: string;
    noFacturarAutomaticamente: boolean;
    razonSocial: string;
  }
}

export interface FacturaPopapp {
  body: any,
  fecha: string,
  fechaMes: string,
  idCuenta: string,
  idFactura: string,
  idPago: string,
  resultado: any,
  total: number,
}

@Component({
  selector: 'app-templatepagos',
  templateUrl: './templatepagos.component.html',
  styleUrls: ['./templatepagos.component.scss']
})
export class TemplatepagosComponent implements OnInit, OnDestroy {
  pagos: Pago[];
  buscandoPagos: boolean = true;

  cuentas: CuentaPopapp[];
  facturas: FacturaPopapp[];



  subscripciones: Subscription[] = [];
  cuenta2: CuentaPopapp;

  @Input() $query: BehaviorSubject<(ref: any) => any>;
  query: (ref: any) => any;
  @Input() pagosLimit: number;
  @Input() sinFacturar: boolean;
  @Input() idCuenta?: string;

  @Output() cambioLimite = new EventEmitter<number>();

  collapsed: boolean = false;

  constructor(
    private fs: AngularFirestore,
    private afAuth: AuthService,
    private http: Http,
    private _snackBar: MatSnackBar,
    private dbfs: DbFSService,
    private dbrd: AngularFireDatabase,
    private dialog: MatDialog,
  ) { }

  ngOnDestroy(): void {
    if (this.subscripciones) {
      for (let s of this.subscripciones) {
        try { s.unsubscribe() } catch (e) { }
      }
    }
  }



  async ngOnInit(): Promise<void> {
    // this.subscripciones.push(this.afAuth.user.subscribe(user => {
    //   if (user && user.email) {
    //   }
    // }));

    this.cuentas = [];
    this.facturas = [];

    this.$query.subscribe(query => {
      this.query = query;
      this.busquedaPagos(this.sinFacturar, query);
    });

  }

  cambiarLimite(pagosLimit: number) {
    this.cambioLimite.emit(+pagosLimit);
  }

  busquedaPagos(sinFacturar: boolean, query: ((ref: any) => any)) {
    this.buscandoPagos = true;
    this.pagos = [];

    if (this.subscripciones) {
      for (let s of this.subscripciones) {
        try { s.unsubscribe() } catch (e) { }
      }
    }

    if (this.idCuenta) {

      this.subscripciones.push(this.fs.collection("cuentas/" + this.idCuenta + "/pagos", query).get().subscribe(async v => {
        this.actualizaDatos(sinFacturar, v);
      }));
    } else {
      this.subscripciones.push(
        this.fs.collectionGroup("pagos", query).get().subscribe(async v => {
          this.actualizaDatos(sinFacturar, v);
        }));
    }
  }
  async actualizaDatos(sinFacturar: boolean, v: firebase.firestore.QuerySnapshot<unknown>) {

    this.pagos = [];

    if (v && v.docs) {
      for (const d of v.docs) {
        const pago: Pago = <Pago>d.data();
        pago.path = d.ref.path;
        pago.idPago = d.id;
        await this.actualizaCuentaYFactura(pago);
        if (sinFacturar) {
          if (!pago.deleted && !pago.controlado) {
            this.pagos.push(pago);

          }
        } else {
          this.pagos.push(pago);

        }
      }

      if (sinFacturar) {
        this.pagos.sort((a, b) => {
          if (a.fecha < b.fecha) {
            return 1;
          } else if (a.fecha === b.fecha) {
            return 0;
          } else {
            return -1;
          }
        })
      }
    }

    this.buscandoPagos = false;
  }

  async editarNombreDeCuenta(p: Pago) {
    if (p && p.idCuenta) {
      const nombreSnap = await this.dbrd.object('cuentas/' + p.idCuenta + '/nombreCuenta').query.once("value");
      let nombre = '';
      if (nombreSnap && nombreSnap.val()) {
        nombre += nombreSnap.val();
      }
      const valor = prompt('Nombre de cuenta', nombre);
      if (valor && valor.trim().length > 0) {
        await this.dbfs.updateAt("cuentas/" + p.idCuenta, { nombre: valor.trim(), idCuenta: p.idCuenta });
        p.cuenta = { nombre: valor.trim(), idCuenta: p.idCuenta };
      }
    }
  }
  async editar(campo: string, cuenta: CuentaPopapp) {
    const cambiar = "Cambiar ";
    let msj = cambiar;
    let def = "";
    const mapFs = {};
    const mapUpdates = {};
    if (campo === "emailpago") {
      msj += "email";
      if (cuenta.emailpago) {
        def = cuenta.emailpago
      }

    } else if (campo === "cuitORut") {
      msj += "CUIT";
      if (cuenta.cuitORut) {
        def = cuenta.cuitORut
      }
    } else {
      return;
    }

    const valor = prompt(msj, def);
    if (valor && valor.trim().length > 0) {
      const data = valor.trim();
      mapFs[campo] = data;
      mapUpdates["cuentas/" + cuenta.idCuenta + "/" + campo] = data;

      if (campo === 'cuitORut' && def.length > 0) {
        mapFs['idClienteContabilium'] = null;
        mapFs['responsabilidad'] = null;
      }
      await this.dbfs.updateAt("cuentas/" + cuenta.idCuenta, mapFs);
      await this.dbrd.object("/").update(mapUpdates);
      this.openSnackBar("Datos actualizados", "");
    } else {
      if (def.length > 0) {
        //preguntamos si quiere borrar
        const borrar = confirm(msj.replace(cambiar, "Borrar ") + "?");
        if (borrar) {
          mapFs[campo] = null;
          mapUpdates["cuentas/" + cuenta.idCuenta + "/" + campo] = null;
          await this.dbfs.updateAt("cuentas/" + cuenta.idCuenta, mapFs);
          await this.dbrd.object("/").update(mapUpdates);
          this.openSnackBar("Eliminado", "");
        }
      } else {
        //no preguntamos nada
      }
    }

  }

  pais(paisARGoCHIo: string) {
    if (paisARGoCHIo === "ARG") {
      return "🇦🇷";
    } else if (paisARGoCHIo === "CHI") {
      return "🇨🇱";
    } else if (paisARGoCHIo === "MXN") {
      return "🇲🇽";
    } else {
      return "🏳️🏴";
    }

  }

  log(p: Pago) {
    console.log(p);
  }

  async actualizaCuentaYFactura(pago: Pago) {
    if (!pago.cuenta) {
      const cuenta = this.cuentas.find(x => x.idCuenta === pago.idCuenta);
      if (cuenta) {
        pago.cuenta = cuenta;
      } else {
        const v = await this.dbfs.docOnce("cuentas/" + pago.idCuenta);
        // this.fs.doc().get().pipe(first()).subscribe(v => {
        if (v && v.data()) {
          const c = <CuentaPopapp>v.data();
          this.cuentas.push(c);
          pago.cuenta = c;
        }
        // });
      }
    }
    if (pago.idFactura && !pago.factura) {
      const factura = this.facturas.find(x => x.idFactura === pago.idFactura);
      if (factura) {
        pago.factura = factura;
      } else {
        const v = await this.dbfs.docOnce("cuentas/" + pago.idCuenta + "/facturas/" + pago.idFactura);
        // this.fs.doc().get().pipe(first()).subscribe(v => {
        if (v && v.data()) {
          const f = <FacturaPopapp>v.data();
          this.facturas.push(f);
          pago.factura = f;
        }
        // });
      }
    }
  }

  irAFactura(factura: FacturaPopapp) {
    console.log(factura);
  }

  async borrar(p: Pago) {
    const seguro = confirm("Borrar?");
    if (seguro) {
      await this.dbfs.updateAt(p.path, { deleted: true });
      this.openSnackBar("Borrado", "");
    }
  }
  async controlado(p: Pago) {
    await this.dbfs.updateAt(p.path, { controlado: true });
    this.openSnackBar("Controlado", "");
  }
  datosCompletos(p: Pago): string {

    if (p && p.cuenta && p.cuenta.datosFacturacion &&
      p.cuenta.datosFacturacion.comuna &&
      p.cuenta.datosFacturacion.cuitORut &&
      p.cuenta.datosFacturacion.direccion &&
      p.cuenta.datosFacturacion.giro &&
      p.cuenta.datosFacturacion.mail &&
      p.cuenta.datosFacturacion.razonSocial
    ) {

      return ' Completos ✅ (' + p.cuenta.datosFacturacion.cuitORut + ')';
    } else {
      const rut = (p.cuenta && p.cuenta.datosFacturacion && p.cuenta.datosFacturacion.cuitORut) ? '(' + p.cuenta.datosFacturacion.cuitORut + ')' : '';
      return ' Incompletos ⚠️' + rut;
    }
  }

  async configDatosChile(p: Pago) {
    const cuentAdminSnap = await this.dbrd.object('cuentas/' + p.idCuenta).query.once('value');
    let servidorAdmin = undefined;

    if (cuentAdminSnap && cuentAdminSnap.val()) {
      console.log(cuentAdminSnap.val());
      const cuenta = cuentAdminSnap.val();
      if (cuenta.locales) {
        for (let idLocal in cuenta.locales) {
          const local = cuenta.locales[idLocal];
          if (local && local.servidores) {
            for (let idMac in local.servidores) {
              const servidor = local.servidores[idMac];
              if (servidor && servidor.numeroLocal &&
                +servidor.numeroLocal > 0 &&
                +servidor.numeroLocal < 9000 &&
                +servidor.numeroLocal !== 900 &&
                servidor.pais &&
                servidor.pais === 'CHI' &&
                servidor.keyPedidosOnline &&
                servidor.keyPedidosOnline.key &&
                servidor.importe &&
                +servidor.importe > 0
              ) {
                servidorAdmin = servidor;
                servidorAdmin.idCuenta = p.idCuenta;
                servidorAdmin.nombreCuenta = cuenta.nombreCuenta;
                break;
              }
            }
          }
        }
      }
    }
    if (servidorAdmin) {
      const dialogRef = this.dialog.open(ModalConfigFactPopappChiComponent, {
        width: '85%',
        data: { s: servidorAdmin }
      })
      dialogRef.afterClosed().subscribe(exito => {
        if (exito) {
          p.cuenta.datosFacturacion = exito;
        }
      });
    } else {
      this.openSnackBar('No se pudo encontrar un servidor activo', 'Avisar a GAMR');
    }
  }

  async facturar(p: Pago) {
    console.log(p);

    let data: any = { pago: JSON.parse(JSON.stringify(p)) };

    try {
      const base = environment.urls.functions;
      const url = `${base}/emitirPopapp/emitir/${p.idCuenta}/${p.idPago}`;
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });

      let res = await this.http.post(url, data, options).toPromise();
      let jsonRespuesta = await res.json();
      console.log("Exito", jsonRespuesta);
      this.openSnackBar("Exito", "");
    } catch (e) {
      let errorMsg = 'Error al emitir';
      let error = e;
      try {
        if (e && e.json()) {
          const respuesta = e.json();
          if (respuesta && respuesta.msg) {
            errorMsg = respuesta.msg;
            error = respuesta.msg;
          }
        }
      } catch (err) {
        console.error('Error al obtener msg', err);
      } finally {
        console.error(e);
        this.openSnackBar(errorMsg, "Revisa la consola (F12)");
      }
    }
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

}
