import { er } from "src/app/shared/error";
import { ValidationErrors } from "@angular/forms";

export class CampoDialog{
    nombre: string;
    valor: string;
    placeholder: string;
    type: string;
    hint: string;
    errores: er[];
    Validaciones: ValidationErrors[];
}