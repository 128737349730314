import { Component, OnInit, ViewChild, Input, AfterViewInit, OnChanges, ElementRef } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';

import * as consts from "../../shared/constantes";
import { ResellerGuard } from 'src/app/guards/reseller.guard';
import { AuthService } from 'src/app/core/auth.service';
import { User } from 'src/app/shared/user';
import { DbFSService } from 'src/app/services/db-fs.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() isCondensed = false;
  menu: any;
  reseller: boolean;
  user: User;
  onepayVar: any;
  showAnuncio: boolean = false;

  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

  /**
   * Constructor. Determina si el usuario registrado es reseller.
   * @param resellerGuard 
   */
  constructor(private authService: AuthService, private resellerGuard: ResellerGuard,  private dbfs: DbFSService) {
    this.resellerGuard.canActivate(null, null).then(v => { this.reseller = v; });
  }

  ngOnInit() {
    //this.onepayVar = window["Onepay"];

    //verifica si se muestran o no se muestran los anuncios
    this.dbfs.docOnce("globales/anuncios").then(result => {
      if (result.data()) {
        const datos: any = result.data();
        this.showAnuncio = datos.showAnuncios2;
      }
    });

  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);

    //Get User data
    this._fetchUserData();

    // this._activateMenuDropdown();
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  /**
   * small sidebar
   */
  smallSidebar() {
    document.body.classList.add('left-side-menu-sm');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('topbar-light');
  }

  /**
   * Dark sidebar
   */
  darkSidebar() {
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.toggle('left-side-menu-dark');
  }

  /**
   * Light Topbar
   */
  lightTopbar() {
    document.body.classList.add('left-side-menu-dark');
    document.body.classList.add('topbar-light');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.remove('enlarged');

  }

  /**
   * Sidebar collapsed
   */
  sidebarCollapsed() {
    document.body.classList.add('enlarged');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.remove('boxed-layout');

  }

  /**
   * Boxed Layout
   */
  boxedLayout() {
    document.body.classList.toggle('boxed-layout');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.add('enlarged');
  }

  /**
   * Devuelte true si el usuario registrado es admin
   * @param email 
   */
  esAdmin(user: User) {
    return consts.esAdmin(user);
  }

  esDeveloper(user: User) {
    return consts.esDeveloper(user);
  }

  /**
 * Obtiene los datos del usuario
 */
  _fetchUserData() {
    this.authService.user.subscribe(user => {
      if (user && user.email && user.email.length > 3) {
        this.user = user
      }
    })
  }

  onepay() {
    console.log("click one pay")
    this.onepayVar.checkout({
      endpoint: 'https://popappservice.net/asd',
      commerceLogo: 'https://tu-url.com/images/icons/logo-01.png',
      callbackUrl: 'https://popappservice.net/test/mypopapp/payments',
      transactionDescription: 'Set de pelotas',
      onclose: function (status) {
        console.log('el estado recibido es: ', status);
      }
    });
  }

  /**
   * Activates the menu dropdown
   */
  // _activateMenuDropdown() {
  //   const links = document.getElementsByClassName('side-nav-link-ref');
  //   let menuItemEl = null;
  //   // tslint:disable-next-line: prefer-for-of
  //   for (let i = 0; i < links.length; i++) {
  //     // tslint:disable-next-line: no-string-literal
  //     if (window.location.pathname === links[i]['pathname']) {
  //       menuItemEl = links[i];
  //       break;
  //     }
  //   }

  //   if (menuItemEl) {
  //     menuItemEl.classList.add('active');

  //     const parentEl = menuItemEl.parentElement;
  //     if (parentEl) {
  //       parentEl.classList.add('active');

  //       const parent2El = parentEl.parentElement;
  //       if (parent2El) {
  //         parent2El.classList.add('in');
  //       }

  //       const parent3El = parent2El.parentElement;
  //       if (parent3El) {
  //         parent3El.classList.add('active');
  //         parent3El.firstChild.classList.add('active');
  //       }
  //     }
  //   }
  // }

}
