import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { ServidorAdmin } from '../shared/admin/servidor-admin';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { CuentaAdmin } from '../shared/admin/cuenta-admin';
import { LocalAdmin } from '../shared/admin/local-admin';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class CuentaService {

    constructor(private afAuth: AngularFireAuth,
        private db: AngularFireDatabase, private http: Http, private afs: AngularFirestore) { }

    randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    charNumber(char: string): number {
        if (char == "0") {
            return 31;
        } else if (char == "1") {
            return 1;
        } else if (char == "2") {
            return 2;
        } else if (char == "3") {
            return 3;
        } else if (char == "4") {
            return 4;
        } else if (char == "5") {
            return 5;
        } else if (char == "6") {
            return 6;
        } else if (char == "7") {
            return 7;
        } else if (char == "8") {
            return 8;
        } else if (char == "9") {
            return 9;
        } else if (char == "A") {
            return 10;
        } else if (char == "B") {
            return 11;
        } else if (char == "C") {
            return 12;
        } else if (char == "D") {
            return 13;
        } else if (char == "E") {
            return 14;
        } else if (char == "F") {
            return 15;
        } else if (char == "X") {
            return 29;
        } else if (char == "Y") {
            return 19;
        } else if (char == "Z") {
            return 23;
        } else {
            return 16;
        }
    }

    generaCódigoConNuevaFechayPlan(servidor: ServidorAdmin): string {

        if (this.servidorConIdsValidas(servidor)) {
            let yyyyFechaHasta: number;
            let mmFechaHasta: number;
            let ddFechaHasta: number;
            let fecha;
            if (servidor.vencimiento) {
                fecha = this.pagoHastaADate(servidor.vencimiento);
            } else {
                fecha = this.pagoHastaADate(servidor.pagoHasta);
            }
            ddFechaHasta = fecha.getDate();
            mmFechaHasta = fecha.getMonth() + 1;
            yyyyFechaHasta = fecha.getFullYear();

            let c01 = this.randomIntFromInterval(10, 99);
            let c2 = servidor.idMac.charAt(1);
            let c334455 = this.charNumber(servidor.idMac.charAt(0)) * servidor.planN;
            let c456: any = this.randomIntFromInterval(1, 3);

            if (c456 == 1) {
                c456 = "X";
            } else if (c456 == 2) {
                c456 = "Y";
            } else {
                c456 = "Z";
            }
            let c7 = this.randomIntFromInterval(1, 4);
            let c8 = this.randomIntFromInterval(1, 5);
            let c910 = (yyyyFechaHasta % 2018)/*este no deberia ser mayor que 5*/ * (c7 + c8);

            let c1112 = (this.charNumber(servidor.idMac.charAt(2)) + c01) % 100;
            let c1314 = this.randomIntFromInterval(10, 50);
            let c1516 = c1314 + mmFechaHasta;
            let c1718 = this.randomIntFromInterval(40, 99);
            let c1920 = c1718 - ddFechaHasta;
            let ctrl: number = ((c01 + this.charNumber(c2) + c334455 + this.charNumber(c456) + c7 + c8 + c910 + c1112 + c1314 + c1516 + c1718 + c1920) * 17) % 10;
            return c01 + c2 + c334455 + c456 + c7 + c8 + c910 + c1112 + c1314 + c1516 + c1718 + c1920 + ctrl;
        } else {
            throw "No se pudo generar código, no es válido el servidor";
        }
    }

    descifraCódigoConNuevaFechayPlan(codigo: string, servidor: ServidorAdmin): { fechaddMMyyyy: string, plan: number } {
        if (this.servidorConIdsValidas(servidor)) {
            let c01 = +(codigo.charAt(0) + codigo.charAt(1));
            codigo = codigo.replace("" + c01, "");

            let c2 = codigo.charAt(0);

            if (c2 == servidor.idMac.charAt(1)) {
                codigo = codigo.replace("" + c2, "");
                let plan: number;
                let c334455;
                if (codigo.startsWith("0")) {//0
                    c334455 = 0;
                    plan = 0;
                } else if (codigo.startsWith("" + this.charNumber(servidor.idMac.charAt(0)))) {
                    c334455 = this.charNumber(servidor.idMac.charAt(0));
                    plan = 1;
                } else if (codigo.startsWith("" + (2 * this.charNumber(servidor.idMac.charAt(0))))) {
                    c334455 = 2 * this.charNumber(servidor.idMac.charAt(0));
                    plan = 2;
                } else if (codigo.startsWith("" + (3 * this.charNumber(servidor.idMac.charAt(0))))) {
                    c334455 = 3 * this.charNumber(servidor.idMac.charAt(0));
                    plan = 3;
                } else {
                    throw "Código inválido 70";
                }
                if (codigo.startsWith("" + c334455)) {
                    codigo = codigo.replace("" + c334455, "");
                    let c456 = codigo.charAt(0);
                    codigo = codigo.replace(c456, "");
                    let c7 = +codigo.charAt(0);
                    codigo = codigo.replace("" + c7, "");
                    let c8 = +codigo.charAt(0);
                    codigo = codigo.replace("" + c8, "");
                    let c910: number;
                    let yyyy: number;
                    if (codigo.startsWith("0")) {//18
                        c910 = 0;
                        yyyy = 2018;
                    } else if (codigo.startsWith("" + (c7 + c8))) {//19
                        c910 = (c7 + c8);
                        yyyy = 2019;
                    } else if (codigo.startsWith("" + (2 * (c7 + c8)))) {//20
                        c910 = 2 * (c7 + c8);
                        yyyy = 2020;
                    } else if (codigo.startsWith("" + (3 * (c7 + c8)))) {//21
                        c910 = 3 * (c7 + c8);
                        yyyy = 2021;
                    } else if (codigo.startsWith("" + (4 * (c7 + c8)))) {//22
                        c910 = 4 * (c7 + c8);
                        yyyy = 2022;
                    } else if (codigo.startsWith("" + (5 * (c7 + c8)))) {//23
                        c910 = 5 * (c7 + c8);
                        yyyy = 2023;
                    } else {
                        throw "Código inválido 92";
                    }
                    codigo = codigo.replace("" + c910, "");
                    let c1112 = (this.charNumber(servidor.idMac.charAt(2)) + c01) % 100;
                    if (codigo.startsWith("" + c1112)) {
                        codigo = codigo.replace("" + c1112, "");
                        let c1314 = +(codigo.charAt(0) + codigo.charAt(1));
                        codigo = codigo.replace("" + c1314, "");
                        let c1516 = +(codigo.charAt(0) + codigo.charAt(1));
                        let mm = c1516 - c1314;
                        if (mm <= 12 && mm > 0) {
                            codigo = codigo.replace("" + c1516, "");
                            let c1718 = +(codigo.charAt(0) + codigo.charAt(1));
                            codigo = codigo.replace("" + c1718, "");

                            let c1920 = +(codigo.charAt(0) + codigo.charAt(1));
                            codigo = codigo.replace("" + c1920, "");

                            let dd = c1718 - c1920;
                            if (dd > 0 && dd < 32) {
                                let ctrl: number = ((c01 + this.charNumber(c2) + c334455 + this.charNumber(c456) + c7 + c8 + c910 + c1112 + c1314 + c1516 + c1718 + c1920) * 17) % 10;
                                if (codigo.startsWith("" + ctrl)) {
                                    codigo = codigo.replace("" + ctrl, "");
                                    if (codigo.length == 0) {
                                        return { fechaddMMyyyy: dd + "/" + mm + "/" + yyyy, plan: plan }
                                    } else {
                                        throw "Código inválido 117";
                                    }
                                } else {
                                    throw "Código inválido 120";
                                }
                            } else {
                                throw "Código inválido 123";
                            }
                        } else {
                            throw "Código inválido 126";
                        }
                    } else {
                        throw "Código inválido 129";
                    }
                } else {
                    throw "Código inválido 132";
                }
            } else {
                throw "Código inválido 135";
            }
        } else {
            throw "No es válido el servidor 138";
        }
    }
    /**
     * Ambos procesos upgrade y downgrade se pueden hacer online.
     * Para hacer offline, es necesario que el POS ingrese un código para actualziar la fecha de vencimiento y el plan,
     * 
     * @param servidor 
     * @param to 0 Gratis, 1 Básico, 2 En crecimiento, 3 Empresa
     */
    async downgrade(servidor: ServidorAdmin, to: number): Promise<void> {

        if (this.servidorConIdsValidas(servidor)) {
            let numPlan = servidor.planN;
            if (numPlan != -1) {

                //let importePlan: number = this.importePorPlan(to, servidor.pais);
                let db = this.db; //await es como que el this no apunta a la clase

                let fechaRef = this.db.object("fecha");
                let xThis = this;
                let mapUpdates = {};

                await fechaRef.update({ fecha: { ".sv": "timestamp" } });

                await fechaRef.query.once("value", function (snap) {
                    //leemos la fecha actual

                    let fechaACtual = snap.val().fecha;

                    //empezamos a armar el update en firebase
                    let urlBase = "cuentas/" + servidor.idCuenta + "/locales/" + servidor.idLocal + "/servidores/";
                    let fecha = new Date();
                    let diferencia = fechaACtual - fecha.getTime();
                    fecha.setUTCMilliseconds(diferencia);

                    //prorrateo
                    //xThis.prorrateo(fecha, servidor, to, mapUpdates, urlBase);


                    //mapUpdates[urlBase + servidor.idMac + "/importeQDeberia/"] = importePlan;
                    ///mapUpdates[urlBase + servidor.idMac + "/importe/"] = importePlan;
                    //mapUpdates[urlBase + servidor.idMac + "/planN/"] = planNuevo; // no tocamos el plan pues debe mantenerse en la mejor version hasta el final del vencimiento
                    mapUpdates[urlBase + servidor.idMac + "/downGradeTo/"] = to; // fowngrade indicara al plan que debe moverse una vez que se llegue al final del vencimiento
                    mapUpdates[urlBase + servidor.idMac + "/fechaCambioPlanMap/" + fecha.getFullYear() + ";" + (fecha.getMonth() + 1) + ";" + fecha.getDate() + ";" + fecha.getHours() + ";" + fecha.getMinutes() + ";" + fecha.getSeconds()] = { fbTime: { ".sv": "timestamp" }, "plan": to, from: servidor.planN };
                });

                await xThis.generaReporteMail(servidor, to, false, xThis);

                return db.object("/").update(mapUpdates);
            } else {
                throw "No se pudo detectar el plan actual";
            }
        } else {
            throw "Servidor con Ids Inváldas, revisar idCuenta, idLocal, o idMac";
        }
    }

    buscaDowngrade(servidor: ServidorAdmin): string {
        if (this.servidorConIdsValidas(servidor)) {
            if ((servidor.downGradeTo || servidor.downGradeTo == 0) && servidor.downGradeTo > -1) {
                let fecha = new Date();
                if (this.pagoHastaADate(servidor.pagoHasta).getTime() > fecha.getTime()) {
                    return this.planNumberToString(servidor.downGradeTo);
                } else {
                    let mapUpdates = {};
                    let urlBase = "cuentas/" + servidor.idCuenta + "/locales/" + servidor.idLocal + "/servidores/";
                    let importe;
                    if (servidor.moneda)
                        importe = this.importePorPlan(servidor.downGradeTo, servidor.moneda);
                    else
                        importe = this.importePorPlan(servidor.downGradeTo, servidor.pais);
                    mapUpdates[urlBase + servidor.idMac + "/importeQDeberia/"] = importe;
                    mapUpdates[urlBase + servidor.idMac + "/importe/"] = importe;
                    mapUpdates[urlBase + servidor.idMac + "/planN/"] = servidor.downGradeTo;
                    mapUpdates[urlBase + servidor.idMac + "/downGradeTo/"] = -1;
                    this.generaReporteMail(servidor, servidor.downGradeTo, true, this);
                    this.db.object("/").update(mapUpdates);
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * 
     * @param servidor debe tener el idCUental, idLocal e idMac cargado
     * @param to el plan al que desea cambiar 0 Gratis, 1 Básico, 2 En crecimiento, 3 Empresa
     */
    async upgrade(servidor: ServidorAdmin, to: number): Promise<void> {
        if (this.servidorConIdsValidas(servidor)) {
            let numPlan = servidor.planN;
            if (numPlan != -1) {


                let importePlan: number;
                importePlan = this.importePorPlan(to, servidor.moneda);

                let db = this.db; //await es como que el thid no apunta a la clase

                let fechaRef = db.object("fecha");
                let xThis = this;
                let mapUpdates = {};

                await fechaRef.update({ fecha: { ".sv": "timestamp" } });

                await fechaRef.query.once("value", function (snap) {
                    //leemos la fecha actual

                    let fechaACtual = snap.val().fecha;

                    //empezamos a armar el update en firebase
                    let urlBase = "cuentas/" + servidor.idCuenta + "/locales/" + servidor.idLocal + "/servidores/";
                    let fecha = new Date();
                    let diferencia = fechaACtual - fecha.getTime();
                    fecha.setUTCMilliseconds(diferencia);

                    //prorrateo
                    if (servidor.planN != null && servidor.planN && servidor.planN > 0 && servidor.planN != to) {
                        //esta validacion permite q no se haga prorrateo en el caso de q me downgradie y estoy volviendo al plan anterior
                        xThis.prorrateo(fecha, servidor, to, mapUpdates, urlBase);
                    } //else puede ser que se este upgradeando habiendo un downgrade

                    mapUpdates[urlBase + servidor.idMac + "/importeQDeberia/"] = importePlan;
                    mapUpdates[urlBase + servidor.idMac + "/importe/"] = importePlan;
                    mapUpdates[urlBase + servidor.idMac + "/planN/"] = to;
                    mapUpdates[urlBase + servidor.idMac + "/downGradeTo/"] = -1; // quitamos cualquier downgrade qse haya hecho
                    mapUpdates[urlBase + servidor.idMac + "/fechaCambioPlanMap/" + fecha.getFullYear() + ";" + (fecha.getMonth() + 1) + ";" + fecha.getDate() + ";" + fecha.getHours() + ";" + fecha.getMinutes() + ";" + fecha.getSeconds()] = { fbTime: { ".sv": "timestamp" }, "plan": to, from: (servidor.planN ? servidor.planN : 0) };
                });
                xThis.generaReporteMail(servidor, to, false, xThis)
                    .then(respuesta => {
                        //console.log("respuesta", respuesta);
                    })
                    .catch(error => console.log("error", error))


                return db.object("/").update(mapUpdates);

            } else {
                throw "No se pudo detectar el plan actual";
            }
        } else {
            throw "Servidor con Ids Inváldas, revisar idCuenta, idLocal, o idMac";
        }
    }

    async generaReporteMail(servidor: ServidorAdmin, to: number, seEfectuoElDownGradePorFechaVencida: boolean, xthis: this): Promise<any> {

        //leer emails de usuarios 
        let ref = this.db.object("usuarios/" + servidor.idMac + "/mails"); //cambiar a emails cuando no valga mas
        let marca = "Popapp";
        if (servidor.marca) {
            marca = servidor.marca;
        }
        let notif = {
            "notification": {
                "title": marca,
                "to": "null",
                "copia": "jcorona@popapp.io, pmarin@popapp.io, pgarcia@popapp.io, victoria@popapp.io, agustina@popapp.io, fzarate@popapp.io, plopez@popapp.io",
                "asunto": "Cambio de plan en " + servidor.nombreLocal + " (" + servidor.nombreServidor + ")",
                "text": "Hola, prueba",
                "html": "<h2>Holaaa</h2>\n<h3>Prueba gggg</h3>"
            }
        };

        await ref.query.once("value", function (snap) {
            if (snap && snap.val()) {
                let mapaAux = snap.val();
                if (mapaAux) {
                    let to = "";
                    for (let i in mapaAux) {
                        //let element: MailTrazable = mapaAux[i];
                        let mail: string = mapaAux[i];
                        to += mail + ",";
                    }
                    notif.notification.to = to;
                }
            }
        });

        if (notif.notification.to == "null") {
            //error no se puede mandar el email
        } else {
            let text = "";
            let html = "";
            let planViejo = xthis.planNumberToString(servidor.planN);
            let planNuevo = xthis.planNumberToString(to);


            //si es una ejecución de downgrade
            if (seEfectuoElDownGradePorFechaVencida) {
                if (to == 0) {
                    //es una cancelación
                    //text += "Tu suscripción al plan " + planViejo + " ha finalizado.\nA partir de ahora puedes seguir disfrutando de tu plan " + planNuevo;
                    //html += "<p>Tu suscripción al plan <strong>" + planViejo + "</strong> ha finalizado.</p>\n<p>A partir de ahora puedes seguir disfrutando de tu plan <strong>" + planNuevo + "</strong></p>";
                } else {
                    //se ejecutó un downgrade
                    //text += "Tu suscripción al plan " + planViejo + " ha finalizado.\nA partir de ahora puedes seguir disfrutando de tu plan " + planNuevo;
                    //html += "<p>Tu suscripción al plan <strong>" + planViejo + "</strong> ha finalizado.</p>\n<p>A partir de ahora puedes seguir disfrutando de tu plan <strong>" + planNuevo + "</strong></p>";
                }
                text += "Tu suscripción al plan " + planViejo + " ha finalizado.\nA partir de ahora puedes seguir disfrutando de tu plan " + planNuevo;
                html += "<p>Tu suscripción al plan <strong>" + planViejo + "</strong> ha finalizado.</p>\n<p>A partir de ahora puedes seguir disfrutando de tu plan <strong>" + planNuevo + "</strong></p>";
            } else if ((servidor.planN || servidor.planN == 0) && servidor.planN > to) {
                //es un downgrade
                let fecha = new Date();
                let fechaPago = xthis.pagoHastaADate(servidor.pagoHasta)
                if (fechaPago.getTime() > fecha.getTime()) {
                    //no deberia mandarse este email pues se enviara en el busca downgrade pero lo haremos igual
                } else {
                    //se deberia mandar el mail
                }
                text += "Tu suscripción al plan " + planViejo + " finalizará el " + xthis.fechaPretty(fechaPago) + ".\nA partir de esa fecha se activará el plan " + planNuevo;
                html += "<p>Tu suscripción al plan <strong>" + planViejo + "</strong> finalizará el <strong>" + xthis.fechaPretty(fechaPago) + "</strong>.</p>\n<p>A partir de esa fecha se activará el plan <strong>" + planNuevo + "</strong></p>";
            } else if (to > 0) {
                //es un upgrade
                text += "¡Felicitaciones!\nYa puedes disfrutar del plan " + planNuevo;
                html += "<h3>¡Felicitaciones!</h3>\n<p>Ya puedes disfrutar del plan <strong>" + planNuevo + "</strong></p>";
            } else {
                //generico no sabemos exactamente
                text += "Tu suscripción actual al plan " + planNuevo + " está en vigencia";
                html += "<p>Tu suscripción actual al plan <strong>" + planNuevo + "</strong> está en vigencia</p>";
            }


            //pie de msj
            text += "\n\nPara más información de tu suscripción puedes acceder a https://popapp.io/mypopapp e ingresar en Mi Cuenta" +
                "\n\n\nGracias por elegirnos\nEl equipo de " + marca + ".";
            html += "\n\n<br><p>Para más información de tu suscripción puedes acceder a https://popapp.io/mypopapp e ingresar en <strong>Mi Cuenta</strong></p>" +
                "\n\n<br><hr>\n<p>Gracias por elegirnos.</p>\n<p>El equipo de " + marca + ".</p>";

            notif.notification.text = text;
            notif.notification.html = html;

            //envio de post
            //let headers = new Headers({ 'Content-Type': 'application/json' });
            //let options = new RequestOptions({ headers: headers });
            return xthis.enviarEmailAFS(xthis, notif, servidor.idMac);
            //return xthis.http.post("environment.urls.escuchador/payments/mails/" + servidor.idMac, notif, options).toPromise()
            //  .then(xthis.extractData)
            //.catch(xthis.handleErrorObservable);
        }
    }


    enviaMailProm(body, urlServidorMail) {
        //envio de post
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(urlServidorMail, body, options).toPromise()

    }

    enviarEmailAFSProm(mailOptions, mac: string): Promise<any> {
        let message: any = {
            to: mailOptions.notification.to,
            message: {
                subject: mailOptions.notification.asunto
            }
        }

        if (mailOptions.notification.copia) {
            message.bcc = mailOptions.notification.copia
        }

        if (mailOptions.notification.text) {
            message.message.text = mailOptions.notification.text
        }
        if (mailOptions.notification.html) {
            message.message.html = mailOptions.notification.html
        }

        try {
            return this.afs.collection('mail').add(message);
        } catch (e) {
            //envio de post
            console.log("No se pudo enviar el nuevo, intentando con el viejo", e)
            let headers = new Headers({ 'Content-Type': 'application/json' });
            let options = new RequestOptions({ headers: headers });
            return this.http.post(environment.urls.escuchador +"/payments/mails/" + mac, mailOptions, options).toPromise()
        }
    }



    enviarEmailAFS(xthis, mailOptions, mac: string) {
        let message: any = {
            to: mailOptions.notification.to,
            message: {
                subject: mailOptions.notification.asunto
            }
        }
        if (mailOptions.notification.copia) {
            message.bcc = mailOptions.notification.copia
        }

        if (mailOptions.notification.text) {
            message.message.text = mailOptions.notification.text
        }
        if (mailOptions.notification.html) {
            message.message.html = mailOptions.notification.html
        }

        try {
            return xthis.afs.collection('mail').add(message);
        } catch (e) {
            //envio de post
            console.log("No se pudo enviar el nuevo, intentando con el viejo", e)
            let headers = new Headers({ 'Content-Type': 'application/json' });
            let options = new RequestOptions({ headers: headers });
            return xthis.http.post(environment.urls.escuchador +"/payments/mails/" + mac, mailOptions, options).toPromise()
                .then(xthis.extractData)
                .catch(xthis.handleErrorObservable);
        }
    }

    fechaPretty(fechaPago: Date): string {
        let mes;
        switch (fechaPago.getMonth()) {
            case 0: mes = " Ene. "; break
            case 1: mes = " Feb. "; break;
            case 2: mes = " Mar. "; break;
            case 3: mes = " Abr. "; break;
            case 4: mes = " May. "; break;
            case 5: mes = " Jun. "; break;
            case 6: mes = " Jul. "; break;
            case 7: mes = " Ago. "; break;
            case 8: mes = " Sep. "; break;
            case 9: mes = " Oct. "; break;
            case 10: mes = " Nov. "; break;
            case 11: mes = " Dic. "; break;
        }
        return fechaPago.getDate() + mes + fechaPago.getFullYear();
    }


    private extractData(res: Response) {

        let body = res.json();
        //console.log("body", body);
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        throw (error.message || error);
    }

    prorrateo(fechaActual: Date, servidor: ServidorAdmin, to: number, mapUpdates, urlBase: string) {
        if (servidor.pagoHasta) {
            //extraemos la fecha de vencimiento actual

            let dif = this.pagoHastaADate(servidor.pagoHasta).getTime() - fechaActual.getTime();
            dif = dif / (1000 * 60 * 60 * 24); //dif en Dias
            dif = Math.round(dif);//dias enteros
            if (dif > 0 && servidor.pagoHasta == servidor.fechaFinPruebaFull) {
                return;
            }
            let idMac = "upgrade" + servidor.planN + "a" + to;
            let importe;
            if (servidor.moneda)
                importe = this.importePorPlan(to, servidor.moneda);
            else
                importe = this.importePorPlan(to, servidor.pais);
            importe = importe - servidor.importe;
            if (importe >= 0) {
                //se trata de un upgrade, sino seria un downgrade
            } else {
                throw "No es un upgrade";
            }
            importe = (importe / 30) * dif;
            mapUpdates[urlBase + idMac + "/importe/"] = importe;
            mapUpdates[urlBase + idMac + "/importeQDeberia/"] = importe;

            if (importe < 0) {
                // entonces esta vencido el sistema,
                // por lo tanto tenemos que hacerle un descuento
                // por los dias que han pasado que no tuvo el mejor plan
                if (dif > 1) {
                    mapUpdates[urlBase + idMac + "/nombreServidor/"] = "Descuento por mejorar al plan " + this.planNumberToString(to) + " " + dif + " días después del vencimiento";
                } else {
                    mapUpdates[urlBase + idMac + "/nombreServidor/"] = "Descuento por mejorar al plan " + this.planNumberToString(to) + " " + dif + " día después del vencimiento";
                }
            } else {
                // entonces va usar el sistema con un mejor plan unos dias del mes que ya habia pagado,
                // por lo tanto tenemos que hacerle un recargo
                // por los dias que faltan hasta que venza
                if (dif > 1) {
                    mapUpdates[urlBase + idMac + "/nombreServidor/"] = "Recargo por mejorar al plan " + this.planNumberToString(to) + " " + dif + " días antes del vencimiento";
                } else {
                    mapUpdates[urlBase + idMac + "/nombreServidor/"] = "Recargo por mejorar al plan " + this.planNumberToString(to) + " " + dif + " día antes del vencimiento";
                }
            }
            mapUpdates[urlBase + idMac + "/cuotasPendientes/"] = 1;
            mapUpdates[urlBase + idMac + "/cuotasTotales/"] = 1;
            mapUpdates[urlBase + idMac + "/moneda/"] = servidor.moneda;
            mapUpdates[urlBase + idMac + "/numeroLocal/"] = servidor.numeroLocal;
            if (servidor.pagaIva)
                mapUpdates[urlBase + idMac + "/pagaIva/"] = servidor.pagaIva;
            mapUpdates[urlBase + idMac + "/pagoHasta/"] = servidor.pagoHasta;
            mapUpdates[urlBase + idMac + "/pais/"] = servidor.pais;
            mapUpdates[urlBase + idMac + "/showPagar/"] = true;
        }
    }

    diasDesdeMillis(millis: number): number {
        return millis / (1000 * 60 * 60 * 24);
    }
    pagoHastaADate(fechaHasta: string): Date {
        let fechaPago = fechaHasta;
        let dia: number;
        let month: number;
        let year: number;

        let i = fechaPago.indexOf("/");

        if (i != -1) {
            let diaString = fechaPago.substring(0, i);
            dia = +diaString;
            fechaPago = fechaPago.replace(diaString + "/", "");
            i = fechaPago.indexOf("/");
            if (i != -1) {
                let monthString = fechaPago.substring(0, i);
                month = +monthString - 1;
                fechaPago = fechaPago.replace(monthString + "/", "");
                year = +fechaPago;
            } else {
                throw "No se pudo obtener mes de vencimiento";
            }
        } else {
            throw "No se pudo obtener día de vencimiento";
        }

        let pagoHasta = new Date(year, month, dia);
        return pagoHasta;
    }
    mostrarParaPagar(servidor: ServidorAdmin): boolean {
        if (servidor && servidor.pagoHasta && servidor.numeroLocal && +servidor.numeroLocal > 0 && servidor.showPagar && servidor.moneda && servidor.importe) {
            return true;
        } else {
            return false;
        }
    }

    mensajeAVerificarAntesDeCobrar(cuenta: CuentaAdmin, meses: number, msjAumento?: boolean): string {

        let mensaje = "Se pagarán los siguientes conceptos: ";
        //let fechaUltimoPago = "";
        let importeTotal = 0;
        let ivaTotal = 0;
        //let cobrosMap = {};
        let pagoHasta = "";
        let ultimoPago = "";
        let moneda = "";
        let cantServidores = 0;
        let error: string = "";
        for (let keyLocal in cuenta.locales) {
            let local: LocalAdmin = cuenta.locales[keyLocal];

            for (let idMac in local.servidores) {
                let servidor: ServidorAdmin = local.servidores[idMac];

                if (this.mostrarParaPagar(servidor)) {
                    cantServidores++;
                    mensaje += "  |  Servidor " + cantServidores + " " + servidor.nombreLocal + ": " + servidor.nombreServidor;

                    if (moneda == "") {
                        moneda = servidor.moneda;
                    } else {
                        if (moneda != servidor.moneda) {
                            moneda = "Error Monedas distintas (" + moneda + " != " + servidor.moneda + ")";
                            error += moneda + "\n";
                        }
                    }

                    if (ultimoPago == "") {
                        if (servidor.fechaUltimoPago) {
                            ultimoPago = servidor.fechaUltimoPago;
                        }
                    } else {
                        if (servidor.fechaUltimoPago && ultimoPago != servidor.fechaUltimoPago) {
                            ultimoPago = "Error Fechas Ultimos pagos distintos (" + ultimoPago + " != " + servidor.fechaUltimoPago + ")";
                        }
                    }

                    if (pagoHasta == "") {
                        pagoHasta = servidor.pagoHasta;
                    } else {
                        if (pagoHasta != servidor.pagoHasta) {
                            pagoHasta = "Error distintas fechas de vencimiento (" + pagoHasta + " != " + servidor.pagoHasta + ")";
                            error += pagoHasta + "\n";
                        }
                    }

                    if (msjAumento && servidor.importeQDeberia && servidor.importeQDeberia > 0) {//es el mismo codigo q el else pero con importeQDeberia para el msj de aumento
                        mensaje += "  " + servidor.moneda + " " + servidor.importeQDeberia;

                        importeTotal += servidor.importeQDeberia * meses;
                        if (servidor.pagaIva && servidor.pagaIva > 0) {
                            ivaTotal += servidor.importeQDeberia * meses * servidor.pagaIva / 100;
                            mensaje += "   " + servidor.pagaIva + "% Iva: " + servidor.importeQDeberia * servidor.pagaIva / 100;

                        }

                        if (servidor.cuotasPendientes && servidor.cuotasPendientes > 0) {
                            //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
                            mensaje += "     Cuotas Pendientes: " + servidor.cuotasPendientes + " de " + servidor.cuotasTotales;
                            if (servidor.cuotasPendientes < meses) {
                                let descuentoImportes = (meses - servidor.cuotasPendientes) * servidor.importeQDeberia;
                                importeTotal -= descuentoImportes;
                                if (servidor.pagaIva) {
                                    let descuentoIva = (meses - servidor.cuotasPendientes) * servidor.importeQDeberia * servidor.pagaIva / 100;
                                    ivaTotal -= descuentoIva;
                                }
                            }
                        }

                        if (servidor.conceptos) {
                            //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
                            mensaje += "     Conceptos: ";
                            for (let keyConc in servidor.conceptos) {
                                let concepto = servidor.conceptos[keyConc];
                                if (!concepto.cuotasPendientes || concepto.cuotasPendientes > 0) {
                                    if (concepto.cuotasPendientes && concepto.cuotasPendientes < meses) {
                                        let descuentoImportes = (meses - concepto.cuotasPendientes) * concepto.importe;
                                        importeTotal -= descuentoImportes;
                                        if (concepto.iva) {
                                            let descuentoIva = (meses - concepto.cuotasPendientes) * concepto.iva;
                                            ivaTotal -= descuentoIva;
                                        }
                                    }
                                    mensaje += concepto.concepto + " (" + concepto.total + ") ";
                                }
                            }
                        }
                    } else {
                        mensaje += "  " + servidor.moneda + " " + servidor.importe;

                        importeTotal += servidor.importe * meses;
                        if (servidor.pagaIva && servidor.pagaIva > 0) {
                            ivaTotal += servidor.importe * meses * servidor.pagaIva / 100;
                            mensaje += "   " + servidor.pagaIva + "% Iva: " + servidor.importe * servidor.pagaIva / 100;

                        }

                        if (servidor.cuotasPendientes && servidor.cuotasPendientes > 0) {
                            //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
                            mensaje += "     Cuotas Pendientes: " + servidor.cuotasPendientes + " de " + servidor.cuotasTotales;
                            if (servidor.cuotasPendientes < meses) {
                                let descuentoImportes = (meses - servidor.cuotasPendientes) * servidor.importe;
                                importeTotal -= descuentoImportes;
                                if (servidor.pagaIva) {
                                    let descuentoIva = (meses - servidor.cuotasPendientes) * servidor.importe * servidor.pagaIva / 100;
                                    ivaTotal -= descuentoIva;
                                }
                            }
                        }

                        if (servidor.conceptos) {
                            //en totalimportes se sumaron cantMesesAPagar cuotas,por lo tanto hay que descontar cantMesesAPagar - cuotasPendientes
                            mensaje += "     Conceptos: ";
                            for (let keyConc in servidor.conceptos) {
                                let concepto = servidor.conceptos[keyConc];
                                if (!concepto.cuotasPendientes || concepto.cuotasPendientes > 0) {
                                    if (concepto.cuotasPendientes && concepto.cuotasPendientes < meses) {
                                        let descuentoImportes = (meses - concepto.cuotasPendientes) * concepto.importe;
                                        importeTotal -= descuentoImportes;
                                        if (concepto.iva) {
                                            let descuentoIva = (meses - concepto.cuotasPendientes) * concepto.iva;
                                            ivaTotal -= descuentoIva;
                                        }
                                    }
                                    mensaje += concepto.concepto + " (" + concepto.total + ") ";
                                }
                            }
                        }
                    }

                }
            }
        }

        //importe a cobrar
        mensaje += "\n-----------------\nResumen a cobrar: " + moneda + " " + (importeTotal + ivaTotal);

        //venc
        //fechaPagoHasta
        mensaje += "   Actual Fecha vencimiento (pagoHasta): " + pagoHasta;
        let fecha: Date;
        try {
            fecha = this.pagoHastaADate(pagoHasta);
            fecha.setMonth(fecha.getMonth() + (meses == 10 ? 12 : meses));
            mensaje += "  |  Nueva Fecha vencimiento (pagoHasta y venc.): " + this.fechaPretty(fecha);
            cuenta.nuevaFechaVenc = this.fechaPretty(fecha);
        } catch (e) {
            mensaje += "  |  Nueva Fecha vencimiento (pagoHasta y venc.): Indetectable " + e;
        }

        //fechaUltPago
        mensaje += "  |  Nueva Fecha de último pago: " + this.fechaPretty(new Date());

        //cuotasPendientes restar eso en el for
        //cobros map eso solo para cuando haya q pagar

        if (error && error.length > 0) {
            cuenta.error = error;
        }
        if (!moneda.startsWith("Error")) {
            cuenta.moneda = moneda;
        }
        if (!pagoHasta.startsWith("Error")) {
            cuenta.pagoHasta = pagoHasta;
        }
        if (!ultimoPago.startsWith("Error")) {
            cuenta.ultimoPago = ultimoPago;
        }
        cuenta.totalApagarConIva = importeTotal + ivaTotal;
        cuenta.totalApagarSinIva = importeTotal;

        return mensaje;
    }

    cobrar(cuenta: CuentaAdmin, meses: number, tipoPago: string): Promise<void> {

        //actualizar todos los servidores
        let mapUpdates = {};

        let pagoHasta: string = "";

        let fechaNueva: string = "";

        let date = new Date();
        let fechaUltimoPago: string = this.convertDateTodd_mm_yyyy(date);

        let fechaa = date.getFullYear() + ";" + (+date.getMonth() + 1) + ";" + date.getDate() + ";" + (+date.getHours()) + ";" + date.getMinutes() + ";" + date.getSeconds() + "/";

        for (let keyLocal in cuenta.locales) {
            let local: LocalAdmin = cuenta.locales[keyLocal];
            for (let keyServ in local.servidores) {
                let servidor: ServidorAdmin = local.servidores[keyServ];
                if (this.mostrarParaPagar(servidor)) {
                    if (fechaNueva == "") {
                        pagoHasta = servidor.pagoHasta;
                        let fecha: Date = this.pagoHastaADate(servidor.pagoHasta);
                        fecha.setMonth(fecha.getMonth() + (meses == 10 ? 12 : meses));//si son 10 es un año
                        fechaNueva = this.convertDateTodd_mm_yyyy(fecha);
                    } else {
                        if (pagoHasta != servidor.pagoHasta) {
                            throw "Error fechas distintas " + pagoHasta + " != " + servidor.pagoHasta;
                        }
                    }

                    if (servidor.cuotasPendientes && servidor.cuotasPendientes > 0 && meses && meses > 0) {
                        if (servidor.pagoHasta != fechaNueva) {
                            //si son 10 son 12 en realidad
                            mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cuotasPendientes/"] = servidor.cuotasPendientes - (meses == 10 ? 12 : meses);
                        }
                    }

                    let importesArestarPorCuotasEnConceptos = 0;
                    if (servidor.conceptos) {
                        for (let keyConc in servidor.conceptos) {
                            let concepto = servidor.conceptos[keyConc];
                            if (concepto.cuotasPendientes && concepto.cuotasPendientes > 0) {
                                if (meses >= concepto.cuotasPendientes) {
                                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/conceptos/" + keyConc + "/cuotasPendientes"] = -1;
                                    importesArestarPorCuotasEnConceptos = concepto.importe;
                                } else {
                                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/conceptos/" + keyConc + "/cuotasPendientes"] = concepto.cuotasPendientes - meses;
                                }
                            }
                        }
                    }

                    if (importesArestarPorCuotasEnConceptos !== 0) {
                        mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/importe/"] = +((servidor.importe - importesArestarPorCuotasEnConceptos).toFixed());
                    }

                    if (servidor.cuotasPendientes != null && (servidor.cuotasPendientes == 0 || servidor.cuotasPendientes <= meses)) {
                        mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/showPagar/"] = false;
                        mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/numeroLocal/"] = "-5";
                    }
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/pagoHasta/"] = fechaNueva;
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/vencimiento/"] = fechaNueva;
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/fechaUltimoPago/"] = fechaUltimoPago;
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cobros/" + fechaa + "fecha"] = fechaUltimoPago;
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cobros/" + fechaa + "tipoPago"] = tipoPago;
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cobros/" + fechaa + "moneda"] = servidor.moneda;
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cobros/" + fechaa + "importe"] = servidor.importe;
                    if (servidor.pagaIva) {
                        mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cobros/" + fechaa + "iva"] = servidor.pagaIva;
                    }
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cobros/" + fechaa + "cantMeses"] = meses;
                    mapUpdates["locales/" + keyLocal + "/servidores/" + keyServ + "/cobros/" + fechaa + "nuevoVencimiento"] = fechaNueva;
                }
            }
        }

        mapUpdates["ultimoPago/"] = fechaNueva;

        if (cuenta.id && cuenta.id.length > 3) {
            return this.db.object("cuentas/" + cuenta.id + "/").update(mapUpdates);
        } else {
            throw "key cuenta invalido";
        }
    }

    convertDateTodd_mm_yyyy(date: Date): string {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [(dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        date.getFullYear()
        ].join('/');
    }

    importePorPlan(to: number, paisOMoneda: string) {
        if (!paisOMoneda) {
            paisOMoneda = "USD";
        }
        if (paisOMoneda == "ARG" || paisOMoneda == "AR" || paisOMoneda == "ARS" || paisOMoneda == "ARS $") {
            if (to == 0) {
                return 0;
            } else if (to == 1) {
                return 1500;
            } else if (to == 2) {
                return 1950;
            } else {
                return 2500;
            }
        } else if (paisOMoneda == "CHI" || paisOMoneda == "CL" || paisOMoneda == 'UF') {
            if (to == 0) {
                return 0;
            } else if (to == 1) {
                return 1;
            } else if (to == 2) {
                return 1.5;
            } else {
                return 2;
            }
        } else if (paisOMoneda == "CLP" || paisOMoneda == "CLP $") {
            if (to == 0) {
                return 0;
            } else if (to == 1) {
                return 29000;
            } else if (to == 2) {
                return 38700;
            } else {
                return 51600;
            }
        } else { //if(pais == "USD" || pais == "US"){
            if (to == 0) {
                return 0;
            } else if (to == 1) {
                return 45;
            } else if (to == 2) {
                return 60;
            } else {
                return 80;
            }
        }
    }

    servidorConIdsValidas(servidor: ServidorAdmin): boolean {
        return servidor &&
            servidor.idCuenta && servidor.idCuenta.trim().length > 3 &&
            servidor.idLocal && servidor.idLocal.trim().length > 3 &&
            servidor.idMac && servidor.idMac.trim().length > 3;
    }

    planNumberToString(plan: number): string {
        if (plan == 0) {
            return "Gratis";
        } else if (plan == 1) {
            return "Básico";
        } else if (plan == 2) {
            return "En crecimiento";
        } else if (plan == 3) {
            return "Empresa";
        } else {
            return "Plan inválido";
        }
    }

    plan(plan: string): number {
        if (plan) {
            if (plan === "Gratis") {
                return 0;
            } else if (plan === "Básico") {
                return 1;
            } else if (plan === "En crecimiento") {
                return 2;
            } else if (plan === "Empresa") {
                return 3;
            } else {
                return -1;
            }
        } else {
            return -1;
        }
    }

    enviaMail(body, urlServidorMail) {
        //envio de post
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(urlServidorMail, body, options).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorObservable);

    }

    getUrlProm(url: string) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.get(url, options).toPromise()
    }
    postUrlProm(url: string, body) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(url, body, options).toPromise()
    }
    patchUrlProm(url: string, body) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        return this.http.patch(url, body, options).toPromise()
    }
    getUrl(url: string) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorObservable);
    }

    async traeLinkDePago(pais: string, idCuenta: string, cantMesesAPagar: number, moneda: string, email: string, totalApagarConIva: number) {
        let idMP: string;
        if (!pais) {
            pais = "ARG";
        }
        let mostrarSoloPayPal = false;
        if (pais == "ARG" || pais == "AR") {
            idMP = "1392845136";
        } else if (pais == "CHI" || pais == "CL") {
            idMP = "349794797";
        } else if (pais == "PER" || pais == "PE") {
            mostrarSoloPayPal = true;
        } else if (pais == "ARGPRUEBA") {
            idMP = "350425595";
        } else if (pais == "CHIPRUEBA") {
            idMP = "350419662";
        } else {
            mostrarSoloPayPal = true;
        }
        let urlAPagar = environment.urls.escuchador + "/mporden/" + idMP + "/pos/" + idCuenta;
        //let urlAPagar = "http://localhost:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
        //let urlAPagar = "http://192.168.10.113:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
        let body = { items: [], email_payer: '' };

        body.items[0] = {
            "currency_id": moneda,
            "unit_price": totalApagarConIva,
            "quantity": cantMesesAPagar

        };
        body.email_payer = email;

        const headers = new Headers({ 'Content-Type': 'application/json' });
        const options = new RequestOptions({ headers: headers });


        return this.http.post(urlAPagar, body, options).toPromise();

    }



    async traeFechaDeFirebase(): Promise<Date> {
        return await this.db.object('/.info/serverTimeOffset').query.once('value')
            .then(function stv(data) {
                return new Date(data.val() + Date.now());
            }, function (err) {
                return err;
            });

    }

    dateFbHoy: Date;
    ddMMyyyyFbHoy: string;

    async actualizaPagoHastaSiEsLeadOClienteViejo(cuotasPendientes, primerPago, pagoHasta, idCuenta, keyLocal, keyServ): Promise<boolean> {

        if (!primerPago) {
            if (!this.dateFbHoy) {
                this.dateFbHoy = await this.traeFechaDeFirebase();
                this.ddMMyyyyFbHoy = this.convertDateTodd_mm_yyyy(this.dateFbHoy);
            }

            let venceDate = this.pagoHastaADate(pagoHasta);
            //actualizar pagoHasta
            if (this.ddMMyyyyFbHoy && pagoHasta !== this.ddMMyyyyFbHoy && this.dateFbHoy.getTime() > venceDate.getTime()) {
                this.db.object('cuentas/' + idCuenta + "/locales/" + keyLocal + "/servidores/" + keyServ + "/pagoHasta").set(this.ddMMyyyyFbHoy)
                return true;
            }
        }


        return false;
    }
}
