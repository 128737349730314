import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';




export interface ConfFactVessi {
  rut: string,
  rutConGuion: string,
  apikey: string,
}



@Component({
  selector: 'app-modal-conf-vessi',
  templateUrl: './modal-conf-vessi.component.html',
  styleUrls: ['./modal-conf-vessi.component.scss']
})
export class ModalConfVessiComponent implements OnInit {

  confFactVessi: ConfFactVessi;
  s: ServidorAdmin;
  form: FormGroup;
  submitted = false;
  error: string;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalConfVessiComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin },
    private db: AngularFireDatabase,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder) {
    this.s = this.data.s;

  }


  async ngOnInit() {
    this.loading = true;
    let v = await this.leerConfFactVessi(this.s);
    this.confFactVessi = v;
    this.initForm(v);


    console.log(this.confFactVessi);
    this.loading = false;

  }

  initForm(v: ConfFactVessi) {
    this.form = this.formBuilder.group({
      rut: [this.confFactVessi.rut, [Validators.required, Validators.minLength(4)]],
      rutConGuion: [this.confFactVessi.rutConGuion, [Validators.required, Validators.minLength(4)]],
      apikey: [this.confFactVessi.apikey, [Validators.required, Validators.minLength(4)]],
    });
  }

  guardarDatos() {
    this.submitted = true;
    this.error = null;

    if (this.form.invalid) {
      this.error = "Datos inválidos";
      return;
    }


    let urlAdmin = "cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/";
    let urlFactVessi = "factVessi/" + this.s.idMac + "/";

    // subimos el formulario 
    let mapUpdates = {};
    mapUpdates[urlFactVessi + "apikey"] = this.form.controls.apikey.value;
    mapUpdates[urlFactVessi + "compuDe"] = this.s.nombreCuenta + " - " + this.s.nombreServidor;
    mapUpdates[urlFactVessi + "rut"] = this.form.controls.rut.value;
    mapUpdates[urlFactVessi + "testing"] = false;
    mapUpdates[urlAdmin + "rut"] = this.form.controls.rutConGuion.value;


    this.db.object("/").update(mapUpdates).then(ok => {
      this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
      this.dialogRef.close();
    }).catch(e => {
      console.error("No se pudo grabar la tienda", e);
      this.snackbar.open("Error, no se pudo grabar")._dismissAfter(2000);
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  async leerConfFactVessi(s: ServidorAdmin) {

    let confFactVessi: ConfFactVessi = { rut: "", rutConGuion: "", apikey: "" };
    if (s.rut) {
      confFactVessi.rutConGuion = s.rut;
    }
    if (s.idMac) {
      let factVessiSnap = await this.db.object("factVessi/" + s.idMac).query.once("value");
      let factVessi = factVessiSnap.val();
      if (factVessi) {
        if (factVessi.rut) {
          confFactVessi.rut = factVessi.rut;
        }

        if (factVessi.apikey) {
          confFactVessi.apikey = factVessi.apikey;
        }
      }
    }

    return confFactVessi;
  }
}









