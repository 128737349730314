import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalConceptoComponent } from '../modal-concepto/modal-concepto.component';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';

type BillingType = 'LEGACY' | 'NEW';

export interface Concepto {
  concepto: string;
  importe: number;
  iva: number;
  total: number;
  id: string;
  cuotasPendientes?: number;
}

export interface Email {
  email: string,
  activado: boolean
}

@Component({
  selector: 'app-modal-pricing',
  templateUrl: './modal-pricing.component.html',
  styleUrls: ['./modal-pricing.component.scss']
})

export class ModalPricingComponent implements OnInit {
  correo: string;
  passReseteado: boolean = false;
  conceptos: Concepto[] = [];
  usuarios: { email: string, activado: boolean }[] = [];

  keyLocalNueva: string = "";
  dispositivos: boolean;

  hasNewBilling: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalPricingComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public s: ServidorAdmin,
    private db: AngularFireDatabase,
    private dbFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.getUsuarios(this.s);
    this.getConceptos(this.s);
    this.getPlan(this.s);
    this.getBillingConfig(this.s);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateConcepto(s: ServidorAdmin, concepto?: Concepto) {
    const dialogRef = this.dialog.open(ModalConceptoComponent, {
      width: '85%',
      data: { s, concepto }
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.actualizarImportesDeServidor(this.s);
          return;
        }
      });
  }
  deleteConcepto(s: ServidorAdmin, concepto: Concepto) {
    this.db.object("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/conceptos/" + concepto.id).set(null);
    this.actualizarImportesDeServidor(s);
  }
  async actualizarImportesDeServidor(s: ServidorAdmin) {
    await this.getConceptos(this.s);
    let importe = 0;
    this.conceptos.forEach(c => {
      if (!c.cuotasPendientes || c.cuotasPendientes === 0 || c.cuotasPendientes > 0)
        importe += c.importe;
    });
    let mapUpdates = {};
    mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/importe"] = importe;
    mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/importeQDeberia"] = importe;
    this.db.object("/").update(mapUpdates)
      .then(_ => {
        this.snackbar.open("Importe actualizado ✔️", "")._dismissAfter(2000);
      })
      .catch(err => {
        this.snackbar.open("No se pudo actualizar el importe, revisar en FB", "")._dismissAfter(4000);
        console.log("No se pudo actualizar importe", err);
      })
  }

  async getPlan(s: ServidorAdmin) {
    let v = await this.db.object("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/planN").query.once("value");
    let plan = v.val();
    if (plan === 3)
      this.dispositivos = true;
    else
      this.dispositivos = false;
    console.log(plan);
  }
  async getUsuarios(s: ServidorAdmin) {
    let usuarios: { email: string, activado: boolean }[] = [];
    let v = await this.db.object("usuarios/" + s.idMac + "/mails").query.once("value");
    let mails = v.val();
    if (mails) {
      for (let u in mails) {
        usuarios.push({ email: mails[u], activado: true });
      }
    }
    if (s.emails) {
      for (let u in s.emails) {
        usuarios.push({ email: s.emails[u]["email"], activado: s.emails[u]["activado"] });
      }
    }
    this.usuarios = usuarios;
  }
  async getConceptos(s: ServidorAdmin) {
    let v = await this.db.object("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/conceptos").query.once("value");
    let conceptos = v.val();
    let con: Concepto[] = [];
    if (conceptos) {
      for (let u in conceptos) {
        con.push({
          concepto: conceptos[u]["concepto"],
          id: conceptos[u]["id"],
          total: conceptos[u]["total"],
          importe: conceptos[u]["importe"],
          iva: conceptos[u]["iva"],
          cuotasPendientes: conceptos[u]["cuotasPendientes"]
        });
      }
    }
    this.conceptos = con;
  }
  activarDesactivarDispositivos() {
    let valor;
    if (this.dispositivos) {
      valor = 1;
    }
    else
      valor = 3;
    let mapUpdates = {};
    mapUpdates["cuentas/" + this.s.idCuenta + "/locales/" + this.s.idLocal + "/servidores/" + this.s.idMac + "/planN"] = valor;
    this.db.object("/").update(mapUpdates).then(ok => {
      if (valor == 3)
        this.dispositivos = true;
      else
        this.dispositivos = false;
      this.snackbar.open("Datos grabados exitosamente")._dismissAfter(2000);
    }).catch(e => {
      console.error("No se pudo actualizar el pack de dispositivos", e);
      this.snackbar.open("No se pudo completar la accion")._dismissAfter(4000);
    });
  }

  async nuevoUsuario(s: ServidorAdmin) {
    let email = prompt("Email del nuevo usuario para acceder a mypopapp");
    let emailConComa = email.replace(".", ",");
    while (emailConComa.includes(".")) {
      emailConComa = emailConComa.replace(".", ",");
    }
    if (email && email.length > 3) {
      //let usuarioSnap = await this.db.object("users/" + emailConComa).query.once("value");
      //let usuario = usuarioSnap.val();
      //if (usuario && usuario.uid) {

      let urlAdminEmails = "cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/emails/";
      let mapUpdates = {};
      let mapEmail = { "email": email, "activado": false };
      mapUpdates[urlAdminEmails + emailConComa] = mapEmail;
      this.db.object("/").update(mapUpdates)
        .then(_ => {
          this.snackbar.open("Datos guardados ✔️", "")._dismissAfter(2000);
          this.usuarios.push(mapEmail);
        })
        .catch(err => {
          this.snackbar.open("No se pudo guardar ❌", "")._dismissAfter(2000);
          console.log("No se pudo completar la accion", err);
        })
      /*} else {
        this.snackbar.open("No es un usuario de mypopapp")._dismissAfter(2000);
      }
    }*/
    }

  }
  async enviarMail(s: ServidorAdmin) {
    let emails = "";
    for (let u in this.usuarios) {
      emails += this.usuarios[u].email + ",";
    }
    emails.slice(0, -1);
    emails = prompt("Ingrese los destinatarios", emails);
    let html = "<p>Estimado usuario de popapp:</p>" +
      "<p>Te escribimos para informarte que vamos a realizar una actualización del valor de la suscripción del servicio.</p>" +
      "<p>Nuestro compromiso durante la pandemia, sabiendo lo duro que fue para todos nosotros que vivimos de la gastronomía, fue sobrellevar el peor momento sin realizar ajustes en el precio ni en la calidad del servicio.</p>" +
      "<p>Sin embargo no te preocupes, nuestra actualización será menor a la inflación acumulada durante el 2020.</p>" +
      '<p>Puedes consultar tu nuevo precio como siempre, ingresando en <a target="_blank" href="https://popapp.io/mypopapp">popapp.io/mypopapp</a> o desde tu link de pago habitual.</p>' +
      "<p>Muchas gracias por tu comprensión, en nombre de todo el equipo de Popapp.</p>"

    let message: any = {
      to: emails,
      message: {
        subject: "POPAPP - Cambio en los términos y condiciones",
        html: html
      }
    }
    this.dbFirestore.collection('mail').add(message);

  }

  async getBillingConfig(server: ServidorAdmin) {
    let v = await this.db.object(`cuentas/${server.idCuenta}/newBilling`).query.once("value");
    this.hasNewBilling = v.val();
  }

  async changeBilling(server: ServidorAdmin) {
    this.db.object(`cuentas/${server.idCuenta}`).update({ newBilling: !this.hasNewBilling })
      .then(_ => {
        this.hasNewBilling = !this.hasNewBilling;
        this.snackbar.open("Toggle de billing modificado ✔️", "")._dismissAfter(2000);
      })
      .catch(err => {
        console.error('error changing billing toggle');
        this.snackbar.open("Error al cambiar toggle de billing", "")._dismissAfter(2000);
      })

  }
}