import { Component, OnInit } from '@angular/core';
import { RutaService } from '../services/ruta.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute } from '@angular/router';

interface OrdenAPagar {
  fecha: string;
  hora: string;
  idCuenta: string;
  idLocal: string;
  mac: string;
  orden: {
    id: string;
    items: {
      cantidad: number;
      nombre: string;
      precio: number;
    }[];
  };
}

@Component({
  selector: 'app-onepay',
  templateUrl: './onepay.component.html',
  styleUrls: ['./onepay.component.scss', '../../mypopapp/assets/_css/style.css', '../../mypopapp/assets/_css/style1.2.css', '../../mypopapp/assets/_css/media-queries.css']
})



export class OnepayComponent implements OnInit {
  onepayVar: any;
  mesesCL = 2;


  constructor(private rutaService: RutaService, private db: AngularFireDatabase, public activatedRoute: ActivatedRoute) {
    this.onepayVar = window["Onepay"];
  }
  onepayyy(ruta, rutaCallback) {
    if (this.onepayVar) {
      console.log("si esta onepay")

      this.onepayVar.checkout({
        endpoint: ruta,
        commerceLogo: 'https://popapp.io/mypopapp/assets/images/logonegro.png',
        callbackUrl: rutaCallback,
        transactionDescription: "Pago Orden",
        onclose: function (status) {
          console.log('el estado recibido es: ', status);
        }
      });
    } else {
      console.log("no esta onepay")

      this.onepayVar = window["Onepay"];
      setTimeout(() => this.onepayyy(ruta, rutaCallback), 2000)

    }

  }

  ngOnInit() {
    if (!this.onepayVar) {
      this.onepayVar = window["Onepay"];
    }
    //   
    this.rutaService.changeRuta("comenzar");

    this.activatedRoute.url.subscribe(url => {
      let keyfb = url[1].path;

      let ruta = 'https://popappservice.net/app/popappPOS/' + keyfb;
      //let ruta = 'http://localhost:8080/popapp/' + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
      let rutaCallback = 'https://popappservice.net/app/mypopapp/payments';/// + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
      //let rutaCallback = 'http://localhost:8080/mypopapp/payments';/// + this.mesesCL + "/" + this.importeTotalCL + "/" + this.idCuentaAPagar;
      console.log("ruta", ruta)

      //let description = 'Licencia popapp ' + this.mesesCL;


      this.onepayyy(ruta, rutaCallback)



      /*this.db.object("onepayAPagar/" + keyfb).snapshotChanges().subscribe(action => {
        //consts.logD(action.type);
        //consts.logD(action.payload.val());
        let ordenAPagar = <OrdenAPagar>action.payload.val();
        console.log(ordenAPagar)
      })*/

    })



  }

}
