import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CuentaService } from '../../services/cuenta.service';
import { InfoCuenta, AuthService } from 'src/app/core/auth.service';
import { Subscription } from 'rxjs';
import * as consts from "../../shared/constantes";

@Component({
  selector: 'app-cancelar2',
  templateUrl: './cancelar2.component.html',
  styleUrls: ['./cancelar2.component.scss', '../../../mypopapp/assets/_css/mypopapp.css', '../../../mypopapp/assets/_css/mypopapp-mq.css']
})
export class Cancelar2Component implements OnInit, OnDestroy {
  planString: string;
  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;
  constructor(private route: ActivatedRoute, public cuentaService: CuentaService, private authService: AuthService) { }

  marca() {
    return consts.marca(this.infoCuenta);
  }

  ngOnDestroy() {
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }
  }
  ngOnInit() {
    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });
    const plan = +this.route.snapshot.params['plan'];
    this.planString = this.cuentaService.planNumberToString(plan);
  }

}
