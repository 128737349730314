import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { LocalOnline } from '../modales/modal-conf-ponline/modal-conf-ponline.component';
import { CuentaService } from '../services/cuenta.service';
import { DbFSService } from '../services/db-fs.service';
import { ServidorAdmin } from '../shared/admin/servidor-admin';

import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';

export interface Pais {
  offset: number;
  pais: string;
  ciudad: string;
  dirección: string;
  moneda: string;
}


@Component({
  selector: 'app-modal-conf-pais-offset',
  templateUrl: './modal-conf-pais-offset.component.html',
  styleUrls: ['./modal-conf-pais-offset.component.scss']
})
export class ModalConfPaisOffsetComponent implements OnInit {

  leyoPaisYOffset = false;
  leyoPaises = false;
  offset: number;
  offsetElegido: number;
  pais: string;
  paises: Pais[];

  paisElegido = "-1";
  subs: Subscription[] = [];
  loadingPaises: boolean = true;
  s: ServidorAdmin;

  direccion: string;
  country: string;
  state: string;
  city: string;

  moneda: string;

  nombre: string;
  forced: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalConfPaisOffsetComponent>,
    public auth: AuthService,
    private cuentaService: CuentaService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin, forced: boolean },
    private db: AngularFireDatabase,
    private http: Http,
    private dbFS: DbFSService,
    private snackbar: MatSnackBar,
  ) {
    this.s = data.s;
    this.forced = data.forced;
  }


  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

  leerPaisOffestYPaises() {
    if (!this.leyoPaisYOffset && this.s && this.s.keyPedidosOnline && this.s.keyPedidosOnline.key) {
      // console.log("leyendo")
      this.leyoPaisYOffset = true;
      this.subs.push(this.dbFS.doc$<LocalOnline>('confLocalPO/' + this.s.keyPedidosOnline.key).subscribe(async local => {
        // console.log(local)

        if (local.nombre) {
          this.nombre = local.nombre;
        }
        if (local.offset) {
          this.offset = local.offset;
          this.offsetElegido = local.offset;
        }
        this.city = local.ciudad;
        this.direccion = local.direccion;
        // console.log(local.pais);
        // if (local.pais && local.offset && local.direccion) {
        if (local.pais && local.offset && local.moneda) {
          this.pais = local.pais;
          this.paisElegido = local.pais;
          // this.direccion = local.direccion;
          this.loadingPaises = false;
          this.moneda = local.moneda
          // this.dialogRef.close({ pais: this.pais, offset: this.offset, direccion: this.direccion });

          if (!this.forced) { // esto lo hacemos para mantener el modal en caso de que quieran editarlo
            this.dialogRef.close({ pais: this.pais, offset: this.offset, moneda: this.moneda });
          } else {
            this.leerPaises();
          }
        } else {
          this.leerPaises();
        }
      }));
    }
  }

  async leerPaises() {
    if (!this.leyoPaises) {
      this.leyoPaises = true;
      this.paises = await this.dbFS.collectionOnce<Pais>('plataformasPorPais').toPromise();
      this.loadingPaises = false;
    }
  }

  async guardar() {
    if (this.paisElegido && this.offsetElegido != null && this.paisElegido !== "-1" && this.city && this.direccion && this.nombre) {

      const paisElemnto = this.paises.find(x => x.pais === this.paisElegido);
      if (!paisElemnto) {
        this.snackbar.open('No se encontró el país elegido')._dismissAfter(2000);
        return;
      }

      if (!paisElemnto.moneda) {
        this.snackbar.open('El país elegido no tiene moneda')._dismissAfter(2000);
        return;
      }

      this.moneda = paisElemnto.moneda;

      const mapFs = {
        pais: this.paisElegido,
        offset: this.offsetElegido,
        ciudad: this.city,
        moneda: this.moneda,
        direccion: this.direccion,
        ubicacion: {
          ciudad: this.city,
          direccion: this.direccion,
          pais: this.paisElegido,
        }
      }
      await this.guardarDatosLocalSql();
      await this.dbFS.updateAt("confLocalPO/" + this.s.keyPedidosOnline.key, mapFs);
      await this.dbFS.updateAt("cuentas/" + this.s.idCuenta, mapFs);
      this.snackbar.open('Exito: datos del local actualizados')._dismissAfter(2000);
    } else {
      this.snackbar.open('Faltan seleccionar datos')._dismissAfter(2000);
      return;
    }

  }

  async guardarDatosLocalSql() {
    const baseUrl = environment.urls.functions + "/basesql/local";

    const body = {
      _key: this.s.keyPedidosOnline.key,
      direccion: this.direccion,
      ciudad: this.city,
      nombre: this.nombre,
      moneda: this.moneda
    };
    try {
      console.log('body a enviar', body);
      let guardarDatos = await this.http.post(baseUrl, body).toPromise();
      let jsonRespuestaGuardar = await guardarDatos.json();
      console.log("Guardar sql", jsonRespuestaGuardar);
    } catch (e) {
      console.error(e);
      this.snackbar.open("Error guardar local en SQL. Contactar con back.", "")._dismissAfter(2000);
    }
  }

}

