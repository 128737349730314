import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-progress',
  templateUrl: './snack-bar-progress.component.html',
  styleUrls: ['./snack-bar-progress.component.scss']
})
export class SnackBarProgressComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data:{mensaje:string,progress:boolean}){ 
    
  }

  ngOnInit() {
    
  }

}
