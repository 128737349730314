import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimoniales',
  templateUrl: './testimoniales.component.html',
  styleUrls: ['./testimoniales.component.scss', '../../mypopapp/assets/_css/style.css', '../../mypopapp/assets/_css/style1.2.css', '../../mypopapp/assets/_css/media-queries.css']
})
export class TestimonialesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
