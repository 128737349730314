import { Pipe, PipeTransform } from '@angular/core';
import { Orden2 } from '../shared/historial/orden2';
@Pipe({
    name: 'cobro'
})
export class CobroPipe implements PipeTransform {
    transform(o: Orden2): string {
        if (o && o.cobrado) {
            if (o.tipoCobro && o.tipoCobro.length === 1) {
                return o.tipoCobro[0].nombreCobro
            } else {
                let msj = ""
                let i = 0
                for (let c of o.tipoCobro) {
                    msj += c.nombreCobro + "(" + c.importeCobro + ")"
                    if (i !== o.tipoCobro.length - 1) {
                        msj += ", "
                    }
                    i++
                }
                return msj
            }
        }
        return "-"
    }
}