import { Component, OnInit, ViewChild, } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { first, map, } from 'rxjs/operators';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { LocalAdmin } from '../../shared/admin/local-admin';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';

import { AuthService } from "../../core/auth.service";
import { AdminCuentaService } from '../../services/admin-cuenta.service';
import { CuentaService } from '../../services/cuenta.service';
import { Router } from '@angular/router';
import * as consts from "../../shared/constantes";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

interface DataParaTabla {
  tag: string,
  cantUsuarios: number,

  versionAnterior: string,
  versionesAnterioresMap: Map<string, number>,

  versionActual: string,
  versionesActualesMap: Map<string, number>,

  versionDisponible: string,
  versionesDisponiblesMap: Map<string, number>,

  fechaCambio: string,
  qxDias: number,

  servidores: ServidorAdmin[],
}

@Component({
  selector: 'app-versiones-manager',
  templateUrl: './versiones-manager.component.html',
  styleUrls: ['./versiones-manager.component.scss']
})
export class VersionesManagerComponent implements OnInit {

  cantLeidas: number = 0;

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;

  msjSnack: string;

  fecha: any;

  email: string;

  driftVar: any;

  data: DataParaTabla[];

  columnsToDisplay = [
    'tag',
    'cantUsuarios',
    'versionDisponible',
    'versionActual',
    // 'versionAnterior',
    // 'fechaCambio',
    // 'qxDias',
  ];

  dataSource: MatTableDataSource<DataParaTabla>;

  @ViewChild(MatSort) sort: MatSort;

  mapCuentas: any[];

  clientesActivos: boolean = true;

  resumenMinMaxVersiones: boolean = true;

  constructor(
    public router: Router,
    public afAuth: AngularFireAuth,
    public cuentaService: CuentaService,
    public db: AngularFireDatabase,
    public authService: AuthService,
    private adminCuentasService: AdminCuentaService,
    private snackbar: MatSnackBar,
  ) {
    afAuth.user.subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {
        this.email = user.email;
      }
    });
  }


  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }

  ngOnInit() {
    this.hideDrift(1);
    // TODO: leer de localStorage clientesActivos y resumenMinMaxVersiones
    this.leerCuentas();
  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }

  cambiarMinMaxVersiones() {
    // TODO: guardar en localStorage
    this.procesaCuentasLocalesYServidores(this.mapCuentas, this.clientesActivos, this.resumenMinMaxVersiones);
  }

  cambiarClientesActivos() {
    // TODO: guardar en localStorage
    this.procesaCuentasLocalesYServidores(this.mapCuentas, this.clientesActivos, this.resumenMinMaxVersiones);
  }

  leerCuentas() {
    this.itemsRef = this.db.list('cuentas');
    this.items = this.itemsRef.snapshotChanges().pipe(
      first(),
      map(changes => {
        let mapeado = changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
        this.mapCuentas = mapeado;
        this.procesaCuentasLocalesYServidores(mapeado, this.clientesActivos, this.resumenMinMaxVersiones);
        return mapeado;
      })
    );
  }

  async procesaCuentasLocalesYServidores(mapCuentas: any[], clientesActivos: boolean, resumenMinMaxVersiones: boolean) {
    const mapaPorTag: Map<string, DataParaTabla> = new Map();
    for (let i in mapCuentas) {
      let c: CuentaAdmin = mapCuentas[i];
      c.id = mapCuentas[i].key;
      let mapLocales = mapCuentas[i].locales;
      //for de locales
      for (let j in mapLocales) {
        let l: LocalAdmin = mapLocales[j];
        l.id = j;
        l.idCuenta = c.id;

        //for de servidores
        let mapServidores = mapLocales[j].servidores;
        for (let y in mapServidores) {
          let s: ServidorAdmin = mapServidores[y];
          s.idMac = y;
          s.idLocal = l.id;
          s.idCuenta = c.id;
          s.uCuenta = c.u;
          s.lastCuenta = c.last;
          if (c.whatsapp) {
            s.whatsapp = c.whatsapp;
          }
          if (c.contacto) {
            s.contacto = c.contacto;
          }
          s.nombreCuenta = c.nombreCuenta;
          s.nombreLocal = l.nombreLocal;

          if (!clientesActivos || this.clienteActivoSegunMetodoExporte(s)) {
            if (!mapaPorTag.get(s.tag)) {
              mapaPorTag.set(s.tag, {
                cantUsuarios: 0,
                fechaCambio: "",
                qxDias: 0,
                servidores: [],
                tag: s.tag,
                versionActual: "",
                versionAnterior: "",
                versionDisponible: "",
                versionesActualesMap: new Map(),
                versionesAnterioresMap: new Map(),
                versionesDisponiblesMap: new Map(),
              });
            }
            const datos = mapaPorTag.get(s.tag);

            datos.cantUsuarios++;
            datos.servidores.push(s);

            if (!datos.versionesActualesMap.get(s.versionActual)) {
              datos.versionesActualesMap.set(s.versionActual, 0);
            }
            datos.versionesActualesMap.set(s.versionActual,
              datos.versionesActualesMap.get(s.versionActual) + 1
            );

            if (!datos.versionesDisponiblesMap.get(s.versionDisponible)) {
              datos.versionesDisponiblesMap.set(s.versionDisponible, 0);
            }
            datos.versionesDisponiblesMap.set(s.versionDisponible,
              datos.versionesDisponiblesMap.get(s.versionDisponible) + 1
            );

            // TODO: datos.fechaCambio;
            // TODO: datos.qxDias;
            // TODO:  datos.versionActual=;
            // TODO: datos.versionAnterior=;
            // TODO: datos.versionDisponible=;
            // TODO: versionesAnterioresMap: new Map(),
            // TODO: versionesDisponiblesMap: new Map(),

          }
        }
      }
    }
    this.actualizaTotal(mapaPorTag, resumenMinMaxVersiones);
  }

  actualizaTotal(mapaPorTag: Map<string, DataParaTabla>, resumenMinMaxVersiones: boolean) {
    this.data = [];
    for (let tag of mapaPorTag.keys()) {
      const objetoMapa = mapaPorTag.get(tag);

      let versionActual = '';
      let versionDisponible = '';

      if (resumenMinMaxVersiones) {
        versionActual = this.resumenMinMaxVersionesRango(objetoMapa.versionesActualesMap);
        versionDisponible = this.resumenMinMaxVersionesRango(objetoMapa.versionesDisponiblesMap);
      } else {
        for (const vActual of objetoMapa.versionesActualesMap.keys()) {
          versionActual += `${vActual} (${objetoMapa.versionesActualesMap.get(vActual)}), `;
        }
        let versionDisponible = '';
        for (const vDisponible of objetoMapa.versionesDisponiblesMap.keys()) {
          versionDisponible += `${vDisponible} (${objetoMapa.versionesDisponiblesMap.get(vDisponible)}), `;
        }
      }

      this.data.push({
        cantUsuarios: objetoMapa.cantUsuarios,
        fechaCambio: objetoMapa.fechaCambio,
        qxDias: objetoMapa.qxDias,
        servidores: objetoMapa.servidores,
        tag: objetoMapa.tag,
        versionActual: versionActual,
        versionAnterior: objetoMapa.versionAnterior,
        versionDisponible: versionDisponible,
        versionesActualesMap: objetoMapa.versionesActualesMap,
        versionesAnterioresMap: objetoMapa.versionesAnterioresMap,
        versionesDisponiblesMap: objetoMapa.versionesDisponiblesMap,
      });
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
  }

  resumenMinMaxVersionesRango(versionesMap: Map<string, number>): string {
    let vMin: { cantidad: number, version: string };
    let cantidadNiMinNiMax: number = 0;
    let vMax: { cantidad: number, version: string };

    for (const v of versionesMap.keys()) {
      const cantidad = versionesMap.get(v);
      if (!vMin && !vMax) {
        vMax = { cantidad: cantidad, version: v };
        vMin = { cantidad: cantidad, version: v };
        cantidadNiMinNiMax = cantidad;
      } else {
        if (v <= vMin.version) {
          if (v < vMin.version) {
            vMin = { cantidad: cantidad, version: v };
          } else {
            // es igual
            vMin.cantidad += cantidad;
          }
        }
        if (v >= vMax.version) {
          if (v > vMax.version) {
            vMax = { cantidad: cantidad, version: v };
          } else {
            // es igual
            vMax.cantidad += cantidad;
          }
        }
        cantidadNiMinNiMax += cantidad;
      }
    }
    cantidadNiMinNiMax -= vMax.cantidad;
    cantidadNiMinNiMax -= vMin.cantidad;

    if (vMin.version === vMax.version) {
      return `${vMin.version} (${vMin.cantidad})`;
    } else {
      return `${vMin.version} (${vMin.cantidad})  /  ${cantidadNiMinNiMax ? '...(' + cantidadNiMinNiMax + ')  /  ' : ''} ${vMax.version} (${vMax.cantidad})`;
    }
  }

  async cambiarVersionDisponible(data: DataParaTabla) {
    const nuevaVersion = prompt('Ingresa nueva versión disponible para todos los servidores de este tag')?.trim();
    let validadorVersion = new RegExp('([0-9]+).([0-9]+).([0-9]+).([0-9]+)');
    if (!validadorVersion.test(nuevaVersion)) {
      this.openSnackBar('La versión ingresada no tiene el formato de versión esperado X.X.X.X', '');
      return;
    }

    this.items = undefined;

    const promesas = [];
    for (const s of data.servidores) {
      promesas.push(this.adminCuentasService.hitearCambiarAtributoOnboarding('managers', s.idMac, 'versionDisponible', nuevaVersion, 'mac')
        .catch(e => {
          console.log('Error al cambiar version de ' + s.idMac, e);
          // this.openSnackBar('Error al cambiar version de ' + s.idMac, 'Revisar Consola (F12)');
          throw e;
        }));
    }
    try {
      await Promise.all(promesas);
      this.openSnackBar('Éxito al cambiar la versión disponible', '');
    } catch (e) {
      console.log(e);
      this.openSnackBar('Hubo un error en el proceso', 'Revisar Consola (F12)');
    }

    this.leerCuentas();
  }

  sortHeader() {
    this.dataSource.sort = this.sort;
  }

  clienteActivoSegunMetodoExporte(servidor: ServidorAdmin) {
    return servidor.idMac
      && +servidor.numeroLocal
      && servidor.nombreServidor
      && servidor.numeroLocal
      && +servidor.numeroLocal > 0
      && +servidor.numeroLocal < 9000
      && +servidor.numeroLocal !== 900
      && servidor.nombreServidor !== 'Costo alta de licencia';
  }

  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action)
      ._dismissAfter(4000);
  }
}




