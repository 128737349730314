import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { Cuenta } from '../../shared/cuenta';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { Jornada } from '../../shared/historial/jornada';
import { User } from '../../shared/user';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, } from '@angular/material/table';
import { MatSort, } from '@angular/material/sort';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Orden2 } from 'src/app/shared/historial/orden2';
import * as consts from "../../shared/constantes";


@Component({
  selector: 'app-ordenes',
  templateUrl: './ordenes.component.html',
  styleUrls: ['./ordenes.component.scss']
})
export class OrdenesComponent implements OnInit, OnDestroy {

  //#region [ rgba (100, 200, 10, 50) ] Variables
  buscando: boolean = true;

  cuenta: Cuenta;
  cuentaAdmin: CuentaAdmin;

  email: string;
  encabezado: string;
  fechaMin: string;
  fechaMax: string;
  idMac: string;
  idLocal: string;
  idCuenta: string;
  keyJornada: string;
  noHayPermiso = false;

  ordenes: Orden2[];
  ordenesObs: Observable<Orden2[]>;
  offset: number;
  subscriptionCuentas: Subscription;
  subscriptionCuentaAdmin: Subscription;

  oSelec: Orden2;
  jornada: Jornada;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  //#region Variables Tabla
  dataSource: MatTableDataSource<Orden2>;
  displayedColumns: string[] =
    [
      "idOrden", "fechaInicio", "fechaFin", "duracion", "total",
      // "comensalDetalle",
      "numMesa",
      "numTablet",
      "cobrado",
      "tipoPedido",
      "observacion",
      "mozo",
      "cliente",
      "costoEnvio",
      //"descuento", 
      "propina",


    ];
  @ViewChild(MatSort) sort: MatSort;


  //#region Filtros
  ordenesxTipos = new Map<string, { name: string, activo: boolean }>();
  mostrarFiltroTipos: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ordenesXMozos = new Map<string, { name: string, activo: boolean }>();
  mostrarFiltroMozos: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ordenesXCobros = new Map<string, { name: string, activo: boolean }>();
  mostrarFiltroCobros: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //#endregion

  //#endregion


  constructor(private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, protected router: Router, public authService: AuthService, public db: AngularFireDatabase, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  rowSeleccionada(row: Orden2, seleccionada: boolean) {
    if (seleccionada) {
      this.oSelec = row;
    } else {
      this.oSelec = undefined;
    }
  }

  tieneitems(j: Jornada): boolean {
    if (j.cantItems) {
      return true;
    } else {
      return false;
    }
  }

  esSeleccionada(row: Orden2) {
    if (row && row.idOrden && this.oSelec && this.oSelec.idOrden) {
      if (row.key === this.oSelec.key) {
        if (this.tieneitems(this.jornada)) {
          return {
            'background': 'lightgrey',
            'cursor': 'pointer',
          }
        } else {
          //return {
          //  'background': 'grey',
          //}
        }
      }
    }
    return {
    }
  }
  copiarTabla() {
    let selector;
    selector = '#tabladatos';
    let table = document.querySelector(selector);
    let range = document.createRange();
    range.selectNodeContents(table)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)

    document.execCommand('copy')

    this._snackBar.open("Se copió la tabla al portapapeles, pega los datos en un archivo de Excel o similar", "", {
      duration: 8000,
    });

  }
  verItems(o: Orden2) {
    if (this.tieneitems(this.jornada)) {
      this.router.navigateByUrl("/mypopapp/historial/pedido/" + o.key);
    } else {
      this._snackBar.open("Los pedidos de esta jornada no tienen el detalle cargado todavia", "Intenta más tarde", {
        duration: 3000,
      });
    }
  }

  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "," + mes : ",0" + mes;

    const diaString = fecha.getDate() > 9 ? "," + fecha.getDate() : ",0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  sortHeader() {
    this.dataSource.sort = this.sort;
  }
  esAdmin(user: User) {
    return consts.esAdmin(user);
  }
  ngOnInit() {
    this.keyJornada = this.activatedRoute.snapshot.params['keyJornada'];
    let arre = this.keyJornada.split(",");
    this.idCuenta = arre[0];
    let año = arre[1];
    let mes = arre[2];
    let dia = arre[3];
    this.idLocal = arre[4];
    this.idMac = arre[5];

    this.offset = (new Date()).getTimezoneOffset();
    this.dataSource = new MatTableDataSource(this.ordenes);
    this.buscando = true;
    this.authService.user.subscribe(user => {
      this.noHayPermiso = false;

      if (user && user.email) {
        // console.log("SUSCRIPTO");
        this.email = user.email;
        this.subscriptionCuentas = this.authService.cuentas$
          .subscribe(cuentas => {
            if (cuentas) {
              this.buscando = true;

              let existeLaCuenta = cuentas.map(c => c.id === this.idMac).reduce((a, v) => a ? true : v, false)
              if (existeLaCuenta || this.esAdmin(user)) {
                this.leeCuentaAdmin(this.idCuenta)

              } else {
                //No hay permiso
                this.noHayPermiso = true;
                this.buscando = false;

              }
            } else {
              this.buscando = false;
            }
          });
      } else {
        this.buscando = false;
      }
    });
  }

  ngOnDestroy(): void {
    try { this.subscriptionCuentas.unsubscribe() } catch (e) { }
    try { this.subscriptionCuentaAdmin.unsubscribe() } catch (e) { }
  }



  async leeCuentaAdmin(idCuenta: string) {
    let refCuentaAdmin = this.authService.leerFB("cuentas/" + idCuenta);

    this.subscriptionCuentaAdmin = refCuentaAdmin.snapshotChanges().subscribe(action => {
      this.cuentaAdmin = <CuentaAdmin>action.payload.val();
    });
    let ja = await this.db.object("estadisticas/jornadas/" + this.keyJornada).query.once("value", function (v) {
    })
    this.jornada = ja.val()
    this.buscar()
  }

  buscar() {
    this.buscando = true;

    let ordenesFireList = this.db.list("estadisticas/ordenes", ref => ref.orderByKey()
      .startAt(this.keyJornada + ",")
      .endAt(this.keyJornada + ",~"))

    this.ordenesObs = ordenesFireList.snapshotChanges().pipe(

      map(changes => {
        let mapeado = changes.map(o => {
          let oo = <Orden2>o.payload.val();
          return { key: o.payload.key, ...oo };
        });
        this.buscando = false;
        return this.sacaOrdenes(mapeado);
      })
    );

  }

  sacaOrdenes(mapeado): Orden2[] {
    let ordenes: Orden2[] = []
    this.ordenes = []
    this.mostrarFiltroCobros.next(false)
    this.mostrarFiltroMozos.next(false)
    this.mostrarFiltroTipos.next(false)
    this.ordenesXCobros = new Map<string, { name: string, activo: boolean }>()
    this.ordenesXMozos = new Map<string, { name: string, activo: boolean }>()
    this.ordenesxTipos = new Map<string, { name: string, activo: boolean }>();

    let fechaMin: Date;
    let fechaMax: Date;
    for (let o of mapeado) {
      let or: Orden2 = o;
      if (or) {
        if (or.tipoPedido) {
          this.ordenesxTipos.set(or.tipoPedido, { name: or.tipoPedido, activo: true });
        }
        if (or.mozo && or.mozo.nombreMozo) {
          this.ordenesXMozos.set(or.mozo.nombreMozo, { name: or.mozo.nombreMozo, activo: true });
        }
        if (or.tipoCobro && or.tipoCobro.length > 0) {
          for (let tc of or.tipoCobro) {
            this.ordenesXCobros.set(tc.nombreCobro, { name: tc.nombreCobro, activo: true });
          }
        }
      }
      if (o.fechaFin && o.fechaInicio) {
        let dateFin = new Date(o.fechaFin);
        let dateinicio = new Date(o.fechaInicio);


        o["duracion"] = (dateFin.getTime() - dateinicio.getTime()) / 1000 / 60;
        try {
          //o["local"] = this.cuentaAdmin["locales"][o.key.split(",")[4]].nombreLocal
          //o["manager"] = this.cuentaAdmin["locales"][o.key.split(",")[4]].servidores[o.key.split(",")[5]].nombreServidor
        } catch (e) { }
        o.fechaInicio = this.fechaLocal(o.fechaInicio);
        o.fechaFin = this.fechaLocal(o.fechaFin);



        if (!fechaMax) {
          fechaMax = dateFin;
        }
        if (!fechaMin) {
          fechaMin = dateinicio
        }

        if (fechaMin.getTime() > dateinicio.getTime()) {
          fechaMin = dateinicio;
        }

        if (fechaMax.getTime() < dateFin.getTime()) {
          fechaMax = dateFin;
        }


      }
      ordenes.push(o)
    }

    if (this.ordenesxTipos.size > 0) {
      this.mostrarFiltroTipos.next(true)
    }
    if (this.ordenesXMozos.size > 0) {
      this.mostrarFiltroMozos.next(true)
    }
    if (this.ordenesXCobros.size > 0) {
      this.mostrarFiltroCobros.next(true)
    }

    this.fechaMin = this.fechaLocal(fechaMin.toISOString());
    this.fechaMax = this.fechaLocal(fechaMax.toISOString());

    this.ordenes = ordenes
    this.dataSource = new MatTableDataSource(this.ordenes);
    this.dataSource.filterPredicate = this.filtroDeFiltroXOrdenes();

    this.applyFilter();
    return ordenes;
  }

  applyFilter() {
    this.dataSource.filter = "gamrgamr" + this.ordenesXCobros.values() + this.ordenesXMozos.values() + this.ordenesxTipos.values();
    this.dataSource.sort = this.sort;
  }

  filtroXTipo(o: Orden2) {
    if (this.mostrarFiltroTipos.getValue() && this.ordenesxTipos && this.ordenesxTipos.get(o.tipoPedido)) {
      return this.ordenesxTipos.get(o.tipoPedido).activo;
    } else {
      return true;
    }
  }

  filtroXMozo(o: Orden2) {
    if (this.mostrarFiltroMozos.getValue() && this.ordenesXMozos && o.mozo && o.mozo.nombreMozo && this.ordenesXMozos.get(o.mozo.nombreMozo)) {
      return this.ordenesXMozos.get(o.mozo.nombreMozo).activo;
    } else {
      return true;
    }
  }

  filtroXCobro(o: Orden2) {
    if (this.mostrarFiltroCobros.getValue() && this.ordenesXCobros) {
      for (let tc of o.tipoCobro) {
        if (this.ordenesXCobros.get(tc.nombreCobro)) {
          if (this.ordenesXCobros.get(tc.nombreCobro).activo) {
            return true;
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }

  filtroDeFiltroXOrdenes() {
    return (o: Orden2, filter: string) => {
      if (this.filtroXTipo(o)) {
        if (this.filtroXMozo(o)) {
          return this.filtroXCobro(o);
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  clickDeAlgunFiltro($event) {
    this.applyFilter();
  }

  getDuracion() {
    let acc = this.dataSource.filteredData.map(o => o.duracion).reduce((acc, value) => acc + value, 0);
    if (this.dataSource.filteredData.length > 0) {
      return acc / this.dataSource.filteredData.length
    }
    return acc

  }

  getCostosEnvio() {
    return this.dataSource.filteredData.map(o => o.costoEnvio).reduce(((acc, value) => { return value ? acc + value : acc }), 0);
  }

  getCantObservaciones() {
    return this.dataSource.filteredData.map(o => o.observacion).reduce((acc, value) => value ? acc + 1 : acc, 0);
  }

  getCantMesas() {
    return this.dataSource.filteredData.map(o => o.numMesa).reduce((acc, value) => value ? acc + 1 : acc, 0);
  }

  getPropinas() {
    return this.dataSource.filteredData.map(o => o.propina).reduce((acc, value) => acc + value, 0);
  }
  getTotal() {
    return this.dataSource.filteredData.map(o => o.total).reduce((acc, value) => acc + value, 0);
  }




  fechaLocal(fecha: string) {
    let date = new Date(fecha);
    date.setMinutes(date.getMinutes() + this.offset);
    return date.toISOString();
  }

}
