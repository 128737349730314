import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogListaMsjsData } from 'src/app/services/admin-cuenta.service';

@Component({
  selector: 'app-modal-listamsjs',
  templateUrl: './modal-listamsjs.component.html',
  styleUrls: ['./modal-listamsjs.component.scss']
})


export class ModalListamsjsComponent implements OnInit {

  msjs: { nombre: string, msj: string }[];
  msjsObs: Observable<{ nombre: string, msj: string }[]>;

  constructor(
    public db: AngularFireDatabase,
    public dialogRef: MatDialogRef<ModalListamsjsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogListaMsjsData
  ) { }

  ngOnInit() {
    this.buscar()

  }

  enviarMsj(m: { nombre: string, msj: string }) {
    this.data.msj = m.msj;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  buscar() {
    console.log("Buscar")
    let msjsFireList = this.db.list("listaMsjs");

    this.msjsObs = msjsFireList.snapshotChanges().pipe(
      map(changes => {
        let mapeado = changes.map(o => ({ nombre: o.payload.key, msj: o.payload.val() }));
        return this.sacaMsjs(mapeado);
      })
    );

  }

  sacaMsjs(mapeado): { nombre: string, msj: string }[] {
    // <mat-radio - button value = "1" > Custom < /mat-radio-button>
    //   < mat - radio - button value = "2" > Eliminar Fix Actual < /mat-radio-button>
    let msjs: { nombre: string, msj: string }[] = []
    this.msjs = []
    //msjs.push({ nombre: "ELIMINAR FIX ACTUAL", sentencia: "null" })
    for (let o of mapeado) {
      msjs.push(o)
    }

    this.msjs = msjs
    //this.applyFilter(this.filtroActual);
    return msjs;
  }

}
