
import { Component, OnInit, OnDestroy } from '@angular/core';
import { POnline } from '../test-pedidos-online/ponliine';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-buscar-pedido',
  templateUrl: './admin-buscar-pedido.component.html',
  styleUrls: ['./admin-buscar-pedido.component.scss']
})

export class AdminBuscarPedidoComponent implements OnInit, OnDestroy {
  searchValue: string;
  buscando: boolean;
  pedidos: POnline[];
  idReal: boolean = false;
  fechaDesc = true;
  environment = environment;

  constructor(private dbFs: AngularFirestore, private snackbar: MatSnackBar, private cuentaService: CuentaService) { }

  ngOnInit() {
    /*this.pedidos = this.db.list<POnline>("pedidosProcesados2/keyERROR", ref => ref.orderByChild("fechaError")).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          let data: POnline = a.payload.val();
          if (data) {
            const _key = a.payload.key;
            data["_key"] = _key;
            return data;
          } else {
            return null
          }
        });
      })
    )*/
  }

  buscar(searchValue: string) {
    searchValue = searchValue.trim()
    this.buscando = true;
    //console.log("buscando");
    if (!this.idReal) {
      this.dbFs.collectionGroup<POnline>("pedidos", ref => ref.where("displayId", "==", searchValue).orderBy('fechaCreacion', this.ordernarSegunCheckBox())).snapshotChanges().subscribe(v => {
        this.pedidos = [];
        for (const d of v) {
          this.pedidos.push(d.payload.doc.data());
          console.log(d.payload.doc.ref.path);
        }
        this.buscando = false;
      });
    } else {
      this.dbFs.collectionGroup<POnline>("pedidos", ref => ref.where("id", "==", searchValue)).snapshotChanges().subscribe(v => {
        this.pedidos = [];
        for (const d of v) {
          this.pedidos.push(d.payload.doc.data());
          console.log(d.payload.doc.ref.path);
        }
        this.buscando = false;
      });
    }
  }



  log(p: POnline) {
    this.dbFs.doc<POnline>("confLocalPO/" + p.keyLocal + "/pedidos/" + p.origen + "," + p.idStore + "," + p.id).valueChanges().subscribe(v => {
      console.log("pedido", v);
      this.snackbar.open("DONE", "Revisa la consola (F12) -->")._dismissAfter(2000);
    });
  }

  fecha(f: string): string {
    if (!f) {
      return "Sin fecha";
    }
    let d = new Date(f);
    d.setHours(d.getHours());
    return (d.getDate() < 10 ? "0" : "") + d.getDate() + "/" +
      (d.getMonth() < 9 ? "0" : "") + (d.getMonth() + 1) + "/" +
      (d.getDate() < 10 ? "0" : "") + d.getFullYear() + " " +
      (d.getHours() < 10 ? "0" : "") + d.getHours() + ":" +
      (d.getMinutes() < 10 ? "0" : "") + d.getMinutes()// + ":" +
    //(d.getSeconds() < 10 ? "0" : "") + d.getSeconds()

  }

  //baseUrl = "http://localhost:3000/pedidosonline/";
  baseUrl = environment.urls.escuchador + "/pedidosonline/";

  keysTipoErrores(p1) {
    let tiposErrores = "";
    for (let k in p1.errorTags) {
      tiposErrores += k;
    }

    return tiposErrores;
  }

  async actualizarUpdatedAt(p: POnline) {
    try {
      await this.cuentaService.postUrlProm(`${environment.urls.functions}/orders/${p.keyLocal}/update/updated_at`, [{
        origen: p.origen, idStore: p.idStore, idPedido: p.id
      }]);
      this.snackbar.open("Éxito", "✅")._dismissAfter(2000);
    } catch (err) {
      console.log(err);
      this.snackbar.open("Error, por favor reintenta", "❗")._dismissAfter(2000);
    }
  }

  migrarAPOnline(p: POnline) {
    this.cuentaService.getUrlProm(this.baseUrl + "migrar/" + p.keyLocal + "/" + p.origen + "," + p.idStore + "," + p.id).then(v => {

      let snack = this.snackbar.open("Migrado", "VER");
      snack._dismissAfter(4000);
      snack.onAction().subscribe(v => {
        let url = "https://app.popapp.io/ponline/pedidos/keyERROR/porpreparar";
        window.open(url, "_blank");
      });
    }).catch(e => {
      console.error("error al migrar", e);
      this.snackbar.open("No se pudo completar la acción", "ERROR")._dismissAfter(2000);
    })

  }

  ngOnDestroy() {

  }

  ordernarSegunCheckBox() {
    return this.fechaDesc ? 'desc' : 'asc';
  }

}
