import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { firebase } from '@firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { AngularFireDatabase } from '@angular/fire/database';


import { Observable, of, BehaviorSubject, } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Cuenta } from '../shared/cuenta';
import { CuentaService } from '../services/cuenta.service';
import { User } from '../shared/user';
import { EmailCuenta } from '../shared/admin/emailCuenta';


export class EmailPasswordCredentials {
  email: string;
  password: string;
}

export interface InfoCuenta {
  mac: string;
  marca: string;
  marcaFotoChicaURL: string;
  marcaFotoURL: string;
  idCuenta: string;
  idLocal: string;
}

export interface CuentaManager {
  idCuenta: string;
  nombreCuenta: string;
  selected: boolean;
  managers: Manager[];
}

export interface Manager {
  idCuenta: string;
  idLocal: string;
  idMac: string;
  nombreServidor: string;
  selected: boolean;
}


@Injectable({ providedIn: 'root' })
export class AuthService {

  plan: number;
  // Observable navItem source
  private _cuentas = new BehaviorSubject<Cuenta[]>(null);
  // Observable navItem stream
  cuentas$ = this._cuentas.asObservable();
  moneda: string;
  pais: string;
  isDlocal: boolean;
  // service command
  async changeCuentas(cuentas: Cuenta[]) {
    this._cuentas.next(cuentas);

    this.cuentasManagersBehSub.next(null);
    let cuentasManager: CuentaManager[] = [];
    for (let c of cuentas) {
      let xthis = this;
      this.db.object("macsPasadas/" + c.id).query.once("value").then(s => {
        s.val();
        let mPasad = s.val();
        if (mPasad && mPasad.idCuenta && mPasad.idLocal) {
          let cms = cuentasManager.filter(x => x.idCuenta === mPasad.idCuenta);
          if (cms && cms[0]) {
            cms[0].managers.push({
              idCuenta: mPasad.idCuenta,
              idLocal: mPasad.idLocal,
              idMac: c.id,
              nombreServidor: c.name,
              selected: true
            })
          } else {
            cuentasManager.push({
              idCuenta: mPasad.idCuenta,
              nombreCuenta: c.name,
              selected: true,
              managers: [{
                idCuenta: mPasad.idCuenta,
                idLocal: mPasad.idLocal,
                idMac: c.id,
                nombreServidor: c.name,
                selected: true
              }]
            })
          }
          xthis.cuentasManagersBehSub.next(cuentasManager);
        } else {
          console.log("No hay mac pasada para ", s.key);
        }
      });
    }
  }

  cuentasManagersBehSub = new BehaviorSubject<CuentaManager[]>(null);
  cuentasManagersObser = this.cuentasManagersBehSub.asObservable();
  // Observable navItem source
  private _cuenta = new BehaviorSubject<Cuenta>(null);
  // Observable navItem stream
  cuenta$ = this._cuenta.asObservable();

  public infoCuenta = new BehaviorSubject<InfoCuenta>(null);

  // service command
  changeCuenta(cuenta: Cuenta) {
    this._cuenta.next(cuenta);
    this.db.object("infoCuentas/" + cuenta.id).query.once("value").then(v => {
      this.infoCuenta.next(v.val());
    });
  }




  // Observable navItem source
  private _bodyAPagar = new BehaviorSubject<any>(null);
  // Observable navItem stream
  bodyAPagar$ = this._bodyAPagar.asObservable();

  urlAPagar: string;
  idCuentaAPagar: string;
  ultimoPago: string;

  user: Observable<User | null>;
  rutaAlAQueIba: string;
  rutaUsuarios = "users/";
  rutaEmailsCuentas = "emailsCuentas/";

  emailCuenta: EmailCuenta

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private router: Router,
    private cuentaService: CuentaService) {


    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user && user.email) {
          //console.log("user", user)
          return this.db.object(this.rutaUsuarios + this.decodeEmail(user.email)).valueChanges()
        } else {
          return of(null)
        }
      })
    )
    /*this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      }),
      tap(user => localStorage.setItem('user', JSON.stringify(user))),
      startWith(JSON.parse(localStorage.getItem('user')))
    );*/
  }


  private oAuthLogin(provider, esNuevo: boolean) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) => {
        if (esNuevo) {
          this.updateUserData(credential.user, esNuevo, "lead desde web google")

        } else {

          this.updateUserData(credential.user, esNuevo)
        }
      }).catch(error => console.error(error));
  }

  setDataAPagar(urlAPagar: string, bodyAPagar: { items: any[] }, idCuentaAPagar: string, ultimoPago: string, moneda: string, pais: string) {
    this.idCuentaAPagar = idCuentaAPagar
    this.ultimoPago = ultimoPago
    this.urlAPagar = urlAPagar;
    this._bodyAPagar.next(bodyAPagar);
    this.moneda = moneda;
    this.pais = pais;
  }

  sendEmailVerif(email: string) {

    // this.afAuth.sendEmailVerification()
    //   .then(() => {
    //     // Verification email sent.
    //     this.db.object("emailsCuentas/" + this.decodeEmail(email) + "/mandoMailVerificacion").set(true)
    //   })
    //   .catch(error => {
    //     // Error occurred. Inspect error.code.
    //     this.mandarEmailError("Error al intentar enviar el email de verificacion", JSON.stringify(error))
    //   });
  }


  auth() {
    return this.afAuth;
  }

  leerFB(ref: string) {
    return this.db.object(ref);
  }



  signOut(desdeBienvenido?: boolean) {
    this.afAuth.signOut().then(() => {
      if (desdeBienvenido) {
        this.router.navigate(['/comenzar']);
      } else {
        this.router.navigate(['/mypopapp']);
      }
    });
  }


  decodeEmail(email: string) {
    while (email && email.includes(".")) {
      email = email.replace(".", ",")
    }
    return email
  }

  private updateUserData(user, esNuevo: boolean, leadDesde?: string) {
    // Sets user data to firestore on login
    if (user && user.email && user.email.length > 3) {
      let email = this.decodeEmail(user.email)
      let mapUpdates = {}

      mapUpdates[this.rutaUsuarios + email + "/uid/"] = user.uid
      mapUpdates[this.rutaUsuarios + email + "/email/"] = user.email
      mapUpdates[this.rutaUsuarios + email + "/emailVerified/"] = user.emailVerified
      if (user.providerData && user.providerData[0] && user.providerData[0].providerId)
        mapUpdates[this.rutaUsuarios + email + "/providerId/"] = user.providerData[0].providerId
      if (user.displayName)
        mapUpdates[this.rutaUsuarios + email + "/displayName/"] = user.displayName
      if (user.photoURL)
        mapUpdates[this.rutaUsuarios + email + "/photoURL/"] = user.photoURL
      if (user.metadata)
        mapUpdates[this.rutaUsuarios + email + "/metadata/"] = user.metadata

      //si es nuevo debemos registrar el lead y los datos para el onboarding desde c#
      if (esNuevo) {
        mapUpdates[this.rutaEmailsCuentas + email + "/creadoDesde"] = leadDesde
        mapUpdates[this.rutaEmailsCuentas + email + "/email"] = user.email
        mapUpdates[this.rutaEmailsCuentas + email + "/creadoDate"] = { ".sv": "timestamp" }
        if (user.displayName)
          mapUpdates[this.rutaEmailsCuentas + email + "/nombre"] = user.displayName
        if (user.emailVerified)
          mapUpdates[this.rutaEmailsCuentas + email + "/verificadoEmail"] = true

        //console.log("enviando email...")
        //this.mandarEmailAJorgeYFacu(leadDesde, user.displayName, user.emailVerified, user.email)
        //.then(_ => {
        // console.log("email enviado exitosamente")
        // mapUpdates["emailsCuentas/" + email + "/emailLeadEnviado"] = true
        //})
      }
      //  console.log("enviando a firebase...")
      return this.db.object("/").update(mapUpdates)
    }
  }

  mandarEmailError(asunto, error) {
    //    let urlServidorMail = "environment.urls.escuchador/payments/mails/error";

    let body = {
      notification: {
        title: "popapp",
        to: "pmarin@popapp.io, cmarin@popapp.io",
        copia: "gmarin@popapp.io",
        asunto: "Web - " + asunto,
        text: error
      }
    }
    this.cuentaService.enviarEmailAFSProm(body, "error");
    // this.cuentaService.enviaMail(body, urlServidorMail);
  }
  /*mandarEmailAJorgeYFacu(leadDesde: string, nombre: string, emailVerificado: boolean, email: string, ) {
    let urlServidorMail = "environment.urls.escuchador/payments/mails/leads";
   
    let cuerpoDeMail = "email: " + email + "\n" +
      leadDesde + "\n" +
      "nombre: " + nombre + "\n" +
      "Email Verificado: " + (emailVerificado ? "Si" : "NO")
   
    let body = {
      notification: {
        title: "popapp",
        
        copia: "gmarin@popapp.io",
        asunto: "Nuevo Lead " + email,
        text: cuerpoDeMail
      }
    }
    return this.cuentaService.enviaMail(body, urlServidorMail);
  }*/




  ////// OAuth Methods /////
  googleLogin(esNuevo: boolean) {
    const provider = new firebase["auth"].GoogleAuthProvider();
    return this.oAuthLogin(provider, esNuevo);
  }

  /*githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.oAuthLogin(provider);
  }
   
  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }
   
  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }
   
   
  //// Anonymous Auth ////
  anonymousLogin() {
    return this.afAuth.auth
      .signInAnonymously()
      .then(credential => {
        this.notify.update('Welcome to Firestarter!!!', 'success');
        return this.updateUserData(credential.user); // if using firestore
      })
      .catch(error => {
        this.handleError(error);
      });
  }
   
  */


  //// Email/Password Auth ////
  emailSignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        return this.updateUserData(credential.user, true, "lead desde web email");
      })

  }

  emailLogin(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        return this.updateUserData(credential.user, false);
      })
    //el catch se agarra desde donde se llama

  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }


  setRutaAlaQueIba(url) {
    this.rutaAlAQueIba = url;
  }


}