import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { PuntuacionJornada } from 'src/app/shared/admin/puntuacionJornada';
import { SelectionModel } from '@angular/cdk/collections';
import * as consts from "../../shared/constantes";
import { Cuenta } from 'src/app/shared/cuenta';
import { ResellerGuard } from 'src/app/guards/reseller.guard';

@Component({
  selector: 'app-admin-puntuaciones-jornada',
  templateUrl: './admin-puntuaciones-jornada.component.html',
  styleUrls: ['./admin-puntuaciones-jornada.component.scss']
})
export class AdminPuntuacionesJornadaComponent implements OnInit, OnDestroy {

  //#region [ rgba (100, 200, 10, 50) ] Variables
  buscando: boolean = true;

  cuentaAdmin: CuentaAdmin;

  email: string;
  encabezado: string;
  idMac: string;
  idLocal: string;
  idCuenta: string;
  cant: number;
  noHayPermiso = false;

  puntuacionesJornadas: PuntuacionJornada[];
  puntuacionesObs: Observable<PuntuacionJornada[]>;
  offset: number;
  subscriptionCuentaAdmin: Subscription;
  cuentas: Cuenta[];
  reseller: boolean;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  //#region Variables Tabla
  dataSource: MatTableDataSource<PuntuacionJornada>;
  selection = new SelectionModel<PuntuacionJornada>(true, []);
  displayedColumns: string[] =
    [
      "select",
      "fecha",
      "estado",
      "asunto",
      "puntuacion",
      "comentario",
      "local",
      "mac",
    ];
  @ViewChild(MatSort) sort: MatSort;

  //#endregion


  constructor(private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, protected router: Router, public authService: AuthService, public db: AngularFireDatabase, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private resellerGuard: ResellerGuard) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "," + mes : ",0" + mes;

    const diaString = fecha.getDate() > 9 ? "," + fecha.getDate() : ",0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  sortHeader() {
    this.dataSource.sort = this.sort;
  }
  driftVar: any;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  deseleccionarTodes() {
    this.selection.clear();
  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }

  ngOnInit() {

    this.hideDrift(1)
    this.idCuenta = this.activatedRoute.snapshot.params['idCuenta'];
    if (!this.idCuenta) {
      this.cant = +this.activatedRoute.snapshot.params['cant'];
    }
    this.offset = (new Date()).getTimezoneOffset();
    this.dataSource = new MatTableDataSource(this.puntuacionesJornadas);
    this.buscando = true;
    this.authService.user.subscribe(user => {
      this.noHayPermiso = false;
      if (user && user.email) {
        // console.log("SUSCRIPTO");
        this.email = user.email;
        this.buscando = true;

        if (this.esAdmin(user)) {
          this.leeCuentaAdmin(this.idCuenta, this.cant)
        } else {
          this.resellerGuard.canActivate(null, null).then(v => {
            this.reseller = v;
            if (this.reseller) {
              this.authService.cuentas$.subscribe(cuentas => {
                this.cuentas = cuentas;
                if (cuentas) {
                  this.leeCuentaAdmin(this.idCuenta, this.cant)
                }
              })
            } else {
              //No hay permiso
              this.noHayPermiso = true;
              this.buscando = false;
            }
          })
        }
      } else {
        this.buscando = false;
      }
    });
  }

  ngOnDestroy(): void {
    try { this.subscriptionCuentaAdmin.unsubscribe() } catch (e) { }
  }


  async leeCuentaAdmin(idCuenta: string, cant: number) {
    let refCuentaAdmin = this.authService.leerFB("cuentas/" + idCuenta);

    this.subscriptionCuentaAdmin = refCuentaAdmin.snapshotChanges().subscribe(action => {
      this.cuentaAdmin = <CuentaAdmin>action.payload.val();
    });

    this.buscar()
  }

  buscar() {
    this.buscando = true;
    let ordenesFireList: AngularFireList<any>
    if (this.idCuenta) {
      ordenesFireList = this.db.list("puntuaciones/jornadas", ref => ref.orderByKey()
        .startAt(this.idCuenta + ",")
        .endAt(this.idCuenta + ",~"))

    } else if (this.cant) {
      ordenesFireList = this.db.list("puntuaciones/jornadas", ref => ref.orderByChild("fecha").limitToLast(this.cant))
    }

    if (ordenesFireList) {
      this.puntuacionesObs = ordenesFireList.snapshotChanges().pipe(
        map(changes => {
          let mapeado = changes.map(o => ({ key: o.payload.key, ...o.payload.val() }));
          this.buscando = false;
          return this.sacaPuntJornadas(mapeado);
        })
      );
    } else {
      this._snackBar.open("Error, no hay cuenta o cant de puntuaciones para mostrar", "", {
        duration: 5000,
      });
      console.log("Error, no hay cuenta o cant de puntuaciones para mostrar")
    }

  }

  sacaPuntJornadas(mapeado): PuntuacionJornada[] {
    let pjs: PuntuacionJornada[] = []
    this.puntuacionesJornadas = []

    for (let pj of mapeado) {
      if (!pj.estado) {
        if (pj.comentario || pj.puntuacion < 5) {
          pj.estado = "ABIERTO"
          if (pj.comentario && pj.puntuacion < 5) {
            pj.asunto = "Puntuo bajo y comento"
          } else if (pj.comentario) {
            pj.asunto = "Comento"
          } else {
            pj.asunto = "Puntuo bajo"
          }
        }
      }
      if (this.reseller) {
        if (this.cuentas.find(x => x.id === pj.mac)) {
          pjs.push(pj)
        } else {
          //hay q sumar 1 a la query
        }
      } else {
        pjs.push(pj)

      }
    }


    this.puntuacionesJornadas = pjs
    this.dataSource = new MatTableDataSource(this.puntuacionesJornadas);
    this.deseleccionarTodes()
    //this.applyFilter(this.filtroActual);
    return pjs;
  }
  actualizaEstado(pjs: PuntuacionJornada[], p1: PuntuacionJornada) {
    let est = prompt("Estado: ABIERTO, CERRADO, RESUELTO", "CERRADO");
    if (est && (est === "ABIERTO" || est === "RESUELTO" || est === "CERRADO")) {
      let mapUpdates = {};

      for (let p of pjs) {
        mapUpdates[p.key + "/estado"] = est;
      }

      if (!mapUpdates[p1.key + "/estado"]) {
        mapUpdates[p1.key + "/estado"] = est;
      }
      this.db.object("puntuaciones/jornadas").update(mapUpdates);
    }
  }

  color(stars: number) {
    if (stars < 1.5) {
      return { color: "#D50000", "font-weight": "bold" }
    } else if (stars < 2.5) {
      return { color: "#FF6D00", "font-weight": "bold" }
    } else if (stars < 3.5) {
      return { color: "#FFEB3B", "font-weight": "bold" }
    } else if (stars < 4.5) {
      return { color: "#558B2F", "font-weight": "bold" }
    } else {
      return { color: "#2E7D32", "font-weight": "bold" }
    }
  }

  getPuntuacion() {
    return this.puntuacionesJornadas.map(o => o.puntuacion).reduce(((acc, value) => { return value ? acc + value : acc }), 0);
  }

  getComentarios() {
    return this.puntuacionesJornadas.map(o => o.comentario).reduce((acc, value) => value ? acc + 1 : acc, 0);
  }

  getPromedio() {
    return this.getPuntuacion() / this.puntuacionesJornadas.length;
  }



  getLocales() {
    let mapLocales = new Map<string, boolean>();
    for (let j of this.dataSource.filteredData) {
      mapLocales.set(j.key[4], true)
    }

    if (mapLocales.size === 1) {
      return "1 Local"
    } else {
      return mapLocales.size + " Locales"
    }
  }

  getManagers() {
    let mapLocales = new Map<string, boolean>();
    for (let j of this.dataSource.filteredData) {
      mapLocales.set(j.mac, true)
    }

    if (mapLocales.size === 1) {
      return "1 Manager"
    } else {
      return mapLocales.size + " Managers"
    }
  }


  fechaLocal(fecha: string) {
    let date = new Date(fecha);
    date.setMinutes(date.getMinutes() + this.offset);
    return date.toISOString();
  }

}
