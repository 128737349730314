import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from '../../../node_modules/rxjs';
import { AuthService } from '../core/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pagardlocal',
  templateUrl: './pagardlocal.component.html',
})
export class PagardlocalComponent implements OnInit, OnDestroy {

  subscriptionBody: Subscription;

  importeTotal: number;
  linkPago: string;
  moneda: string;
  pais: string;
  idCuentaAPagar: string;
  ultimoPagoAnterior: string;
  ultimoPagoNuevo: any;
  timerX: string;

  loading: boolean = false;

  constructor(private snackBar: MatSnackBar, private authService: AuthService, private http: Http) {
    this.loading = false;
  }

  ngOnInit() {
    this.loading = false;
    this.subscriptionBody = this.authService.bodyAPagar$
      .subscribe(body => {
        if (body && this.authService.urlAPagar) {
          this.loading = true;
          this.ultimoPagoAnterior = this.authService.ultimoPago;
          this.idCuentaAPagar = this.authService.idCuentaAPagar;
          this.moneda = body.currency_original;
          this.pais = body.country;

          console.log(body);
          this.creaPagoenDLocalYObtieneLink(body, this.authService.urlAPagar);
          this.startTimer();

        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptionBody.unsubscribe();
  }
  startTimer() {

    // Set the date we're counting down to
    let auxDate = new Date();
    auxDate.setMinutes(auxDate.getMinutes() + 20);
    auxDate.setSeconds(auxDate.getSeconds() - 10);
    let countDownDate = auxDate.getTime();
    this.timerX = "20:00"

    // Update the count down every 1 second
    let x = setInterval(() => {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      this.timerX = (minutes < 10 ? "0" + minutes : minutes)
        + ":" + (seconds < 10 ? "0" + seconds : seconds);


      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        location.reload();
      }
    }, 1000);
  }

  creaPagoenDLocalYObtieneLink(body: any, url: string): void {
    this.httpPostPago(body, url)
      .then(respuesta => {
        //console.log("Respuestaaa", respuesta);
        if (respuesta && respuesta.data && respuesta.data.redirect_url) {
          this.loading = false;
          this.importeTotal = body.total_amount;
          this.moneda = body.currency_amount;
          this.linkPago = respuesta.data.redirect_url;
        }

        //empezar a escuchar en cuenta.ultimoPago para mostrar exito
        let refMacP = this.authService.leerFB('cuentas/' + this.idCuentaAPagar + '/ultimoPago/');
        refMacP.snapshotChanges().subscribe(action => {

          if (action && action.payload) {
            this.ultimoPagoNuevo = action.payload.val();
          }

        });
      },
        error => {
          this.loading = false;
        }
      );
  }

  httpPostPago(body: { items: any[] }, url: string): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    return this.http.post(url, body, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorObservable);
  }
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }



  obtenerLinkDlocal() {
    this.http.post('https://billing.dev.popapp.io/v1/payments/', {
      accountId: this.idCuentaAPagar,
      amount: 5000, //resolver en back
      currency: this.moneda,
      country: this.mapearPaises(this.pais)
    })
  }

  mapearPaises(paisAdmin: string){
    switch(paisAdmin){
      case "ARG": return "AR";
      case "CHI": return "Cl";
      default: return paisAdmin;
    }
  }


}
