import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { Cuenta } from '../shared/cuenta';
import { CuentaAdmin } from '../shared/admin/cuenta-admin';
import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Jornada } from '../shared/historial/jornada';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, } from '@angular/material/table';
import { MatDatepicker, MatDatepickerInputEvent, } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MediaMatcher } from '@angular/cdk/layout';
import { LineChartConfig } from '../charts/linechartconfig';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ModalTextoComponent, DatosTexto } from '../modales/modal-texto/modal-texto.component';


@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss'],

})
export class HistorialComponent implements OnInit, OnDestroy {

  //#region [ rgba (100, 200, 10, 50) ] Variables
  buscando: boolean = false;
  botonConsultarDesactivado: boolean = false;
  cuentas: Cuenta[];
  cuentasAdmin: Map<string, CuentaAdmin>;

  email: string;
  encabezado: string;
  //fechaDesde: string = "2019,10,24"
  //fechaHasta: string = "2019,10,24"
  fD = new Date();
  fH = new Date();
  fechaDesde = this.convierteDateAStringPBuscar(this.fD);
  fechaHasta = this.convierteDateAStringPBuscar(this.fH);
  idsMac: string;
  idsLocal: string;
  idsCuenta: Map<string, number>;
  jornadas: Jornada[];


  offset: number;
  subscriptionCuenta: Subscription;
  subscriptionCuentaAdmin: Subscription[];
  subscriptionMacsPasadas: Subscription[];
  subscriptionJornadas: Subscription[];
  //#region Variables Grafico
  config: LineChartConfig;
  data: any[];
  elementId: string;
  jornadasUnidasXDia: boolean;;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  //#endregion

  //#region Filtro Local/Manager
  jornadasManager = new Map<string, { name: string, activo: boolean }>();
  mostrarFiltroLocales: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //#endregion

  //#region Filtro Jornadas Vacias
  //ocultarVacias: boolean = true;
  jornadasVacias = new Map<string, { name: string, activo: boolean }>();
  mostrarFiltroJornadasVacias: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //#endregion

  //#region Filtro Cuentas
  cuentasAdminFiltros = new Map<string, { name: string, activo: boolean }>();
  mostrarFiltroCuentas: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //#endregion

  //#region Variables Tabla
  dataSource: MatTableDataSource<Jornada>;
  displayedColumns: string[] =
    [//"select",
      "horaInicio", "horaFin",

      "resumenJornada",
      "archivoExporte",

      "duracion",
      "subtotal", "descuentos", "propinas", "total", "local", "manager",
      "cantComensales",
      "cantItems",

      "cantPedidosCobrados",
      "cantPedidosDelivery",
      "cantPedidosEliminados",
      "cantPedidosExpress",
      "cantPedidosSalon",
      "cantPedidosYa",
      "cantRappi",


    ];
  selection = new SelectionModel<Jornada>(true, []);
  @ViewChild(MatSort) sort: MatSort;

  //#endregion

  maxDate: Date = new Date();
  @ViewChild('picker') picker: MatDatepicker<Date>;
  @ViewChild('picker2') picker2: MatDatepicker<Date>;

  //#endregion


  constructor(private _snackBar: MatSnackBar,
    protected router: Router,
    public authService: AuthService,
    public db: AngularFireDatabase,
    public changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private media: MediaMatcher,
    private datePipe: DatePipe) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  jSelec: Jornada
  rowSeleccionada(row: Jornada, seleccionada: boolean) {
    if (seleccionada) {
      this.jSelec = row;
    } else {
      this.jSelec = undefined;
    }
  }

  tienePedidos(j: Jornada): boolean {
    if (j.cantPedidosCobrados ||
      j.cantPedidosDelivery ||
      j.cantPedidosEliminados ||
      j.cantPedidosExpress ||
      j.cantPedidosSalon ||
      j.cantPedidosYa ||
      j.cantRappi
    ) {
      return true;
    } else {
      return false;
    }
  }
  esJornadaSeleccionada(row: Jornada) {
    if (row && row.key && this.jSelec && this.jSelec.key) {
      if (row.key === this.jSelec.key) {
        if (this.tienePedidos(this.jSelec)) {
          return {
            'background': 'lightgrey',
            'cursor': 'pointer'
          }
        } else {
          //return {
          //'background': 'grey',
          //}
        }
      }
    }
    return {
    }
  }
  verResumen(j: Jornada) {
    if (j.resumenJornada) {
      //console.log("jres", j.resumenJornada)
      let textos = j.resumenJornada.split("\r\n");
      //console.log("tex", textos)

      let data: DatosTexto = {
        titulo: "Jornada " + this.datePipe.transform(j.horaInicio, 'dd MMM HH') + "hs.",
        textos: textos
      };
      this.dialog.open(ModalTextoComponent, {
        width: '85%',
        data: data
      });

    } else {
      this._snackBar.open("La jornada no tiene resumen", "", {
        duration: 2000,
      });
    }
  }
  verOrdenes(j: Jornada) {
    if (this.tienePedidos(j)) {
      this.router.navigateByUrl("/mypopapp/historial/" + j.key);
    } else {
      this._snackBar.open("La jornada seleccionada no tiene ordenes todavía", "", {
        duration: 2000,
      });
    }
  }

  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "," + mes : ",0" + mes;

    const diaString = fecha.getDate() > 9 ? "," + fecha.getDate() : ",0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  cambioFecha(picker: number, event: MatDatepickerInputEvent<Date>) {
    if (picker === 1) {
      //fecha desde
      this.fechaDesde = this.convierteDateAStringPBuscar(event.value);
    } else {
      //fecha hasta
      this.fechaHasta = this.convierteDateAStringPBuscar(event.value);
    }
    this.actualizarBotonConsultar(event);
  }


  abrirPicker(intentos: number) {
    if (this.picker) {
      this.picker.open();
    } else {
      if (intentos < 5) {
        intentos++;
        setTimeout(() => this.abrirPicker(intentos), 1000)
      }
    }
  }

  abrirPicker2(intentos: number) {
    if (this.picker2) {
      this.picker2.open();
    } else {
      if (intentos < 5) {
        intentos++;
        setTimeout(() => this.abrirPicker2(intentos), 1000)
      }
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  sortHeader() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.jornadasUnidasXDia = true;
    this.offset = (new Date()).getTimezoneOffset();
    this.dataSource = new MatTableDataSource(this.jornadas);
    this.buscando = false;
    this.authService.user.subscribe(user => {
      if (user && user.email) {
        this.email = user.email;
        this.subscriptionCuenta = this.authService.cuentas$
          .subscribe(cuentas => {
            if (cuentas) {

              this.cuentas = cuentas;
              this.cargarIdCuentaYLocal(this.cuentas)
            } else {
              this.buscando = false;
            }
          });
      } else {
        this.buscando = false;
      }
    });
  }

  ngOnDestroy(): void {
    try { this.subscriptionCuenta.unsubscribe() } catch (e) { }

    if (this.subscriptionMacsPasadas && this.subscriptionMacsPasadas.length > 0) {
      for (let s of this.subscriptionMacsPasadas) {
        try { s.unsubscribe() } catch (e) { }
      }
    }

    if (this.subscriptionCuentaAdmin && this.subscriptionCuentaAdmin.length > 0) {
      for (let s of this.subscriptionCuentaAdmin) {
        try { s.unsubscribe() } catch (e) { }
      }
    }

    try {
      if (this.subscriptionJornadas && this.subscriptionJornadas.length > 0) {
        for (let s of this.subscriptionJornadas) {
          try { s.unsubscribe() } catch (e) { }
        }
      }

    } catch (e) { }

  }



  cargarIdCuentaYLocal(cuentas: Cuenta[]) {

    //leerUsuario para ver si tiene stockCentralizado y keyCarta o carta y mails
    //ASDthis.idMac = cuenta.id;
    if (this.subscriptionMacsPasadas && this.subscriptionMacsPasadas.length > 0) {
      for (let s of this.subscriptionMacsPasadas) {
        try {
          s.unsubscribe();
        } catch (e) {
          console.log(e)
        }
      }
    }

    if (this.subscriptionCuentaAdmin && this.subscriptionCuentaAdmin.length > 0) {
      for (let s of this.subscriptionCuentaAdmin) {
        try {
          s.unsubscribe();
        } catch (e) {
          console.log(e)
        }
      }

    }


    this.subscriptionMacsPasadas = []
    this.subscriptionCuentaAdmin = []
    this.cuentasAdmin = new Map<string, CuentaAdmin>()
    this.mostrarFiltroCuentas.next(false)
    this.cuentasAdminFiltros = new Map<string, { name: string, activo: boolean }>();

    this.idsCuenta = new Map<string, number>();


    for (let c of cuentas) {
      let refMacsPasadas = this.authService.leerFB("macsPasadas/" + c.id);
      this.subscriptionMacsPasadas.push(
        refMacsPasadas.snapshotChanges().subscribe(action => {
          this.buscando = true;

          let macsPasadas = action.payload.val()
          if (macsPasadas && macsPasadas["idCuenta"] && macsPasadas["idLocal"]) {
            //ASDthis.idLocal = macsPasadas["idLocal"]
            let idCuenta = macsPasadas["idCuenta"]
            let v = this.idsCuenta.get(idCuenta);
            if (v)
              this.idsCuenta.set(idCuenta, v + 1)
            else
              this.idsCuenta.set(idCuenta, 1)


            this.leeCuentaAdmin(idCuenta);

          } else {
            //ASDthis.idLocal = undefined
            //ASDthis.idCuenta = undefined
            this.buscando = false;

          }
        })
      );
    }
  }

  leeCuentaAdmin(idCuenta: string) {
    let v = this.idsCuenta.get(idCuenta);
    if (v === 1) {
      let refCuentaAdmin = this.authService.leerFB("cuentas/" + idCuenta);

      this.subscriptionCuentaAdmin.push(refCuentaAdmin.snapshotChanges().subscribe(action => {
        let c = <CuentaAdmin>action.payload.val();
        this.buscando = false

        this.cuentasAdmin.set(idCuenta, c);
        if (this.cuentasAdmin.size > 1) {
          this.cuentasAdminFiltros.set(idCuenta, { name: c.nombreCuenta ? c.nombreCuenta : " - ", activo: false })
        } else {
          this.cuentasAdminFiltros.set(idCuenta, { name: c.nombreCuenta ? c.nombreCuenta : " - ", activo: true })
        }

        if (this.cuentasAdminFiltros.size > 1) {
          this.mostrarFiltroCuentas.next(true)
        }

      }));

    } else {
      //  console.log("v", v)
      //No hacemos nada pq ya estamos leyendo la cuenta
    }
  }

  buscarCuentasFiltradas() {
    this.buscando = true;

    if (this.subscriptionJornadas && this.subscriptionJornadas.length > 0) {
      for (let s of this.subscriptionJornadas) {
        try {
          s.unsubscribe();
        } catch (e) {
          console.log(e)
        }
      }
    }
    this.jornadas = []
    this.dataSource = new MatTableDataSource(this.jornadas);
    this.subscriptionJornadas = []
    this.mostrarFiltroLocales.next(false)
    this.mostrarFiltroJornadasVacias.next(false)
    this.jornadasManager = new Map<string, { name: string, activo: boolean }>()
    this.jornadasVacias = new Map<string, { name: string, activo: boolean }>();

    for (let idCuenta of this.cuentasAdminFiltros.keys()) {
      if (this.cuentasAdminFiltros.get(idCuenta).activo) {

        this.buscar(this.fechaDesde, this.fechaHasta, idCuenta)
      }
    }
  }

  buscar(fechaDesde, fechaHasta, idCuenta) {
    this.botonConsultarDesactivado = true;

    let jornadasFireList = this.db.list("estadisticas/jornadas", ref => ref.orderByKey()
      .startAt(idCuenta + "," + fechaDesde)
      .endAt(idCuenta + "," + fechaHasta + "~"))


    this.subscriptionJornadas.push(jornadasFireList.snapshotChanges().subscribe(changes => {
      this.buscando = false;
      changes.map(j => {
        let jj: Jornada = <Jornada>j.payload.val();
        this.sacaJornadas({ key: j.payload.key, ...jj });
      });

      this.jornadasManager
      if (this.jornadasManager.size > 1) {
        this.mostrarFiltroLocales.next(true)
      }

      if (this.jornadasVacias.size > 0) {
        this.mostrarFiltroJornadasVacias.next(true)
      }

      this.jornadas.sort((j1, j2) => {
        if (j1.horaInicio < j2.horaInicio) {
          return -1;
        } else if (j1.horaInicio > j2.horaInicio) {
          return 1
        } else {
          return 0
        }
      })
      this.dataSource = new MatTableDataSource(this.jornadas);
      this.dataSource.filterPredicate = this.filtroDeFiltroJornadas();
      this.applyFilter();
      //this.applyFilter(this.filtroActual);
      this.buscando = false;
      return this.jornadas
    }))
  }
  esJornadaVacia(j: Jornada): boolean {
    if (
      j.subtotal ||
      j.cantPedidosCobrados ||
      j.cantPedidosDelivery ||
      j.cantPedidosEliminados ||
      j.cantPedidosExpress ||
      j.cantPedidosSalon ||
      j.cantPedidosYa ||
      j.cantRappi
    ) {
      return false;
    } else {
      return true;
    }
  }


  cambiarOcultas($event) {
    this.applyFilter();
  }

  copiarTabla() {
    let selector;
    selector = '#tabladatos';
    let table = document.querySelector(selector);
    let range = document.createRange();
    range.selectNodeContents(table)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)

    document.execCommand('copy')

    this._snackBar.open("Se copió la tabla al portapapeles, pega los datos en un archivo de Excel o similar", "", {
      duration: 8000,
    });

  }

  sacaJornadas(jo: Jornada): Jornada {
    let arreKey = jo.key.split(",");
    jo.idCuenta = arreKey[0];
    jo.idLocal = arreKey[4];
    jo.idMac = arreKey[5];

    if (!this.cuentas.find(x => x.id === jo.idMac)) {
      //Solo las jornadas que tenga ese usuario permitido
      return undefined;
    }

    if (this.esJornadaVacia(jo)) {
      this.jornadasVacias.set(jo.key, { name: this.datePipe.transform(jo.horaInicio, 'dd MMM HH') + "hs.", activo: false });
    }

    if (jo.horaFin && jo.horaInicio) {
      let dateFin = new Date(jo.horaFin);
      let dateinicio = new Date(jo.horaInicio);
      jo["duracionMins"] = (dateFin.getTime() - dateinicio.getTime()) / 1000 / 60;

      try {
        jo["local"] = this.cuentasAdmin.get(jo.idCuenta)["locales"][jo.idLocal].nombreLocal
        jo["manager"] = this.cuentasAdmin.get(jo.idCuenta)["locales"][jo.idLocal].servidores[jo.idMac].nombreServidor
      } catch (e) { }
      jo.horaInicio = this.fechaLocal(jo.horaInicio);
      jo.horaFin = this.fechaLocal(jo.horaFin);

    }


    if (!this.jornadasManager.get(this.idLocalCOMAidManager(jo))) {
      this.idLocalCOMAidManager(jo)
      this.jornadasManager.set(this.idLocalCOMAidManager(jo), { name: this.localGUIONManager(jo), activo: true });
    }

    this.jornadas.push(jo)




    return jo;
  }



  fechaLocal(fecha: string) {
    let date = new Date(fecha);
    date.setMinutes(date.getMinutes() + this.offset);
    return date.toISOString();
  }

  deseleccionarTodes() {
    this.selection.clear();
  }

  //#region filtros
  nombreLocalManager(nLocalManager: string, localesManager: Map<string, boolean>, n: number): string {
    let nAux = nLocalManager + " (" + n + ")";
    if (localesManager.get(nAux)) {
      n++
      return this.nombreLocalManager(nLocalManager, localesManager, n)
    } else {
      return nAux;
    }
  }

  idLocalCOMAidManager(j: Jornada): string {
    return j.idLocal + "," + j.idMac
  }
  localGUIONManager(j: Jornada): string {
    return j.local + " / " + j.manager;
  }

  applyFilter() {
    this.dataSource.filter = "gamrgamr" + this.jornadasVacias.values() + this.jornadasManager.values() + this.cuentasAdminFiltros.values();
    this.dataSource.sort = this.sort;
    this.deseleccionarTodes();
    this.dibujaCharts(this.dataSource.filteredData);
  }

  clickDeAlgunFiltro($event) {
    this.applyFilter();
  }
  actualizarBotonConsultar($event) {
    //se hizo click en algun filtro de cuentas o picker
    this.botonConsultarDesactivado = false;

  }

  filtroJornadasManager(j: Jornada) {
    if (this.mostrarFiltroLocales.getValue()) {
      return this.jornadasManager.get(this.idLocalCOMAidManager(j)).activo;
    } else {
      return true;
    }
  }

  filtroJornadasVacias(j: Jornada) {
    if (this.mostrarFiltroJornadasVacias.getValue() && this.jornadasVacias && this.jornadasVacias.get(j.key)) {
      return this.jornadasVacias.get(j.key).activo;
    } else {
      return true;
    }
  }



  filtroDeFiltroJornadas() {
    return (j: Jornada, filter: string) => {
      if (this.filtroJornadasManager(j) && this.filtroJornadasVacias(j)) {
        return true;
      } else {
        return false;
      }
    }
  }
  //#endregion

  dibujaCharts(jornadas: Jornada[]) {
    this.data = [];
    let titulo;

    if (jornadas && jornadas.length > 1) {

      if (this.jornadasUnidasXDia) {
        this.data.push(['Día', 'Total']);
        titulo = 'Total por dia'
        let inicioAux = this.datePipe.transform(jornadas[0].horaInicio, "dd MMM");
        let totalAux = 0;
        for (let j of jornadas) {
          if (this.datePipe.transform(j.horaInicio, "dd MMM") === inicioAux) {
            totalAux += j.total;
          } else {
            this.data.push([inicioAux, totalAux]);
            inicioAux = this.datePipe.transform(j.horaInicio, "dd MMM");
            totalAux = j.total;
          }
        }
        this.data.push([inicioAux, totalAux]);
      } else {
        this.data.push(['Jornada', 'Total']);
        titulo = 'Total por jornada'
        for (let j of jornadas) {
          this.data.push([this.datePipe.transform(j.horaInicio, "dd MMM HH") + "hs", j.total]);
        }
      }
    }


    this.config = new LineChartConfig(titulo, '');
    this.elementId = 'myPieChartJornadas';
  }

  //#region pie de tabla
  getLocales() {
    let cant = 0;
    for (let c of this.cuentasAdmin.values()) {
      for (let idLocal in c.locales) {
        if (this.dataSource.filteredData.find(j => j.key.includes(idLocal))) {
          cant++
        }
      }
    }


    if (cant === 1) {
      return "1 Local"
    } else {
      return cant + " Locales"
    }
  }

  getManagers() {
    let cant = 0;
    for (let c of this.cuentasAdmin.values()) {
      for (let idLocal in c.locales) {
        for (let mac in c.locales[idLocal].servidores) {
          if (this.dataSource.filteredData.find(j => j.key.includes(mac))) {
            cant++
          }
        }
      }
    }


    if (cant === 1) {
      return "1 Manager"
    } else {
      return cant + " Managers"
    }
  }
  getDuracion() {
    if (this.dataSource.filteredData.length) {
      let acc = this.dataSource.filteredData.map(j => j.duracionMins).reduce((acc, value) => acc + value, 0);
      return acc / this.dataSource.filteredData.length
    } else {
      return 0
    }
  }
  getSubTotal() {
    return this.dataSource.filteredData.map(j => j.subtotal).reduce((acc, value) => acc + value, 0);
  }
  getDescuentos() {
    return this.dataSource.filteredData.map(j => j.descuentos).reduce((acc, value) => acc + value, 0);
  }
  getPropinas() {
    return this.dataSource.filteredData.map(j => j.propinas).reduce((acc, value) => acc + value, 0);
  }
  getTotal() {
    return this.dataSource.filteredData.map(j => j.total).reduce((acc, value) => acc + value, 0);
  }

  getCantComensales() {
    return this.dataSource.filteredData.map(j => j.cantComensales).reduce((acc, value) => acc + (value ? value : 0), 0);
  }
  getCantPedidosCobrados() {
    return this.dataSource.filteredData.map(j => j.cantPedidosCobrados).reduce((acc, value) => acc + (value ? value : 0), 0);
  }
  getCantPedidosDelivery() {
    return this.dataSource.filteredData.map(j => j.cantPedidosDelivery).reduce((acc, value) => acc + (value ? value : 0), 0);
  }

  getCantPedidosEliminados() {
    return this.dataSource.filteredData.map(j => j.cantPedidosEliminados).reduce((acc, value) => acc + (value ? value : 0), 0);
  }
  getCantPedidosExpress() {
    return this.dataSource.filteredData.map(j => j.cantPedidosExpress).reduce((acc, value) => acc + (value ? value : 0), 0);

  }
  getCantPedidosSalon() {
    return this.dataSource.filteredData.map(j => j.cantPedidosSalon).reduce((acc, value) => acc + (value ? value : 0), 0);
  }
  getCantPedidosYa() {
    return this.dataSource.filteredData.map(j => j.cantPedidosYa).reduce((acc, value) => acc + (value ? value : 0), 0);

  }
  getCantRappi() {
    return this.dataSource.filteredData.map(j => j.cantRappi).reduce((acc, value) => acc + (value ? value : 0), 0);

  }
  getCantItems() {
    return this.dataSource.filteredData.map(j => j.cantItems).reduce((acc, value) => acc + (value ? value : 0), 0);

  }

  //#endregion

}
