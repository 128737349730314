import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Billing from '../models/billing';
import { undefined } from 'src/app/shared/utils';
import Pricing from '../models/pricing';
import { PricingService } from '../pricing.service';
import { ToastsService } from 'src/app/admin2/services/toasts.service';

@Component({
  selector: 'app-billing-concept-card',
  templateUrl: './billing-concept-card.component.html',
  styleUrls: ['./billing-concept-card.component.scss']
})
export class BillingConceptCardComponent implements OnInit {

  constructor(
    private pricingSrv: PricingService,
    private toastSrv: ToastsService,
  ) { }

  @Input() type: 'fixed' | 'variable';

  @Input() index: number;

  @Input() billing: Billing;

  @Input() pricings: Pricing[];

  @Input() allowSelect: boolean;

  @Input() allowEdit: boolean;

  @Output() billingDeleted = new EventEmitter<Billing>();

  @Output() pricingSelected = new EventEmitter<Pricing>();

  @Output() billingModified = new EventEmitter<{old: Billing, new: Billing}>();

  currencySymbol: string;

  pricingChanged: boolean = false;

  loading: boolean = false;

  newPricingSelected: Pricing;

  @Input() newDiscounts: number[] = [];

  ngOnInit(): void {
    if (undefined(this.billing) || undefined(this.allowEdit) || undefined(this.allowSelect) || undefined(this.pricings) || undefined(this.type)) {
      throw new Error('missing required inputs');
    }
    this.currencySymbol = this.billing?.pricing?.base_currency?.symbol || '$';
    this.pricings = this.pricings.filter(p => p.fixed);
  }

  onClickDelete() {
    this.billingDeleted.emit(this.billing);
  }

  onPricingChange(pricing: Pricing) {
    this.newPricingSelected = pricing;
    this.pricingSelected.emit(pricing);
    this.pricingChanged = true;
  }

  onClickSavePricing() {
    this.loading = true;
    this.pricingSrv.deleteBilling(this.billing.id).subscribe(
      _res =>{
        this.pricingSrv.createBilling(this.billing.account_id, this.billing.premises_id, this.newPricingSelected.id).subscribe(
          res =>{
            const oldBilling = this.billing;
            this.billing = res.data[0];
            this.billingModified.emit({old: oldBilling, new: this.billing});
            this.toastSrv.show('Plan modificado con éxito');
            this.pricingChanged = false;
          },
          err =>{
            console.error('error creating new billing', err);
            this.toastSrv.show('Error al crear el nuevo plan');
          }
        )
      },
      err =>{
        console.error('error deleting billing',err);
        this.toastSrv.show('Error al borrar el plan anterior');
      }
    ).add(() => this.loading = false)
  }

  formatCurrency(value: number) {
    return `${this.billing?.pricing?.base_currency?.code} ${value}`;
  }

  isDiscountNew(discountId: number){
    return this.newDiscounts.includes(discountId);
  }

  onDeleteBillingDiscount(discountId: number){
    this.pricingSrv.deleteBillingDiscount(this.billing.id, discountId).subscribe(
      _res => {
        this.toastSrv.show('Éxito al eliminar descuento');
        this.billing.discounts = this.billing.discounts.filter(d => d.discount_id !== discountId);
      },
      err =>{
        console.error('error deleting discount',err);
        this.toastSrv.show('Error al eliminar descuento');
      }
    )
  }

}
