import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Cuenta from '../../models/cuenta';
import Locacion from '../../models/locacion';
import { UbicacionLocal } from '../../models/local';
import { SelectData } from '../../models/types';
import { CommonService } from '../../services/common.service';
import { CuentasService } from '../../services/cuentas.service';
import { LocacionesService } from '../../services/locaciones.service';
import { ToastsService } from '../../services/toasts.service';
import { ModalEditLocacionComponent } from './modal-edit-locacion/modal-edit-locacion.component';
import {MatSort, Sort} from '@angular/material/sort';

type ColumnasMostradasLocales = (keyof Locacion | keyof UbicacionLocal | 'actions');

@Component({
  selector: 'app-gestion-locaciones',
  templateUrl: './gestion-locaciones.component.html',
  styleUrls: ['./gestion-locaciones.component.scss']
})
export class GestionLocacionesComponent implements OnInit {


  @Input() cuenta: Cuenta;
  @Input() cuentas: Cuenta[];

  locaciones: Locacion[];
  dataLocacionesTabla: MatTableDataSource<Locacion>;
  columnasMostradas: ColumnasMostradasLocales[] = ['id', 'nombre', 'pais', 'activo', 'idCuenta', 'idLocal', 'actions'];
  
  paisesForm: SelectData[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  soloActivos = true;
  loadingData;

  constructor(
    private locacionesSrv: LocacionesService,
    private dialog: MatDialog,
    private toastSrv: ToastsService,
    private cuentasSrv: CuentasService,
    private commonSrv: CommonService
  ) { }

  ngOnInit(): void {
    this.obtenerLocales();
    this.obtenerCuentas();
    this.obtenerPaises();
  }

  private obtenerLocales() {
    this.loadingData = true;
    if (this.cuenta) {
      this.cuentasSrv.obtenerLocacionesDeCuenta(this.cuenta.id).subscribe(
        res => this.exitoObtenerLocaciones(res.data)
      ),
      err => console.error('Error obteniendo locales de cuenta')
    } else {
      this.locacionesSrv.getLocaciones().subscribe(
        res => this.exitoObtenerLocaciones(res.data),
        err => console.error('Error al obtener las locales', err)
      );
    }
  }

  private exitoObtenerLocaciones(locaciones: Locacion[]) {
    console.log('locaciones obtenidas', locaciones);
    this.locaciones = locaciones;
    this.cargarTablaFiltrada();
    this.loadingData = false;
  }

  private obtenerCuentas() {
    if (!this.cuentas) this.cuentasSrv.obtenerCuentas().subscribe(
      res => {
        this.cuentas = res;
      },
      err => {
        console.error('Error al obtener cuentas', err);
        this.toastSrv.show('Error obteniendo las cuentas ' + err.message)
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataLocacionesTabla.filter = filterValue.trim().toLowerCase();
  }

  onClickCrearLocacion() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60%';
    dialogConfig.height = '90%';
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      titulo: 'Crear Locacion',
      createMode: true,
      cuentaDeLocal: this.cuenta,
      cuentas: this.cuentas,
      paisesForm: this.paisesForm
    };

    this.dialog.open(ModalEditLocacionComponent, dialogConfig).afterClosed().subscribe(result => {
      console.log('result locacionCreado', result);
      if (result?.locacionCreada) {
        this.agregarLocalArreglo(result.locacionCreada);
        this.mostrarModalEditarLocal(result.locacionCreada);
        this.toastSrv.show('Locacion creado con éxito');
      }
    });
  }

  mostrarModalEditarLocal(locacion: Locacion) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60%';
    dialogConfig.height = '90%';
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      titulo: 'Editar locación',
      local: locacion,
      cuentaDeLocal: this.cuenta || this.cuentas.find(c => c.id == locacion.idCuenta),
      paisesForm: this.paisesForm
    };

    this.dialog.open(ModalEditLocacionComponent, dialogConfig).afterClosed().subscribe(result => {
      console.log('locacionModificada',result);
      if(result?.locacionModificada){
        this.modificarLocalArreglo(result.locacionModificada);
        this.toastSrv.show('Local actualizado con éxito');
      }
    });
  }

  modificarLocalArreglo(locacion: Locacion) {
    const indice = this.locaciones.findIndex(c => c.id === locacion.id);
    this.locaciones[indice] = locacion;
    // this.dataLocacionesTabla.data[indice] = locacion;
    // this.dataLocacionesTabla.paginator = this.paginator;
    this.cargarTablaFiltrada();
  }

  agregarLocalArreglo(locacion: Locacion) {
    this.dataLocacionesTabla.data.push(locacion);
    this.dataLocacionesTabla.paginator = this.paginator;
  }

  private obtenerPaises() {
    this.commonSrv.obtenerPaises().subscribe(
      (data: { paises: { pk_pais: number, pais: string }[] }) => {
        this.paisesForm = data.paises.map(p => ({ value: p.pais, label: p.pais }));
      },
      err => this.toastSrv.show('Error al cargar paises')
    )
  }

  onChangeActivos(event){
    this.soloActivos = !this.soloActivos;
    this.cargarTablaFiltrada();
  }
  
  cargarTablaFiltrada(){
    if(this.soloActivos){
      this.dataLocacionesTabla = new MatTableDataSource(this.locaciones.filter(l => l.activo == this.soloActivos));
    }else{
      this.dataLocacionesTabla = new MatTableDataSource(this.locaciones);
    }
    this.dataLocacionesTabla.paginator = this.paginator;
    this.dataLocacionesTabla.sort = this.sort;
    this.dataLocacionesTabla.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'pais': return item.ubicacion.pais;
        default: return item[property];
      }
    };
  }


}

