import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Cuenta from 'src/app/admin2/models/cuenta';
import { CuentasService } from 'src/app/admin2/services/cuentas.service';
import { ToastsService } from 'src/app/admin2/services/toasts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-modal-edit-cuenta',
  templateUrl: './modal-edit-cuenta.component.html',
  styleUrls: ['./modal-edit-cuenta.component.scss']
})
export class ModalEditCuentaComponent implements OnInit {

  titulo: string;
  cuenta: Cuenta; //en caso de editar cuenta
  formularioCuenta: FormGroup;
  guardando: boolean;
  createMode: boolean; //Indica si el componente se instancia para crear una nueva cuenta
  guardandoDatos: boolean;

  constructor(
    private cuentasSrv: CuentasService,
    private dialogRef: MatDialogRef<ModalEditCuentaComponent>,
    private toastSrv: ToastsService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.titulo = data.titulo;
    this.cuenta = data.cuenta;
    this.createMode = data.createMode;
  }

  ngOnInit(): void {
    this.guardando = false;
    this.formularioCuenta = new FormGroup({
      'id': new FormControl(this.cuenta?.id),
      'fechaCreacion': new FormControl((this.createMode ? new Date() : this.cuenta.fechaCreacion)),
      'idCuenta': new FormControl(this.cuenta?.idCuenta),
      'fechaBaja': new FormControl(this.cuenta?.fechaBaja),
      'nombre': new FormControl(this.cuenta?.nombre, [Validators.required, Validators.minLength(5)]),
      'nombreCliente': new FormControl(this.cuenta?.nombreCliente, [Validators.required, Validators.minLength(5)]),
      'activa': new FormControl(this.cuenta?.activa, [Validators.required]),
      'emailRegistro': new FormControl(this.cuenta?.emailRegistro,[Validators.minLength(5),Validators.email]),
      'numeroTelefono': new FormControl(this.cuenta?.numeroTelefono,[Validators.minLength(5)]),
    });
    this.formularioCuenta.patchValue({fechaCreacion: this.datePipe.transform((this.createMode ? new Date() : this.cuenta.fechaCreacion), 'dd/MM/yyyy')});
  }

  cerrarModal(){
    this.dialogRef.close();
  }

  guardarCuenta(){
    this.guardandoDatos = true;
    if(this.createMode) this.nuevaCuenta()
    else this.actualizarCuenta();
  }

  nuevaCuenta(){
    const cuentaAGuardar = this.generarCuentaAGuardar();
    this.cuentasSrv.postCuentas(cuentaAGuardar).subscribe({
      next: (data: {cuenta: Cuenta}) => {
        this.dialogRef.close({cuentaCreada: data.cuenta});
      },
      error: (err) => {
        this.toastSrv.show('Error al crear la cuenta: '+ err.error.msg);
        console.error('error al crear cuenta', err);
      },
    })
    .add(() => this.guardandoDatos = false);
  }

  actualizarCuenta(){
    const cuentaAGuardar = this.generarCuentaAGuardar();
    this.cuentasSrv.putCuentas(cuentaAGuardar).subscribe({
      next: (data) => {
        this.dialogRef.close({cuentaModificada: cuentaAGuardar});
      },
      error: (err) => {
        this.toastSrv.show('Error al modificar la cuenta: ' + err.error.msg);
        console.error('error al modificar cuenta', err);
      },
    })
    .add(() => this.guardandoDatos = false);

  }

  generarCuentaAGuardar(): Cuenta{
    const cuentaGenerada = {};
    for(const keyControl of Object.keys(this.formularioCuenta.controls)){
      if(keyControl !== 'fechaBaja' && keyControl !== 'fechaCreacion') cuentaGenerada[keyControl]=this.formularioCuenta.controls[keyControl].value;
    }
    return <Cuenta>{ ...cuentaGenerada, fechaCreacion: this.cuenta?.fechaCreacion || new Date(), fechaBaja: this.cuenta?.fechaBaja};
  }


}
