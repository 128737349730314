import { Component, OnInit, } from '@angular/core';
import { Mail } from '../shared/mail';
import { AuthService } from '../core/auth.service';
import { Subscription } from 'rxjs';
import { Cuenta } from '../shared/cuenta';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFireObject } from '@angular/fire/database';
import { DialogComponent } from '../modales/dialog/dialog.component';
import { MatDialog, } from '@angular/material/dialog';
import { CampoDialog } from '../shared/campo-dialog';
import { Validators } from '@angular/forms';
import { er } from '../shared/error';
import { LogService } from '../services/log.service';
import { Log } from '../shared/log/log';
import { MailTrazable } from '../shared/mailTrazable';
import { SnackBarProgressComponent } from '../snack-bar-progress/snack-bar-progress.component';


@Component({
  selector: 'app-mails',
  templateUrl: './mails.component.html',
  styleUrls: ['./mails.component.scss']
})
export class MailsComponent implements OnInit {
  auxMapaMails: Map<string, Map<string, MailTrazable>> = new Map();
  subscriptionCuentas: Subscription;
  subscriptionCuenta: Subscription;
  cuenta: Cuenta;
  cuentas: Cuenta[] = [];
  dialogRef: any;
  idCuentaSelected: string;
  displayedColumns: string[] = ['email', 'editar', 'activaren', 'eliminar'];
  dataSource: MatTableDataSource<Mail>;
  mails: Mail[] = [];
  mailEditado: string;
  editar: boolean = false;
  ref: AngularFireObject<{}>;
  constructor(public dialog: MatDialog, public authService: AuthService, public logService: LogService, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.subscriptionCuenta = this.authService.cuenta$
      .subscribe(cuenta => {
        if (cuenta) {
          this.cuenta = cuenta;
          if (this.idCuentaSelected != this.cuenta.id) {
            this.idCuentaSelected = this.cuenta.id;
          }
        }
      });
    this.subscriptionCuentas = this.authService.cuentas$
      .subscribe(cuentas => {
        if (cuentas) {
          this.cuentas = cuentas;
          this.auxMapaMails = new Map();

          cuentas.forEach(cuenta => {
            this.leerDatosEmail(cuenta);
          });
        }
      });
  }
  leerDatosEmail(cuenta: Cuenta) {
    this.ref = this.authService.leerFB("usuarios/" + cuenta.id + "/emails");
    this.ref.snapshotChanges().subscribe(action => {
      if (action && action.payload && action.payload.val()) {
        //consts.logD(action.type);
        //consts.logD(action.payload.val());
        let mapaAux = action.payload.val();

        if (mapaAux) {
          for (let i in mapaAux) {
            let element: MailTrazable = mapaAux[i];
            if (!this.auxMapaMails.get(cuenta.id)) {
              this.auxMapaMails.set(cuenta.id, new Map());
            }
            let map = this.auxMapaMails.get(cuenta.id);
            map.set(i, element);
            let a: Mail;
            a = this.mails.find(auxiliar => auxiliar.direccion == element.email);
            if (a) {
              a.servidores[this.cuentas.indexOf(cuenta)] = !element.deleted;
            } else {
              this.creaMail(element.email, cuenta, !element.deleted);
            }
          }

          this.dataSource = new MatTableDataSource(this.mails);
        }
      }
    });
  }
  creaMail(direccion: string, cuenta: Cuenta, activado: boolean): void {
    let mail = new Mail();
    mail.direccion = direccion;
    mail.servidores = [];
    mail.servidores[this.cuentas.indexOf(cuenta)] = activado;
    this.mails.push(mail);
  }
  activaDesactivaEdicion(direccion: string) {
    let campos: CampoDialog[] = [];
    campos[0] = new CampoDialog;
    campos[0].nombre = "email";
    campos[0].placeholder = "Email";
    campos[0].Validaciones = [Validators.required, Validators.email];
    campos[0].errores = [];
    campos[0].errores[0] = new er;
    campos[0].errores[0].key = "required";
    campos[0].errores[0].mensaje = "El email no puede ser vacío";
    campos[0].errores[1] = new er;
    campos[0].errores[1].key = "email";
    campos[0].errores[1].mensaje = "El email no es válido";
    campos[0].valor = direccion;
    let submit: string = "Guardar";
    let cerrar: string = "Cancelar";
    let valores: string[];
    const dialogRef = this.dialog.open(DialogComponent, { width: '390px', height: '', data: { campos, submit, cerrar } });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  agregarOEliminarMail(mail: Mail, cuenta: Cuenta, e: any) {
    let agregado = mail.servidores[this.cuentas.indexOf(cuenta)];
    let mensaje = "Sincronizando: " + mail.direccion;
    let progress = true;
    this.snackBar.openFromComponent(SnackBarProgressComponent, { data: { mensaje, progress } });
    if (agregado) {
      //el email esta agregado, queremos eliminarlo
      this.deleteMailEnFirebase(mail.direccion, cuenta);
    } else {
      //el email no esta, queremos agregarlo
      this.agregarMailEnFirebase(mail.direccion, cuenta);
    }
  }
  agregarMail() {
    let campos: CampoDialog[] = [];
    let campo: CampoDialog = new CampoDialog;
    campo.nombre = "email";
    campo.placeholder = "Email";
    campo.Validaciones = [Validators.required, Validators.email];
    campo.errores = [];
    let errores: er = new er;
    errores.key = "required";
    errores.mensaje = "El email no puede ser vacío";
    campo.errores.push(errores);
    errores = new er;
    errores.key = "email";
    errores.mensaje = "El email no es válido";
    campo.errores.push(errores);
    campo.valor = "";
    campos.push(campo);
    let submit: string = "Guardar";
    let cerrar: string = "Cancelar";
    const dialogRef = this.dialog.open(DialogComponent, { width: '390px', height: '', data: { campos, submit, cerrar } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.agregarMailEnFirebase(result[0], this.cuenta);
      }
    });
  }
  /*prueba() {
    let mensaje: string = "Se ha guardado: ";
    let progress=true;
    this.snackBar.dismiss();
    this.snackBar.openFromComponent(SnackBarProgressComponent, { data: {mensaje, progress}});
  }*/

  async agregarMailEnFirebase(email: string, cuenta: Cuenta) {
    let log: Log = await this.logService.crearLog(cuenta.id, [email, cuenta.name], null, 101, email, null);
    if (log.cancel) {
      console.log(log.err);
    } else {
      this.logService.escribeEnFirebase(log)
        .then(_ => {
          //this.creaMail(email, cuenta);
          let mensaje: string = "Se ha guardado: " + email;
          let progress = null;
          this.snackBar.dismiss();
          this.snackBar.openFromComponent(SnackBarProgressComponent, { duration: 3000, data: { mensaje, progress } });
        })
        .catch(reason => { console.log("No se pudo grabar en firebase, razon: ", reason) });
    }
  }

  async deleteMailEnFirebase(email: string, cuenta: Cuenta) {
    //[email, key]
    let key: string = this.buscaKey(email, cuenta.id);
    console.log(key);
    console.log(this.auxMapaMails);
    console.log(email);

    if (key && this.auxMapaMails.get(cuenta.id) && this.auxMapaMails.get(cuenta.id).get(key) && this.auxMapaMails.get(cuenta.id).get(key).last) {
      let log: Log = await this.logService.crearLog(cuenta.id, [email, key], this.auxMapaMails.get(cuenta.id).get(key).last, 103, email, null);
      if (log.cancel) {
        console.log(log.err);
      } else {
        this.logService.escribeEnFirebase(log)
          .then(_ => {
            let mensaje: string = "Se eliminó: " + email + " de la cuenta " + cuenta.name;
            let progress = null;
            this.snackBar.dismiss();
            this.snackBar.openFromComponent(SnackBarProgressComponent, { duration: 3000, data: { mensaje, progress } });
          })
          .catch(reason => { console.log("No se pudo grabar en firebase, razon: ", reason) });
      }
    } else {
      //no deberia pasar
      throw "Check deberia volver a true";
    }
  }

  buscaKey(email: string, mac: string): string {
    let keyRes = null;
    if (this.auxMapaMails && this.auxMapaMails.get(mac)) {
      this.auxMapaMails.get(mac).forEach((mail, key) => {
        if (mail && mail.email === email) {
          keyRes = key;
          return;
        }
      });
    }
    return keyRes;
  }

}
