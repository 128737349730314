import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, InfoCuenta } from '../../core/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuentaService } from '../../services/cuenta.service';
import { Subscription } from 'rxjs';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';
import { AngularFireAuth } from '@angular/fire/auth';
import * as consts from "../../shared/constantes";

@Component({
  selector: 'app-felicitaciones',
  templateUrl: './felicitaciones.component.html',
  styleUrls: ['./felicitaciones.component.scss', '../../../mypopapp/assets/_css/mypopapp.css', '../../../mypopapp/assets/_css/mypopapp-mq.css']
})
export class FelicitacionesComponent implements OnInit, OnDestroy {
  subscriptionCuenta: Subscription;
  idLocal: string;
  macElegida: string;
  servidor: ServidorAdmin;
  plan: number;
  planString: string;
  moneda: string;
  nombre: string = "";
  importes: number[] = [];
  codigoActivacion: string;

  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;
  constructor(public authService: AuthService, public snackBar: MatSnackBar, public cuentaService: CuentaService, afauth: AngularFireAuth) {
    afauth.user.subscribe(user => {
      this.nombre = user.displayName;
    });
  }
  marca() {
    return consts.marca(this.infoCuenta);
  }

  ngOnDestroy() {
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }
  }
  ngOnInit() {
    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });
    this.subscriptionCuenta = this.authService.cuenta$
      .subscribe(cuenta => {
        if (cuenta) {
          let refMacP = this.authService.leerFB('macsPasadas/' + cuenta.id);
          refMacP.snapshotChanges().subscribe(action => {

            let macPasad = action.payload.val();
            let idCuenta = macPasad['idCuenta'];
            this.idLocal = macPasad['idLocal'];
            this.macElegida = cuenta.id;
            this.leerFBCuenta(idCuenta);

          });
        }
      });

  }
  leerFBCuenta(idCuenta: string): void {
    if (idCuenta && idCuenta.length > 3) {
      let refCuenta = this.authService.leerFB('cuentas/' + idCuenta);
      refCuenta.snapshotChanges().subscribe(action => {
        let cuentaAdmin = action.payload.val();
        let locales = cuentaAdmin['locales'];
        let local = locales[this.idLocal];
        let servidores = local['servidores'];
        this.servidor = servidores[this.macElegida];
        this.servidor.idCuenta = idCuenta;
        this.servidor.idLocal = this.idLocal;
        this.servidor.idMac = this.macElegida;
        this.plan = this.servidor['planN'];
        this.planString = this.cuentaService.planNumberToString(this.plan);
        this.codigoActivacion = this.cuentaService.generaCódigoConNuevaFechayPlan(this.servidor);
        this.leeImportes();
      });
    } else {
      this.snackBar.open("No se pudo leer la cuenta, reintenta más tarde", "", { duration: 4000 });
    }
  }
  leeImportes() {
    for (let i = 1; i <= 3; i++) {
      if (this.servidor.moneda) {
        this.moneda = this.servidor.moneda;
        this.importes.push(this.cuentaService.importePorPlan(i, this.servidor.moneda));
      }
      else {
        this.servidor.moneda = "USD";
        this.importes.push(this.cuentaService.importePorPlan(i, this.servidor.pais));
      }
    }
  }
}


