import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { CuentaAdmin } from 'src/app/shared/admin/cuenta-admin';


export interface UsuarioPOW {
  _key?: string,
  urlProfileImg: string,
  nombreCompleto: string,
  email: string,
  ultConexion: string,
  funcion: string,

  /**
   * si no tiene uid es que todavia no acepta la invitacion
   */
  uid?: string,
  timestampUltConexion?: any,
  permisos: string[]
}


@Component({
  selector: 'app-modal-email-aumento-chile',
  templateUrl: './modal-email-aumento-chile.component.html',
  styleUrls: ['./modal-email-aumento-chile.component.scss']
})
export class ModalEmailAumentoChileComponent implements OnInit {

  cuentasAEnviar: CuentaAdmin[];
  cuentasEnviadas: CuentaAdmin[];
  cuentasSinEmails: CuentaAdmin[];
  servidores: ServidorAdmin[] = [];
  loading: boolean;
  cuentas: CuentaAdmin[];
  SIN_MAILS: string = "SIN_MAILS";

  constructor(
    public dialogRef: MatDialogRef<ModalEmailAumentoChileComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { servidores: ServidorAdmin[], cuentas: CuentaAdmin[] },
    private db: AngularFireDatabase,
    private dbFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
  ) {
    this.servidores = this.data.servidores;
    this.cuentas = this.data.cuentas;

  }


  async ngOnInit() {
    this.loading = true;
    this.cuentasEnviadasYno();
    this.loading = false;
  }

  loguear(s) {
    console.log(s);
  }

  cuentasEnviadasYno() {
    this.cuentasAEnviar = [];
    this.cuentasEnviadas = [];
    this.cuentasSinEmails = [];

    const cuentasProcesadas: Map<string, boolean> = new Map();
    const mapPaises: Map<string, boolean> = new Map();

    // ARG,ARS $,
    // ARG,USD,
    // CHI,CLP $,
    // CHI,UF,
    // COL,USD,
    // CR,USD,
    // MEX,USD,
    // PER,USD,
    // VEN,USD,

    for (let s of this.servidores) {
      if (!cuentasProcesadas.get(s.idCuenta)) {
        cuentasProcesadas.set(s.idCuenta, true);
        const servidoresDeLaCuenta = this.servidores.filter(x => x.idCuenta === s.idCuenta);
        const cuenta = this.cuentas.find(x => x.id === s.idCuenta);
        if (cuenta && servidoresDeLaCuenta && servidoresDeLaCuenta.length > 0) {
          cuenta.servidoresActivosSinIvaChile = [];
          for (let sc of servidoresDeLaCuenta) {
            sc = new ServidorAdmin(sc);
            if (sc.numeroLocal &&
              +sc.numeroLocal > 0 &&
              +sc.numeroLocal !== 900 &&
              +sc.numeroLocal < 9000 &&
              sc.showPagar &&
              sc.moneda &&

              sc.pais === 'CHI')
            // sc.moneda === "USD")
            {
              if (!sc.tieneIvaConfiguradoChile()) {
                // hay que enviar el email
                cuenta.servidoresActivosSinIvaChile.push(sc);
              } else {
                // no hay que enviar el email pq ya tiene el 19%
                // TODO: quizas un control que nos refuerce que esta bien configurado como importe menos iva = total o algo asi no se
              }
              // mapPaises.set(sc.pais + ',' + sc.moneda + ',', true);
            }
          }

          if (cuenta.servidoresActivosSinIvaChile.length > 0) {
            if (cuenta.emailAumentoIvaChileEnviado && cuenta.emailAumentoIvaChileEnviado !== this.SIN_MAILS) {
              this.cuentasEnviadas.push(cuenta);
            } else if (!cuenta.emailAumentoIvaChileEnviado) {
              this.cuentasAEnviar.push(cuenta);
            } else {
              this.cuentasSinEmails.push(cuenta);
            }
          }
        }
      }
    }
    // for (let k of mapPaises.keys()) {
    //   console.log(k);
    // }
  }

  async getMails(servidores: ServidorAdmin[]): Promise<string | undefined> {
    let usuarios: string[] = [];
    for (let s of servidores) {
      let v = await this.db.object("usuarios/" + s.idMac + "/mails").query.once("value");
      let mails = v.val();
      if (mails) {
        for (let u in mails) {
          if (!usuarios.find(x => x === mails[u])) {
            usuarios.push(mails[u]);
          }
        }
      }

      if (s.emails) {
        for (let u in s.emails) {
          if (!usuarios.find(x => x === mails[u])) {
            usuarios.push(s.emails[u]["email"]);
          }
        }
      }

      if (s.keyPedidosOnline && s.keyPedidosOnline.key) {
        const usuariosWeb = await this.dbFirestore.collection('confLocalPO/' + s.keyPedidosOnline.key + '/usuarios').get().toPromise();
        if (usuariosWeb && usuariosWeb.docs) {
          for (const u of usuariosWeb.docs) {
            const userWeb: UsuarioPOW = <UsuarioPOW>u.data();
            if (userWeb && userWeb.email &&
              !usuarios.find(x => x === userWeb.email) &&
              userWeb.permisos &&
              userWeb.permisos.includes('PEDIDOSW') &&
              userWeb.permisos.includes('CONFIGURACIONESW') &&
              userWeb.permisos.includes('ESTADISTICASW') &&
              userWeb.permisos.includes('USUARIOSW')) {
              usuarios.push(userWeb.email);
            }
          }
        }
      }
    }
    if (usuarios && usuarios.length > 0) {
      let emails = "";
      for (let u of usuarios) {
        if (emails === "") {
          emails += u;
        } else {
          emails += ", " + u;
        }
      }
      return emails;
    } else {
      return undefined;
    }


  }



  async enviarMail() {
    this.loading = true;
    const asunto = "Cambios en los términos y condiciones";

    const cuerpoText = "Estimado usuario de Popapp:\n" +
      "\n" +
      "Escribimos para informarte que a partir del mes de Enero del año 2022, realizaremos un cambio de razón social y pasaremos a estar gravados por IVA, por lo que realizaremos un incremento del 19% en el valor de la suscripción mensual. \n" +
      "\n" +
      "Podrás pedir tu factura por nuestro canal de soporte https://wa.me/56553280676  y en breve te la enviaremos de forma automática a tu correo electrónico.\n" +
      "\n" +
      "Seguiremos mejorando nuestro nivel de servicio, invirtiendo en nuevos recursos y procesos para seguir brindando soluciones tecnológicas de calidad para todos nuestros clientes.\n" +
      "\n" +
      "Por favor no responder este mail. Cualquier consulta dirigirla a nuestro canal de soporte en https://wa.me/56553280676 .\n" +
      "\n" +
      "Saludos! El equipo de Popapp.";


    const cuerpoHtml = "<p>Estimado usuario de Popapp:</p>" +
      // "<br>" +
      "<p>Escribimos para informarte que a partir del mes de Enero del año 2022, realizaremos un cambio de razón social y pasaremos a estar gravados por IVA, por lo que realizaremos un incremento del 19% en el valor de la suscripción mensual. </p>" +
      // "<br>" +
      '<p>Podrás pedir tu factura por nuestro canal de <a href="https://wa.me/56553280676">soporte</a>  y en breve te la enviaremos de forma automática a tu correo electrónico.</p>' +
      // "<br>" +
      "<p>Seguiremos mejorando nuestro nivel de servicio, invirtiendo en nuevos recursos y procesos para seguir brindando soluciones tecnológicas de calidad para todos nuestros clientes.</p>" +

      '<p>Por favor no responder este mail. Cualquier consulta dirigirla a nuestro canal de <a href="https://wa.me/56553280676">soporte.</a> </p>' +
      // "<br>" +
      "<p>Saludos! El equipo de Popapp.</p>";

    // if (true) {
    //   await this.dbFirestore.collection("mail").add({
    //     to: 'gmarin@popapp.io',
    //     message: {
    //       html: cuerpoHtml,
    //       text: cuerpoText,
    //       subject: asunto
    //     }
    //   });
    //   return;
    // }

    for (let c of this.cuentasAEnviar) {
      const emailsAEnviar: string | undefined = await this.getMails(c.servidoresActivosSinIvaChile);
      if (emailsAEnviar) {
        await this.dbFirestore.collection("mail").add({
          to: emailsAEnviar,
          message: {
            html: cuerpoHtml,
            text: cuerpoText,
            subject: asunto
          }
        });
        await this.db.object("cuentas/" + c.id + "/emailAumentoIvaChileEnviado").set(emailsAEnviar);

      } else {
        await this.db.object("cuentas/" + c.id + "/emailAumentoIvaChileEnviado").set(this.SIN_MAILS);
      }
    }

    this.snackbar.open("Proceso terminado actualiza la pagina y vuelve a entrar")._dismissAfter(4000);
    this.loading = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}