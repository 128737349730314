import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tiendas-error-rappi',
  templateUrl: './tiendas-error-rappi.component.html',
  styleUrls: ['./tiendas-error-rappi.component.scss']
})
export class TiendasErrorRappiComponent implements OnInit {

  constructor(private http: Http) { }
  tiendasSinWebhook: any[];
  botonActivar: boolean = false;
  obteniendo: boolean = true;
  ngOnInit(): void {
    this.obtenerTiendasSinWebhook().then(res => {
      this.tiendasSinWebhook = res.json().tiendasSinWebhook;
    }).catch(e => {
      console.error("error al obtener tiendas sin webhook", e)
    })

  }

  obtenerTiendasSinWebhook() {

    return this.http.get(`${environment.urls.functions}/rappi/tiendasSinWebhook`).toPromise().then(res => {
      this.obteniendo = false;
      return res;
    });
  }

  activarWebhook(tienda: any, $event: MouseEvent) {
    tienda.botonActivar = true;
    console.log("Boton apretado, activar tienda:", tienda);
    const pais = tienda.origen.replace("ra2", "")
    let resultadoPost = null;
    this.http.post(`${environment.urls.functions}/rappi/${pais}/activarWebhooks/${tienda.idStore}`, '{}').toPromise().then(res => {
      resultadoPost = res.json()
      tienda.respuesta = JSON.stringify(resultadoPost);
      tienda.botonActivar = true;
    }).catch(e => {
      tienda.respuesta = "ERROR" + e;
      tienda.botonActivar = true;

    });
    return;
  }
  boton() {
    return this.botonActivar
  }
}
