//
// ===== File globals.ts    
//
'use strict';

import { User } from 'firebase';
import { InfoCuenta } from '../core/auth.service';
import { User as User2 } from '../shared/user';

export const version: string = "1.0.0";
export const desarrollador: boolean = false;//TODO poner en false
const MARCA_DEFECTO: string = "Popapp";
const MARCA_FOTO_URLDEFECTO: string = "https://popapp.io/zzz/assets/logo-mypopapp-header.png";
const MARCA_FOTO_URL_CHICA_DEFECTO: string = "https://popapp.io/zzz/assets/logo-sm.png";

export function esAdmin(user: User | User2): boolean {
    const email = user.email;
    if (!user.emailVerified) {
        return false;
    } else {
        return email === 'gmarin@popapp.io' ||
            email === 'pmarin@popapp.io' ||
            email === 'cmarin@popapp.io' ||
            email === 'facundo@popapp.io' ||
            email === 'valeria@popapp.io' ||
            email === 'victoria@popapp.io' ||
            email === 'pgarcia@popapp.io' ||
            email === 'msaavedra@popapp.io' ||
            email === 'nnasser@popapp.io' ||
            email === 'nquinteros@popapp.io' ||
            email === 'paula@popapp.io' ||
            email === 'luciana@popapp.io' ||
            email === 'ldrazic@popapp.io' ||
            email === 'agustina@popapp.io' ||
            email === 'marcelo@popapp.io' ||
            email === 'bmedawar@popapp.io' ||
            email === 'dbaigorri@popapp.io' ||
            email === 'jcorona@popapp.io' ||
            email === 'fsanchez@popapp.io' ||
            email === 'fmoya@popapp.io' ||
            email === 'nperez@popapp.io' ||
            email.endsWith("@popapp.io")
    }
}

export function esDeveloper(user: User | User2): boolean {
    const email = user.email;
    if (!user.emailVerified) {
        return false;
    } else {
        return email === 'gmarin@popapp.io' ||
            email === 'pmarin@popapp.io' ||
            email === 'cmarin@popapp.io' ||
            email === 'nnasser@popapp.io' ||
            email === 'ldrazic@popapp.io' ||
            email === 'bmedawar@popapp.io' ||
            email === 'nperez@popapp.io' ||
            email === 'jcortez@popapp.io'
    }
}

export function logD(texto, from): void {
    if (desarrollador) {
        console.log(from);
        console.log(texto);
    }
}

export function replaceAll(str, find, replace) {
    while (str && str.indexOf(find) > 0) {
        str = str.replace(find, replace);
    }
    return str;
}

export function marca(infoCuenta: InfoCuenta): string {
    if (infoCuenta && infoCuenta.marca) {
        return infoCuenta.marca;
    } else {
        return MARCA_DEFECTO;
    }
}

export function marcaURL(infoCuenta: InfoCuenta): string {
    if (infoCuenta && infoCuenta.marcaFotoURL) {
        return infoCuenta.marcaFotoURL;
    } else {
        return MARCA_FOTO_URLDEFECTO;
    }
}

export function marcaURLChica(infoCuenta: InfoCuenta): string {
    if (infoCuenta && infoCuenta.marcaFotoChicaURL) {
        return infoCuenta.marcaFotoChicaURL;
    } else {
        return MARCA_FOTO_URL_CHICA_DEFECTO;
    }
}

