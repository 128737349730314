import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Http, Response } from '@angular/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-marcas',
  templateUrl: './modal-marcas.component.html',
  styleUrls: ['./modal-marcas.component.scss']
})
export class ModalMarcasComponent implements OnInit {

  FormMarca = new FormControl('');
  marcas: string[]
  marcasLowerCase: string[] = [];
  marcaExistente: boolean = false;
  marcaCreada: boolean = false;

  constructor(public dialogRef: MatDialogRef<ModalMarcasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { marcas: string[] },
    private http: Http
  ) {

    this.marcas = data.marcas;
  }

  ngOnInit(): void {

    this.marcas.forEach((element: string) => this.marcasLowerCase.push(element.toLowerCase().split(" ").join("")));


  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  guardarDatos() {

    let marcaIngresada: string = this.FormMarca.value

    let exsiteMarca: boolean = this.marcasLowerCase.includes(marcaIngresada.toLowerCase().split(" ").join(""));


    if (exsiteMarca) {
      this.marcaExistente = true;
    } else {

      this.marcaExistente = false;
      const url: string = environment.urls.functions + "/basesql/marcas"
      const body = { nombre: marcaIngresada }

      this.http.post(url, body).toPromise()
        .then(res => {
          console.log(res.json())
          this.marcaCreada = true;
        })
        .catch(error => console.error(error));
    }
  }
}


