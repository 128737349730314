import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AdminCuentasComponent } from './admin/admin-cuentas/admin-cuentas.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { PagarComponent } from './pagar/pagar.component';
import { StockComponent } from './stock/stock.component';
import { VentasComponent } from './ventas/ventas.component';
import { MailsComponent } from './mails/mails.component';
import { DialogComponent } from './modales/dialog/dialog.component';
import { AdminEmailsComponent } from './admin/admin-emails/admin-emails.component';
import { BienvenidoComponent } from './inicio/bienvenido/bienvenido.component';
import { ComenzarComponent } from './inicio/comenzar/comenzar.component';
import { AccederComponent } from './inicio/acceder/acceder.component';
import { DescargasComponent } from './inicio/descargas/descargas.component';

import { AdminGuard } from './guards/admin.guard';
import { LoginGuard } from './guards/login.guard';
import { MypopappComponent } from './mypopapp/mypopapp.component';
import { OnepayComponent } from './onepay/onepay.component';
import { MapCuentasComponent } from './admin/map-cuentas/map-cuentas.component';
import { AdminMovimientosComponent } from './admin/admin-movimientos/admin-movimientos.component';
import { Descargas2Component } from './descargas2/descargas2.component';
import { AdminErroresComponent } from './admin/admin-errores/admin-errores.component';
import { AdminStatusPOnlineComponent } from './admin/admin-statusponline/admin-statusponline.component';
import { AdminCeluComponent } from './admin/admin-celu/admin-celu.component';
import { AdminMetricasComponent } from './admin/admin-metricas/admin-metricas.component';
import { AdminMetricas2Component } from './admin/admin-metricas2/admin-metricas2.component';
import { PedidosonlineComponent } from './pedidosonline/pedidosonline.component';
import { StockSebaComponent } from './stock-seba/stock-seba.component';
import { HistorialComponent } from './historial/historial.component';
import { FechaComponent } from './fecha/fecha.component';
import { OrdenesComponent } from './historial/ordenes/ordenes.component';
import { DetalleordenComponent } from './historial/detalleorden/detalleorden.component';
import { AdminPuntuacionesComponent } from './admin/admin-puntuaciones/admin-puntuaciones.component';
import { AdminPuntuacionesJornadaComponent } from './admin/admin-puntuaciones-jornada/admin-puntuaciones-jornada.component';
import { ProductosComponent } from './productos/productos.component';
import { AdminProcesosComponent } from './admin/admin-procesos/admin-procesos.component';
import { TestPedidosOnlineComponent } from './admin/test-pedidos-online/test-pedidos-online.component';
import { AdminEmailsFirestoreComponent } from './admin/admin-emails-firestore/admin-emails-firestore.component';
import { ResellerGuard } from './guards/reseller.guard';
import { AdminTareasComponent } from './admin/admin-tareas/admin-tareas.component';
import { AdminUsuariosComponent } from './admin/admin-usuarios/admin-usuarios.component';
import { AdminTicketsComponent } from './admin/admin-tickets/admin-tickets.component';
import { MispedidosonlineComponent } from './mispedidosonline/mispedidosonline.component';
import { AdminHistorialComponent } from './admin/admin-historial/admin-historial.component';
import { AdminHistoriasComponent } from './admin/admin-historias/admin-historias.component';
import { AdminBuscarPedidoComponent } from './admin/admin-buscar-pedido/admin-buscar-pedido.component';
import { PedidosReportadosComponent } from './admin/pedidos-reportados/pedidos-reportados.component';
import { ReportesComponent } from './admin/reportes/reportes.component';
import { AdminScriptsComponent } from './admin/admin-scripts/admin-scripts.component';
import { AdminFacturacionComponent } from './admin/admin-facturacion/admin-facturacion.component';
import { AdminOrigenesComponent } from './admin/admin-origenes/admin-origenes.component';
import { TiendasErrorRappiComponent } from './tiendas-error-rappi/tiendas-error-rappi.component';
import { ManejoCuentasNuevoComponent } from './manejo-cuentas-nuevo/manejo-cuentas-nuevo.component';
import { DeveloperGuard } from './guards/developer.guard';
import { GestionCuentasComponent } from './admin2/pages/gestion-cuentas/gestion-cuentas.component';
import { GestionServidoresComponent } from './admin2/pages/gestion-servidores/gestion-servidores.component';
import { GestionLocacionesComponent } from './admin2/pages/gestion-locaciones/gestion-locaciones.component';
import { PagardlocalComponent } from './pagardlocal/pagardlocal.component';
import { VersionesManagerComponent } from './admin/versiones-manager/versiones-manager.component';
import { ModalPricingBillingComponent } from './admin/modal-pricing-billing/modal-pricing-billing.component';
import { InvitacionesComponent } from './admin/invitaciones/invitaciones.component';
import { AdminUpdatePedidosComponent } from './admin/admin-update-pedidos/admin-update-pedidos.component';


const routes: Routes = [
  { path: '', component: MypopappComponent },
  { path: 'comenzar', component: ComenzarComponent },
  { path: 'pedidosonline', component: PedidosonlineComponent },
  { path: 'pagar', component: MiCuentaComponent },
  { path: 'pago', component: PagarComponent },
  { path: 'pagodlocal', component: PagardlocalComponent },
  { path: 'acceder', component: AccederComponent },
  { path: 'onepay/:keyAPagar', component: OnepayComponent },
  { path: 'mypopappp', redirectTo: 'mypopapp', pathMatch: 'full' },
  { path: 'mipopapp', redirectTo: 'mypopapp', pathMatch: 'full' },
  //{ path: 'productos', component: ProductosComponent },
  {
    path: 'mypopapp', component: MypopappComponent,
    children: [
      { path: '', component: MainComponent },
      { path: 'bienvenido', component: BienvenidoComponent, canActivate: [LoginGuard] },
      { path: 'descargar', component: DescargasComponent, canActivate: [LoginGuard] },
      { path: 'descargas', component: Descargas2Component, canActivate: [LoginGuard] },
      { path: 'fecha', component: FechaComponent, canActivate: [LoginGuard] },
      { path: 'historial', component: HistorialComponent, canActivate: [LoginGuard] },
      { path: 'historial/:keyJornada', component: OrdenesComponent, canActivate: [LoginGuard] },
      { path: 'historial/pedido/:keyPedido', component: DetalleordenComponent, canActivate: [LoginGuard] },
      { path: 'micuenta', component: MiCuentaComponent, canActivate: [LoginGuard] },
      { path: 'micuenta/pagar', component: PagarComponent, canActivate: [LoginGuard] },
      { path: 'micuenta/pagardlocal', component: PagarComponent, canActivate: [LoginGuard] },
      { path: 'productos', component: ProductosComponent, canActivate: [LoginGuard] },
      { path: 'stock', component: StockComponent, canActivate: [LoginGuard] },
      { path: 'stock-seba', component: StockSebaComponent, canActivate: [LoginGuard] },
      { path: 'ventas', component: VentasComponent, canActivate: [LoginGuard] },
      { path: 'mails', component: MailsComponent, canActivate: [LoginGuard] },
      { path: 'dialog', component: DialogComponent },
      { path: 'miplan', redirectTo: 'micuenta', pathMatch: 'full' },
      //{ path: 'miplan', component: MiPlanComponent },
      //{ path: 'miplan/cancelar', component: CancelarComponent, canActivate: [LoginGuard] },
      //{ path: 'miplan/cancelar2/:plan', component: Cancelar2Component, canActivate: [LoginGuard] },
      //{ path: 'miplan/felicitaciones', component: FelicitacionesComponent, canActivate: [LoginGuard] },
      { path: 'pedidosonline', component: MispedidosonlineComponent, canActivate: [LoginGuard] },
      { path: 'adminCuentas', component: AdminCuentasComponent, canActivate: [ResellerGuard] },
      { path: 'adminScripts', component: AdminScriptsComponent, canActivate: [AdminGuard] },
      { path: 'adminHistorial', component: AdminHistorialComponent, canActivate: [AdminGuard] },
      { path: 'adminFacturacion', component: AdminFacturacionComponent, canActivate: [AdminGuard] },
      { path: 'adminCelu', component: AdminCeluComponent, canActivate: [AdminGuard] },
      { path: 'testPOnline', component: TestPedidosOnlineComponent, canActivate: [AdminGuard] },
      { path: 'buscarPedido', component: AdminBuscarPedidoComponent, canActivate: [AdminGuard] },
      { path: 'adminPuntuaciones', component: AdminPuntuacionesComponent, canActivate: [ResellerGuard] },
      { path: 'adminPuntuaciones/:idCuenta', component: AdminPuntuacionesJornadaComponent, canActivate: [ResellerGuard] },
      { path: 'adminPuntuaciones/last/:cant', component: AdminPuntuacionesJornadaComponent, canActivate: [ResellerGuard] },
      { path: 'adminMetricas', component: AdminMetricasComponent, canActivate: [AdminGuard] },
      { path: 'adminMetricas2', component: AdminMetricas2Component, canActivate: [AdminGuard] },
      { path: 'adminTareas', component: AdminTareasComponent, canActivate: [AdminGuard] },
      { path: 'adminHistorias', component: AdminHistoriasComponent, canActivate: [AdminGuard] },
      { path: 'adminTickets', component: AdminTicketsComponent, canActivate: [AdminGuard] },
      { path: 'adminUsuarios', component: AdminUsuariosComponent, canActivate: [AdminGuard] },
      { path: 'adminEmails', component: AdminEmailsFirestoreComponent, canActivate: [AdminGuard] },
      { path: 'adminOrigenes', component: AdminOrigenesComponent, canActivate: [AdminGuard] },
      { path: 'adminTiendasRappiError', component: TiendasErrorRappiComponent, canActivate: [AdminGuard] },
      { path: 'nuevoManejoCuentas', component: ManejoCuentasNuevoComponent, canActivate: [AdminGuard] },
      { path: 'movimientosAdmin', component: AdminMovimientosComponent, canActivate: [AdminGuard] },
      { path: 'movimientosAdmin/:idCuenta', component: AdminMovimientosComponent, canActivate: [AdminGuard] },
      { path: 'erroresAdmin', component: AdminErroresComponent, canActivate: [AdminGuard] },
      { path: 'statusPOnline', component: AdminStatusPOnlineComponent, canActivate: [AdminGuard] },
      { path: 'procesos', component: AdminProcesosComponent, canActivate: [AdminGuard] },
      { path: 'map', component: MapCuentasComponent, canActivate: [AdminGuard] },
      { path: 'adminCRM', component: AdminEmailsComponent, canActivate: [AdminGuard] },
      { path: 'pedidosReportados', component: PedidosReportadosComponent, canActivate: [AdminGuard] },
      { path: 'reportes', component: ReportesComponent, canActivate: [AdminGuard] },
      { path: 'admin2/gestion-cuentas', component: GestionCuentasComponent, canActivate: [AdminGuard] },
      { path: 'admin2/gestion-locaciones', component: GestionLocacionesComponent, canActivate: [AdminGuard] },
      { path: 'admin2/gestion-servidores', component: GestionServidoresComponent, canActivate: [AdminGuard] },
      { path: 'admin/invitaciones', component: InvitacionesComponent, canActivate: [AdminGuard] },
      { path: 'admin/versiones-manager', component: VersionesManagerComponent, canActivate: [AdminGuard] },
      { path: 'admin/pricing-billing', component: ModalPricingBillingComponent, canActivate: [AdminGuard] },
      { path: 'admin/update-pedidos-manager', component: AdminUpdatePedidosComponent, canActivate: [AdminGuard] },
      // { path: '**', redirectTo: '', pathMatch: 'full' }
    ]
  },



  /* { path: 'v2', component: HomeComponent },
   { path: 'v2/mypopapp', component: MainComponent },
   { path: 'v2/mypopapp/bienvenido', component:  Component, canActivate: [LoginGuard] },
   { path: 'v2/comenzar', component: ComenzarComponent },
   { path: 'v2/mypopapp/acceder', component: AccederComponent },
   { path: 'v2/mypopapp/descargas', component: DescargasComponent, canActivate: [LoginGuard] },
   //{ path: 'mypopapp/ventas', component: VentasSucursalesComponent },
 */
  //error 404
  //{ path: '**', component: HomeComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
