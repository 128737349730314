import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList, } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';

import { MatSort, } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AuthService } from "../../core/auth.service";
import { Router } from '@angular/router';
import { OrdenPya } from '../../shared/admin/ordenPya';
import { HeartLocal } from '../../shared/admin/heartlocal';
import * as consts from "../../shared/constantes";


@Component({
  selector: 'app-admin-statusponline',
  templateUrl: './admin-statusponline.component.html',
  styleUrls: ['./admin-statusponline.component.scss']
})

export class AdminStatusPOnlineComponent implements OnInit {

  ordenesRef;

  cantLeidos: number = 0;

  ordenesMap: Map<String, OrdenPya>;
  ordenes: OrdenPya[] = [];

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;

  itemsAProcesarRef: AngularFireList<any>;
  itemsAProcesar: Observable<any[]>;

  msjSnack: string;

  fecha: any;
  //selectedOptions: ServidorAdmin[];
  @ViewChild(MatSort) sort: MatSort;

  fechaInicioQuery: string;
  fechaFinQuery: string;
  idLocalPya: string = "102144";
  pya: string = "/pya";
  urlFbase: string;
  //datos tabla


  displayedColumns: string[] = ['select', 'registeredDate', 'restaurant', 'state', 'id', 'deliveryDate', 'tipo', 'user',
    'details', 'address'
  ];

  columnsToDisplay: string[] = this.displayedColumns;//.slice(0, 9);

  dataSource: MatTableDataSource<OrdenPya> = new MatTableDataSource(this.ordenes);


  filtroTodasColumnas: boolean = false;

  filtRechazadas: boolean = false;
  filtConfirmadas: boolean = false;


  filtroActual: string = "a";
  filtroActual2: string = "";
  buscandoActual: string;

  obsHeartBeat: AngularFireList<any>;
  obsHeartBeatObservable: Observable<any>;
  arreHeartLocales: HeartLocal[];

  actualizaColumnas(value: number, filtro: boolean) {
    if (value == 999) {
      if (filtro) {
        //ocultar columnas
        /*this.columnsToDisplay = this.displayedColumns.slice(0, 5);*/

        this.filtRechazadas = false;
        this.filtConfirmadas = false;


      } else {
        /*this.columnsToDisplay = this.displayedColumns.slice(0, 5);
        for (let i = 5; i < 48; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }*/
        this.filtRechazadas = true;
        this.filtConfirmadas = true;

      }
      this.applyFilter(this.filtroActual);
    } else if (filtro) {

      if (value == 0) {
        this.filtRechazadas = false;
        this.applyFilter(this.filtroActual);
        this.filtRechazadas = true;
      } else if (value == 1) {
        this.filtConfirmadas = false;
        this.applyFilter(this.filtroActual);
        this.filtConfirmadas = true;
      }
    } else {
      //mostrar columnas correspondientes
      this.applyFilter(this.filtroActual);

      if (value == 0) {
        this.filtRechazadas = true;
        this.applyFilter(this.filtroActual);
        this.filtRechazadas = false;

      } else if (value == 1) {
        this.filtConfirmadas = true;
        this.applyFilter(this.filtroActual);
        this.filtConfirmadas = false;
      }
    }

  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = "";

    let filtro = filterValue.trim().toLowerCase();
    if (!filtro || filtro.length == 0) {
      filtro = "a";
      this.filtroActual2 = "";
      this.buscandoActual = "";
    } else {
      if (filtro === "a") {
        this.filtroActual2 = "";
        this.buscandoActual = "";
      } else {
        this.filtroActual2 = filtro;
        this.buscandoActual = filtro;
      }
    }

    this.filtroActual = filtro;
    this.dataSource.filter = filtro;

    this.dataSource.sort = this.sort;


  }


  selection = new SelectionModel<OrdenPya>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  driftVar: any;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  ngOnInit() {
    let date = new Date();
    this.fechaFinQuery = date.toISOString();
    this.fechaInicioQuery = new Date(date.setDate(date.getDate() - 1)).toISOString();
    this.dataSource.sort = this.sort;
    this.buscar();
    this.hideDrift(1)
  }

  constructor(public router: Router, public afAuth: AngularFireAuth, public db: AngularFireDatabase, public authService: AuthService) {
    //console.log("ENTRO");
  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }

  buscar() {
    this.urlFbase = this.pya + this.idLocalPya;

    this.afAuth.user.subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {
        // console.log("SUSCRIPTO");

        //leemos las cuentas
        this.itemsRef = this.db.list('pedidosProcesados' + this.urlFbase, ref => {// Use snapshotChanges().map() to store the key
          return ref.orderByChild('registeredDate').startAt(this.fechaInicioQuery).endAt(this.fechaFinQuery)
        });

        this.items = this.itemsRef.snapshotChanges().pipe(
          map(changes => {
            let mapeado = changes.map(orderAux => ({ key: orderAux.payload.key, ...orderAux.payload.val() }));
            //console.log("LLEGO ALGO");
            this.procesaOrdersProcesadas(mapeado);
            this.dataSource.sort = this.sort;
            return mapeado;
          })
        );
        //console.log("leer")

        this.obsHeartBeat = this.db.list("heartBeatLocales");
        this.obsHeartBeatObservable = this.obsHeartBeat.snapshotChanges().pipe(
          map(v => {
            this.arreHeartLocales = v.map(hear => ({ keyLocal: hear.payload.key, ...hear.payload.val() }));

            //  console.log(this.arreHeartLocales)
            return this.arreHeartLocales;
          })
        );


        /*this.obsHeartBeatObservable.toPromise().then(v => {
          let numero = v.payload.val();
          console.log("leido", numero)
          if (numero === -1) {
            this.heartbeat = "Cerrado"
          } else {
            this.heartbeat = v + ""
          }
        })*/
      }
    });
  }


  buscarAProcesar() {
    //y se fija si tiene una orden a procesar le pone ese atributo
    this.urlFbase = this.pya + this.idLocalPya;

    this.itemsAProcesarRef = this.db.list('pedidosAProcesar' + this.urlFbase, ref => {// Use snapshotChanges().map() to store the key
      return ref.orderByChild('registeredDate').startAt(this.fechaInicioQuery).endAt(this.fechaFinQuery)
    });
    this.itemsAProcesar = this.itemsAProcesarRef.snapshotChanges().pipe(
      map(changes => {
        let mapeado = changes.map(orderAux => ({ key: orderAux.payload.key, ...orderAux.payload.val() }));
        //console.log("LLEGO ALGO");
        this.procesaOrdersAProcesar(mapeado);
        return mapeado;
      })
    );
  }


  sortHeader(index: number) {
    //console.log("click");
    this.dataSource.sort = this.sort;
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }


  onRightClick(input: string, element: OrdenPya) {
    if (input == "cobrar") {
      //leer mails de ese usuario

      return false;
    }
  }

  public actualizaTipoDeOrden(o: OrdenPya) {
    if (o.logistics) {
      o.tipo = "logistics";
    } else if (o.preOrder) {
      o.tipo = "preOrder";
    } else if (o.pickup) {
      o.tipo = "pickup";
    } else {
      o.tipo = "regular";
    }
  }

  public stringDeDetalles(o: OrdenPya) {
    let itemsDeOrden = o.details;
    let stringDetalles = "";
    for (let i of itemsDeOrden) {
      stringDetalles += i.quantity + " " + i.product.name + " $" + i.total + "   ---   ";
    }
    o.detailsString = stringDetalles;
  }

  inputABM(input: string, element: OrdenPya) {
    let ordenesAModificar: OrdenPya[] = [];
    if (this.selection.selected && this.selection.selected.length > 0) {
      for (let i = 0; i < this.selection.selected.length; i++) {
        ordenesAModificar.push(this.selection.selected[i]);
      }
      if (!this.selection.selected.includes(element)) {
        ordenesAModificar.push(element);
      } //else ya se proceso en el for
    } else {
      //proceso individual
      ordenesAModificar.push(element);
    }
  }

  procesaOrdersProcesadas(mapOrders: any[]) {

    this.ordenes = [];
    this.ordenesMap = new Map<string, OrdenPya>();
    this.ordenes = [];
    for (let i in mapOrders) {
      let o: OrdenPya = mapOrders[i];
      o.id = mapOrders[i].key;
      this.ordenesMap.set(mapOrders[i].key, o);
      this.actualizaTipoDeOrden(o);
      this.stringDeDetalles(o);
      //aca iria poner tipos de MP y esas cosas
      this.ordenes.push(o);
      // no habria q sumar nada pues no sabemos q moneda iria
    }

    this.buscarAProcesar();

    this.dataSource = new MatTableDataSource(this.ordenes);
    this.dataSource.filterPredicate = (o: OrdenPya, filter: string) => {
      if (!this.filtRechazadas && o.state && o.state === "REJECTED") {
        return false;
      }

      if (!this.filtConfirmadas && o.state && o.state === "CONFIRMED") {
        return false;
      }

      return JSON.stringify(o).trim().toLowerCase().indexOf(filter) != -1;
    }
    this.applyFilter(this.filtroActual);
    this.deseleccionarTodes();
  }

  procesaOrdersAProcesar(mapOrders: any[]) {
    for (let i in mapOrders) {
      let o: OrdenPya = mapOrders[i];
      o.id = mapOrders[i].key;
      this.actualizaTipoDeOrden(o);
      this.stringDeDetalles(o);
      let ordenProcesada = this.ordenesMap.get(mapOrders[i].key);
      if (ordenProcesada) {
        ordenProcesada.ordenAprocesar = o;
      } else {
        o.noTieneOrdenProcesada = true;
        this.ordenesMap.set(mapOrders[i].key, o);
        this.ordenes.push(o);
      }
    }
    this.applyFilter(this.filtroActual);


  }
  actualizaLocal(id: number) {
    this.idLocalPya = id + "";
    this.buscar()
  }

  nombreKeyLocal(h: HeartLocal): string {
    if (h.nombre) {
      return h.nombre;
    } //else if (h.keyLocal.startsWith("keyPedidosOnline")) {
    //return h.keyLocal.replace("keyPedidosOnline", "");
    else {
      return h.keyLocal;
    }
  }

  lugarConHeart(h: HeartLocal): string {

    let min = 1809229023691;
    let max = -99;
    let cant = 0;
    let cantTotales = 0;
    for (let key in h.tiendas) {
      let v: { t: number, n?: string } = h.tiendas[key];
      if (v.t > max) {
        max = v.t;
      }

      if (v.t < min) {
        min = v.t;
      }

      if (min === max) {
        cant++;
      }
      cantTotales++;
    }

    let resultado = cant + "/" + cantTotales + " tiendas: "
    if (min === -1) {
      resultado += "Cerrado"
    } else {
      let date = new Date();
      date.setTime(min)
      resultado += date.toLocaleDateString() + "___" + date.toLocaleTimeString()
    }
    return resultado;
  }

  getStyleHeartBeat(h: HeartLocal) {

    let min = 1809229023691;
    let max = -99;
    let cant = 0;
    let cantTotales = 0;
    for (let key in h.tiendas) {
      let v: { t: number, n?: string } = h.tiendas[key];
      if (v.t > max) {
        max = v.t;
      }

      if (v.t < min) {
        min = v.t;
      }

      if (min === max) {
        cant++;
      }
      cantTotales++;
    }


    let resultado = cant + "/" + cantTotales + " tiendas: "
    if (min === -1) {
      resultado += "Cerrado"
    } else {
      let date = new Date();
      date.setTime(min)
      resultado += date.toLocaleDateString() + "___" + date.toLocaleTimeString()
    }




    let heartbeat: string;
    if (min === -1) {
      heartbeat = "Cerrado"
    } else {
      let date = new Date();
      min = min - 3 * 60 * 60 * 1000
      date.setTime(min);
      heartbeat = date.toISOString();
    }
    let date = new Date().getTime();
    date = date - 3 * 60 * 60 * 1000

    if (heartbeat) {
      if (heartbeat === "Cerrado") {
        return {
          'color': 'white',
          'background': 'violet'
        }
      } else {
        let date2 = new Date(min).getTime();
        let resta = date - date2;
        if (resta > 1000 * 60 * 4) {
          return {
            'color': 'white',
            'background': 'red'
          }
        } else {
          return {
            'color': 'white',
            'background': 'green'
          }
        }
      }
    } else {
      return {
        'color': 'black',
        'background': 'white'
      }
    }
  }

  getClassHB(h: HeartLocal) {

    let min = 1809229023691;
    let max = -99;
    let cant = 0;
    let cantTotales = 0;
    for (let key in h.tiendas) {
      let v: { t: number, n?: string } = h.tiendas[key];
      if (v.t > max) {
        max = v.t;
      }

      if (v.t < min) {
        min = v.t;
      }

      if (min === max) {
        cant++;
      }
      cantTotales++;
    }


    let resultado = cant + "/" + cantTotales + " tiendas: "
    if (min === -1) {
      resultado += "Cerrado"
    } else {
      let date = new Date();
      date.setTime(min)
      resultado += date.toLocaleDateString() + "___" + date.toLocaleTimeString()
    }




    let heartbeat: string;
    if (min === -1) {
      heartbeat = "Cerrado"
    } else {
      let date = new Date();
      min = min - 3 * 60 * 60 * 1000
      date.setTime(min);
      heartbeat = date.toISOString();
    }
    let date = new Date().getTime();
    date = date - 3 * 60 * 60 * 1000

    if (heartbeat) {
      if (heartbeat === "Cerrado") {
        return 'task-high';
      } else {
        let date2 = new Date(min).getTime();
        let resta = date - date2;
        if (resta > 1000 * 60 * 4) {
          return 'task-medium';
        } else {
          return 'task-low';
        }
      }
    } else {
      return 'task-undefined';
    }
  }

  getMyStyles(o: OrdenPya) {
    let myStyles;
    if (o.ordenAprocesar) {
      myStyles = {
        'color': 'white',
        'background': 'violet'
      }
    } else {
      myStyles = {
        'color': 'black',
        'background': 'white'
      }
    }

    //myStyles[] = {

    //'padding': '10px'
    //};
    return myStyles;
  }

  deseleccionarTodes() {
    this.selection.clear();
  }

  openSnackBar(message: string, action: string) {
    this.msjSnack = "|  " + message + "      " + action + "  |"
    setTimeout(_ => {
      this.msjSnack = null
    }, 3000)
  }

}
