/**
 * Para registar los pasos de los leads en el proceso de onboarding
 */
export class EmailCuenta {
    /**
     * Indica el ultimo formulario que lleno el email de ese contacto
     */
    contactoDesde;// = "Form Home ";

    /**
     * date in millis desde que se origino el ultimo contacto de ese email Timestamp de Firebase, se usa en complemento con el contactoDesde
     */
    contactoDate: number;// = {".sv": "timestamp"};

    /**
     * date in millis desde cuando se creo en Timestamp de Firebase, se usa en complemento con el creadoDesde
     */
    creadoDate: number;

    /**
     * indica quien fue el que registra el email por primera vez
     */
    creadoDesde: string;

    /**
     * NO ES UNA VARIABLE DE FIREBASE
     * email normal
     */
    email: string;

    /**
     * NO ES UNA VARIABLE DE FIREBASE
     * email sin puntos
     */
    emailSinPuntos: string;

    /**
     * si se mando el mail de verificacion para que solo se mande una vez
     */
    mandoMailVerificacion: boolean;

    /**
     * el nombre que ingresan en el form de la web o en el form de manager o el displayName si se crea con un inicio de Gmail
     */
    nombre: string;

    /**
     * Nombre del local cuando instalan el servidor
     */
    nombreLocal: string;

    /**
     * el pais que se elige en la web o en la instalacion AR= Argentina, CL= Chile, PE = Peru, para todos los codigos https://es.wikipedia.org/wiki/ISO_3166-1
     */
    pais: string;

    /**
     * un hash mac, fecha(yyyy;MM;dd;HH;mm;ss), (evento y dato)
     */
    servidores: Map<string, Map<string, { dato: string, evento: string }>>;

    telPais: string;
    telArea: string;
    telNum: string;

    /**
     * si el email es valido
     */
    verificadoEmail: boolean;

    /**
     * si el numero de whatsapp es valido
     */
    verificadowapp: boolean;

    /**
     * numero de whatsapp en formato +549264...
     */
    wapp: string;
}