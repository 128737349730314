import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fecha',
  templateUrl: './fecha.component.html',
  styleUrls: ['./fecha.component.scss']
})
export class FechaComponent implements OnInit, AfterViewInit {
  closeResult: string;
  maxDate: Date = new Date();
  @ViewChild('picker') picker: MatDatepicker<Date>;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => this.abrirPicker(0), 1000)
  }

  abrirPicker(intentos: number) {
    if (this.picker && !this.picker.opened) {
      this.picker.open();
    } else {
      if (intentos < 5) {
        intentos++;
        setTimeout(() => this.abrirPicker(intentos), 1000)
      }
    }
  }



  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
