import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule, ClusterManager } from "@agm/js-marker-clusterer";

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpModule } from '@angular/http';

import { MatNativeDateModule } from "@angular/material/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatBadgeModule, } from "@angular/material/badge"
import { MatButtonModule, } from "@angular/material/button"
import { MatButtonToggleModule, } from "@angular/material/button-toggle"
import { MatCardModule, } from "@angular/material/card"
import { MatCheckboxModule, } from "@angular/material/checkbox"
import { MatChipsModule, } from "@angular/material/chips"
import { MatDatepickerModule, } from "@angular/material/datepicker"
import { MatDialogModule, } from "@angular/material/dialog"
import { MatFormFieldModule, } from "@angular/material/form-field"
import { MatGridListModule, } from "@angular/material/grid-list"
import { MatIconModule, } from "@angular/material/icon"
import { MatInputModule, } from "@angular/material/input"
import { MatListModule, } from "@angular/material/list"
import { MatMenuModule, } from "@angular/material/menu"
import { MatProgressBarModule, } from "@angular/material/progress-bar"
import { MatProgressSpinnerModule, } from "@angular/material/progress-spinner"
import { MatRadioModule, } from "@angular/material/radio"
import { MatSelectModule, } from "@angular/material/select"
import { MatSidenavModule, } from "@angular/material/sidenav"
import { MatSliderModule, } from "@angular/material/slider"
import { MatSlideToggleModule, } from "@angular/material/slide-toggle"
import { MatSnackBarModule, } from "@angular/material/snack-bar"
import { MatSortModule, } from "@angular/material/sort"
import { MatTableModule, } from "@angular/material/table"
import { MatToolbarModule, } from "@angular/material/toolbar"
import { MatTooltipModule, } from "@angular/material/tooltip"

import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ResponsiveModule } from 'ngx-responsive';


//componentes
import { AccederComponent } from './inicio/acceder/acceder.component';
import { AdminCeluComponent } from './admin/admin-celu/admin-celu.component';
import { AdminCuentasComponent } from './admin/admin-cuentas/admin-cuentas.component';
import { AdminEmailsComponent } from './admin/admin-emails/admin-emails.component';
import { AdminErroresComponent } from './admin/admin-errores/admin-errores.component';
import { AdminMetricasComponent } from './admin/admin-metricas/admin-metricas.component';
import { AdminMovimientosComponent } from './admin/admin-movimientos/admin-movimientos.component';
import { AdminStatusPOnlineComponent } from './admin/admin-statusponline/admin-statusponline.component';
import { BienvenidoComponent } from './inicio/bienvenido/bienvenido.component';
import { CancelarComponent } from './mi-plan/cancelar/cancelar.component';
import { Cancelar2Component } from './mi-plan/cancelar2/cancelar2.component';
import { ComenzarComponent } from './inicio/comenzar/comenzar.component';
import { DescargasComponent } from './inicio/descargas/descargas.component';
import { Descargas2Component } from './descargas2/descargas2.component';
import { DialogComponent } from './modales/dialog/dialog.component';
import { FechaComponent } from './fecha/fecha.component';
import { FelicitacionesComponent } from './mi-plan/felicitaciones/felicitaciones.component';
import { HeaderlandingComponent } from './inicio/headerlanding/headerlanding.component';
import { HistorialComponent } from './historial/historial.component'
import { LinechartComponent } from './charts/linechart/linechart.component';
import { BarchartComponent } from './charts/barchart/barchart.component';
import { MailsComponent } from './mails/mails.component';
import { MainComponent } from './main/main.component';
import { MapCuentasComponent } from './admin/map-cuentas/map-cuentas.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { MiPlanComponent } from './mi-plan/mi-plan.component';
import { MypopappComponent } from './mypopapp/mypopapp.component';
import { OnepayComponent } from './onepay/onepay.component';
import { OrdenesComponent } from './historial/ordenes/ordenes.component';
import { PagarComponent } from './pagar/pagar.component';
import { PedidosonlineComponent } from './pedidosonline/pedidosonline.component';
import { PiechartComponent } from './charts/piechart/piechart.component';
import { SnackBarProgressComponent } from './snack-bar-progress/snack-bar-progress.component';
import { StockComponent } from './stock/stock.component';
import { StockSebaComponent } from './stock-seba/stock-seba.component'
import { TestimonialesComponent } from './testimoniales/testimoniales.component';
import { VentasComponent } from './ventas/ventas.component';


//servicios
import { AuthService } from './core/auth.service';
import { CuentaService } from './services/cuenta.service';
import { GooglepiechartService } from './charts/googlepiechart.service';
import { GooglebasechartService } from './charts/googlebasechart.service';
import { GooglelinechartService } from './charts/googlelinechart.service';
import { LogService } from './services/log.service';
import { ModalOlvidePassComponent } from './modales/modal-olvide-pass/modal-olvide-pass.component';
import { RutaService } from './services/ruta.service';
import { TeaserpedidosonlineComponent } from './modales/teaserpedidosonline/teaserpedidosonline.component';
import { TeaserleadspedidosonlineComponent } from './modales/teaserleadspedidosonline/teaserleadspedidosonline.component';
import { ModalAlertaStockComponent } from './modales/modal-alerta-stock/modal-alerta-stock.component';

//pipes
import { CobroPipe } from './pipes/cobro';
import { MinutosPipe } from './pipes/minutos';
import { DetalleordenComponent } from './historial/detalleorden/detalleorden.component';
import { AdminPuntuacionesComponent } from './admin/admin-puntuaciones/admin-puntuaciones.component';
import { AdminPuntuacionesJornadaComponent } from './admin/admin-puntuaciones-jornada/admin-puntuaciones-jornada.component';
import { ModalFixComponent } from './modales/modal-fix/modal-fix.component';
import { ProductosComponent } from './productos/productos.component';
import { ProductosABMComponent } from './productos-abm/productos-abm.component';
import { FiltroComponent } from './reutil/filtro/filtro.component';
import { DatePipe } from '@angular/common';
import { ModalListamsjsComponent } from './modales/modal-listamsjs/modal-listamsjs.component';
import { AdminProcesosComponent } from './admin/admin-procesos/admin-procesos.component';
import { TestPedidosOnlineComponent } from './admin/test-pedidos-online/test-pedidos-online.component';
import { ModalTextoComponent } from './modales/modal-texto/modal-texto.component';
import { AdminEmailsFirestoreComponent } from './admin/admin-emails-firestore/admin-emails-firestore.component';

//Font awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AdminTareasComponent } from './admin/admin-tareas/admin-tareas.component';
import { AdminUsuariosComponent } from './admin/admin-usuarios/admin-usuarios.component';
import { SelectorfechasComponent } from './reutil/selectorfechas/selectorfechas.component';
import { AdminTicketsComponent } from './admin/admin-tickets/admin-tickets.component';

//Custom Modules
import { LayoutsModule } from './layouts/layouts.module';
import { MispedidosonlineComponent } from './mispedidosonline/mispedidosonline.component';
import { AdminHistorialComponent } from './admin/admin-historial/admin-historial.component';
import { AdminHistoriasComponent } from './admin/admin-historias/admin-historias.component';
import { PedidoComponent } from './mispedidosonline/pedido/pedido.component';
import { ModalConfPonlineComponent } from './modales/modal-conf-ponline/modal-conf-ponline.component';
import { ModalTiendaComponent } from './modales/modal-tienda/modal-tienda.component';
import { ModalConfVessiComponent } from './modales/modal-conf-vessi/modal-conf-vessi.component';
import { AdminBuscarPedidoComponent } from './admin/admin-buscar-pedido/admin-buscar-pedido.component';
import { ModalConfIngefacturaComponent } from './modales/modal-conf-ingefactura/modal-conf-ingefactura.component';
import { PedidosReportadosComponent } from './admin/pedidos-reportados/pedidos-reportados.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalPricingComponent } from './modales/modal-pricing/modal-pricing.component';
import { ModalConceptoComponent } from './modales/modal-concepto/modal-concepto.component';
import { DbFSService } from './services/db-fs.service';
import { ReportesComponent } from './admin/reportes/reportes.component';
import { AdminScriptsComponent } from './admin/admin-scripts/admin-scripts.component';
import { ModalVincularCentralizadoComponent } from './modales/modal-vincular-centralizado/modal-vincular-centralizado.component';
import { ModalConfFacturapiComponent } from './modales/modal-conf-facturapi/modal-conf-facturapi.component';
import { ModalConfContabiliumComponent } from './modales/modal-conf-contabilium/modal-conf-contabilium.component';
import { ModalAutomatizarSyncManagerComponent } from './modales/modal-automatizar-sync-manager/modal-automatizar-sync-manager.component';
import { ModalEnvioMailPrecioComponent } from './modales/modal-envio-mail-precio/modal-envio-mail-precio.component';
import { AdminFacturacionComponent } from './admin/admin-facturacion/admin-facturacion.component';
import { TablaComponent } from './tabla/tabla.component';
import { ModalMpComponent } from './modales/modal-mp/modal-mp.component';
import { ModalIntegrarMpComponent } from './modales/modal-integrar-mp/modal-integrar-mp.component';
import { AdminOrigenesComponent } from './admin/admin-origenes/admin-origenes.component';
import { OrigenComponent } from './origen/origen.component';
import { ModalOrigenesComponent } from './modales/modal-origenes/modal-origenes.component';
import { ModalFullprimeComponent } from './modales/modal-fullprime/modal-fullprime.component';
import { ModalEmailAumentoChileComponent } from './modales/modal-email-aumento-chile/modal-email-aumento-chile.component';
import { ModalMarcasComponent } from './modales/modal-marcas/modal-marcas.component';
import { ModalConfigFactPopappChiComponent } from './modales/modal-config-fact-popapp-chi/modal-config-fact-popapp-chi.component';
import { ModalConfPaisOffsetComponent } from './modal-conf-pais-offset/modal-conf-pais-offset.component';
import { TemplatepagosComponent } from './admin/templatepagos/templatepagos.component';
import { AdminAutomatizacionesComponent } from './admin/admin-automatizaciones/admin-automatizaciones.component';
import { TiendasErrorRappiComponent } from './tiendas-error-rappi/tiendas-error-rappi.component';
import { ManejoCuentasNuevoComponent } from './manejo-cuentas-nuevo/manejo-cuentas-nuevo.component';
import { Admin2Module } from './admin2/admin2.module';
import { PagardlocalComponent } from './pagardlocal/pagardlocal.component';
import { AdminMetricas2Component } from './admin/admin-metricas2/admin-metricas2.component';
import { VersionesManagerComponent } from './admin/versiones-manager/versiones-manager.component';
import { ModalPricingBillingComponent } from './admin/modal-pricing-billing/modal-pricing-billing.component';
import { ConceptModalComponent } from './admin/modal-pricing-billing/concept-modal/concept-modal.component';
import { BillingConceptCardComponent } from './admin/modal-pricing-billing/billing-concept-card/billing-concept-card.component';
import { InvitacionesComponent } from './admin/invitaciones/invitaciones.component';
import { AdminUpdatePedidosComponent } from './admin/admin-update-pedidos/admin-update-pedidos.component';

@NgModule({
  declarations: [
    CobroPipe,
    MinutosPipe,
    AccederComponent,
    AppComponent,
    AdminCeluComponent,
    AdminCuentasComponent,
    AdminEmailsComponent,
    AdminErroresComponent,
    AdminMetricasComponent,
    AdminMovimientosComponent,
    AdminStatusPOnlineComponent,
    AppComponent,
    BienvenidoComponent,
    CancelarComponent,
    Cancelar2Component,
    ComenzarComponent,
    DescargasComponent,
    Descargas2Component,
    DialogComponent,
    FechaComponent,
    FelicitacionesComponent,
    HeaderlandingComponent,
    HistorialComponent,
    LinechartComponent,
    BarchartComponent,
    MailsComponent,
    MainComponent,
    MapCuentasComponent,
    MiCuentaComponent,
    MiPlanComponent,
    ModalAlertaStockComponent,
    ModalOlvidePassComponent,
    MypopappComponent,
    OnepayComponent,
    OrdenesComponent,
    PagarComponent,
    PedidosonlineComponent,
    PiechartComponent,
    SnackBarProgressComponent,
    StockComponent,
    StockSebaComponent,
    TestimonialesComponent,
    VentasComponent,
    TeaserpedidosonlineComponent,
    TeaserleadspedidosonlineComponent,
    DetalleordenComponent,
    AdminPuntuacionesComponent,
    AdminPuntuacionesJornadaComponent,
    ModalFixComponent,
    ProductosComponent,
    ProductosABMComponent,
    FiltroComponent,
    ModalListamsjsComponent,
    AdminProcesosComponent,
    TestPedidosOnlineComponent,
    ModalTextoComponent,
    AdminEmailsFirestoreComponent,
    AdminTareasComponent,
    AdminUsuariosComponent,
    SelectorfechasComponent,
    AdminTicketsComponent,
    MispedidosonlineComponent,
    AdminHistorialComponent,
    AdminHistoriasComponent,
    PedidoComponent,
    ModalConfPonlineComponent,
    ModalTiendaComponent,
    ModalConfVessiComponent,
    AdminBuscarPedidoComponent,
    ModalConfIngefacturaComponent,
    PedidosReportadosComponent,
    ModalPricingComponent,
    ModalConceptoComponent,
    ReportesComponent,
    AdminScriptsComponent,
    ModalVincularCentralizadoComponent,
    ModalConfFacturapiComponent,
    ModalConfContabiliumComponent,
    ModalAutomatizarSyncManagerComponent,
    ModalEnvioMailPrecioComponent,
    AdminFacturacionComponent,
    TablaComponent,
    ModalMpComponent,
    ModalIntegrarMpComponent,
    AdminOrigenesComponent,
    OrigenComponent,
    ModalOrigenesComponent,
    ModalFullprimeComponent,
    ModalEmailAumentoChileComponent,
    ModalMarcasComponent,
    ModalConfigFactPopappChiComponent,
    ModalConfPaisOffsetComponent,
    TemplatepagosComponent,
    AdminAutomatizacionesComponent,
    TiendasErrorRappiComponent,
    ManejoCuentasNuevoComponent,
    PagardlocalComponent,
    AdminMetricas2Component,
    VersionesManagerComponent,
    ModalPricingBillingComponent,
    ConceptModalComponent,
    BillingConceptCardComponent,
    InvitacionesComponent,
    AdminUpdatePedidosComponent,
  ],

  imports: [
    LayoutsModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCoD86If_hRE88cPWQ4xoakwsRDkwV6nZA'
    }),
    AgmJsMarkerClustererModule,

    AngularFireModule.initializeApp(environment.firebase),//environment.production
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,

    AppRoutingModule,

    BrowserAnimationsModule,
    BrowserModule,


    FormsModule,
    HttpModule,

    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,

    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    ResponsiveModule.forRoot(),

    FontAwesomeModule,
    Admin2Module
  ],

  entryComponents: [
    ModalConceptoComponent,
    ModalPricingComponent,
    ModalConfVessiComponent,
    ModalConfIngefacturaComponent,
    ModalConfFacturapiComponent,
    ModalTiendaComponent,
    ModalConfPonlineComponent,
    ModalTextoComponent,
    ModalAlertaStockComponent,
    ModalFixComponent,
    ModalListamsjsComponent,
    ModalOlvidePassComponent,
    SnackBarProgressComponent,
    TeaserpedidosonlineComponent,
    TeaserleadspedidosonlineComponent,
    ModalVincularCentralizadoComponent,
    ModalConfContabiliumComponent,
    ModalAutomatizarSyncManagerComponent,
    ModalEnvioMailPrecioComponent,
    ModalMpComponent,
    ModalOrigenesComponent,
    ModalEmailAumentoChileComponent,
    ModalConfigFactPopappChiComponent,
    ModalConfPaisOffsetComponent,
    ModalFullprimeComponent
  ],

  providers: [
    AuthService,
    ClusterManager,
    CuentaService,
    DbFSService,
    FelicitacionesComponent,
    GooglebasechartService,
    GooglelinechartService,
    GooglepiechartService,
    LogService,
    RutaService,
    MinutosPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ar' },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
