import { Injectable } from '@angular/core';
import { NetworkMode, ServidorAdmin } from '../shared/admin/servidor-admin';
import { of } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { MovimientoAdmin } from '../shared/admin/movimientoAdmin';
import { SelectionModel } from '@angular/cdk/collections';
import { CuentaService } from './cuenta.service';
import { CuentaAdmin } from '../shared/admin/cuenta-admin';
import { LocalAdmin } from '../shared/admin/local-admin';
import { Http } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { ModalFixComponent } from '../modales/modal-fix/modal-fix.component';
import { ModalListamsjsComponent } from '../modales/modal-listamsjs/modal-listamsjs.component';
import { DbFSService } from './db-fs.service';
import { Headers, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';


export interface DialogFixsData {
  sentencia: string;
  servidores: ServidorAdmin[];
}
export interface DialogListaMsjsData {
  msj: string;
  servidores: ServidorAdmin[];
}

const url = environment.urls.functions + "/basesql/"
// const url = "http://localhost:5000/popappowner/us-central1/basesql/";

@Injectable({
  providedIn: 'root'
})
export class AdminCuentaService {
  BARRA_ULTIMOS_MODIFICADOS_BARRA = "/ultimosModificados/";
  CARTAS_BARRA = "cartas/";

  constructor(private cuentaService: CuentaService, private http: Http, public db: AngularFireDatabase, public dialog: MatDialog, private dbFS: DbFSService) { }

  private URL_BASE_OB = environment.urls.onboarding;
  private URL_BASE_FUNCTIONS = environment.urls.functions;


  private atributosModificablesServidorOnboarding = [
    'mac',
    'enUso',
    'fechaFinPruebaFull',
    'fechaInstalacion',
    'moneda',
    'nombreServidor',
    'network_mode',
    'setting_last_updated',
    'numeroLocal',
    'pagoHasta',
    'pais',
    'planN',
    'vencimiento',
    'versionActual',
    'versionDisponible',
    'showPagar',
    'fix',
    // 'fechaUltimoPago',
    // 'fechaActualizado',
    'keyPedidosOnline',
  ];

  private atributosModificablesCuentasOb = [
    'nombreCuenta',
    'contacto',
    'emailpago',
    'whatsapp',
    'ultimoPago'
  ];

  private atributosModificablesLocacionesOb = [
    'nombreLocal',
  ];

  private traducirAtributoOb(atributoFb: string) {
    switch (atributoFb) {
      case 'nombreServidor': return 'nombre';
      case 'vencimiento': return 'fechaVencimiento';
      case 'nombreCuenta': return 'nombre';
      case 'contacto': return 'nombreCliente';
      case 'emailpago': return 'emailRegistro';
      case 'whatsapp': return 'numeroTelefono';
      case 'nombreLocal': return 'nombre';
      default: return atributoFb;
    }
  }

  async hitearCambiarAtributoOnboarding(recurso: string, id: string, atributoFb: string, valor: unknown, by: string) {
    const jsonBody = {
      atributo: this.traducirAtributoOb(atributoFb),
      valor
    }

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    return this.http.patch(`${this.URL_BASE_OB}/v1/${recurso}/${id}?by=${by}`, jsonBody, options).toPromise();
  }

  async validarYEscribirEnFBServidor(valor: any, validarNumero: boolean, input: string, servAModificar: ServidorAdmin[], infoCuentasKey?, infoCuentasValue?): Promise<string> {
    const arregloPromesas = [];
    const arregloPromesasOb = [];
    const arregloValoresEnviados = [];
    if (!validarNumero || !isNaN(valor)) {
      console.log("Modificando " + input + "... a " + valor);
      let mapUpdates = {};
      for (let i = 0; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
          if (input === "moneda" && s.keyPedidosOnline && s.keyPedidosOnline.key) {
            arregloPromesas.push(this.dbFS.updateAt("confLocalPO/" + s.keyPedidosOnline.key + "/", { _key: s.keyPedidosOnline.key, moneda: valor }));
          }
          if (this.atributosModificablesServidorOnboarding.includes(input)) {
            arregloPromesasOb.push(this.hitearCambiarAtributoOnboarding('managers', s.idMac, input, valor, 'mac'));
            arregloValoresEnviados.push({ mac: s.idMac, input, valor })
          }
          mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/" + input + "/"] = valor;
        } else {
          console.log("Key Invalida", s);
          throw "Key Invalida...idCuenta, idLocal, idMac: " + s.idCuenta + ", " + s.idLocal + ", " + s.idMac;
        }
      }
      console.log(mapUpdates);

      try {
        await this.db.object("/").update(mapUpdates);
        await Promise.all(arregloPromesasOb);
        await Promise.all(arregloPromesas);
      } catch (e) {
        console.error('error al hitear onboarding-ms - managers', e);
        throw e; // no deja continuar si no se escribió en OB
      }
      return "Exito";
    } else {
      return "No es número";
    }
  }

  async validarYEscribirEnFBLocal(valor: any, validarNumero: boolean, input: string, servAModificar: ServidorAdmin[]): Promise<string> {
    const arregloPromesasOb = [];
    const arregloValoresEnviados = [];
    if (!validarNumero || !isNaN(valor)) {
      console.log("Modificando " + input + "... a " + valor);
      let mapUpdates = {};
      for (let i = 0; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal)) {
          mapUpdates[s.idCuenta + "/locales/" + s.idLocal + "/" + input + "/"] = valor;
          if (this.atributosModificablesLocacionesOb.includes(input)) {
            arregloPromesasOb.push(this.hitearCambiarAtributoOnboarding('locaciones', s.idLocal, input, valor, 'legacyId'));
            arregloValoresEnviados.push({ mac: s.idMac, input, valor })
          }
        } else {
          console.log("Key Invalida", s);
          throw "Key Invalida...idCuenta, idLocal: " + s.idCuenta + ", " + s.idLocal;
        }
      }

      try {
        await Promise.all(arregloPromesasOb);
      } catch (e) {
        console.error('error al hitear onboarding-ms - locaciones', e);
        this.enviarMensajeSlackError('alertas-onboarding', 1, 'Error al actualizar atributo locacion' + `arregloPromesas: ${JSON.stringify(arregloPromesasOb)}, arregloValoresEnviados: ${JSON.stringify(arregloValoresEnviados)}`, { err: e.message, stack: e.stack })
          .subscribe(
            data => {
              console.log('hiteo a mandar slack', data);
            },
            err => console.error('error al mandar slack', err)
          );
        throw e; // no deja continuar si no se puede modificar en onboarding
      }

      return this.db.object("cuentas").update(mapUpdates)
        .then(_ => { return "Exito" })
        .catch(err => {
          console.log(err)
          throw "No pudo grabar " + input;
        })
    } else {
      return of("No es número").toPromise();
    }
  }

  async validarYEscribirEnFBCuenta(valor: any, validarNumero: boolean, input: string, servAModificar: ServidorAdmin[]): Promise<string> {
    const arregloPromesasOb = [];
    const arregloValoresEnviados = [];
    if (!validarNumero || !isNaN(valor)) {
      console.log("Modificando " + input + "... a " + valor);
      let mapUpdates = {};
      for (let i = 0; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta)) {
          //modificaraca
          mapUpdates[s.idCuenta + "/" + input + "/"] = valor;
          if (this.atributosModificablesCuentasOb.includes(input)) {
            arregloPromesasOb.push(this.hitearCambiarAtributoOnboarding('cuentas', s.idCuenta, input, valor, 'legacyId'));
            arregloValoresEnviados.push({ mac: s.idMac, input, valor })
          }
        } else {
          console.log("Key Invalida", s);
          throw "Key Invalida...idCuenta: " + s.idCuenta;
        }
      }
      try {
        await Promise.all(arregloPromesasOb);
      } catch (e) {
        console.error('error al hitear onboarding-ms - ', e);
        this.enviarMensajeSlackError('alertas-onboarding', 1, 'Error al actualizar atributo manager' + `arregloPromesas: ${JSON.stringify(arregloPromesasOb)}, arregloValoresEnviados: ${JSON.stringify(arregloValoresEnviados)}`, { err: e.message, stack: e.stack })
          .subscribe(
            data => {
              console.log('hiteo a mandar slack', data);
            },
            err => console.error('error al mandar slack', err)
          );
        throw e; // no deja continuar si no se escribió en OB
      }
      return this.db.object("cuentas").update(mapUpdates)
        .then(_ => { return "Exito" })
        .catch(err => {
          console.log(err);
          throw "No pudo grabar " + input;
        });
    } else {
      return of("No es número").toPromise();
    }
  }

  /**
   * valida que la key sea != null y con length mayor a 3
   */
  keyValida(key: string): boolean {
    if (key != null && key.length > 3) {
      return true;
    } else {
      return false;
    }
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }



  /**
   * Revisa los servidores seleccionados mas el ultimo clickeado y revisa de acurdo al boton tocado que valor es el que hay que cambiar, lo valida y lo modifica en FireBase
   *
   * @param input el tipo de atributo que estamos por modificar
   * @param element el servidor que hemos tocado al ultimo
   */
  async inputABM(input: string, element: ServidorAdmin, selection: SelectionModel<ServidorAdmin>, cuentasMap: Map<String, CuentaAdmin>, locales: Map<String, LocalAdmin>, cuentas: CuentaAdmin[]): Promise<string> {
    let mensaje: string = "";
    let servAModificar: ServidorAdmin[] = [];
    let mensajeLocales: string = "";
    if (selection.selected && selection.selected.length > 0) {
      for (let i = 0; i < selection.selected.length; i++) {
        mensaje += "\n " + selection.selected[i].nombreServidor;
        mensajeLocales += "\n " + selection.selected[i].nombreLocal;
        servAModificar.push(selection.selected[i]);
      }
      if (!selection.selected.includes(element)) {
        mensaje += "\n " + element.nombreServidor;
        mensajeLocales += "\n " + element.nombreLocal;
        servAModificar.push(element);
      } //else ya se proceso en el for
    } else {
      //proceso individual
      servAModificar.push(element);
      mensaje += "\n " + element.nombreServidor;
      mensajeLocales += "\n " + element.nombreLocal;
    }


    if (input == 'nombreServidor') {
      let valor = prompt("Cambiar el nombreServidor de " + servAModificar.length + ":" + mensaje, servAModificar[0].nombreServidor);
      if (valor != null && valor != "") {

        if (servAModificar[0] && servAModificar[0].keyPedidosOnline && servAModificar[0].keyPedidosOnline.key) {
          if (+servAModificar[0].numeroLocal > 0 && +servAModificar[0].numeroLocal !== 900 && +servAModificar[0].numeroLocal < 9000) {
            await this.hitearLocalSQLBack(servAModificar[0], true);
          } else {
            await this.hitearLocalSQLBack(servAModificar[0], false);
          }

          await this.dbFS.updateAt('confLocalPO/' + servAModificar[0].keyPedidosOnline.key, { nombre: valor });
        }


        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)

      }
    } else if (input == 'versionDisponible') {
      let valor = prompt("Cambiar versionDisponible de " + servAModificar.length + ":" + mensaje, servAModificar[0].versionDisponible);
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'network_mode') {
      let availableNetworksModes = '';
      const keys = Object.keys(NetworkMode);
      for (let i = 0; i < keys.length; i++) {
        availableNetworksModes += NetworkMode[keys[i]];
        if (i < keys.length - 1) {
          availableNetworksModes += ', ';
        }
      }
      let valor = prompt(`Elige un Network Mode (${availableNetworksModes}): ${mensaje}`, servAModificar[0].network_mode)?.trim();
      if (valor !== null && valor !== "") {
        for (const key of Object.keys(NetworkMode)) {
          if (valor === NetworkMode[key]) {
            return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
          }
        }
        return of(`No es un valor de los esperados: ${availableNetworksModes}`).toPromise();
      }
    } else if (input == 'tag' ||
      input == 'hub' ||
      input == 'fullweb' ||
      input == 'fechaingreso' ||
      input == 'fechaclientelisto' ||
      input == 'duenix' ||
      input == 'tag2'
    ) {
      let valor = prompt("Cambiar " + input + " de " + servAModificar.length + ":" + mensaje, servAModificar[0][input] ? servAModificar[0][input] : "");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'pagaIva') {
      let alerta = prompt("Al cambiar IVA tengo que actualizar los conceptos, escribre ENTIENDO para continuar, sino consulta a GERMAN", "NO ENTIENDO");
      if (alerta && alerta === "ENTIENDO") {
        let valor = prompt("Cambiar % IVA que paga de " + servAModificar.length + ":" + mensaje, "21");
        if (valor != null && valor != "") {
          let porcIva: number = +valor;
          return this.validarYEscribirEnFBServidor(porcIva, true, input, servAModificar)
        }
      } else {
        return of("No entendiste, consultá con German").toPromise();
      }

    } else if (input == 'txMypopapp') {
      let valor = prompt("Cambiar tiempo de Subida de MyPopApp en Segundos " + servAModificar.length + ":" + mensaje, element.txMypopapp ? element.txMypopapp + "" : "450");
      if (valor != null && valor != "") {
        let txMypopapp: number = +valor;
        return this.validarYEscribirEnFBServidor(txMypopapp, true, input, servAModificar)
      }
    } else if (input == 'fechaEstadisticasWeb') {
      let valor = prompt("Cambiar fechaEstadisticasWeb " + servAModificar.length + ":" + mensaje, element.fechaEstadisticasWeb ? element.fechaEstadisticasWeb : "dd/mm/aaaa");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'tester') {
      let valor = prompt("Cambiar Tester de " + servAModificar.length + ": 0 es rama ppal" + mensaje, "0");
      if (valor != null && valor != "") {
        let testerV: number = +valor;
        return this.validarYEscribirEnFBServidor(testerV, true, input, servAModificar)
      }
    } else if (input == 'fiscal') {

      let valor = prompt("Cambiar Fiscal de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (valor != null && valor != "") {
        if (valor == 's' || valor == 'n' || valor == 'S' || valor == 'N') {
          //modificar fiscal
          let fiscalActivado: boolean;
          if (valor == 's' || valor == 'S') {
            fiscalActivado = true;
          } else {
            fiscalActivado = false;
          }
          return this.validarYEscribirEnFBServidor(fiscalActivado, false, input, servAModificar)
        } else {
          return of("Escribir s ó n").toPromise();
        }
      }
    } else if (input == 'pagoAlta') {
      let valor = prompt("Cambiar Pagó Alta? de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (valor != null && valor != "") {
        if (valor == 's' || valor == 'n' || valor == 'S' || valor == 'N') {
          //modificar fiscal
          let fiscalActivado: boolean;
          if (valor == 's' || valor == 'S') {
            fiscalActivado = true;
          } else {
            fiscalActivado = false;
          }
          return this.validarYEscribirEnFBServidor(fiscalActivado, false, input, servAModificar)
        } else {
          return of("Escribir s ó n").toPromise();
        }
      }
    } else if (input == 'primerPago') {
      let valor = prompt("Cambiar primerPago? de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (valor != null && valor != "") {
        if (valor == 's' || valor == 'n' || valor == 'S' || valor == 'N') {
          //modificar fiscal
          let fiscalActivado: boolean;
          if (valor == 's' || valor == 'S') {
            fiscalActivado = true;
          } else {
            fiscalActivado = false;
          }
          return this.validarYEscribirEnFBServidor(fiscalActivado, false, input, servAModificar)
        } else {
          return of("Escribir s ó n").toPromise();
        }
      }
    } else if (input == 'importe') {
      let alerta = prompt("Al cambiar Importe tengo que actualizar los conceptos, escribre ENTIENDO para continuar, sino consulta a GERMAN", "NO ENTIENDO");
      if (alerta && alerta === "ENTIENDO") {
        let valor = prompt("Cambiar Importe e ImporteQDeberia de " + servAModificar.length + ":" + mensaje, "0");
        if (valor != null && valor != "") {
          let numberNuevo: number = +valor;
          this.validarYEscribirEnFBServidor(numberNuevo, true, input, servAModificar)
          return this.validarYEscribirEnFBServidor(numberNuevo, true, "importeQDeberia", servAModificar)
        }
      } else {
        return of("No entendiste, consultá con German").toPromise();
      }
    } else if (input == 'estado') {
      let valor = prompt("Cambiar Estado de " + servAModificar.length + "\nValores válidos: Desactualizado, DatosSinVerificar, DatosVerificados, ProbandoPremium, PruebaPremiumCaducada, LicenciaActivada, ClienteActivo, Churn" + mensaje, "LicenciaActivada");
      if (valor != null && valor != "") {
        if (valor == 'Desactualizado' || valor == 'DatosSinVerificar' || valor == 'DatosVerificados' || valor == 'ProbandoPremium' || valor == 'PruebaPremiumCaducada' || valor == 'LicenciaActivada' || valor == 'ClienteActivo' || valor == 'Churn') {
          return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
        } else {
          return of("Ningún valor adecuado, revisar en el cartel antes de escribir").toPromise();
        }
        //Desactualizado, DatosSinVerificar, DatosVerificados, ProbandoPremium, PruebaPremiumCaducada, LicenciaActivada, ClienteActivo, Churn
      }
    } else if (input == 'marca') {
      let valor = prompt("Cambiar Marca de " + servAModificar.length + "\n-\nDeli Gurú" + mensaje, element.marca ? element.marca : "Deli Gurú");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar, "marca", valor);
      }
    } else if (input == 'marcaFotoURL') {
      let valor = prompt("Cambiar marcaFotoURL de " + servAModificar.length + "\n-\nhttps://popapp.io/zzz/assets/deliguru-logo.png" + mensaje, element.marcaFotoURL ? element.marcaFotoURL : "https://popapp.io/zzz/assets/deliguru-logo.png");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar, "marcaFotoURL", valor);
      }
    } else if (input == 'marcaFotoChicaURL') {
      let valor = prompt("Cambiar marcaFotoChicaURL de " + servAModificar.length + "\n-\nhttps://popapp.io/zzz/assets/deliguru-logo-sm.png" + mensaje, element.marcaFotoChicaURL ? element.marcaFotoChicaURL : "https://popapp.io/zzz/assets/deliguru-logo-sm.png");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar, "marcaFotoChicaURL", valor);
      }
    } else if (input == 'tipoUsuario') {
      let valor = prompt("Cambiar tipoUsuario de " + servAModificar.length + "\n-\nComercial" + mensaje, element.tipoUsuario ? element.tipoUsuario : "Comercial");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'fix') {

      const dialogRef = this.dialog.open(ModalFixComponent, {
        width: '70%',
        data: {
          sentencia: element.fix,
          servidores: servAModificar
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result && result.sentencia) {
          if (result.sentencia === "null") {
            return this.validarYEscribirEnFBServidor(null, false, input, servAModificar)
          } else {
            return this.validarYEscribirEnFBServidor(result.sentencia, false, input, servAModificar)
          }
        }
      });

    } else if (input == 'msjGenerico') {
      this.abreWhatsappConMsjAumentoPrecioGenerico(servAModificar, cuentasMap, false, false);
    } else if (input == 'msjEstadisticas') {
      this.abreWhatsappConMsjAumentoPrecioGenerico(servAModificar, cuentasMap, true, false);

    } else if (input == 'msjNoIva') {
      this.abreWhatsappConMsjAumentoPrecioGenerico(servAModificar, cuentasMap, false, true);

    } else if (input == 'msjEstadYNoIva') {
      this.abreWhatsappConMsjAumentoPrecioGenerico(servAModificar, cuentasMap, true, true);
    } else if (input == 'listaMsjsWhatsapp') {
      const dialogRef = this.dialog.open(ModalListamsjsComponent, {
        width: '70%',
        data: {
          servidores: servAModificar
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return this.enviaMsjDesdeLista(result.msj, servAModificar, cuentasMap);
        }
      });
    } else if (input == 'planN') {
      let valor = prompt("Cambiar Plan de " + servAModificar.length + ":" + mensaje, "Básico");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'pagoHasta') {
      let valor = prompt("Cambiar Fecha de Ultimo Pago de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, element.pagoHasta ? element.pagoHasta : "01/04/2020");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'vencimiento') {
      let valor = prompt("Cambiar Fecha de Vencimiento de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, element.vencimiento ? element.vencimiento : "01/04/2020");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        console.log("Modificando " + input + "... a " + valor);



        for (let i = 0; i < servAModificar.length; i++) {
          let s: ServidorAdmin = servAModificar[i];
          if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
            this.escribePagos(s, valor)
          } else {
            console.log("Key Invalida", s);
            throw "Key Invalida...idCuenta, idLocal, idMac: " + s.idCuenta + ", " + s.idLocal + ", " + s.idMac;
          }
        }
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'fechaCambioPlanMap') {
      let valor = prompt("Cambiar Fecha de CAMBIO de Plan de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, "01/04/2020");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'fechaInstalacion') {
      let valor = prompt("Cambiar Fecha de CAMBIO de Plan de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, element.fechaInstalacion ? element.fechaInstalacion : "01/04/2020");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'idCuenta') {
      if (servAModificar.length > 1) {
        let valor: boolean = confirm("UNIR los Locales seleccionados en UNA SOLA CUENTA (" + servAModificar.length + ")??\nSOLO SE MANTENDRA LA INFO DE LA PRIMER CUENTA:" + mensajeLocales);
        if (valor) {
          //unir los locales
          return this.unirCuentas(servAModificar, locales);
        }
      } else {
        let valor: boolean = confirm("DIVIDIR los Locales seleccionados en Cuentas separadas (" + element.totLocales + ")??" + mensajeLocales);
        if (valor) {
          //unir los locales
          return this.separarCuentas(element, cuentasMap, locales);
        }
        throw "Seleccionar más de 1 servidor para unir Cuentas";
      }
    } else if (input == 'idLocal') {

      if (servAModificar.length > 1) {
        let valor: boolean = confirm("UNIR los servidores  seleccionados en UN SOLO LOCAL (" + servAModificar.length + ")??\nSOLO SE MANTENDRA LA INFO DEL PRIMERO:" + mensaje);

        if (valor) {
          //unir los locales
          return this.unirLocales(servAModificar);
        }
      } else {
        throw "Seleccionar más de 1 servidor para unir locales";
      }

    } else if (input == 'lat') {
      let valor = prompt("Cambiar Lat de " + servAModificar.length + ": 0 es rama ppal" + mensaje, "" + element.lat);
      if (valor != null && valor != "") {
        let testerV: number = +valor;
        return this.validarYEscribirEnFBServidor(testerV, true, input, servAModificar)
      }
    } else if (input == 'lng') {
      let valor = prompt("Cambiar Lng de " + servAModificar.length + ": 0 es rama ppal" + mensaje, "" + element.lng);
      if (valor != null && valor != "") {
        let testerV: number = +valor;
        return this.validarYEscribirEnFBServidor(testerV, true, input, servAModificar)
      }
    } else if (input == 'nombreCuenta') {
      let valor = prompt("Cambiar Nombre Cuenta de " + servAModificar.length + ":" + mensaje, element.nombreCuenta);
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBCuenta(valor, false, input, servAModificar)
      }
    } else if (input == 'nombreLocal') {
      let valor = prompt("Cambiar Nombre Local de " + servAModificar.length + ":" + mensaje, element.nombreLocal);
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBLocal(valor, false, input, servAModificar)
      }
    } else if (input == 'fechaAlta') {
      let valor = prompt("Cambiar fechaAlta " + servAModificar.length + ":" + mensaje, element.fechaAlta ? element.fechaAlta : new Date().toISOString());
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'fechaBaja') {
      let valor = prompt("Cambiar fechaBaja " + servAModificar.length + ":" + mensaje, element.fechaBaja ? element.fechaBaja : new Date().toISOString());
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'numeroLocal') {

      let valor = prompt("Cambiar Número de Local de " + servAModificar.length + ":\n-1 si fué cliente\n0 si no es ni fué\n>0 si es cliente actual\n-2 reseller\n-3 developers\n-4 cambio de PC" + mensajeLocales, "" + element[input]);
      if (valor != null && valor != "") {
        if (+valor > 0 && +valor !== 900 && +valor < 9000) {
          const fechaAlta = new Date().toISOString();
          let fecha = prompt("Configurar fecha de alta?", fechaAlta)?.trim();
          if (fecha != null && fecha != "") {
            if (await this.hitearLocalSQLBack(element, true)) {
              await this.validarYEscribirEnFBServidor('' + valor, false, input, servAModificar);
              return this.validarYEscribirEnFBServidor(fecha, false, 'fechaAlta', servAModificar);
            } else {
              throw 'Error al escribir en base SQL';
            }
          }
        } else {
          const fechaBaja = new Date().toISOString();
          let fecha = prompt("Configurar fecha de baja?", fechaBaja)?.trim();
          if (fecha != null && fecha != "") {
            if (element && element.keyPedidosOnline && element.keyPedidosOnline.key) {
              if (await this.hitearLocalSQLBack(element, false)) {
                await this.validarYEscribirEnFBServidor('' + valor, false, input, servAModificar);
                await this.validarYEscribirEnFBServidor(fecha, false, 'fechaBaja', [element]);
              } else {
                throw 'Error al escribir en base SQL';
              }
            } else {
              await this.validarYEscribirEnFBServidor(fecha, false, 'fechaBaja', servAModificar);
            }
          }
        }
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }

    } else if (input == 'codigo') {
      let codigo: string = this.cuentaService.generaCódigoConNuevaFechayPlan(element);
      console.log(codigo);
      let res = this.cuentaService.descifraCódigoConNuevaFechayPlan(codigo, element);
      console.log(res);
      this.copyToClipboard(codigo);
      return of("Código copiado al portapeles para " + res.fechaddMMyyyy + " , plan: " + this.cuentaService.planNumberToString(res.plan)).toPromise()
    } else if (input == 'valorDolar') {
      let valor = prompt("Cambiar ValorDolar de " + servAModificar.length + ":" + mensaje, "0");
      if (valor != null && valor != "") {
        let numberNuevo: number = +valor;
        return this.validarYEscribirEnFBServidor(numberNuevo, true, input, servAModificar)
      }
    } else if (input == 'pais') {
      let valor = prompt("Cambiar País de " + servAModificar.length + ":" + mensaje, "ARG");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'fechaConversion') {
      let valor = prompt("Cambiar fechaConversion de (yyyy/MM/dd)" + servAModificar.length + ":" + mensaje, element.fechaConversion ? element.fechaConversion : "01/04/2020");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'ciudad') {
      let valor = prompt("Cambiar País de " + servAModificar.length + ":" + mensaje, "ARG");
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'addImporte') {
      let valor = confirm("Agregar un Importe mensual, Cuota o Mac para: " + servAModificar.length + ":" + mensaje);
      if (valor) {
        let keysQNoPuedenSer = "";
        const cuentaPadre = cuentas.find(i => element.idCuenta == i.id);
        for (let keyLocal in cuentaPadre.locales) {
          if (keyLocal == element.idLocal) {
            for (let mac in cuentaPadre.locales[keyLocal].servidores) {
              keysQNoPuedenSer += mac + "  -  "
            }
          }
        }
        const key = prompt("key o mac (Tiene q se unica, sin caracteres especiales) no puede ser: " + keysQNoPuedenSer, "moduloFEArg");
        if (key != null && key != "" && !keysQNoPuedenSer.includes(key)) {
          let newServidorOImporte: any = {};
          newServidorOImporte.nombreServidor = prompt("Nombre del concepto a cobrar o del Servidor a cobrar", "Módulo Factura Electrónica");
          let cuotas = +prompt("Cuotas a cobrar (number) (0 es infinito, como un servidor o un modulo que se cobra indefinidamente)", "0");
          if (cuotas != 0) {
            newServidorOImporte.cuotasPendientes = cuotas;
          }
          newServidorOImporte.importe = +prompt("Importe a cobrar por mes(number) en " + element.moneda, "0");
          newServidorOImporte.importeQDeberia = +prompt("Importe Q deberia (si hay un desc y este importe es mayor q " + newServidorOImporte.importe + " entonces se muestra un descuento", "" + newServidorOImporte.importe);
          newServidorOImporte.moneda = element.moneda;
          newServidorOImporte.numeroLocal = "9050";//pues no deberia contar en estadisticas
          newServidorOImporte.pagaIva = +prompt("Iva (21 si se quiere cobrar iva en arg)", "0");
          newServidorOImporte.pagoHasta = element.pagoHasta;
          newServidorOImporte.pais = element.pais;
          newServidorOImporte.showPagar = true;
          return this.validarYEscribirEnFBLocal(newServidorOImporte, false, "servidores/" + key, servAModificar)
        } else {
          if (key != null && key != "") {
          } else {
            throw "Escribir Una Key Q No Este Repetida";
          }
        }
      }
    } else if (input == 'fearg') {
      let activar = prompt("Cambiar Activado FEArg de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (activar != null && activar != "") {
        if (activar == 's' || activar == 'n' || activar == 'S' || activar == 'N') {
          //modificar fiscal
          let bool: boolean;
          if (activar == 's' || activar == 'S') {
            bool = true;
          } else {
            bool = false;
          }
          if (bool) {
            //se viene la ola de alerts para cada atributo
            //fearg: { activado: boolean, cuit: string, email: string, password: string, razonSocial: string, domicilio: string, responsabilidad: string, ptoVta: number };
            let feargAux: any = {};
            feargAux.activado = true;
            feargAux.cuit = prompt("cuit", element.fearg ? element.fearg.cuit : "");
            feargAux.email = prompt("email", element.fearg ? element.fearg.email : "");
            feargAux.password = prompt("password", element.fearg ? element.fearg.password : "");
            feargAux.razonSocial = prompt("razonSocial", element.fearg ? element.fearg.razonSocial : "");
            feargAux.domicilio = prompt("domicilio", element.fearg ? element.fearg.domicilio : "");
            feargAux.responsabilidad = prompt("responsabilidad", element.fearg ? element.fearg.responsabilidad : "");
            feargAux.ptoVta = +prompt("ptoVta (number)", element.fearg ? element.fearg.ptoVta + "" : "0");
            feargAux.idPtoVta = +prompt("idPtoVta (number)", element.fearg ? element.fearg.idPtoVta + "" : "0");
            feargAux.idPais = 10; //Arg
            feargAux.idProvincia = +prompt("provincia:  1 BsAs - 2 Ciudad de BsAs - 3 Catamarca - 4 Chaco - 5 Chubut - 6 Córdoba - 7 Corrientes - 8 E.Rios - 9 Formosa - 10 Jujuy - 11 La Pampa - 12 La Rioja - 13 Mendoza - 14 Misiones - 15 Neuquen - 16 R.Negro - 17 Salta - 18 S.Juan - 19 S.Luis - 20 S.Cruz - 21 S.Fe - 22 S.del Estero - 23 T.del Fuego - 24 Tucuman"
              , element.fearg ? element.fearg.idProvincia + "" : "1");
            feargAux.nroLocalFea = +prompt("nroLocalFea: (Numero de local para distinguir entre varios servidores de una misma cuenta de contabilium) ", element.fearg ? element.fearg.nroLocalFea + "" : "1");
            feargAux.ingresosBrutos = prompt("ingresosBrutos", element.fearg ? element.fearg.ingresosBrutos + "" : "");
            feargAux.fechaInicioActividades = prompt("fechaInicioActividades", element.fearg ? element.fearg.fechaInicioActividades + "" : "");
            feargAux.actualizado = true;
            return this.validarYEscribirEnFBServidor(feargAux, false, input, servAModificar)
              .then(res => { return this.inputABM("addImporte", element, selection, cuentasMap, locales, cuentas); })
          } else {
            return this.validarYEscribirEnFBServidor(bool, false, input + "/activado", servAModificar)
              .then(res => { return this.validarYEscribirEnFBServidor(true, false, input + "/actualizado", servAModificar) })
          }
        } else {
          throw "Escribir s ó n";
        }
      }

    } else if (input == 'fechaUltimoPago') {
      let valor = prompt("Cambiar Fecha de Ultimo Pago de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, element.fechaUltimoPago ? element.fechaUltimoPago : "01/04/2020");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'fechaFinPruebaFull') {
      let valor = prompt("Cambiar Fecha de Fin de Prueba de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, element.fechaFinPruebaFull ? element.fechaFinPruebaFull : "01/04/2020");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'moneda') {
      let valor = prompt("Cambiar Moneda de " + servAModificar.length + ":" + mensaje, "ARS $");
      if (valor != null && valor != "") {

        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'showPagar') {

      let valor = prompt("Cambiar ShowPagar de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (valor != null && valor != "") {
        if (valor == 's' || valor == 'n' || valor == 'S' || valor == 'N') {
          //modificar fiscal
          let bool: boolean;
          if (valor == 's' || valor == 'S') {
            bool = true;
          } else {
            bool = false;
          }
          return this.validarYEscribirEnFBServidor(bool, false, input, servAModificar)
        } else {
          throw "Escribir s ó n";
        }
      }
    } else if (input == 'importeQDeberia') {
      let valor = prompt("Cambiar Importe Que Deberia Pagar de " + servAModificar.length + ":" + mensaje, "0");
      if (valor != null && valor != "") {
        let numberNuevo: number = +valor;
        return this.validarYEscribirEnFBServidor(numberNuevo, true, input, servAModificar)
      }
    } else if (input === "pedidosOnline") {
      let defaultKeys: string = "";
      let keyAnterior = "";
      if (element.keyPedidosOnline && element.keyPedidosOnline.key) {
        keyAnterior = element.keyPedidosOnline.key;
      } else {
        let v = await this.db.object("keysLocalesPedidosOnline").query.once("value");
        let mapa = v.val();
        if (!mapa) {
          console.log("No se pudo leer keysLocalesPedidosOnline")
        } else {
          console.log("Leido mapa keysLocalesPedidosOnline", mapa);
          defaultKeys = "Keys que ya existen: "
          for (let pya123 in mapa) {
            if (!defaultKeys.includes(mapa[pya123])) {
              defaultKeys += mapa[pya123] + ", "
            }
          }
        }
      }
      let mapUpdates = {};
      let keyLocal = prompt("Key para vincular los servicios de " + element.nombreCuenta + "\n" + defaultKeys, keyAnterior);
      if (keyLocal != null && keyLocal != "" && keyLocal.trim().length > 0) {
        let defaultCantidad = "1";
        let msj = ""
        let localesAnteriores: { key: string, activado: boolean, nombre: string, autoaccept: boolean }[] = []
        if (element.keyPedidosOnline && element.keyPedidosOnline.locales) {
          let cantAux = 0;
          for (let k in element.keyPedidosOnline.locales) {
            let v = element.keyPedidosOnline.locales[k]
            msj += "\n" + k + " " + v.nombre
            let localAux = { key: k, activado: v.activado, nombre: v.nombre, autoaccept: v.autoaccept ? true : false }
            localesAnteriores.push(localAux)
            cantAux++;
          }
          defaultCantidad = "" + cantAux;
        }
        let cantidadIntegraciones = +prompt("Cantidad de locales online integrados " + msj, defaultCantidad);
        if (cantidadIntegraciones > 0) {
          for (let i = 0; i < cantidadIntegraciones; i++) {
            //cada local
            let defaultKeypya123 = ""
            if (localesAnteriores && localesAnteriores[i] && localesAnteriores[i].key) {
              defaultKeypya123 = localesAnteriores[i].key
            }
            let keypya123 = prompt("Key pya, rap, ... + el id del resto en esa plataforma\nx ej: pya58031", defaultKeypya123);
            if (keypya123 && keypya123.trim().length > 0) {
              let defaultnombre = ""
              if (localesAnteriores && localesAnteriores[i] && localesAnteriores[i].nombre) {
                defaultnombre = localesAnteriores[i].nombre
              }
              let nombre = prompt("Nombre x ej Bourbon para " + keypya123, defaultnombre);
              if (nombre && nombre.trim().length > 0) {
                let defaultActivado = "s"
                if (localesAnteriores && localesAnteriores[i] && localesAnteriores[i].activado) {
                  defaultActivado = localesAnteriores[i].activado ? "s" : "n"
                }
                let activado = prompt("Activado (s/n) \npara " + keypya123 + " " + nombre, defaultActivado);
                if (activado && activado.trim().length > 0 && (activado === "s" || activado === "n")) {
                  let actboolean = activado === "s"
                  if (actboolean)
                    mapUpdates["keysLocalesPedidosOnline/" + keypya123] = keyLocal;
                  else
                    mapUpdates["keysLocalesPedidosOnline/" + keypya123] = null;
                  mapUpdates["cuentas/" + element.idCuenta + "/locales/" + element.idLocal + "/servidores/" + element.idMac + "/keyPedidosOnline/locales/" + keypya123 + "/activado"] = actboolean
                  mapUpdates["cuentas/" + element.idCuenta + "/locales/" + element.idLocal + "/servidores/" + element.idMac + "/keyPedidosOnline/locales/" + keypya123 + "/nombre"] = nombre

                  let defaultAutoAcept = "n"
                  if (localesAnteriores && localesAnteriores[i] && localesAnteriores[i].autoaccept) {
                    defaultActivado = localesAnteriores[i].autoaccept ? "s" : "n"
                  }
                  let autoaccept = prompt("Autoacept (s/n) \npara " + keypya123 + " " + nombre, defaultAutoAcept);
                  if (autoaccept && autoaccept.trim().length > 0 && (autoaccept === "s" || autoaccept === "n")) {
                    let autoacceptboolean = autoaccept === "s"
                    if (autoacceptboolean)
                      mapUpdates["keysAutoAceptar/" + keypya123] = keyLocal;
                    else
                      mapUpdates["keysAutoAceptar/" + keypya123] = null;
                    mapUpdates["cuentas/" + element.idCuenta + "/locales/" + element.idLocal + "/servidores/" + element.idMac + "/keyPedidosOnline/locales/" + keypya123 + "/autoaccept"] = autoacceptboolean
                  } else {
                    throw "Ingresa s o n"
                  }
                } else {
                  throw "Ingresa s o n"
                }
              } else {
                throw "Ingresa un nombre"
              }
            } else {
              throw "Ingresa una key pya o rap + el id del local en esa plataforma"
            }
          }
          mapUpdates["cuentas/" + element.idCuenta + "/locales/" + element.idLocal + "/servidores/" + element.idMac + "/keyPedidosOnline/key"] = keyLocal;

        } else {
          throw "Ingresa una cantidad"
        }
      } else {
        throw "Ingresa una key"
      }
      return this.db.object("/").update(mapUpdates).then(_ => { return "Exito" });
    } else if (input == 'detalleImporte') {
      let valor = prompt("Cambiar Detalle que acompaña el Importe de " + servAModificar.length + ":" + mensaje, element.detalleImporte);
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'whatsapp') {
      let valor = prompt("Cambiar Whatsapp de " + servAModificar.length + ":" + mensaje, servAModificar[0].whatsapp);
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBCuenta(valor, false, input, servAModificar)
      }
    } else if (input == 'rut') {
      let valor = prompt("Cambiar RUT de " + servAModificar.length + ":" + mensaje, servAModificar[0].rut);
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBServidor(valor, false, input, servAModificar)
      }
    } else if (input == 'contacto') {
      let valor = prompt("Cambiar Contacto de " + servAModificar.length + ":" + mensaje, servAModificar[0].contacto);
      if (valor != null && valor != "") {
        return this.validarYEscribirEnFBCuenta(valor, false, input, servAModificar)
      }
    } else if (input == 'msjWhatsapp') {
      this.abreWhatsappConMsjPago(servAModificar, cuentasMap);
    } else if (input == "msjWhatsappAumento") {
      this.abreWhatsappConMsjAumentoPrecio(servAModificar, cuentasMap);
    } else if (input == 'cobrar') {
      let cuenta: CuentaAdmin = cuentasMap.get(servAModificar[0].idCuenta);

      if (servAModificar.length == 1 && cuenta) {
        //cantidad de meses
        let mesesString = prompt("Cuantos meses quieres cobrar de la cuenta " + servAModificar[0].nombreCuenta + "?", "1");
        if (mesesString != null && mesesString != "") {
          let meses = +mesesString;
          if (!isNaN(meses) && meses > 0 && meses < 11) {
            let mensajeAVerificar = this.cuentaService.mensajeAVerificarAntesDeCobrar(cuenta, meses);
            let tipoPago = prompt(mensajeAVerificar + "\n\nTipo de Cobro: Efectivo, Tarjeta, Mercado Pago, Mercado Pago Manual, Paypal, Deposito...?", "Efectivo");
            if (tipoPago != null && tipoPago != "") {
              this.cuentaService.cobrar(cuenta, meses, tipoPago)
                .then(_ => {
                  return of("Exito al grabar").toPromise();
                })
            } else {
              throw "Valor nulo";
            }
          } else {
            throw "Meses no es un numero válido entre 1 y 10";
          }
        } else {
          throw "Valor nulo";
        }
      } else {
        throw "Cuenta null o Solo elegir 1 servidor para cobrar la cuenta entera";
      }
    }
  }

  async unirCuentas(servAModificar: ServidorAdmin[], locales: Map<String, LocalAdmin>): Promise<string> {
    if (this.keyValida(servAModificar[0].idCuenta) && this.keyValida(servAModificar[0].idLocal)) {
      console.log("Empezando a unir cuentas ... ");

      let localesIds: Map<string, boolean> = new Map<string, boolean>();
      for (let i = 1; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
          if (s.idCuenta != servAModificar[0].idCuenta) {
            //si fueran iguales no hay que hacer union
            localesIds.set(s.idLocal, true);
          }
        } else {
          console.log("Key Invalida", s);
          throw "Key Invalida...idCuenta, idLocal, idMac: " + s.idCuenta + ", " + s.idLocal + ", " + s.idMac;
        }
      }
      const idsLocales = [];
      for (const [key, value] of localesIds) {
        //for (let key in localesIds.keys()) {
        let local: LocalAdmin = locales.get(key);
        idsLocales.push({ idCuenta: local.idCuenta, idLocal: local.id });
      }
      try {
        await this.postUnirCuentasOb(idsLocales, servAModificar[0].idCuenta);
      } catch (e) {
        console.error('ERROR UNIR CUENTAS', e);
        return 'Error unir cuentas';
      }

      return 'Exito al unir cuentas'

    } else {
      console.log("Key Invalida local o cuenta", servAModificar[0]);
      throw "Key Invalida...idLocal Nuevo: " + servAModificar[0].idLocal;
    }
  }

  private async postUnirCuentasOb(idsLocales: { idCuenta: string, idLocal: string }[], idCuenta: string) {
    const url = `${environment.urls.onboarding}/v1/locaciones/migrate-locations`
    console.log('url', url, 'body', { idsLocales, idCuenta });
    return this.http.post(url, { idsLocales, idCuenta }).toPromise();
  }

  deleteCuentaSQLBack(idCuenta: string) {
    // DELETE basesql/cuentas/:id
    return this.http.delete(url + 'cuentas/' + idCuenta).toPromise()
  }
  async unirLocales(servAModificar: ServidorAdmin[]) {
    if (this.keyValida(servAModificar[0].idCuenta) && this.keyValida(servAModificar[0].idLocal)) {
      console.log("Empezando a unir locales ... ");
      let mapUpdates = {};
      for (let i = 1; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
          //1ero escribimos en mac pasadas los nuevos y los valores viejos
          mapUpdates["macsPasadas/" + s.idMac + "/idLocal"] = servAModificar[0].idLocal;
          mapUpdates["macsPasadas/" + s.idMac + "/vAntIdLocal"] = s.idLocal;
          mapUpdates["macsPasadas/" + s.idMac + "/idCuenta"] = servAModificar[0].idCuenta;
          mapUpdates["macsPasadas/" + s.idMac + "/vAntIdCuenta"] = s.idCuenta;

          //3ro borramos el servidor en la rama vieja
          if (s.totLocales > 0 && s.totServidoresOSvs > 0) {
            if (s.totLocales == 1 && s.totServidoresOSvs == 1) {
              //tenemos que borrar la cuenta entera, pues ya no hay más locales ni servidores que dependan
              mapUpdates["cuentas/" + s.idCuenta + "/"] = null;
            } else if (s.totServidoresOSvs == 1) {
              //borrar ese local pues ese local queda sin servidores pero hay otro local en esa cuenta
              mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/"] = null;
            } else {
              //borrar ese servidor pues hay otros servidores en ese mismo local
              mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/"] = null;
              await this.deleteCuentaSQLBack(s.idCuenta);
            }
          } else {
            //Esto no deberia pasar pues el total deberia ser > 0
            console.log("Error en total locales y servidores", s);
            throw "Esto no deberia pasar pues el total de serviodres y locales deberia ser > 0";
          }

          //2do escribimos en el local 1 todos los q vamos a pasar
          //armamos el servidorAdmin
          //otro approach seria poner en null las variables q no estan en el constructor, probar
          let sIdMac = s.idMac;
          s.dias = null;
          s.nombreCuenta = null;
          s.nombreLocal = null;
          s.indServidor = null;
          s.indLocal = null;
          s.totLocales = null;
          s.totServidoresOSvs = null;

          if (s && s.keyPedidosOnline && s.keyPedidosOnline.key) {
            s.idCuenta = servAModificar[0].idCuenta;
            s.idLocal = servAModificar[0].idLocal;

            try {
              await this.dbFS.updateAt('confLocalPO/' + s.keyPedidosOnline.key, {
                idCuenta: servAModificar[0].idCuenta,
                idLocal: servAModificar[0].idLocal,
              });
            } catch (e) {
              console.error('Error al escribir en FS' + s.keyPedidosOnline.key, s, e);
              throw 'No se pudo escribir en FS, preguntar a German con captura de la consola F12'
            }
            if (+s.numeroLocal > 0 && +s.numeroLocal !== 900 && +s.numeroLocal < 9000) {
              await this.hitearLocalSQLBack(s, true);
            } else {
              await this.hitearLocalSQLBack(s, false);
            }
          }
          s.idMac = null;
          s.idLocal = null;
          s.idCuenta = null;

          //let aux: ServidorAdmin = new ServidorAdmin(s.estado, s.fechaCambioPlan, s.fechaInstalacion, s.fiscal, s.fix, s.importe, s.nombreServidor, s.pagaIva, s.pagoHasta, s.plan, s.tester, s.vencimiento);
          mapUpdates["cuentas/" + servAModificar[0].idCuenta + "/locales/" + servAModificar[0].idLocal + "/servidores/" + sIdMac + "/"] = s;

        } else {
          console.log("Key Invalida", s);
          throw "Key Invalida...idCuenta, idLocal, idMac: " + s.idCuenta + ", " + s.idLocal + ", " + s.idMac;
        }
      }
      return this.db.list('/').update("/", mapUpdates).then(r => {
        return "Exito"
      })
    } else {
      console.log("Key Invalida local o cuenta", servAModificar[0]);
      throw "Key Invalida...idLocal Nuevo: " + servAModificar[0].idLocal;
    }
  }

  diasss(fecha: string, hoyAnio, hoyMes, hoyDia): number {
    let fv: string = fecha;
    if (!fv) {
      fv = "1/1/1999";
    }
    let indexBarra: number = fv.indexOf("/");
    let iFor = 0;
    let stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let dia: number = Number(stringAuxFecha);

    fv = fv.replace(stringAuxFecha + "/", "");
    indexBarra = fv.indexOf("/");
    iFor = 0;
    stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let mes: number = Number(stringAuxFecha);
    fv = fv.replace(stringAuxFecha + "/", "");
    let anio: number = Number(fv);

    //let mes: number = Number(fv.charAt(3) + fv.charAt(4));
    //let anio: number = Number(fv.charAt(6) + fv.charAt(7) + fv.charAt(8) + fv.charAt(9));
    let dias = (anio - hoyAnio) * 365;
    dias += (mes - 1 - hoyMes) * 30;
    dias += (dia - hoyDia);
    return dias;
  }

  abreWhatsappConMsjPago(servidores: ServidorAdmin[], cuentasMap: Map<String, CuentaAdmin>) {
    //sacamos las cuentas a partir de los servidores y no las repetimos
    let cuentas = new Map<string, CuentaAdmin>();
    servidores.forEach((s) => {
      let cuenta: CuentaAdmin = cuentasMap.get(s.idCuenta);
      cuenta.pais = s.pais;
      cuentas.set(s.idCuenta, cuenta);
    });

    //para cada cuenta calculamos el importe y la moneda y q no tenga errores
    cuentas.forEach(async (c) => {
      this.cuentaService.mensajeAVerificarAntesDeCobrar(c, 1);
      if (!c.error || c.error.length == 0) {
        //verificamos que tenga los atributos necesarios antes de mandar el msj
        if (c.contacto && c.whatsapp && c.moneda && c.pagoHasta) {

          let primeraParteURL: string = "https://api.whatsapp.com/send?phone=";
          let segundaParteURL: string = "&text=";
          let textoSuscripcionVencida: string = "*venció el pasado%20";
          let textoSuscripcionNoVencida: string = "*vence el próximo%20";
          let textoSuscripcionVenceHoy: string = "*vence hoy%20";



          //HARDCODEO PARA PROBAR EL MENSAJE
          let uRLMensaje: string;
          uRLMensaje = primeraParteURL + c.whatsapp.replace("+", "") + segundaParteURL;

          let cuerpoDelMensaje: string = "";
          let textoDiferenciaDeDiasConVencimiento: string = "";
          let fechaHoy: number = new Date().getTime();
          let fechaPagoHastaMillis: number = this.cuentaService.pagoHastaADate(c.pagoHasta).getTime();

          //SALUDO Y ENCABEZADO
          cuerpoDelMensaje += "Hola%20" + c.contacto + ".%20Cómo estás??%0AQueremos%20informarte%20que%20tu%20suscripción%20a%20popapp%20de%20la%20cuenta%20" + this.sacaEspacios(c.nombreCuenta);
          //////////////////////////////////////////

          // FECHAS DE VENCIMIENTO Y ULTIMO PAGO
          if (fechaPagoHastaMillis < fechaHoy) {
            cuerpoDelMensaje += textoSuscripcionVencida;
            textoDiferenciaDeDiasConVencimiento = "hace%20";
          }
          else if (fechaPagoHastaMillis > fechaHoy) {
            cuerpoDelMensaje += textoSuscripcionNoVencida;
            textoDiferenciaDeDiasConVencimiento = "en%20";
          }
          else
            cuerpoDelMensaje += textoSuscripcionVenceHoy;
          cuerpoDelMensaje += c.pagoHasta;

          if (textoDiferenciaDeDiasConVencimiento != "") {
            let diasDeDiferencia: number = this.cuentaService.diasDesdeMillis(fechaHoy - fechaPagoHastaMillis);
            if (diasDeDiferencia < 0)
              diasDeDiferencia = diasDeDiferencia * -1;

            cuerpoDelMensaje += "%20(" + textoDiferenciaDeDiasConVencimiento + diasDeDiferencia.toFixed() + "%20 días)";
          }
          if (c.ultimoPago) {
            cuerpoDelMensaje += "*.%20Tu%20último%20pago%20fue%20realizado%20el%20día%20" + c.ultimoPago + ".";
          }
          ///////////////////////////////////////////

          //IMPORTES
          cuerpoDelMensaje += "%0APara%20renovar%20la%20misma%20por%2030%20días%20más%20desde%20la%20fecha%20de%20vencimiento,%20recuerda realizar el pago.";
          cuerpoDelMensaje += "%0AEl%20importe%20a%20abonar%20es%20de%20*" + c.totalApagarSinIva.toFixed() + "%20" + c.moneda;
          if (c.totalApagarConIva > c.totalApagarSinIva) {
            cuerpoDelMensaje += "%20+IVA(" + (c.totalApagarConIva - c.totalApagarSinIva).toFixed() + ")%20%3D%20" +
              c.totalApagarConIva.toFixed() + "%20" + c.moneda;
          }
          cuerpoDelMensaje += "*";
          ///////////////////////////////

          // PIE DE MENSAJE
          cuerpoDelMensaje += "%0A%0AMuchas%20gracias%20en%20nombre%20de%20todo%20el%20equipo%20de%20*popapp*!!" +
            "%0A%0APuedes%20consultar%20el%20detalle%20de%20tu%20cuenta%20y%20realizar%20el%20pago,%20iniciando%20" +
            "sesión%20en%20https://popapp.io/mypopapp/micuenta";

          const respuesta2 = await this.cuentaService.traeLinkDePago(c.pais, c.id, 1, c.moneda, "p@p", c.totalApagarConIva);
          let respuesta = respuesta2.json();

          if (respuesta && respuesta.init_point) {
            console.log("init", respuesta.init_point)
            let link: string = respuesta.init_point;
            link = link.replace("=", "%3D");
            cuerpoDelMensaje += "%0A%0AO%20bien,%20puedes%20pagar%201%20mes%20directamente%20en%20este%20link:%20" + link;
          }

          cuerpoDelMensaje += "%0A%0A%5FEste%20mensaje%20fue%20generado%20automaticamente%5F";
          //////////////////////////////////////

          uRLMensaje += cuerpoDelMensaje;
          window.open(uRLMensaje, '_blank');

        } else {
          console.log(c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta): ", c);
          throw c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta)";
        }
      } else {
        console.log("Error cuenta " + c.nombreCuenta + ": " + c.error);
        throw "Error cuenta " + c.nombreCuenta + ": ", c.error;
      }
    });
  }

  enviaMsjDesdeLista(msj: string, servidores: ServidorAdmin[], cuentasMap: Map<String, CuentaAdmin>) {
    //sacamos las cuentas a partir de los servidores y no las repetimos
    let cuentas = new Map<string, CuentaAdmin>();
    servidores.forEach((s) => {
      let cuenta: CuentaAdmin = cuentasMap.get(s.idCuenta);
      cuenta.pais = s.pais;
      cuentas.set(s.idCuenta, cuenta);
    });

    //para cada cuenta calculamos el importe y la moneda y q no tenga errores
    cuentas.forEach(async (c) => {
      let primeraParteURL: string = "https://api.whatsapp.com/send?phone=";
      let segundaParteURL: string = "&text=";

      //HARDCODEO PARA PROBAR EL MENSAJE
      let uRLMensaje: string;
      uRLMensaje = primeraParteURL + c.whatsapp.replace("+", "") + segundaParteURL;

      let cuerpoDelMensaje: string = msj;


      uRLMensaje += cuerpoDelMensaje;
      window.open(uRLMensaje, '_blank');
    });
  }
  abreWhatsappConMsjAumentoPrecio(servidores: ServidorAdmin[], cuentasMap: Map<String, CuentaAdmin>) {
    //sacamos las cuentas a partir de los servidores y no las repetimos
    let cuentas = new Map<string, CuentaAdmin>();
    servidores.forEach((s) => {
      let cuenta: CuentaAdmin = cuentasMap.get(s.idCuenta);
      cuenta.pais = s.pais;
      cuentas.set(s.idCuenta, cuenta);
    });

    //para cada cuenta calculamos el importe y la moneda y q no tenga errores
    cuentas.forEach(async (c) => {
      this.cuentaService.mensajeAVerificarAntesDeCobrar(c, 1, true);
      if (!c.error || c.error.length == 0) {
        //verificamos que tenga los atributos necesarios antes de mandar el msj
        if (c.contacto && c.whatsapp && c.moneda && c.pagoHasta) {

          let primeraParteURL: string = "https://api.whatsapp.com/send?phone=";
          let segundaParteURL: string = "&text=";



          //HARDCODEO PARA PROBAR EL MENSAJE
          let uRLMensaje: string;
          uRLMensaje = primeraParteURL + c.whatsapp.replace("+", "") + segundaParteURL;

          let cuerpoDelMensaje: string = "Hola, soy Vale de Popapp y quería comentarte que aguantamos lo que más pudimos pero por todo lo que pasa en Argentina, nos vemos obligados a aumentar el precio de los planes a partir el mes que viene.%0A"
            + "Tu nuevo precio a partir del 1/1/2020 es de *$" +
            c.totalApagarConIva.toFixed()
            + " por mes.* Cualquier consulta podés revisar los precios en www.popapp.io/precios y para ver el detalle de tu cuenta en www.popapp.io/mypopapp (Facturación y pagos)%0A%0A"

            + "Una buena? Ahora podés consultar el historial y estadísticas de tu negocio de forma online desde tu cuenta de MyPopapp, ingresando a www.popapp.io/mypopapp (Disponible en planes Crecimiento y Empresa)%0A%0A"
            + "Espero entiendas y te deseamos un 2020 con mucho crecimiento en tu negocio!";


          uRLMensaje += cuerpoDelMensaje;
          window.open(uRLMensaje, '_blank');

        } else {
          console.log(c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta): ", c);
          throw c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta)";
        }
      } else {
        console.log("Error cuenta " + c.nombreCuenta + ": " + c.error);
        throw "Error cuenta " + c.nombreCuenta + ": ";
      }
    });
  }

  abreWhatsappConMsjAumentoPrecioGenerico(servidores: ServidorAdmin[], cuentasMap: Map<String, CuentaAdmin>, estadisticasIva: boolean, noIva: boolean) {
    //sacamos las cuentas a partir de los servidores y no las repetimos
    let cuentas = new Map<string, CuentaAdmin>();
    servidores.forEach((s) => {
      let cuenta: CuentaAdmin = cuentasMap.get(s.idCuenta);
      cuenta.pais = s.pais;
      cuentas.set(s.idCuenta, cuenta);
    });

    //para cada cuenta calculamos el importe y la moneda y q no tenga errores
    cuentas.forEach(async (c) => {
      this.cuentaService.mensajeAVerificarAntesDeCobrar(c, 1, false);
      if (!c.error || c.error.length == 0) {
        //verificamos que tenga los atributos necesarios antes de mandar el msj
        if (c.contacto && c.whatsapp && c.moneda && c.pagoHasta) {

          let primeraParteURL: string = "https://api.whatsapp.com/send?phone=";
          let segundaParteURL: string = "&text=";



          //HARDCODEO PARA PROBAR EL MENSAJE
          let uRLMensaje: string;
          uRLMensaje = primeraParteURL + c.whatsapp.replace("+", "") + segundaParteURL;

          let cuerpoDelMensaje: string = "Hola " + c.contacto + "%0A"
            + "Debido a las últimas medidas adoptadas por el Gobierno, nuestra estructura de costos se ve sensiblemente afectada, dado que todos nuestros insumos son contratados en el extranjero.%0A"
            + "Sumada esta situación, estamos terminando un año donde la inflación supera el 50%.%0A"
            + "Es por eso que nos vemos forzados a realizar un aumento de nuestros planes de precios, ya que de otra forma nos sería imposible continuar trabajando.%0A%0A"

            + "El nuevo esquema de precios es el siguiente:%0A%0A"

            + "- Plan Básico: $1500 %2B IVA%0A"
            + "- Plan Crecimiento: $1950 %2B IVA%0A"
            + "- Plan Empresa: $2500 %2B IVA%0A"
            + "- Manager extra: $850 %2B IVA%0A"
            + "- Factura Electrónica: $810 %2B IVA%0A"
            + "- Contabilium: $1100 %2B IVA%0A%0A"

            + "%5FPuedes revisar los precios publicados en popapp.io/precios.";

          if (noIva) {
            cuerpoDelMensaje += " En caso de que prefieras hacer el pago en efectivo, podemos gestionar un descuento."
          }
          cuerpoDelMensaje += "%5F%0A%0A"
            + "A partir del 1/1/2020 tu abono mensual será de *$" + c.totalApagarConIva.toFixed() + "*, podés ver el detalle de tu cuenta ingresando a popapp.io/mypopapp (Facturación y pagos)%0A%0A";

          if (estadisticasIva) {
            cuerpoDelMensaje += "¿Una buena? Ahora podés consultar el historial y estadísticas de tu negocio de forma online desde tu cuenta de MyPopapp, ingresando a popapp.io/mypopapp (Historial)%0A%0A"
          }

          cuerpoDelMensaje += "Tal como vos lo haces todos los días en tu negocio, nosotros también vamos a tirar para adelante en el 2020, a pesar de todo.%0A%0A"

            + "Un gran saludo%0A%0A"

            + "Equipo de Popapp"


          uRLMensaje += cuerpoDelMensaje;
          window.open(uRLMensaje, '_blank');

        } else {
          console.log(c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta): ", c);
          throw c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta)";
        }
      } else {
        console.log("Error cuenta " + c.nombreCuenta + ": " + c.error);
        throw "Error cuenta " + c.nombreCuenta + ": ";
      }
    });
  }
  abreWhatsappConMsjAumentoPrecioViejo(servidores: ServidorAdmin[], cuentasMap: Map<String, CuentaAdmin>) {
    //sacamos las cuentas a partir de los servidores y no las repetimos
    let cuentas = new Map<string, CuentaAdmin>();
    servidores.forEach((s) => {
      let cuenta: CuentaAdmin = cuentasMap.get(s.idCuenta);
      cuenta.pais = s.pais;
      cuentas.set(s.idCuenta, cuenta);
    });

    //para cada cuenta calculamos el importe y la moneda y q no tenga errores
    cuentas.forEach(async (c) => {
      this.cuentaService.mensajeAVerificarAntesDeCobrar(c, 1, true);
      if (!c.error || c.error.length == 0) {
        //verificamos que tenga los atributos necesarios antes de mandar el msj
        if (c.contacto && c.whatsapp && c.moneda && c.pagoHasta) {

          let primeraParteURL: string = "https://api.whatsapp.com/send?phone=";
          let segundaParteURL: string = "&text=";



          //HARDCODEO PARA PROBAR EL MENSAJE
          let uRLMensaje: string;
          uRLMensaje = primeraParteURL + c.whatsapp.replace("+", "") + segundaParteURL;

          let cuerpoDelMensaje: string = "";
          let fechaHoy: number = new Date().getTime();

          //SALUDO Y ENCABEZADO
          cuerpoDelMensaje += "Hola%20" + c.contacto + ",%20cómo estás?%0A%0A" +
            "Queremos comunicarte que a partir del *1ero de septiembre de 2019* nos vemos forzados a aumentar nuestro esquema de precios.%0A%0A" +


            "Lamentablemente, la inflación acumulada desde julio de 2018 es de 53 %25 y el último ajuste lo realizamos en agosto de 2018. " +
            "De igual modo no te preocupes, sabemos que la cosa está difícil, así que solamente decidimos aumentar los valores con " +
            "un máximo del 27 %25. El otro 26 %25 de inflación lo asumimos nosotros.%0A%0A" +

            "Por lo tanto, tu suscripción de la cuenta " + this.sacaEspacios(c.nombreCuenta) + "a partir de septiembre será de *" + c.moneda +
            c.totalApagarSinIva.toFixed();

          if (c.totalApagarConIva > c.totalApagarSinIva) {
            cuerpoDelMensaje += "%20+IVA(" + (c.totalApagarConIva - c.totalApagarSinIva).toFixed() + ")%20%3D%20" + c.moneda +
              c.totalApagarConIva.toFixed();
          }
          cuerpoDelMensaje += "*";

          cuerpoDelMensaje += "%0A%0APor otro lado, se comunica también que el tiempo de gracia para el pago son 7 días.%0A" +
            "Esto no quiere decir que vence 7 días después, sino que efectuado el vencimiento y no mediando pago en dicho plazo," +
            "el sistema se corta automáticamente hasta que se abone la mensualidad vencida.%0A%0A" +

            "Muchas gracias por seguir apoyándonos, nos encanta trabajar con vos.%0A%0A" +

            "Saludos. Gerencia Comercial de Popapp.";

          //cuerpoDelMensaje += "%0A%0A%5FEste%20mensaje%20fue%20generado%20automaticamente%5F";
          //////////////////////////////////////

          uRLMensaje += cuerpoDelMensaje;
          window.open(uRLMensaje, '_blank');

        } else {
          console.log(c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta): ", c);
          throw c.nombreCuenta + " no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta)";
        }
      } else {
        console.log("Error cuenta " + c.nombreCuenta + ": " + c.error);
        throw "Error cuenta " + c.nombreCuenta + ": ";
      }
    });
  }

  sacaEspacios(nombreCuenta: string) {
    if (nombreCuenta) {
      while (nombreCuenta.includes(" ")) {
        nombreCuenta = nombreCuenta.replace(" ", "%20");
      }
      return nombreCuenta + "%20";
    } else {
      return "%20";
    }

  }

  async separarCuentas(s: ServidorAdmin, cuentasMap: Map<String, CuentaAdmin>, locales: Map<String, LocalAdmin>): Promise<string> {
    if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
      if (s.totLocales > 1) {
        console.log("Empezando a separar cuentas ... ");
        let cuenta: CuentaAdmin = cuentasMap.get(s.idCuenta);
        let mapUpdates = {};

        //1ero escribimos en mac pasadas los nuevos y los valores viejos
        if (cuenta && cuenta.locales) {
          for (let idLocal in cuenta.locales) {
            let local = locales.get(idLocal);
            if (local != null && local.servidores != null) {
              mapUpdates["cuentas/" + idLocal + "/locales/"] = { idLocal: local };
              mapUpdates["cuentas/" + idLocal + "/nombreCuenta/"] = s.nombreCuenta;
              if (s.contacto)
                mapUpdates["cuentas/" + idLocal + "/contacto/"] = s.contacto;
              if (s.whatsapp)
                mapUpdates["cuentas/" + idLocal + "/whatsapp/"] = s.whatsapp;

              for (let mac in local.servidores) {
                mapUpdates["macsPasadas/" + mac + "/idCuenta"] = idLocal;
                mapUpdates["macsPasadas/" + mac + "/vAntIdCuenta"] = s.idCuenta;
                local.servidores[mac].dias = null;
                local.servidores[mac].nombreCuenta = null;
                local.servidores[mac].nombreLocal = null;
                local.servidores[mac].indServidor = null;
                local.servidores[mac].indLocal = null;
                local.servidores[mac].totLocales = null;
                local.servidores[mac].totServidoresOSvs = null;

                const servidor: ServidorAdmin = local.servidores[mac];
                if (servidor && servidor.keyPedidosOnline && servidor.keyPedidosOnline.key) {
                  servidor.idCuenta = idLocal;
                  try {
                    await this.dbFS.updateAt('confLocalPO/' + servidor.keyPedidosOnline.key, { idCuenta: idLocal });
                  } catch (e) {
                    console.error('Error al escribir en FS' + servidor.keyPedidosOnline.key, servidor, e);
                    throw 'No se pudo escribir en FS, preguntar a German con captura de la consola F12'
                  }
                  if (+servidor.numeroLocal > 0 && +servidor.numeroLocal !== 900 && +servidor.numeroLocal < 9000) {
                    this.hitearLocalSQLBack(servidor, true);
                  } else {
                    this.hitearLocalSQLBack(servidor, false);
                  }
                }
                local.servidores[mac].idMac = null;
                local.servidores[mac].idLocal = null;
                local.servidores[mac].idCuenta = null;
              }

              local.id = null;
              local.idCuenta = null;
              local.totalImpServidores = null;
              local.totLocales = null;
            }
          }
          return this.db.object('/').update(mapUpdates).then(v => {
            return "Exito"
          });

        } else {
          //esto no deberia pasar
          console.log("Locales == null en la cuenta", cuenta);
          throw "Locales == null";
        }

      } else {
        throw "No hay más de 1 local" + s.totLocales;
      }
    } else {
      console.log("Key Invalida local o cuenta", s);
      throw "Key Invalida...idLocal" + s.idLocal;
    }
  }

  deshacerOperacion(s: ServidorAdmin, email: string): Promise<string> {
    if (s && s.lastCuenta && s.uCuenta && s.idCuenta && (email === 'gmarin@popapp.io' || email === 'pmarin@popapp.io' || email === 'cmarin@popapp.io')) {
      const fecha = new Date(s.uCuenta).toLocaleDateString();
      console.log("Deshaciendo movimiento del " + fecha, s.nombreCuenta)
      //leemos el ultimo movimiento
      return this.db.object("movimientosCuentas/" + s.lastCuenta).query.once("value").then(snap => {
        let mov: MovimientoAdmin = snap.val();
        if (mov && mov.before && !mov.undone) {
          // si no tiene before entonces no tiene un estado anterior al cual deshacer
          let mapUpdates = {};
          mapUpdates["movimientosCuentas/" + s.lastCuenta + "/undone"] = true;

          if (mov.before.last) {
            //para rehacer el movimiento alguna vez
            mapUpdates["movimientosCuentas/" + mov.before.last + "/redo"] = s.lastCuenta;
          } else {
            mov.before.last = null;
          }
          mapUpdates["cuentas/" + s.idCuenta] = mov.before;

          return this.db.object("/").update(mapUpdates)
            .then(r => {
              return "Deshecho con Exito"
            })
            .catch(error => {
              console.log("Error al deshacer la operacion", error)
              throw error;
            });
        } else {
          return of("No habia nada para deshacer").toPromise()
        }
      });
    } else {
      throw "No se pudo deshacer";
    }
  }

  escribePagos(s: ServidorAdmin, fechaString: string): void {
    this.httpPatchPagos(s, fechaString)
      .then(respuesta => {
        return of("Exito").toPromise;
      })
  }

  httpPatchPagos(s: ServidorAdmin, fecha: string): Promise<any> {
    let jsonVenc = {};
    jsonVenc[s.idMac] = fecha;

    return this.http.patch("https://popapprestoserver.firebaseio.com/pagos.json", jsonVenc).toPromise()
  }

  actualizaUltimosModificados(mapUpdates, keyCarta: string, timestamp) {
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/categorias"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/comidas"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/fracciones"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/ingredientes"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/stocks"] = timestamp;
    mapUpdates[this.CARTAS_BARRA + keyCarta + this.BARRA_ULTIMOS_MODIFICADOS_BARRA + "/activos"] = timestamp;
  }

  async agregarVinculadoStock(s: ServidorAdmin, usuario: any): Promise<string> {




    const USUARIOS_BARRA = "usuarios/"
    const mac: string = s.idMac;
    const keyCarta: string = prompt("ingresa keyCarta", "").trim();
    if (!keyCarta || !(keyCarta.length > 2)) {
      throw "Mal ingresada keyCarta"
    }

    const numSucursal: number = +prompt("ingresa numSucursal", "1").trim();
    if (!numSucursal || !(numSucursal > 0)) {
      throw "Mal ingresada numSucursal"
    }

    const nombreLocal: string = prompt("ingresa nombreLocal", usuario.nombre).trim();
    if (!nombreLocal || !(nombreLocal.length > 0)) {
      throw "Mal ingresada nombreLocal"
    }
    let email: string = prompt("Ingrese emails, 0 para terminar de ingresar emails o N, para abortar").trim();
    let emails: string[] = [];
    while (email && email.length > 0 && (email !== "0" && email !== "N")) {
      emails.push(email)
      email = prompt("Ingrese emails, 0 para terminar de ingresar emails o N, para abortar").trim();
    }
    if (email === "N") {
      throw "Abortado por N"
    }
    if (email !== "0") {
      throw "Abortado por Ni 0 ni N"
    }

    if (!confirm("Estas seguro de los datos cargados? no se valida nada!. ")) {
      return of("No se confirmó, no se hizo nada").toPromise();
    } else {
      let mapUpdates = {};
      //forzar la actualizacion de la carta
      this.actualizaUltimosModificados(mapUpdates, keyCarta, { ".sv": "timestamp" });

      mapUpdates["usuarios/" + mac + "/carta"] = keyCarta;
      mapUpdates["usuarios/" + mac + "/nombre"] = nombreLocal;
      mapUpdates["usuarios/" + mac + "/numSucursal"] = numSucursal;
      mapUpdates["usuarios/" + mac + "/stockCentralizado"] = true;
      mapUpdates["usuarios/" + mac + "/mails"] = emails;


      let macsAnteriores: string[] = []
      let flagIntentoCargarMacs = false

      let fbactivos = await this.db.object("cartas/" + keyCarta + "/activos").query.once("value").then(v => {
        return v.val();
      })
      if (!fbactivos) throw "No hay activos"
      for (let idActivo in fbactivos) {
        if (!flagIntentoCargarMacs) {
          if (fbactivos[idActivo] && fbactivos[idActivo].activo) {
            for (let m in fbactivos[idActivo].activo) {
              macsAnteriores.push(m)
            }
          }
          flagIntentoCargarMacs = true
        }
        mapUpdates["cartas/" + keyCarta + "/activos/" + idActivo + "/activo/" + mac] = true;
      }


      let fbstocks = await this.db.object("cartas/" + keyCarta + "/stocks").query.once("value").then(v => {
        return v.val();
      })
      if (!fbstocks) throw "No hay stocks"
      for (let idStock in fbstocks) {
        mapUpdates["cartas/" + keyCarta + "/stocks/" + idStock + "/stock/" + mac] = 0;
      }

      console.log(macsAnteriores)

      for (let locMac of macsAnteriores) {
        if (locMac !== mac) {
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/aceptado"] = true;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/mac"] = mac;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/nombre"] = nombreLocal;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/numSucursal"] = numSucursal;
          mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac + "/mails"] = emails;

          let uAux = await this.db.object("usuarios/" + locMac).query.once("value").then(v => {
            return v.val();
          })
          if (uAux) {
            console.log(uAux)
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/aceptado"] = true;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/mac"] = locMac;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/nombre"] = uAux.nombre;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/numSucursal"] = uAux.numSucursal;
            mapUpdates[USUARIOS_BARRA + mac + "/vinculadoCon/" + locMac + "/mails"] = emails;
          }
        }

      }

      return this.db.list('/').update("/", mapUpdates).then(v => {
        return "Exito"
      });
    }
  }

  async sacarIngredientesSinStock(keyCarta: string) {
    let mapUpdates = {};
    let stocks = await this.db.list("cartas/" + keyCarta + "/stocks").query.once("value").then(v => {
      return v.val();
    })

    this.actualizaUltimosModificados(mapUpdates, keyCarta, { ".sv": "timestamp" })

    let stocksEIngredientesBorrados: string[] = [];
    for (let keyStock in stocks) {
      let stock = stocks[keyStock];
      if (stock && !stock.keyIngrediente) {
        mapUpdates["cartas/" + keyCarta + "/stocks/" + keyStock] = null;
        mapUpdates["cartas/" + keyCarta + "/ingredientes/" + keyStock] = null;
        stocksEIngredientesBorrados.push(keyStock)
      }
    }
    if (confirm("Borrar " + stocksEIngredientesBorrados.length + " objetos?")) {
      return this.db.list('/').update("/", mapUpdates).then(v => {
        return "Exito"
      });
    }
  }

  async quitarVinculadoStock(s: ServidorAdmin): Promise<string> {
    if (!s.idMac) {
      throw "No tiene idMac"
    } else {
      /*
        309C2302AD34
          carta: "keyAustralia"
          mails
            0: "guidoarg@hotmail.com"
            1: "panchosaustralia@gmail.com"
          nombre: "Australia Twin"
          numSucursal: 2
          stockCentralizado: true
          vinculadoCon
            4CCC6AD0A72E
              aceptado: true
              mac: "4CCC6AD0A72E"
              mails
                0: "guidoarg@hotmail.com"
                1: "panchosaustralia@gmail.com"
              nombre: "Panchos Australia"
              numSucursal: 1
            7085C20912F0
              aceptado: true
              mac: "7085C20912F0"
              mails
                0: "guidoarg@hotmail.com"
                1: "panchosaustralia@gmail.com"
              nombre: "Australia Rawson"
              numSucursal: 3
        */
      let usuario = await this.db.object("usuarios/" + s.idMac).query.once("value").then(v => {
        return v.val();
      })

      if (!usuario) {
        throw "No existe usuario"
      }
      if (!usuario.stockCentralizado) {
        throw "No hay stockCentralizado"
      }
      if (!usuario.carta) {
        throw "No hay carta (keycarta)"
      }
      if (!+usuario.numSucursal) {
        throw "No hay numSucursal"
      }
      if (!usuario.nombre) {
        throw "No hay nombre"
      }
      if (!usuario.vinculadoCon) {
        throw "No hay vinculadoCon"
      }
      if (!confirm("Estas seguro de los datos cargados? no se valida nada!. ")) {
        return of("No se confirmó, no se hizo nada").toPromise();
      } else {
        const USUARIOS_BARRA = "usuarios/"
        const mac: string = s.idMac;
        const keyCarta: string = usuario.carta;
        const numSucursal: number = +usuario.numSucursal;
        const nombreLocal: string = usuario.nombre;
        let mapUpdates = {};
        //forzar la actualizacion de la carta
        this.actualizaUltimosModificados(mapUpdates, keyCarta, { ".sv": "timestamp" });

        mapUpdates[USUARIOS_BARRA + s.idMac + "/stockCentralizado"] = false;
        mapUpdates[USUARIOS_BARRA + s.idMac + "/carta"] = null;
        mapUpdates[USUARIOS_BARRA + s.idMac + "/numSucursal"] = null;
        mapUpdates[USUARIOS_BARRA + s.idMac + "/vinculadoCon"] = null;

        for (let locMac in usuario.vinculadoCon) {
          if (locMac !== mac) {
            mapUpdates[USUARIOS_BARRA + locMac + "/vinculadoCon/" + mac] = null;
          }
        }

        let fbactivos = await this.db.list("cartas/" + keyCarta + "/activos").query.once("value").then(v => {
          return v.val();
        })
        if (!fbactivos) throw "No hay activos"
        for (let idActivo in fbactivos) {
          mapUpdates["cartas/" + keyCarta + "/activos/" + idActivo + "/activo/" + mac] = null;
        }

        let fbstocks = await this.db.list("cartas/" + keyCarta + "/stocks").query.once("value").then(v => {
          return v.val();
        })
        if (!fbstocks) throw "No hay stocks"
        for (let idStock in fbstocks) {
          mapUpdates["cartas/" + keyCarta + "/stocks/" + idStock + "/stock/" + mac] = null;
        }

        return this.db.list('/').update("/", mapUpdates).then(v => {
          return "Exito"
        });
      }
    }
  }
  hitearLocalSQLBack(s: ServidorAdmin, activo: boolean, nuevakey?: string) {
    // const arre = s.fechaInstalacion.split('/');
    // const fecha = new Date(+arre[2], +arre[1] - 1, +arre[0]);
    let body: any = {
      _key: nuevakey || s.keyPedidosOnline?.key,
      activo: activo,
      idCuenta: s.idCuenta,
      idMac: s.idMac,
      idLocal: s.idLocal,
      nombre: s.nombreServidor,
      // fechaCreacion: fecha.toISOString(),
      // esHub: s.hub ? s.hub : false,

    }
    if (s.moneda) {
      body.moneda = s.moneda;
    }
    if (s.pais) {
      body.pais = s.pais;
      if (s.pais == "ARG" || s.pais == "AR" || s.pais == "ARGPRUEBA") {
        body.pais = 'Argentina';
      } else if (s.pais == "CHI" || s.pais == "CL" || s.pais == "CHIPRUEBA") {
        body.pais = 'Chile';
      } else if (s.pais == "PER" || s.pais == "PE") {
        body.pais = 'Perú';
      } else if (s.pais == "COL" || s.pais == "Colombia") {
        body.pais = 'Colombia';
      } else if (s.pais == "CR") {
        body.pais = 'Costa Rica';
      } else if (s.pais == "MEX") {
        body.pais = 'México';
      } else if (s.pais == "ECU") {
        body.pais = 'Ecuador';
      }
    }
    if (s.direccionyciudad) {
      if (s.direccionyciudad.ciudad) {
        body.ciudad = s.direccionyciudad.ciudad;
      }
      if (s.direccionyciudad.direccion) {
        body.direccion = s.direccionyciudad.direccion;
      }
    }
    console.log(body);
    return this.http.post(url + 'local', body).toPromise()
  }

  hitearSQLBackCrearCuenta(s: ServidorAdmin) {

    let body = {
      idCuenta: s.idCuenta,
      nombre: s.nombreCuenta,
      nombreCliente: '',
    }

    console.log(body);
    return this.http.post(url + 'cuentas', body).toPromise()
  }

  hitearSQLBackCrearManager(s: ServidorAdmin) {

    let body = {
      nombre: s.nombreServidor,
      mac: s.idMac,
      versionDisponible: s.versionDisponible ? s.versionDisponible : '',
      versionAcual: s.versionActual ? s.versionActual : ''
    }
    return this.http.post(url + 'managers', body).toPromise()
  }

  enviarMensajeSlackError(canal: string, codigo: number, mensaje: string, objetoError: object) {
    return this.http.post(`${this.URL_BASE_FUNCTIONS}/scripts/enviar_mensaje_slack`, {
      canal,
      codigo,
      mensaje,
      objetoError,
    });
  }

  hitearCrearLocalSQLBackOb(s: ServidorAdmin, activo: boolean, nuevaKey?: string) {
    // const arre = s.fechaInstalacion.split('/');
    // const fecha = new Date(+arre[2], +arre[1] - 1, +arre[0]);
    let body: any = {
      keyLocal: nuevaKey || s.keyPedidosOnline?.key,
      nombre: s.nombreLocal,
      fechaCreacion: new Date(),
      offset: s.keyPedidosOnline?.locales?.[0]?.offset || null,
      ubicacion: {
        pais: s.pais,
      },
      idCuenta: s.idCuenta,
      idLocal: s.idLocal,
      idManager: s.idMac,
      activo: activo,
      moneda: s.moneda,
      tipoComercio: 'No especificado',
      createdBy: 'AdminCuentas',
      esHub: false,
    }
    body.moneda = s.moneda || null;
    if (s.pais) {
      body.ubicacion.pais = s.pais;
      if (s.pais == "ARG" || s.pais == "AR" || s.pais == "ARGPRUEBA") {
        body.ubicacion.pais = 'Argentina';
      } else if (s.pais == "CHI" || s.pais == "CL" || s.pais == "CHIPRUEBA") {
        body.ubicacion.pais = 'Chile';
      } else if (s.pais == "PER" || s.pais == "PE") {
        body.ubicacion.pais = 'Perú';
      } else if (s.pais == "COL" || s.pais == "Colombia") {
        body.ubicacion.pais = 'Colombia';
      } else if (s.pais == "CR") {
        body.ubicacion.pais = 'Costa Rica';
      } else if (s.pais == "MEX") {
        body.ubicacion.pais = 'México';
      } else if (s.pais == "ECU") {
        body.ubicacion.pais = 'Ecuador';
      }
    }
    if (s.direccionyciudad) {
      if (s.direccionyciudad.ciudad) {
        body.ubicacion.divisionPolitica3 = s.direccionyciudad.ciudad;
      }
      if (s.direccionyciudad.direccion) {
        body.ubicacion.direccion = s.direccionyciudad.direccion;
      }
    }

    body.ubicacion.pais = s.pais || null;
    console.log(body);
    return this.http.post(this.URL_BASE_OB + '/v1/locales', body).toPromise();
  }

  hitearSQLObtenerCuenta(s: ServidorAdmin) {
    return this.http.get(this.URL_BASE_OB + '/v1/cuentas/' + s.idCuenta + '?by=legacyId').toPromise();
  }

  hitearSQLBackCrearCuentaOb(s: ServidorAdmin, activo: boolean) {
    const body = {
      idCuenta: s.idCuenta,
      nombre: s.nombreCuenta,
      nombreCliente: s.nombreCuenta,
      activa: activo,
      fechaCreacion: new Date(),
      numeroTelefono: s.whatsapp || '',
      emailRegistro: s.emailpago || ''
    }
    return this.http.post(this.URL_BASE_OB + '/v1/cuentas', body).toPromise();
  }

  hitearSQLBackCrearManagerOb(s: ServidorAdmin, nuevaKey?: string) {
    console.log('servidorAdmin enviar', s, 'nuevaKey', nuevaKey);
    let body = {
      fechaInstalacion: new Date(),
      mac: s.idMac,
      nombre: s.nombreServidor,
      enUso: true,
      numeroLocal: s.numeroLocal,
      keyLocal: nuevaKey || s.keyPedidosOnline?.key,
      idCuenta: s.idCuenta,
      moneda: s.moneda || null,
      planN: s.planN || null,
    }
    return this.http.post(this.URL_BASE_OB + '/v1/managers', body).toPromise()
  }

  hitearSQLBackCrearLocacionOb(s: ServidorAdmin, activo: boolean, idCuenta: number) {
    const body = {
      nombre: s.nombreLocal || s.nombreCuenta || s.nombreServidor,
      ubicacion: {
        pais: s.pais
      },
      idCuenta: idCuenta,
      activo,
      idLocal: s.idLocal
    }
    return this.http.post(this.URL_BASE_OB + '/v1/locaciones', body).toPromise();
  }


  async leerRecursoOb(recurso: string, id: string, by: string) {
    let res;
    try {
      const url = `${this.URL_BASE_OB}/v1/${recurso}/${id}?by=${by}`;
      const res = await (this.http.get(url)).toPromise();
      return res.json();
    } catch (e) {
      return null
    }
  }

  async hitearSQL(s: ServidorAdmin, nuevakey?: string) {
    let resCuenta, resLocal, resManager, resLocacion;

    const activo =
      (s.numeroLocal && +s.numeroLocal > 0 &&
        +s.numeroLocal !== 900 &&
        +s.numeroLocal < 9000);

    console.log('Hitear SQL init');
    let cuenta;
    try {
      try {

        [resCuenta, resLocal, resLocacion, resManager] = await Promise.all([
          this.leerRecursoOb('cuentas', s.idCuenta, 'legacyId'),
          s.keyPedidosOnline?.key ? this.leerRecursoOb('locales', s.keyPedidosOnline?.key, 'keyLocal') : null,
          this.leerRecursoOb('locaciones', s.idCuenta, 'legacyId'),
          this.leerRecursoOb('managers', s.idMac, 'mac')
        ]);
        cuenta = resCuenta.cuenta;
      } catch { }

      if (!resCuenta?.cuenta) {
        cuenta = (await this.hitearSQLBackCrearCuentaOb(s, activo)).json().cuenta;
      }
    } catch (e) {
      console.error('error al crear cuenta ob', e);
      cuenta = (await this.hitearSQLBackCrearCuenta(s)).json().cuentaSql;
      cuenta.id = cuenta.pk_cuenta;
    }

    try {
      if (!resLocacion?.data) await this.hitearSQLBackCrearLocacionOb(s, activo, cuenta.id)
      if (!resLocal?.local) await this.hitearCrearLocalSQLBackOb(s, activo, nuevakey);
    } catch (e) {
      console.error('error al crear local ob', e);
      await this.hitearLocalSQLBack(s, activo, nuevakey)
    }

    try {

      if (!resLocal?.local) await this.hitearCrearLocalSQLBackOb(s, activo, nuevakey);
    } catch (e) {
      console.error('error al crear local ob', e);
      await this.hitearLocalSQLBack(s, activo, nuevakey)
    }

    try {
      if (!resManager?.manager) await this.hitearSQLBackCrearManagerOb(s, nuevakey);
    } catch (e) {
      console.error('error al crear manager ob', e);
      await this.hitearSQLBackCrearManager(s);
    }

  }

}
