import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Response from './models/service-response';
import Account from './models/account';
import { environment } from 'src/environments/environment';
import Discount from './models/discount';
import Pricing from './models/pricing';
import Billing from './models/billing';
import { Country } from './models/misc';

const BASE_URL = environment.urls.billing;

export const ERRORS = Object.freeze({
  ACCOUNT_HAS_BILLING: "error cannot update country of account with associated billings",
  PREMISE_MAIL_EMPTY: "error premises email is empty",
})

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  constructor(private http: HttpClient) { }

  getAccount(popappId: string) {
    return this.http.get<Response<Account>>(`${BASE_URL}/account/popapp/${popappId}`);
  }

  getPricings(countryId?: number) {
    const query = `${countryId ? '?country-id=' + countryId : ''}`
    return this.http.get<Response<Pricing[]>>(`${BASE_URL}/pricing${query}`);
  }

  getBillingsFromAccount(accountId: number) {
    return this.http.get<Response<Billing[]>>(`${BASE_URL}/billing/account/${accountId}`);
  }

  createDiscount(discount: Discount) {
    return this.http.post<Response<Discount>>(`${BASE_URL}/discount`, discount);
  }

  addDiscountToBilling(billingId: number, discountId: string) {
    return this.http.post<Response<Billing>>(`${BASE_URL}/billing/${billingId}/discount`, { discount_id: discountId });
  }

  deleteBilling(id: number) {
    return this.http.delete<Response<Billing>>(`${BASE_URL}/billing/${id}`);
  }

  createBilling(accountId: number, premiseId: number, pricingId: number) {
    return this.http.post<Response<Billing[]>>(`${BASE_URL}/billing/setup`, {
      account_id: `${accountId}`,
      premises_id: `${premiseId}`,
      pricing_id: [`${pricingId}`],
      concept_id: []
    });
  }

  migrateAccount(popappId: string) {
    return this.http.post<Response<Account>>(`${BASE_URL}/account/migrate`, {
      popapp_id: popappId,
    });
  }

  getCountries() {
    return this.http.get<Response<Country[]>>(`${BASE_URL}/country`);
  }

  updateAccount(countryId: number, accountId: number) {
    return this.http.patch<Response<Account>>(`${BASE_URL}/account`, { id: accountId, country_id: countryId });
  }

  deleteBillingDiscount(billingId: number, discountId: number) {
    return this.http.delete<Response<Billing>>(`${BASE_URL}/billing/${billingId}/discount/${discountId}`);
  }
}
