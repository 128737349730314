import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogFiltrosData } from '../../stock/stock.component';

@Component({
  selector: 'app-modal-alerta-stock',
  templateUrl: './modal-alerta-stock.component.html',
  styleUrls: ['./modal-alerta-stock.component.scss']
})

export class ModalAlertaStockComponent implements OnInit {
  errorReset: string;
  correo: string;
  passReseteado: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalAlertaStockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogFiltrosData
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
