import { Component, OnInit, Inject } from '@angular/core';
import { CampoDialog } from '../../shared/campo-dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  userForm: FormGroup;

  formErrors = this.creaJsonErrors();

  validationMessages = this.creaJsonMensajes();

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { campos: CampoDialog[], submit: string, cerrar: string }) {
  }
  ngOnInit() {
    this.buildForm();
  }
  buildForm(): void {
    let Json = this.creaJsonGroup();
    this.userForm = this.fb.group(Json);

    this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  /**
   * Crea los controles para cada campo del formulario
   */
  creaJsonGroup() {
    let Json = {};
    for (let i = 0; i < this.data.campos.length; i++) {
      Json[this.data.campos[i].nombre] = ['', this.data.campos[i].Validaciones];
    }
    return Json;
  }
  onValueChanged(data?: any) {
    if (!this.userForm) { return; }
    const form = this.userForm;
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  /**
   * Guarda los mensajes de error de las validaciones
   */
  creaJsonMensajes(): any {
    let Json = {};
    let arreglo = {};
    for (let i = 0; i < this.data.campos.length; i++) {
      this.data.campos[i].errores.forEach(e => {
        arreglo[e.key] = e.mensaje;
      });
      Json[this.data.campos[i].nombre] = arreglo;
    }
    return Json;
  }

  /**
   * Guarda las validaciones que se hacen en cada campo 
   */
  creaJsonErrors(): any {
    let Json = {};
    for (let i = 0; i < this.data.campos.length; i++) {
      Json[this.data.campos[i].nombre] = [''];
    }
    return Json;
  }


  /** 
   * Retorna los valores de cada campo al componente que los llamó y cierra el dialog 
   * 
  */
  onSubmit() {
    let valores: string[] = [];
    for (let i = 0; i < this.data.campos.length; i++) {
      valores.push(this.userForm.controls[this.data.campos[i].nombre].value);
    }
    this.dialogRef.close(valores);
  }
}
