import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailCuenta } from '../../shared/admin/emailCuenta';
import { SelectionModel } from '@angular/cdk/collections';


import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AuthService } from "../../core/auth.service";
import { CuentaService } from '../../services/cuenta.service';
import { Router } from '@angular/router';
import * as consts from "../../shared/constantes";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-emails',
  templateUrl: './admin-emails.component.html',
  styleUrls: ['./admin-emails.component.scss', '../../../mypopapp/assets/_css/mypopapp.css', '../../../mypopapp/assets/_css/mypopapp-mq.css']
})

export class AdminEmailsComponent implements OnInit {

  cuentasRef;

  cantLeidas: number = 0;

  environment = environment;

  emailsCuentas: EmailCuenta[] = [];

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;

  msjSnack: string;

  fecha: any;


  //datos tabla

  displayedColumns: string[] = ['select', 'email', "nombreLocal", "contactoDate", "contactoDesde", 'creadoDate', 'creadoDesde', 'mandoMailVerificacion', 'nombre', 'pais', 'servidores', 'verificadoEmail'
    , 'verificadoWapp', 'wapp'
  ];

  columnsToDisplay: string[] = this.displayedColumns; //this.displayedColumns.slice(0, 5);

  dataSource: MatTableDataSource<EmailCuenta> = new MatTableDataSource(this.emailsCuentas);



  filtroActual: string = "a";
  selection = new SelectionModel<EmailCuenta>(true, []);

  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = "";

    let filtro = filterValue.trim().toLowerCase();
    if (!filtro || filtro.length == 0) {
      filtro = "a";
    }

    this.filtroActual = filtro;
    this.dataSource.filter = filtro;
    this.dataSource.sort = this.sort;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  driftVar: any;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  ngOnInit() {
    this.hideDrift(1);
    this.dataSource.sort = this.sort;
  }
  esAdmin(user) {
    return consts.esAdmin(user);
  }
  constructor(public router: Router, public afAuth: AngularFireAuth, public cuentaService: CuentaService, public db: AngularFireDatabase, public authService: AuthService) {
    afAuth.user.subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {

        //leemos los emails
        this.itemsRef = db.list('emailsCuentas');

        this.items = this.itemsRef.snapshotChanges().pipe(
          map(changes => {
            let mapeado = changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
            this.sacaEmailsCuentas(mapeado);
            this.dataSource.sort = this.sort;
            return mapeado;
          }
          )
        );
      }
    });
  }


  sortHeader(index: number) {
    //console.log("click");
    this.dataSource.sort = this.sort;
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }


  /**
   * Revisa los emails seleccionados mas el ultimo clickeado y revisa de acurdo al boton tocado que valor es el que hay que cambiar, lo valida y lo modifica en FireBase
   * 
   * @param input el tipo de atributo que estamos por modificar
   * @param element el servidor que hemos tocado al ultimo
   */
  inputABM(input: string, element: EmailCuenta) {
    let mensaje: string = "";
    let emailsAModificar: EmailCuenta[] = [];
    if (this.selection.selected && this.selection.selected.length > 0) {
      for (let i = 0; i < this.selection.selected.length; i++) {
        mensaje += "\n " + this.selection.selected[i].email;
        emailsAModificar.push(this.selection.selected[i]);
      }
      if (!this.selection.selected.includes(element)) {
        mensaje += "\n " + element.email;
        emailsAModificar.push(element);
      } //else ya se proceso en el for
    } else {
      //proceso individual
      emailsAModificar.push(element);
      mensaje += "\n " + element.email;
    }

    if (input == 'creadoDate') {
      //let valor = prompt("Cambiar el nombreServidor de " + emailsAModificar.length + ":" + mensaje, emailsAModificar[0].email);
      // if (valor != null && valor != "") {
      //   this.validarYEscribirEnFBServidor(valor, false, input, emailsAModificar);
      // }
    } else if (input == 'fiscal') {

      let valor = prompt("Cambiar Fiscal de " + emailsAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (valor != null && valor != "") {
        if (valor == 's' || valor == 'n' || valor == 'S' || valor == 'N') {
          //modificar fiscal
          let fiscalActivado: boolean;
          if (valor == 's' || valor == 'S') {
            fiscalActivado = true;
          } else {
            fiscalActivado = false;
          }
          this.validarYEscribirEnFBServidor(fiscalActivado, false, input, emailsAModificar);
        } else {
          this.openSnackBar("", "Escribir s ó n");
        }
      }
    }
  }



  validarYEscribirEnFBServidor(valor: any, validarNumero: boolean, input: string, servAModificar: EmailCuenta[]) {
    if (!validarNumero || !isNaN(valor)) {
      console.log("Modificando " + input + "... a " + valor);
      let mapUpdates = {};
      for (let i = 0; i < servAModificar.length; i++) {
        let s: EmailCuenta = servAModificar[i];
        //if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
        //mapUpdates[s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/" + input + "/"] = valor;
        //} else {
        //  this.openSnackBar("Key Invalida...idCuenta, idLocal, idMac: " + s.idCuenta + ", " + s.idLocal + ", " + s.idMac, "Avisar GAMR");
        //  console.log("Key Invalida", s);
        //}
      }

      this.itemsRef.update("/", mapUpdates)
        .then(_ => { this.openSnackBar("", "Exito") })
        .catch(err => { this.openSnackBar("No pudo grabar " + input, "Avisar GAMR"); console.log(err) });

    } else {
      this.openSnackBar("", "No es número");
    }
  }

  /**
   * valida que la key sea != null y con length mayor a 3
   */
  keyValida(key: string): boolean {
    if (key != null && key.length > 3) {
      return true;
    } else {
      return false;
    }
  }








  deseleccionarTodes() {
    this.selection.clear();
  }

  getMyStyles(dias: number) {
    let color = 'green';
    if (dias < 0 && dias > -15) {
      color = 'violet';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': 'white',
      'background': color,
      'padding': '10px'
    };
    return myStyles;
  }



  openSnackBar(message: string, action: string) {
    this.msjSnack = "|  " + message + "      " + action + "  |"
    setTimeout(_ => {
      this.msjSnack = null
    }, 3000)

    //this.snackBar.open(message, action, {
    // duration: 2000,
    //});
  }

  fechaConPuntoYComa(date) {
    return date.getFullYear() + ";" + ((+date.getMonth() + 1) > 9 ? '' : '0') + (+date.getMonth() + 1) + ";" + ((+date.getDate()) > 9 ? '' : '0') + date.getDate() + ";" + ((+date.getHours()) > 9 ? '' : '0') + (+date.getHours() /*- 3*/) + ";" + ((+date.getMinutes()) > 9 ? '' : '0') + date.getMinutes() + ";" + ((+date.getSeconds()) > 9 ? '' : '0') + date.getSeconds();
  }

  convertDateTodd_mm_yyyy(date: Date): string {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [(dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    date.getFullYear()
    ].join('/');
  }

  verificarPorWapp() {
    //pedir todos los campos para agregar
    let email = prompt("Ingrese email: ", "@gmail.com");
    //buscamos el email en el arreglo
    let emailCuentaEncontrada = this.emailsCuentas.find(e => e.email == email);
    let emailConComas = this.reemplazaPuntosPorComas(email);
    let mapUpdates = {}
    let nombre = ""
    let pais = "AR"
    let wapp = "+549264"
    if (emailCuentaEncontrada != null) {
      nombre = emailCuentaEncontrada.nombre
      pais = emailCuentaEncontrada.pais
      wapp = emailCuentaEncontrada.wapp
    } else {
      mapUpdates["emailsCuentas/" + emailConComas + "/creadoDate"] = { ".sv": "timestamp" };
      mapUpdates["emailsCuentas/" + emailConComas + "/creadoDesde"] = "paadmin";
    }

    nombre = prompt("Ingrese nombre: ", nombre);
    pais = prompt("Ingrese pais: (AR,CL,PE,...,XX)", pais);
    wapp = prompt("Ingrese whatsapp:", wapp);
    let mac = prompt("Ingrese mac:", "123456789ABC");

    mapUpdates["emailsCuentas/" + emailConComas + "/nombre"] = nombre;
    mapUpdates["emailsCuentas/" + emailConComas + "/pais"] = pais;
    mapUpdates["emailsCuentas/" + emailConComas + "/wapp"] = wapp;
    mapUpdates["emailsCuentas/" + emailConComas + "/verificadowapp"] = true;
    let fechayHora = this.fechaConPuntoYComa(new Date());
    mapUpdates["emailsCuentas/" + emailConComas + "/servidores/" + mac + "/" + fechayHora + "/evento"] = "verificar wapp desde instalacion";
    mapUpdates["emailsCuentas/" + emailConComas + "/servidores/" + mac + "/fechaInstalacion"] = this.convertDateTodd_mm_yyyy(new Date());


    //generar los campos y verif wapp 

    //escribir en FB emailsCuentas

    //generar una cuenta nueva con los datos posibles
    //revisar si ya hay en macsPasadas
    //revisar si ya hay un servidor en esa cuenta y ese local

    //copiar al portapapeles el codigo para validar por wapp
    /*
      ult char
      1er char
      penult char
      2do char
      las letras son números
      A=1
      B=2
      ...
      F=6
    */
    this.db.list('/').update("/", mapUpdates).then(_ => {
      let codigo = this.obtieneCodigoVerif(mac)
      this.copyToClipboard(codigo)
      this.openSnackBar("Se copio el codigo al portapapeles", "Exito")
    }).catch(reason => {
      this.openSnackBar("No se pudo grabar en Firebase", "Fallo")
      console.log("No se pudo grabar en Firebase", reason)
    })


  }

  obtieneCodigoVerif(mac: string): string {
    let ult = mac[mac.length - 1];
    let primer = mac[0];
    let penult = mac[mac.length - 2];
    let seg = mac[1];

    return this.convierteAnum([ult, primer, penult, seg]);
  }

  convierteAnum(cod: string[]): string {
    let codigo = ""
    cod.forEach(c => {
      if (c == 'A') {
        codigo += "1"
      } else if (c == 'B') {
        codigo += "2"
      } else if (c == 'C') {
        codigo += "3"
      } else if (c == 'D') {
        codigo += "4"
      } else if (c == 'E') {
        codigo += "5"
      } else if (c == 'F') {
        codigo += "6"
      } else {
        codigo += c;
      }
    });
    return codigo;
  }

  reemplazaComaasPorPuntos(emailConComas: string) {
    let emailNormal: string = emailConComas;
    while (emailNormal.includes(",")) {
      emailNormal = emailNormal.replace(",", ".");
    }
    return emailNormal;
  }

  reemplazaPuntosPorComas(emailConPuntos: string) {
    let emailNormal: string = emailConPuntos;
    while (emailNormal.includes(".")) {
      emailNormal = emailNormal.replace(".", ",");
    }
    return emailNormal;
  }

  sacaEmailsCuentas(mapEmails: any[]) {
    let hoyDia: number = new Date().getDate();
    let hoyMes: number = new Date().getMonth();
    let hoyAnio: number = new Date().getFullYear();

    this.emailsCuentas = [];

    for (let i in mapEmails) {
      let emailCuenta: EmailCuenta = mapEmails[i];
      emailCuenta.emailSinPuntos = mapEmails[i].key;
      emailCuenta.email = this.reemplazaComaasPorPuntos(emailCuenta.emailSinPuntos);
      //for de servidores

      // c.localesIds = asdasd;
      this.emailsCuentas.push(emailCuenta);
    }



    /*this.emailsCuentas.sort(function (e1: EmailCuenta, e2: EmailCuenta) {
      return e1.email < e2.email;
    });*/

    this.dataSource = new MatTableDataSource(this.emailsCuentas);
    this.dataSource.filterPredicate =
      (e: EmailCuenta, filter: string) => {
        return JSON.stringify(e).trim().toLowerCase().indexOf(filter) != -1;
      }
    this.applyFilter(this.filtroActual);
    this.deseleccionarTodes();

  }

}
