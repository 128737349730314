import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'minutos'
})
export class MinutosPipe implements PipeTransform {
    transform(mins: number): string {
        if (mins < 1) {
            return "instantes"
        } else if (mins < 60) {
            return mins.toFixed(0) + " min."
        } else if (mins < (24 * 60)) {
            let horas = Math.trunc(mins / 60);
            let minutos = (mins - (+horas * 60));
            let minString = minutos.toFixed(0);
            if (minString.length === 1) {
                minString = "0" + minString;
            }
            return horas + ":" + minString + " hs.";
        } else if (mins < (24 * 60 * 2)) {
            return "+" + (mins / (24 * 60)).toFixed(0) + " día."
        } else if (mins < (24 * 60 * 31)) {
            return "+" + (mins / (24 * 60)).toFixed(0) + " días."
        } else {
            return "+" + (mins / (24 * 60 * 31)).toFixed(0) + " meses."
        }
    }
}