import { Component, OnInit, } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Nodo } from '../../shared/procesos/nodo';


@Component({
  selector: 'app-admin-procesos',
  templateUrl: './admin-procesos.component.html',
  styleUrls: ['./admin-procesos.component.scss']
})
export class AdminProcesosComponent implements OnInit {

  graficoObs: Observable<any>;
  nodos: Nodo[];

  xRect = 10;
  constructor(public db: AngularFireDatabase, private snackbar: MatSnackBar) { }

  ngOnInit() {

    this.graficoObs = this.db.list("procesos/abstracciones").snapshotChanges().pipe(
      map(changes => {
        let mapeado = changes.map(o => ({ key: o.payload.key, ...(<Nodo>o.payload.val()) }));
        console.log(mapeado)
        this.nodos = mapeado
        return mapeado;
      })
    );
  }

}
