import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { tap, map, take } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import firebase from '@firebase/app';
import * as consts from "../shared/constantes";

@Injectable({
  providedIn: 'root'
})
export class ResellerGuard implements CanActivate {
  constructor(public auth: AuthService, private router: Router, private db: AngularFireDatabase) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {


    let asd = this.auth.user.pipe(take(1),
      map(user => {
        //console.log("user auth", user)
        if (user && user.email && user.email.length > 3) {
          if (this.esAdmin(user)) {
            this.updateUser(user);
            return of(true).toPromise();
          } else {
            return this.revisarUsuario(user);
          }

        } else {
          return of(false).toPromise();
        }
      }),
      tap(loggedIn => {
        loggedIn.then(v => {
          if (v) {
            this.auth.setRutaAlaQueIba(null)
          } else {
            if (state && state.url && state.url !== "null") {
              this.auth.setRutaAlaQueIba(state.url)
            } else {
              this.auth.setRutaAlaQueIba(null)
            }
            this.router.navigate(['/mypopapp']);
          }
        }).catch(e => {
          if (state && state.url && state.url !== "null") {
            this.auth.setRutaAlaQueIba(state.url)
          } else {
            this.auth.setRutaAlaQueIba(null)
          }
          this.router.navigate(['/mypopapp']);
        })
      })
    )

    return asd.toPromise();

  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }

  updateUser(user) {
    let updates = {}
    updates["email"] = user.email;
    if (user.displayName)
      updates["displayName"] = user.displayName;
    if (user.metadata) {
      updates["metadata"] = user.metadata;
    }
    if (user.phoneNumber) {
      updates["phoneNumber"] = user.phoneNumber;
    }
    if (user.photoURL) {
      updates["photoURL"] = user.photoURL;
    }
    if (user.providerId) {
      updates["providerId"] = user.providerId;
    }

    this.db.object("usuariosuid/" + user.uid).update(updates);
  }

  async revisarUsuario(user): Promise<boolean> {
    let usuarioId = await this.db.object("usuariosuid/" + user.uid).query.once("value");
    let data = usuarioId.val();
    if (data && data.tipoUsuario === "reseller") {
      this.updateUser(user);
      return of(true).toPromise();
    } else {
      return of(false).toPromise();
    }

  }
}