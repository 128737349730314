export class StockPOJO{
    alertas:number[];
    categorias:string[];
    fecha:string;
    precios:number[];
    prodNombre:string[];
    prodStock:number[];
    medidas:string[];
}

export class Stock{
    //El indice de categoría es para poner color a cada producto
    indiceCategoria:number;
    nombre:string;
    categoria:string;
    medida:string;
    cantidad: number;
    alerta:number;
    precio:number;
    esCategoria:boolean;
    constructor(indiceCategoria:number,nombre:string, categoria:string, medida:string, cantidad: number, alerta:number, precio:number,esCategoria:boolean) {
        this.indiceCategoria=indiceCategoria;
        this.nombre=nombre;
        this.categoria=categoria;
        this.medida=medida;
        this.cantidad=cantidad;
        this.alerta=alerta;
        this.precio=precio;
        this.esCategoria=esCategoria;
     }
}

