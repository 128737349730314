import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/user';

@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.scss']
})
export class AdminUsuariosComponent implements OnInit, OnDestroy {

  botonConsultarDesactivado: boolean;
  buscando: boolean;
  driftVar: any;
  exito = false;
  fD = new Date();
  fechaDesde = this.convierteDateAStringPBuscar(this.fD);
  maxDate: Date = new Date();
  @ViewChild('picker') picker: MatDatepicker<Date>;
  subscripTionUsers: Subscription;
  users: Map<string, User>;
  constructor(private db: AngularFireDatabase) { }

  ngOnInit() {
    this.hideDrift(1);
  }
  ngOnDestroy() {
    try { this.subscripTionUsers.unsubscribe() } catch (e) { };
  }

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }

  actualizarBotonConsultar($event) {
    //se hizo click en algun filtro de cuentas o picker
    this.botonConsultarDesactivado = false;
  }

  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "-" + mes : "-0" + mes;

    const diaString = fecha.getDate() > 9 ? "-" + fecha.getDate() : "-0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  cambioFecha(picker: number, event: MatDatepickerInputEvent<Date>) {
    this.fechaDesde = this.convierteDateAStringPBuscar(event.value);
    this.actualizarBotonConsultar(event);
  }

  abrirPicker(intentos: number) {
    if (this.picker) {
      this.picker.open();
    } else {
      if (intentos < 5) {
        intentos++;
        setTimeout(() => this.abrirPicker(intentos), 1000)
      }
    }
  }

  buscar(fechaDesde) {
    this.buscando = true;
    this.botonConsultarDesactivado = true;
    this.users = new Map<string, User>();
    let usersFireList = this.db.list("users", ref => ref.orderByChild("lastActive")
      .startAt(fechaDesde));


    this.subscripTionUsers = usersFireList.snapshotChanges().subscribe(changes => {
      changes.map(u => {
        let user: User = <User>u.payload.val();
        this.procesaUsers({ key: u.payload.key, ...user });
      });
      this.exito = true;
      this.buscando = false;
      return this.users;
    });
  }

  procesaUsers(user: User) {
    this.users.set(user.key, user);
  }
}
