import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { LocalAdmin } from '../../shared/admin/local-admin';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';
import { SelectionModel } from '@angular/cdk/collections';
const packageJson = require('../../../../package.json');


import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/toPromise';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { Cuenta } from '../../shared/cuenta';

import { AuthService } from "../../core/auth.service";
import { CuentaService } from '../../services/cuenta.service';
import { Router } from '@angular/router';
import { FormAlert } from '../../shared/admin/formalert';
import { AdminCuentaService } from '../../services/admin-cuenta.service';
import { ResellerGuard } from 'src/app/guards/reseller.guard';

import * as consts from "../../shared/constantes";
import { LocalOnlineInterno, ModalConfPonlineComponent } from 'src/app/modales/modal-conf-ponline/modal-conf-ponline.component';
import { ModalConfVessiComponent } from 'src/app/modales/modal-conf-vessi/modal-conf-vessi.component';
import { ModalConfIngefacturaComponent } from 'src/app/modales/modal-conf-ingefactura/modal-conf-ingefactura.component';
import { ModalConfFacturapiComponent } from 'src/app/modales/modal-conf-facturapi/modal-conf-facturapi.component';
import { ModalPricingComponent } from 'src/app/modales/modal-pricing/modal-pricing.component';
import { ModalVincularCentralizadoComponent } from 'src/app/modales/modal-vincular-centralizado/modal-vincular-centralizado.component';
import { DbFSService } from 'src/app/services/db-fs.service';
import { ModalConfContabiliumComponent } from 'src/app/modales/modal-conf-contabilium/modal-conf-contabilium.component';
import { ModalAutomatizarSyncManagerComponent } from 'src/app/modales/modal-automatizar-sync-manager/modal-automatizar-sync-manager.component';
import { ModalEnvioMailPrecioComponent } from 'src/app/modales/modal-envio-mail-precio/modal-envio-mail-precio.component';
import { ModalMpComponent } from 'src/app/modales/modal-mp/modal-mp.component';
import { ModalIntegrarMpComponent } from 'src/app/modales/modal-integrar-mp/modal-integrar-mp.component';
import { ModalFullprimeComponent } from 'src/app/modales/modal-fullprime/modal-fullprime.component';
import { ModalEmailAumentoChileComponent } from 'src/app/modales/modal-email-aumento-chile/modal-email-aumento-chile.component';
import { ModalConfigFactPopappChiComponent } from 'src/app/modales/modal-config-fact-popapp-chi/modal-config-fact-popapp-chi.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-cuentas',
  templateUrl: './admin-cuentas.component.html',
  styleUrls: ['./admin-cuentas.component.scss']
})
export class AdminCuentasComponent implements OnInit, OnDestroy {

  version = packageJson.version;

  environment = environment;

  cuentasRef;

  cotizacionUF;
  cotizacionUSDCLP: number;

  cantLeidas: number = 0;

  cuentas: CuentaAdmin[];
  cuentasMap: Map<String, CuentaAdmin>;
  locales: Map<String, LocalAdmin>;
  servidores: ServidorAdmin[] = [];

  fecha: any;
  //selectedOptions: ServidorAdmin[];
  @ViewChild(MatSort) sort: MatSort;

  actualizarEnVivo: boolean = false;

  email: string;
  //datos tabla
  servidorMenu: any;
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };
  migrando: boolean;
  servidorDestino: ServidorAdmin;
  tiendasAMigrar: LocalOnlineInterno[];
  servidorOrigen: ServidorAdmin;

  macsDuplicadas: boolean;
  macsDuplicadasMap: Map<string, ServidorAdmin[]>;
  macsDuplicadasNumber: number;

  keysDuplicadas: boolean;
  keysDuplicadasMap: Map<string, ServidorAdmin[]>;
  keysDuplicadasNumber: number;
  keysLocalesMap: Map<string, ServidorAdmin[]>;

  openSubMenu(event, s: ServidorAdmin, input: string) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.menu.menuData = { 'item': s };
    this.menu.menu.focusFirstItem('mouse');
    this.menu.openMenu();
  }

  async select(event, servidorDestino: ServidorAdmin) {
    if (this.migrando) {
      this.migrando = false;
      this.snackbar.open('Migrando...')._dismissAfter(2000);
      this.servidorDestino = servidorDestino;
      event.stopPropagation();
      const confirmado = confirm('Migrar tiendas a este servidor?');
      if (confirmado) {
        let cantidadTiendasMigradas = 0;
        let msjError = "";
        for (const t of this.tiendasAMigrar) {
          let headers = new Headers({ 'Content-Type': 'application/json' });
          let options = new RequestOptions({ headers: headers });
          const body = {
            "origen": t.origen,
            "idStore": t.idStore,
            "idCuentaOrigen": this.servidorOrigen.idCuenta,
            "idCuentaDestino": this.servidorDestino.idCuenta,
            "idLocalOrigen": this.servidorOrigen.idLocal,
            "idLocalDestino": this.servidorDestino.idLocal,
            "idMacOrigen": this.servidorOrigen.idMac,
            "idMacDestino": this.servidorDestino.idMac,
            "keyLocalOrigen": this.servidorOrigen.keyPedidosOnline.key,
            "keyLocalDestino": this.servidorDestino.keyPedidosOnline.key,
          };

          console.log(body);
          // await this.http.post("https://6b0b-131-72-2-50.ngrok.io/popappowner/us-central1/basesql/migrar_tiendas/", body, options).toPromise()
          await this.http.post(environment.urls.functions + "/basesql/migrar_tiendas/", body, options).toPromise()
            .then(res => {
              cantidadTiendasMigradas++;
              console.log(res.json());
            })
            .catch(error => {
              if (error && error._body) {
                const body = JSON.parse(error._body);
                console.error("Error al migrar tiendas:", body.msg);
                msjError += " " + body.msg;
              } else {
                console.error("Error al migrar tiendas:", error._body || error);
              }
            })
        }

        this.snackbar.open('Migrado con éxito ' + cantidadTiendasMigradas + ' tiendas' + (msjError ? ":" + msjError : ""))._dismissAfter(4000);
      } else {

      }
    } else {
      this.selection.toggle(servidorDestino);
    }
  }

  nuevoLinkPago(s: ServidorAdmin) {
    this.adminCuentaService.copyToClipboard(environment.urls.mypopapp + "/pagar?i=" + s.idCuenta);
    this.openSnackBar("Copiado al portapapeles", "");
  }
  esAdmin(user) {
    return consts.esAdmin(user);
  }

  eliminarServidor(s: ServidorAdmin) {
    this.db.object("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac).set(null)
      .then(_ => this.openSnackBar("Exito", "OK"))
      .catch(e => {
        this.openSnackBar("No se pudo", "Error");
        console.error("Error al borrar servidor ", { s, e });
      });

  }

  async separarManagerDeLaCuenta(s: ServidorAdmin) {
    try {
      let c: CuentaAdmin = this.cuentasMap.get(s.idCuenta);
      if (c && c.locales) {
        let cantLocales = 0;
        let cantServidoresEnElLocalDelManagerASeparar = 0;
        for (let idLocal in c.locales) {
          cantLocales++;
          if (idLocal === s.idLocal) {
            for (let idServidor in c.locales[idLocal].servidores) {
              cantServidoresEnElLocalDelManagerASeparar++;
            }
          }
        }

        let ref = this.db.object('cuentas').query.ref.push();
        const idNuevo = ref.key;

        const cuentaNueva = {
          fechaCreacion: c.fechaCreacion ? c.fechaCreacion : '',
          locales: {},
          nombreCuenta: 'Separado ' + (c.nombreCuenta ? c.nombreCuenta : ''),
        };
        let mapUpdates = {};
        if (cantServidoresEnElLocalDelManagerASeparar > 1) {
          // si el manager a separar tiene mas servidores en ese local, entonces hay que separar solo el servidor
          cuentaNueva.locales[idNuevo] = {
            fechaCreacion: c.locales[s.idLocal].fechaCreacion ? c.locales[s.idLocal].fechaCreacion : '',
            nombreLocal: c.locales[s.idLocal].nombreLocal ? c.locales[s.idLocal].nombreLocal : '',
            servidores: {}
          }
          cuentaNueva.locales[idNuevo].servidores[s.idMac] = c.locales[s.idLocal].servidores[s.idMac];
          mapUpdates['cuentas/' + s.idCuenta + '/locales/' + s.idLocal + '/servidores/' + s.idMac] = null;
          mapUpdates['cuentas/' + idNuevo] = JSON.parse(JSON.stringify(cuentaNueva));
          mapUpdates['macsPasadas/' + s.idMac + '/idCuenta'] = idNuevo;
          mapUpdates['macsPasadas/' + s.idMac + '/idLocal'] = idNuevo;

          await this.db.object('/').update(mapUpdates);
          this.snackbar.open('Manager separado exitosamente', '')._dismissAfter(2000);

        } else if (cantServidoresEnElLocalDelManagerASeparar === 1 && cantLocales > 1) {
          // si el manager a separar es el unico del local, entonces hay que separar el local, siempre que haya mas de un local
          cuentaNueva.locales[idNuevo] = c.locales[s.idLocal];

          // cuentaNueva.locales[idNuevo].servidores[s.idMac] = c.locales[s.idLocal].servidores[s.idMac];
          mapUpdates['cuentas/' + s.idCuenta + '/locales/' + s.idLocal] = null;
          mapUpdates['cuentas/' + idNuevo] = JSON.parse(JSON.stringify(cuentaNueva));
          mapUpdates['macsPasadas/' + s.idMac + '/idCuenta'] = idNuevo;
          mapUpdates['macsPasadas/' + s.idMac + '/idLocal'] = idNuevo;

          await this.db.object('/').update(mapUpdates);
          this.snackbar.open('Local separado exitosamente', '')._dismissAfter(2000);
        } else {
          // no se puede separar
          this.snackbar.open('No se puede separar', 'cantLocales: ' + cantLocales + '. cantServidoresDelLocal: ' + cantServidoresEnElLocalDelManagerASeparar)._dismissAfter(2000);
        }
      } else {
        this.snackbar.open('No hay locales para esa cuenta', '')._dismissAfter(2000);
      }
    } catch (e) {
      console.error(e);
      this.snackbar.open('No se pudo separar el manager', '')._dismissAfter(2000);
    }

  }

  async managerFicticio() {
    const nombrePrompt = prompt('Nombre de la cuenta?', '');

    if (nombrePrompt && nombrePrompt.trim() && nombrePrompt.trim().length > 0) {
      try {

        const nombre = nombrePrompt.trim();
        let ref = this.db.object("cuentas").query.ref.push();
        const id = ref.key;
        const mapUpdates = {};
        const date = new Date();
        const fecha = (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' +
          ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' +
          date.getFullYear();
        mapUpdates['cuentas/' + id] = {
          fechaCreacion: fecha,
          locales: {},
          nombreCuenta: nombre
        };

        mapUpdates['cuentas/' + id]['locales'][id] = {
          fechaCreacion: fecha,
          servidores: {},
          nombreLocal: nombre,
          numeroLocal: '900'
        };

        mapUpdates['cuentas/' + id]['locales'][id]['servidores'][id] = {
          fechaInstalacion: fecha,
          nombreServidor: nombre,
          numeroLocal: '900'
        };


        mapUpdates['macsPasadas/' + id] = {
          idCuenta: id,
          idLocal: id
        };

        console.log(mapUpdates);
        await this.db.object('/').update(mapUpdates);
        this.snackbar.open('Manager creado', '')._dismissAfter(2000);
      } catch (e) {
        console.error(e);
        this.snackbar.open('Error al crear manager', 'Revisar Consola')._dismissAfter(2000);
      }
    }
  }
  /*onContextMenuAction1(item: ServidorAdmin) {
    console.log("qwe", item)
    alert(`Click on Action 1 for ${item.nombreServidor}`);
  }*/

  /*openSubMenu(event, s: ServidorAdmin) {
    console.log("ASD", s)
    this.menu.openMenu();
    return false;
    //event.stopPropagation();
    //return true;
  }*/

  displayedColumns: string[] = ['select', 'nombreCuenta', 'nombreLocal', 'nombreServidor',
    'numeroLocal',//5


    'pagoHasta',
    "diaReal",
    'vencimiento',
    'dias',
    'fechaUltimoPago',
    "fechaUltimaFacturacion",
    "cuitORut",
    "emailpago",
    //13

    'pais',
    'ciudad',
    'direccionyciudad',
    //16

    'moneda',
    'importe',
    'pagaIva',
    'showPagar',//20

    'idMac',
    'tester',
    'fix',
    'idCuenta',
    'idLocal',//25

    'whatsapp',
    'contacto',//27

    'fiscal',
    "fearg",
    "rut",
    'estado',
    'planN',
    'fechaInstalacionyyyyMMddd',
    "fechaConversion",
    'importeQDeberia',
    'detalleImporte',
    "fechaFinPruebaFull",//37

    'pya',
    "lat",
    "lng",
    "addImporte",
    "marca",
    "marcaFotoURL",
    "marcaFotoChicaURL",
    "tipoUsuario"//45
    ,
    "versionActual",
    "versionDisponible",
    "network_mode",
    "fechaActualizado",
    "fechaUltimoCheck",
    "tag",
    'pedidosOnline',
    "txMypopapp",
    "fechaEstadisticasWeb",
    "pagoAlta",
    "primerPago",
    "syncManager",
    "fechaAlta",
    "fechaBaja",

    "hub",
    "fullweb",
    "fechaingreso",
    "fechaclientelisto",
    "duenix",
    "enUso",
    "tag2",
    "pyaV1"

    //66
  ];;

  columnsToDisplay: string[] = this.displayedColumns.slice(0, 5);

  dataSource: MatTableDataSource<ServidorAdmin> = new MatTableDataSource(this.servidores);

  filtroTodasColumnas: boolean = false;

  filtVence: boolean = false;
  filtPais: boolean = false;
  filtPago: boolean = false;
  filtDev: boolean = false;
  filtWapp: boolean = false;
  filtOtros: boolean = false;
  filtStock: boolean = false;

  filtroActual: string = "a";
  filtroActual2: string = "";
  buscandoActual: string;
  filtroClientesActivado: boolean = true;

  subsCriptionCuentasVivo: Subscription;
  suscripUsuarioConfig: Subscription;

  columnasFiltros = new Map<string, { name: string, activo: boolean }>();
  uid: string;

  reseller: boolean;
  marca: string;
  actualizaFiltroClientesActivos() {
    this.filtroClientesActivado = !this.filtroClientesActivado;
    this.applyFilter(this.filtroActual);
    this.filtroClientesActivado = !this.filtroClientesActivado;
  }

  actualizaColumnas(value: number, filtro: boolean) {
    if (value == 999) {
      if (filtro) {
        //ocultar columnas
        this.columnsToDisplay = this.displayedColumns.slice(0, 5);
        this.filtVence = false;
        this.filtPais = false;
        this.filtPago = false;
        this.filtDev = false;
        this.filtWapp = false;
        this.filtOtros = false;
        this.filtStock = false;
      } else {
        this.columnsToDisplay = this.displayedColumns.slice(0, 5);
        for (let i = 5; i < this.displayedColumns.length; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }
        this.filtVence = true;
        this.filtPais = true;
        this.filtPago = true;
        this.filtDev = true;
        this.filtWapp = true;
        this.filtOtros = true;
        this.filtStock = true;
      }
    }
    if (filtro) {
      //ocultar columnas
      this.columnsToDisplay = this.displayedColumns.slice(0, 5);
      this.filtVence = false;
      this.filtPais = false;
      this.filtPago = false;
      this.filtDev = false;
      this.filtWapp = false;
      this.filtOtros = false;
      this.filtStock = false;

      if (value == 0) {
        this.filtVence = true;
      } else if (value == 1) {
        this.filtPais = true;
      } else if (value == 2) {
        this.filtPago = true;
      } else if (value == 3) {
        this.filtDev = true;
      } else if (value == 4) {
        this.filtWapp = true;
      } else if (value == 5) {
        this.filtOtros = true;
      } else if (value == 6) {
        this.filtStock = true;
      }
    } else {
      //mostrar columnas correspondientes
      if (value == 0) {
        for (let i = 5; i < 13; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }
      } else if (value == 1) {
        for (let i = 13; i < 16; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }
      } else if (value == 2) {
        for (let i = 16; i < 20; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }
      } else if (value == 3) {
        for (let i = 20; i < 25; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }
      } else if (value == 4) {
        for (let i = 25; i < 27; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }
      } else if (value == 5) {
        for (let i = 27; i < 37; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }
      }
      else if (value == 6) {
        this.columnsToDisplay.push(this.displayedColumns[37]);
        this.columnsToDisplay.push(this.displayedColumns[38]);
        this.columnsToDisplay.push(this.displayedColumns[39]);
        this.columnsToDisplay.push(this.displayedColumns[40]);
        this.columnsToDisplay.push(this.displayedColumns[41]);
        this.columnsToDisplay.push(this.displayedColumns[42]);
        this.columnsToDisplay.push(this.displayedColumns[43]);
        this.columnsToDisplay.push(this.displayedColumns[44]);
        this.columnsToDisplay.push(this.displayedColumns[45]);
        this.columnsToDisplay.push(this.displayedColumns[46]);
        this.columnsToDisplay.push(this.displayedColumns[47]);
        this.columnsToDisplay.push(this.displayedColumns[48]);
        this.columnsToDisplay.push(this.displayedColumns[49]);
        this.columnsToDisplay.push(this.displayedColumns[50]);
        this.columnsToDisplay.push(this.displayedColumns[51]);
        this.columnsToDisplay.push(this.displayedColumns[52]);
        this.columnsToDisplay.push(this.displayedColumns[53]);
        this.columnsToDisplay.push(this.displayedColumns[54]);
        this.columnsToDisplay.push(this.displayedColumns[55]);
        this.columnsToDisplay.push(this.displayedColumns[56]);
        this.columnsToDisplay.push(this.displayedColumns[57]);
        this.columnsToDisplay.push(this.displayedColumns[58]);
        this.columnsToDisplay.push(this.displayedColumns[59]);
        this.columnsToDisplay.push(this.displayedColumns[60]);
        this.columnsToDisplay.push(this.displayedColumns[61]);
        this.columnsToDisplay.push(this.displayedColumns[62]);
        this.columnsToDisplay.push(this.displayedColumns[63]);
        this.columnsToDisplay.push(this.displayedColumns[64]);
        this.columnsToDisplay.push(this.displayedColumns[65]);
      }
    }
  }

  applyFilter(filterValue: string) {
    if (this.primeraVez) {
      this.updateTabla(true);
    } else {
      this.primeraVez = false;
    }
    this.dataSource.filter = "";

    let filtro = filterValue.trim().toLowerCase();
    if (!filtro || filtro.length == 0) {
      filtro = "a";
      this.filtroActual2 = "";
      this.buscandoActual = "";
    } else {
      if (filtro === "a") {
        this.filtroActual2 = "";
        this.buscandoActual = "";
      } else {
        this.filtroActual2 = filtro;
        this.buscandoActual = filtro;
      }
    }

    this.filtroActual = filtro;
    this.dataSource.filter = filtro;

    this.dataSource.sort = this.sort;

  }

  nuevasColumnas: string[] = ["marca", "tipoUsuario", "marcaFotoURL", "marcaFotoChicaURL", "fechaUltimaFacturacion",
    "cuitORut",
    "emailpago",
    "enUso",
    "syncManager",
    "fechaAlta",
    "fechaBaja",
    "network_mode",

    "hub",
    "fullweb",
    "fechaingreso",
    "fechaclientelisto",
    "duenix",
    "tag2",
    'direccionyciudad',
    "pyaV1"
  ];

  actualizarColumnasDesdeFiltro(valoresDesdeFB) {
    if (valoresDesdeFB) {
      for (let i in valoresDesdeFB) {
        this.columnasFiltros.set(i, { name: valoresDesdeFB[i].name, activo: valoresDesdeFB[i].activo });
        if (this.nuevasColumnas && this.nuevasColumnas.length > 0 && this.nuevasColumnas.includes(i)) {
          let num = this.nuevasColumnas.indexOf(i);
          if (num >= 0) {
            this.nuevasColumnas.splice(num, 1);
          }
        }
      }
      if (this.nuevasColumnas && this.nuevasColumnas.length > 0) {
        for (let nuevaColumna of this.nuevasColumnas) {
          this.columnasFiltros.set(nuevaColumna, { name: nuevaColumna, activo: false });
        }
      }
    } else {
      for (let col of this.displayedColumns) {
        this.columnasFiltros.set(col, { name: col, activo: false })
      }
    }
    if (this.uid) {
      let mapUpdates = {}
      for (let mu of this.columnasFiltros.values()) {
        mapUpdates[mu.name] = mu
      }
      this.db.object("usuariosuid/" + this.uid + "/filtrosColumnasAdmin").update(mapUpdates).then(v => {
        //console.log("Exito", mapUpdates)
      }).catch(e => {

      });
      this.columnsToDisplay = [];
      for (let c of this.displayedColumns) {
        if (this.columnasFiltros.get(c) ? this.columnasFiltros.get(c).activo : true) {
          this.columnsToDisplay.push(c)
        }
      }
    }
    this.mostrarFiltroColumnas.next(true);

  }
  mostrarFiltroColumnas: BehaviorSubject<boolean> = new BehaviorSubject(false);

  selection = new SelectionModel<ServidorAdmin>(true, []);


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  primeraVez: boolean;
  driftVar: any;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }

  user: firebase.User;
  msjoculto: string;
  ngOnInit() {
    this.hideDrift(1);
    this.primeraVez = true;
    this.dataSource.sort = this.sort;
    this.leerCotizacionUF();
    this.db.object("zzzNoBorrar").query.once("value").then(v => {
      if (v && v.val()) {
        this.msjoculto = v.val();
      }
    })

    this.afAuth.user.subscribe(user => {
      if (user && user.email) {
        if (this.esAdmin(user)) {
          this.user = user;
          this.continuaConInit(user);
        } else {
          this.resellerGuard.canActivate(null, null).then(v => {
            this.reseller = v;
            if (this.reseller) {
              this.continuaConInit(user)
            }
          })
        }
      }
    });
  }

  continuaConInit(user: firebase.User) {
    this.email = user.email;
    //leemos las cuentas
    // Use snapshotChanges().map() to store the key
    this.uid = user.uid;
    //console.log(user.uid)
    this.suscripUsuarioConfig = this.db.object("usuariosuid/" + user.uid).snapshotChanges().subscribe(v => {
      let usuarioUid = v.payload.val();
      if (usuarioUid && usuarioUid["marca"]) {
        this.marca = usuarioUid["marca"];
      }
      let valores
      if (usuarioUid && usuarioUid["filtrosColumnasAdmin"]) {
        valores = usuarioUid["filtrosColumnasAdmin"];
      }
      if (!valores) {
        //console.log("grabando valores");
      } else {
      }
      this.actualizarColumnasDesdeFiltro(valores);
    })
    this.leerCuentas();
  }

  leerCuentas() {
    if (this.actualizarEnVivo) {
      //console.log("leyendo en vivo")
      let itemsRef = this.db.object('cuentas');
      // this.db.list('cuentas').snapshotChanges() asd
      try {
        if (this.subsCriptionCuentasVivo) {
          this.subsCriptionCuentasVivo.unsubscribe();
        }
      } catch (e) { }

      this.subsCriptionCuentasVivo = itemsRef.snapshotChanges().subscribe(v => {
        let mapeado: any = v.payload.val();
        for (let i in mapeado) {
          mapeado[i].key = i;
        }
        this.sacaLocalesYServidores(mapeado);
        this.dataSource.sort = this.sort;
      });
    } else {
      // console.log("leyendo en pausa")
      try {
        if (this.subsCriptionCuentasVivo) {
          this.subsCriptionCuentasVivo.unsubscribe();
        }
      } catch (e) { }
      this.db.object("cuentas").query.once("value").then(v => {
        let mapeado = v.val();
        for (let i in mapeado) {
          mapeado[i].key = i;
        }
        this.sacaLocalesYServidores(mapeado);
        this.dataSource.sort = this.sort;
      })
    }

  }

  ngOnDestroy() {
    try {
      if (this.subsCriptionCuentasVivo) {
        this.subsCriptionCuentasVivo.unsubscribe();
      }
    } catch (e) { }

    try {
      if (this.suscripUsuarioConfig) {
        this.suscripUsuarioConfig.unsubscribe();
      }
    } catch (e) { }


  }


  deshacerOperacion(s: ServidorAdmin) {
    this.adminCuentaService.deshacerOperacion(s, this.email)
      .then(res => { this.openSnackBar(res, ""); })
      .catch(e => { this.openSnackBar(e, "AVISAR GAMR"); })
  }

  verMovimientos(s: ServidorAdmin) {
    this.router.navigateByUrl("/mypopapp/movimientosAdmin/" + s.idCuenta);
  }

  constructor(public router: Router, public afAuth: AngularFireAuth,
    public cuentaService: CuentaService, public db: AngularFireDatabase, private http: Http, public authService: AuthService, public adminCuentaService: AdminCuentaService, public _snackBar: MatSnackBar, private resellerGuard: ResellerGuard
    , private snackbar: MatSnackBar, public dialog: MatDialog,
    private dbFirestore: DbFSService) {
  }

  selectCuenta(cuentaAdmin: ServidorAdmin) {
    let cuenta: Cuenta = new Cuenta();
    cuenta.id = cuentaAdmin.idMac;
    cuenta.name = cuentaAdmin.nombreCuenta;
    this.authService.changeCuenta(cuenta);
  }

  sortHeader(index: number) {
    //console.log("click");
    this.dataSource.sort = this.sort;
  }


  leerCotizacionUF() {
    const url = environment.urls.functions + "/cotizacion/cotizacion/UF/CLP";
    // const url = "https://mindicador.cl/api/uf/";
    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.serie && body.serie[0] && body.serie[0].valor) {
          this.cotizacionUF = Math.floor(body.serie[0].valor);
        } else if (body && body.data && body.data.cotizacion) {
          this.cotizacionUF = body.data.cotizacion;
        }
      })
      .catch(this.handleErrorObservable);

  }
  leerCotizacionUSDCLP() {
    const url = environment.urls.functions + "/cotizacion/cotizacion/USD/CLP";
    // const url = "http://localhost:5000/popappowner/us-central1/cotizacion/cotizacion/USD/CLP";
    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.data && body.data.cotizacion) {
          this.cotizacionUSDCLP = +body.data.cotizacion;
        }
      })
      .catch(this.handleErrorObservable);

  }

  private handleErrorObservable(error: Response | any) {
    console.error("Erroooor FE", error.message || error);
    return Observable.throw(error.message || error);
  }

  idMPDeliGuru = "251579662";
  marcaMPDeliGuru = "Deli Gurú";

  async clickPagar(s: ServidorAdmin, email: string, cantMeses: number) {
    console.log(cantMeses);
    let idMP: string;
    let pais: string;
    if (!s.pais) {
      pais = "ARG";
    }
    let mostrarSoloPayPal = false;
    if (s.pais == "ARG" || s.pais == "AR") {
      if (s.marca === this.marcaMPDeliGuru) {
        idMP = this.idMPDeliGuru;
      } else {
        idMP = "1392845136";
      }
    } else if (s.pais == "CHI" || s.pais == "CL") {
      idMP = "349794797";
    } else if (s.pais == "PER" || s.pais == "PE") {
      mostrarSoloPayPal = true;
    } else if (s.pais == "ARGPRUEBA") {
      idMP = "350425595";
    } else if (s.pais == "CHIPRUEBA") {
      idMP = "350419662";
    } else {
      mostrarSoloPayPal = true;
    }


    let c: CuentaAdmin = this.cuentasMap.get(s.idCuenta);
    this.cuentaService.mensajeAVerificarAntesDeCobrar(c, cantMeses);
    if (!c.error || c.error.length == 0) {
      if (c.moneda && c.pagoHasta) {
        if (mostrarSoloPayPal) {
          //this.enviarEmailPayPal(); con cant de meses prox fecha, importe mensual y total y moneda
          //let urlServidorMail = "http://ec2-52-14-127-152.us-east-2.compute.amazonaws.com:3000/fcm/mails/paypal";

          let cuerpoDeMail = "email: " + email + "\n" + cantMeses + " meses\n" +
            c.moneda + " " + (c.totalApagarSinIva);
          if (c.totalApagarConIva > c.totalApagarSinIva) {
            cuerpoDeMail += " +" + (c.totalApagarConIva - c.totalApagarSinIva) + " IVA " +
              " = " + c.moneda + " " + c.totalApagarConIva;
          }

          /*if (this.cantMesesAPagar == 10) {
            cuerpoDeMail += "\nProx. Vencimiento: " + this.fechaProxAnioVenc.getDate() + "/" + this.fechaProxAnioVenc.getMonth() + 1 + "/" + this.fechaProxAnioVenc.getFullYear();
          } else {*/
          cuerpoDeMail += "\nProx. Vencimiento: " + c.nuevaFechaVenc;
          //}

          cuerpoDeMail += "\n" + c.nombreCuenta + " (" + c.id + ")";

          this.adminCuentaService.copyToClipboard(cuerpoDeMail);
          this.openSnackBar("Msj para Facu copiado al portapeles", "");

        } else {
          let urlAPagar = environment.urls.escuchador + "/mporden/" + idMP + "/pos/" + c.id;
          // let urlAPagar = "http://localhost:3000/mporden/" + idMP + "/pos/" + c.id;
          //let urlAPagar = "http://192.168.10.113:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
          let body = { items: [], email_payer: '' };

          let totalMensual: number = c.totalApagarConIva;

          if (c.moneda === 'UF' && !this.cotizacionUF) {
            await this.leerCotizacionUF();
          }
          if ((s.pais == "CHI" || s.pais == "CL") && c.moneda === 'USD') {
            if (!this.cotizacionUSDCLP) {
              await this.leerCotizacionUSDCLP();
            }
            if (!this.cotizacionUSDCLP) {
              let up = totalMensual / cantMeses;
              up = +(up.toFixed());
              body.items[0] = {
                "currency_id": c.moneda,
                "unit_price": up,
                "quantity": cantMeses

              };
            } else {
              let up = totalMensual * this.cotizacionUSDCLP / cantMeses;
              up = +(up.toFixed());
              body.items[0] = {
                "currency_id": c.moneda,
                "unit_price": up,
                "quantity": cantMeses
              };
              body.items[0].cotiz = this.cotizacionUSDCLP;

            }

          } else if (c.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
            let up = totalMensual * this.cotizacionUF / cantMeses;
            up = +(up.toFixed());
            body.items[0] = {
              "currency_id": c.moneda,
              "unit_price": up,
              "quantity": cantMeses
            };
          } else {
            let up = totalMensual / cantMeses;
            up = +(up.toFixed());
            body.items[0] = {
              "currency_id": c.moneda,
              "unit_price": up,
              "quantity": cantMeses

            };
          }

          if (c.moneda && c.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
            body.items[0].cotiz = this.cotizacionUF;
          }

          body.email_payer = email;

          this.authService.setDataAPagar(urlAPagar, body, c.id, c.ultimoPago, c.moneda, c.pais);

          this.router.navigateByUrl('/mypopapp/micuenta/pagar');
        }
      } else {
        console.log(c.nombreCuenta + " no tiene todos los atributos (moneda, pagoHasta): ", c);
        this.openSnackBar(c.nombreCuenta + " no tiene todos los atributos (moneda, pagoHasta)", c.nombreCuenta);
      }
    } else {
      console.log("Error cuenta " + c.nombreCuenta + ": " + c.error);
      this.openSnackBar("Error cuenta " + c.nombreCuenta + ": ", c.error);
    }
  }

  onRightClick(input: string, element: ServidorAdmin) {
    if (input == "cobrar") {
      //leer mails de ese usuario

      this.cuentaService.actualizaPagoHastaSiEsLeadOClienteViejo(element.cuotasPendientes, element.primerPago, element.pagoHasta, element.idCuenta, element.idLocal, element.idMac)
        .then(seActualizaronLosDatos => {
          if (seActualizaronLosDatos) {
            this._snackBar.open("Se actualizo la fecha de pago Hasta", "Vuelve a generar el link", {
              duration: 5000,
            });
            return;
          } else {
            //proponer un promt con el 1ero por defecto encontrado
            let email = prompt("Generar link de pago para " + element.nombreCuenta + ":", "p@p");
            if (email != null && email != "") {
              let cantMesesAPagarA = 1;
              if (element.tag && element.tag.includes("planal1D")) {
                cantMesesAPagarA = 2;
              }
              let cantMeses = prompt("Cantidad de meses a pagar", "" + cantMesesAPagarA);
              if (cantMeses != null) {
                //generar link de pago
                this.clickPagar(element, email, +cantMeses);
              }
            }
            return false;
          }
        })
    } else if (input == "rut") {
      if (element.idMac && element.rut && element.rut.length > 0) {
        console.log("probando contra vessi");
        this.cuentaService.getUrl(environment.urls.escuchador + "/factvessi/contribuyentes/info/" + element.idMac + "/" + element.rut)
          .then(respuesta => console.log("ok", respuesta))
          .catch(error => console.log("error"));
      } else {
        console.log("mac o rut nulos", element);
      }
      return false;
    } else if (input == "fearg") {
      if (element.fearg) {
        this.searchPuntosDeVenta(element);
      } else {
        if (this.reseller) {
          return this.soloDisponibleParaAdmins();
        }
        let email = prompt("email", "");
        if (email != null) {
          let password = prompt("apikey", "");
          if (password != null) {
            let cuit = prompt("cuit", "");
            if (cuit != null) {
              let fearg = {};
              fearg["email"] = email;
              fearg["password"] = password;
              fearg["cuit"] = cuit;
              this.adminCuentaService.validarYEscribirEnFBServidor(fearg, false, input, [element])
                .then(res => { this.openSnackBar(res, ""); })
                .catch(e => { this.openSnackBar(e, "AVISAR GAMR"); })
            }
          }
        }
      }
      return false;
    } else if (input == "feargFactPrueba") {
      if (this.reseller) {
        return this.soloDisponibleParaAdmins();
      }
      this.facturaELectronicaPrueba(element);
    } else if (input === 'datosFacturacion') {
      const dialogRef = this.dialog.open(ModalConfigFactPopappChiComponent, {
        width: '85%',
        data: { s: element }
      });
    }
  }

  actualizaAMacsDuplicadas() {
    this.macsDuplicadas = !this.macsDuplicadas;

    if (this.macsDuplicadas && this.macsDuplicadasNumber) {
      console.log(this.macsDuplicadasMap);
      console.log(this.macsDuplicadasMap.keys());
      console.log(this.macsDuplicadasMap.keys().next().value);

      this.applyFilter(this.macsDuplicadasMap.keys().next().value);
    } else {
      this.applyFilter('');
    }
  }

  async revisaKeysEnFS() {
    const confirmado = confirm('Este proceso se puede demorar varios minutos. Continuar?');
    if (!confirmado) {
      return;
    }
    let i = 0;
    for (const key of this.keysLocalesMap.keys()) {
      i++;

      const ss = this.keysLocalesMap.get(key);
      if (ss.length === 1) {
        const servidor = ss[0];
        const datos: any = (await this.dbFirestore.docOnce('confLocalPO/' + key)).data();
        if (!(datos && datos.idCuenta && datos.idLocal && datos.idCuenta === servidor.idCuenta && datos.idLocal === servidor.idLocal && datos.idMac && datos.idMac === servidor.idMac)) {
          await this.dbFirestore.updateAt('confLocalPO/' + key, { idCuenta: servidor.idCuenta, idLocal: servidor.idLocal, idMac: servidor.idMac });
          console.log('updates: ' + key + '. ' + JSON.stringify({ idCuenta: servidor.idCuenta, idLocal: servidor.idLocal, idMac: servidor.idMac }));
        } else {
          console.log(i);
        }
      }
    }
  }

  actualizaAkeysDuplicadas() {
    this.keysDuplicadas = !this.keysDuplicadas;

    if (this.keysDuplicadas && this.keysDuplicadasNumber) {
      console.log(this.keysDuplicadasMap);
      console.log(this.keysDuplicadasMap.keys());
      console.log(this.keysDuplicadasMap.keys().next().value);

      this.applyFilter('"key":"' + this.keysDuplicadasMap.keys().next().value);
    } else {
      this.applyFilter('');
    }
  }

  // creaContactoHubspotPrueba() {
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   let options = new RequestOptions({ headers: headers });
  //   let jsonContacto = {};
  //   jsonContacto["properties"] = [];
  //   const email = "gmarin@popapp.io";
  //   jsonContacto["properties"].push({
  //     "property": "firstname",
  //     "value": "GErman Probando2"
  //   })
  //   jsonContacto["properties"].push({
  //     "property": "phone",
  //     "value": "+5492645122088"
  //   })
  //   jsonContacto["properties"].push({
  //     "property": "country",
  //     "value": "Argentina2"
  //   })
  //   jsonContacto["properties"].push({
  //     "property": "company",
  //     "value": "Popapp2"
  //   })

  //   return this.http.post("environment.urls.functionsapi/createOrUpdateContactHubspot/" + email, jsonContacto, options).toPromise()
  //     .then(res => console.log(res.json()))
  //     .catch(error => console.error("Error Hubspot", error.message || error));
  // }
  facturaELectronicaPrueba(s: ServidorAdmin) {

    //let fechaEmisionAux = prompt("Fecha de hoy", "19/02/2020");
    let facturaABC = prompt("FACTURA (FCA, FCB, FCC, NCA, NCB, NCC) no usar A pues cliente es CF", "FCB");

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    // const url = "http://localhost:5000/popappowner/us-central1/api/fearg/generaFactura";

    const url = environment.urls.functions + "/api/fearg/generaFactura";
    const body = {
      "idCuenta": s.idCuenta,
      "idLocal": s.idLocal,
      "idMac": s.idMac,
      "cliente": {

        ///Si tengo el idClienteContabilium no enviar nada m�s en cliente,
        //"idClienteContabilium": 1, // si no lo tengo ni lo envip y si mando los restantes campos obligatorios menos email

        "tipoDoc": "DNI", //CUIT o DNI
        "nroDoc": 999994, // si es dni q venga el iddel cliente y si es CUIT el CUIT sin guiones pues es un int
        "razonSocial": "Consumidor Final Prueba",
        //"domicilio": "Calle wallaby 42 Sydney Prueba", //Podria venir " "
        //"email": "dory@buscando.com",//no es obligatorio
        "condicionIva": "CF" //CF, MO , RI, EX
      },
      "pedido": {
        "items": [
          {
            "cantidad": 1,
            "concepto": "Prueba",
            "precioUnitario": 10,
            "Iva": 21,
            "Bonificacion": 9
          }
        ],
        "fechaEmision": "19/02/2020",
        "tipoFc": facturaABC
      }
    };

    return this.http.post(url, body, options).toPromise()
      .then(resp => console.log(resp.json()))
      .catch(this.handleErrorObservable);
  }
  searchPuntosDeVenta(s: ServidorAdmin) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    const url = environment.urls.functions + "/api/efactura/ptosVta/" + s.idCuenta + "/" + s.idLocal + "/" + s.idMac;
    return this.http.get(url, options).toPromise()
      .then(resp => console.log("Exitoooo FE", resp.json()))
      .catch(this.handleErrorObservable);
  }

  tienePermiso(): boolean {
    if (this.user && (this.user.email === "fmoya@popapp.io" ||
      this.user.email === "paula@popapp.io" ||
      this.user.email === "gperez@popapp.io" ||
      this.user.email === "nnasser@popapp.io" ||
      this.user.email === "gorellano@popapp.io" ||
      this.user.email === "nquinteros@popapp.io" ||
      this.user.email === "ldrazic@popapp.io" ||
      this.user.email === "jcortez@popapp.io" ||
      this.user.email === "pmarin@popapp.io" ||
      this.user.email === "gmarin@popapp.io")
    ) {
      return true;
    } else {
      return false;
    }

  }

  /**
   * Revisa los servidores seleccionados mas el ultimo clickeado y revisa de acurdo al boton tocado que valor es el que hay que cambiar, lo valida y lo modifica en FireBase
   *
   * @param input el tipo de atributo que estamos por modificar
   * @param element el servidor que hemos tocado al ultimo
   */
  inputABM(input: string, element: ServidorAdmin) {
    if (input === 'enUso') {
      this.onClickEnUso(element.idMac, element);
    }
    if (this.reseller) {
      if (
        input === "fearg" ||
        input === "rut" ||
        input === "pedidosOnline" ||
        input === "tipoUsuario" ||
        input === "marca" ||
        input === "marcaFotoURL" ||
        input === "marcaFotoChicaURL" ||
        input === "addImporte" ||
        input === "txMypopapp" ||
        input === "fechaEstadisticasWeb" ||
        input === "pagoAlta" ||
        input === "primerPago"
      ) {
        return this.soloDisponibleParaAdmins();
      }
    }
    if (input === "numeroLocal" || input === 'fechaAlta' || input === 'fechaBaja') {
      if (!this.tienePermiso()) {
        this.openSnackBar("Acceso denegado", "");
        return;
      }
    }
    if (input === "pedidosOnline") {
      this.configurarNuevoUXPO(element);
    } else {
      if (input == "fearg") {
        console.log('este ', element);
        const dialogRef = this.dialog.open(ModalConfContabiliumComponent, {
          width: '85%',
          data: { element }
        });
      } else {
        if (input === 'direccionyciudad') {
          this.abrirModalCiudadYDireccion(element);
        } else {
          this.adminCuentaService.inputABM(input, element, this.selection, this.cuentasMap, this.locales, this.cuentas)
            .then(res => { this.openSnackBar(res, ""); })
            .catch(e => {
              console.log(e);
              this.openSnackBar(e, "");
            })
        }
      }
    }
  }
  abrirModalCiudadYDireccion(s: ServidorAdmin) {
    // TODO: const dialogRef = this.dialog.open(ModalCiudadDireccionComponent, {
    // TODO:  width: '85%',
    // TODO:  data: { s }
    // TODO:});
    // TODO:
    // TODO:dialogRef.afterClosed().subscribe(
    // TODO:  result => {
    // TODO:    console.log('The dialog was closed', result);
    // TODO:    if (result && result.msj) {
    // TODO:      return;
    // TODO:    }
    // TODO:  });
  }

  async integracionFacturapi(s: ServidorAdmin) {
    const dialogRef = this.dialog.open(ModalConfFacturapiComponent, {
      width: '85%',
      data: { s }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });

  }


  async integracionFullPrime(s: ServidorAdmin) {
    const dialogRef = this.dialog.open(ModalFullprimeComponent, {
      width: '85%',
      data: { s }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });
  }

  async integracionIngefactura(s: ServidorAdmin) {
    const dialogRef = this.dialog.open(ModalConfIngefacturaComponent, {
      width: '85%',
      data: { s }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });
  }

  async integracionVessi(s: ServidorAdmin) {
    const dialogRef = this.dialog.open(ModalConfVessiComponent, {
      width: '85%',
      data: { s }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });
  }

  async configurarNuevoUXPO(s: ServidorAdmin) {


    const dialogRef = this.dialog.open(ModalConfPonlineComponent, {
      width: '85%',
      data: s
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result && result.length > 0) {
        this.tiendasAMigrar = result;
        this.servidorOrigen = s;
        //migrar tiendasSeleccionadas s
        this.migrando = true;
      }
      if (result && result.msj) {
        return;
      }
    });



  }

  nuevoPedido(local: { key: string, nombre: string, activado: string }, s: ServidorAdmin) {
    console.log("nuevoPedido ", local);
    let url1 = environment.urls.escuchador;
    //let url1 = "http://localhost:3000";
    let url2 = "/pedidosonline/nuevopedidorandom/";

    this.cuentaService.getUrlProm(url1 + url2 + s.keyPedidosOnline.key + "/" + local.key)
      .then(_ => {
        this.openSnackBar("Nuevo pedido creado", "");
      })
      .catch(e => {
        console.log("error en el pedido nuevo", e)
        this.openSnackBar("Error al crear al pedido", "");
      })
  }

  onClickEnUso(mac: string, element: ServidorAdmin) {
    const jsonBody = {
      atributo: "enUso",
      valor: !element.enUso
    }

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    this.http.patch(`${environment.urls.onboarding}/v1/managers/${mac}?by=mac`, jsonBody, options).toPromise()
      .then(res => {
        this.openSnackBar("Exito", "Valor de enUso invertido");
        console.log("exito enUso", res);
        element.enUso = !element.enUso
      })
      .catch(e => {
        this.openSnackBar("Error", "Error al cambiar en Uso");
        console.log("fallo enUso", e);
      })
  }

  localesPOnline(s: ServidorAdmin): { key: string, nombre: string, activado: string }[] {
    let arre: { key: string, nombre: string, activado: string }[] = []
    try {
      for (let pya123 in s.keyPedidosOnline.locales) {
        arre.push({ key: pya123, nombre: s.keyPedidosOnline.locales[pya123].nombre, activado: s.keyPedidosOnline.locales[pya123].activado });
      }
    } catch (e) {
    }

    return arre;
  }

  ejecutarFix(s: ServidorAdmin) {
    this.inputABM("fix", s);
  }
  listaMsjsWhatsapp(s: ServidorAdmin) {
    this.inputABM("listaMsjsWhatsapp", s);

  }

  msjGenerico(s: ServidorAdmin) {
    this.inputABM("msjGenerico", s);
  }
  msjEstadisticas(s: ServidorAdmin) {
    this.inputABM("msjEstadisticas", s);

  }
  msjNoIva(s: ServidorAdmin) {
    this.inputABM("msjNoIva", s);
  }
  msjEstadYNoIva(s: ServidorAdmin) {
    this.inputABM("msjEstadYNoIva", s);
  }
  valorNumeroLocal(numLocal: number): string {
    if (numLocal != null) {
      if (numLocal == -1) {
        return "Ya NO es Cliente";
      } else if (numLocal == -2) {
        return "Reseller";
      } else if (numLocal == -3) {
        return "Developer";
      } else if (numLocal == -4) {
        return "Cambió de PC";
      } else if (numLocal == -5) {
        return "Pago por cuotas pendientes";
      } else {
        return "" + numLocal;
      }
    } else {
      return "";
    }
  }

  nombresPedidosOnline(s: ServidorAdmin) {
    let msj = ""
    if (s && s.keyPedidosOnline) {
      msj += s.keyPedidosOnline.key + ": ";
      if (s.keyPedidosOnline.locales) {
        for (let pya123 in s.keyPedidosOnline.locales) {
          let v = s.keyPedidosOnline.locales[pya123]
          msj += v.nombre + " (" + pya123;
          if (!v.activado) {
            msj += " [X] ";
          }
          msj += "), ";
        }
      }
    }
    return msj
  }

  hasPyaV1(s: ServidorAdmin) {
    let msj = ""
    if (s && s.keyPedidosOnline) {
      msj += s.keyPedidosOnline.key + ": ";
      if (s.keyPedidosOnline.locales) {
        for (let pya123 in s.keyPedidosOnline.locales) {
          let v = s.keyPedidosOnline.locales[pya123]
          if (v.origen === 'pya') {
            return true;
          }
        }
      }
    }
    return false;
  }



  async reconfigurarMacsPasadas(s: ServidorAdmin) {
    const mac = this.macsDuplicadasMap.keys().next().value;
    if (mac === s.idMac) {
      console.log(mac);
      const arreServidores = this.macsDuplicadasMap.get(mac);
      console.log(arreServidores);
      if (arreServidores.length > 1) {
        this.db.object('macsPasadas/' + mac).update({
          idCuenta: s.idCuenta,
          idLocal: s.idLocal,
        }).then(a => {
          console.log('Ok');
          this.snackbar.open('Ok')._dismissAfter(2000);
        })
      }
    }
  }

  async sacaLocalesYServidores(mapCuentas: any[]) {
    let hoyDia: number = new Date().getDate();
    let hoyMes: number = new Date().getMonth();
    let hoyAnio: number = new Date().getFullYear();
    //console.log("empieza a sacar");
    //for (let i = 0; i < this.servidores.length;) {
    //this.servidores. pop();
    //}
    this.macsDuplicadasMap = new Map<string, ServidorAdmin[]>();
    const macsDuplicadasMapAux = new Map<string, ServidorAdmin[]>();
    this.macsDuplicadasNumber = 0;

    this.keysDuplicadasMap = new Map<string, ServidorAdmin[]>();
    const keysDuplicadasMapAux = new Map<string, ServidorAdmin[]>();
    this.keysDuplicadasNumber = 0;

    this.servidores = [];
    this.locales = new Map<string, LocalAdmin>();
    this.cuentasMap = new Map<string, CuentaAdmin>();
    this.cuentas = [];
    //for de cuentas

    let cantServidorasMasQue900 = 0;
    let cantTiendasV1Rappi = 0;
    for (let i in mapCuentas) {
      let c: CuentaAdmin = mapCuentas[i];
      c.id = mapCuentas[i].key;
      this.cuentasMap.set(mapCuentas[i].key, c);
      let mapLocales = mapCuentas[i].locales;
      //for de locales
      let indexLocal = 0;
      let totalLocales = 0;
      for (let j in mapLocales) {
        totalLocales++;
      }
      for (let j in mapLocales) {
        indexLocal++;
        let l: LocalAdmin = mapLocales[j];
        l.id = j;
        l.idCuenta = c.id;
        l.totLocales = totalLocales;


        //for de servidores
        let mapServidores = mapLocales[j].servidores;
        let indexServidor = 0;
        let totalServidores = 0;
        for (let y in mapServidores) {
          totalServidores++;
        }
        for (let y in mapServidores) {
          indexServidor++;
          let s: ServidorAdmin = mapServidores[y];
          s.idMac = y;
          s.idLocal = l.id;
          s.idCuenta = c.id;
          s.uCuenta = c.u;
          s.lastCuenta = c.last;

          if (c.linkSuscription) {
            s.linkSuscription = c.linkSuscription;
          }

          if (c.idSuscription) {
            s.idSuscription = c.idSuscription;
          }

          if (s.numeroLocal && (+s.numeroLocal) > 900 && (+s.numeroLocal) < 9000) {
            cantServidorasMasQue900++;
            // console.log(cantServidorasMasQue900, s.numeroLocal, s);
          }

          const mac = s.idMac.trim().toLocaleLowerCase();
          if (mac !== "modulofearg" &&
            mac !== "upgrade2a3" &&
            mac !== "descuento" &&
            mac !== "costoalta" &&
            mac !== "plantodosal1" &&
            mac !== "upgrade1a2" &&
            mac !== "packdispositivos"

          ) {
            const arre = macsDuplicadasMapAux.get(s.idMac) ? macsDuplicadasMapAux.get(s.idMac) : [];
            arre.push(s);
            if (arre.length > 1) {
              if (arre.length === 2 && (arre[0].idLocal === arre[1].idLocal || arre[0].idCuenta === arre[1].idCuenta)) {

              } else {
                this.macsDuplicadasNumber++;
                this.macsDuplicadasMap.set(s.idMac, arre);
              }
            }
            macsDuplicadasMapAux.set(s.idMac, arre);
          }

          if (s.keyPedidosOnline && s.keyPedidosOnline.key) {
            const arre = keysDuplicadasMapAux.get(s.keyPedidosOnline.key) ? keysDuplicadasMapAux.get(s.keyPedidosOnline.key) : [];
            arre.push(s);
            if (arre.length > 1) {
              // if (arre.length === 2 && (arre[0].idLocal === arre[1].idLocal || arre[0].idCuenta === arre[1].idCuenta)) {

              // } else {
              this.keysDuplicadasNumber++;
              this.keysDuplicadasMap.set(s.keyPedidosOnline.key, arre);
              // }
            }
            keysDuplicadasMapAux.set(s.keyPedidosOnline.key, arre);
          }
          // if (s.numeroLocal && (+s.numeroLocal) > 0 && (+s.numeroLocal) !== 900 && (+s.numeroLocal) < 9000) {
          //   if (s.keyPedidosOnline && s.keyPedidosOnline.key && s.keyPedidosOnline.locales) {
          //     for (let k in s.keyPedidosOnline.locales) {
          //       const tienda: {
          //         activado: boolean;
          //         nombre: string;
          //         autoacept: boolean;
          //         fechaAlta: string;
          //         idStore: string;
          //         marca: string;
          //         tag: string;
          //         descuentopya: string;
          //         descrappi: string;
          //         origen: string;
          //         rappiv2?: boolean;
          //       } = s.keyPedidosOnline.locales[k];

          //       if (tienda && tienda.origen && tienda.origen.startsWith("rap") &&
          //         !tienda.rappiv2 && tienda.activado) {
          //         cantTiendasV1Rappi++;
          //         console.log("(" + cantTiendasV1Rappi + ")  " + s.keyPedidosOnline.key + "  ---  " + tienda.idStore + "   ---  " + tienda.origen);
          //       }
          //     }
          //   }
          // }

          if (c.fechaUltimaFacturacion) {
            s.fechaUltimaFacturacion = c.fechaUltimaFacturacion;
          }
          if (c.cuitORut) {
            s.cuitORut = c.cuitORut;
          }
          if (c.emailpago) {
            s.emailpago = c.emailpago;
          }

          if (c.whatsapp) {
            s.whatsapp = c.whatsapp;
          }
          if (c.contacto) {
            s.contacto = c.contacto;
          }

          if (c.idClienteContabilium) {
            s.idClienteContabilium = c.idClienteContabilium;
          }

          if (c.responsabilidad) {
            s.responsabilidad = c.responsabilidad;
          }

          s.nombreCuenta = c.nombreCuenta;
          s.nombreLocal = l.nombreLocal;
          s.indServidor = indexServidor;
          s.indLocal = indexLocal;
          s.totLocales = totalLocales;
          s.totServidoresOSvs = totalServidores;



          let dias = this.adminCuentaService.diasss(s.vencimiento, hoyAnio, hoyMes, hoyDia)
          s.dias = dias;
          if (!c.dias || c.dias < s.dias) {
            c.dias = s.dias;
            c.venceAux = s.vencimiento;
          }

          let diasReal = this.adminCuentaService.diasss(s.pagoHasta, hoyAnio, hoyMes, hoyDia)
          s.diasReal = diasReal

          s.fechaInstalacionyyyyMMddd = this.fechaDeddMMyyyyAyyyyMMdd(s.fechaInstalacion);

          if (!this.marca || (this.marca && s.marca && s.marca === this.marca)) {
            this.servidores.push(s);
          }

          if (s.numeroLocal && s.moneda && +s.numeroLocal > 0 && +s.numeroLocal < 9000 && +s.numeroLocal !== 900 && (s.cuotasPendientes == null)) { //1500 para arriba seria para testear pero no para sumar

          }
        }

        //  l.servidoresIds = asdasdasd;
        this.locales.set(l.id, l);
      }

      // c.localesIds = asdasd;
      this.cuentas.push(c);
    }

    this.keysLocalesMap = keysDuplicadasMapAux;

    this.updateTabla(false);


  }

  async facturarAPopapp(s: ServidorAdmin) {
    this.router.navigateByUrl("/mypopapp/adminFacturacion?idCuenta=" + s.idCuenta + '&pais=' + s.pais);
  }

  // generarExcel() {
  //   this.openSnackBar("Espera unos segundos y dale click a obter excel", "");
  // }

  updateTabla(yaFiltro: boolean) {
    this.servidores.sort(function (s1: ServidorAdmin, s2: ServidorAdmin) {
      return s2.dias - s1.dias;
    });

    this.dataSource = new MatTableDataSource(this.servidores);
    this.dataSource.filterPredicate =
      (s: ServidorAdmin, filter: string) => {
        if (this.filtroClientesActivado) {
          if (s.numeroLocal && s.moneda && +s.numeroLocal > 0 && +s.numeroLocal < 9000 && +s.numeroLocal !== 900 && (s.cuotasPendientes == null)) { //1500 para arriba seria para testear pero no para sumar
            return JSON.stringify(s).trim().toLowerCase().indexOf(filter) != -1;
          } else {
            return false;
          }
        } else {
          return JSON.stringify(s).trim().toLowerCase().indexOf(filter) != -1;
        }
      }
    if (!yaFiltro)
      this.applyFilter(this.filtroActual)
    this.deseleccionarTodes();
  }

  deseleccionarTodes() {
    this.selection.clear();
  }

  getMyStyles(dias: number) {
    let color = 'green';
    if (dias < 0 && dias > -8) {
      color = 'violet';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': 'white',
      'background': color,
      'padding': '10px'
    };
    return myStyles;
  }

  fechaDeddMMyyyyAyyyyMMdd(fechaInstalacion: string): string {
    if (fechaInstalacion) {
      let array = fechaInstalacion.split("/");
      if (array && array.length == 3) {
        return array[2] + "/" + (array[1].length > 1 ? array[1] : "0" + array[1]) + "/" + (array[0].length > 1 ? array[0] : "0" + array[0]);
      }
    }
    return "";
  }

  escribePagos(s: ServidorAdmin, fechaString: string): void {
    this.httpPatchPagos(s, fechaString)
      .then(respuesta => {
        this.openSnackBar("", "Exito");
      },
        error => this.openSnackBar(error, "Error"));
  }
  httpPatchPagos(s: ServidorAdmin, fecha: string): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let jsonVenc = {};
    jsonVenc[s.idMac] = fecha;

    return this.http.patch("https://popapprestoserver.firebaseio.com/pagos.json", jsonVenc, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorObservable);
  }
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  editar() {
    if (this.fecha && this.fecha instanceof Date && this.selection.selected && this.selection.selected.length > 0) {
      let dia: number = this.fecha.getDate();
      let mes: number = this.fecha.getMonth() + 1;
      let anio: number = this.fecha.getFullYear();

      let fechaString: string = "";
      if (dia < 10) {
        fechaString += "0";
      }
      fechaString += dia + ";";
      if (mes < 10) {
        fechaString += "0";
      }
      fechaString += mes + ";";
      fechaString += anio;

      let fechaNormString: string = dia + "/" + mes + "/" + anio;

      let stringServidores: string = "";
      for (let i = 0; i < this.selection.selected.length; i++) {
        let s: ServidorAdmin = this.selection.selected[i];
        stringServidores += s.nombreServidor + "\n";
      }

      if (fechaString.length == 10) {
        if (confirm("Asignar fecha: " + fechaNormString + " (" + fechaString + ") a:\n" + stringServidores)) {
          for (let i = 0; i < this.selection.selected.length; i++) {
            let s: ServidorAdmin = this.selection.selected[i];
            stringServidores += s.nombreServidor + "\n";
            this.db.object("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac).update({ vencimiento: fechaNormString })
              .then(_ => this.escribePagos(s, fechaString))
              .catch(err => { this.openSnackBar("No pudo grabar en Fire MyPA", "Avisar GAMR"); console.log(err) });
            //this.escribePagos(s, fechaString);
          }

          //console.log("You pressed OK!");
        } else {
          //console.log("You pressed Cancel!");
        }
      } else {
        //error de la fecha
        this.openSnackBar('La fecha no tiene length 10', 'Contactar GAMR');
      }
    } else {
      //no hay servidores elegidos o fecha
      if (!this.fecha) {
        this.openSnackBar('..', 'Elija Fecha');
      } else if (!(this.fecha instanceof Date)) {
        this.openSnackBar('fecha no es Date', 'Contactar GAMR');
      } else {
        this.openSnackBar('No hay servidores elegidos', 'Elige 1 al menos');
      }
    }
  }
  sacaEspacios(nombreCuenta: string) {
    if (nombreCuenta) {
      while (nombreCuenta.includes(" ")) {
        nombreCuenta = nombreCuenta.replace(" ", "%20");
      }
      return nombreCuenta + "%20";
    } else {
      return "%20";
    }

  }
  //onNgModelChange(event) {
  // console.log('on ng model change', event);
  //}

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });

    //this.snackBar.open(message, action, {
    // duration: 2000,
    //});
  }




  showFormAlert(fs: FormAlert[]) {
    let arreResp = [];
    let i = 0;
    for (let f of fs) {
      let respuesta: any = prompt(f.msg, (f.defaultMsg ? f.defaultMsg : ""));
      if (f.requerido) {
        if (respuesta && respuesta != null && respuesta.trim().length > 0) {
          if (f.numero) {
            respuesta = +(respuesta.trim());
          }
        } else {
          throw "Campo " + f.msg + " requerido";
        }
      }
      arreResp[i] = respuesta;
      i++;
    }
    return arreResp;
  }

  inicioPya(s: ServidorAdmin) {
    if (this.reseller) {
      return this.soloDisponibleParaAdmins();
    }
    const error = "No se Inicio PYA";
    try {
      let form: FormAlert[] = [];
      form[0] = new FormAlert("Id local de Pya", s.idLocalPya + "", true, true);
      form[1] = new FormAlert("Sistema Operativo de la compu", (s.pyaInicio && s.pyaInicio.os) ? s.pyaInicio.os : "Windows 10 x64", false, true);
      form[2] = new FormAlert("Version de popapp del local", (s.pyaInicio && s.pyaInicio.app) ? s.pyaInicio.app : (s.versionActual ? s.versionActual : "1.3.5"), false, true);
      form[3] = new FormAlert("Recibe pedidos logistics? (s/n)", (s.pyaInicio && s.pyaInicio.logistics) ? s.pyaInicio.logistics : "s", false, true);
      let arreResp = this.showFormAlert(form);
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      let jsonBody = {
        "os": arreResp[1],
        "app": arreResp[2],
        "logistics": arreResp[3]
      }
      this.http.post(this.urlPYa + "initialization/" + s.idCuenta + "/" + s.idLocal + "/" + s.idMac + "/" + arreResp[0], jsonBody, options).toPromise()
        .then(res => {
          this.openSnackBar("Exito", "PYA inicializado");
          console.log("exito", res);
        })
    } catch (e) {
      console.log(e.message || e);
      this.openSnackBar(error, "Revisa la consola (F12)");
    }
  }

  enviarMailPrecios() {
    const dialogRef = this.dialog.open(ModalEnvioMailPrecioComponent, {
      width: '85%',
      data: { servidores: this.servidores, cuentas: this.cuentas }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        if (result && result.msj) {
          return;
        }
      }
    });
  }

  enviarMailAumentoPreciosChile() {
    const dialogRef = this.dialog.open(ModalEmailAumentoChileComponent, {
      width: '85%',
      data: { servidores: this.servidores, cuentas: this.cuentas }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        if (result && result.msj) {
          return;
        }
      }
    });
  }

  enviarMsjPlanAl1(s: ServidorAdmin) {
    try {

      //sacamos las cuentas a partir de los servidores y no las repetimos
      let cuentas = new Map<string, CuentaAdmin>();
      let servidores: ServidorAdmin[] = [s];
      servidores.forEach((s) => {
        let cuenta: CuentaAdmin = this.cuentasMap.get(s.idCuenta);
        cuenta.pais = s.pais;
        cuentas.set(s.idCuenta, cuenta);
      });

      //para cada cuenta calculamos el importe y la moneda y q no tenga errores
      cuentas.forEach(async (c) => {
        let mesesAa;
        if (s.tag && s.tag.includes("planal1A")) {
          mesesAa = 1;
        } else if (s.tag && s.tag.includes("planal1D")) {
          mesesAa = 2;
        } else {
          throw "No tiene tag de plan A o D";
        }
        let dias = s.tag.split(".")[1];


        this.cuentaService.mensajeAVerificarAntesDeCobrar(c, mesesAa);
        if (!c.error || c.error.length == 0) {
          //verificamos que tenga los atributos necesarios antes de mandar el msj
          if (c.contacto && c.whatsapp && c.moneda && c.pagoHasta) {

            let primeraParteURL: string = "https://api.whatsapp.com/send?phone=";
            let segundaParteURL: string = "&text=";
            let textoSuscripcionVencida: string = "*venció el pasado%20";
            let textoSuscripcionNoVencida: string = "*vence el próximo%20";
            let textoSuscripcionVenceHoy: string = "*vence hoy%20";



            //HARDCODEO PARA PROBAR EL MENSAJE
            let uRLMensaje: string;
            uRLMensaje = primeraParteURL + c.whatsapp.replace("+", "") + segundaParteURL;

            let cuerpoDelMensaje: string = "";
            let textoDiferenciaDeDiasConVencimiento: string = "";
            let fechaHoy: number = new Date().getTime();
            let fechaPagoHastaMillis: number = this.cuentaService.pagoHastaADate(c.pagoHasta).getTime();

            //SALUDO Y ENCABEZADO
            cuerpoDelMensaje += "Hola%20" + c.contacto + ".%20Cómo estás??";
            cuerpoDelMensaje += "%0A%0ATe escribimos desde Popapp para avisarte de un cambio en tu fecha de vencimiento de la suscripción. A partir de octubre los *vencimientos serán el 1º de cada mes.*";
            cuerpoDelMensaje += "%0A%0AEn tu caso *vencía los días " + dias + "*, entonces encontrarás un descuento de *" + (dias - 1) + " día";
            if (dias > 2) {
              cuerpoDelMensaje += "s.*";
            } else {
              cuerpoDelMensaje += ".*";
            }


            // PIE DE MENSAJE
            cuerpoDelMensaje += "%20Puedes%20consultar%20el%20detalle%20de%20tu%20cuenta%20y%20realizar%20el%20pago,%20iniciando%20" +
              "sesión%20en%20https://popapp.io/mypopapp/micuenta";


            cuerpoDelMensaje += "%0A%0A%5FEste%20mensaje%20fue%20generado%20automaticamente%5F";
            //////////////////////////////////////

            uRLMensaje += cuerpoDelMensaje;
            window.open(uRLMensaje, '_blank');
          } else {
            throw "no tiene todos los atributos (contacto, whatsapp, moneda, pagoHasta):";
          }
        } else {
          throw c.error;
        }
      });
    } catch (error) {
      console.log(error);
      this.openSnackBar("Problema: ", error);
    }
  }



  planAl1(s: ServidorAdmin) {
    if (this.reseller) {
      return this.soloDisponibleParaAdmins();
    }
    try {
      let cuenta: CuentaAdmin = this.cuentasMap.get(s.idCuenta);
      let arreFecha = s.pagoHasta.split("/");
      let dia: number = +arreFecha[0];
      let tag: string;
      if (dia < 20) {
        tag = "planal1A." + dia + ".";
      } else {
        tag = "planal1D." + dia + ".";
      }
      let diasADesc = dia - 1;
      if (diasADesc < 1) {
        throw "Ya tiene dia 1 o negativo " + s.pagoHasta;
      } else {
        const nuevoPagoHasta = "01/" + arreFecha[1] + "/" + arreFecha[2];
        let importeConySinIva = 0;
        let mapUpdates = {};
        if (cuenta && cuenta.locales) {
          for (let idLocal in cuenta.locales) {
            let local = this.locales.get(idLocal);
            if (local != null && local.servidores != null) {
              for (let mac in local.servidores) {
                let sAux: ServidorAdmin = local.servidores[mac];
                if (sAux && this.cuentaService.mostrarParaPagar(sAux) && ((!sAux.cuotasPendientes || sAux.cuotasPendientes === null) || sAux.cuotasPendientes === 0)) {
                  mapUpdates["cuentas/" + idLocal + "/locales/" + idLocal + "/servidores/" + mac + "/pagoHasta"] = nuevoPagoHasta;
                  mapUpdates["cuentas/" + idLocal + "/locales/" + idLocal + "/servidores/" + mac + "/tag"] = tag + (sAux.tag ? " " + sAux.tag : "");
                  let impoConIva = sAux.importe;
                  if (sAux.pagaIva && sAux.pagaIva > 0) {
                    impoConIva += sAux.importe * sAux.pagaIva / 100
                  }
                  importeConySinIva -= diasADesc * impoConIva / this.maxDiasMes(+arreFecha[1] - 1, +arreFecha[2]);
                }
              }
              const key = "planTodosAl1";
              console.log(mapUpdates);
              let cuotaLocal = this.creaCuota(s, dia, importeConySinIva, nuevoPagoHasta, key);
              console.log(cuotaLocal);
              let confirmar = confirm("Crear una cuota de descuento por " + s.moneda + " " + importeConySinIva + "?");
              if (confirmar) {
                this.db.list('/').update("/", mapUpdates).then(_ => {
                  this.adminCuentaService.validarYEscribirEnFBLocal(cuotaLocal, false, "servidores/" + key, [s])
                    .then(res => { this.openSnackBar(res, ""); })
                    .catch(e => { this.openSnackBar(e, "AVISAR GAMR"); })
                });
              }
            }
          }
        }
      }
    } catch (error) {
      console.log("hubo un problema", error);
      this.openSnackBar("hubo un problema", error);
    }
  }

  maxDiasMes(mes: number, year: number): number {
    if (mes === 2) {
      if (year % 4 === 0)
        return 29;
      else
        return 28;
    } else if (mes === 1 || mes === 3 || mes === 5 || mes === 7 || mes === 8 || mes === 10 || mes === 0 || mes === 12) {
      return 31;
    } else {
      return 30;
    }
  }

  creaCuota(s: ServidorAdmin, dias: number, importe: number, nuevoPagoHasta: string, key) {

    let keysQNoPuedenSer = "";
    const cuentaPadre = this.cuentas.find(i => s.idCuenta == i.id);
    for (let keyLocal in cuentaPadre.locales) {
      if (keyLocal == s.idLocal) {
        for (let mac in cuentaPadre.locales[keyLocal].servidores) {
          keysQNoPuedenSer += mac + "  -  "
        }
      }
    }
    if (!keysQNoPuedenSer.includes(key)) {
      let newCuota: any = {};
      newCuota.nombreServidor = "Descuento de " + (dias - 1) + " día" + ((dias > 2) ? "s" : "") + " por cambio de vencimiento al día 1";
      newCuota.cuotasPendientes = 1;
      newCuota.importe = importe;
      newCuota.importeQDeberia = importe;
      newCuota.moneda = s.moneda;
      newCuota.numeroLocal = "9051";//pues no deberia contar en estadisticas
      newCuota.pagaIva = 0;
      newCuota.pagoHasta = nuevoPagoHasta;
      newCuota.pais = s.pais;
      newCuota.showPagar = true;
      return newCuota;
    } else {
      throw "Esta cuenta ya tiene una mac = planTodosAl1";
    }
  }

  urlPYa = environment.urls.escuchador + "/pya/";

  soloDisponibleParaAdmins() {
    this.openSnackBar("No tienes permiso para ejecutar la función", "");
  }

  getAllRestaurants() {
    if (this.reseller) {
      this.soloDisponibleParaAdmins();
    } else {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });

      this.http.get(this.urlPYa + "restaurantes/all", options).toPromise()
        .then(res => {
          this.openSnackBar("Exito", "Revisa la consola (F12)");
          console.log("exito", res.json());
        })
        .catch(err => {
          console.error("Erroooor Get All restaurants ", err.message || err);
        });
    }

  }

  irAFb(s: ServidorAdmin) {
    const url = environment.urls.firebase + "/data/cuentas/" + s.idCuenta;
    let win = window.open(url, '_blank');
    win.focus();
  }
  irAReportePedidos(s: ServidorAdmin) {
    const url = "https://app.popapp.io/ponline/reportar-pedido/" + s.keyPedidosOnline.key;
    let win = window.open(url, '_blank');
    win.focus();
  }

  irAStock(s: ServidorAdmin) {
    this.selectCuenta(s);
    this.router.navigateByUrl("/mypopapp/stock");
  }

  irAEstadisticas(s: ServidorAdmin) {
    this.selectCuenta(s);
    this.router.navigateByUrl("/mypopapp");
  }

  copiarTablaClipBoard() {
    let table = document.querySelector('#tablaAdmin');

    let range = document.createRange();
    range.selectNodeContents(table)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)

    document.execCommand('copy')
  }

  async darDeBaja(s: ServidorAdmin) {
    try {
      // TODO: aca debería ir el endpoint de baja
      let mapUpdates = {}
      // mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/primerPago"] = false;
      // mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/pagoAlta"] = true;
      mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/showPagar"] = false;
      // mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/planN"] = 0;
      // mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/importe"] = 0;
      // mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/importeQDeberia"] = 0;
      mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/numeroLocal"] = "1000" + s.numeroLocal;
      mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/fechaBaja"] = new Date().toISOString();

      //buscamos si tiene tiendas y local en popappweb
      let mapFS = {};
      if (s.keyPedidosOnline) {
        if (s.keyPedidosOnline.key) {
          await this.dbFirestore.updateAt("confLocalPO/" + s.keyPedidosOnline.key, { _key: s.keyPedidosOnline.key, desactivado: true });
        }

        let desactivar = confirm("Desactivar tiendas tambien?");
        if (desactivar) {
          for (let pya123 in s.keyPedidosOnline.locales) {
            const tienda = s.keyPedidosOnline.locales[pya123];
            await this.dbFirestore.updateAt("confLocalPO/" + s.keyPedidosOnline.key + "/tiendas/" + tienda.origen + "," + tienda.idStore, { activado: false });
            if (tienda.keyHub) {
              await this.dbFirestore.updateAt("confLocalPO/" + tienda.keyHub + "/tiendasId/" + tienda.origen + "," + tienda.idStore, { activado: false });
            }
            mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/locales/" + pya123 + "/activado"] = false;
            mapUpdates["keysLocalesPedidosOnline/" + pya123 + tienda.idStore] = null;
          }
        }
      }
      await this.db.object("/").update(mapUpdates);
      this._snackBar.open("Exito");
    } catch (e) {
      console.log("Error al dar de baja", e);
      this._snackBar.open("Error REINTENTAR O CONSULTAR A GAMR con captura de la consola (F12)");
    }

  }

  configNuevoPricing(s: ServidorAdmin) {
    const dialogRef = this.dialog.open(ModalPricingComponent, {
      width: '85%',
      data: s
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });

  }

  puntuar(s: ServidorAdmin) {
    let año = prompt("Año", "2019");
    if (!año || año.length === 0) {
      throw "No se ingreso año"
    }
    let mes = prompt("Mes (2 digitos)", "11");
    if (!mes || mes.length === 0) {
      throw "No se ingreso mes"
    }
    let dia = prompt("Dia (2 digitos)", "23");
    if (!dia || dia.length === 0) {
      throw "No se ingreso dia"
    }
    let idJornada = prompt("idJornada", "z1");
    if (!idJornada || idJornada.length === 0) {
      throw "No se ingreso idJornada"
    }
    let keyPuntuacion = s.idCuenta + "," + año + "," + mes + "," + dia + "," + s.idLocal + "," + s.idMac + "," + idJornada;

    const puntuacion = {}
    puntuacion["fecha"] = año + "-" + mes + "-" + dia + "T06:06:06Z"
    puntuacion["manual"] = true
    puntuacion["local"] = s.nombreServidor
    puntuacion["mac"] = s.idMac

    let cantEstrellas = +prompt("Cant Estrellas", "5");
    if (!cantEstrellas) {
      throw "No hay estrellas"
    }
    puntuacion["puntuacion"] = cantEstrellas

    let comentario = prompt("Comentario", "");
    if (comentario && comentario.length > 0) {
      puntuacion["comentario"] = comentario
    }

    this.db.object("puntuaciones/jornadas/" + keyPuntuacion).set(puntuacion);
  }

  async vincularStockCentralizado(s: ServidorAdmin) {
    if (!s.idMac) {
      this.snackbar.open("No existe mac")._dismissAfter(2000);
    } else {
      let usuario = await this.db.object("usuarios/" + s.idMac).query.once("value").then(v => {
        return v.val();
      })
      if (!usuario) {
        this.snackbar.open("No existe usuario")._dismissAfter(2000);
      } else {
        const dialogRef = this.dialog.open(ModalVincularCentralizadoComponent, {
          width: '85%',
          data: { s, usuario }
        });
      }
    }
  }
  fixCartaCemtralizada() {
    let keyCarta: string = prompt("keyCarta?", "keyCereza").trim();
    if (keyCarta && keyCarta.length > 0)
      this.adminCuentaService.sacarIngredientesSinStock(keyCarta)
  }

  automatizarConfigParaSync(s: ServidorAdmin, activar: boolean) {
    const servidores: ServidorAdmin[] = this.obtieneServidoresSeleccionados(s, this.selection);
    const dialogRef = this.dialog.open(ModalAutomatizarSyncManagerComponent, {
      width: '85%',
      data: { servidores, activar: activar }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });


  }

  obtieneServidoresSeleccionados(s: ServidorAdmin, selection: SelectionModel<ServidorAdmin>): ServidorAdmin[] {
    let servAModificar: ServidorAdmin[] = [];
    if (selection.selected && selection.selected.length > 0) {
      for (let i = 0; i < selection.selected.length; i++) {
        servAModificar.push(selection.selected[i]);
      }
      if (!selection.selected.includes(s)) {
        servAModificar.push(s);
      } //else ya se proceso en el for
    } else {
      //proceso individual
      servAModificar.push(s);
    }
    return servAModificar;
  }

  abrirFormMP(s: ServidorAdmin) {
    const cuenta = this.cuentas.find(x => x.id === s.idCuenta);
    const dialogRef = this.dialog.open(ModalMpComponent, {
      width: '85%',
      data: { s, cuenta }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });
  }

  integrarMP(s: ServidorAdmin) {

    const dialogRef = this.dialog.open(ModalIntegrarMpComponent, {
      width: '85%',
      data: { s }
    });

    dialogRef.afterClosed().subscribe(result => {
      result => {
        console.log('The dialog was closed', result);
        if (result && result.msj) {
          return;
        }
      }
    });
  }

  onClickCrearServidorEnSql(s: ServidorAdmin) {
    this.adminCuentaService.hitearSQLBackCrearManagerOb(s)
      .then(_ => this.snackbar.open('Exito al crear servidor en SQL')._dismissAfter(3000))
      .catch(
        err => {
          console.log('error al crear manager ob', err);
          this.snackbar.open(JSON.parse(err._body).msg)._dismissAfter(3000);
        }
      );
  }

  onClickCrearLocalEnSql(s: ServidorAdmin) {
    this.adminCuentaService.hitearCrearLocalSQLBackOb(
      s,
      (s.numeroLocal && +s.numeroLocal > 0 && +s.numeroLocal !== 900 && +s.numeroLocal < 9000)
    )
      .then(_ => this.snackbar.open('Exito al crear local en SQL')._dismissAfter(3000))
      .catch(
        err => {
          console.log('error al crear local ob', err);
          this.snackbar.open(JSON.parse(err._body).msg)._dismissAfter(3000);
        }
      );
  }

  onClickCrearCuentaEnSql(s: ServidorAdmin) {
    this.adminCuentaService.hitearSQLBackCrearCuentaOb(
      s,
      (s.numeroLocal && +s.numeroLocal > 0 && +s.numeroLocal !== 900 && +s.numeroLocal < 9000)
    )
      .then(_ => this.snackbar.open('Exito al crear cuenta en SQL')._dismissAfter(3000))
      .catch(
        err => {
          console.log('error al crear cuenta ob', err);
          this.snackbar.open(JSON.parse(err._body).msg)._dismissAfter(3000);
        }
      );
  }

  onClickCrearLocacionEnSql(s: ServidorAdmin) {
    const cuenta = this.adminCuentaService.leerRecursoOb('locaciones', s.idCuenta, 'legacyId');
    this.adminCuentaService.hitearSQLBackCrearLocacionOb(
      s,
      (s.numeroLocal && +s.numeroLocal > 0 && +s.numeroLocal !== 900 && +s.numeroLocal < 9000)
      , 1)
      .then(_ => this.snackbar.open('Exito al crear locacion en SQL')._dismissAfter(3000))
      .catch(
        err => {
          console.log('error al crear locacion ob', err);
          this.snackbar.open(JSON.parse(err._body).msg)._dismissAfter(3000);
        }
      );
  }



}

