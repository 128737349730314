import { Component, OnInit } from '@angular/core';
import { RutaService } from '../services/ruta.service';

@Component({
  selector: 'app-descargas2',
  templateUrl: './descargas2.component.html',
  styleUrls: ['./descargas2.component.scss', '../../mypopapp/assets/_css/mypopapp.css', '../../mypopapp/assets/_css/mypopapp-mq.css']
})
export class Descargas2Component implements OnInit {

  constructor(public rutaService: RutaService) { }

  ngOnInit() {
    this.rutaService.changeRuta("descargas2")
  }

}
