import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Http, RequestOptions, Headers, } from '@angular/http';
import { AuthService } from '../../core/auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { CuentaService } from '../../services/cuenta.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-teaserleadspedidosonline',
  templateUrl: './teaserleadspedidosonline.component.html',
})
export class TeaserleadspedidosonlineComponent implements OnInit {

  error: string;
  registrando: boolean = false;
  formPedOnline: FormGroup;
  submitted = false;
  exito = false;
  siEsUsuario: boolean = false;
  noEsUsuario: boolean = true;
  pedi: boolean = false;
  rapp: boolean = false;
  glov: boolean = false;
  uber: boolean = false;
  otro: boolean = false;
  ning: boolean = false;

  emailInvalido = false;

  constructor(private formBuilder: FormBuilder,
    private http: Http,
    public dialogRef: MatDialogRef<TeaserleadspedidosonlineComponent>,
    public authService: AuthService,
    public cuentaService: CuentaService,
    public db: AngularFireDatabase,
  ) { }

  ngOnInit() {
    this.formPedOnline = this.formBuilder.group({
      nombre: ["", [Validators.required, Validators.minLength(2)]],
      nombreLocal: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      telefono: ["", [Validators.required, Validators.minLength(5)]],
      //esUsuario: ["", [Validators.required]],
      //usa: ["", [Validators.required]],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  async registrar(logGoogle: boolean) {
    this.submitted = true;
    this.error = null

    if (this.formPedOnline.invalid) {
      this.error = "Datos inválidos";
      return;
    }

    if (!this.pedi && !this.rapp && !this.glov
      && !this.uber && !this.otro && !this.ning) {
      this.error = "Elige al menos una opción";
      return;
    }
    this.registrando = true

    // subimos el formulario 
    let map = {};
    const nombre = this.f.nombre.value;
    const nombreLocal = this.f.nombreLocal.value;
    const wapp = this.f.telefono.value;
    const email = this.f.email.value;

    map["nombre"] = nombre;
    map["nombreLocal"] = nombreLocal;
    map["wapp"] = wapp;
    map["email"] = email;
    map["esUsuario"] = this.siEsUsuario;
    let usa: string = ""
    if (this.pedi) {
      map["pedidosYa"] = true;
      usa += "Pedidos Ya, "
    }
    if (this.rapp) {
      map["rappi"] = true;
      usa += "Rappi, "
    }
    if (this.glov) {
      map["glovo"] = true;
      usa += "Glovo, "
    }
    if (this.uber) {
      map["uber"] = true;
      usa += "Uber, "
    }
    if (this.otro) {
      map["otro"] = true;
      usa += "Otro, "
    }
    if (this.ning) {
      map["ninguno"] = true;
      usa += "Ninguno."
    }
    let siNo: string = this.siEsUsuario ? "SI" : "NO"
    // let seCreo = await this.creaContactoHubspot(email, nombre, wapp, nombreLocal, usa, siNo);
    // if (!seCreo) {
    //   /**
    //    * Mandar email de error pq no se pudo crear el contacto en hubspot
    //    */
    //   let cuerpoDeMailError = "Los datos son:"
    //     + "\nNombre: " + nombre
    //     + "\nEmail: " + email
    //     + "\nNegocio: " + nombreLocal
    //     + "\nTelefono: " + wapp
    //     + "\nEs Usuario Popapp: " + siNo
    //     + "\nUsuario Delivery Online: " + usa
    //     + "\n\n";

    //   if (this.emailInvalido) {
    //     cuerpoDeMailError = "***Error email invalido*** \n\n" + cuerpoDeMailError;
    //   }
    //   //const urlServidorMail = "environment.urls.escuchador/payments/mails/errorHubspot";

    //   let body = {
    //     notification: {
    //       title: "popapp",
    //       to: "gmarin@popapp.io",
    //       copia: "gmarin@popapp.io",
    //       asunto: "No se pudo crear el contaco en Hubspot PedsOnline" + nombre,
    //       text: cuerpoDeMailError
    //     }
    //   }
    //   this.cuentaService.enviarEmailAFSProm(body, "errorHubspot");

    //   //this.cuentaService.enviaMail(body, urlServidorMail)
    // }
    if (this.emailInvalido) {
      //no dejamos que avance
      return;
    }

    let cuerpoDeMail = "Los datos son:"
      + "\nNombre: " + nombre
      + "\nEmail: " + email
      + "\nNegocio: " + nombreLocal
      + "\nTelefono: " + wapp
      + "\nEs Usuario Popapp: " + siNo
      + "\nUsuario Delivery Online: " + usa
      + "\n\n";

    this.db.object("emailsPedidosOnline/" + this.authService.decodeEmail(email) + "/").update(map)

    //let urlServidorMail = "environment.urls.escuchador/payments/mails/leads";

    let body = {
      notification: {
        title: "popapp",
        to: "pgarcia@popapp.io",
        copia: "jcorona@popapp.io, victoria@popapp.io, agustina@popapp.io, fzarate@popapp.io, plopez@popapp.io",
        asunto: "Lead desde web pedidosonline " + email,
        text: cuerpoDeMail
      }
    }
    //mandar email para descargar con copia al facu jorge y a mi
    this.cuentaService.enviarEmailAFSProm(body, "leads")
      .then(r => {
        this.exito = true
      })
      .catch(e => {
        this.error = "Hubo un problema, por favor reintenta más tarde"
      })

  }


  get f() { return this.formPedOnline.controls; }

  // async creaContactoHubspot(email?, name?, phone?, nombreLocal?, usa?: string, siNo?: string) {
  //   this.emailInvalido = false;
  //   try {
  //     let _hsq = window["_hsq"] = window["_hsq"] || [];
  //     _hsq.push(["identify", {
  //       email: email,
  //       firstname: name,
  //       phone: phone,
  //       company: nombreLocal,
  //       es_usuario_popapp: siNo,
  //       usuario_delivery_online: usa
  //     }]);
  //     _hsq.push(['trackPageView']);
  //     return true;
  //   } catch (error) {
  //     console.log("error hs", error);
  //     let headers = new Headers({ 'Content-Type': 'application/json' });
  //     let options = new RequestOptions({ headers: headers });
  //     let jsonContacto = {};
  //     jsonContacto["properties"] = [];
  //     jsonContacto["properties"].push({
  //       "property": "firstname",
  //       "value": name
  //     })
  //     jsonContacto["properties"].push({
  //       "property": "phone",
  //       "value": phone
  //     })

  //     jsonContacto["properties"].push({
  //       "property": "company",
  //       "value": nombreLocal
  //     })

  //     jsonContacto["properties"].push({
  //       "property": "es_usuario_popapp",
  //       "value": siNo
  //     })

  //     jsonContacto["properties"].push({
  //       "property": "usuario_delivery_online",
  //       "value": usa
  //     })

  //     this.http.post("environment.urls.functionsapi/createOrUpdateContactHubspot/" + email, jsonContacto, options).toPromise()
  //       .then(v => {
  //         console.log("Exito", v);
  //         return false;
  //       })
  //       .catch(e => {
  //         if (e && e._body && e._body === "{'errorEmail':'INVALID_EMAIL'}") {
  //           this.emailInvalido = true;
  //           return false;
  //         }
  //         return false;
  //       });
  //   }
  // }

}
