import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-selectorfechas',
  templateUrl: './selectorfechas.component.html',
  styleUrls: ['./selectorfechas.component.scss']
})
export class SelectorfechasComponent implements OnInit {
  maxDate: Date = new Date();
  @ViewChild('picker') picker: MatDatepicker<Date>;
  @ViewChild('picker2') picker2: MatDatepicker<Date>;
  fD = new Date();
  fH = new Date();
  fechaDesde = this.convierteDateAStringPBuscar(this.fD);
  fechaHasta = this.convierteDateAStringPBuscar(this.fH);

  buscando: boolean = false;
  botonConsultarDesactivado: boolean = false;

  @Input() titulo: string

  @Output() datos = new EventEmitter<{ fechaDesde: string, fechaHasta: string, buscando: boolean }>();

  constructor() { }

  ngOnInit() {
  }

  abrirPicker(intentos: number) {
    if (this.picker) {
      this.picker.open();
    } else {
      if (intentos < 5) {
        intentos++;
        setTimeout(() => this.abrirPicker(intentos), 1000)
      }
    }
  }

  abrirPicker2(intentos: number) {
    if (this.picker2) {
      this.picker2.open();
    } else {
      if (intentos < 5) {
        intentos++;
        setTimeout(() => this.abrirPicker2(intentos), 1000)
      }
    }
  }

  cambioFecha(picker: number, event: MatDatepickerInputEvent<Date>) {
    if (picker === 1) {
      //fecha desde
      this.fechaDesde = this.convierteDateAStringPBuscar(event.value);
    } else {
      //fecha hasta
      this.fechaHasta = this.convierteDateAStringPBuscar(event.value);
    }
    this.actualizarBotonConsultar(event);
  }

  actualizarBotonConsultar($event) {
    //se hizo click en algun filtro de cuentas o picker
    this.botonConsultarDesactivado = false;

  }
  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "-" + mes : "-0" + mes;

    const diaString = fecha.getDate() > 9 ? "-" + fecha.getDate() : "-0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  buscar(fechaDesde, fechaHasta) {
    this.botonConsultarDesactivado = true;
    this.buscando = true;
    this.datos.emit({ fechaDesde: fechaDesde, fechaHasta: fechaHasta, buscando: true });
  }
  
  buscarFiltro() {
    this.buscar(this.fechaDesde, this.fechaHasta);

  }

}
