import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Log } from '../shared/log/log';
import { ValorFirebase } from '../shared/log/valorFirebase';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  /**
   * la url de popappowner base de firebase
   */
  urlBase: string = "https://popappowner.firebaseio.com/";

  urlEmails: string = "emails/";

  urlUsuarios: string = "usuarios/";

  urlLogs: string = "logs/";

  constructor(private afAuth: AngularFireAuth,
    private db: AngularFireDatabase) { }

  /**
   * 
   * @param data objeto que va a reemplazar al actual
   * @param last null si se trata de una creacion de un objeto, o lo que tenga en last ese objeto a la hora de modificarlo o eliminarlo, que apunta al objeto log anterior que lo modifico por ultima vez
   */
  async crearLog(mac: string, data: any, last: string, op: number, descripcion: string, observacion: string): Promise<Log> {
    let log: Log = new Log();
    log.date = { ".sv": "timestamp" };
    log.desc = descripcion;
    log.fecha = new Date().getTime();
    log.last = last;
    log.name = this.tituloOp(op);
    log.obs = observacion;
    log.op = op;
    if (this.afAuth && await this.afAuth.currentUser) {
      let user = await this.afAuth.currentUser;
      log.user = user.email;
    } else {
      //no esta logueado habria que devolver null para que de error o un throw
      log.err = "No hay usuario iniciado";
      log.cancel = 10;
    }

    try {
      this.valorNuevo(op, data, mac, log);
    } catch (e) {
      log.vNuev = null;
      log.err = e;
      log.cancel = 10;
    }



    return log;
  }


  /**
   * Tipos de operaciones
   * Ref Tipos de operaciones (op):
      101: Email agregado  //data = [email, nombreServer]
      102: Email modificado //data = [key, email, nombreServer]
      103: Email eliminado //data = [email, key]
   */
  tituloOp(op: number): string {
    switch (op) {
      case 101: return "Email agregado. ";
      case 102: return "Email modificado. ";
      case 103: return "Email eliminado. ";
      default: return null;
    }
  }

  /**
   * 
   * @param op 
   * @returns el titulo de la operación para mostrar en el progress mientras se sube al servidor
   */
  tituloOpSubiendo(op: number): string {
    switch (op) {
      case 101: return "Agregando email... ";
      case 102: return "Modificando email... ";
      case 103: return "Eliminando email... ";
      default: return null;
    }
  }

  /**
   * 
   * @param log el log dado al cual se aplicara la key
   * @param indexToApplyKey los indices del vNuev al cual se le aplica la key 
   */
  generaPushKeyEnFirebase(log: Log, indexToApplyKey: number[]): string {
    let key = this.db.createPushId();
    for (let i = 0; i < indexToApplyKey.length; i++) {
      log.vNuev[indexToApplyKey[i]].key = key;
    }
    return key;
  }

  /**
   * 
   * @param log el log a guardar
   * @returns true si la operacion se ejecuto con exito 
   */
  escribeEnFirebase(log: Log): Promise<void> {
    let mapUpdates = {};
    for (let i = 0; i < log.vNuev.length; i++) {
      let valor = log.vNuev[i].valor;
      mapUpdates[log.vNuev[i].url + log.vNuev[i].key] = valor;
      if (valor instanceof Log && valor.vNuev) {
        valor.vNuev.forEach((element) => {
          if (element.valor instanceof Log) {
            //esto es para que no haya un loop circular pq el vNuev apunta a un log y el log tiene ese mismo vNuev
            mapUpdates[log.vNuev[i].url + log.vNuev[i].key].vNuev[i] = null;
          }
        });
      }
    }
    return this.db.object("/").update(mapUpdates);
  }

  /**
   * devuelve un valor nuevo para usar en Log de acuerdo al tipo de operacion
   * @param op 
   * @param data 
   * @param mac 
   */
  valorNuevo(op: number, data: any, mac: string, log: Log): ValorFirebase[] {
    let arre: ValorFirebase[] = [];
    if (this.validaMac(mac)) {
      switch (op) {
        case 101:
          //101: Email agregado  //data = [email, nombreServer]
          let emailDecoded = this.validaYdecodeEmail(data[0]);
          if (emailDecoded != null) {
            //rama emails
            let valor0 = new ValorFirebase();
            valor0.url = this.urlEmails + emailDecoded + "/"; //el 0 es el email 
            valor0.key = mac;
            valor0.valor = data[1]; //el valor en este tipo de operacion es el nombre del restaurant
            arre.push(valor0);


            //rama usuarios
            let valor1 = new ValorFirebase();
            valor1.url = this.urlUsuarios + mac + "/" + this.urlEmails;
            //valor1.key = se creara cuando hagamos push
            valor1.valor = { deleted: false, email: data[0], u: { ".sv": "timestamp" } };
            arre.push(valor1);

            //rama log
            let valor2 = new ValorFirebase();
            valor2.url = this.urlLogs + mac + "/";
            //valor2.key = se creara cuando hagamos push
            valor2.valor = log;

            arre.push(valor2);

            log.vNuev = arre;
            let key = this.generaPushKeyEnFirebase(log, [1, 2]);
            valor1.valor.last = key;
            //aca deberia ir para el valor0 tambien

            return arre;
          } else {
            throw "El email no es válido";
          }


        case 102:
          //102: Email modificado //data = [key ,email, nombreServer]
          let emailDecodedModificado = this.validaYdecodeEmail(data[1]);
          if (emailDecodedModificado != null) {
            if (this.validaMac(data[0])) {

              //rama emails
              let valor0 = new ValorFirebase();
              valor0.url = this.urlEmails + emailDecodedModificado + "/";
              valor0.key = mac;
              valor0.valor = data[2];
              arre.push(valor0);

              //rama usuarios
              let valor1 = new ValorFirebase();
              valor1.url = this.urlUsuarios + mac + "/" + this.urlEmails;
              valor1.key = data[0];
              valor1.valor = { email: data[1], deleted: false, u: { ".sv": "timestamp" } };
              arre.push(valor1);

              //rama log
              let valor2 = new ValorFirebase();
              valor2.url = this.urlLogs + mac + "/";
              //valor2.key = se creara cuando hagamos push
              valor2.valor = log;
              arre.push(valor2);

              log.vNuev = arre;
              let key = this.generaPushKeyEnFirebase(log, [2]);
              valor1.valor.last = key;
              //aca deberia ir para el valor0 tambien

              return arre;
            } else {
              throw "Key a eliminar inválida";
            }
          } else {
            throw "El email no es válido";
          }


        case 103:
          //103: Email eliminado //data = [email, key]
          let emailDecodedEliminado = this.validaYdecodeEmail(data[0]);
          if (emailDecodedEliminado != null) {
            if (this.validaMac(data[1])) {

              //rama emails
              let valor0 = new ValorFirebase();
              valor0.url = this.urlEmails + emailDecodedEliminado + "/"; //el 0 es el email 
              valor0.key = mac;
              valor0.valor = null; //aca debiera ser un deleted en true
              arre.push(valor0);

              //rama usuarios
              let valor1 = new ValorFirebase();
              valor1.url = this.urlUsuarios + mac + "/" + this.urlEmails;
              valor1.key = data[1];
              valor1.valor = { email: data[0], deleted: true, u: { ".sv": "timestamp" } };
              arre.push(valor1);

              //rama log
              let valor2 = new ValorFirebase();
              valor2.url = this.urlLogs + mac + "/";
              //valor2.key = se creara cuando hagamos push
              valor2.valor = log;
              arre.push(valor2);

              log.vNuev = arre;
              let key = this.generaPushKeyEnFirebase(log, [2]);
              valor1.valor.last = key;
              //aca deberia ir para el valor0 tambien

              return arre;
            } else {
              throw "Key a eliminar inválida";
            }
          } else {
            throw "El email no es válido";
          }
        default: throw "No es una operación " + op;
      }
    } else {
      throw "La mac no es válida";
    }
  }


  /**
   * 
   * @param mac
   * @param log el log reciente que genero la operacion que queremos deshacer, quizas luego se puede pasar el log anterior como un parametro, pues seguro lo tendremos leidp
   */
  async deshacerOperacionEnFirebase(mac: string, log: Log): Promise<void> {
    if (log) {
      //no puede ser null
      if (log.last) {
        //se trata de una modificacion o eliminacion

        //leer el log anterior
        let db = this.db;
        this.db.object(this.urlLogs + mac + "/" + log.last).query.once("value", action => {
          if (action && action.val()) {
            let logAnterior = <Log>action.val();
            if (logAnterior) {
              //crear un nuevo log con el mov anterior
              let logAEjecutar = new Log();
              logAEjecutar.date = { ".sv": "timestamp" };
              logAEjecutar.desc = logAnterior.desc;
              logAEjecutar.fecha = new Date().getTime();
              logAEjecutar.last = log.last;
              logAEjecutar.name = logAnterior.name;
              logAEjecutar.obs = logAnterior.obs;
              logAEjecutar.op = logAnterior.op;
              logAEjecutar.user = log.user;
              logAEjecutar.vNuev = logAnterior.vNuev;

              //ejecutarlo
              let mapUpdates = {};
              for (let i = 0; i < logAEjecutar.vNuev.length; i++) {
                mapUpdates[logAEjecutar.vNuev[i].url + logAEjecutar.vNuev[i].key] = logAEjecutar.vNuev[i].valor;
              }
              return db.object("/").update(mapUpdates);
            }
          }
        });

      } else {
        //se trata de una creacion por lo tanto la inversa es crear una eliminacion del movimiento
        let op: number = 0;
        let data;
        let mac;
        let key;
        let descripcion;
        let observacion = null;
        if (log.op == 101) {
          op = 103;
          key = log.vNuev[1].key;
          data = [log.vNuev[1].valor.email, key];
          mac = log.vNuev[0].key;
          descripcion = data[0];
        }
        if (op != 0) {
          let logEliminar: Log = await this.crearLog(mac, data, key, op, descripcion, observacion);
          if (logEliminar.cancel) {
            //error mostrar el error en log.err;
            throw "No se pudo generar la eliminación " + logEliminar.err;
          } else {
            return this.escribeEnFirebase(logEliminar);
          }
        } else {
          throw "Operacion de crear no soportada " + log.op;
        }
      }
    } else {
      throw "Registro nulo";
    }
  }

  validaMac(mac: string): boolean {
    if (mac && mac.trim().length > 3) {
      return true;
    } else {
      return false;
    }
  }

  validaYdecodeEmail(email: string): string {
    if (email && email.trim().length > 3) {
      while (email.includes('.')) {
        email = email.replace('.', ',');
      }
      return email.trim();
    } else {
      return null;
    }
  }




}
