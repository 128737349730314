import { Component, OnInit, OnDestroy } from '@angular/core';
import { RutaService } from '../../services/ruta.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../../core/auth.service';
import { EmailCuenta } from '../../shared/admin/emailCuenta';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAgentInfo, RESPONSIVE_DIRECTIVE } from 'ngx-responsive';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
  styleUrls: ['./descargas.component.scss', '../../../mypopapp/assets/_css/style.css', '../../../mypopapp/assets/_css/style1.2.css', '../../../mypopapp/assets/_css/media-queries.css']

})
export class DescargasComponent implements OnInit {

  constructor(public activatedRoute: ActivatedRoute, public rutaService: RutaService, private db: AngularFireDatabase, private authService: AuthService, private router: Router) { }

  email: string;
  nombre: string;
  windowss: boolean;
  esProbableQueNoSeDescargue: boolean;

  suscripcionUser: Subscription;
  suscripcionEmailsCuentas: Subscription;
  suscripcionParams: Subscription;
  suscripcionEmails: Subscription;

  thisUserAgent(info) {
    if (info && info.window_os && info.window_os.state && info.window_os.name && info.window_os.name.includes("indows")) {
      this.windowss = true;
      //console.log(this.activatedRoute.root);
      this.suscripcionParams = this.activatedRoute.queryParams.subscribe(params => {
        let subUrl = params['de'];
        if (subUrl && subUrl == "bienvenido") {
          //this.router.navigateByUrl('/mypopapp/' + subUrl);
          this.esProbableQueNoSeDescargue = false;
        } else {
          this.esProbableQueNoSeDescargue = true;
          window.open("https://popapp.io/descargas/setupPopApp.exe");
        }
      });
    }
  }

  ngOnDestroy(): void {
    // prevent memory leak when component is destroyed
    if (this.suscripcionUser) {
      this.suscripcionUser.unsubscribe();
    }
    if (this.suscripcionParams) {
      this.suscripcionParams.unsubscribe();
    }
    if (this.suscripcionEmailsCuentas) {
      this.suscripcionEmailsCuentas.unsubscribe();
    }
    if (this.suscripcionEmails) {
      this.suscripcionEmails.unsubscribe();
    }
  }

  ngOnInit() {
    this.rutaService.changeRuta("descargas")
    this.suscripcionUser = this.authService.user.subscribe(user => {
      if (user) {

        this.email = user.email
        this.suscripcionEmailsCuentas = this.db.object("emailsCuentas/" + this.authService.decodeEmail(user.email)).snapshotChanges().subscribe(actionLead => {
          if (actionLead && actionLead.payload && actionLead.payload.val()) {
            let emailCuenta: EmailCuenta = <EmailCuenta>actionLead.payload.val()
            this.authService.emailCuenta = emailCuenta
            if (emailCuenta.nombre) {
              //es alguien q ya lleno el formulario de bievenido, por lo tanto vamos a descargas
              this.nombre = emailCuenta.nombre

            }

            //escuchamos si hay cuentas
            this.suscripcionEmails = this.db.object("emails/" + this.authService.decodeEmail(user.email)).snapshotChanges().subscribe(cuenta => {
              if (cuenta && cuenta.payload && cuenta.payload.val()) {
                this.router.navigateByUrl('/mypopapp');
              }
            });
          }
        })
      }
    })

  }

}
