
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogFixsData } from 'src/app/services/admin-cuenta.service';

@Component({
  selector: 'app-modal-fix',
  templateUrl: './modal-fix.component.html',
  styleUrls: ['./modal-fix.component.scss']
})

export class ModalFixComponent implements OnInit {

  fixs: { nombre: string, sentencia: string }[];
  fixsObs: Observable<{ nombre: string, sentencia: string }[]>;

  constructor(
    public db: AngularFireDatabase,
    public dialogRef: MatDialogRef<ModalFixComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogFixsData
  ) { }

  ngOnInit() {
    this.buscar()

  }

  ejecutarFix(f: { nombre: string, sentencia: string }) {
    this.data.sentencia = f.sentencia;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  buscar() {
    console.log("Buscar")
    let fixsFireList = this.db.list("sentenciasFix");

    this.fixsObs = fixsFireList.snapshotChanges().pipe(
      map(changes => {
        let mapeado = changes.map(o => ({ nombre: o.payload.key, sentencia: o.payload.val() }));
        return this.sacaResumenes(mapeado);
      })
    );

  }

  sacaResumenes(mapeado): { nombre: string, sentencia: string }[] {
    // <mat-radio - button value = "1" > Custom < /mat-radio-button>
    //   < mat - radio - button value = "2" > Eliminar Fix Actual < /mat-radio-button>
    let fixs: { nombre: string, sentencia: string }[] = []
    this.fixs = []
    fixs.push({ nombre: "ELIMINAR FIX ACTUAL", sentencia: "null" })
    for (let o of mapeado) {
      fixs.push(o)
    }

    this.fixs = fixs
    //this.applyFilter(this.filtroActual);
    return fixs;
  }

}
