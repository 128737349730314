import { Injectable } from '@angular/core';
import { GooglebasechartService } from './googlebasechart.service';
import { PieChartConfig } from './pieconfig';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglepiechartService extends GooglebasechartService {

  constructor() { super(); }
  public BuildPieChart(elementId: string, data: any[], config: PieChartConfig, pieTrueBarFalse: boolean): void {
    var chartFunc;
    if (pieTrueBarFalse) {
      chartFunc = () => { return new google.visualization.PieChart(document.getElementById(elementId)); };
    } else {
      chartFunc = () => { return new google.visualization.BarChart(document.getElementById(elementId)); };
    }

    let options;
    if (config.colors) {
      options = {
        title: config.title,
        pieHole: config.pieHole,
        colors: config.colors
      };

    } else {
      options = {
        title: config.title,
        pieHole: config.pieHole,
      };
    }

    this.buildChart(data, chartFunc, options);
  }
}

