export class FormAlert {
    msg: string;
    defaultMsg: string;
    numero: boolean;
    requerido: boolean;

    constructor(msg: string,
        defaultMsg: string,
        numero: boolean,
        requerido: boolean) {
        this.msg = msg;
        this.defaultMsg = defaultMsg;
        this.numero = numero;
        this.requerido = requerido;

    }
}