import { Component, Input, OnInit } from '@angular/core';
import { Origen } from '../admin/admin-origenes/admin-origenes.component';

@Component({
  selector: 'app-origen',
  templateUrl: './origen.component.html',
  styleUrls: ['./origen.component.scss']
})
export class OrigenComponent implements OnInit {
  @Input() origen: Origen;
  @Input() expandido?: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
