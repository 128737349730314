import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Origen } from 'src/app/admin/admin-origenes/admin-origenes.component';

@Component({
  selector: 'app-modal-origenes',
  templateUrl: './modal-origenes.component.html',
  styleUrls: ['./modal-origenes.component.scss']
})
export class ModalOrigenesComponent implements OnInit {

  origenes: Origen[];
  origenSeleccionado: Origen;

  constructor(
    public dialogRef: MatDialogRef<ModalOrigenesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { origenes: Origen[], origenSeleccionado?: Origen },
  ) {
    this.origenes = data.origenes;
    this.origenSeleccionado = data.origenSeleccionado ? data.origenSeleccionado : undefined;
  }

  ngOnInit() {
  }

  elegirOrigen(o: Origen) {
    this.dialogRef.close(o);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
