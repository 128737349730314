import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { LocalAdmin } from '../../shared/admin/local-admin';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';
import { SelectionModel } from '@angular/cdk/collections';

import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Cuenta } from '../../shared/cuenta';


import { AuthService } from "../../core/auth.service";
import { CuentaService } from '../../services/cuenta.service';
import { Router } from '@angular/router';
import { FormAlert } from '../../shared/admin/formalert';
import * as consts from "../../shared/constantes";
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-admin-celu',
  templateUrl: './admin-celu.component.html',
  styleUrls: ['./admin-celu.component.scss']
})

export class AdminCeluComponent implements OnInit {

  environment = environment;

  cuentasRef;

  cotizacionUF;

  cantLeidas: number = 0;

  cuentas: CuentaAdmin[];
  cuentasMap: Map<String, CuentaAdmin>;
  locales: Map<String, LocalAdmin>;
  servidores: ServidorAdmin[] = [];

  itemsRef: AngularFireList<any>;
  itemsRefIndx: AngularFireList<any>[];

  items: Observable<any[]>;
  itemsBuscador: Observable<any[]>[];

  cuentasIndx: Map<string, string> = new Map<string, string>();
  cuentasIndxArre: { k: string, n: string }[];
  total: number;
  msjSnack: string;

  fecha: any;
  //selectedOptions: ServidorAdmin[];
  @ViewChild(MatSort) sort: MatSort;


  email: string;
  selected: string;
  cuenta: BehaviorSubject<CuentaAdmin> = new BehaviorSubject(null);
  servidor: BehaviorSubject<ServidorAdmin> = new BehaviorSubject(null);
  //datos tabla

  displayedColumns: string[] = ['nombreCuenta', 'nombreLocal', 'nombreServidor',
    'numeroLocal',//5


    'pagoHasta',
    "diaReal",
    'vencimiento',
    'dias',
    'fechaUltimoPago',//10

    'pais',
    'ciudad',//12

    'moneda',
    'importe',
    'pagaIva',
    'showPagar',//16

    'idMac',
    'tester',
    'fix',

    // 'whatsapp',
    'contacto',//23

    'fiscal',
    "fearg",
    "rut",
    //  'estado',
    'planN',
    //'fechaInstalacionyyyyMMddd',
    //"fechaConversion",
    //'importeQDeberia',
    //'detalleImporte',
    //"fechaFinPruebaFull",//33
    // 'pya',
    //"lat",
    //"lng",
    //"addImporte",//37

    "versionActual",
    "versionDisponible",
    //"fechaActualizado",
    //"fechaUltimoCheck",
    "tag"
    //,
    //'idCuenta',
    //'idLocal',//21

  ];



  columnsToDisplay: string[] = this.displayedColumns;

  dataSource: MatTableDataSource<ServidorAdmin> = new MatTableDataSource(this.servidores);


  filtroActual2: string = "";
  buscandoActual: string;

  selection = new SelectionModel<ServidorAdmin>(true, []);




  driftVar: any;

  hideDrift() {
    if (this.driftVar) {
      this.driftVar.hide();
    } else {
      this.driftVar = window["drift"];
      setTimeout(() => { this.hideDrift() }, 600)
    }
  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }
  ngOnInit() {
    this.hideDrift();

    //console.log("ENTRO");
    this.afAuth.user.subscribe(user => {
      if (user && user.email && consts.esAdmin(user)) {
        // console.log("SUSCRIPTO");
        this.email = user.email;
      }
    });
  }

  sortHeader(index: number) {
    //console.log("click");
    this.dataSource.sort = this.sort;
  }
  buscar(filtroActual2) {
    if (filtroActual2.trim().length > 0) {
      let f = this.sinAcentos(filtroActual2.trim()).split(" ");
      let i = 0;
      this.itemsRefIndx = [];
      for (let keyWord of f) {
        this.itemsRefIndx[i] = this.db.list('indexCuentas', ref => {// Use snapshotChanges().map() to store the key
          return ref.orderByKey()
            .startAt(keyWord)
            .endAt(keyWord + "\uf8ff")
        })
        i++;
      }

      this.cuentas = null;
      this.cuenta.next(null)
      this.total = i;
      this.cuentasIndx.clear();
      this.cuentasIndxArre = []
      this.selected = null;
      i = 0
      this.itemsBuscador = [];

      for (let itRef of this.itemsRefIndx) {
        this.itemsBuscador[0] = itRef.snapshotChanges().pipe(
          map(changes => {
            let mapeado = changes.map(c => ({ key: c.payload.val().n, ...c.payload.val() }));
            this.sacaCuentasIndx(mapeado, i);
            return mapeado;
          })
        );
        i++
      }
    } else {
      this.openSnackBar("Filtra por algun campo", "");
      /*this.itemsRef = this.db.list('cuentas');
      // Use snapshotChanges().map() to store the key

      this.items = this.itemsRef.snapshotChanges().pipe(
        map(changes => {
          let mapeado = changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
          //console.log("LLEGO ALGO");

          this.sacaLocalesYServidores(mapeado);
          this.dataSource.sort = this.sort;
          return mapeado;
        })
      );*/
    }
  }

  sacaCuentasIndx(map: any[], i: number) {
    console.log(i, map);
    this.total--;

    for (let aux in map) {
      this.cuentasIndx.set(map[aux].k, map[aux].key);
      if (!this.selected) {
        this.selected = map[aux].k;
      }
    }


    // if (this.total <= 0) {
    let j = 0;
    this.cuentasIndx.forEach((v, k) => {
      this.cuentasIndxArre[j] = { k: k, n: v }
      j++
    });
    //}
    this.seleccionar();
  }

  seleccionar() {
    //termino de traer los datos

    if (this.selected) {
      let contexto = this;
      let cRef = this.db.object("cuentas/" + this.selected);

      cRef.query.once("value", function (snap) {
        //leemos la fecha actual

        let c: CuentaAdmin = snap.val();
        contexto.sacaLocalesYServidores([{ key: snap.key, ...c }]);
      });
    } else {
      this.cuenta.next(null);
      this.servidores = [];
    }
  }

  constructor(public router: Router, public afAuth: AngularFireAuth, public cuentaService: CuentaService, public db: AngularFireDatabase, private http: Http, public authService: AuthService) {

  }

  sinAcentos(str: string): string {
    const str2 = str.replace(".", ",");
    return str2.normalize('NFD').replace(/[\u00C0-\u00FF]/g, '').toLowerCase();
  }

  selectCuenta(cuentaAdmin: ServidorAdmin) {
    let cuenta: Cuenta = new Cuenta();
    cuenta.id = cuentaAdmin.idMac;
    cuenta.name = cuentaAdmin.nombreCuenta;
    this.authService.changeCuenta(cuenta);
  }

  async clickPagar(s: ServidorAdmin, email: string, cantMeses: number) {
    console.log(cantMeses);
    let idMP: string;
    let pais: string;
    if (!s.pais) {
      pais = "ARG";
    }
    let mostrarSoloPayPal = false;
    if (s.pais == "ARG" || s.pais == "AR") {
      idMP = "1392845136";
    } else if (s.pais == "CHI" || s.pais == "CL") {
      idMP = "349794797";
    } else if (s.pais == "PER" || s.pais == "PE") {
      mostrarSoloPayPal = true;
    } else if (s.pais == "ARGPRUEBA") {
      idMP = "350425595";
    } else if (s.pais == "CHIPRUEBA") {
      idMP = "350419662";
    } else {
      mostrarSoloPayPal = true;
    }


    let c: CuentaAdmin = this.cuentasMap.get(s.idCuenta);
    this.cuentaService.mensajeAVerificarAntesDeCobrar(c, cantMeses);
    if (!c.error || c.error.length == 0) {
      if (c.moneda && c.pagoHasta) {
        if (mostrarSoloPayPal) {
          this.openSnackBar("No se puede generar link de PayPal", "");
        } else {
          let urlAPagar = environment.urls.escuchador + "/mporden/" + idMP + "/pos/" + c.id;
          //let urlAPagar = "http://localhost:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
          //let urlAPagar = "http://192.168.10.113:3000/mporden/" + idMP + "/pos/" + this.idCuenta;
          let body = { items: [], email_payer: '' };

          let totalMensual: number = c.totalApagarConIva;

          if (c.moneda === 'UF' && !this.cotizacionUF) {
            this.openSnackBar("No se puede con moneda UF", "")
          }
          if (c.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
            body.items[0] = {
              "currency_id": c.moneda,
              "unit_price": totalMensual * this.cotizacionUF / cantMeses,
              "quantity": cantMeses
            };
          } else {
            body.items[0] = {
              "currency_id": c.moneda,
              "unit_price": totalMensual / cantMeses,
              "quantity": cantMeses

            };
          }

          if (c.moneda && c.moneda === 'UF' && this.cotizacionUF && this.cotizacionUF >= 25000) {
            body.items[0].cotiz = this.cotizacionUF;
          }

          body.email_payer = email;

          this.authService.setDataAPagar(urlAPagar, body, c.id, c.ultimoPago, c.moneda, c.pais);

          this.router.navigateByUrl('/mypopapp/micuenta/pagar');
        }
      } else {
        console.log(c.nombreCuenta + " no tiene todos los atributos (moneda, pagoHasta): ", c);
        this.openSnackBar(c.nombreCuenta + " no tiene todos los atributos (moneda, pagoHasta)", c.nombreCuenta);
      }
    } else {
      console.log("Error cuenta " + c.nombreCuenta + ": " + c.error);
      this.openSnackBar("Error cuenta " + c.nombreCuenta + ": ", c.error);
    }
  }

  onRightClick(input: string, element: ServidorAdmin) {
    if (input == "cobrar") {
      //leer mails de ese usuario

      //proponer un promt con el 1ero por defecto encontrado
      let email = prompt("Generar link de pago para " + element.nombreCuenta + ":", "p@p");
      if (email != null && email != "") {
        let cantMesesAPagarA = 1;
        if (element.tag && element.tag.includes("planal1D")) {
          cantMesesAPagarA = 2;
        }
        let cantMeses = prompt("Cantidad de meses a pagar", "" + cantMesesAPagarA);
        if (cantMeses != null) {
          //generar link de pago
          this.clickPagar(element, email, +cantMeses);
        }

      }
      return false;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  /**
   * Revisa los servidores seleccionados mas el ultimo clickeado y revisa de acurdo al boton tocado que valor es el que hay que cambiar, lo valida y lo modifica en FireBase
   * 
   * @param input el tipo de atributo que estamos por modificar
   * @param element el servidor que hemos tocado al ultimo
   */
  inputABM(input: string, element: ServidorAdmin) {
    let mensaje: string = "";
    let servAModificar: ServidorAdmin[] = [];
    let mensajeLocales: string = "";
    if (this.selection.selected && this.selection.selected.length > 0) {
      for (let i = 0; i < this.selection.selected.length; i++) {
        mensaje += "\n " + this.selection.selected[i].nombreServidor;
        mensajeLocales += "\n " + this.selection.selected[i].nombreLocal;
        servAModificar.push(this.selection.selected[i]);
      }
      if (!this.selection.selected.includes(element)) {
        mensaje += "\n " + element.nombreServidor;
        mensajeLocales += "\n " + element.nombreLocal;
        servAModificar.push(element);
      } //else ya se proceso en el for
    } else {
      //proceso individual
      servAModificar.push(element);
      mensaje += "\n " + element.nombreServidor;
      mensajeLocales += "\n " + element.nombreLocal;
    }


    if (input == 'nombreServidor') {
      let valor = prompt("Cambiar el nombreServidor de " + servAModificar.length + ":" + mensaje, servAModificar[0].nombreServidor);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'versionDisponible') {
      let valor = prompt("Cambiar versionDisponible de " + servAModificar.length + ":" + mensaje, servAModificar[0].versionDisponible);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'tag') {
      let valor = prompt("Cambiar tag de " + servAModificar.length + ":" + mensaje, servAModificar[0].tag);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'pagaIva') {
      let valor = prompt("Cambiar % IVA que paga de " + servAModificar.length + ":" + mensaje, "21");
      if (valor != null && valor != "") {
        let porcIva: number = +valor;
        this.validarYEscribirEnFBServidor(porcIva, true, input, servAModificar);
      }
    } else if (input == 'tester') {
      let valor = prompt("Cambiar Tester de " + servAModificar.length + ": 0 es rama ppal" + mensaje, "0");
      if (valor != null && valor != "") {
        let testerV: number = +valor;
        this.validarYEscribirEnFBServidor(testerV, true, input, servAModificar);
      }
    } else if (input == 'fiscal') {

      let valor = prompt("Cambiar Fiscal de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (valor != null && valor != "") {
        if (valor == 's' || valor == 'n' || valor == 'S' || valor == 'N') {
          //modificar fiscal
          let fiscalActivado: boolean;
          if (valor == 's' || valor == 'S') {
            fiscalActivado = true;
          } else {
            fiscalActivado = false;
          }
          this.validarYEscribirEnFBServidor(fiscalActivado, false, input, servAModificar);
        } else {
          this.openSnackBar("", "Escribir s ó n");
        }
      }
    } else if (input == 'importe') {
      let valor = prompt("Cambiar Importe de " + servAModificar.length + ":" + mensaje, "0");
      if (valor != null && valor != "") {
        let numberNuevo: number = +valor;
        this.validarYEscribirEnFBServidor(numberNuevo, true, input, servAModificar);
      }
    } else if (input == 'estado') {
      let valor = prompt("Cambiar Estado de " + servAModificar.length + "\nValores válidos: Desactualizado, DatosSinVerificar, DatosVerificados, ProbandoPremium, PruebaPremiumCaducada, LicenciaActivada, ClienteActivo, Churn" + mensaje, "LicenciaActivada");
      if (valor != null && valor != "") {
        if (valor == 'Desactualizado' || valor == 'DatosSinVerificar' || valor == 'DatosVerificados' || valor == 'ProbandoPremium' || valor == 'PruebaPremiumCaducada' || valor == 'LicenciaActivada' || valor == 'ClienteActivo' || valor == 'Churn') {
          this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
        } else {
          this.openSnackBar("", "Ningún valor adecuado, revisar en el cartel antes de escribir");
        }
        //Desactualizado, DatosSinVerificar, DatosVerificados, ProbandoPremium, PruebaPremiumCaducada, LicenciaActivada, ClienteActivo, Churn
      }
    } else if (input == 'fix') {
      let valor = prompt("Cambiar FIX de " + servAModificar.length + ":" + mensaje, "");
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'planN') {
      let valor = prompt("Cambiar Plan de " + servAModificar.length + ":" + mensaje, "Básico");
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'pagoHasta') {
      let valor = prompt("Cambiar Fecha de Ultimo Pago de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, "dd/11/2018");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'vencimiento') {
      let valor = prompt("Cambiar Fecha de Vencimiento de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, "dd/01/2019");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
        console.log("Modificando " + input + "... a " + valor);
        for (let i = 0; i < servAModificar.length; i++) {
          let s: ServidorAdmin = servAModificar[i];
          if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
            this.escribePagos(s, valor)
          } else {
            this.openSnackBar("Key Invalida...idCuenta, idLocal, idMac: " + s.idCuenta + ", " + s.idLocal + ", " + s.idMac, "Avisar GAMR");
            console.log("Key Invalida", s);
          }
        }
      }
    } else if (input == 'fechaCambioPlanMap') {
      let valor = prompt("Cambiar Fecha de CAMBIO de Plan de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, "dd/11/2018");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'fechaInstalacion') {
      let valor = prompt("Cambiar Fecha de CAMBIO de Plan de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, "dd/11/2018");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'lat') {
      let valor = prompt("Cambiar Lat de " + servAModificar.length + ": 0 es rama ppal" + mensaje, "" + element.lat);
      if (valor != null && valor != "") {
        let testerV: number = +valor;
        this.validarYEscribirEnFBServidor(testerV, true, input, servAModificar);
      }
    } else if (input == 'lng') {
      let valor = prompt("Cambiar Lng de " + servAModificar.length + ": 0 es rama ppal" + mensaje, "" + element.lng);
      if (valor != null && valor != "") {
        let testerV: number = +valor;
        this.validarYEscribirEnFBServidor(testerV, true, input, servAModificar);
      }
    } else if (input == 'nombreCuenta') {
      let valor = prompt("Cambiar Nombre Cuenta de " + servAModificar.length + ":" + mensaje, element.nombreCuenta);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBCuenta(valor, false, input, servAModificar);
      }
    } else if (input == 'nombreLocal') {
      let valor = prompt("Cambiar Nombre Local de " + servAModificar.length + ":" + mensaje, element.nombreLocal);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBLocal(valor, false, input, servAModificar);
      }
    } else if (input == 'numeroLocal') {
      let valor = prompt("Cambiar Número de Local de " + servAModificar.length + ":\n-1 si fué cliente\n0 si no es ni fué\n>0 si es cliente actual\n-2 reseller\n-3 developers\n-4 cambio de PC" + mensajeLocales, "" + element[input]);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'codigo') {
      let codigo: string = this.cuentaService.generaCódigoConNuevaFechayPlan(element);
      console.log(codigo);
      let res = this.cuentaService.descifraCódigoConNuevaFechayPlan(codigo, element);
      console.log(res);
      this.copyToClipboard(codigo);
      this.openSnackBar("Código copiado al portapeles para " + res.fechaddMMyyyy + " , plan: " + this.cuentaService.planNumberToString(res.plan), codigo);
    } else if (input == 'valorDolar') {
      let valor = prompt("Cambiar ValorDolar de " + servAModificar.length + ":" + mensaje, "0");
      if (valor != null && valor != "") {
        let numberNuevo: number = +valor;
        this.validarYEscribirEnFBServidor(numberNuevo, true, input, servAModificar);
      }
    } else if (input == 'pais') {
      let valor = prompt("Cambiar País de " + servAModificar.length + ":" + mensaje, "ARG");
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'fechaConversion') {
      let valor = prompt("Cambiar fechaConversion de (yyyy/MM/dd)" + servAModificar.length + ":" + mensaje, element.fechaConversion);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'ciudad') {
      let valor = prompt("Cambiar País de " + servAModificar.length + ":" + mensaje, "ARG");
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'addImporte') {
      let valor = confirm("Agregar un Importe mensual, Cuota o Mac para: " + servAModificar.length + ":" + mensaje);
      if (valor) {
        let keysQNoPuedenSer = "";
        const cuentaPadre = this.cuentas.find(i => element.idCuenta == i.id);
        for (let keyLocal in cuentaPadre.locales) {
          if (keyLocal == element.idLocal) {
            for (let mac in cuentaPadre.locales[keyLocal].servidores) {
              keysQNoPuedenSer += mac + "  -  "
            }
          }
        }
        const key = prompt("key o mac (Tiene q se unica, sin caracteres especiales) no puede ser: " + keysQNoPuedenSer, "moduloFEArg");
        if (key != null && key != "" && !keysQNoPuedenSer.includes(key)) {
          let newServidorOImporte: any = {};
          newServidorOImporte.nombreServidor = prompt("Nombre del concepto a cobrar o del Servidor a cobrar", "Módulo Factura Electrónica");
          let cuotas = +prompt("Cuotas a cobrar (number) (0 es infinito, como un servidor o un modulo que se cobra indefinidamente)", "0");
          if (cuotas != 0) {
            newServidorOImporte.cuotasPendientes = cuotas;
          }
          newServidorOImporte.importe = +prompt("Importe a cobrar por mes(number) en " + element.moneda, "0");
          newServidorOImporte.importeQDeberia = +prompt("Importe Q deberia (si hay un desc y este importe es mayor q " + newServidorOImporte.importe + " entonces se muestra un descuento", "" + newServidorOImporte.importe);
          newServidorOImporte.moneda = element.moneda;
          newServidorOImporte.numeroLocal = "9050";//pues no deberia contar en estadisticas
          newServidorOImporte.pagaIva = +prompt("Iva (21 si se quiere cobrar iva en arg)", "0");
          newServidorOImporte.pagoHasta = element.pagoHasta;
          newServidorOImporte.pais = element.pais;
          newServidorOImporte.showPagar = true;
          this.validarYEscribirEnFBLocal(newServidorOImporte, false, "servidores/" + key, servAModificar);

        } else {
          if (key != null && key != "") {
          } else {
            this.openSnackBar("", "Escribir Una Key Q No Este Repetida");
          }
        }
      }
    } else if (input == 'fearg') {
      let activar = prompt("Cambiar Activado FEArg de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (activar != null && activar != "") {
        if (activar == 's' || activar == 'n' || activar == 'S' || activar == 'N') {
          //modificar fiscal
          let bool: boolean;
          if (activar == 's' || activar == 'S') {
            bool = true;
          } else {
            bool = false;
          }
          if (bool) {
            //se viene la ola de alerts para cada atributo
            //fearg: { activado: boolean, cuit: string, email: string, password: string, razonSocial: string, domicilio: string, responsabilidad: string, ptoVta: number };
            let feargAux: any = {};
            feargAux.activado = true;
            feargAux.cuit = prompt("cuit", element.fearg ? element.fearg.cuit : "");
            feargAux.email = prompt("email", element.fearg ? element.fearg.email : "");
            feargAux.password = prompt("password", element.fearg ? element.fearg.password : "");
            feargAux.razonSocial = prompt("razonSocial", element.fearg ? element.fearg.razonSocial : "");
            feargAux.domicilio = prompt("domicilio", element.fearg ? element.fearg.domicilio : "");
            feargAux.responsabilidad = prompt("responsabilidad", element.fearg ? element.fearg.responsabilidad : "");
            feargAux.ptoVta = +prompt("ptoVta (number)", element.fearg ? element.fearg.ptoVta + "" : "0");
            feargAux.idPtoVta = +prompt("idPtoVta (number)", element.fearg ? element.fearg.idPtoVta + "" : "0");
            feargAux.idPais = 10; //Arg
            feargAux.idProvincia = +prompt("provincia:  1 BsAs - 2 Ciudad de BsAs - 3 Catamarca - 4 Chaco - 5 Chubut - 6 Córdoba - 7 Corrientes - 8 E.Rios - 9 Formosa - 10 Jujuy - 11 La Pampa - 12 La Rioja - 13 Mendoza - 14 Misiones - 15 Neuquen - 16 R.Negro - 17 Salta - 18 S.Juan - 19 S.Luis - 20 S.Cruz - 21 S.Fe - 22 S.del Estero - 23 T.del Fuego - 24 Tucuman"
              , element.fearg ? element.fearg.idProvincia + "" : "1");
            feargAux.nroLocalFea = +prompt("nroLocalFea: (Numero de local para distinguir entre varios servidores de una misma cuenta de contabilium) ", element.fearg ? element.fearg.nroLocalFea + "" : "1");
            feargAux.ingresosBrutos = prompt("ingresosBrutos", element.fearg ? element.fearg.ingresosBrutos + "" : "");
            feargAux.fechaInicioActividades = prompt("fechaInicioActividades", element.fearg ? element.fearg.fechaInicioActividades + "" : "");
            feargAux.actualizado = true;
            this.validarYEscribirEnFBServidor(feargAux, false, input, servAModificar);
            this.inputABM("addImporte", element);
          } else {
            this.validarYEscribirEnFBServidor(bool, false, input + "/activado", servAModificar);
            this.validarYEscribirEnFBServidor(true, false, input + "/actualizado", servAModificar);
          }
        } else {
          this.openSnackBar("", "Escribir s ó n");
        }
      }

    } else if (input == 'fechaUltimoPago') {
      let valor = prompt("Cambiar Fecha de Ultimo Pago de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, "dd/11/2018");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'fechaFinPruebaFull') {
      let valor = prompt("Cambiar Fecha de Fin de Prueba de " + servAModificar.length + ": (dd/mm/yyyy)" + mensaje, "dd/11/2018");
      if (valor != null && valor != "") {
        //TODO aca podemos validar mejor la fecha ingresada
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'moneda') {
      let valor = prompt("Cambiar Moneda de " + servAModificar.length + ":" + mensaje, "ARS $");
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'showPagar') {

      let valor = prompt("Cambiar ShowPagar de " + servAModificar.length + ": ESCRIBIR s ó n (s/n)" + mensaje, "s");
      if (valor != null && valor != "") {
        if (valor == 's' || valor == 'n' || valor == 'S' || valor == 'N') {
          //modificar fiscal
          let bool: boolean;
          if (valor == 's' || valor == 'S') {
            bool = true;
          } else {
            bool = false;
          }
          this.validarYEscribirEnFBServidor(bool, false, input, servAModificar);
        } else {
          this.openSnackBar("", "Escribir s ó n");
        }
      }
    } else if (input == 'importeQDeberia') {
      let valor = prompt("Cambiar Importe Que Deberia Pagar de " + servAModificar.length + ":" + mensaje, "0");
      if (valor != null && valor != "") {
        let numberNuevo: number = +valor;
        this.validarYEscribirEnFBServidor(numberNuevo, true, input, servAModificar);
      }
    } else if (input == 'detalleImporte') {
      let valor = prompt("Cambiar Detalle que acompaña el Importe de " + servAModificar.length + ":" + mensaje, element.detalleImporte);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'whatsapp') {
      let valor = prompt("Cambiar Whatsapp de " + servAModificar.length + ":" + mensaje, servAModificar[0].whatsapp);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBCuenta(valor, false, input, servAModificar);
      }
    } else if (input == 'rut') {
      let valor = prompt("Cambiar RUT de " + servAModificar.length + ":" + mensaje, servAModificar[0].rut);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBServidor(valor, false, input, servAModificar);
      }
    } else if (input == 'contacto') {
      let valor = prompt("Cambiar Contacto de " + servAModificar.length + ":" + mensaje, servAModificar[0].contacto);
      if (valor != null && valor != "") {
        this.validarYEscribirEnFBCuenta(valor, false, input, servAModificar);
      }
    } else if (input == 'cobrar') {
      let cuenta: CuentaAdmin = this.cuentasMap.get(servAModificar[0].idCuenta);

      if (servAModificar.length == 1 && cuenta) {
        //cantidad de meses
        let mesesString = prompt("Cuantos meses quieres cobrar de la cuenta " + servAModificar[0].nombreCuenta + "?", "1");
        if (mesesString != null && mesesString != "") {
          let meses = +mesesString;
          if (!isNaN(meses) && meses > 0 && meses < 11) {
            let mensajeAVerificar = this.cuentaService.mensajeAVerificarAntesDeCobrar(cuenta, meses);
            let tipoPago = prompt(mensajeAVerificar + "\n\nTipo de Cobro: Efectivo, Tarjeta, Mercado Pago, Mercado Pago Manual, Paypal, Deposito...?", "Efectivo");
            if (tipoPago != null && tipoPago != "") {
              this.cuentaService.cobrar(cuenta, meses, tipoPago)
                .then(_ => {
                  this.openSnackBar("", "Exito al grabar");
                })
                .catch(e => {
                  this.openSnackBar("", "Error al grabar");
                  console.log(e);
                });
            } else {
              this.openSnackBar("", "Valor nulo");
            }
          } else {
            this.openSnackBar("", "Meses no es un numero válido entre 1 y 10");
          }
        } else {
          this.openSnackBar("", "Valor nulo");
        }
      } else {
        this.openSnackBar("", "Cuenta null o Solo elegir 1 servidor para cobrar la cuenta entera");
      }
    }
  }

  valorNumeroLocal(numLocal: number): string {
    if (numLocal != null) {
      if (numLocal == -1) {
        return "Ya NO es Cliente";
      } else if (numLocal == -2) {
        return "Reseller";
      } else if (numLocal == -3) {
        return "Developer";
      } else if (numLocal == -4) {
        return "Cambió de PC";
      } else if (numLocal == -5) {
        return "Pago por cuotas pendientes";
      } else {
        return "" + numLocal;
      }
    } else {
      return "";
    }
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }


  validarYEscribirEnFBLocal(valor: any, validarNumero: boolean, input: string, servAModificar: ServidorAdmin[]) {
    if (!validarNumero || !isNaN(valor)) {
      console.log("Modificando " + input + "... a " + valor);
      let mapUpdates = {};
      for (let i = 0; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal)) {
          mapUpdates[s.idCuenta + "/locales/" + s.idLocal + "/" + input + "/"] = valor;
        } else {
          this.openSnackBar("Key Invalida...idCuenta, idLocal: " + s.idCuenta + ", " + s.idLocal, "Avisar GAMR");
          console.log("Key Invalida", s);
        }
      }
      this.itemsRef.update("/", mapUpdates)
        .then(_ => { this.openSnackBar("", "Exito") })
        .catch(err => { this.openSnackBar("No pudo grabar " + input, "Avisar GAMR"); console.log(err) });
    } else {
      this.openSnackBar("", "No es número");
    }
  }



  validarYEscribirEnFBCuenta(valor: any, validarNumero: boolean, input: string, servAModificar: ServidorAdmin[]) {
    if (!validarNumero || !isNaN(valor)) {
      console.log("Modificando " + input + "... a " + valor);
      let mapUpdates = {};
      for (let i = 0; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta)) {
          mapUpdates[s.idCuenta + "/" + input + "/"] = valor;
        } else {
          this.openSnackBar("Key Invalida...idCuenta: " + s.idCuenta, "Avisar GAMR");
          console.log("Key Invalida", s);
        }
      }
      this.itemsRef.update("/", mapUpdates)
        .then(_ => { this.openSnackBar("", "Exito") })
        .catch(err => { this.openSnackBar("No pudo grabar " + input, "Avisar GAMR"); console.log(err) });
    } else {
      this.openSnackBar("", "No es número");
    }
  }

  validarYEscribirEnFBServidor(valor: any, validarNumero: boolean, input: string, servAModificar: ServidorAdmin[]) {
    if (!validarNumero || !isNaN(valor)) {
      console.log("Modificando " + input + "... a " + valor);
      let mapUpdates = {};
      for (let i = 0; i < servAModificar.length; i++) {
        let s: ServidorAdmin = servAModificar[i];
        if (this.keyValida(s.idCuenta) && this.keyValida(s.idLocal) && this.keyValida(s.idMac)) {
          mapUpdates[s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/" + input + "/"] = valor;
        } else {
          this.openSnackBar("Key Invalida...idCuenta, idLocal, idMac: " + s.idCuenta + ", " + s.idLocal + ", " + s.idMac, "Avisar GAMR");
          console.log("Key Invalida", s);
        }
      }

      this.itemsRef.update("/", mapUpdates)
        .then(_ => { this.openSnackBar("", "Exito") })
        .catch(err => { this.openSnackBar("No pudo grabar " + input, "Avisar GAMR"); console.log(err) });

    } else {
      this.openSnackBar("", "No es número");
    }
  }

  diasADar: string;

  darDias(diasADar: string, c: CuentaAdmin) {
    try {
      let dias: number = +diasADar;
      let sumar = dias - c.dias;
      let arre = c.venceAux.split("/");
      let date: Date = new Date();
      date.setFullYear(+arre[2]);
      date.setMonth(+arre[1] - 1);
      date.setDate(+arre[0]);
      date.setDate(date.getDate() + sumar);
      let nuevaFecha = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      let maoUpdates = {};
      for (let idLocal in c.locales) {
        for (let idMac in c.locales[idLocal].servidores) {
          let servidor = c.locales[idLocal].servidores[idMac];
          if (this.cuentaService.mostrarParaPagar(servidor)) {
            maoUpdates[c.id + "/locales/" + idLocal + "/servidores/" + idMac + "/vencimiento"] = nuevaFecha;
          }
        }
      }
      console.log(maoUpdates);
      this.db.object("/").update(maoUpdates)
        .then(a => {
          console.log("EXito")
          this.openSnackBar("Grabado", nuevaFecha);
        })
        .catch(e => {
          console.log(e);
          this.openSnackBar("Error, No se pudo realizar", e);
        })

    } catch (e) {
      console.log(e);
      this.openSnackBar("Error, No se pudo realizar", e);
    }
  }
  /**
   * valida que la key sea != null y con length mayor a 3
   */
  keyValida(key: string): boolean {
    if (key != null && key.length > 3) {
      return true;
    } else {
      return false;
    }
  }




  async sacaLocalesYServidores(mapCuentas: any[]) {
    let hoyDia: number = new Date().getDate();
    let hoyMes: number = new Date().getMonth();
    let hoyAnio: number = new Date().getFullYear();
    //console.log("empieza a sacar");
    //for (let i = 0; i < this.servidores.length;) {
    //this.servidores. pop();
    //}
    this.servidores = [];
    this.locales = new Map<string, LocalAdmin>();
    this.cuentasMap = new Map<string, CuentaAdmin>();
    this.cuentas = [];
    //for de cuentas.
    this.cuenta.next(null);
    this.servidor.next(null);
    for (let i in mapCuentas) {
      let c: CuentaAdmin = mapCuentas[i];
      if (!this.cuenta.value) {
        this.cuenta.next(c);
      }

      c.id = mapCuentas[i].key;
      this.cuentasMap.set(mapCuentas[i].key, c);
      let mapLocales = mapCuentas[i].locales;
      //for de locales
      let indexLocal = 0;
      let totalLocales = 0;
      for (let j in mapLocales) {
        totalLocales++;
      }
      for (let j in mapLocales) {
        indexLocal++;
        let l: LocalAdmin = mapLocales[j];
        l.id = j;
        l.idCuenta = c.id;
        l.totLocales = totalLocales;


        //for de servidores
        let mapServidores = mapLocales[j].servidores;
        let indexServidor = 0;
        let totalServidores = 0;
        for (let y in mapServidores) {
          totalServidores++;
        }
        for (let y in mapServidores) {
          indexServidor++;
          let s: ServidorAdmin = mapServidores[y];
          s.idMac = y;
          s.idLocal = l.id;
          s.idCuenta = c.id;
          s.uCuenta = c.u;
          s.lastCuenta = c.last;
          if (!this.servidor.value) {
            this.servidor.next(s);
          }
          if (c.whatsapp) {
            s.whatsapp = c.whatsapp;
          }
          if (c.contacto) {
            s.contacto = c.contacto;
          }
          s.nombreCuenta = c.nombreCuenta;
          s.nombreLocal = l.nombreLocal;
          s.indServidor = indexServidor;
          s.indLocal = indexLocal;
          s.totLocales = totalLocales;
          s.totServidoresOSvs = totalServidores;



          let dias = this.diasss(s.vencimiento, hoyAnio, hoyMes, hoyDia)
          s.dias = dias;
          if (!c.dias || c.dias < s.dias) {
            c.dias = s.dias;
            c.venceAux = s.vencimiento;
            this.diasADar = c.dias + "";
          }

          let diasReal = this.diasss(s.pagoHasta, hoyAnio, hoyMes, hoyDia)
          s.diasReal = diasReal


          s.fechaInstalacionyyyyMMddd = this.fechaDeddMMyyyyAyyyyMMdd(s.fechaInstalacion);

          this.servidores.push(s);

          if (s.numeroLocal && s.moneda && +s.numeroLocal > 0 && +s.numeroLocal < 9000 && +s.numeroLocal !== 900 && (s.cuotasPendientes == null)) { //1500 para arriba seria para testear pero no para sumar

          }

        }

        //  l.servidoresIds = asdasdasd;
        this.locales.set(l.id, l);
      }

      // c.localesIds = asdasd;
      this.cuentas.push(c);
    }


    this.servidores.sort(function (s1: ServidorAdmin, s2: ServidorAdmin) {
      return s2.dias - s1.dias;
    });

    this.dataSource = new MatTableDataSource(this.servidores);
  }

  diasss(fecha: string, hoyAnio, hoyMes, hoyDia): number {
    let fv: string = fecha;
    if (!fv) {
      fv = "1/1/1999";
    }
    let indexBarra: number = fv.indexOf("/");
    let iFor = 0;
    let stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let dia: number = Number(stringAuxFecha);

    fv = fv.replace(stringAuxFecha + "/", "");
    indexBarra = fv.indexOf("/");
    iFor = 0;
    stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let mes: number = Number(stringAuxFecha);
    fv = fv.replace(stringAuxFecha + "/", "");
    let anio: number = Number(fv);

    //let mes: number = Number(fv.charAt(3) + fv.charAt(4));
    //let anio: number = Number(fv.charAt(6) + fv.charAt(7) + fv.charAt(8) + fv.charAt(9));
    let dias = (anio - hoyAnio) * 365;
    dias += (mes - 1 - hoyMes) * 30;
    dias += (dia - hoyDia);
    return dias;
  }

  getMyStyles(dias: number) {
    let color = 'green';
    if (dias < 0 && dias > -8) {
      color = 'violet';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': 'white',
      'background': color,
      'padding': '10px'
    };
    return myStyles;
  }

  fechaDeddMMyyyyAyyyyMMdd(fechaInstalacion: string): string {
    if (fechaInstalacion) {
      let array = fechaInstalacion.split("/");
      if (array && array.length == 3) {
        return array[2] + "/" + (array[1].length > 1 ? array[1] : "0" + array[1]) + "/" + (array[0].length > 1 ? array[0] : "0" + array[0]);
      }
    }
    return "";
  }
  editar() {
    if (this.fecha && this.fecha instanceof Date && this.selection.selected && this.selection.selected.length > 0) {
      let dia: number = this.fecha.getDate();
      let mes: number = this.fecha.getMonth() + 1;
      let anio: number = this.fecha.getFullYear();

      let fechaString: string = "";
      if (dia < 10) {
        fechaString += "0";
      }
      fechaString += dia + ";";
      if (mes < 10) {
        fechaString += "0";
      }
      fechaString += mes + ";";
      fechaString += anio;

      let fechaNormString: string = dia + "/" + mes + "/" + anio;

      let stringServidores: string = "";
      for (let i = 0; i < this.selection.selected.length; i++) {
        let s: ServidorAdmin = this.selection.selected[i];
        stringServidores += s.nombreServidor + "\n";
      }

      if (fechaString.length == 10) {
        if (confirm("Asignar fecha: " + fechaNormString + " (" + fechaString + ") a:\n" + stringServidores)) {
          for (let i = 0; i < this.selection.selected.length; i++) {
            let s: ServidorAdmin = this.selection.selected[i];
            stringServidores += s.nombreServidor + "\n";
            this.itemsRef.update(s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/", { vencimiento: fechaNormString })
              .then(_ => this.escribePagos(s, fechaString))
              .catch(err => { this.openSnackBar("No pudo grabar en Fire MyPA", "Avisar GAMR"); console.log(err) });
            //this.escribePagos(s, fechaString);
          }

          //console.log("You pressed OK!");
        } else {
          //console.log("You pressed Cancel!");
        }
      } else {
        //error de la fecha
        this.openSnackBar('La fecha no tiene length 10', 'Contactar GAMR');
      }
    } else {
      //no hay servidores elegidos o fecha
      if (!this.fecha) {
        this.openSnackBar('..', 'Elija Fecha');
      } else if (!(this.fecha instanceof Date)) {
        this.openSnackBar('fecha no es Date', 'Contactar GAMR');
      } else {
        this.openSnackBar('No hay servidores elegidos', 'Elige 1 al menos');
      }
    }
  }
  sacaEspacios(nombreCuenta: string) {
    if (nombreCuenta) {
      while (nombreCuenta.includes(" ")) {
        nombreCuenta = nombreCuenta.replace(" ", "%20");
      }
      return nombreCuenta + "%20";
    } else {
      return "%20";
    }

  }
  //onNgModelChange(event) {
  // console.log('on ng model change', event);
  //}

  openSnackBar(message: string, action: string) {
    this.msjSnack = "|  " + message + "      " + action + "  |"
    setTimeout(_ => {
      this.msjSnack = null
    }, 3000)
    //this.snackBar.open(message, action, {
    // duration: 2000,
    //});
  }


  escribePagos(s: ServidorAdmin, fechaString: string): void {
    this.httpPatchPagos(s, fechaString)
      .then(respuesta => {
        this.openSnackBar("", "Exito");
      },
        error => this.openSnackBar(error, "Error"));
  }

  httpPatchPagos(s: ServidorAdmin, fecha: string): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let jsonVenc = {};
    jsonVenc[s.idMac] = fecha;

    return this.http.patch("https://popapprestoserver.firebaseio.com/pagos.json", jsonVenc, options).toPromise()
      .then(this.extractData)
      .catch(this.handleErrorObservable);
  }
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  private handleErrorObservable(error: Response | any) {
    console.error("Erroooor FE", error.message || error);
    return Observable.throw(error.message || error);
  }

  showFormAlert(fs: FormAlert[]) {
    let arreResp = [];
    let i = 0;
    for (let f of fs) {
      let respuesta: any = prompt(f.msg, (f.defaultMsg ? f.defaultMsg : ""));
      if (f.requerido) {
        if (respuesta && respuesta != null && respuesta.trim().length > 0) {
          if (f.numero) {
            respuesta = +(respuesta.trim());
          }
        } else {
          throw "Campo " + f.msg + " requerido";
        }
      }
      arreResp[i] = respuesta;
      i++;
    }
    return arreResp;
  }



  irAFb(s: ServidorAdmin) {
    const url = environment.urls.firebase + "/data/cuentas/" + s.idCuenta;
    let win = window.open(url, '_blank');
    win.focus();
  }

  irAStock(s: ServidorAdmin) {
    this.selectCuenta(s);
    this.router.navigateByUrl("/mypopapp/stock");
  }

}

