import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { LocalOnline } from '../modal-conf-ponline/modal-conf-ponline.component';

const FULL_PRIME_URL = 'http://app.idte.cl/WebAPI/api/PopAppOrder';

@Component({
  selector: 'app-modal-fullprime',
  templateUrl: './modal-fullprime.component.html',
  styleUrls: ['./modal-fullprime.component.scss']
})
export class ModalFullprimeComponent implements OnInit {



  s: ServidorAdmin;
  loading: boolean;
  fullPrimeActivado: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalFullprimeComponent>,
    public auth: AuthService,
    private cuentaService: CuentaService,
    @Inject(MAT_DIALOG_DATA) public data: { s: ServidorAdmin },
    private dbFs: AngularFirestore,
    private snackbar: MatSnackBar,
  ) {
    this.s = this.data.s;
  }


  async ngOnInit() {
    this.loading = true;
    let doc = await this.dbFs.doc("confLocalPO/" + this.s.keyPedidosOnline.key).get().toPromise();
    const local = <LocalOnline>(doc.data() ? doc.data() : null);
    this.loading = false;
    if (local && local.urlFacturacion && local.urlFacturacion === FULL_PRIME_URL) {
      this.fullPrimeActivado = true;
    } else {
      this.fullPrimeActivado = false;
    }
  }

  async activar() {
    this.loading = true;
    this.dbFs.doc("confLocalPO/" + this.s.keyPedidosOnline.key).update({ urlFacturacion: FULL_PRIME_URL }).then(e => {
      this.snackbar.open("Exito", "")._dismissAfter(3000);
      this.onNoClick();

    }).catch(e => {
      console.error(e);
      this.snackbar.open("Error", "Mirar consola F12")._dismissAfter(3000);
    }).finally(() => {
      this.loading = false;
    });

  }

  desactivar() {
    this.loading = true;
    this.dbFs.doc("confLocalPO/" + this.s.keyPedidosOnline.key).update({ urlFacturacion: null }).then(e => {
      this.snackbar.open("Exito", "")._dismissAfter(3000);
      this.onNoClick();
    }).catch(e => {
      console.error(e);
      this.snackbar.open("Error", "Mirar consola F12")._dismissAfter(3000);
    }).finally(() => {
      this.loading = false;
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}