import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';

import { AuthService, InfoCuenta } from 'src/app/core/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TeaserpedidosonlineComponent } from '../../modales/teaserpedidosonline/teaserpedidosonline.component';
import { Subscription } from 'rxjs';

import * as consts from "../../shared/constantes";
import { Cuenta } from 'src/app/shared/cuenta';
import { DbFSService } from 'src/app/services/db-fs.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnChanges {
  // custom vars
  user;
  userName: string;
  userPhotoURL: string;
  teasermypopappweb = "teasermypopappweb";
  teaserData: { clickenQSMas: number, seMostro: number };
  clicksEnBell = 0;
  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;
  logoURL = this.marcaURL();
  @Input() cuentas: Cuenta[];
  selectedCuenta: Cuenta;

  // minton vars
  notificationItems: Array<{}>;
  openMobileMenu: boolean;

  showAnuncio: boolean = false;


  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() cuentaChange = new EventEmitter();

  constructor(private router: Router, private db: AngularFireDatabase, private authService: AuthService, public dialog: MatDialog,
    private dbfs: DbFSService,
  ) { }

  ngOnInit() {
    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });

    //verifica si se muestran o no se muestran los anuncios
    this.dbfs.docOnce("globales/anuncios").then(result => {
      if (result.data()) {
        const datos: any = result.data();
        this.showAnuncio = datos.showAnuncios2;
      }
    });

    // Get de notifications
    this._fetchNotifications();

    // Get de user data
    this._fetchUserData();

    this.openMobileMenu = false;

    /** 
     * Esta inicialización es necesaria ya que 
     * las cuentas son enviadas por el componente 
     * padre y aún no están disponibles en el "ngOnInit."
    */
    this.selectedCuenta = new Cuenta();
    this.selectedCuenta.id = "-1";
    this.selectedCuenta.name = "Seleccione Cuenta";
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.cuentas && this.selectedCuenta.id === "-1") {
      this.selectedCuenta = this.cuentas[0];
      this.authService.changeCuenta(this.cuentas[0]);
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.signOut();
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.notificationItems = [{
      text: 'Caleb Flakelar commented on Admin',
      subText: '1 min ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'primary',
      redirectTo: '/notification/1'
    },
    {
      text: 'New user registered.',
      subText: '5 min ago',
      icon: 'mdi mdi-account-plus',
      bgColor: 'info',
      redirectTo: '/notification/2'
    },
    {
      text: 'Cristina Pride',
      subText: 'Hi, How are you? What about our next meeting',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'success',
      redirectTo: '/notification/3'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '2 days ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'danger',
      redirectTo: '/notification/4'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '1 min ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'primary',
      redirectTo: '/notification/5'
    },
    {
      text: 'New user registered.',
      subText: '5 min ago',
      icon: 'mdi mdi-account-plus',
      bgColor: 'info',
      redirectTo: '/notification/6'
    },
    {
      text: 'Cristina Pride',
      subText: 'Hi, How are you? What about our next meeting',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'success',
      redirectTo: '/notification/7'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '2 days ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'danger',
      redirectTo: '/notification/8'
    }];
  }

  /**
   * Obtiene los datos del usuario
   */
  _fetchUserData() {
    this.authService.user.subscribe(user => {
      if (user && user.email && user.email.length > 3) {
        this.user = user
        this.userName = user.displayName != null ? user.displayName : user.email;
        this.userPhotoURL = user.photoURL != null ? user.photoURL.valueOf() : "/assets/images/users/default-user.png";
      }
    })
  }

  /**
   * Abre el modal de Pedidos Online
   */
  abreNotificaciones() {
    const dialogRef = this.dialog.open(TeaserpedidosonlineComponent, {
      width: '85%',
      data: {
        pedi: false,
        rapp: false,
        glov: false,
        uber: false,
        otro: false,
        ning: false,
        teaserData: this.teaserData,
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });

    this.clicksEnBell++;
    if (!this.teaserData) {
      this.clicksEnBell--;
    } else {
      if (this.clicksEnBell > 0) {
        this.teaserData.seMostro++
        this.db.object(this.teasermypopappweb + "/" + this.authService.decodeEmail(this.user.email)).update({ clickenQSMas: this.teaserData.clickenQSMas, seMostro: this.teaserData.seMostro });
      }
    }

  }

  /**
   * Setea el logo de acuerdo a la cuenta.
   */
  marcaURL() {
    return consts.marcaURL(this.infoCuenta);
  }

  marcaURLChica() {
    return consts.marcaURLChica(this.infoCuenta);
  }

  /**
   * Cambia la cuenta
   * @param cuenta cuenta
   */
  changeCuenta(cuenta: Cuenta) {
    this.selectedCuenta = cuenta;
    this.authService.changeCuenta(cuenta);
  }

  // leerNotificaciones(emailDecodificado) {
  //   if (emailDecodificado && emailDecodificado.length > 0) {
  //     this.subscriptionTeaser = this.db.object(this.teasermypopappweb + "/" + emailDecodificado).snapshotChanges().subscribe(snap => {
  //       if (snap && snap.payload && snap.payload.val()) {
  //         this.teaserData = <{ clickenQSMas: number, seMostro: number }>snap.payload.val()
  //         if (!this.teaserData.clickenQSMas && this.teaserData.seMostro && this.teaserData.seMostro <= 3) {
  //           this.cantNotificaciones = "1"
  //           if (!this.escribioPorLectura) {
  //             this.escribioPorLectura = true;
  //             this.teaserData.seMostro++
  //             this.db.object(this.teasermypopappweb + "/" + emailDecodificado).update({ clickenQSMas: this.teaserData.clickenQSMas, seMostro: this.teaserData.seMostro });
  //           }
  //         } else {
  //           this.cantNotificaciones = ""
  //         }
  //       } else {
  //         if (!this.escribioPorLectura) {
  //           this.escribioPorLectura = true;
  //           this.db.object(this.teasermypopappweb + "/" + emailDecodificado).update({ clickenQSMas: 0, seMostro: 1 });
  //           // this.mostrarModal(1);
  //         }
  //       }
  //     })
  //   }
  // }


}
