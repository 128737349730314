import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlimscrollDirective } from '../shared/ui/slimscroll.directive';
import { LayoutmypopappComponent } from './layoutmypopapp.component';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';



@NgModule({
  declarations: [LayoutmypopappComponent, TopbarComponent, SidebarComponent, SlimscrollDirective],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    ClickOutsideModule
  ],
  exports: [SlimscrollDirective, LayoutmypopappComponent]
})
export class LayoutsModule { }
