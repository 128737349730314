import { Component, OnInit, } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { first, map, } from 'rxjs/operators';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { LocalAdmin } from '../../shared/admin/local-admin';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';

import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/toPromise';
import { Cuenta } from '../../shared/cuenta';
import * as firebase from 'firebase/app';


import { AuthService } from "../../core/auth.service";
import { CuentaService } from '../../services/cuenta.service';
import { Router } from '@angular/router';
import * as consts from "../../shared/constantes";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from 'src/app/shared/user';
import { DbFSService } from 'src/app/services/db-fs.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


export interface UsuarioPOW {
  _key?: string,
  urlProfileImg: string,
  nombreCompleto?: string,
  email: string,
  ultConexion?: string,
  funcion: string,

  /**
   * si no tiene uid es que todavia no acepta la invitacion
   */
  uid?: string,
  timestampUltConexion?: any,
  permisos: string[]
}

@Component({
  selector: 'app-admin-scripts',
  templateUrl: './admin-scripts.component.html',
  styleUrls: ['./admin-scripts.component.scss']
})

export class AdminScriptsComponent implements OnInit {
  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;
  servidores: (ServidorAdmin & { usuarios: any[] })[] = [];

  driftVar: any;

  formQuery: FormGroup;
  submitted = false;
  error: string;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.hide();
      } else {
        this.driftVar = window["drift"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }

  ngOnInit() {
    this.hideDrift(1)

    this.formQuery = this.formBuilder.group({
      keyLocal: ["", [Validators.required, Validators.minLength(3)]],
      mes: ["", [Validators.required, Validators.minLength(7), Validators.maxLength(7)]],
    });

    let date = new Date();
    let mes = date.getMonth() + 1;
    this.formQuery.controls.mes.setValue(date.getFullYear() + "-" +
      (mes > 9 ? mes : "0" + mes)
    );
  }

  guardarDatos() {
    this.submitted = true;
    this.error = null;

    if (this.formQuery.invalid) {
      this.error = "Datos inválidos";
      return;
    }

    const keyLocal = this.formQuery.controls.keyLocal.value.trim();
    const mes = this.formQuery.controls.mes.value.trim();

    const callable = this.fns.httpsCallable("queryStatsCheck");
    callable({ keyLocal: keyLocal, fechaInicio: mes }).pipe(first()).subscribe(logs => {
      console.log("RESULTADO");
      console.log(logs);
    });
  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }

  constructor(public router: Router,
    private formBuilder: FormBuilder,
    private fns: AngularFireFunctions,
    public afAuth: AngularFireAuth, public cuentaService: CuentaService, public db: AngularFireDatabase, private http: Http, public authService: AuthService, private dbFs: DbFSService) {
    //console.log("ENTRO");

    afAuth.user.subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {



        // console.log("SUSCRIPTO");
        //leemos las cuentas
        this.itemsRef = db.list('cuentas');
        // // Use snapshotChanges().map() to store the key

        let items = this.itemsRef.snapshotChanges().pipe(
          first(),
          map(changes => {
            let mapeado = changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
            console.log("LLEGO ALGO");

            this.sacaLocalesYServidores(mapeado);
            return mapeado;
          }
          )
        ).subscribe(changes => {

        });
      }
    });
  }

  selectCuenta(cuentaAdmin: ServidorAdmin) {
    let cuenta: Cuenta = new Cuenta();
    cuenta.id = cuentaAdmin.idMac;
    cuenta.name = cuentaAdmin.nombreCuenta;
    this.authService.changeCuenta(cuenta);
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }


  private handleErrorObservable(error: Response | any) {
    console.error("Erroooor FE", error.message || error);
    return Observable.throw(error.message || error);
  }

  /**
   * valida que la key sea != null y con length mayor a 3
   */
  keyValida(key: string): boolean {
    if (key != null && key.length > 3) {
      return true;
    } else {
      return false;
    }
  }


  diasss(fecha: string, hoyAnio, hoyMes, hoyDia): number {
    let fv: string = fecha;
    if (!fv) {
      fv = "1/1/1999";
    }
    let indexBarra: number = fv.indexOf("/");
    let iFor = 0;
    let stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let dia: number = Number(stringAuxFecha);

    fv = fv.replace(stringAuxFecha + "/", "");
    indexBarra = fv.indexOf("/");
    iFor = 0;
    stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let mes: number = Number(stringAuxFecha);
    fv = fv.replace(stringAuxFecha + "/", "");
    let anio: number = Number(fv);

    //let mes: number = Number(fv.charAt(3) + fv.charAt(4));
    //let anio: number = Number(fv.charAt(6) + fv.charAt(7) + fv.charAt(8) + fv.charAt(9));
    let dias = (anio - hoyAnio) * 365;
    dias += (mes - 1 - hoyMes) * 30;
    dias += (dia - hoyDia);
    return dias;
  }

  monedas = new Set();

  async migrarNuevoModelo(s: ServidorAdmin, u: { key: string, email: string }) {
    console.log(u);
    // u.key = "ZaK4BFjdI3WfdGiRHUfTiifuUFq1"
    u.key = "C9h6B2vYdBNULESpfNxUmEij3wt2";
    const PEDIDOSR = "PEDIDOSR";
    const PEDIDOSW = "PEDIDOSW";
    const CONFIGURACIONESR = "CONFIGURACIONESR";
    const CONFIGURACIONESW = "CONFIGURACIONESW";
    const ESTADISTICASR = "ESTADISTICASR";
    const ESTADISTICASW = "ESTADISTICASW";
    const USUARIOSR = "USUARIOSR";
    const USUARIOSW = "USUARIOSW";

    let permisosRoWSelec = [PEDIDOSW, PEDIDOSR, CONFIGURACIONESW, CONFIGURACIONESR, ESTADISTICASW, ESTADISTICASR, USUARIOSW, USUARIOSR]
    let userSnap = await this.dbFs.docOnce("users/" + u.key);

    if (userSnap && userSnap.data()) {
      let user: User = <User>userSnap.data();
      let userPow: UsuarioPOW = {
        _key: user.uid,
        email: user.email,
        funcion: "Propietario",
        permisos: permisosRoWSelec,
        uid: user.uid,
        urlProfileImg: user.photoURL ? user.photoURL : "https://popapp.io/assets/images/users/default-user.png"
      }
      if (user.displayName) {
        userPow.nombreCompleto = user.displayName;
      }

      let hub;
      for (let ke in s.keyPedidosOnline.locales) {
        let local = s.keyPedidosOnline.locales[ke];
        if (local.keyHub) {
          hub = local.keyHub;
        }
      }

      //hub = "veryDeli";

      if (hub) {
        this.dbFs.updateAt("confLocalPO/" + hub + "/usuarios/" + user.uid, userPow).then(v => {
          console.log("EXITO hub ", hub);
        }).catch(e => {
          console.error("Error al migrar hub ", e);
        });


        // this.dbFs.updateAt("confLocalPO/" + hub, {
        //   uids: firebase.firestore.FieldValue.arrayUnion(user.uid)
        // }).then(v => {
        //   console.log("EXITO usuario hub");
        // }).catch(e => {
        //   console.error("Error al migrar usuario hub", e);
        // });

      }


      // this.dbFs.updateAt("confLocalPO/" + s.keyPedidosOnline.key, {
      //   uids: firebase.firestore.FieldValue.arrayUnion(user.uid)
      // }).then(v => {
      //   console.log("EXITO usuario");
      // }).catch(e => {
      //   console.error("Error al migrar usuario", e);
      // });

      this.dbFs.updateAt("confLocalPO/" + s.keyPedidosOnline.key + "/usuarios/" + user.uid, userPow).then(v => {
        console.log("EXITO");
      }).catch(e => {
        console.error("Error al migrar", e);
      });
    } else {
      console.error("NO hay usuario");
      return;
    }
  }

  docs = [];
  mapUpdates = {};
  async sacaLocalesYServidores(mapCuentas: any[]) {

    let hoyDia: number = new Date().getDate();
    let hoyMes: number = new Date().getMonth();
    let hoyAnio: number = new Date().getFullYear();
    //console.log("empieza a sacar");
    //for (let i = 0; i < this.servidores.length;) {
    //this.servidores. pop();
    //}
    this.servidores = [];
    //for de cuentas
    let importes: number = 0;
    let importesDolar: number = 0;
    let importesClp: number = 0;

    let ivaARS: number = 0;
    let ivaDolar: number = 0;
    let ivaCLP: number = 0;


    let servidoresSinVencer: number = 0;
    let incluidos14: number = 0;
    for (let i in mapCuentas) {
      let c: CuentaAdmin = mapCuentas[i];
      c.id = mapCuentas[i].key;
      let mapLocales = mapCuentas[i].locales;
      //for de locales
      let indexLocal = 0;
      let totalLocales = 0;
      for (let j in mapLocales) {
        totalLocales++;
      }
      for (let j in mapLocales) {
        indexLocal++;
        let l: LocalAdmin = mapLocales[j];
        l.id = j;
        l.idCuenta = c.id;
        l.totLocales = totalLocales;


        //for de servidores
        let mapServidores = mapLocales[j].servidores;
        let indexServidor = 0;
        let totalServidores = 0;
        for (let y in mapServidores) {
          totalServidores++;
        }
        for (let y in mapServidores) {
          indexServidor++;
          let s: (ServidorAdmin & { usuarios: any[] }) = mapServidores[y];
          s.idMac = y;
          s.idLocal = l.id;
          s.idCuenta = c.id;
          s.uCuenta = c.u;
          s.lastCuenta = c.last;
          if (c.whatsapp) {
            s.whatsapp = c.whatsapp;
          }
          if (c.contacto) {
            s.contacto = c.contacto;
          }
          s.nombreCuenta = c.nombreCuenta;
          s.nombreLocal = l.nombreLocal;
          s.indServidor = indexServidor;
          s.indLocal = indexLocal;
          s.totLocales = totalLocales;
          s.totServidoresOSvs = totalServidores;



          let dias = this.diasss(s.vencimiento, hoyAnio, hoyMes, hoyDia)
          s.dias = dias;

          let diasReal = this.diasss(s.pagoHasta, hoyAnio, hoyMes, hoyDia)
          s.diasReal = diasReal
          if (dias >= 0) {
            servidoresSinVencer++;
            incluidos14++;
          } else if (dias >= -7) {
            incluidos14++;
          }

          s.fechaInstalacionyyyyMMddd = this.fechaDeddMMyyyyAyyyyMMdd(s.fechaInstalacion);

          if (s.keyPedidosOnline && s.keyPedidosOnline.key && s.keyPedidosOnline.locales) {

            if (s.pais && s.pais.startsWith("CHI")) {
              for (let pya123 in s.keyPedidosOnline.locales) {
                let tienda = s.keyPedidosOnline.locales[pya123];
                if (tienda.offset && tienda.offset === -4) {
                  // this.mapUpdates["keysLocalesPedidosOnline2/" + tienda.origen + "," + tienda.idStore + "/offset"] = -4;
                  //this.mapUpdates["cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/locales/" + pya123 + "/offset"] = -4;
                  this.dbFs.updateAt("confLocalPO/" + s.keyPedidosOnline.key + "/tiendas/" + tienda.origen + "," + tienda.idStore,
                    {
                      offset: -4
                    }).then(v => {
                      console.log("EXITO")

                    }).catch(e => {
                      console.log("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/locales/" + pya123 + "/offset");
                      console.error("ERROR", e);
                    })
                  // this.docs.push({
                  //   path: "confLocalPO/" + s.keyPedidosOnline.key + "/tiendas/" + tienda.origen + "," + tienda.idStore,
                  //   data: {
                  //     offset: -4
                  //   },
                  //   removeOperation: false,
                  // })
                  // console.log("cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/locales/" + pya123 + "/offset");
                }
              }
            }
            this.servidores.push(s);
            if (!s.moneda) {
              console.log(s)
            } else {
              let moneda;
              if (s.moneda === "ARS $" || s.moneda === "ARS") {
                moneda = "ARS";
              } else if (s.moneda === 'UF' || s.moneda === "CLP" || s.moneda === "CLP $" || (s.moneda === 'USD' && s.pais === 'CHI')) {
                moneda = "CLP";
              } else {
                console.log(s);
              }
              if (moneda) {

                // this.docs.push({
                //   path: "confLocalPO/" + s.keyPedidosOnline.key,
                //   data: {
                //     moneda: moneda
                //   },
                //   removeOperation: false,
                // })
              }
            }

            // s.usuarios = []
            // for (let k in s.keyPedidosOnline.users) {
            //   s.usuarios.push({
            //     key: k,
            //     email: s.keyPedidosOnline.users[k],
            //   })
            // }

            // let flag = false;
            // for (let l in s.keyPedidosOnline.locales) {
            //   let local = s.keyPedidosOnline.locales[l];
            //   if (local.keyHub && local.keyHub === "veryDeli") {
            //     flag = true;
            //   }
            // }
            // if (flag) {
            //   this.servidores.push(s);
            // }

          }


          if (s.numeroLocal && s.moneda && +s.numeroLocal > 0 && +s.numeroLocal < 9000 && +s.numeroLocal !== 900 && (s.cuotasPendientes == null)) { //1500 para arriba seria para testear pero no para sumar

          } else {

          }

        }

      }

    }


    // this.db.object("/").update(this.mapUpdates)
    //   //this.dbFs.writeBatch(this.docs)
    //   .then(v => {
    //     console.log("EXITO FB", v);
    //   }).catch(e => {
    //     console.error("Problema al grabar los datos FS", e);
    //   });

    // this.dbFs.writeBatch(this.docs)
    //   .then(v => {
    //     console.log("EXITO FS", v);
    //   }).catch(e => {
    //     console.error("Problema al grabar los datos FS", e);
    //   });

  }


  getMyStyles(dias: number) {
    let color = 'green';
    if (dias < 0 && dias > -8) {
      color = 'violet';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': 'white',
      'background': color,
      'padding': '10px'
    };
    return myStyles;
  }

  fechaDeddMMyyyyAyyyyMMdd(fechaInstalacion: string): string {
    if (fechaInstalacion) {
      let array = fechaInstalacion.split("/");
      if (array && array.length == 3) {
        return array[2] + "/" + (array[1].length > 1 ? array[1] : "0" + array[1]) + "/" + (array[0].length > 1 ? array[0] : "0" + array[0]);
      }
    }
    return "";
  }


  copiarTablaClipBoard() {
    let table = document.querySelector('#tablaAdmin');

    let range = document.createRange();
    range.selectNodeContents(table)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)

    document.execCommand('copy')
  }

}
