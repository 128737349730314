import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeaserData } from '../../layouts/layoutmypopapp.component';
import { Subscription } from 'rxjs';
import { AuthService, InfoCuenta } from '../../core/auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { CuentaService } from '../../services/cuenta.service';
import * as consts from "../../shared/constantes";

@Component({
  selector: 'app-teaserpedidosonline',
  templateUrl: './teaserpedidosonline.component.html',
  styleUrls: ['./teaserpedidosonline.component.scss']
})
export class TeaserpedidosonlineComponent implements OnInit, OnDestroy {

  exito: number = 0
  error: string
  tituloExito: string
  descripcionExito: string
  teasermypopappweb = "teasermypopappweb";

  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;

  constructor(
    public dialogRef: MatDialogRef<TeaserpedidosonlineComponent>,
    public authService: AuthService,
    public cuentaService: CuentaService,
    public db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: TeaserData) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  marca() {
    return consts.marca(this.infoCuenta);
  }

  ngOnDestroy() {
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }
  }
  ngOnInit() {
    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });
  }

  quieroSaberMas() {
    let map = {};

    let usa: string = ""
    if (this.data.pedi) {
      map["pedidosYa"] = true;
      usa += "Pedidos Ya, "
    }
    if (this.data.rapp) {
      map["rappi"] = true;
      usa += "Rappi, "
    }
    if (this.data.glov) {
      map["glovo"] = true;
      usa += "Glovo, "
    }
    if (this.data.uber) {
      map["uber"] = true;
      usa += "Uber, "
    }
    if (this.data.otro) {
      map["otro"] = true;
      usa += "Otro, "
    }
    if (this.data.ning) {
      map["ninguno"] = true;
      usa += "Ninguno."
    }
    let clicks;
    if (this.data.teaserData) {
      this.data.teaserData.clickenQSMas++;
      clicks = this.data.teaserData.clickenQSMas;
      map["clickenQSMas"] = this.data.teaserData.clickenQSMas;
      map["seMostro"] = this.data.teaserData.seMostro;
      this.db.object(this.teasermypopappweb + "/" + this.authService.decodeEmail(this.data.user.email)).update(map);
    } else {
      clicks = 1
      map["clickenQSMas"] = 1;
      map["seMostro"] = 1;
      this.db.object(this.teasermypopappweb + "/" + this.authService.decodeEmail(this.data.user.email)).update(map);
    }

    this.error = null


    if (!this.data.pedi && !this.data.rapp && !this.data.glov
      && !this.data.uber && !this.data.otro && !this.data.ning) {
      this.error = "Elige al menos una opción";
      return;
    }




    let cuerpoDeMail = "El cliente " + this.data.user.email + " hizo click " + clicks + " veces en Quiero Saber mas en pedidos online mypopappweb";
    cuerpoDeMail += "\nUsuario Delivery Online: " + usa


    //let urlServidorMail = "environment.urls.escuchador/payments/mails/leads";
    let body = {
      notification: {
        title: "popapp",
        to: "pgarcia@popapp.io",
        copia: "jcorona@popapp.io, victoria@popapp.io, agustina@popapp.io, fzarate@popapp.io, plopez@popapp.io",
        asunto: "Click en mypopappweb pedidosonline cliente: " + this.data.user.email,
        text: cuerpoDeMail
      }
    }

    this.cuentaService.enviarEmailAFSProm(body, "leads")
      .then(r => {
        this.exito = clicks
        if (clicks === 1) {
          this.tituloExito = "¡Genial!"
          this.descripcionExito = "En breve nos pondremos en contacto para darte más información y coordinar todo lo necesario."
        } else {
          this.tituloExito = "¡Gracias por seguir con interés!"
          this.descripcionExito = "Si todavía no nos hemos contactado, te pedimos disculpas. Pero estamos atendiendo gran cantidad de solicitudes por este servicio y vamos lo más rápido que podemos, gracias por tu paciencia y en breve nos pondremos en contacto."
        }
      })
      .catch(e => {
        this.error = "Hubo un problema, por favor reintenta más tarde"
      })
    //mandar email para descargar con copia al facu jorge y a mi
    /*this.cuentaService.enviaMailProm(body, urlServidorMail)
      .then(r => {
        this.exito = clicks
        if (clicks === 1) {
          this.tituloExito = "¡Genial!"
          this.descripcionExito = "En breve nos pondremos en contacto para darte más información y coordinar todo lo necesario."
        } else {
          this.tituloExito = "¡Gracias por seguir con interés!"
          this.descripcionExito = "Si todavía no nos hemos contactado, te pedimos disculpas. Pero estamos atendiendo gran cantidad de solicitudes por este servicio y vamos lo más rápido que podemos, gracias por tu paciencia y en breve nos pondremos en contacto."
        }
      })
      .catch(e => {
        this.error = "Hubo un problema, por favor reintenta más tarde"
      })*/

  }
}
