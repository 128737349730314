
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { Cuenta } from '../../shared/cuenta';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { MatSort, } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Orden2 } from 'src/app/shared/historial/orden2';
import { ItemPapp } from 'src/app/shared/historial/itemPapp';

import * as consts from "../../shared/constantes";
import { User } from '../../shared/user';


@Component({
  selector: 'app-detalleorden',
  templateUrl: './detalleorden.component.html',
  styleUrls: ['./detalleorden.component.scss']
})
export class DetalleordenComponent implements OnInit, OnDestroy {

  //#region [ rgba (100, 200, 10, 50) ] Variables
  buscando: boolean = true;

  cuenta: Cuenta;
  cuentaAdmin: CuentaAdmin;

  email: string;
  encabezado: string;
  idMac: string;
  idLocal: string;
  idCuenta: string;
  idJornada: string;
  idPedido: string;
  keyPedido: string;
  noHayPermiso = false;

  items: ItemPapp[];
  itemsObs: Observable<ItemPapp[]>;
  offset: number;
  subscriptionCuentas: Subscription;
  subscriptionCuentaAdmin: Subscription;

  iSelec: ItemPapp;
  orden: Orden2;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  //#region Variables Tabla
  dataSource: MatTableDataSource<ItemPapp>;
  displayedColumns: string[] =
    [
      "cantidad", "nombreComidaMasFraccion", "precioItem", "montoTotal", "observacion",
    ];
  @ViewChild(MatSort) sort: MatSort;

  //#endregion


  constructor(private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar, protected router: Router, public authService: AuthService, public db: AngularFireDatabase, public changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => { changeDetectorRef.detectChanges(); }
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  rowSeleccionada(row: ItemPapp, seleccionada: boolean) {
    if (seleccionada) {
      this.iSelec = row;
    } else {
      this.iSelec = undefined;
    }
  }

  esSeleccionada(row: ItemPapp) {
    if (row && row.idItem && this.iSelec && this.iSelec.idItem) {
      if (row.key === this.iSelec.key) {
        //if (this.tieneitems(this.orden)) {
        //  return {
        //    'background': 'darkslategrey',
        //    'cursor': 'pointer'
        //  }
        //} else {
        return {
          //'background': 'grey',
        }
      }
    }
    return {}
  }


  convierteDateAStringPBuscar(fecha: Date): string {
    const mes = fecha.getMonth() + 1;
    const mesString = mes > 9 ? "," + mes : ",0" + mes;

    const diaString = fecha.getDate() > 9 ? "," + fecha.getDate() : ",0" + fecha.getDate();

    return fecha.getFullYear() + mesString + diaString;
  }

  sortHeader() {
    this.dataSource.sort = this.sort;
  }
  esAdmin(user: User) {
    return consts.esAdmin(user);
  }
  ngOnInit() {
    this.keyPedido = this.activatedRoute.snapshot.params['keyPedido'];
    let arre = this.keyPedido.split(",");
    this.idCuenta = arre[0];
    let año = arre[1];
    let mes = arre[2];
    let dia = arre[3];
    this.idLocal = arre[4];
    this.idMac = arre[5];
    this.idJornada = arre[6];
    this.idPedido = arre[7];

    this.offset = (new Date()).getTimezoneOffset();
    this.dataSource = new MatTableDataSource(this.items);
    this.buscando = true;
    this.authService.user.subscribe(user => {
      this.noHayPermiso = false;

      if (user && user.email) {
        // console.log("SUSCRIPTO");
        this.email = user.email;
        this.subscriptionCuentas = this.authService.cuentas$
          .subscribe(cuentas => {
            if (cuentas) {
              this.buscando = true;

              let existeLaCuenta = cuentas.map(c => c.id === this.idMac).reduce((a, v) => a ? true : v, false)
              if (existeLaCuenta || this.esAdmin(user)) {
                this.leeCuentaAdmin(this.idCuenta)
              } else {
                //No hay permiso
                this.noHayPermiso = true;
                this.buscando = false;

              }
            } else {
              this.buscando = false;
            }
          });
      } else {
        this.buscando = false;
      }
    });
  }

  ngOnDestroy(): void {
    try { this.subscriptionCuentas.unsubscribe() } catch (e) { }
    try { this.subscriptionCuentaAdmin.unsubscribe() } catch (e) { }
  }



  async leeCuentaAdmin(idCuenta: string) {
    let refCuentaAdmin = this.authService.leerFB("cuentas/" + idCuenta);

    this.subscriptionCuentaAdmin = refCuentaAdmin.snapshotChanges().subscribe(action => {
      this.cuentaAdmin = <CuentaAdmin>action.payload.val();
    });
    let or = await this.db.object("estadisticas/ordenes/" + this.keyPedido).query.once("value", function (v) {
    })
    this.orden = or.val()
    this.buscar()
  }

  buscar() {
    this.buscando = true;

    let itemsFireList = this.db.list("estadisticas/items", ref => ref.orderByKey()
      .startAt(this.keyPedido + ",")
      .endAt(this.keyPedido + ",~"))

    this.itemsObs = itemsFireList.snapshotChanges().pipe(

      map(changes => {
        let mapeado = changes.map(i => {
          let ii = <ItemPapp>i.payload.val();
          return ({ key: i.payload.key, ...ii })
        });
        this.buscando = false;
        return this.sacaItems(mapeado);
      })
    );

  }




  sacaItems(mapeado): ItemPapp[] {
    let items: ItemPapp[] = []
    this.items = []

    for (let i of mapeado) {
      items.push(i)
    }


    this.items = items
    this.dataSource = new MatTableDataSource(this.items);
    //this.applyFilter(this.filtroActual);
    return items;
  }


  getCantItems() {
    return this.items.map(o => o.cantidad).reduce((acc, value) => acc + value, 0);
  }

  getTotal() {
    return this.items.map(o => o.montoTotal).reduce((acc, value) => acc + value, 0);
  }


  fechaLocal(fecha: string) {
    let date = new Date(fecha);
    date.setMinutes(date.getMinutes() + this.offset);
    return date.toISOString();
  }

}
