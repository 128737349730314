import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { POnline } from './ponliine';
import { map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuentaService } from 'src/app/services/cuenta.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test-pedidos-online',
  templateUrl: './test-pedidos-online.component.html',
  styleUrls: ['./test-pedidos-online.component.scss']
})

export class TestPedidosOnlineComponent implements OnInit, OnDestroy {
  pedidos: Observable<POnline[]>;

  constructor(private db: AngularFireDatabase, private snackbar: MatSnackBar, private cuentaService: CuentaService) { }

  ngOnInit() {
    this.pedidos = this.db.list<POnline>("pedidosProcesados2/keyERROR", ref => ref.orderByChild("fechaError")).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          let data: POnline = a.payload.val();
          if (data) {
            const _key = a.payload.key;
            data["_key"] = _key;
            return data;
          } else {
            return null
          }
        });
      })
    )
  }
  keyLocal(keyLocal: string) {
    if (keyLocal.startsWith("keyPedidosOnline")) {
      return keyLocal.replace("keyPedidosOnline", "");
    } else {
      return keyLocal;
    }
  }

  async log(p: POnline) {
    let snap = await this.db.object("pedidosProcesados2/" + p.keyLocal + "/" + p["_key"]).query.once("value");
    console.log("pedido", snap.val());
    this.snackbar.open("DONE", "Revisa la consola (F12) -->")._dismissAfter(2000);
  }

  fecha(f: string): string {
    if (!f) {
      return "Sin fecha";
    }
    let d = new Date(f);
    d.setHours(d.getHours() - 3);
    return (d.getDate() < 10 ? "0" : "") + d.getDate() + "/" +
      (d.getMonth() < 9 ? "0" : "") + (d.getMonth() + 1) + " " +
      (d.getHours() < 10 ? "0" : "") + d.getHours() + ":" +
      (d.getMinutes() < 10 ? "0" : "") + d.getMinutes()// + ":" +
    //(d.getSeconds() < 10 ? "0" : "") + d.getSeconds()

  }

  //baseUrl = "http://localhost:3000/pedidosonline/";
  baseUrl = environment.urls.escuchador +"/pedidosonline/";
  eliminar(p: POnline) {
    this.cuentaService.getUrlProm(this.baseUrl + "eliminar/keyERROR/" + p["_key"]).then(v => {
      this.snackbar.open("", "DONE")._dismissAfter(2000);
    }).catch(e => {
      console.error("error al eliminar", e);
      this.snackbar.open("No se pudo completar la acción", "ERROR")._dismissAfter(2000);
    })
  }

  eliminarTagsIguales(p1: POnline) {
    let keysErrores: string;
    if (p1.errorTags) {
      keysErrores = this.keysTipoErrores(p1);
    }
    this.pedidos.pipe(first()).subscribe(peds => {
      for (let p of peds) {
        if (!keysErrores && !p.errorTags) {
          this.eliminar(p);
        } else {
          let keysErroresAux = this.keysTipoErrores(p);
          if (keysErrores === keysErroresAux) {
            this.eliminar(p);
          }
        }
      }
    })

  }

  keysTipoErrores(p1) {
    let tiposErrores = "";
    for (let k in p1.errorTags) {
      tiposErrores += k;
    }

    return tiposErrores;
  }

  migrarAPOnline(p: POnline) {
    this.cuentaService.getUrlProm(this.baseUrl + "migrar/" + p.keyLocal + "/" + p["_key"]).then(v => {
      this.snackbar.open("", "DONE")._dismissAfter(2000);
    }).catch(e => {
      console.error("error al eliminar", e);
      this.snackbar.open("No se pudo completar la acción", "ERROR")._dismissAfter(2000);
    })

  }

  ngOnDestroy() {

  }

}
