import { Component, OnInit, OnDestroy } from '@angular/core';
import { InfoCuenta, AuthService } from '../core/auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Cuenta } from '../shared/cuenta';
import { RutaService } from '../services/ruta.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CuentaService } from '../services/cuenta.service';
import { MatDialog } from '@angular/material/dialog';
import { DbFSService } from '../services/db-fs.service';


export interface TeaserData {
  pedi: boolean
  rapp: boolean
  glov: boolean
  uber: boolean
  otro: boolean
  ning: boolean
  teaserData: { clickenQSMas: number, seMostro: number }
  user: any
}

@Component({
  selector: 'app-layoutmypopapp',
  templateUrl: './layoutmypopapp.component.html',
  styleUrls: ['./layoutmypopapp.component.scss']
})

export class LayoutmypopappComponent implements OnInit, OnDestroy {

  mostrarVentas: boolean
  mostrarLocales: boolean
  mostrarSesion: boolean
  nombre: string
  facturacionYplan: boolean
  cuentas: Cuenta[]
  cuenta: Cuenta
  user
  // onepayVar: any;
  cantNotificaciones = ""
  teasermypopappweb = "teasermypopappweb";
  subscriptionTeaser: Subscription;
  oscuro = false;
  teaserData: { clickenQSMas: number, seMostro: number };
  escribioPorLectura = false;

  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;

  mostrarSide: boolean;
  //Vars definitivas
  isCondensed = false;

  showAnuncio: boolean = false;

  constructor(private router: Router, private rutaService: RutaService, public authService: AuthService, private db: AngularFireDatabase, public cuentaService: CuentaService, public dialog: MatDialog,
    private dbfs: DbFSService) { }

  ngOnDestroy() {
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }

    if (this.subscriptionTeaser) {
      try {
        this.subscriptionTeaser.unsubscribe();
      } catch (e) {

      }
    }
  }

  ngOnInit() {
    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });

    // verifica si se muestran o no se muestran los anuncios
    this.dbfs.docOnce("globales/anuncios").then(result => {
      if (result.data()) {
        const datos: any = result.data();
        this.showAnuncio = datos.showAnuncios2;
      }
    });

    //this.onepayVar = window["Onepay"];
    this.rutaService.changeRuta("mypopapp")

    this.authService.user.subscribe(user => {
      if (user && user.email && user.email.length > 3) {
        this.user = user
        this.mostrarSesion = true
        this.nombre = user.email
        this.db.object("emails/" + this.authService.decodeEmail(user.email)).snapshotChanges().subscribe(action => {
          //consts.logD(action.type);
          //consts.logD(action.key);
          //consts.logD(action.payload.val());
          if (action && action.payload && action.payload.val()) {
            let myMap: any = action.payload.val();
            if (myMap) {
              let cuentasX = []
              for (let key in myMap) {
                let c: Cuenta = new Cuenta()
                c.id = key
                c.name = myMap[key]
                cuentasX.push(c)
                if (!this.cuenta) {
                  this.cuenta = c
                  this.authService.changeCuenta(c)
                }
              }
              this.cuentas = cuentasX;
              this.authService.changeCuentas(cuentasX)
              //              this.router.navigateByUrl("mypopapp/0")
              if (this.cuentas.length > 0) {
                this.mostrarLocales = true
                // this.leerNotificaciones(this.authService.decodeEmail(user.email));
              }
              if (this.cuentas.length > 1)
                this.mostrarVentas = true
              else
                this.mostrarVentas = false

              this.facturacionYplan = true

              this.mostrarSide = true;
            } else {
              this.mostrarSide = false;
            }

            if (this.mostrarLocales && !this.mostrarVentas) {
              //hay 1 sola locacion
              if (this.authService.plan) {
                //entonces entraron con el plan desde comenzar
                this.router.navigateByUrl("/mypopapp/miplan")

              }
            }
          } else {
            this.mostrarLocales = false
            this.mostrarVentas = false
            this.facturacionYplan = false

          }
        })

      } else {
        this.mostrarLocales = false
        this.mostrarVentas = false
        if (user) {
          //this.rutaService.changeRuta("mypopapp")
          this.mostrarSesion = true
        } else
          this.mostrarSesion = false

        if (user.email)
          this.nombre = user.email
        else
          this.nombre = null

        this.facturacionYplan = false
      }
    })
    this.authService.cuentasManagersObser.subscribe(c => {
      // console.log(c);
    })

  }

  // onepay() {
  //   console.log("click one pay")
  //   this.onepayVar.checkout({
  //     endpoint: 'https://popappservice.net/asd',
  //     commerceLogo: 'https://tu-url.com/images/icons/logo-01.png',
  //     callbackUrl: 'https://popappservice.net/test/mypopapp/payments',
  //     transactionDescription: 'Set de pelotas',
  //     onclose: function (status) {
  //       console.log('el estado recibido es: ', status);
  //     }
  //   });
  // }
  //
  //Métodos definitivos

  /**
  * On mobile/table toggle button clicked
  */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    if (window.screen.width > 767) {
      document.body.classList.toggle('enlarged');
      this.isCondensed = !this.isCondensed;
    }

  }

}