import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { of } from 'rxjs';
import Cuenta from '../../models/cuenta';
import { CuentasService } from '../../services/cuentas.service';
import { ToastsService } from '../../services/toasts.service';
import { ModalEditCuentaComponent } from './modal-edit-cuenta/modal-edit-cuenta.component';

type ColumnasMostradas = (keyof Cuenta | 'actions');

@Component({
  selector: 'app-gestion-cuentas',
  templateUrl: './gestion-cuentas.component.html',
  styleUrls: ['./gestion-cuentas.component.scss']
})
export class GestionCuentasComponent implements OnInit {

  cuentas: Cuenta[];
  dataCuentasTabla: MatTableDataSource<Cuenta>;
  columnasMostradas: ColumnasMostradas[] = ['id', 'nombre', 'activa', 'emailRegistro', 'fechaCreacion', 'idCuenta', 'actions'];
  clickedRows: Set<Cuenta>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) input: HTMLInputElement;

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  soloActivos = true;

  loadingData;

  constructor(
    private cuentasSrv: CuentasService,
    private dialog: MatDialog,
    private toastSrv: ToastsService
  ) { }

  ngOnInit(): void {
    this.loadingData = true;
    this.clickedRows = new Set<Cuenta>();

    this.cuentasSrv.obtenerCuentas().subscribe(
      res => {
        this.cuentas = res;
       this.cargarTablaFiltrada();
      },
      err => console.error('Error al obtener las cuentas', err),
      () => this.loadingData = false
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataCuentasTabla.filter = filterValue.trim().toLowerCase();
  }

  mostrarModalEditarCuenta(cuenta: Cuenta) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60%';
    dialogConfig.height = '90%';
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      titulo: 'Editar cuenta',
      cuenta: cuenta
    };

    this.dialog.open(ModalEditCuentaComponent, dialogConfig).afterClosed().subscribe(result => {
      console.log('result',result);
      if(result?.cuentaModificada){
        this.modificarCuentaArreglo(result.cuentaModificada);
        this.toastSrv.show('Cuenta modificada con éxito');
      }
    });
  }

  onClickCrearCuenta(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60%';
    dialogConfig.height = '90%';
    dialogConfig.autoFocus = true;
    
    dialogConfig.data = {
      titulo: 'Crear cuenta',
      createMode: true
    };

    this.dialog.open(ModalEditCuentaComponent, dialogConfig).afterClosed().subscribe(result => {
      console.log('result',result);
      if(result?.cuentaCreada){
        this.agregarCuentaArreglo(result.cuentaCreada);
        this.mostrarModalEditarCuenta(result.cuentaCreada);
        this.toastSrv.show('Cuenta creada con éxito');
      }
    });

  }

  modificarCuentaArreglo(cuenta: Cuenta){
    const indice = this.cuentas.findIndex(c => c.id === cuenta.id);
    this.cuentas[indice] = cuenta;
    this.dataCuentasTabla.data[indice] = cuenta;
    this.dataCuentasTabla.paginator = this.paginator;
  }

  agregarCuentaArreglo(cuenta: Cuenta){
    this.dataCuentasTabla.data.push(cuenta);
    this.dataCuentasTabla.paginator = this.paginator;
  }


  log = (o?) => console.log('log', o);

  onChangeActivos(event){
    this.soloActivos = !this.soloActivos;
    this.cargarTablaFiltrada();
  }

  cargarTablaFiltrada(){
    if(this.soloActivos){
      this.dataCuentasTabla = new MatTableDataSource(this.cuentas.filter(l => l.activa == this.soloActivos));
    }else{
      this.dataCuentasTabla = new MatTableDataSource(this.cuentas);
    }
    this.dataCuentasTabla.paginator = this.paginator;
    this.dataCuentasTabla.sort = this.sort;
  }

}
