import { ValorFirebase } from "./valorFirebase";

export class Log {

    /**
     * si el cambio lo deshizo el usuario, 8 si el cambio está cancelado en la nube por concurrencia, 9 si el cambio se cancela pq ya existe ese objeto y se trata de un Crear o Agregar a la lista, 0 si no está cancelado. 3 si no se canceló pero dio error al leer obj de firebase, 4 si no se cancela pero dio timeout al leer el obj con el valor actual, que sería que no tiene internet
     * 10 si se cancelo en la creacion del log por mac invalida, por no tener el usuario logueado, o si el email no es valido
     */
    cancel: number;

    /**
     * long(milliseconds) (SERVER_TIME) este campo lo rellena firebase cuando se le manda una operación.
     */
    date: any; //number o serverTimeStamp 

    /**
     * descripcion que sirve para el usuario para entender qué sucedió, por ej: agregado email gmarin@popapp.io a emails de la cuenta
     */
    desc: string;

    /**
     * msj de error que de en alguna operación, por ej cuando cancel = 3, guardamos el msj de error que devuelve firebase en este campo.
     */
    err: string;

    /**
     * (milliseconds) = la última fecha que tengamos registrada en milisegundos del servidor
     */
    fecha: number;

    /**
     * el id numérico usado en BD local si es necesario que nada tiene que ver con el id que tiene en firebase al crearse
     */
    id: number;

    /**
     * es la key que tenía el objeto modificado en last, es decir que apunta al movimiento anterior a este, formando una cadena de movimientos.
     */
    last: string;

    /**
     * titulo del cambio, por ejemplo, email agregado, o email modificado, se recomienda usar el correspondiente a cada op.
     */
    name: string;

    /**
     * algún comentario generado por el usuario
     */
    obs: string;

    /**
     * alguno de los tipos listados en la sección de abajo, indican qué tipo de operación y sobre que objeto de datos
     */
    op: number;

    /**
     * el id del usuario
     */
    user: string; //o long 


    /**
     * los objetos que reemplazan a lo nuevo, para escribir en Firebase se debe hacer por cada elemento del arreglo, un mapa: {url+key: valor} y hacer un PUT a firebase con ese mapa
     */
    vNuev: ValorFirebase[];



    /**
     * Tipos de operaciones
     * Ref Tipos de operaciones (op):
        101: Email agregado
        102: Email modificado
        103: Email eliminado
     */


}