import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mypopapp';
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const popapp = 'popapp.io';
        const mypopapp = environment.urls.mypopapp;
        if (window.location.hostname === popapp) {
          window.location.href = window.location.href.replace(popapp, mypopapp);
        }
      }
      try {
        if (event instanceof NavigationEnd) {
          try {
            if (event.url === "/mypopapp/descargar?de=bienvenido") {
              // (<any>window).gtag('event', 'conversion', { 'send_to': 'AW-407618734/_t8bCI7IjvoBEK6Jr8IB' });
            }
          } catch (e) {
            console.error(e);
          }

          // (<any>window).ga('set', 'page', event.urlAfterRedirects);
          // (<any>window).ga('send', 'pageview');
        }
      } catch (e) {
        console.error(e);
      }
    });
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
}
