import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';

//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/toPromise';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


import { AuthService } from "../../core/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { MovimientoAdmin } from '../../shared/admin/movimientoAdmin';
import { Cambio } from '../../shared/admin/cambio';

import * as consts from "../../shared/constantes";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-movimientos',
  templateUrl: './admin-movimientos.component.html',
  styleUrls: ['./admin-movimientos.component.scss']
})

export class AdminMovimientosComponent implements OnInit, OnDestroy {

  environment = environment;

  movimientosCuentasRef;

  cantLeidos: number = 0;


  movimientosMap: Map<String, MovimientoAdmin>;
  movimientos: MovimientoAdmin[] = [];

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;

  msjSnack: string;

  fecha: any;
  //selectedOptions: ServidorAdmin[];
  @ViewChild(MatSort) sort: MatSort;

  fechaInicioQuery: string;
  fechaFinQuery: string;
  //datos tabla

  displayedColumns: string[] = ['select', 'pic', 'date', 'email', 'authType',
    'tipo',
    'cuenta',
    'cambios',
    "firebase"
  ];

  columnsToDisplay: string[] = this.displayedColumns;//.slice(0, 9);

  dataSource: MatTableDataSource<MovimientoAdmin> = new MatTableDataSource(this.movimientos);


  filtroTodasColumnas: boolean = false;

  filtFUCheck: boolean = false;
  filtTokenFEArg: boolean = false;


  filtroActual: string = "a";
  filtroActual2: string = "";
  buscandoActual: string;
  filtroClientesActivado: boolean = true;

  subsQuery: Subscription;
  idCuentaQuery: string;

  cantLast: number = 100;

  actualizaFiltroClientesActivos() {
    this.filtroClientesActivado = !this.filtroClientesActivado;
    this.applyFilter(this.filtroActual);
    this.filtroClientesActivado = !this.filtroClientesActivado;

  }

  modifCantLast() {
    let cant = +prompt("Ingresa el numero de ultimos movimientos a buscar", this.cantLast + "");
    if (cant !== this.cantLast) {
      this.cantLast = cant;
      this.buscar();
    }
  }

  actualizaColumnas(value: number, filtro: boolean) {
    if (value == 999) {
      if (filtro) {
        //ocultar columnas
        /*this.columnsToDisplay = this.displayedColumns.slice(0, 5);*/

        this.filtFUCheck = false;
        this.filtTokenFEArg = false;


      } else {
        /*this.columnsToDisplay = this.displayedColumns.slice(0, 5);
        for (let i = 5; i < 48; i++) {
          this.columnsToDisplay.push(this.displayedColumns[i]);
        }*/
        this.filtFUCheck = true;
        this.filtTokenFEArg = true;

      }
      this.applyFilter(this.filtroActual);
    } else if (filtro) {

      if (value == 0) {
        this.filtFUCheck = false;
        this.applyFilter(this.filtroActual);
        this.filtFUCheck = true;
      } else if (value == 1) {
        this.filtTokenFEArg = false;
        this.applyFilter(this.filtroActual);
        this.filtTokenFEArg = true;
      }
    } else {
      //mostrar columnas correspondientes
      this.applyFilter(this.filtroActual);

      if (value == 0) {
        this.filtFUCheck = true;
        this.applyFilter(this.filtroActual);
        this.filtFUCheck = false;

      } else if (value == 1) {
        this.filtTokenFEArg = true;
        this.applyFilter(this.filtroActual);
        this.filtTokenFEArg = false;
      }
    }

  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = "";

    let filtro = filterValue.trim().toLowerCase();
    if (!filtro || filtro.length == 0) {
      filtro = "a";
      this.filtroActual2 = "";
      this.buscandoActual = "";
    } else {
      if (filtro === "a") {
        this.filtroActual2 = "";
        this.buscandoActual = "";
      } else {
        this.filtroActual2 = filtro;
        this.buscandoActual = filtro;
      }
    }

    this.filtroActual = filtro;
    this.dataSource.filter = filtro;

    this.dataSource.sort = this.sort;


  }


  selection = new SelectionModel<MovimientoAdmin>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }


  ngOnDestroy() {
    if (this.subsQuery) {
      this.subsQuery.unsubscribe();
    }
  }
  driftVar: any;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  ngOnInit() {
    let date = new Date();
    this.fechaFinQuery = date.toISOString();
    this.fechaInicioQuery = new Date(date.setDate(date.getDate() - 1)).toISOString();
    this.dataSource.sort = this.sort;
    this.buscar();

    this.subsQuery = this.activatedRoute.params.subscribe(v => {
      if (v.idCuenta) {
        this.idCuentaQuery = v.idCuenta;
        this.buscar();
      }
    });
    this.hideDrift(1)

  }

  constructor(public router: Router, public activatedRoute: ActivatedRoute, public afAuth: AngularFireAuth, public db: AngularFireDatabase, public authService: AuthService) {
    //console.log("ENTRO");
  }

  esAdmin(user) {
    return consts.esAdmin(user);
  }

  buscar() {
    this.afAuth.user.pipe(first()).subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {
        // console.log("SUSCRIPTO");

        //leemos las cuentas
        this.itemsRef = this.db.list('movimientosCuentas', ref => {
          // Use snapshotChanges().map() to store the key
          if (this.idCuentaQuery && this.idCuentaQuery.length > 0) {
            return ref.orderByChild('idCuenta').startAt(this.idCuentaQuery).endAt(this.idCuentaQuery).limitToLast(this.cantLast);
          } else {
            return ref.orderByChild('date').startAt(this.fechaInicioQuery).endAt(this.fechaFinQuery)
          }
        });
        this.items = this.itemsRef.snapshotChanges().pipe(
          first(),
          map(changes => {
            let mapeado = changes.map(movAux => ({ key: movAux.payload.key, ...movAux.payload.val() }));
            //console.log("LLEGO ALGO");
            this.procesaMovs(mapeado);
            this.dataSource.sort = this.sort;
            return mapeado;
          })
        );
      }
    });
  }


  sortHeader(index: number) {
    //console.log("click");
    this.dataSource.sort = this.sort;
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }


  onRightClick(input: string, element: MovimientoAdmin) {
    if (input == "cobrar") {
      //leer mails de ese usuario

      return false;
    }
  }


  inputABM(input: string, element: MovimientoAdmin) {
    let movimientosAModificar: MovimientoAdmin[] = [];
    if (this.selection.selected && this.selection.selected.length > 0) {
      for (let i = 0; i < this.selection.selected.length; i++) {
        movimientosAModificar.push(this.selection.selected[i]);
      }
      if (!this.selection.selected.includes(element)) {
        movimientosAModificar.push(element);
      } //else ya se proceso en el for
    } else {
      //proceso individual
      movimientosAModificar.push(element);
    }
  }

  objectsAreEqual(a, b) {
    for (var prop in a) {
      if (a.hasOwnProperty(prop)) {
        if (b.hasOwnProperty(prop)) {
          if (typeof a[prop] === 'object') {
            if (!this.objectsAreEqual(a[prop], b[prop])) return false;
          } else {
            if (a[prop] !== b[prop]) return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * @param before 
   * @param after 
   * @param padreKey 
   * @param revisaAgregaciones primero se llama en false para chequear modificaciones y eliminaciones y luego en true para las agregaciones
   */
  diferenciaDe2(before, after, padreKey: string, revisaAgregaciones?: boolean): Cambio[] {
    let cambios: Cambio[] = [];
    if (revisaAgregaciones) {
      for (let key in after) {
        if (after.hasOwnProperty(key)) {
          if (before.hasOwnProperty(key)) {
            //esto ya se reviso en el paso anterior de modificaciones o eliminaciones
          } else {
            cambios.push(new Cambio(padreKey, key, JSON.stringify(after[key]), "+++", this.shortKey(padreKey)));
          }
        }
      }
      return cambios;
    } else {
      for (let key in before) {
        if (before.hasOwnProperty(key)) {
          if (after.hasOwnProperty(key)) {
            if (typeof before[key] === 'object') {
              for (let camb of this.diferenciaDe2(before[key], after[key], padreKey + key + "/", revisaAgregaciones)) {
                cambios.push(camb);
              }
            } else {
              if (before[key] !== after[key]) {
                cambios.push(new Cambio(padreKey, key, " -- " + JSON.stringify(before[key]) + "   ++ " + JSON.stringify(after[key]), "--++", this.shortKey(padreKey)));
              } else {
                //son iguales no deberia pasar nada
              }
            }
          } else {
            cambios.push(new Cambio(padreKey, key, JSON.stringify(before[key]), "---", this.shortKey(padreKey)));
          }
        } else {
          //return "else"
        }
      }
      //en este paso se revisan las agregaciones
      for (let camb of this.diferenciaDe2(before, after, padreKey, true)) {
        cambios.push(camb);
      }
    }
    return cambios;
  }

  shortKey(padreKey: string): string {
    if (padreKey) {
      let i = padreKey.indexOf("servidores/");
      let i2 = padreKey.indexOf("/", i + 12);
      if (i != -1) {
        return padreKey.substr(i2, padreKey.length - i2);
        //return "Servidor";
      } else if (padreKey.includes("locales")) {
        return "Local";
      }
    }

    return "Cuenta";
  }
  diferencia(before, after, padreKey: string): Cambio[] {
    // console.log({ before, after })
    if (before) {
      if (after) {
        return this.diferenciaDe2(before, after, padreKey);
      } else {
        let cambio = new Cambio(padreKey, "", JSON.stringify(before), "----", "Cuenta");
        let cambios: Cambio[] = [];
        cambios.push(cambio);
        return cambios;
      }
    } else {
      if (after) {
        let cambio = new Cambio(padreKey, "", JSON.stringify(after), "++++", "Cuenta");
        let cambios: Cambio[] = [];
        cambios.push(cambio);
        return cambios;
      } else {
        let cambio = new Cambio(padreKey, "", "No hay Datos Anteriores ni Posteriores", "ERR", "Cuenta");
        let cambios: Cambio[] = [];
        cambios.push(cambio);
        return cambios;
      }
    }
  }
  procesaMovs(mapMovimientos: any[]) {
    let hoyDia: number = new Date().getDate();
    let hoyMes: number = new Date().getMonth();
    let hoyAnio: number = new Date().getFullYear();
    this.movimientos = [];
    this.movimientosMap = new Map<string, MovimientoAdmin>();
    this.movimientos = [];

    for (let i in mapMovimientos) {
      let m: MovimientoAdmin = mapMovimientos[i];
      m.id = mapMovimientos[i].key;
      this.movimientosMap.set(mapMovimientos[i].key, m);

      m.cambios = this.diferencia(m.before, m.after, "cuentas/" + m.idCuenta + "/");

      if (m.after && m.after.nombreCuenta) {
        m.cuenta = m.after.nombreCuenta
      } else if (m.before && m.before.nombreCuenta) {
        m.cuenta = m.before.nombreCuenta
      }

      this.movimientos.push(m);


      // no habria q sumar nada pues no sabemos q moneda iria
    }



    this.dataSource = new MatTableDataSource(this.movimientos);
    this.dataSource.filterPredicate = (m: MovimientoAdmin, filter: string) => {
      if (!this.filtFUCheck && m.cambios.length == 1 && m.cambios[0].key.includes("fechaUltimoCheck")) {
        return false;
      }

      if (!this.filtTokenFEArg && m.cambios.length == 1 && m.cambios[0].key.includes("access_token")) {
        return false;
      }

      return JSON.stringify(m).trim().toLowerCase().indexOf(filter) != -1;
    }
    this.applyFilter(this.filtroActual);
    this.deseleccionarTodes();
  }
  deseleccionarTodes() {
    this.selection.clear();
  }
  getMyStyles(dias: number) {
    let color = 'green';
    if (dias < 0 && dias > -15) {
      color = 'violet';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': 'white',
      'background': color,
      'padding': '10px'
    };
    return myStyles;
  }

  fechaDeddMMyyyyAyyyyMMdd(fechaInstalacion: string): string {
    if (fechaInstalacion) {
      let array = fechaInstalacion.split("/");
      if (array && array.length == 3) {
        return array[2] + "/" + (array[1].length > 1 ? array[1] : "0" + array[1]) + "/" + (array[0].length > 1 ? array[0] : "0" + array[0]);
      }
    }
    return "";
  }

  openSnackBar(message: string, action: string) {
    this.msjSnack = "|  " + message + "      " + action + "  |"
    setTimeout(_ => {
      this.msjSnack = null
    }, 3000)
    //this.snackBar.open(message, action, {
    // duration: 2000,
    //});
  }

}
