import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Pricing from '../models/pricing';
import { PricingService } from '../pricing.service';
import Billing from '../models/billing';
import Discount from '../models/discount';
import { ToastsService } from 'src/app/admin2/services/toasts.service';

@Component({
  selector: 'app-concept-modal',
  templateUrl: './concept-modal.component.html',
  styleUrls: ['./concept-modal.component.scss']
})
export class ConceptModalComponent implements OnInit {

  currentForm: 'discount' | 'charge' = 'charge';

  form: FormGroup;

  billings: Billing[];

  accountId: number;

  premiseId: number;

  conceptOptions = [
    { id: 'charge', label: 'Adicional' },
    { id: 'discount', label: 'Descuento' },
  ];

  conceptType = [
    { id: 'amount', label: 'Monto' },
    { id: 'percentage', label: 'Porcentaje' },
  ];

  pricings: Pricing[];

  constructor(
    private toastSrv: ToastsService,
    private pricingSrv: PricingService,
    private dialogRef: MatDialogRef<ConceptModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { pricings: Pricing[], accountBillings: Billing[], accountId: number, premiseId: number },
  ) {
    this.pricings = data.pricings.filter(p => !p.fixed);
    this.billings = data.accountBillings;
    this.accountId = data.accountId;
    this.premiseId = data.premiseId;
  }

  ngOnInit(): void {
    this.form = this.newChargeForm();
  }

  onOptionChange(selectedOption) {
    const newValue = selectedOption.id;
    if (newValue === 'discount') {
      this.currentForm = 'discount';
      this.form = this.newDiscountForm();
    } else {
      this.currentForm = 'charge';
      this.form = this.newChargeForm();
    }
  }

  private newChargeForm() {
    return new FormGroup({
      'option': new FormControl('charge', [Validators.required]),
      'pricing': new FormControl('', [Validators.required]),
      'numPayments': new FormControl(''),
    });
  }

  private newDiscountForm() {
    return new FormGroup({
      'option': new FormControl('discount', [Validators.required]),
      'billing': new FormControl('', [Validators.required]),
      'name': new FormControl('', [Validators.required]),
      'type': new FormControl('percentage', [Validators.required]),
      'value': new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)]),
      'numPayments': new FormControl(''),
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }


  getCurrType(): 'amount' | 'percentage' {
    return this.form.controls['type'].value;
  }

  getOptionLabel() {
    return this.currentForm === 'discount' ? 'descuento' : 'adicional';
  }

  getTypeLabel() {
    return this.getCurrType() === 'amount' ? 'valor' : 'porcentaje';
  }

  onClickSave() {
    console.log(this.form);
    if (this.currentForm === 'discount') {
      return this.createAddDiscount();
    }
    this.addBilling(this.form.controls['pricing'].value);
  }

  createAddDiscount() {
    if (this.currentForm === 'discount') {
      const billingId = +this.form.controls['billing'].value;
      if (!billingId) {
        throw new Error('billingId required');
      }
      const newDiscount: Discount = {
        id: 0,
        name: this.form.controls['name'].value,
        percentage: this.form.controls['value'].value / 100,
        description: '',
        terms: '',
      };
      this.pricingSrv.createDiscount(newDiscount).subscribe(
        res => {
          console.log('discount created', res.data);
          this.addDiscountToBilling(billingId, res.data);
        },
        err => {
          this.toastSrv.show('Error al crear el descuento');
          console.error('error creating discount', err);
        }
      )
    } else {
      throw new Error('cannot create a discount with charge form');
    }
  }

  addDiscountToBilling(billingId: number, discount: Discount) {
    this.pricingSrv.addDiscountToBilling(billingId, `${discount.id}`).subscribe(
      res => {
        console.log('new billing with discount', res.data);
        this.toastSrv.show('Descuento agregado con éxito');
        this.dialogRef.close({ billingUpdated: res.data, discountAdded: discount });
      },
      err => {
        this.toastSrv.show('Error al vincular descuento');
        console.error('error adding discount', err);
      }
    )
  }

  addBilling(pricingId: number) {
    this.pricingSrv.createBilling(this.accountId, this.premiseId, pricingId).subscribe(
      res => {
        console.log('billing created', res.data);
        this.toastSrv.show('Concepto agregado con éxito');
        this.dialogRef.close({ accountBillings: res.data });
      },
      err => {
        console.error('error creating billing', err);
        this.toastSrv.show('Error al crear concepto');
      }
    );
  }

  log(o) {
    console.log('o', o);
  }

}
