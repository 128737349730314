export class LineChartConfig {
    title: string;
    curveType: string;
    colors: string[];

    constructor(title: string, curveType: string, colors?: string[]) {
        this.title = title;
        this.curveType = curveType;
        if (colors) {
            this.colors = colors;
        }
    }
}