import { Component, OnInit } from '@angular/core';
import { RutaService } from '../../services/ruta.service';
import { AuthService } from '../../core/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalOlvidePassComponent } from '../../modales/modal-olvide-pass/modal-olvide-pass.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-acceder',
  templateUrl: './acceder.component.html',
  styleUrls: ['./acceder.component.scss', '../../../mypopapp/assets/_css/style.css','../../../mypopapp/assets/_css/style1.2.css', '../../../mypopapp/assets/_css/media-queries.css']
})
export class AccederComponent implements OnInit {
  error: string;
  registrando: boolean = false;
  formAcceder: FormGroup;
  submitted: boolean = false;

  constructor(public rutaService: RutaService, public auth: AuthService, private formBuilder: FormBuilder, public activatedRoute: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit() {
    this.rutaService.changeRuta("acceder")
    this.formAcceder = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      pass: ["", [Validators.required, Validators.minLength(6)]],
    });
    this.activatedRoute.queryParams.subscribe(params => {
      let registrandoConGoogleDesdeComenzar = params['regG'];

      if (registrandoConGoogleDesdeComenzar && registrandoConGoogleDesdeComenzar === "google") {
        this.submitted = true;
        this.error = null;

        this.auth.googleLogin(true)
          .then(r => {
            //this.routeToMyPopApp()
          })
          .catch(error => {
            console.log("Error al registrarse con Google", error)
            //this.authService.mandarEmailError("Error al registrarse con Google", JSON.stringify(error))
            this.error = error.message

          })
      } else {
        let registradoConEmailYPassDesdeComenzar = params['regE'];
        if (registradoConEmailYPassDesdeComenzar && registradoConEmailYPassDesdeComenzar.length > 3) {
          this.submitted = true;
          this.error = null;

          this.registrando = true;

          let refEyP = this.auth.leerFB("registrando/" + this.auth.decodeEmail(registradoConEmailYPassDesdeComenzar));

          refEyP.snapshotChanges().subscribe(action => {

            //consts.logD(action.type);
            //consts.logD(action.key);
            //consts.logD(action.payload.val());
            let auxEyP: any = action.payload.val();
            if (auxEyP && auxEyP.email && auxEyP.pass) {
              //borrar Aux
              refEyP.set(null);

              this.auth.emailLogin(auxEyP.email, auxEyP.pass)
                .then(_ => {
                  this.registrando = false
                })
                .catch(_ => {
                  //sino intentar registrar
                  this.auth.emailSignUp(auxEyP.email, auxEyP.pass)
                    .then(_ => {
                      //this.routeToMyPopApp()
                      this.registrando = false
                    })
                    .catch(error => {
                      this.registrando = false
                      console.log("No se pudo crear el usuario con pass", error)
                      this.error = error.message
                      //this.authService.mandarEmailError("No se pudo crear el usuario con pass", JSON.stringify(error))
                    })
                })

            }
          });
        }
      }
    });
  }


  olvide() {
    const dialogRef = this.dialog.open(ModalOlvidePassComponent, {
      width: '85%',
      data: undefined
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  acceder() {
    this.submitted = true;
    if (this.formAcceder.invalid) {
      return;
    }
    this.registrando = true
    this.auth.emailLogin(this.f.email.value, this.f.pass.value)
      .then(_ => {
        this.registrando = false
      }).catch(error => {
        this.registrando = false
        console.log("No se pudo iniciar con el usuario con pass", error)
        this.error = error.message
        //this.authService.mandarEmailError("No se pudo crear el usuario con pass", JSON.stringify(error))

      })

  }
  get f() { return this.formAcceder.controls; }




}
