import { Component, OnInit, OnDestroy } from '@angular/core';
import { CuentaService } from '../../services/cuenta.service';
import { AuthService, InfoCuenta } from '../../core/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as consts from "../../shared/constantes";

@Component({
  selector: 'app-cancelar',
  templateUrl: './cancelar.component.html',
  styleUrls: ['./cancelar.component.scss', '../../../mypopapp/assets/_css/mypopapp.css', '../../../mypopapp/assets/_css/mypopapp-mq.css']
})
export class CancelarComponent implements OnInit, OnDestroy {
  subscriptionCuenta: Subscription;
  idLocal: any;
  macElegida: string;
  servidor: any;
  plan: any;
  infoCuenta: InfoCuenta;
  subsInfoCuenta: Subscription;

  constructor(public authService: AuthService, public snackBar: MatSnackBar, public cuentaService: CuentaService, public router: Router) { }

  marca() {
    return consts.marca(this.infoCuenta);
  }

  ngOnDestroy() {
    if (this.subsInfoCuenta) {
      try { this.subsInfoCuenta.unsubscribe() } catch (e) { }
    }
  }
  ngOnInit() {
    this.subsInfoCuenta = this.authService.infoCuenta.subscribe(infoCuenta => {
      this.infoCuenta = infoCuenta;
    });
    this.subscriptionCuenta = this.authService.cuenta$
      .subscribe(cuenta => {
        if (cuenta) {
          let refMacP = this.authService.leerFB('macsPasadas/' + cuenta.id);
          refMacP.snapshotChanges().subscribe(action => {

            let macPasad = action.payload.val();
            let idCuenta = macPasad['idCuenta'];
            this.idLocal = macPasad['idLocal'];
            this.macElegida = cuenta.id;
            this.leerFBCuenta(idCuenta);

          });
        }
      });
  }
  leerFBCuenta(idCuenta: string): void {
    if (idCuenta && idCuenta.length > 3) {
      let refCuenta = this.authService.leerFB('cuentas/' + idCuenta);
      refCuenta.snapshotChanges().subscribe(action => {
        let cuentaAdmin = action.payload.val();
        let locales = cuentaAdmin['locales'];
        let local = locales[this.idLocal];
        let servidores = local['servidores'];
        this.servidor = servidores[this.macElegida];
        this.servidor.idCuenta = idCuenta;
        this.servidor.idLocal = this.idLocal;
        this.servidor.idMac = this.macElegida;
        this.plan = this.servidor['planN'];
      });
    } else {
      this.snackBar.open("No se pudo leer la cuenta, reintenta más tarde", "", { duration: 4000 });
    }
  }

  cancelar() {
    this.cuentaService.downgrade(this.servidor, 0)
      .then(_ => {
        this.router.navigateByUrl('/mypopapp/miplan/cancelar2/' + this.plan);
      })
      .catch(reason => {
        console.log(reason);
        this.snackBar.open("No se pudo realizar la operación", "Reintentar", { duration: 4000 }).onAction().subscribe(_ => {
          this.cancelar();
        });
      });
  }
}
