import { Component, OnInit, OnDestroy } from '@angular/core';
import { CuentaService } from 'src/app/services/cuenta.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { PieChartConfig } from 'src/app/charts/pieconfig';
import { Subscription } from 'rxjs';
import { LineChartConfig } from 'src/app/charts/linechartconfig';
import { BarChartConfig } from 'src/app/charts/barchartconfig';
import { environment } from 'src/environments/environment';



export interface Persona {
  id: string;
  name: string;

  totalPuntos: number;
  tipos: Map<string, { nombre: string, total: number, fechas: Map<string, { dia: string, totalDeEseDia: number }> }>;
  tags: Map<string, { nombre: string, total: number, fechas: Map<string, { dia: string, totalDeEseDia: number }> }>;



  data1: any[];
  config1: PieChartConfig;
  elementId1: string;
  pieTrueBarFalse1: boolean;

  data2: any[];
  config2: PieChartConfig;
  elementId2: string;
  pieTrueBarFalse2: boolean;
}
export interface TareaAgil {
  key: string;

  Asignada: { email: string, id: string, name: string }[];
  Estado: string;
  Estimacion: number;
  Meetings: string;
  Name: string;
  Tags: string[];
  Tipo: string;
}

@Component({
  selector: 'app-admin-tareas',
  templateUrl: './admin-tareas.component.html',
  styleUrls: ['./admin-tareas.component.scss']
})
export class AdminTareasComponent implements OnInit, OnDestroy {
  driftVar: any;
  tareas: Map<string, TareaAgil>;
  personas: Map<string, Persona>;
  _MS_PER_DAY = 1000 * 60 * 60 * 24;
  dias: number;
  subs: Subscription;
  totalTdx: number;
  fechaDesde: Date;
  fechaHasta: Date;

  histograma: boolean = false;
  data4: any[];
  config4: LineChartConfig;
  elementId4: string;

  mapDias: Map<string, string>;

  histogramaTodoTotal = false;
  data6: any[];
  config6: BarChartConfig;
  elementId6: string;

  histogramaPorSemanaTipo = false;
  data5: any[];
  config5: BarChartConfig;
  elementId5: string;

  constructor(private cuentaService: CuentaService, private db: AngularFireDatabase) { }

  ngOnInit() {
    this.hideDrift(1);
  }

  ngOnDestroy() {
    if (this.subs) {
      try { this.subs.unsubscribe() } catch (e) { }
    }
  }

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  prom(total) {
    if (this.dias) {
      let promed = Math.floor(total / this.dias);
      return "" + promed + " " + Math.floor((100 * promed / (this.totalTdx / this.dias))) + "%";
    } else {
      return 0;
    }
  }
  importarTareas() {
    let si = confirm("Revisaste el estado de La vista Grid general de Tares en Airtable?");
    if (si) {
      this.cuentaService.getUrlProm(environment.urls.functions +"/api/agilidad/updateTareas").then(v => {
        console.log("exito", v)
      }).catch(e => {
        console.error("error al leerr ", e)
      })
    }
  }
  addDays(date: Date, days): Date {
    let dateAux = new Date(date);
    dateAux.setDate(dateAux.getDate() + days);
    return dateAux;
  }

  getDates(startDate: Date, stopDate: Date): Date[] {
    let dateArray = [];
    startDate.setHours(startDate.getHours() + 3);
    stopDate.setHours(stopDate.getHours() + 3);
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = this.addDays(currentDate, 1);
    }
    return dateArray;
  }

  fechaString(d: Date): string {
    let m = (d.getMonth() + 1);
    let mes = m > 9 ? "-" + m : "-0" + m;
    let dia = d.getDate() > 9 ? "-" + d.getDate() : "-0" + d.getDate();
    return d.getFullYear() + mes + dia;
  }

  dibujaCharts6(tareas) {
    let lineas6: string[] = ['Semana', "TO DO", "DONE"]
    let colores6: string[] = [this.rojo, this.verde];
    this.data6 = [];
    this.histogramaPorSemanaTipo = false;//6
    this.data6.push(lineas6);
    let valores: any[] = [];
    let totalSenanaTODO = 0;
    let totalSemanaDONE = 0
    let dateAnterior = "";
    for (let date of this.mapDias.values()) {
      if (valores.length === 0) {
        valores = [date];
      }
      let totalDiaDone = 0
      let totalDiaTodo = 0
      for (let v of tareas.values()) {

        let t = <TareaAgil>v;
        if (t.Meetings === date) {
          let sizeConjunto = t.Estimacion
          if (t.Asignada) {
            sizeConjunto = t.Estimacion * t.Asignada.length;
          }
          if (t.Estado === "DONE") {
            totalDiaDone += sizeConjunto
          } else {
            totalDiaTodo += sizeConjunto
          }
        }
      }

      totalSenanaTODO += totalDiaTodo
      totalSemanaDONE += totalDiaDone

      if (totalDiaDone === 0 && totalDiaTodo === 0 && (totalSemanaDONE > 0 || totalSenanaTODO > 0)) {
        valores[0] = valores[0] + " a " + dateAnterior;
        valores.push(totalSenanaTODO);
        valores.push(totalSemanaDONE);
        this.data6.push(valores);
        valores = [];
        totalSenanaTODO = 0
        totalSemanaDONE = 0
      }
      dateAnterior = date;
    }

    if (totalSenanaTODO > 0 || totalSemanaDONE > 0) {
      valores[0] = valores[0] + " a " + dateAnterior;
      valores.push(totalSenanaTODO);
      valores.push(totalSemanaDONE);
      this.data6.push(valores);
    }


    this.config6 = new BarChartConfig('TO DO vs DONE x semana', '', colores6);
    this.elementId6 = 'myPieChart4';
  }
  dibujaChartComparativo(p: Persona) {
    //aca iria la 5
    this.data5 = [];

    let lineas: string[] = ['Tipo'] //, "SOPORTE", "BUG", "AGILIDAD", "MEJORA"] //, "Resolved", "Closed", "Open", "Pending", "Total"];
    let colores: string[] = [];
    let tiposXSemana: number[] = [];
    for (let t of p.tipos.values()) {
      if (!lineas.includes(t.nombre)) {
        tiposXSemana.push(0);
        lineas.push(t.nombre);
        colores.push(this.colorPorTag(t.nombre));
      }
    }

    this.data5.push(lineas);

    let dateAnterior = "";
    let totalSemana = 0;
    let valores: any[] = []
    for (let date of this.mapDias.values()) {
      if (valores.length === 0) {
        valores = [date];
      }
      let totalFecha = 0;
      let i = 0;
      p.tipos.forEach((v) => {

        let total = 0;
        if (v.fechas.get(date)) {
          total = v.fechas.get(date).totalDeEseDia
        }
        tiposXSemana[i] += total;
        totalSemana += total;
        totalFecha += total;
        i++;
      });

      if (totalFecha === 0 && totalSemana > 0) {
        valores[0] = valores[0] + " a " + dateAnterior;
        i = 0;
        for (let t of tiposXSemana) {
          valores.push(t);
          tiposXSemana[i] = 0;
          i++
        }
        this.data5.push(valores);
        totalSemana = 0;
        valores = []
      }
      dateAnterior = date;
    }

    if (totalSemana > 0) {
      valores[0] = valores[0] + " a " + dateAnterior;
      let i = 0;
      for (let t of tiposXSemana) {
        valores.push(t);
        tiposXSemana[i] = 0;
        i++;
      }
      this.data5.push(valores);
    }


    this.config5 = new BarChartConfig('Historico', '', colores);
    this.elementId5 = 'myPieChart5';
  }

  dibujaChartComparativoViejo(p: Persona) {
    //aca iria la 5
    this.data4 = [];

    let lineas: string[] = ['Tipo'] //, "SOPORTE", "BUG", "AGILIDAD", "MEJORA"] //, "Resolved", "Closed", "Open", "Pending", "Total"];
    let colores: string[] = [];
    for (let t of p.tipos.values()) {
      if (!lineas.includes(t.nombre)) {
        lineas.push(t.nombre);
        colores.push(this.colorPorTag(t.nombre));
      }
    }
    lineas.push("Total");
    colores.push(this.colorPorTag("Total"));


    this.data4.push(lineas);
    for (let date of this.mapDias.values()) {
      let valores: any[] = [date];
      let totalFecha = 0;
      p.tipos.forEach((v) => {

        let total = 0;
        if (v.fechas.get(date)) {
          total = v.fechas.get(date).totalDeEseDia
        }
        valores.push(total);
        totalFecha += total
      });
      valores.push(totalFecha);
      this.data4.push(valores);
    }


    this.config4 = new LineChartConfig('Historico', '', colores);
    this.elementId4 = 'myPieChart4';
  }

  async consultar(datos: { fechaDesde: string, fechaHasta: string, buscando: boolean }) {
    this.fechaDesde = new Date(datos.fechaDesde);
    this.fechaHasta = new Date(datos.fechaHasta);
    this.tareas = new Map<string, TareaAgil>();
    this.dias = 1 + this.dateDiffInDays(new Date(datos.fechaDesde), new Date(datos.fechaHasta));
    let a = this.db.list("agilidad/tareas", ref => ref.orderByChild("Meetings")
      .startAt(datos.fechaDesde)
      .endAt(datos.fechaHasta + "~"));

    let diasss = this.getDates(this.fechaDesde, this.fechaHasta);

    this.mapDias = new Map<string, string>();
    console.log(diasss)
    for (let d of diasss) {
      let daa = this.fechaString(d);
      this.mapDias.set(daa, daa);
    }
    let ts = await a.query.once("value").then(v => {
      return v.val();
    });


    let tareas2 = new Map<string, TareaAgil>();
    for (let tKey in ts) {

      let t = ts[tKey];
      tareas2.set(tKey, <TareaAgil>t);
    }

    this.procesaTarea(tareas2);

    this.tareas = tareas2;
    this.dibujaChartComparativo(this.personas.get("TODXS"));
    this.histograma = false;
    this.histogramaPorSemanaTipo = true;
    this.histogramaTodoTotal = true;
  }

  getTotal(p: Persona): number {
    let total = 0;
    if (p.tipos) {
      for (let t of p.tipos.values()) {
        total += t.total;
      }
    }
    return total;
  }

  getTotal2(p: Persona): number {
    let total = 0;
    if (p.tags) {
      for (let t of p.tags.values()) {
        total += t.total;
      }
    }
    return total;
  }

  procesaTarea(tareas: Map<string, TareaAgil>) {
    let personas = new Map<string, Persona>();
    let tiposa = new Map<string, { nombre: string, total: number, fechas: Map<string, { dia: string, totalDeEseDia: number }> }>();
    let tagsx = new Map<string, { nombre: string, total: number, fechas: Map<string, { dia: string, totalDeEseDia: number }> }>();
    let pc = new PieChartConfig('Tipo', 0.4);
    let pc2 = new PieChartConfig('Tags', 0, []);
    let p = {
      id: "TODXS",
      name: "TODXS",
      totalPuntos: 0,
      tipos: tiposa,
      tags: tagsx,
      fechas: new Map<string, { dia: string, totalDeEseDia: number }>(),
      data1: [],
      config1: pc,
      elementId1: 'myPieChart1todxs',
      pieTrueBarFalse1: true,


      data2: [],
      config2: pc2,
      elementId2: 'myPieChart2todxs',
      pieTrueBarFalse2: true,
    }
    personas.set("TODXS", p);
    tareas.forEach((v) => {
      for (let a in v.Asignada) {
        let tiposx = new Map<string, { nombre: string, total: number, fechas: Map<string, { dia: string, totalDeEseDia: number }> }>();
        let tagsx = new Map<string, { nombre: string, total: number, fechas: Map<string, { dia: string, totalDeEseDia: number }> }>();
        let pc = new PieChartConfig('Tipo', 0.4);
        let pc2 = new PieChartConfig('Tags', 0, []);
        let p = {
          id: v.Asignada[a].id,
          name: v.Asignada[a].name,
          totalPuntos: 0,
          tipos: tiposx,
          tags: tagsx,
          fechas: new Map<string, { dia: string, totalDeEseDia: number }>(),

          data1: [],
          config1: pc,
          elementId1: 'myPieChart1' + v.Asignada[a].id,
          pieTrueBarFalse1: true,

          data2: [],
          config2: pc2,
          elementId2: 'myPieChart2' + v.Asignada[a].id,
          pieTrueBarFalse2: true,
        };
        personas.set(v.Asignada[a].id, p);
      }
    });





    for (let v of tareas.values()) {
      if (v.Estado === "DONE") {
        let size = v.Estimacion;
        let sizeConjunto = v.Estimacion * v.Asignada.length;
        let arrep: Persona[] = [];


        for (let a in v.Asignada) {
          arrep.push(personas.get(v.Asignada[a].id));
        }
        for (let p of arrep) {
          this.actualizaValores(p.tipos, v, size, "Tipo");
          if (v.Tags) {
            this.actualizaValores(p.tags, v, size, "Tags");
          }
        }
        let ptdxs = personas.get("TODXS");
        this.actualizaValores(ptdxs.tipos, v, sizeConjunto, "Tipo");
        if (v.Tags) {
          this.actualizaValores(p.tags, v, size, "Tags");
        }

      }
    }

    for (let p of personas.values()) {
      this.dibujaCharts(p);
    }
    this.totalTdx = this.getTotal(personas.get("TODXS"));
    this.personas = personas
    console.log(personas);

    this.dibujaCharts6(tareas);
  }



  actualizaValores(
    coleccion: Map<string, {
      nombre: string;
      total: number;
      fechas: Map<string, {
        dia: string;
        totalDeEseDia: number;
      }>
    }>,
    v: TareaAgil,
    size: number,
    atributo: string) {

    let arre = v[atributo];
    if (!Array.isArray(arre)) {
      arre = [arre];
    }

    for (let tagOTipo of arre) {
      if (coleccion.get(tagOTipo)) {
        let nuevoTotal = coleccion.get(tagOTipo).total + size;
        let fechas = coleccion.get(tagOTipo).fechas;
        let fechaDeEseDia = fechas.get(v.Meetings);
        let totalDeEseDia = size;
        if (fechaDeEseDia) {
          totalDeEseDia += fechaDeEseDia.totalDeEseDia
        }
        fechas.set(v.Meetings, { dia: v.Meetings, totalDeEseDia: totalDeEseDia })
        coleccion.set(tagOTipo, { nombre: tagOTipo, total: nuevoTotal, fechas: fechas })
      } else {
        let map = new Map<string, { dia: string, totalDeEseDia: number }>();
        map.set(v.Meetings, { dia: v.Meetings, totalDeEseDia: size });
        coleccion.set(tagOTipo, { nombre: tagOTipo, total: size, fechas: map })
      }
    }
  }

  tiposPorDefecto(p: Persona, tipo: string) {
    if (p.tipos.get(tipo)) {
      p.data1.push([tipo, p.tipos.get(tipo).total])
    } else {
      p.data1.push([tipo, 0])
    }
  }
  dibujaCharts(p: Persona) {
    if (p.tipos && p.tipos.size > 0) {
      //Piechart1 Data & Config
      //Mesas
      p.data1 = [];
      p.data1.push(['Tipo', 'Puntos']);

      let tiposParaQueSeRespetenLosColores = ["SOPORTE", "BUG", "AGILIDAD", "MEJORA"];
      for (let t of tiposParaQueSeRespetenLosColores) {
        this.tiposPorDefecto(p, t);
      }

      p.tipos.forEach((v) => {
        if (!tiposParaQueSeRespetenLosColores.includes(v.nombre)) {
          p.data1.push([v.nombre, v.total])
        }
      });

      // p.config1 = new PieChartConfig('Tipo', 0.4);
      //  p.elementId1 = 'myPieChart1';
      // p.pieTrueBarFalse1 = true;

    }

    if (p.tags && p.tags.size > 0) {
      p.data2 = [];
      p.data2.push(['Tags', 'Puntos']);

      let colores = [];
      let tags = [];
      p.tags.forEach((v) => {
        if (!tags.includes(v.nombre)) {
          p.data2.push([v.nombre, v.total])
          colores.push(this.colorPorTag(v.nombre));
        }
      });

      p.config2 = new PieChartConfig('Tags', 0, colores);
      // p.elementId1 = 'myPieChart1';
      // p.pieTrueBarFalse1 = true;

    }
  }
  verde = "#109618";
  violeta = "#990099";
  rojo = "#dc3912";
  amarillo = "#ff9900";
  gris = "#888777";
  celeste = "#990099";
  azul = "#3366cc";

  colorPorTag(tag: string): string {

    switch (tag) {
      case "MEJORA":
      case "PLANING":
      case "PRE REFINEMENT":
      case "TUTORIAL":
        return this.verde;

      case "AGILIDAD":
      case "METRICAS":
      case "PRE REUNION":
      case "REUNION":
        return this.amarillo;

      case "BUG":
        return this.rojo;

      case "SOPORTE":
        return this.azul;
      case "OTRO":
      case "DEPLOY":
        return this.gris;

      case "Total":
        return this.celeste;

      default:
        return this.violeta;
    }
  }

  // a and b are javascript Date objects
  dateDiffInDays(a: Date, b: Date) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
  }
}
