import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-olvide-pass',
  templateUrl: './modal-olvide-pass.component.html',
  styleUrls: ['./modal-olvide-pass.component.scss']
})
export class ModalOlvidePassComponent implements OnInit {
  errorReset: string;
  correo: string;
  passReseteado: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalOlvidePassComponent>,
    public auth: AuthService, ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  resetPass() {
    this.errorReset = null
    this.auth.resetPassword(this.correo)
      .then(() => {
        this.passReseteado = true
      })
      .catch(error => {
        console.log("error", error)
        this.errorReset = error.message
      })
  }

}
