import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_FUNCTIONS } from '../core/const';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient
  ) { }

  obtenerPaises(){
    return this.http.get(BASE_URL_FUNCTIONS +'/basesql/paises');
  }
}
