import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { first, map, retry } from 'rxjs/operators';
import { CuentaAdmin } from '../../shared/admin/cuenta-admin';
import { LocalAdmin } from '../../shared/admin/local-admin';
import { ServidorAdmin } from '../../shared/admin/servidor-admin';

import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/toPromise';
import { Cuenta } from '../../shared/cuenta';


import { AuthService } from "../../core/auth.service";
import { CuentaService } from '../../services/cuenta.service';
import { Router } from '@angular/router';
import * as consts from "../../shared/constantes";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-admin-metricas',
  templateUrl: './admin-metricas.component.html',
  styleUrls: ['./admin-metricas.component.scss']
})
export class AdminMetricasComponent implements OnInit {

  cuentasRef;

  cotizacionUF;
  cotizacionUSDCLP: number;

  cantLeidas: number = 0;

  cuentas: CuentaAdmin[];
  cuentasMap: Map<String, CuentaAdmin>;
  locales: Map<String, LocalAdmin>;
  servidores: ServidorAdmin[] = [];

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;

  msjSnack: string;

  fecha: any;


  email: string;
  //datos tabla



  coeficienteDolar: number = 180.00;
  coeficienteDolarClp: number = 810.00;


  filtroClientesActivado: boolean = true;


  totalServidores: number;
  sinVencer: number;
  porcentaje: number;
  inc14porcentaje: number;
  inc14: number;

  cantServConImporteArg: number;
  cantServConImporteClp: number;
  cantServConImporteDolar: number;

  totalImportesDolar: number;
  totalImportesArg: number;
  totalImportesClp: number;

  totalImportesDolarcIVA: number;
  totalImportesArgcIVA: number;
  totalImportesClpcIVA: number;


  cuentasConImportesARS: Map<String, number>;
  cuentasConImportesUSD: Map<String, number>;
  cuentasConImportesCLP: Map<String, number>;

  localesConImportesARS: Map<String, number>;
  localesConImportesUSD: Map<String, number>;
  localesConImportesCLP: Map<String, number>;

  servidoresCLP: Map<String, number>;
  servidoresARS: Map<String, number>;
  servidoresUSD: Map<String, number>;

  cuentasActivas: String[];
  localesActivos: String[];
  servidoresActivos: String[];

  cargandoScript: boolean;

  conceptos = {};

  driftVar: any;
  totalCotizacionUSDARS: number;

  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }
  ngOnInit() {
    this.hideDrift(1)
    const callable = this.fns.httpsCallable("cotizacionUSDARS");
    callable({}).pipe(first()).subscribe(v => {
      this.totalCotizacionUSDARS = v;
      this.leerCuentas();
    });
    this.cargandoScript = false;
  }
  esAdmin(user) {
    return consts.esAdmin(user);
  }
  constructor(public router: Router, public afAuth: AngularFireAuth, public cuentaService: CuentaService, public db: AngularFireDatabase, private http: Http, public authService: AuthService,
    private dbFs: AngularFirestore,
    private fns: AngularFireFunctions,
  ) {
    //console.log("ENTRO");
    afAuth.user.subscribe(user => {
      if (user && user.email && this.esAdmin(user)) {
        // console.log("SUSCRIPTO");
        this.email = user.email;
        //leemos las cuentas



      }
    });
  }

  leerCuentas() {
    this.itemsRef = this.db.list('cuentas');
    this.items = this.itemsRef.snapshotChanges().pipe(
      first(),
      map(changes => {
        let mapeado = changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
        //console.log("LLEGO ALGO");
        const estructuraAdmin1 = this.sacaLocalesYServidores(mapeado);
        const estructuraAdmin2 = this.sacaLocalesYServidoresAdminMetricas2(mapeado);

        this.controlEstructuras(estructuraAdmin1, estructuraAdmin2);
        return mapeado;
      })
    );
  }

  recorrerEstructuraYLoguearDistinto2(estructura: Map<string, number>, numeroEstructura: number) {
    for (const id of estructura.keys()) {
      const cantidad = estructura.get(id);
      if (cantidad === 2) {
        console.log(`*** [${numeroEstructura}] *** id: ${id}: cantidad: ${cantidad}`);
      }
    }
  }

  recorrerEstructuraYRestarAlaOtra(
    estructuraARecorrer: Map<string, number>,
    otra: Map<string, number>,
  ) {
    for (const id of estructuraARecorrer.keys()) {
      this.resta1AEstructura(id, otra);
    }
  }

  resta1AEstructura(
    id: string,
    estructura: Map<string, number>,
  ) {
    if (
      estructura &&
      estructura.get(id)
    ) {
      let cantidad = estructura.get(id);
      cantidad--;
      estructura.set(id, cantidad);
    } else {
      // console.log('NO se encontro en la otra estructura', id)
    }
  }

  controlEstructuras(estructuraAdmin1: Map<string, number>, estructuraAdmin2: Map<string, number>) {
    this.recorrerEstructuraYRestarAlaOtra(estructuraAdmin1, estructuraAdmin2);
    this.recorrerEstructuraYRestarAlaOtra(estructuraAdmin2, estructuraAdmin1);

    this.recorrerEstructuraYLoguearDistinto2(estructuraAdmin1, 1);
    this.recorrerEstructuraYLoguearDistinto2(estructuraAdmin2, 2);

  }

  sacaLocalesYServidoresAdminMetricas2(mapCuentas: any[]): Map<string, number> {
    const estructuraControl: Map<string, number> = new Map();
    for (let i in mapCuentas) {
      let c: CuentaAdmin = mapCuentas[i];
      c.id = mapCuentas[i].key;
      let mapLocales = mapCuentas[i].locales;
      //for de locales
      for (let j in mapLocales) {
        let l: LocalAdmin = mapLocales[j];
        l.id = j;
        l.idCuenta = c.id;


        //for de servidores
        let mapServidores = mapLocales[j].servidores;
        for (let y in mapServidores) {
          let s: ServidorAdmin = mapServidores[y];
          s.idMac = y;
          s.idLocal = l.id;
          s.idCuenta = c.id;
          s.uCuenta = c.u;
          s.lastCuenta = c.last;
          if (c.whatsapp) {
            s.whatsapp = c.whatsapp;
          }
          if (c.contacto) {
            s.contacto = c.contacto;
          }
          s.nombreCuenta = c.nombreCuenta;
          s.nombreLocal = l.nombreLocal;


          if (this.metodoExporte(s)) {
            // TODO: importes a descontar de cuotas que no son fijas
            this.addEstructura(s.idCuenta, j, y, estructuraControl);

            if (isNaN(s.importe)) {
              console.log("*********************NaN************************")
              console.log(s)
            }

          }
        }
      }
    }

    return estructuraControl;
  }

  selectCuenta(cuentaAdmin: ServidorAdmin) {
    let cuenta: Cuenta = new Cuenta();
    cuenta.id = cuentaAdmin.idMac;
    cuenta.name = cuentaAdmin.nombreCuenta;
    this.authService.changeCuenta(cuenta);
  }

  copyToClipboard(item: string) {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  leerCotizacionUF() {

    const url = environment.urls.functions + "/cotizacion/cotizacion/UF/CLP";
    // const url = "https://mindicador.cl/api/uf/";

    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.serie && body.serie[0] && body.serie[0].valor) {
          this.cotizacionUF = Math.floor(body.serie[0].valor);
        } else if (body && body.data && body.data.cotizacion) {
          this.cotizacionUF = body.data.cotizacion;
        }
      })
      .catch(this.handleErrorObservable);

  }

  leerCotizacionUSDCLP() {

    const url = environment.urls.functions + "/cotizacion/cotizacion/USD/CLP";
    return this.http.get(url).toPromise()
      .then(res => {
        let body = res.json();
        if (body && body.data && body.data.cotizacion) {
          this.cotizacionUSDCLP = body.data.cotizacion;
        }
      })
      .catch(this.handleErrorObservable);

  }


  private handleErrorObservable(error: Response | any) {
    console.error("Erroooor FE", error.message || error);
    return Observable.throw(error.message || error);
  }

  /**
   * valida que la key sea != null y con length mayor a 3
   */
  keyValida(key: string): boolean {
    if (key != null && key.length > 3) {
      return true;
    } else {
      return false;
    }
  }


  diasss(fecha: string, hoyAnio, hoyMes, hoyDia): number {
    let fv: string = fecha;
    if (!fv) {
      fv = "1/1/1999";
    }
    let indexBarra: number = fv.indexOf("/");
    let iFor = 0;
    let stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let dia: number = Number(stringAuxFecha);

    fv = fv.replace(stringAuxFecha + "/", "");
    indexBarra = fv.indexOf("/");
    iFor = 0;
    stringAuxFecha = "";
    while (indexBarra > -1 && indexBarra > iFor) {
      stringAuxFecha += fv.charAt(iFor);
      iFor++;
    }
    let mes: number = Number(stringAuxFecha);
    fv = fv.replace(stringAuxFecha + "/", "");
    let anio: number = Number(fv);

    //let mes: number = Number(fv.charAt(3) + fv.charAt(4));
    //let anio: number = Number(fv.charAt(6) + fv.charAt(7) + fv.charAt(8) + fv.charAt(9));
    let dias = (anio - hoyAnio) * 365;
    dias += (mes - 1 - hoyMes) * 30;
    dias += (dia - hoyDia);
    return dias;
  }

  sacaLocalesYServidores(mapCuentas: any[]): Map<string, number> {

    const estructuraControl: Map<string, number> = new Map();
    if (!this.totalCotizacionUSDARS) {
      console.log("*********", !this.totalCotizacionUSDARS);
      return;
    }
    this.conceptos = {};

    let hoyDia: number = new Date().getDate();
    let hoyMes: number = new Date().getMonth();
    let hoyAnio: number = new Date().getFullYear();
    //console.log("empieza a sacar");
    //for (let i = 0; i < this.servidores.length;) {
    //this.servidores. pop();
    //}
    this.servidores = [];
    this.localesConImportesARS = new Map<string, number>();
    this.localesConImportesUSD = new Map<string, number>();
    this.localesConImportesCLP = new Map<string, number>();
    this.cuentasConImportesARS = new Map<string, number>();
    this.cuentasConImportesUSD = new Map<string, number>();
    this.cuentasConImportesCLP = new Map<string, number>();
    this.servidoresARS = new Map<string, number>();
    this.servidoresCLP = new Map<string, number>();
    this.servidoresUSD = new Map<string, number>();
    this.locales = new Map<string, LocalAdmin>();
    this.cuentasMap = new Map<string, CuentaAdmin>();
    this.cuentas = [];
    //for de cuentas
    let importes: number = 0;
    let importesDolar: number = 0;
    let importesClp: number = 0;

    let ivaARS: number = 0;
    let ivaDolar: number = 0;
    let ivaCLP: number = 0;

    this.cantServConImporteArg = 0;
    this.cantServConImporteDolar = 0;
    this.cantServConImporteClp = 0;

    let servidoresSinVencer: number = 0;
    let incluidos14: number = 0;
    for (let i in mapCuentas) {
      let c: CuentaAdmin = mapCuentas[i];
      c.id = mapCuentas[i].key;
      this.cuentasMap.set(mapCuentas[i].key, c);
      let mapLocales = mapCuentas[i].locales;
      //for de locales
      let indexLocal = 0;
      let totalLocales = 0;
      for (let j in mapLocales) {
        totalLocales++;
      }
      for (let j in mapLocales) {
        indexLocal++;
        let l: LocalAdmin = mapLocales[j];
        l.id = j;
        l.idCuenta = c.id;
        l.totLocales = totalLocales;


        //for de servidores
        let mapServidores = mapLocales[j].servidores;
        let indexServidor = 0;
        let totalServidores = 0;
        for (let y in mapServidores) {
          totalServidores++;
        }
        for (let y in mapServidores) {
          indexServidor++;
          let s: ServidorAdmin = mapServidores[y];
          s.idMac = y;
          s.idLocal = l.id;
          s.idCuenta = c.id;
          s.uCuenta = c.u;
          s.lastCuenta = c.last;
          if (c.whatsapp) {
            s.whatsapp = c.whatsapp;
          }
          if (c.contacto) {
            s.contacto = c.contacto;
          }
          s.nombreCuenta = c.nombreCuenta;
          s.nombreLocal = l.nombreLocal;
          s.indServidor = indexServidor;
          s.indLocal = indexLocal;
          s.totLocales = totalLocales;
          s.totServidoresOSvs = totalServidores;



          let dias = this.diasss(s.vencimiento, hoyAnio, hoyMes, hoyDia)
          s.dias = dias;

          let diasReal = this.diasss(s.pagoHasta, hoyAnio, hoyMes, hoyDia)
          s.diasReal = diasReal
          if (dias >= 0) {
            servidoresSinVencer++;
            incluidos14++;
          } else if (dias >= -7) {
            incluidos14++;
          }

          s.fechaInstalacionyyyyMMddd = this.fechaDeddMMyyyyAyyyyMMdd(s.fechaInstalacion);

          this.servidores.push(s);

          if (
            (this.metodoExporte(s) &&
              !this.servidorActivo(s))
            ||
            (!this.metodoExporte(s) &&
              this.servidorActivo(s))
          ) {
            console.log('Infeliz', s);
            this.dbFs.collection("mail").add({
              to: 'gmarin@popapp.io, jcortez@popapp.io, gperez@popapp.io, paula@popapp.io',
              message: {
                text: '',
                subject: 'Esta servidor se suma en el exporte y no en el metricas o viceversa: ' + s.idCuenta
              }
            });
          }

          if (s.numeroLocal && s.moneda && +s.numeroLocal > 0 && +s.numeroLocal < 9000 && +s.numeroLocal !== 900 && (s.cuotasPendientes == null)) { //1500 para arriba seria para testear pero no para sumar

            this.addEstructura(s.idCuenta, s.idLocal, s.idMac, estructuraControl);

            let importesADescontar = 0;
            if (s.conceptos) {
              for (let keyConc in s.conceptos) {
                if (s.moneda === "USD" && s.pais === "ARG") {
                  let concepto = s.conceptos[keyConc];
                  if (concepto.cuotasPendientes && concepto.cuotasPendientes > 0) {
                    importesADescontar += concepto.total;
                    let cuotasString = "CUOTAS (" + concepto.cuotasPendientes + ") ";
                    if (this.conceptos["ARS"]) {
                      if (this.conceptos["ARS"][cuotasString + concepto.concepto]) {
                        this.conceptos["ARS"][cuotasString + concepto.concepto] += this.totalCotizacionUSDARS * concepto.importe;
                      } else {
                        this.conceptos["ARS"][cuotasString + concepto.concepto] = this.totalCotizacionUSDARS * concepto.importe;
                      }
                    } else {
                      this.conceptos["ARS"] = {};
                      this.conceptos["ARS"][cuotasString + concepto.concepto] = this.totalCotizacionUSDARS * concepto.importe;
                    }
                  } else {
                    if (!concepto.cuotasPendientes) {
                      if (this.conceptos["ARS"]) {
                        if (this.conceptos["ARS"][concepto.concepto]) {
                          this.conceptos["ARS"][concepto.concepto] += this.totalCotizacionUSDARS * concepto.importe;
                        } else {
                          this.conceptos["ARS"][concepto.concepto] = this.totalCotizacionUSDARS * concepto.importe;
                        }
                      } else {
                        this.conceptos["ARS"] = {};
                        this.conceptos["ARS"][concepto.concepto] = this.totalCotizacionUSDARS * concepto.importe;
                      }
                    }
                  }

                  if (!this.conceptos["ARS"]["zzzTOTAL"]) {
                    this.conceptos["ARS"]["zzzTOTAL"] = this.totalCotizacionUSDARS * concepto.importe;
                  } else {
                    this.conceptos["ARS"]["zzzTOTAL"] = this.conceptos["ARS"]["zzzTOTAL"] + this.totalCotizacionUSDARS * concepto.importe;
                  }

                  if (concepto.concepto === "Contabilium Fact. Electrónica") {
                    importesADescontar += concepto.importe
                  }
                } else {
                  let concepto = s.conceptos[keyConc];
                  if (concepto.cuotasPendientes && concepto.cuotasPendientes > 0) {
                    importesADescontar += concepto.total;
                    let cuotasString = "CUOTAS (" + concepto.cuotasPendientes + ") ";
                    if (this.conceptos[s.moneda]) {
                      if (this.conceptos[s.moneda][cuotasString + concepto.concepto]) {
                        this.conceptos[s.moneda][cuotasString + concepto.concepto] += concepto.importe;
                      } else {
                        this.conceptos[s.moneda][cuotasString + concepto.concepto] = concepto.importe;
                      }
                    } else {
                      this.conceptos[s.moneda] = {};
                      this.conceptos[s.moneda][cuotasString + concepto.concepto] = concepto.importe;
                    }
                  } else {
                    if (!concepto.cuotasPendientes) {
                      if (this.conceptos[s.moneda]) {
                        if (this.conceptos[s.moneda][concepto.concepto]) {
                          this.conceptos[s.moneda][concepto.concepto] += concepto.importe;
                        } else {
                          this.conceptos[s.moneda][concepto.concepto] = concepto.importe;
                        }
                      } else {
                        this.conceptos[s.moneda] = {};
                        this.conceptos[s.moneda][concepto.concepto] = concepto.importe;
                      }
                    }
                  }

                  if (!this.conceptos[s.moneda]["zzzTOTAL"]) {
                    this.conceptos[s.moneda]["zzzTOTAL"] = concepto.importe;
                  } else {
                    this.conceptos[s.moneda]["zzzTOTAL"] = this.conceptos[s.moneda]["zzzTOTAL"] + concepto.importe;
                  }

                  if (concepto.concepto === "Contabilium Fact. Electrónica") {
                    importesADescontar += concepto.importe
                  }
                }

              }
            }

            if (s.moneda == "ARS" || s.moneda == "ARS $" || (s.moneda === "USD" && s.pais === "ARG")) {
              if (s.moneda !== "USD") {
                if (isNaN(s.importe)) {
                  console.log(s);
                }
                importes += s.importe;
                if (isNaN(importesADescontar)) {
                  console.log(s);
                }
                importes -= importesADescontar;
                if (s.pagaIva) {
                  ivaARS += (s.importe - importesADescontar) * s.pagaIva / 100;
                }
                let valorAcumLocal = 0;
                let valorAcumCuenta = 0;

                if (this.localesConImportesARS.get(`${s.idCuenta}/locales/${s.idLocal}`)) {
                  valorAcumLocal = this.localesConImportesARS.get(`${s.idCuenta}/locales/${s.idLocal}`);
                }

                this.localesConImportesARS.set(`${s.idCuenta}/locales/${s.idLocal}`, valorAcumLocal + s.importe);

                if (this.cuentasConImportesARS.get(s.idCuenta)) {
                  valorAcumCuenta = this.cuentasConImportesARS.get(s.idCuenta);
                }
                this.cuentasConImportesARS.set(s.idCuenta, valorAcumCuenta + s.importe);
                this.cantServConImporteArg++;
                this.servidoresARS.set(`${s.idCuenta}/locales/${s.idLocal}/servidores/${s.idMac}`, 1);
              } else {
                if (isNaN(this.totalCotizacionUSDARS)) {
                  console.log(s);
                }
                if (isNaN(s.importe)) {
                  console.log(s);
                }
                if (isNaN(importesADescontar)) {
                  console.log(s);
                }
                importes += this.totalCotizacionUSDARS * s.importe;
                importes -= this.totalCotizacionUSDARS * importesADescontar;
                if (s.pagaIva) {
                  ivaARS += this.totalCotizacionUSDARS * (s.importe - importesADescontar) * s.pagaIva / 100;
                }
                let valorAcumLocal = 0;
                let valorAcumCuenta = 0;

                if (this.localesConImportesARS.get(`${s.idCuenta}/locales/${s.idLocal}`)) {
                  valorAcumLocal = this.localesConImportesARS.get(`${s.idCuenta}/locales/${s.idLocal}`);
                }

                this.localesConImportesARS.set(`${s.idCuenta}/locales/${s.idLocal}`, valorAcumLocal + (this.totalCotizacionUSDARS * s.importe));

                if (this.cuentasConImportesARS.get(s.idCuenta)) {
                  valorAcumCuenta = this.cuentasConImportesARS.get(s.idCuenta);
                }
                this.cuentasConImportesARS.set(s.idCuenta, valorAcumCuenta + (this.totalCotizacionUSDARS * s.importe));
                this.cantServConImporteArg++;
                this.servidoresARS.set(`${s.idCuenta}/locales/${s.idLocal}/servidores/${s.idMac}`, 1);
              }

            } else if (s.moneda == "CLP" || s.moneda == "CLP $") {

              if (isNaN(s.importe)) {
                console.log(s);
                console.log("clp");

              }
              if (isNaN(importesADescontar)) {
                console.log(s);
                console.log("asd");

              }
              importesClp += s.importe;
              importesClp -= importesADescontar;

              if (s.pagaIva) {
                ivaCLP += (s.importe - importesADescontar) * s.pagaIva / 100;
              }

              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;

              if (this.localesConImportesCLP.get(`${s.idCuenta}/locales/${s.idLocal}`)) {
                valorAcumLocal = this.localesConImportesCLP.get(`${s.idCuenta}/locales/${s.idLocal}`);
              }

              this.localesConImportesCLP.set(`${s.idCuenta}/locales/${s.idLocal}`, valorAcumLocal + s.importe);

              if (this.cuentasConImportesCLP.get(s.idCuenta)) {
                valorAcumCuenta = this.cuentasConImportesCLP.get(s.idCuenta);
              }
              this.cuentasConImportesCLP.set(s.idCuenta, valorAcumCuenta + s.importe);
              this.cantServConImporteClp++;
              this.servidoresCLP.set(`${s.idCuenta}/locales/${s.idLocal}/servidores/${s.idMac}`, 1);
            } else if (s.moneda == 'UF') {
              if (!this.cotizacionUF) {
                try {
                  this.leerCotizacionUF();
                } catch (e) {
                  this.cotizacionUF = 28330;//TODO:
                }
                if (!this.cotizacionUF) {
                  this.cotizacionUF = 28330;//TODO:
                }
              }

              if (isNaN(s.importe)) {
                console.log(s);
                console.log('UF');

              }
              if (isNaN(this.cotizacionUF)) {
                console.log("COTIZacion");
              }
              importesClp += (s.importe * this.cotizacionUF);
              importesClp -= importesADescontar * this.cotizacionUF;
              if (isNaN(importesADescontar)) {
                console.log(s);
                console.log("****");

              }

              if (s.pagaIva) {
                ivaCLP += (s.importe - importesADescontar) * this.cotizacionUF * s.pagaIva / 100;
              }
              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;

              if (this.localesConImportesCLP.get(`${s.idCuenta}/locales/${s.idLocal}`)) {
                valorAcumLocal = this.localesConImportesCLP.get(`${s.idCuenta}/locales/${s.idLocal}`);
              }

              this.localesConImportesCLP.set(`${s.idCuenta}/locales/${s.idLocal}`, valorAcumLocal + s.importe * this.cotizacionUF);

              if (this.cuentasConImportesCLP.get(s.idCuenta)) {
                valorAcumCuenta = this.cuentasConImportesCLP.get(s.idCuenta);
              }
              this.cuentasConImportesCLP.set(s.idCuenta, valorAcumCuenta + s.importe * this.cotizacionUF);
              this.cantServConImporteClp++;
              this.servidoresCLP.set(`${s.idCuenta}/locales/${s.idLocal}/servidores/${s.idMac}`, 1);

            } else if (s.moneda === "USD" && s.pais === "CHI") {
              if (!this.cotizacionUSDCLP) {
                try {
                  this.leerCotizacionUSDCLP();
                } catch (e) {
                  console.log(e);
                  this.cotizacionUSDCLP = 1000;//TODO:
                }
                if (!this.cotizacionUSDCLP) {
                  this.cotizacionUSDCLP = 1000;//TODO:
                }
              }

              if (isNaN(s.importe)) {
                console.log(s);
                console.log('USD CHI');

              }
              if (isNaN(this.cotizacionUSDCLP)) {
                console.log("COTIZacion USD CLP");
              }
              importesClp += (s.importe * this.cotizacionUSDCLP);
              importesClp -= importesADescontar * this.cotizacionUSDCLP;
              if (isNaN(importesADescontar)) {
                console.log(s);
                console.log("****");

              }

              if (s.pagaIva) {
                ivaCLP += (s.importe - importesADescontar) * this.cotizacionUSDCLP * s.pagaIva / 100;
              }
              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;

              if (this.localesConImportesCLP.get(`${s.idCuenta}/locales/${s.idLocal}`)) {
                valorAcumLocal = this.localesConImportesCLP.get(`${s.idCuenta}/locales/${s.idLocal}`);
              }

              this.localesConImportesCLP.set(`${s.idCuenta}/locales/${s.idLocal}`, valorAcumLocal + s.importe * this.cotizacionUSDCLP);

              if (this.cuentasConImportesCLP.get(s.idCuenta)) {
                valorAcumCuenta = this.cuentasConImportesCLP.get(s.idCuenta);
              }
              this.cuentasConImportesCLP.set(s.idCuenta, valorAcumCuenta + s.importe * this.cotizacionUSDCLP);
              this.cantServConImporteClp++;
              this.servidoresCLP.set(`${s.idCuenta}/locales/${s.idLocal}/servidores/${s.idMac}`, 1);
            } else if (s.moneda == "USD" || s.moneda == "U$D") {
              importesDolar += s.importe;
              importesDolar -= importesADescontar;

              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;

              if (s.pagaIva) {
                ivaDolar += (s.importe - importesADescontar) * s.pagaIva / 100;
              }


              if (this.localesConImportesUSD.get(`${s.idCuenta}/locales/${s.idLocal}`)) {
                valorAcumLocal = this.localesConImportesUSD.get(`${s.idCuenta}/locales/${s.idLocal}`);
              }

              this.localesConImportesUSD.set(`${s.idCuenta}/locales/${s.idLocal}`, valorAcumLocal + s.importe);

              if (this.cuentasConImportesUSD.get(s.idCuenta)) {
                valorAcumCuenta = this.cuentasConImportesUSD.get(s.idCuenta);
              }
              this.cuentasConImportesUSD.set(s.idCuenta, valorAcumCuenta + s.importe);
              this.cantServConImporteDolar++;
              this.servidoresUSD.set(`${s.idCuenta}/locales/${s.idLocal}/servidores/${s.idMac}`, 1);
            } else if (s.valorDolar && s.valorDolar > 0) {

              let valorAPagar = s.valorDolar;
              if (s.pagaIva && s.pagaIva > 0) {
                valorAPagar = valorAPagar + valorAPagar * s.pagaIva / 100;
                ivaDolar += (s.importe - importesADescontar) * s.pagaIva / 100;

              }

              importesDolar += valorAPagar;
              importesDolar -= importesADescontar;

              let valorAcumLocal = 0;
              let valorAcumCuenta = 0;

              if (this.localesConImportesUSD.get(`${s.idCuenta}/locales/${s.idLocal}`)) {
                valorAcumLocal = this.localesConImportesUSD.get(`${s.idCuenta}/locales/${s.idLocal}`);
              }

              this.localesConImportesUSD.set(`${s.idCuenta}/locales/${s.idLocal}`, valorAcumLocal + valorAPagar);

              if (this.cuentasConImportesUSD.get(s.idCuenta)) {
                valorAcumCuenta = this.cuentasConImportesUSD.get(s.idCuenta);
              }
              this.cuentasConImportesUSD.set(s.idCuenta, valorAcumCuenta + valorAPagar);
              this.cantServConImporteDolar++;
              this.servidoresUSD.set(`${s.idCuenta}/locales/${s.idLocal}/servidores/${s.idMac}`, 1);

            } else {
              // no habria q sumar nada pues no sabemos q moneda iria
              this.dbFs.collection("mail").add({
                to: 'gmarin@popapp.io, jcortez@popapp.io, gperez@popapp.io, paula@popapp.io',
                message: {
                  text: 'Cambiar la moneda y/o el pais',
                  subject: 'Moneda y/o pais mal configurados para la cuenta: ' + s.idCuenta
                }
              });
            }
          } else {
            if (s.numeroLocal && s.moneda && +s.numeroLocal > 0 && (s.cuotasPendientes == null)) { //1500 para arriba seria para testear pero no para sumar
              //TODO: otrosConceptos sumar sumar iva
            }

          }
          //TODO: actualizacion marca
          //this.actualizaDatos(s);

        }

        //  l.servidoresIds = asdasdasd;
        this.locales.set(l.id, l);
      }

      // c.localesIds = asdasd;
      this.cuentas.push(c);
    }

    //if (this.mapUpdates !== {}) {
    //  this.db.object("/").update(this.mapUpdates).then(v => {
    //    console.log("adminCuentas Actualizado");
    //  }).catch(e => {
    //    console.log("adminCuentas NO SE Actualizo", e);
    //  })
    //}

    this.totalServidores = this.servidores.length;
    this.sinVencer = servidoresSinVencer;
    this.inc14 = incluidos14;
    if (this.totalServidores > 0) {
      this.porcentaje = this.sinVencer / this.totalServidores;
      this.inc14porcentaje = this.inc14 / this.totalServidores;
    }

    this.totalImportesDolar = importesDolar;
    this.totalImportesArg = importes;
    this.totalImportesClp = importesClp;

    this.totalImportesDolarcIVA = importesDolar + ivaDolar;
    console.log(importes)
    console.log(ivaARS)
    this.totalImportesArgcIVA = importes + ivaARS;
    this.totalImportesClpcIVA = importesClp + ivaCLP;

    const arregloCuentasArs = Array.from(this.cuentasConImportesARS.keys());
    const arregloCuentasClp = Array.from(this.cuentasConImportesCLP.keys());
    const arregloCuentasUsd = Array.from(this.cuentasConImportesUSD.keys());
    this.cuentasActivas = [...arregloCuentasUsd, ...arregloCuentasClp, ...arregloCuentasArs];

    // TODO: console.log('cuentasARS', arregloCuentasArs);
    // TODO:console.log('cuentasCLP', arregloCuentasClp);
    // TODO:console.log('cuentasUSD', arregloCuentasUsd);
    // TODO:console.log('Cuentas', this.cuentasActivas);

    const arregloLocalesArs = Array.from(this.localesConImportesARS.keys());
    const arregloLocalesClp = Array.from(this.localesConImportesCLP.keys());
    const arregloLocalesUsd = Array.from(this.localesConImportesUSD.keys());
    this.localesActivos = [...arregloLocalesArs, ...arregloLocalesClp, ...arregloLocalesUsd];

    // TODO:console.log('localesARS', arregloLocalesArs);
    // TODO:console.log('localesCLP', arregloLocalesClp);
    // TODO:console.log('localesUSD', arregloLocalesUsd);
    // TODO:console.log('Locales', this.localesActivos);

    const arregloservidoresArs = Array.from(this.servidoresARS.keys());
    const arregloservidoresClp = Array.from(this.servidoresCLP.keys());
    const arregloservidoresUsd = Array.from(this.servidoresUSD.keys());
    this.servidoresActivos = [...arregloservidoresArs, ...arregloservidoresClp, ...arregloservidoresUsd];

    // TODO:console.log('managersARS', arregloservidoresArs);
    // TODO:console.log('managersCLP', arregloservidoresClp);
    // TODO:console.log('managersUSD', arregloservidoresUsd);
    // TODO:console.log('Servidores', this.servidoresActivos);

    return estructuraControl;
  }
  addEstructura(
    idCuenta: string,
    idLocal: string,
    idMac: string,
    estructuraControl: Map<string, number>
  ) {
    estructuraControl.set(`${idCuenta},${idLocal},${idMac}`, 2);
  }

  servidorActivo(s: ServidorAdmin) {
    return s.numeroLocal &&
      s.moneda &&
      +s.numeroLocal > 0 &&
      +s.numeroLocal < 9000 && +s.numeroLocal !== 900 && (s.cuotasPendientes == null)
  }

  metodoExporte(servidor: ServidorAdmin) {
    return servidor.idMac &&
      +servidor.numeroLocal &&
      servidor.nombreServidor &&
      servidor.numeroLocal && 0 < +servidor.numeroLocal &&
      +servidor.numeroLocal < 9000 &&
      +servidor.numeroLocal !== 900
      && servidor.nombreServidor !== 'Costo alta de licencia';
  }

  mapUpdates = {};

  actualizaDatos(s: ServidorAdmin) {
    if (s.keyPedidosOnline && s.keyPedidosOnline.key && s.keyPedidosOnline.locales) {


      let urlConfLocal = "confLocalPO/" + s.keyPedidosOnline.key + "/";

      for (let keyTienda in s.keyPedidosOnline.locales) {
        let mapUpdatesTiendaFS = {};
        let mapUpdateTiendaChanged = false;
        let urlAdmin = "cuentas/" + s.idCuenta + "/locales/" + s.idLocal + "/servidores/" + s.idMac + "/keyPedidosOnline/locales/";

        let tienda = s.keyPedidosOnline.locales[keyTienda];

        let origen: string = tienda.origen;
        if (!origen) {
          console.log("Sin origen " + s.keyPedidosOnline.key, tienda);
          continue;
        }

        let origen3 = origen;
        if (origen.startsWith("rap")) {
          origen3 = "rap";
        }


        const nombre = tienda.nombre;
        const idStore = tienda.idStore;
        const marca = tienda.marca;
        const fechaAlta = tienda.fechaAlta;




        urlAdmin += origen3 + idStore + "/";
        let urlConfLocalTienda = urlConfLocal += "tiendas/" + origen + "," + idStore + "/";

        let urlkeysLocales2 = "keysLocalesPedidosOnline2/" + origen + "," + idStore + "/";

        if (!marca) {
          this.mapUpdates[urlAdmin + "marca"] = nombre;
          this.mapUpdates[urlkeysLocales2 + "marca"] = nombre;
          mapUpdatesTiendaFS["marca"] = nombre;
          mapUpdateTiendaChanged = true;
        }

        if (!fechaAlta) {
          if (s.fechaInstalacionyyyyMMddd) {
            let fecha = s.fechaInstalacionyyyyMMddd;
            while (fecha.includes("/")) {
              fecha = fecha.replace("/", "-");
            }
            this.mapUpdates[urlAdmin + "fechaAlta"] = fecha;
            this.mapUpdates[urlkeysLocales2 + "fechaAlta"] = fecha;
            mapUpdatesTiendaFS["fechaAlta"] = fecha;
            mapUpdateTiendaChanged = true;
          }
        }

        if (mapUpdateTiendaChanged) {
          mapUpdatesTiendaFS["keyLocal"] = s.keyPedidosOnline.key;
          let refTienda = this.dbFs.doc(urlConfLocalTienda).ref;
          refTienda.set(mapUpdatesTiendaFS, { merge: true });
        }
      }
    }
  }

  getMyStyles(dias: number) {
    let color = 'green';
    if (dias < 0 && dias > -8) {
      color = 'violet';
    } else if (dias < 0) {
      color = 'red';
    }
    let myStyles = {
      'color': 'white',
      'background': color,
      'padding': '10px'
    };
    return myStyles;
  }

  fechaDeddMMyyyyAyyyyMMdd(fechaInstalacion: string): string {
    if (fechaInstalacion) {
      let array = fechaInstalacion.split("/");
      if (array && array.length == 3) {
        return array[2] + "/" + (array[1].length > 1 ? array[1] : "0" + array[1]) + "/" + (array[0].length > 1 ? array[0] : "0" + array[0]);
      }
    }
    return "";
  }

  openSnackBar(message: string, action: string) {
    this.msjSnack = "|  " + message + "      " + action + "  |"
    setTimeout(_ => {
      this.msjSnack = null
    }, 3000)
    //this.snackBar.open(message, action, {
    // duration: 2000,
    //});
  }


  copiarTablaClipBoard() {
    let table = document.querySelector('#tablaAdmin');

    let range = document.createRange();
    range.selectNodeContents(table)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)

    document.execCommand('copy')
  }


  onClickConciliarDatosSql(recurso: string) {
    this.cargandoScript = true;
    this.seleccionarDatosAConciliar(recurso)
      .then(_ => this.openSnackBar('Script ejecutado con éxito', 'Ok'))
      .catch(err => {
        this.openSnackBar('Error al ejecutar el script', 'Ok');
        console.error(err);
      })
      .finally(() => this.cargandoScript = false);
  }

  seleccionarDatosAConciliar(recurso: string) {
    switch (recurso) {
      case 'cuentas':
        return this.conciliarDatosSql('v1/cuentas/scripts/update-active-accounts', { accountIds: this.cuentasActivas });
      case 'locales':
        return this.conciliarDatosSql('v1/locaciones/scripts/update-active-locations', { locPaths: this.localesActivos });
      case 'managers':
        return this.conciliarDatosSql('v1/managers/scripts/update-active-managers', { pathsManagers: this.servidoresActivos });
    }
  }

  conciliarDatosSql(path: string, body) {
    return this.http.post(`https://onboarding.services.popapp.io/${path}`, body).toPromise();
  }

}
