export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCdIWqXwzcYR-JA66iiAVLsk09BE6NATt4",
    authDomain: "popapptest-520dc.firebaseapp.com",
    databaseURL: "https://popapptest-520dc.firebaseio.com",
    projectId: "popapptest-520dc",
    storageBucket: "popapptest-520dc.appspot.com",
    messagingSenderId: "724184716284",
    appId: "1:724184716284:web:f20c9dd3eb107cb4da1879"
  },
  urls: {
    onboarding: 'https://onboarding.dev.popapp.io',
    billing: 'https://billing.dev.popapp.io/api/billing/v1',
    functions: 'https://us-central1-popapptest-520dc.cloudfunctions.net',
    escuchador: 'https://integraciones3.popapp.io',
    firebase: 'https://console.firebase.google.com/u/0/project/popapptest-520dc/database/popapptest-520dc',
    firestore: 'https://console.firebase.google.com/u/0/project/popapptest-520dc/firestore',
    popappWeb: 'https://test.popapp.io',
    mypopapp: 'https://dev.my.popapp.io'
  }
};
