import { FraccionCentral } from "./stockCentral/fraccioncentral";
import { IngredCentral, IngredientePOJO } from "./stockCentral/ingredientecentral";

export class CartaCentalPOJO {
    activos: any;
    categorias: any;
    comidas: any;
    fracciones: any;
    ingredientes: any;
    medidas: any;
    stocks: any;
    tipoImpresion: any;
    ultimoActualizado: any;
    ultimoSubidoStock: any;
    ultimosLeidos: any;
    ultimosModificados: any;
    constructor() { }
}
export class ComidaPOJO {
    activado: boolean;
    alertaStock: boolean;
    codigo: number;
    costo: number;
    descripcion: string;
    eliminado: boolean;
    esCombo: boolean;
    idPAR: number;
    idRubro: number;
    ingredientes: Map<string, IngredientePOJO>;
    keyCategoria: string;
    keyfb: string;
    keysFracciones: Map<string, string>;
    nombreComida: string;
    stockAlerta: number;
    stockMinimo: number;
    tieneFracciones: boolean;
    tieneIngredientes: boolean;
    constructor() { }
}


export class ComidaCentral {
    alerta: boolean;
    alertaCant: number;
    categoria: string;
    desactivoEnMacs: string[];
    esCombo: boolean;
    esIngrediente: boolean;
    fracciones: FraccionCentral[];
    idkey: string;
    ingredientes: IngredCentral[];
    keyCategoria: string;
    medida: string;
    nombre: string;
    precio: number;
    stock: any;
    tieneIngredientes: boolean;
    keysComidas: string[];
    constructor() { }
}



