import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServidorAdmin } from 'src/app/shared/admin/servidor-admin';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { CuentaAdmin } from 'src/app/shared/admin/cuenta-admin';

@Component({
  selector: 'app-modal-envio-mail-precio',
  templateUrl: './modal-envio-mail-precio.component.html',
  styleUrls: ['./modal-envio-mail-precio.component.scss']
})
export class ModalEnvioMailPrecioComponent implements OnInit {

  cuentasAEnviar: CuentaAdmin[];
  cuentasEnviadas: CuentaAdmin[];
  cuentasSinEmails: CuentaAdmin[];
  servidores: ServidorAdmin[] = [];
  loading: boolean;
  cuentas: CuentaAdmin[];
  SIN_MAILS: string = "SIN_MAILS";

  constructor(
    public dialogRef: MatDialogRef<ModalEnvioMailPrecioComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { servidores: ServidorAdmin[], cuentas: CuentaAdmin[] },
    private db: AngularFireDatabase,
    private dbFirestore: AngularFirestore,
    private snackbar: MatSnackBar,
  ) {
    this.servidores = this.data.servidores;
    this.cuentas = this.data.cuentas;

  }


  async ngOnInit() {
    this.loading = true;
    this.cuentasEnviadasYno();
    this.loading = false;
  }

  cuentasEnviadasYno() {
    this.cuentasAEnviar = [];
    this.cuentasEnviadas = [];
    this.cuentasSinEmails = [];

    const cuentasProcesadas: Map<string, boolean> = new Map();
    for (let s of this.servidores) {
      if (!cuentasProcesadas.get(s.idCuenta)) {
        cuentasProcesadas.set(s.idCuenta, true);
        const servidoresDeLaCuenta = this.servidores.filter(x => x.idCuenta === s.idCuenta);
        const cuenta = this.cuentas.find(x => x.id === s.idCuenta);
        if (cuenta && servidoresDeLaCuenta && servidoresDeLaCuenta.length > 0) {
          cuenta.servidoresActivosConUSDARG = [];
          for (let sc of servidoresDeLaCuenta) {
            if (sc.numeroLocal &&
              +sc.numeroLocal > 0 &&
              +sc.numeroLocal !== 900 &&
              +sc.numeroLocal < 9000 &&
              sc.pais === "ARG" &&
              sc.moneda === "USD") {
              cuenta.servidoresActivosConUSDARG.push(s);
            }
          }
          if (cuenta.servidoresActivosConUSDARG.length > 0) {
            if (cuenta.emailDolarizacionEnviado && cuenta.emailDolarizacionEnviado !== this.SIN_MAILS) {
              this.cuentasEnviadas.push(cuenta);
            } else if (!cuenta.emailDolarizacionEnviado) {
              this.cuentasAEnviar.push(cuenta);
            } else {
              this.cuentasSinEmails.push(cuenta);
            }
          }
        }
      }
    }
  }

  async getMails(servidores: ServidorAdmin[]): Promise<string | undefined> {
    let usuarios: string[] = [];
    for (let s of servidores) {
      let v = await this.db.object("usuarios/" + s.idMac + "/mails").query.once("value");
      let mails = v.val();
      if (mails) {
        for (let u in mails) {
          if (!usuarios.find(x => x === mails[u])) {
            usuarios.push(mails[u]);
          }
        }
      }

      if (s.emails) {
        for (let u in s.emails) {
          if (!usuarios.find(x => x === mails[u])) {
            usuarios.push(s.emails[u]["email"]);
          }
        }
      }
    }
    if (usuarios && usuarios.length > 0) {
      let emails = "";
      for (let u of usuarios) {
        if (emails === "") {
          emails += u;
        } else {
          emails += ", " + u;
        }
      }
      return emails;
    } else {
      return undefined;
    }


  }



  async enviarMail() {
    this.loading = true;
    const asunto = "Cambios en los términos y condiciones";
    const cuerpo = "Estimado usuario de Popapp:\n" +
      "\n" +
      "Escribimos para informarte que a partir del mes de agosto del corriente año, el nuevo valor de la suscripción mensual de Popapp es de USD 45 + IVA  (dólar oficial).\n" +
      "\n" +
      "Nos vemos obligados a actualizar para sostener nuestro nivel de servicio, seguir invirtiendo en nuevos recursos y procesos para mejorar Popapp.\n" +
      "\n" +
      "Como muestra de nuestro compromiso, a partir de ahora podrás integrar todas las tiendas de apps de delivery que necesites y también podrás utilizar dispositivos con app de mozo o cocina de forma ilimitada. Todo incluído en el valor antes mencionado.\n" +
      "\n" +
      "Saludos!\n" +
      "\n" +
      "El equipo de Popapp.";
    for (let c of this.cuentasAEnviar) {
      const emailsAEnviar: string | undefined = await this.getMails(c.servidoresActivosConUSDARG);
      if (emailsAEnviar) {
        await this.dbFirestore.collection("mail").add({
          to: emailsAEnviar,
          message: {
            text: cuerpo,
            subject: asunto
          }
        });
        await this.db.object("cuentas/" + c.id + "/emailDolarizacionEnviado").set(emailsAEnviar);

      } else {
        await this.db.object("cuentas/" + c.id + "/emailDolarizacionEnviado").set(this.SIN_MAILS);
      }
    }

    this.snackbar.open("Proceso terminado actualiza la pagina y vuelve a entrar")._dismissAfter(4000);
    this.loading = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}