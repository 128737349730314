import { Component, OnInit } from '@angular/core';
import { PieChartConfig } from 'src/app/charts/pieconfig';
import { LineChartConfig } from 'src/app/charts/linechartconfig';
export interface Ticket {
  "ID del ticket": number,
  "Asunto": string,
  "Estado": string,
  "Origen": string,
  "Tipo": string,
  "Agente": string,
  "Hora de creación": string,//"2020-02-03 09:11:31",
  "Hora de resolución": string, //"2020-02-07 14:50:16",
  "Hora de cierre": string,
  "Etiquetas": string,
  "Nombre completo": string,
  "Correo electrónico": string,
  "ID del contacto": string
}
@Component({
  selector: 'app-admin-tickets',
  templateUrl: './admin-tickets.component.html',
  styleUrls: ['./admin-tickets.component.scss']
})
export class AdminTicketsComponent implements OnInit {
  tickets: Ticket[] = []

  driftVar: any;
  totales: number[];

  tipos: Map<string, { nombre: string, total: number }>[];
  mostrar = false;
  graficosData: {
    data: any[];
    config: PieChartConfig;
    elementId: string;
    pieTrueBarFalse: boolean;
  }[];

  constructor() { }

  ngOnInit() {
    this.hideDrift(1);
    this.tipos = []
    this.tipos.push(this.procesaLista("Estado"));
    this.tipos.push(this.procesaLista("Tipo"));
    this.tipos.push(this.procesaLista("ID del contacto"));
    this.graficosData = []
    this.dibujaCharts(0);
    this.dibujaCharts(1);
    this.dibujaCharts(2);
    this.dibujaChartComparativo();
    this.mostrar = true;
  }
  hideDrift(cant) {
    if (cant < 15) {
      if (this.driftVar) {
        this.driftVar.widget ? this.driftVar.widget.remove() : "";
      } else {
        this.driftVar = window["HubSpotConversations"];
      }
      cant++;
      setTimeout(() => { this.hideDrift(cant) }, 600)
    }
  }


  /*consultar(datos: { fechaDesde: string, fechaHasta: string, buscando: boolean }) {
    this.tareas = new Map<string, TareaAgil>();
    let tareasList = this.db.list("agilidad/tareas", ref => ref.orderByChild("Meetings")
      .startAt(datos.fechaDesde)
      .endAt(datos.fechaHasta + "~"))
   
    let subs = tareasList.snapshotChanges().subscribe(changes => {
      //this.buscando = false;
      changes.map(t => {
        let tt: TareaAgil = <TareaAgil>t.payload.val();
        this.tipos = this.procesaTarea({ key: t.payload.key, ...tt });
      });
      this.dibujaCharts()
    });
  }*/

  getTotal(ind): number {
    let total = 0;
    if (this.tipos && this.tipos[ind]) {
      for (let t of this.tipos[ind].values()) {
        total += t.total;
      }
    }
    return total;
  }

  procesaLista(atributo: string): Map<string, { nombre: string, total: number }> {
    let tipos = new Map<string, { nombre: string, total: number }>();

    this.tickets.forEach((v) => {
      //if (v.Estado !== "Resolved") {
      if (tipos.get(v[atributo])) {
        tipos.set(v[atributo], { nombre: v[atributo], total: tipos.get(v[atributo]).total + 1 })
      } else {
        tipos.set(v[atributo], { nombre: v[atributo], total: 1 })
      }
      // }
    })


    if (tipos.size > 10) {
      return this.agrupaTiposEnOtros(tipos);
    } else {
      return tipos;
    }
  }

  agrupaTiposEnOtros(tipos: Map<string, { nombre: string, total: number }>): Map<string, { nombre: string, total: number }> {
    let arre: { nombre: string, total: number }[] = []
    tipos.forEach((v) => {
      arre.push(v);
    })

    arre.sort((a, b) => {
      return b.total - a.total
    })

    let tiposResultados0 = new Map<string, { nombre: string, total: number }>();
    let i = 0;
    for (let a of arre) {
      if (i < 10) {
        tiposResultados0.set(a.nombre, { nombre: a.nombre, total: a.total })
      } else {
        if (tiposResultados0.get("OTROSS")) {
          tiposResultados0.set("OTROSS", { nombre: "OTROSS", total: tiposResultados0.get("OTROSS").total + a.total })
        } else {
          tiposResultados0.set("OTROSS", { nombre: "OTROSS", total: a.total })
        }
      }
      i++;
    }
    return tiposResultados0;
  }

  /* tiposPorDefecto(tipo: string) {
     if (this.tipos.get(tipo)) {
       this.data1.push([tipo, this.tipos.get(tipo).total])
     } else {
       this.data1.push([tipo, 0])
     }
   }*/

  data4: any[];
  config4: LineChartConfig;
  elementId4: string;
  dibujaChartComparativo() {
    let ticketsHistoricos = [
      {
        mes: "Noviembre",
        tipos: [
          { t: "Resolved", q: 369 },
          { t: "Closed", q: 58 },
          { t: "Open", q: 0 },
          { t: "Pending", q: 0 },
          { t: "Total", q: 427 }
        ]
      }, {
        mes: "Diciembre",
        tipos: [
          { t: "Resolved", q: 398 },
          { t: "Closed", q: 92 },
          { t: "Open", q: 0 },
          { t: "Pending", q: 0 },
          { t: "Total", q: 490 }
        ]
      },
      {
        mes: "Enero",
        tipos: [
          { t: "Resolved", q: 374 },
          { t: "Closed", q: 45 },
          { t: "Open", q: 1 },
          { t: "Pending", q: 0 },
          { t: "Total", q: 420 }
        ]
      },
      {
        mes: "Febrero",
        tipos: [
          { t: "Resolved", q: 371 },
          { t: "Closed", q: 66 },
          { t: "Open", q: 16 },
          { t: "Pending", q: 4 },
          { t: "Total", q: 457 }
        ]
      },
      {
        mes: "Marzo",
        tipos: [
          { t: "Resolved", q: 312 },
          { t: "Closed", q: 72 },
          { t: "Open", q: 42 },
          { t: "Pending", q: 3 },
          { t: "Total", q: 429 }
        ]
      }
    ]
    this.data4 = [];

    let lineas: string[] = ['Tipo', "Resolved", "Closed", "Open", "Pending", "Total"];
    this.data4.push(lineas);

    ticketsHistoricos.forEach((v) => {
      let valores: any[] = [v.mes];
      v.tipos.forEach((x) => {
        valores.push(x.q);
      });
      this.data4.push(valores);
    });


    this.config4 = new LineChartConfig('Tickets Historicos', '');
    this.elementId4 = 'myPieChart4';
  }

  dibujaCharts(ind: number) {
    if (this.tipos && this.tipos[ind] && this.tipos[ind].size > 0) {
      //Piechart1 Data & Config
      //Mesas

      let data = [];
      data.push(['Tipo' + ind, 'Puntos']);

      let tiposParaQueSeRespetenLosColores = []//"SOPORTE", "BUG", "AGILIDAD", "MEJORA"];
      for (let t of tiposParaQueSeRespetenLosColores) {
        //this.tiposPorDefecto(t);
      }

      this.tipos[ind].forEach((v) => {
        if (!tiposParaQueSeRespetenLosColores.includes(v.nombre)) {
          data.push([v.nombre, v.total])
        }
      });

      let config = new PieChartConfig('Tipo' + ind, 0.4);
      let elementId = 'myPieChart' + ind;
      let pieTrueBarFalse = true;

      this.graficosData.push(
        {
          data: data,
          config: config,
          elementId: elementId,
          pieTrueBarFalse: pieTrueBarFalse
        }
      );


    }

  }
}
